export enum LaunchpadNavEnum {
  START_SCREEN,
  SALES,
  PURCHASE,
  ACCOUNTING,
  WAREHOUSE,
  ANALYTICS,
  MANAGEMENT,
  ALL_FUNCTIONS
}

export interface LaunchPadNavModel {
  tabName: LaunchpadNavEnum;
  label: string;
  hidden?: boolean;
}

export interface StartScreenModel {
  screenCount: number;
  items: AppListModel[];
  name: string;
}
export interface AppListModel {
  type?: AppListTypeEnum;
  name: AppNameEnum | string;
  isOpen?: boolean;
  iconName?: string;
  mainColor?: string;
  routerLink?: string;
  counter?: number;
  items?: AppListModel[];
}

export enum AppListTypeEnum {
  SINGLE = 'single',
  FOLDER = 'folder',
}

export enum AppNameEnum {
  ADMINISTRATION = 'administration',
  TRADE_DASHBOARD = 'trade_dashboard',
  ACCOUNTING_DASHBOARD = 'accounting_dashboard',
  WAREHOUSE_DASHBOARD = 'warehouse_dashboard',
  STOCK_STATUS = 'stock_status',
  INCOMING_DELIVERIES = 'incoming_deliveries',
  OUTGOING_DELIVERIES = 'outgoing_deliveries',
  TRANSACTIONS = 'transactions',
  STOCKTAKING = 'stock_tacking',
  MANAGEMENT = 'management',
  OUTGOING_INVOICES = 'outgoing_invoices',
  INCOMING_INVOICES = 'incoming_invoices',
  PAYMENTS = 'payments',
  CREDIT_NOTES = 'credit_notes',
  OPEN_ITEM_LIST = 'open_item_list',
  REPORTING_PERIODS = 'reporting_periods',
  BANK_ACCOUNT = 'bank_account',
  GL_ACCOUNT = 'gl_account',
  FIXED_ASSETS = 'fixed_assets',
  GENERAL_PRODUCTS = 'general_products',
  SETTINGS = 'settings',
  PURCHASE_ORDERS = 'purchase_orders',
  PURCHASE_PRICES = 'purchase_prices',
  SALES_PRICES = 'sales_prices',
  OFFERS = 'offers',
  SALES_ORDERS = 'sales_orders',
  RETURNS = 'returns',
  TIME_TRACKING = 'time_tracking',
  SUBSCRIPTIONS = 'subscriptions',
  E_COMMERCE = 'ecommerce',
  CONTROL_CENTER = 'control_center',
  SCHEDULED_PAYMENTS = 'scheduled_payments',
  PAYMENT_CALENDAR = 'payment_calendar',
  DATA_IMPORT = 'data_import',
  GOODS = 'goods',
  SERVICES = 'services',
  DIGITAL = 'digital_products',
  CORPORATE_PARTNERS = 'corporate_partners',
  PRIVATE_PARTNERS = 'private_partners',
  CATEGORIES = 'categories',
  EMPLOYEES = 'employees',
  PRICES = 'prices',
  PRODUCTS = 'products',
  PARTNERS = 'partners',
  PARTNERS_SETTINGS = 'partners_settings',
  PRODUCTS_SETTINGS = 'products_settings',
  PDF_DESIGNER = 'pdf_designer',
  REPORTS = 'reports',
  CASHFLOW = 'cashflow',
}

export interface AppDataModel {
  title: string;
  iconName: string;
  mainColor: string;
  routerLink: string;
  actionList: AppActionModel[];
}

export interface AppActionModel {
  router?: string;
  action?: string;
  title: string;
  icon: string;
  hide?: boolean;
}

export interface AppActionsListModel {
  app?: AppListModel;
  appName?: AppNameEnum;
  actions: AppActionModel[];
}

