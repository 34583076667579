import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResponseModel, UserSorting } from 'projects/workspace/src/app/shared/models/response';
import { DisplayToaster } from 'projects/workspace/src/app/shared/decorators/toaster';
import { environment } from 'projects/workspace/src/environments/environment';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { AuthService } from 'common/src/auth/auth.service';

@Injectable()
export class UserSortingService {
  private readonly apiEndpoint: string = `${environment.javaCommonsApiVersion}/sorting`;

  private apiUrl(url: string = ''): string {
    return this.apiEndpoint + url;
  }

  constructor(
    private readonly toasterService: ToasterService,
    private readonly http: HttpClient,
    private readonly authService: AuthService,
  ) { }

  @DisplayToaster({showErrorMessage: true})
  public getUserSorting(status: string, docType: string): Observable<UserSorting> {
    const params = {
      userId: this.authService.getUser().id as any,
      type: docType,
      status,
    };
    return this.http.get<ResponseModel<UserSorting>>(this.apiUrl(), { params })
      .pipe(map((response: ResponseModel<UserSorting>) => response.data));
  }

  public saveUserSorting(sorting: UserSorting): Observable<UserSorting> {
    return this.http.post<ResponseModel<UserSorting>>(this.apiUrl(), { ...sorting, userId: this.authService.getUser().id })
      .pipe(map((response: ResponseModel<UserSorting>) => response.data));
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

}
