import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';

import { RnplCommonModule } from '../rnpl-common';
import {
  FiltersMainComponent,
  FiltersControlHeadComponent,
  FiltersControlBaseComponent,
  FiltersControlDeliveryTypeComponent,
  FiltersControlDatePeriodsComponent,
  FiltersControlBaseDropdownComponent,
  FiltersControlPartnersComponent,
  FiltersControlEmployeeComponent,
  FiltersControlProductComponent,
  FiltersControlProductCategoryComponent,
  FiltersControlDropdownObservableStringComponent,
  FiltersControlLinkedDocumentComponent,
  FiltersControlDatesRangeComponent,
  FiltersControlCountryComponent,
  FiltersControlToggleComponent,
  FiltersControlProductTypeComponent,
  FiltersControlBankAccountComponent
} from './components';
import { UIComponentsModule } from '../ui-components/ui-components.module';
import { LoaderWhiteModule } from '../ui-components/loader-white/loader-white.module';
import { SafePipeModule } from 'safe-pipe';
import { UsersCommonModule } from '..';
import { FiltersService } from './filters.service';
import { FiltersGroupComponent } from './components/filters-group/filters-group.component';
import { NumberFormatPipeModule } from 'projects/workspace/src/app/shared/pipes/number-format-pipe/number-format-pipe.module';

@NgModule({
  declarations: [
    FiltersMainComponent,
    FiltersControlHeadComponent,
    FiltersControlBaseComponent,
    FiltersControlDeliveryTypeComponent,
    FiltersControlDatePeriodsComponent,
    FiltersControlBaseDropdownComponent,
    FiltersControlPartnersComponent,
    FiltersControlEmployeeComponent,
    FiltersControlProductComponent,
    FiltersControlProductCategoryComponent,
    FiltersControlProductTypeComponent,
    FiltersControlDropdownObservableStringComponent,
    FiltersControlLinkedDocumentComponent,
    FiltersControlDatesRangeComponent,
    FiltersControlCountryComponent,
    FiltersControlToggleComponent,
    FiltersGroupComponent,
    FiltersControlBankAccountComponent,
  ],
  exports: [
    FiltersMainComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    RnplCommonModule,
    UIComponentsModule,
    LoaderWhiteModule,
    SafePipeModule,
    UsersCommonModule,
    TranslateModule,
    NumberFormatPipeModule
  ],
  providers: [
    FiltersService
  ]
})
export class FiltersModule {
}
