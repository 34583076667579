import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { DocumentInfoTypeEnum, DocumentViewerModel } from '../../document-viewer.model';
import { selectCompanyTimezoneOffset } from 'projects/workspace/src/app/administration/store/selectors';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { PdfSettingsModel } from 'projects/workspace/src/app/pdf-settings/models';
import {
  PdfSettingsAlignEnum,
  PdfSettingsHeaderBlocksEnum, PdfSettingsHeaderInfoBlocksEnum,
  PdfSettingsSizeEnum
} from 'projects/workspace/src/app/pdf-settings/enums';
import { AddressTypeEnum } from 'projects/workspace/src/app/sales-order/enums/address-type.enum';

@Component({
  selector: 'rnpl-document-viewer-head',
  templateUrl: './document-viewer-head.component.html',
  styleUrls: ['./document-viewer-head.component.scss']
})
export class DocumentViewerHeadComponent {

  public DocumentInfoTypeEnum = DocumentInfoTypeEnum;
  public addressTypeEnum = AddressTypeEnum;
  public sizeEnum = PdfSettingsSizeEnum;
  public alignEnum = PdfSettingsAlignEnum;
  public blocksEnum = PdfSettingsHeaderBlocksEnum;
  public headerInfoBlocksEnum = PdfSettingsHeaderInfoBlocksEnum;

  readonly companyProfileTimezoneOffset$: Observable<string> = this.store.select(selectCompanyTimezoneOffset);

  @Input() public isLoading: boolean;
  @Input() public pdfSettings: PdfSettingsModel;
  @Input() public documentPreviewData: DocumentViewerModel;
  @Input() public companyLogo: string;
  @Input() public isCustomCompanyLogo: boolean;
  @Input() public customCompanyLogoUrl: string;
  @Input() public companyName: string;
  // @Input() public companyLogo: string;
  // @Input() public isCustomCompanyLogo: boolean;
  // @Input() public customCompanyLogoUrl: string;
  // @Input() public companyName: string;
  @Input() public hideHeadInfo: boolean;
  @Input() public lang: string;

  @Input()
  public isReadOnly;

  @Input()
  public customerBlockIsValid: boolean = true;

  @Output() selectCustomerEmit: EventEmitter<any> = new EventEmitter();
  @Output() editInfoEmit: EventEmitter<any> = new EventEmitter();

  constructor(
    private readonly store: Store<AppState>,
  ) { }

  public hasInfoItems(infoList: any[]): boolean {
    if (!infoList) { return false; }

    return infoList.some(itm => !!itm.value);
  }

  public selectCustomerAction(): void {
    // if (this.isReadOnly) { return; }
    this.selectCustomerEmit.emit();
  }

  public editInfoAction(): void {
    // if (this.isReadOnly) { return; }
    this.editInfoEmit.emit();
  }

}
