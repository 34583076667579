import { createAction, props } from '@ngrx/store';

import { CostCenterListState } from '../reducers';
import { CostCenterTabsEnum } from '../../enums';
import { CostCenterModel } from '../../models';

export const LoadCostCenterList = createAction(
  '[Cost Center] Load Cost Center list',
  props<{ costCenterListData: CostCenterListState, status: CostCenterTabsEnum }>()
);

export const LoadCostCenter = createAction(
  '[Cost Center] Load Cost Center',
  props<{ costCenter: CostCenterModel }>()
);

export const UpdateCostCenterUpdatedAt = createAction(
  '[Cost Center] Update Cost Center updatedAt',
  props<{ updatedAt: Date }>()
);

export const UpdateShouldRefreshEntity = createAction(
  '[Cost Center] Update Cost Center should refresh flag',
  props<{ isShouldRefresh: boolean }>()
);

export const ClearCostCenterUpdatedAt = createAction('[Cost Center] Clear Cost Center updatedAt');

export const IncrementLoadingRequestsCount = createAction('[Cost Center] Increment Loading Requests Count');

export const DecrementLoadingRequestsCount = createAction('[Cost Center] Decrement Loading Requests Count');
