import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'rnpl-incoming-invoice-ocr-error-modal',
  templateUrl: './incoming-invoice-ocr-error-modal.component.html'
})
export class IncomingInvoiceOcrErrorModalComponent {
  public createPositions = true;
  public separatePositions = false;

  constructor(
    public dialogRef: MatDialogRef<IncomingInvoiceOcrErrorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      hasLinkedPo: boolean,
    }
  ) {
  }

  public submit(matching: boolean): void {
    const params =
      `?confirmed=true&matchDocument=${matching}&createPositions=${this.createPositions}&separatePositions=${this.separatePositions}`;
    this.dialogRef.close({
      params,
      matching: matching
    });
  }

}
