import { Observable } from 'rxjs';

import { DateColumnType, TableColumnModelExtended } from 'common/src/models/table-column.model';
import { NavBarBtnModel } from 'common/src/models';
import {
  HighlightTypeEnum,
  TableSummaryBarItemModel,
  TableSummaryBarItemTypeEnum
} from 'common/src/modules/ui-components/table-summary-bar/table-summary-bar.model';
import { StockStatusTotalsModel } from '../../models/stock-address.model';
import { TableActivateTypes } from 'common/src/modules/ui-components/table/custom-table.enums';
import { TabDefinitionModel } from 'common/src/modules/ui-components/nav-tabs/tab-definition.model';
import { FiltersControlsNamesEnum, FiltersGroupModel } from 'common/src/modules/filters';
import { ProductTypes } from 'common/src/modules/products/product-types';

export function getStockStatusFiltersConfig(
  rackList$: Observable<any[]>,
  shelfList$: Observable<any[]>,
  binList$: Observable<any[]>,
  batchesList$: Observable<any>,
): FiltersGroupModel[] {
  const filtersConfig: FiltersGroupModel[] = [
    {
      disabled: true,
      filters: [
        // {
        //   label: 'COLUMN.PRODUCT_ID',
        //   controlName: 'runpleId',
        //   bindValue: 'runpleId',
        //   controlType: FiltersControlsNamesEnum.LINKED_DOCUMENT,
        //   allowSelectAll: true,
        //   observableList: productsRunpleIdsList$,
        // },
        // {
        //   label: 'COLUMN.PRODUCT_NAME',
        //   controlName: 'name',
        //   bindValue: 'name',
        //   bindLabel: 'name',
        //   controlType: FiltersControlsNamesEnum.LINKED_DOCUMENT,
        //   allowSelectAll: true,
        //   observableList: productsNamesList$,
        // },
        {
          label: 'POSITIONS.PRODUCT',
          controlName: 'id',
          controlType: FiltersControlsNamesEnum.PRODUCT,
          availableProductTypes: [ProductTypes.GOODS]
        },
        {
          label: 'FORM.CATEGORY',
          controlName: 'familyId',
          controlType: FiltersControlsNamesEnum.PRODUCT_CATEGORY,
        },
        {
          label: 'FORM.STOCK_STATUS',
          controlName: 'stockStatus',
          controlType: FiltersControlsNamesEnum.BASE_DROPDOWN,
          controlClass: 'col-6 mw-272',
          placeholder: 'FORM.SELECT_STOCK_STATUS',
          baseDropdownItems: [
            {label: 'TABLE_SIDEBAR.ORDERED', value: 'ordered'},
            {label: 'TABLE_SIDEBAR.ON_STOCK', value: 'on_stock'},
            {label: 'TABLE_SIDEBAR.SOLD', value: 'sold'},
            {label: 'TABLE_SIDEBAR.AVAILABLE', value: 'available'},
            {label: 'COMMON.OVERSALE', value: 'oversale'},
          ],
        },
        {
          label: 'FORM.RACK',
          controlName: 'rack',
          placeholder: 'FORM.RACK',
          containerClass: 'col-4',
          controlType: FiltersControlsNamesEnum.DROPDOWN_OBSERVABLE_STRING,
          observableList: rackList$,
        },
        {
          label: 'FORM.SHELF',
          controlName: 'shelf',
          placeholder: 'FORM.SHELF',
          controlType: FiltersControlsNamesEnum.DROPDOWN_OBSERVABLE_STRING,
          containerClass: 'col-4',
          observableList: shelfList$
        },
        {
          label: 'FILTERS.STORAGE_PLACE',
          controlName: 'bin',
          placeholder: 'FILTERS.STORAGE_PLACE',
          controlType: FiltersControlsNamesEnum.DROPDOWN_OBSERVABLE_STRING,
          containerClass: 'col-4',
          observableList: binList$
        },
        {
          label: 'COLUMN.BATCH',
          controlName: 'batchId',
          bindValue: 'id',
          placeholder: 'FORM.SELECT_BATCH',
          controlType: FiltersControlsNamesEnum.LINKED_DOCUMENT,
          controlClass: 'col-6 mw-272',
          observableList: batchesList$
        },
        {
          label: 'COLUMN.EXPIRATION_DATE',
          controlName: 'expirationDate',
          controlType: FiltersControlsNamesEnum.DATE_PERIODS,
        },
      ]
    },
  ];

  return filtersConfig.map(itm => {
    return {
      ...itm,
      filters: itm.filters.filter(itm => itm.hasOwnProperty('showWithStatus') ? itm.showWithStatus.includes(status) : true)
    }
  });
}

export function StockStatusListColumns(): TableColumnModelExtended[] {
  return [
    {
      cellTemplate: 'numberRows',
      cellClass: 'center cursor-pointer',
      name: 'Nr.',
      width: 40,
      maxWidth: 40,
      sortable: false,
      resizeable: false,
      headerClass: 'center',
      clicktype: TableActivateTypes.RowDetail,
    },
    {
      cellTemplate: 'withLinkPrimary',
      headerTemplate: 'headerPrimary',
      cellClass: 'link-primary',
      name: 'COLUMN.PRODUCT_ID',
      prop: 'productLink',
      sortable: true,
      customizable: true,
      width: 152,
      maxWidth: 152,
    },
    {
      cellTemplate: 'withLinkPrimary',
      headerTemplate: 'headerPrimary',
      cellClass: 'link-primary inline',
      name: 'COLUMN.PRODUCT_NAME',
      preventTranslate: true,
      prop: 'productNameLink',
      sortable: true,
    },
    {
      cellTemplate: 'primaryWithNumber',
      headerTemplate: 'headerWithHints',
      name: 'COLUMN.ORDERED',
      hintsId: 'orderedColumn',
      prop: 'ordered',
      sortable: true,
      cellClass: 'right cursor-pointer',
      headerClass: 'right',
      unitTypeShortcut: true,
      customizable: true,
      width: 120,
      maxWidth: 120,
      clicktype: TableActivateTypes.RowDetail,

    },
    {
      cellTemplate: 'primaryWithNumber',
      headerTemplate: 'headerWithHints',
      name: 'COLUMN.ON_STOCK',
      hintsId: 'onStockColumn',
      prop: 'onStock',
      sortable: true,
      cellClass: 'right cursor-pointer',
      headerClass: 'right',
      unitTypeShortcut: true,
      customizable: true,
      width: 120,
      maxWidth: 120,
      clicktype: TableActivateTypes.RowDetail,
    },
    {
      cellTemplate: 'primaryWithNumber',
      headerTemplate: 'headerWithHints',
      name: 'COLUMN.SOLD',
      hintsId: 'soldColumn',
      prop: 'sold',
      sortable: true,
      cellClass: 'right cursor-pointer',
      headerClass: 'right',
      unitTypeShortcut: true,
      customizable: true,
      width: 120,
      maxWidth: 120,
      clicktype: TableActivateTypes.RowDetail,
    },
    {
      cellTemplate: 'primaryWithNumber',
      headerTemplate: 'headerWithHints',
      name: 'COLUMN.AVAILABLE',
      hintsId: 'availableColumn',
      prop: 'available',
      sortable: true,
      cellClass: 'right cursor-pointer',
      headerClass: 'right',
      unitTypeShortcut: true,
      customizable: true,
      width: 120,
      maxWidth: 120,
      clicktype: TableActivateTypes.RowDetail,
      highlightTypes: [HighlightTypeEnum.POSITIVE_TO_GREEN, HighlightTypeEnum.NEGATIVE_TO_RED],
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.CATEGORY',
      prop: 'categoryPath',
      sortable: true,
      customizable: true,
      cellClass: 'cursor-pointer',
      clicktype: TableActivateTypes.RowDetail,
    },
    {
      cellTemplate: 'withClickableIcon',
      name: '',
      width: 42,
      maxWidth: 42,
      sortable: false,
      resizeable: false,
      clicktype: TableActivateTypes.RowDetail,
      frozenRight: true,
      icon: 'arrow-micro-down',
      cellClass: 'px-4 cursor-pointer'
    }
  ];
}

export function StockStatusDocumentsInnerTableColumns(): TableColumnModelExtended[] {
 return [
   {
     cellTemplate: 'numberRows',
     cellClass: 'center',
     name: 'Nr.',
     width: 38,
     maxWidth: 38,
     sortable: false,
     resizeable: false,
     headerClass: 'center',
   },
   {
     cellTemplate: 'primary',
     headerTemplate: 'headerPrimary',
     name: 'COLUMN.DOCUMENT_TYPE',
     prop: 'typeLabel',
     sortable: true,
     width: 140,
     maxWidth: 140,
   },
   {
     cellTemplate: 'withLinkPrimary',
     headerTemplate: 'headerPrimary',
     name: 'COLUMN.DOCUMENT_ID',
     prop: 'documentLink',
     cellClass: 'link-primary',
     sortable: true,
     width: 160,
     maxWidth: 160,
     // clicktype: TableActivateTypes.Link,
   },
   {
     cellTemplate: 'primary',
     headerTemplate: 'headerPrimary',
     name: 'COLUMN.PARTNER_NAME',
     prop: 'partnerName',
     sortable: false,
   },
   {
     cellTemplate: 'withDate',
     headerTemplate: 'headerPrimary',
     name: 'FORM.ESTIMATED_DELIVERY_DATE',
     prop: 'estimatedDeliveryDate',
     sortable: false,
     width: 183,
     maxWidth: 183,
   },
   {
     cellTemplate: 'primaryWithNumber',
     headerTemplate: 'headerPrimary',
     name: 'COLUMN.QUANTITY',
     prop: 'open',
     unitTypeShortcut: true,
     sortable: false,
     width: 120,
     maxWidth: 120,
   },
 ];
}

export function getStockStatusBinsInnerTableColumns(): TableColumnModelExtended[] {
   return [
     {
       cellTemplate: 'numberRows',
       cellClass: 'center',
       name: 'Nr.',
       width: 38,
       maxWidth: 38,
       sortable: false,
       resizeable: false,
       headerClass: 'center',
     },
     {
       cellTemplate: 'primary',
       headerTemplate: 'headerPrimary',
       name: 'COLUMN.LOCATION_TYPE',
       prop: 'locationType',
       sortable: true,
       width: 119,
       maxWidth: 119,
     },
     {
       cellTemplate: 'primary',
       headerTemplate: 'headerPrimary',
       name: 'COLUMN.BIN_LOCATION',
       prop: 'binLocation',
       sortable: true,
       width: 130,
       maxWidth: 130,
     },
     {
       cellTemplate: 'primary',
       headerTemplate: 'headerPrimary',
       name: 'COLUMN.BATCH',
       prop: 'batch',
       sortable: true,
       width: 160,
       maxWidth: 160,
     },
     {
       cellTemplate: 'withDate',
       headerTemplate: 'headerPrimary',
       name: 'COLUMN.EXPIRATION_DATE',
       prop: 'expirationDate',
       dateType: DateColumnType.WITHOUT_TIME,
       sortable: false,
       width: 175,
       maxWidth: 175,
     },
     {
       cellTemplate: 'primaryWithNumber',
       headerTemplate: 'headerPrimary',
       name: 'COLUMN.STORED',
       prop: 'stored',
       sortable: false,
       unitTypeShortcut: true,
       cellClass: 'right font-weight-bold',
       width: 120,
       maxWidth: 120,
     },
     {
       cellTemplate: 'primaryWithNumber',
       headerTemplate: 'headerPrimary',
       name: 'COLUMN.SOLD',
       prop: 'sold',
       sortable: false,
       unitTypeShortcut: true,
       cellClass: 'right font-weight-bold',
       width: 120,
       maxWidth: 120,
     },
     {
       cellTemplate: 'primaryWithNumber',
       headerTemplate: 'headerPrimary',
       name: 'COLUMN.AVAILABLE',
       prop: 'available',
       sortable: false,
       unitTypeShortcut: true,
       cellClass: 'right font-weight-bold',
       width: 120,
       maxWidth: 120,
     },
     {
       cellTemplate: 'withDate',
       headerTemplate: 'headerPrimary',
       name: 'COLUMN.LAST_ACTIVITY',
       prop: 'lastActivity',
       sortable: false,
     },
   ];
}

export const NavBarButtons: NavBarBtnModel[] = [
  {
    classes: 'btn-white',
    text: 'BUTTON.EXPORT',
    iconName: 'file-csv',
    disabled: false,
    actionName: 'onExportCsvClick',
    multiple: false,
    allowLoadingSpinner: true,
    hintsId: 'exportBtn',
    spinnerColor: '#5E6681',
  },
];

export function getTableSummaryBarItems(totals: StockStatusTotalsModel): TableSummaryBarItemModel[] {
  return [
    {
      label: 'POSITIONS.PRODUCTS',
      titleIcon: 'box',
      type: TableSummaryBarItemTypeEnum.WITH_NUMBER,
      value: totals.products,
      id: 'totalRowsProduct',
    },
    {
      label: 'TABLE_SUMMARY_BAR.STOCK_VALUATION',
      type: TableSummaryBarItemTypeEnum.WITH_CURRENCY,
      currencyConversion: true,
      currencySymbol: '€',
      value: totals.stockValuation,
      id: 'totalRowStockValuation',
    },
    {
      label: 'TABLE_SUMMARY_BAR.EXPIRED',
      type: TableSummaryBarItemTypeEnum.WITH_NUMBER,
      highlightTypes: [HighlightTypeEnum.POSITIVE_TO_ORANGE],
      // value: totals.expired,
      value: totals.expired,
      displayed: !!totals.expired
    },
  ];
}

export const StockStatusListTabs: TabDefinitionModel[] = [
  {
    label: 'TAB.GENERAL_STOCK',
    tabName: 'General stock',
    link: '/warehouse/stock-status/general-stock/1',
    icon: 'box',
    hideCount: true
  },
  {
    label: 'TAB.RETURNS_STOCK',
    tabName: 'Returned stock',
    link: '/warehouse/stock-status/returned-stock/1',
    icon: 'returns',
    hideCount: true
  }
];
