import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { ProductModel, ProductsService } from 'common/src/modules/products';
import { CustomSearchFn, getProductVariationSearchValue } from 'common/src/modules/rnpl-common/helpers';
import { PRODUCTS_TYPE } from 'common/src/modules/rnpl-common/constants/products-type';
import { ProductTypes } from 'common/src/modules/products/product-types';

@Component({
  selector: 'rnpl-product-variations-control',
  templateUrl: './product-variations-control.component.html',
})
export class ProductVariationsControlComponent implements OnDestroy, OnChanges {

  public productVariationsList: ProductModel[] = [];

  public selectedProductType: ProductTypes;
  public productType = PRODUCTS_TYPE;
  public customSearchFn = CustomSearchFn;

  @Input() selectedProduct: ProductModel;
  @Output() fieldChanged: EventEmitter<ProductModel> = new EventEmitter<ProductModel>();

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private readonly productsService: ProductsService,
    private readonly translateService: TranslateService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.hasOwnProperty('selectedProduct') && this.selectedProduct) {
      this.selectedProductType = this.selectedProduct.type || this.selectedProduct.productType;
      if (this.selectedProductType !== 'general') {
        this.getProductVariations();
      }
    }
  }

  public getProductVariations(): void {
    this.productsService.getProductVariations(+this.selectedProduct.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        if (res.products && res.products.length) {
          this.productVariationsList = [this.selectedProduct, ...res.products]
            .map(itm => ({
              ...itm,
              searchLabel: getProductVariationSearchValue(itm, this.translateService),
            }));
        } else {
          this.productVariationsList = [];
        }
      });
  }

  public updateField(fieldValue: ProductModel): void {
    this.fieldChanged.emit(fieldValue);
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
