import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { tap, map, finalize, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { AppState } from '../../store/state/app.state';
import {
  FullyPaidRequestFee,
  IncomingInvoiceModel,
} from '../models/incoming-invoice.model';
import { ResponseModel, ResponseList } from '../../shared/models';
import { CurrencyModel, UIStatesEnum, PaginationModel, PredefinedDocumentDataModel } from 'common/src/models';
import { FilterModelNew } from '../../outgoing-invoice/models/filter-model-new';
import { IncomingInvoiceCountersModel, UpdatePositionsModel } from '../../outgoing-invoice/models';
import { IINPositionsModel, IncomingInvoicePositionModel, IncomingInvoiceListTotalsModel, IinValidateFilesResponseModel } from '../models';
import { ChangeStatusOperationsEnum } from '../../outgoing-invoice/enums';
import { IncomingInvoiceListTabsEnum } from '../enums';
import { EditModel } from '../../outgoing-invoice/models/edit.model';
import { environment } from 'projects/workspace/src/environments/environment';
import { PaymentModel } from '../../payment/models/payment.model';
import {
  LoadIncomingInvoicesList,
  DecrementLoadingRequestsCount,
  IncrementLoadingRequestsCount,
  LoadCurrencies,
  LoadIncomingInvoice,
  UpdateIncomingInvoicePaymentsCount,
  UpdateIncomingInvoicePositionsCount,
  UpdateIncomingInvoiceState,
  UpdateIncomingInvoiceSummary,
  UpdateIncomingInvoiceUpdatedAt,
  UpdateShouldRefreshEntity,
  LoadIncomingInvoicePositions
} from '../store/actions/incoming-invoice.actions';
import { FileUploadParams } from 'common/src/models/file-upload-params.model';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { DisplayToaster } from '../../shared/decorators/toaster';
import { CommonModalsActionsEnum, WarningModalComponent } from 'common/src/modules/modals/modals-common';
import { getAnotherUserEditErrorModalData } from 'common/src/modules/modals/modals-common/common-modal.config';
import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { PurchaseOrder } from '../../purchase-order/models';
import { ProductModel } from 'common/src/modules/products';
import { OcrDataModel } from '../../shared/components/ocr-preview/models';
import { OcrFieldCorrectionModel, OcrPositionCorrectionModel } from '../../shared/components/ocr-preview/models';
import { selectCompanyProfile } from '../../administration/store/selectors';
import { CompanyProfile } from '../../administration/models/company-profile.model';

@Injectable({
  providedIn: 'root',
})
export class IncomingInvoiceApiService {
  private wid: number;
  private readonly apiEndpoint: string = `${environment.javaApiVersion}/invoices/incoming`;

  constructor(
    private readonly http: HttpClient,
    private readonly dialog: MatDialog,
    private readonly toasterService: ToasterService,
    private readonly translateService: TranslateService,
    private readonly store: Store<AppState>
  ) {
    this.store.select(selectCompanyProfile)
      .subscribe((profile: CompanyProfile) => {
        this.wid = profile.workspaceId;
      });
  }

  public createIncomingInvoicePredefined(params?: PredefinedDocumentDataModel): Observable<IncomingInvoiceModel> {
    return this.http
      .post<ResponseModel<IncomingInvoiceModel>>(`${this.apiEndpoint}/create-predefined`, params)
      .pipe(
        map((data: ResponseModel<IncomingInvoiceModel>) => data.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public createIncomingInvoiceBlank(): Observable<IncomingInvoiceModel> {
    return this.http
      .post<ResponseModel<IncomingInvoiceModel>>(`${this.apiEndpoint}/blank`, {})
      .pipe(
        map((data: ResponseModel<IncomingInvoiceModel>) => data.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public updateIncomingInvoice(invoiceId: number, invoice: IncomingInvoiceModel): Observable<IncomingInvoiceModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<IncomingInvoiceModel>>(`${this.apiEndpoint}/${invoiceId}`, invoice).pipe(
      tap((response: ResponseModel<IncomingInvoiceModel>) => {
        this.updateInvoiceDataAndSummary(response);
        this.store.dispatch(UpdateIncomingInvoiceUpdatedAt({ updatedAt: new Date() }));
      }),
      map((response: ResponseModel<IncomingInvoiceModel>) => response.data),
      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
      catchError(error => {
        this.handlePopupErrors(error, invoiceId);
        return throwError(error);
      })
    );
  }

  public updateIncomingInvoiceField(invoiceId: number, fieldName: string, fieldValue: any): Observable<IncomingInvoiceModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    const body = { fieldName, fieldValue };

    return this.http.patch<ResponseModel<IncomingInvoiceModel>>(`${this.apiEndpoint}/${invoiceId}/field-update`, body).pipe(
      tap((response: ResponseModel<IncomingInvoiceModel>) => {
        this.updateInvoiceDataAndSummary(response);
        this.store.dispatch(UpdateIncomingInvoiceUpdatedAt({ updatedAt: new Date() }));
      }),
      map((response: ResponseModel<IncomingInvoiceModel>) => response.data),
      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
      catchError(error => {
        this.handlePopupErrors(error, invoiceId);
        return throwError(error);
      })
    );
  }

  public getIncomingInvoiceList(
    status: IncomingInvoiceListTabsEnum,
    pagination: PaginationModel,
    sort: FilterModelNew,
    filters: any = {}
  ): Observable<ResponseList<IncomingInvoiceModel>> {

    const params = {
      status: status.toUpperCase(),
      page: pagination.page,
      sortBy: sort.sortBy,
      direction: sort.direction,
      length: pagination.per_page,
    };

    for (const [key, value] of Object.entries(filters)) {
      params[key] = Array.isArray(value) ? value.join(',') : value.toString();
    }

    return this.http.get<ResponseList<IncomingInvoiceModel>>(`${this.apiEndpoint}`, { params })
      .pipe(tap((data: ResponseList<IncomingInvoiceModel>) => {
        this.store.dispatch(LoadIncomingInvoicesList({
          incomingInvoiceListData: {
            [data.pagination.page]: {
              pagination: data.pagination,
              sort,
              data: data.data
            }
          },
          status, page: data.pagination.page
        }));
      }));
  }

  getIncomingInvoicesFilterRunpleIds(wid: number): Observable<any[]> {
    const params = {
      wid: wid.toString()
    };
    return this.http.get<ResponseModel<any[]>>(`${this.apiEndpoint}/filter/rids`, { params })
      .pipe(map((response: ResponseModel<any[]>) => response.data));
  }

  getIncomingInvoicesFilterCostCenters(wid: number, status: IncomingInvoiceListTabsEnum): Observable<{ key: number; value: string }[]> {
    const params = {
      wid: wid.toString()
    };
    return this.http.get<ResponseModel<{ key: number; value: string }[]>>(`${this.apiEndpoint}/filter/cost-center/${status}`, { params })
      .pipe(map((response: ResponseModel<{ key: number; value: string }[]>) => response.data));
  }

  getIncomingInvoicesFilterGeneralProducts(status: IncomingInvoiceListTabsEnum): Observable<any[]> {
    return this.http.get<ResponseModel<any[]>>(`${this.apiEndpoint}/filter/general-products/${status}`)
      .pipe(map((response: ResponseModel<any[]>) => response.data));
  }

  // public getIncomingInvoiceLinkedDocuments(invoiceId: number): Observable<LinkedDocumentResponseModel> {
  //   return this.http
  //     .get<ResponseModel<LinkedDocumentResponseModel>>(`${this.apiEndpoint}/${invoiceId}/linked`)
  //     .pipe(map((data: ResponseModel<LinkedDocumentResponseModel>) => data.data));
  // }
  //
  // public getIncomingInvoiceLinkedDocumentsTotals(invoiceId: number): Observable<LinkedDocumentsTotals> {
  //   return this.http
  //     .get<ResponseModel<LinkedDocumentsTotals>>(`${this.apiEndpoint}/${invoiceId}/linked/totals`)
  //     .pipe(map((data: ResponseModel<LinkedDocumentsTotals>) => data.data));
  // }

  public getIncomingInvoiceListTotals(status: IncomingInvoiceListTabsEnum, filters: any = {}): Observable<IncomingInvoiceListTotalsModel> {
    const params = {};
    for (const [key, value] of Object.entries(filters)) {
      params[key] = Array.isArray(value) ? value.join(',') : value.toString();
    }
    return this.http
      .get<ResponseModel<IncomingInvoiceListTotalsModel>>(`${this.apiEndpoint}/totals/${status}`, { params })
      .pipe(map((data: ResponseModel<IncomingInvoiceListTotalsModel>) => data.data));
  }

  public getIncomingInvoiceCounters(): Observable<IncomingInvoiceCountersModel> {
    return this.http
      .get<ResponseModel<IncomingInvoiceCountersModel>>(`${this.apiEndpoint}/count/by-status`)
      .pipe(map((data: ResponseModel<IncomingInvoiceCountersModel>) => data.data));
  }

  public getIncomingInvoicePositions(invoiceId: number): Observable<IINPositionsModel> {
    return this.http
      .get<ResponseModel<IINPositionsModel>>(`${this.apiEndpoint}/${invoiceId}/positions`)
      .pipe(
        map((data: ResponseModel<IINPositionsModel>) => data.data),
        tap((positions: IINPositionsModel) => {
          this.store.dispatch(LoadIncomingInvoicePositions({positions: positions}))
        })
      );
  }

  // public getIncomingInvoicePositionsTotals(invoiceId: number): Observable<PositionsTotals> {
  //   return this.http
  //     .get<ResponseModel<PositionsTotals>>(`${this.apiEndpoint}/${invoiceId}/positions/totals`)
  //     .pipe(map((data: ResponseModel<PositionsTotals>) => data.data));
  // }

  // public createIncomingInvoicePosition(
  //   invoiceId: number,
  //   position: Partial<IncomingInvoicePositionModel>,
  // ): Observable<IINPositionsModel> {
  //   this.store.dispatch(IncrementLoadingRequestsCount());
  //
  //   return this.http.post<ResponseModel<IINPositionsModel>>(`${this.apiEndpoint}/${invoiceId}/positions`, position).pipe(
  //     tap((response: ResponseModel<IINPositionsModel>) => {
  //       this.store.dispatch(UpdateIncomingInvoiceUpdatedAt({ updatedAt: new Date() }));
  //     }),
  //     map((data: ResponseModel<IINPositionsModel>) => data.data),
  //     finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
  //     catchError(error => {
  //       this.handlePopupErrors(error, invoiceId);
  //       return throwError(error);
  //     })
  //   );
  // }

  public createPositionByPredefinedForm(
    invoiceId: number,
    position: Partial<IncomingInvoicePositionModel>,
  ): Observable<IINPositionsModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.post<ResponseModel<IINPositionsModel>>(`${this.apiEndpoint}/${invoiceId}/positions-predefined`, position).pipe(
      tap(() => {
        this.store.dispatch(UpdateIncomingInvoiceUpdatedAt({ updatedAt: new Date() }));
      }),
      map((data: ResponseModel<IINPositionsModel>) => data.data),
      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
      catchError(error => {
        this.handlePopupErrors(error, invoiceId);
        return throwError(error);
      })
    );
  }

  public createCorrectionPositions(
    invoiceId: number,
    body: { targetAmountNet: number; targetVat: number; targetAmountGross: number; },
    forceComplete = false
  ): Observable<IINPositionsModel> {
    const params = { forceComplete: forceComplete as any };
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.post<ResponseModel<IINPositionsModel>>(`${this.apiEndpoint}/${invoiceId}/positions/correction`, body, { params })
      .pipe(
        tap(() => this.store.dispatch(UpdateIncomingInvoiceUpdatedAt({ updatedAt: new Date() }))),
        map((data: ResponseModel<IINPositionsModel>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, invoiceId);
          return throwError(error);
        })
      );
  }

  public updateIncomingInvoicePosition(invoiceId: number, updatePosition: UpdatePositionsModel): Observable<IncomingInvoicePositionModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http
      .patch<ResponseModel<IncomingInvoicePositionModel>>(
        `${this.apiEndpoint}/${invoiceId}/positions/${updatePosition.positionId}`,
        updatePosition
      )
      .pipe(
        tap(() => {
          this.store.dispatch(UpdateIncomingInvoiceUpdatedAt({ updatedAt: new Date() }));
        }),
        map((data: ResponseModel<IncomingInvoicePositionModel>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, invoiceId);
          return throwError(error);
        })
      );
  }

  // public addCorrectionPosition(invoiceId: number, positionId: number): Observable<IncomingInvoicePositionModel> {
  //   return this.http.request<ResponseModel<IncomingInvoicePositionModel>>('post', `${this.apiEndpoint}/${invoiceId}/positions/${positionId}/correction`)
  //     .pipe(
  //       map((data: ResponseModel<IncomingInvoicePositionModel>) => data.data),
  //       catchError(error => {
  //         this.handlePopupErrors(error, invoiceId);
  //         return throwError(error);
  //       })
  //     );
  // }

  public deleteIncomingInvoicePositions(invoiceId: number, ids: number[], confirmed = false): Observable<IINPositionsModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());
    const body = { ids };
    const params = { confirmed: confirmed as any };

    return this.http
      .request<ResponseModel<IINPositionsModel>>('delete', `${this.apiEndpoint}/${invoiceId}/positions`, { body, params })
      .pipe(
        tap(() => {
          this.store.dispatch(UpdateIncomingInvoiceUpdatedAt({ updatedAt: new Date() }));
        }),
        map((data: ResponseModel<IINPositionsModel>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, invoiceId, { ids });
          return throwError(error);
        })
      );
  }

  changePositionOrder(posId: number, moveToOrder: number, invoiceId: number): any {
    const body = {
      orderPosition: moveToOrder,
      id: posId,
    }
    return this.http.patch(`${this.apiEndpoint}/${invoiceId}/positions/${posId}/order/${moveToOrder}`, body)
      .pipe(
        map((data: ResponseModel<PurchaseOrder>) => data.data),
        catchError(error => {
          this.showMsg('error', error.error.message);
          return throwError(error);
        })
      );
  }

  public getPositionsCount(invoiceId: number): Observable<number> {
    return this.http.get(`${this.apiEndpoint}/${invoiceId}/positions/count`).pipe(
      tap((data: ResponseModel<number>) => {
        this.store.dispatch(UpdateIncomingInvoicePositionsCount({ positionsCount: data.data }));
      }),
      map((data: ResponseModel<number>) => data.data)
    );
  }

  public changeIncomingInvoicesStatus(
    ids: number[],
    action: ChangeStatusOperationsEnum,
    status: IncomingInvoiceListTabsEnum,
    proceed = false
  ): Observable<any> {
    const params = new HttpParams()
      .set('proceed', proceed.toString());

    this.store.dispatch(IncrementLoadingRequestsCount());

    const body = { ids, action, status };

    return this.http.patch<ResponseModel<IncomingInvoiceModel>>(`${this.apiEndpoint}/batch`, body, { params }).pipe(
      tap(() => {
        this.store.dispatch(UpdateIncomingInvoiceUpdatedAt({ updatedAt: new Date() }));
      }),
      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
    );
  }

  public getIncomingInvoiceById(id: string | number): Observable<IncomingInvoiceModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.get<ResponseModel<IncomingInvoiceModel>>(`${this.apiEndpoint}/${id}`).pipe(
      tap((response: ResponseModel<IncomingInvoiceModel>) => {
        this.updateInvoiceDataAndSummary(response);
        this.store.dispatch(UpdateIncomingInvoiceUpdatedAt({ updatedAt: new Date() }));
        this.store.dispatch(UpdateShouldRefreshEntity({ isShouldRefresh: false }));
      }),
      map((response: ResponseModel<IncomingInvoiceModel>) => response.data),
      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
    );
  }

  public getIncomingInvoiceOCR(iinId: string | number): Observable<OcrDataModel> {
    return this.http.get<OcrDataModel>(`${this.apiEndpoint}/${iinId}/ocr`);
  }

  public incomingInvoiceOCRCorrection(iinId: string | number, correctionData: OcrFieldCorrectionModel): Observable<IncomingInvoiceModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.post<ResponseModel<IncomingInvoiceModel>>(`${this.apiEndpoint}/${iinId}/ocr/correction`, {...correctionData})
      .pipe(
        tap((response: ResponseModel<IncomingInvoiceModel>) => {
          this.updateInvoiceDataAndSummary(response);
          this.store.dispatch(UpdateIncomingInvoiceUpdatedAt({ updatedAt: new Date() }));
          this.store.dispatch(UpdateShouldRefreshEntity({ isShouldRefresh: false }));
        }),
        map((response: ResponseModel<IncomingInvoiceModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public incomingInvoiceParseFile(iinId: string | number, fileId: number, createPositions: boolean, separatePositions: boolean): Observable<IncomingInvoiceModel> {
    const params = new HttpParams()
      .set('createPositions', createPositions.toString())
      .set('separatePositions', separatePositions.toString());

    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.post<ResponseModel<IncomingInvoiceModel>>(`${this.apiEndpoint}/${iinId}/files/${fileId}`, {}, { params })
      .pipe(
        tap((response: ResponseModel<IncomingInvoiceModel>) => {
          this.updateInvoiceDataAndSummary(response);
          this.store.dispatch(UpdateIncomingInvoiceUpdatedAt({ updatedAt: new Date() }));
          this.store.dispatch(UpdateShouldRefreshEntity({ isShouldRefresh: false }));
        }),
        map((response: ResponseModel<IncomingInvoiceModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }


  public incomingInvoiceOCRPositionsCorrection(iinId: string | number, correctionData: OcrPositionCorrectionModel): Observable<IINPositionsModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.post<ResponseModel<IINPositionsModel>>(
      `${this.apiEndpoint}/${iinId}/positions/ocr/correction`,
      {...correctionData},
      { params: { force: true.toString()}}
    ) // todo: force
      .pipe(
        tap(() => {
          this.store.dispatch(UpdateIncomingInvoiceUpdatedAt({ updatedAt: new Date() }));
          this.store.dispatch(UpdateShouldRefreshEntity({ isShouldRefresh: false }));
        }),
        map((response: ResponseModel<IINPositionsModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getPaymentsCount(invoiceId: number): Observable<number> {
    return this.http.get(`${this.apiEndpoint}/${invoiceId}/payments/count`).pipe(
      tap((data: ResponseModel<number>) => {
        this.store.dispatch(UpdateIncomingInvoicePaymentsCount({ paymentsCount: data.data }));
      }),
      map((data: ResponseModel<number>) => data.data)
    );
  }

  // public getIncomingInvoicePayments(invoiceId: string | number): Observable<ResponseList<IncomingInvoicePaymentModel>> {
  //   return this.http.get<ResponseList<IncomingInvoicePaymentModel>>(`${this.apiEndpoint}/${invoiceId}/payments`);
  // }

  createInvoiceFromPO(poId: number): Observable<any> {
    return this.http.request('patch', `${this.apiEndpoint}/based-on/${poId}`).pipe(map((response: ResponseModel<any>) => response.data));
  }

  // public getIncomingInvoiceSummary(id: number): Observable<Summary> {
  //   return this.http.get<ResponseModel<Summary>>(`${this.apiEndpoint}/${id}/summary`).pipe(
  //     tap((response: ResponseModel<Summary>) => {
  //       this.store.dispatch(UpdateIncomingInvoiceSummary({ summary: response.data }));
  //     }),
  //     map((response: ResponseModel<Summary>) => response.data)
  //   );
  // }

  public getCurrencies(): Observable<CurrencyModel[]> {
    return this.http.get<ResponseModel<CurrencyModel[]>>(`${environment.javaApiVersion}/utils/currencies`).pipe(
      tap((response: ResponseModel<CurrencyModel[]>) => {
        this.store.dispatch(LoadCurrencies({ currencies: response.data }));
      }),
      map((response: ResponseModel<CurrencyModel[]>) => response.data)
    );
  }

  public createOrUpdateIncomingInvoiceFee(
    invoiceId: number,
    fee?: FullyPaidRequestFee
  ): Observable<IncomingInvoiceModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());
    //Creates fee if no FeeId specified or updates fee otherwise
    const body: FullyPaidRequestFee = { ...fee };

    return this.http.put<ResponseModel<IncomingInvoiceModel>>(`${this.apiEndpoint}/${invoiceId}/fee`, body).pipe(
      tap((response: ResponseModel<IncomingInvoiceModel>) => {
        this.updateInvoiceDataAndSummary(response);
        this.store.dispatch(UpdateIncomingInvoiceUpdatedAt({ updatedAt: new Date() }));
      }),
      map((response: ResponseModel<IncomingInvoiceModel>) => response.data),
      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
      catchError(error => {
        this.handlePopupErrors(error, invoiceId);
        return throwError(error);
      })
    );
  }

  public deleteIncomingInvoiceFee(invoiceId: number, fee: FullyPaidRequestFee): Observable<IncomingInvoiceModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());
    const body: FullyPaidRequestFee = { ...fee };

    return this.http
      .request<ResponseModel<IncomingInvoiceModel>>('delete', `${this.apiEndpoint}/${invoiceId}/fee`, { body })
      .pipe(
        tap((response: ResponseModel<IncomingInvoiceModel>) => {
          this.updateInvoiceDataAndSummary(response);
          this.store.dispatch(UpdateIncomingInvoiceUpdatedAt({ updatedAt: new Date() }));
        }),
        map((response: ResponseModel<IncomingInvoiceModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, invoiceId);
          return throwError(error);
        })
      );
  }

  public startIncomingInvoiceEditing(iinId: number, force = false, proceed = false): Observable<EditModel> {
    const params = new HttpParams()
      .set('force', force.toString())
      .set('proceed', proceed.toString());

    return this.http.get<EditModel>(`${environment.javaApiVersion}/utils/edit-flag/${DocumentTypesUppercaseEnum.IIN}/${iinId}/check-and-set`, {params})
      .pipe(
        tap(() => {
          this.store.dispatch(UpdateIncomingInvoiceState({ currentState: UIStatesEnum.EDIT }));
        }),
        catchError(error => {
          this.handlePopupErrors(error, iinId);
          return throwError(error);
        })
      );
  }

  public finishIncomingInvoiceEditing(iinId: number, forced = false): Observable<EditModel> {
    const params = new HttpParams()
      .set('forced', forced.toString());
    return this.http.get<EditModel>(`${environment.javaApiVersion}/utils/edit-flag/${DocumentTypesUppercaseEnum.IIN}/${iinId}/commit`, { params })
      .pipe(
        tap(() => {
          this.store.dispatch(UpdateIncomingInvoiceState({ currentState: UIStatesEnum.VIEW }));
        })
      );
  }

  public deleteFile(invoiceId: number | string, fileId: string|number): Observable<IncomingInvoiceModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.delete<ResponseModel<IncomingInvoiceModel>>(`${this.apiEndpoint}/${invoiceId}/files/${fileId}`).pipe(
      tap((response: ResponseModel<IncomingInvoiceModel>) => {
        this.updateInvoiceDataAndSummary(response);
        this.store.dispatch(UpdateIncomingInvoiceUpdatedAt({ updatedAt: new Date() }));
      }),
      map((response: ResponseModel<IncomingInvoiceModel>) => response.data),
      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
      catchError(error => {
        this.handlePopupErrors(error, invoiceId);
        return throwError(error);
      })
    );
  }

  public getUploadPdfLink(id: string | number): string {
    return `${environment.javaApiUrl}${environment.javaApiVersion}/invoices/incoming/${id}/files/upload`;
  }

  @DisplayToaster({ showErrorMessage: true })
  public validateInvoicesFiles(files: File[]): Observable<IinValidateFilesResponseModel[]> {
    const formData = new FormData();
    files.forEach((file: File) => formData.append('images', file));

    return this.http.post(`${this.apiEndpoint}/files/validate`, formData)
      .pipe(map((response: ResponseModel<IinValidateFilesResponseModel[]>) => response.data));
  }

  @DisplayToaster({ showErrorMessage: true })
  public uploadMultipleInvoices(files: File[], matchDocument: boolean, createPositions: boolean,  separatePositions: boolean, confirmed = false): Observable<{name: string; success: boolean}[]> {
    const params = {
      matchDocument: matchDocument.toString(),
      createPositions: createPositions.toString(),
      separatePositions: separatePositions.toString(),
      confirmed: confirmed.toString(),
      wid: this.wid.toString(),
    };
    const formData = new FormData();
    files.forEach((file: File) => formData.append('images', file));

    return this.http.post(`${this.apiEndpoint}/files/upload`, formData, {params})
      .pipe(map((response: ResponseModel<{name: string; success: boolean}[]>) => response.data));
  }

  @DisplayToaster({ showErrorMessage: true })
  public linkIncomingInvoiceWithPurchaseOrder(invoiceId: number, poId: number): Observable<IncomingInvoiceModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<IncomingInvoiceModel>>('patch', `${this.apiEndpoint}/${invoiceId}/link/${poId}`).pipe(
      tap((response: ResponseModel<IncomingInvoiceModel>) => {
        this.updateInvoiceDataAndSummary(response);
        this.store.dispatch(
          UpdateIncomingInvoicePositionsCount({
            positionsCount: response.data.positionsTotals.positionsCount,
          })
        );
        this.store.dispatch(UpdateIncomingInvoiceUpdatedAt({ updatedAt: new Date() }));
      }),
      map((response: ResponseModel<IncomingInvoiceModel>) => response.data),
      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
    );
  }

  public getAvailablePositionsFromPO(invoiceId: number, wid: number): Observable<ProductModel[]> {
    const params = {
      wid: wid.toString()
    };
    return this.http.get<ResponseModel<ProductModel[]>>(`${this.apiEndpoint}/${invoiceId}/products/available`, { params })
      .pipe(
        map((data: ResponseModel<ProductModel[]>) => data.data),
      );
  }

  public proceedIncomingInvoice(invoiceId: number, forced = false): Observable<IncomingInvoiceModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());
    const params = new HttpParams()
      .set('forced', forced.toString());

    return this.http.request<ResponseModel<IncomingInvoiceModel>>('post', `${this.apiEndpoint}/${invoiceId}/proceed`, {params})
      .pipe(
        tap((response: ResponseModel<IncomingInvoiceModel>) => {
          this.store.dispatch(UpdateIncomingInvoiceUpdatedAt({ updatedAt: new Date() }));
          this.store.dispatch(UpdateIncomingInvoiceState({ currentState: UIStatesEnum.VIEW }));
          this.updateInvoiceDataAndSummary(response);
        }),
        map((response: ResponseModel<IncomingInvoiceModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  public forceCompleteIncomingInvoice(invoiceId: number, option: 'SKONTO_DEDUCTION'|'DISCOUNT_DEDUCTION'|'ROUNDING_DIFFERENCE'): Observable<IncomingInvoiceModel> {
    const params = { option };
    return this.http.request<ResponseModel<IncomingInvoiceModel>>('patch', `${this.apiEndpoint}/${invoiceId}/force`, {params})
      .pipe(
        tap((response: ResponseModel<IncomingInvoiceModel>) => {
          this.updateInvoiceDataAndSummary(response);
        }),
        map((response: ResponseModel<IncomingInvoiceModel>) => response.data)
      );
  }

  public cancelForceCompleteIncomingInvoice(invoiceId: number): Observable<IncomingInvoiceModel> {
    return this.http.request<ResponseModel<IncomingInvoiceModel>>('patch', `${this.apiEndpoint}/${invoiceId}/cancel-force`)
      .pipe(
        tap((response: ResponseModel<IncomingInvoiceModel>) => {
          this.updateInvoiceDataAndSummary(response);
        }),
        map((response: ResponseModel<IncomingInvoiceModel>) => response.data)
      );
  }

  public incomingInvoiceApplyAction(
    invoiceId: number,
    action: ChangeStatusOperationsEnum,
    proceed = false,
    forced = false
  ): Observable<IncomingInvoiceModel> {
    const params = new HttpParams()
      .set('proceed', proceed.toString())
      .set('forced', forced.toString());

    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<IncomingInvoiceModel>>('post', `${this.apiEndpoint}/${invoiceId}/${action}`, { params }).pipe(
      tap((response: ResponseModel<IncomingInvoiceModel>) => {
        this.updateInvoiceDataAndSummary(response);
        this.store.dispatch(UpdateIncomingInvoiceUpdatedAt({ updatedAt: new Date() }));
      }),
      map((response: ResponseModel<IncomingInvoiceModel>) => response.data),
      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
    );
  }

  @DisplayToaster({ showErrorMessage: true })
  public bookPaymentBaseOnIncomingInvoice(incomingInvoiceId: number): Observable<PaymentModel> {
    const params = {
      type: 'IIN',
      id: String(incomingInvoiceId),
    };
    return this.http
      .request<ResponseModel<PaymentModel>>('post', `${environment.javaApiVersion}/payments/based-on`, { params })
      .pipe(map((response: ResponseModel<PaymentModel>) => response.data));
  }

  // @DisplayToaster({ showErrorMessage: true })
  // public allocatePayment(incomingInvoiceId: number): Observable<PaymentModel> {
  //   return this.http
  //     .request<ResponseModel<PaymentModel>>('post', `${this.apiEndpoint}/${incomingInvoiceId}/allocate-payment`)
  //     .pipe(map((response: ResponseModel<PaymentModel>) => response.data));
  // }

  private updateInvoiceDataAndSummary(response: ResponseModel<IncomingInvoiceModel>): void {
    this.store.dispatch(
      LoadIncomingInvoice({
        incomingInvoice: {
          ...response.data,
          // summary: {} as Summary,
        },
      })
    );
    this.store.dispatch(UpdateIncomingInvoiceSummary({ summary: response.data.summary }));
  }

  public reCheckVatStatus(invoiceId: number): Observable<IncomingInvoiceModel> {
    return this.http.request<ResponseModel<IncomingInvoiceModel>>('post', `${this.apiEndpoint}/${invoiceId}/vat-status`)
      .pipe(
        tap((response: ResponseModel<IncomingInvoiceModel>) => {
          this.updateInvoiceDataAndSummary(response);
        }),
        map((data: ResponseModel<IncomingInvoiceModel>) => data.data),
      );
  }

  getIncomingInvoiceFilterIBANs(): Observable<string[]> {
    return this.http.get<ResponseModel<string[]>>(`${this.apiEndpoint}/filter/ibans`)
      .pipe(
        map((response: ResponseModel<string[]>) => {
          return response.data
            .filter(itm => !!itm)
            .sort((a, b) => a.localeCompare(b));
        })
      );
  }

  getIncomingInvoiceFilterExternalNumbers(): Observable<string[]> {
    return this.http.get<ResponseModel<string[]>>(`${this.apiEndpoint}/filter/extnumbers`)
      .pipe(
        map((response: ResponseModel<string[]>) => {
          return response.data
            .filter(itm => !!itm)
            .sort((a, b) => a.localeCompare(b));
        })
      );
  }

  public getIncomingInvoiceListExport(status: string): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: `${this.apiEndpoint}/download?status=${status}`,
      type: 'zip',
    };
    return of(fileParams);
  }

  public getIncomingInvoiceListExportAll(): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: `${this.apiEndpoint}/download/all`,
      type: 'zip',
    };
    return of(fileParams);
  }

  public downloadIncomingInvoiceXMLFile(ids: number[]): Observable<Partial<FileUploadParams>> {
    const fileParams: Partial<FileUploadParams> = {
      url: `${this.apiEndpoint}/xml?ids=${ids.join(',')}`,
    };
    return of(fileParams);
  }

  public downloadIncomingInvoiceImgFile(incomingInvoiceId: number, imageId: number, title: string): Observable<Partial<FileUploadParams>> {
    const fileParams: Partial<FileUploadParams> = {
      url: `${this.apiEndpoint}/${incomingInvoiceId}/files/${imageId}`,
      title,
    };
    return of(fileParams);
  }

  @DisplayToaster({ showErrorMessage: true })
  public linkPayment(invoiceId: number, paymentId: number): Observable<any[]> {
    return this.http.get<ResponseModel<any[]>>(`${this.apiEndpoint}/${invoiceId}/payments/${paymentId}/link`)
      .pipe(
        map((data: ResponseModel<any[]>) => data.data)
      );
  }

  public getPaymentsAvailable(invoiceId: number): Observable<PaymentModel[]> {
    return this.http.get<ResponseModel<PaymentModel[]>>(`${this.apiEndpoint}/${invoiceId}/payments/available`)
      .pipe(
        map((data: ResponseModel<PaymentModel[]>) => data.data)
      );
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  private handlePopupErrors(error: HttpErrorResponse, iinId?: string | number, params?: {ids?: number[]}): void {
    if (error.error.errors && error.error.errors.length) {
      error.error.errors.forEach(errorText => {
        switch (errorText) {
          case 'anotherUserEditError': {
            const dialog = this.dialog.open(WarningModalComponent, {
              data: getAnotherUserEditErrorModalData(
                {
                  document: error.error.data.entityName,
                  userName: error.error.data.userName,
                },
                this.translateService
              ),
              backdropClass: 'backdrop-none',
            });

            dialog.afterClosed().subscribe(res => {
              if (res === CommonModalsActionsEnum.CONFIRM) {
                this.startIncomingInvoiceEditing(+iinId, true).subscribe();
                this.getIncomingInvoiceById(iinId).subscribe();
              }
            });
          }
            break;
          case 'confirmationAfterPositionDeleteIsRequired':
            {
              const dialog = this.dialog.open(WarningModalComponent, {
                data: {
                  title: 'POSITIONS.REMOVE_POSITION',
                  message: 'POSITIONS.REMOVE_POSITION_IIN_MSG',
                  confirmBtnText: 'BUTTON.CONTINUE',
                  confirmBtnIcon: 'trash-2'
                }
              });

              dialog.afterClosed().subscribe(res => {
                if (res === CommonModalsActionsEnum.CONFIRM) {
                  this.deleteIncomingInvoicePositions(+iinId, params.ids, true)
                    .pipe(
                      tap(() => this.getIncomingInvoiceById(iinId).subscribe())
                    )
                    .subscribe();
                }
              });
            }
            break;
          case 'The document has been sent to the accountant, if you edit it, you will need to re-send the document to the accountant. Proceed?':
          {
            const dialog = this.dialog.open(WarningModalComponent, {
              data: {
                title: 'COMMON.DOC_SENT_TO_ACCOUNTANT',
                message: 'COMMON.DOC_SENT_TO_ACCOUNTANT_MSG',
                confirmBtnText: 'BUTTON.EDIT',
                confirmBtnIcon: 'edit'
              }
            });

            dialog.afterClosed().subscribe(res => {
              if (res === CommonModalsActionsEnum.CONFIRM) {
                this.startIncomingInvoiceEditing(+iinId, false, true).subscribe();
                this.getIncomingInvoiceById(iinId).subscribe();
              }
            });
          }
          break;
          case 'notEditModeError':
            const documentName = this.translateService.instant('APP.INCOMING_INVOICE');
            this.showMsg('warning', this.translateService.instant('COMMON.DOC_UPDATED_BY_USER', { document: documentName}));
            this.store.dispatch(UpdateIncomingInvoiceState({currentState: UIStatesEnum.VIEW}));
            this.getIncomingInvoiceById(iinId).subscribe();
            break;
          default:
            this.showMsg('error', errorText);
            break
        }
      });
    }
  }


}
