import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, tap, finalize, catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';

import { FilterModel } from '../models/filter.model';
import { ResponseList, ResponseModel } from '../../shared/models/response';
import { BufferTypes } from '../enums/buffer-types';
import { WarehouseTypes } from '../enums/warehouse-types';
import { StationTypes } from '../enums/station-types';
import { AddressModel, UIStatesEnum } from 'common/src/models';
import { AppState } from '../../store/state/app.state';
import {
  IncrementLoadingRequestsCount,
  DecrementLoadingRequestsCount,
  UpdateWarehouseUpdatedAt,
  UpdateCurrentWarehouse,
  UpdateWarehouseCurrentState
} from '../store/actions/warehouse.actions';
import { WarehouseResponse } from '../models/warehouse-response.model';
import { FormInputChangedModel } from '../../shared/models/form-input-value.model';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { StockStatusBinsModel, StockStatusDocumentModel, StockStatusModel } from '../models/stock-address.model';
import { LoadStockStatusList } from '../store/actions/stock-status.actions';
import { CommonModalsActionsEnum, WarningModalComponent } from 'common/src/modules/modals/modals-common';
import { getAnotherUserEditErrorModalData } from 'common/src/modules/modals/modals-common/common-modal.config';
import { DEFAULT_SORT_DIRECTION } from '../../shared/constants';


@Injectable({
  providedIn: 'root'
})
export class StockAddressesService {

  constructor(
    private http: HttpClient,
    private readonly dialog: MatDialog,
    private readonly toasterService: ToasterService,
    private readonly translateService: TranslateService,
    private readonly store: Store<AppState>,
  ) {}


  public getWarehouseList(
    filter: FilterModel = {nameColumn: 'status', value: 'active'},
    page = '1',
    per_page = '100',
    sort: FilterModel = {nameColumn: 'status', value: DEFAULT_SORT_DIRECTION}
    ): Observable<any> {
    return this.http.get('/warehouse/structure/warehouses', { params: {page, per_page, [`filter[${filter.nameColumn}]`]: filter.value} });
  }

  public getStockStatusList(
    page = '1',
    per_page = '100',
    sort: FilterModel = {nameColumn: 'status', value: DEFAULT_SORT_DIRECTION},
    hideOutOfStock: boolean = false,
    filters: any = {}
    ): Observable<ResponseList<StockStatusModel>> {

    const params = {
      page,
      per_page,
      [`sort[${sort.nameColumn}]`]: sort.value,
      hideOutOfStock: String(Number(hideOutOfStock)) // convert boolean to string with boolean ("0", "1")
    };

    for (const [key, value] of Object.entries(filters)) {
      params[`filters[${key}]`] = Array.isArray(value) ? value.join(',') : value.toString();
    }

    return this.http.get<ResponseList<StockStatusModel>>(`/warehouse/stock-status`, { params })
      .pipe(tap((data: ResponseList<StockStatusModel>) => {
        this.store.dispatch(LoadStockStatusList({
          stockStatusListData: {
            [data.pagination.page]: {
              pagination: data.pagination,
              sort,
              data: data.data,
              totals: data.totals
            }
          }
        }));
      }));
  }

  getStockStatusListBins(stockStatusPositionId: number): Observable<StockStatusBinsModel[]> {
    return this.http.get<ResponseModel<StockStatusBinsModel[]>>(`/warehouse/stock-status/${stockStatusPositionId}/bins`)
      .pipe(map((data: ResponseModel<StockStatusBinsModel[]>) => data.data));
  }

  getStockStatusListDocuments(stockStatusPositionId: number): Observable<StockStatusDocumentModel[]> {
    return this.http.get<ResponseModel<StockStatusDocumentModel[]>>(`/warehouse/stock-status/${stockStatusPositionId}/documents`)
      .pipe(map((data: ResponseModel<StockStatusDocumentModel[]>) => data.data));
  }

  public searchEmployeeByName(phrase, page = '1', per_page = '100'): Observable<any> {
    return this.http.get('/users/employees', {params: {
      phrase,
      page,
      per_page
    }}).pipe(map((response: any) => response.data));
  }

  public setWarehouseLegalAddress(warehouseId): Observable<WarehouseResponse> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<WarehouseResponse>>('put', `/warehouse/structure/warehouses/${warehouseId}/address/company-legal-address`)
      .pipe(
        tap((response: ResponseModel<WarehouseResponse>) => {
          this.store.dispatch(UpdateCurrentWarehouse({ currentWarehouse: response.data }));
          this.store.dispatch(UpdateWarehouseUpdatedAt({updatedAt: new Date()}));
        }),
        map((response: any) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public downloadAttachment(url: string): Observable<any> {
    return this.http.get(url);
  }

  public getWarehouseCounters(): Observable<any> {
    return this.http.get('/warehouse/structure/warehouses/counter').pipe(map((response: any) => response.data));
  }

  public getControlStationCounters(stationType, warehouseId: string): Observable<any> {
    return this.http.get(`/warehouse/structure/warehouses/${warehouseId}/${WarehouseTypes.STATION}/${stationType}/counter`)
      .pipe(map((response: any) => response.data));
  }

  public getWarehouseInfo(): Observable<any> {
    return this.http.get('/warehouse')
      .pipe(
        tap((response: ResponseModel<WarehouseResponse>) => {
          this.store.dispatch(UpdateCurrentWarehouse({ currentWarehouse: response.data }));
          this.store.dispatch(UpdateWarehouseUpdatedAt({updatedAt: new Date()}));
        }),
      );
  }

  public patchWarehouseProfile(field: FormInputChangedModel): Observable<WarehouseResponse> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch(`/warehouse`, field)
      .pipe(
        tap((response: ResponseModel<WarehouseResponse>) => {
          this.store.dispatch(UpdateCurrentWarehouse({ currentWarehouse: response.data }));
          this.store.dispatch(UpdateWarehouseUpdatedAt({updatedAt: new Date()}));
        }),
        map((data: ResponseModel<any>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public warehouseSetEdit(force = false): Observable<any> {
    const body = { force };
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.put<ResponseModel<WarehouseResponse>>(`/warehouse/locking`, body)
      .pipe(
        tap((response: ResponseModel<WarehouseResponse>) => {
          this.store.dispatch(UpdateCurrentWarehouse({ currentWarehouse: response.data }));
          this.store.dispatch(UpdateWarehouseCurrentState({ currentState: UIStatesEnum.EDIT }));
        }),
        map((response: ResponseModel<WarehouseResponse>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public warehouseUnsetEdit(): Observable<WarehouseResponse> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<WarehouseResponse>>('put', `/warehouse/unlocking`)
      .pipe(
        tap((response: ResponseModel<WarehouseResponse>) => {
          this.store.dispatch(UpdateCurrentWarehouse({ currentWarehouse: response.data }));
          this.store.dispatch(UpdateWarehouseCurrentState({ currentState: UIStatesEnum.VIEW }));
        }),
        map((response: ResponseModel<WarehouseResponse>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public createWarehouse(warehouseData): Observable<any> {
    return this.http.post('/warehouse/structure/warehouses', warehouseData);
  }

  public createWarehouseBuffer(bufferType: BufferTypes, warehoudeId, warehouseBufferData): Observable<any> {
    return this.http.post(`/warehouse/structure/warehouses/${warehoudeId}/${WarehouseTypes.BUFFER}/${bufferType}`, warehouseBufferData);
  }

  public createWarehouseFloor(warehoudeId, warehouseFloorData): Observable<any> {
    return this.http.post(`/warehouse/structure/warehouses/${warehoudeId}/floor`, warehouseFloorData);
  }

  public createWarehouseRack(warehoudeId, warehouseRackData): Observable<any> {
    return this.http.post(`/warehouse/structure/warehouses/${warehoudeId}/rack`, warehouseRackData);
  }

  public createNewIncomingDelivery(warehoudeId, incomingDeliveryData): Observable<any> {
    return this.http.post(`/warehouse/${warehoudeId}/incoming-delivery`, incomingDeliveryData);
  }

  public changeWarehouseStatus(warehouseId: number, status: string): Observable<any> {
    return this.http.patch(`/warehouse/structure/warehouses/${warehouseId}/status`, {status}).pipe(map((response: any) => response.data));
  }

  public changeControlStationStatus(stationType: StationTypes, warehouseId, params: {ids: number[], status: string}): Observable<any> {
    return this.http.patch(`/warehouse/structure/warehouses/${warehouseId}/${WarehouseTypes.STATION}/${stationType}/status`, params);
  }

  public changeFloorStatus(warehouseId, params: {ids: number[], status: string}): Observable<any> {
    return this.http.patch(`/warehouse/structure/warehouses/${warehouseId}/floor/status`, params);
  }

  public changePositionStatuses(warehouseId, incomingDeliveryId, params): Observable<any> {
    return this.http.put(`/warehouse/${warehouseId}/incoming-delivery/${incomingDeliveryId}/positions/status`, params)
    .pipe(map((response: any) => response.data));
  }

  public updatePackageItems(warehouseId, incomingDeliveryId, params): Observable<any> {
    return this.http.patch(`/warehouse/${warehouseId}/incoming-delivery/${incomingDeliveryId}/package-items`, params);
  }

  public getBasicWarehouseAddress(): Observable<AddressModel> {
    return this.http.get<ResponseModel<AddressModel>>('/warehouse/basic/address')
      .pipe(map((response: ResponseModel<AddressModel>) => response.data));
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  private handlePopupErrors(error: HttpErrorResponse): void {
    switch (error.error.message) {
      case 'anotherUserEditError':
      {
        const dialog = this.dialog.open(WarningModalComponent, {
          data: getAnotherUserEditErrorModalData(
            {
              document: error.error.data.entityName,
              userName: error.error.data.userName,
            },
            this.translateService
          )
        });

        dialog.afterClosed().subscribe(res => {
          if (res === CommonModalsActionsEnum.CONFIRM) {
            this.warehouseSetEdit(true).subscribe();
          }
        });
      }
        break;
      case 'notEditModeError':
        const documentName = this.translateService.instant('MODULE.WAREHOUSE');
        this.showMsg('warning', this.translateService.instant('COMMON.DOC_UPDATED_BY_USER', { document: documentName }));
        this.store.dispatch(UpdateWarehouseCurrentState({ currentState: UIStatesEnum.VIEW }));
        this.getWarehouseInfo().subscribe();
        break;
      default:
        this.showMsg('error', error.error.message);
        break
    }
  }
}
