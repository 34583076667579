import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { catchError, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { cloneDeep, get, isEqual, isEmpty } from 'lodash';
import { FileSaverService } from 'ngx-filesaver';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';

import { CommonModalsActionsEnum, DangerModalComponent } from 'common/src/modules/modals/modals-common';
import { NavBarBtnModel, PaginationModel } from 'common/src/models';
import { TableSummaryBarItemModel } from 'common/src/modules/ui-components/table-summary-bar/table-summary-bar.model';
import { TabDefinitionModel } from 'common/src/modules/ui-components/nav-tabs/tab-definition.model';
import { TableActivateTypes } from 'common/src/modules/ui-components/table/custom-table.enums';
import { FileUploadParams } from 'common/src/models/file-upload-params.model';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { FileService } from 'common/src/services/file.service';
import { TableColumnModel, TableColumnModelExtended } from 'common/src/models/table-column.model';
import { STATUS_KEY } from './purchase-order-list.constants';
import { PoTabs } from '../../enums';
import { PurchaseOrder, PurchaseOrderCounters } from '../../models';
import { PurchaseOrderApiService } from '../../services/purchase-order-api.service';
import { FilterModel } from '../../../warehouse/models/filter.model';
import { ResponseList, UserSorting } from '../../../shared/models/response';
import { TradePositionModel } from '../../../trade/models';
import {
  ActionBarButtons,
  EMPTY_STATE_DATA,
  getDeleteModalData,
  getDeletePermanentlyModalData,
  getPurchaseOrderListFiltersConfig,
  getPurchaseOrderListInnerTableColumns,
  getTableSummaryBarItems,
  PurchaseOrderListColumns,
  PurchaseOrderTabs
} from './purchase-order-list.config';
import { POListState } from '../../store/reducers';
import { AppState } from '../../../store/state/app.state';
import { selectPOList } from '../../store/selectors/index';
import { DEFAULT_PAGINATION, DEFAULT_SORT_DIRECTION } from '../../../shared/constants';
import { getContentDispositionFileName } from 'common/src/modules/rnpl-common/helpers';
import { FiltersGroupModel, FiltersService } from 'common/src/modules/filters';
import { StatusTranslatePipe, UnitTypeTranslatePipe } from 'common/src/modules/rnpl-common';
import { getDocumentUrl } from '../../../shared/helpers';
import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { UserSortingService } from '../../../shared/services';

@Component({
  selector: 'rnpl-purchase-order-list',
  templateUrl: './purchase-order-list.component.html',
  styleUrls: ['./purchase-order-list.component.scss']
})
export class PurchaseOrderListComponent implements OnInit, OnDestroy {
  public emptyStateData = EMPTY_STATE_DATA;
  public tabs: TabDefinitionModel[] = PurchaseOrderTabs;
  public columns: TableColumnModel[] = PurchaseOrderListColumns;
  public customizedColumns: TableColumnModel[] = [];
  public innerTableColumns: TableColumnModel[] = getPurchaseOrderListInnerTableColumns(this.translate);
  public pagination: PaginationModel = DEFAULT_PAGINATION;
  public actionBarButtons: NavBarBtnModel[] = ActionBarButtons;
  public tableSummaryBarItems: TableSummaryBarItemModel[] = [];
  public selectedRows = [];
  public documentTypesUppercaseEnum = DocumentTypesUppercaseEnum;
  public poTabs = PoTabs;

  public sort: FilterModel = { nameColumn: 'updatedAt', value: DEFAULT_SORT_DIRECTION };
  public filtersConfig: FiltersGroupModel[] = [];

  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly isLoadingMegaHint$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly isLoadingInnerTable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  readonly poList$: BehaviorSubject<PurchaseOrder[]> = new BehaviorSubject([]);
  readonly activeStatus$: BehaviorSubject<PoTabs> = new BehaviorSubject<PoTabs>(PoTabs.Draft);
  readonly updatedAt$: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());
  readonly selectedInnerTableData$: BehaviorSubject<TradePositionModel[]> = new BehaviorSubject([]);
  readonly destroy$: Subject<void> = new Subject();
  private listData$: BehaviorSubject<POListState> = new BehaviorSubject(null);
  readonly exportCsvRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  readonly createPoRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  readonly btnToClearLoadingStatus$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  @ViewChild('tableComponent', {static: false}) tableComponentRef;
  @ViewChild('filters', {static: false}) filtersRef;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly toasterService: ToasterService,
    private readonly router: Router,
    private readonly location: Location,
    private readonly poApi: PurchaseOrderApiService,
    private readonly datePipe: DatePipe,
    private readonly fileService: FileService,
    private readonly fileSaverService: FileSaverService,
    private readonly dialog: MatDialog,
    private readonly titleService: Title,
    private readonly store: Store<AppState>,
    private readonly cdr: ChangeDetectorRef,
    private readonly statusTranslatePipe: StatusTranslatePipe,
    private readonly unitTypeTranslatePipe: UnitTypeTranslatePipe,
    private readonly translate: TranslateService,
    private readonly filtersService: FiltersService,
    private readonly userSortingService: UserSortingService
  ) {
  }

  ngOnInit(): void {
    this.setTitle();
    this.bindPOList();

    this.route.paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe((params: ParamMap) => {
        const pageFromRouting = Number(params.get('page'));

        if (this.activeStatus !== params.get(STATUS_KEY)) {
          this.sort = { nameColumn: 'updatedAt', value: DEFAULT_SORT_DIRECTION };
          if (this.filtersRef) {
            this.filtersRef.closeSidebar();
          }
        }
        this.activeStatus$.next(params.get(STATUS_KEY) as PoTabs);
        this.filtersConfig = getPurchaseOrderListFiltersConfig(
          this.activeStatus,
          this.poApi.getPurchaseOrdersFilterExternalNumbers(),
          this.poApi.getPurchaseOrdersRunpleIds(this.activeStatus),
          this.poApi.getDocumentTemplatesNames(),
        );
        this.selectedRows = [];
        this.tableSummaryBarItems = [];
        this.columns = this.filterColumns(PurchaseOrderListColumns);
        this.pagination = { ...this.pagination, page: pageFromRouting };

        this.getPOList();
        this.getPOTabsCounters();
        this.cdr.detectChanges();
      });
  }

  public setTitle(): void {
    const activeStatus = this.translate.instant(this.statusTranslatePipe.transform(this.activeStatus));
    this.titleService.setTitle(
      `${this.translate.instant('APP.PURCHASE_ORDERS')}: ${activeStatus}`
    );
  }

  public bindPOList() {
    this.store.select(selectPOList)
      .pipe(takeUntil(this.destroy$))
      .subscribe((purchaseOrderList) => {
        this.listData$.next(purchaseOrderList);
      });
  }

  public getPOList(preventLoading = false, page = this.pagination.page): void {
    this.isLoadingMegaHint$.next(true);

    if (!preventLoading) {
      this.isLoading$.next(true);
    }

    if (this.tableComponentRef) {
      this.tableComponentRef.collapseAll();
    }

    this.selectedRows = [];

    this.userSortingService.getUserSorting(this.activeStatus, DocumentTypesUppercaseEnum.PO)
      .pipe(
        tap((sorting: UserSorting) => {
          this.sort = {
            nameColumn: sorting.sortBy || this.sort.nameColumn,
            value: sorting.direction || this.sort.value
          };
          const purchaseOrdersByCurrentPage = this.listData$.getValue()[this.activeStatus][page];
          if (purchaseOrdersByCurrentPage && isEqual(this.sort, purchaseOrdersByCurrentPage.sort)) {
            this.pagination = purchaseOrdersByCurrentPage.pagination;
            this.sort = purchaseOrdersByCurrentPage.sort;
            this.poList$.next(this.preparePOList(purchaseOrdersByCurrentPage.data));
            this.isLoading$.next(false);
          }
        }),
        switchMap(() => this.poApi.getPoList(
          this.activeStatus,
          page,
          this.pagination.per_page,
          this.sort,
          this.filtersService.getFilters()
        )),
        finalize(() => {
          this.isLoading$.next(false);
          this.isLoadingMegaHint$.next(false);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((data: ResponseList<PurchaseOrder>) => {
        if (data.status && data.status !== this.activeStatus) { return; }

        this.poList$.next(this.preparePOList(data.data));
        this.tableSummaryBarItems = getTableSummaryBarItems(data.totals, this.activeStatus);
        this.tabs.find(tab => tab.tabName === data.status).count = data.totals.totalCount;

        this.pagination = get(data, 'pagination', DEFAULT_PAGINATION);
        this.updatedAt$.next(new Date());

        this.setTitle();
      }, error => {
        this.updatedAt$.next(null);
        this.showMsg('error', error.error.message);
      });
  }

  public getPOTabsCounters(): void {
    this.poApi.getPOListCounters()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: PurchaseOrderCounters) => {
        this.tabs = this.tabs.map((t: TabDefinitionModel) => {
          if (!isEmpty(this.filtersService.getFilters()) && t.tabName === this.activeStatus) { return t; }
          return {...t, count: response[t.tabName]};
        });
      });
  }

  private filterColumns(cols: TableColumnModelExtended[]): TableColumnModelExtended[] {
    const columns = [...cols];

    return columns.filter((col: TableColumnModelExtended) => {
      if (!col.showWithStatuses) { return true; } // show for all statuses
      return col.showWithStatuses.includes(this.activeStatus); // check for showing
    });
  }

  createNewPurchaseOrder(): void {
    if (this.createPoRequest$.getValue()) { return; }
    this.createPoRequest$.next(true);

    this.poApi.createDefaultPurchaseOrder()
      .pipe(
        catchError(error => {
          this.createPoRequest$.next(false);
          return throwError(error);
        }),
        tap((data) => {
          this.router.navigate([`/trade/purchase-order/${data.id}`]);
        }),
        takeUntil(this.destroy$),
      ).subscribe();
  }

  private preparePOList(poList: PurchaseOrder[]): PurchaseOrder[] {
    poList = cloneDeep(poList); // If data is loading from store - object is locked for edit
    return poList.map((po: PurchaseOrder) => {

      if (po.positionsConfirmedAt) {
        po.deliveryDateValue = {
          replaceWithDate: true,
          date: get(po, 'positionsConfirmedAt', null)
        };
      } else {
        po.deliveryDateValue = {
          expectedDate: po.estimatedDeliveryDate,
          replaceWithEmpty: !po.estimatedDeliveryDate,
          date: get(po, 'createdAt', null)
        };
      }

      if (po.linkedDocumentsLinks) {
        po.linkedDocumentsLinks = po.linkedDocuments.map(doc => ({
          label: doc.documentRunpleId || this.translate.instant('TAB.DRAFT'),
          routerLink: getDocumentUrl(doc.type, doc.documentId)
        }));
      }

      if (po.status === 'template' && po.name) {
        po.templateNameLink = {
          label: po.name,
          routerLink:`/trade/purchase-order/${po.id}`
        };
      }

      po.documentLink = {
        label: po.runpleId,
        routerLink:`/trade/purchase-order/${po.id}`
      };

      return po;
    });
  }

  private prepareInnerTableColumns(positions: TradePositionModel[]): TradePositionModel[] {
    return positions.map((pos) => ({
      ...pos,
      productLink: {
        label: pos.productRunpleId,
        routerLink: `/products/product-view/${pos.productId}`
      },
      productNameLink: {
        label: pos.description,
        routerLink: `/products/product-view/${pos.productId}`
      },
      openData: {
        value: pos.open,
        maxValue: pos.quantity,
        inverse: true,
        postfix: this.unitTypeTranslatePipe.transform(pos.unitType),
      },
    }))
  }

  public onActionReceived(btnAction: string) {
    if (this[btnAction]) {
      this[btnAction]();
    }
  }

  rowClickReceiver(event): void {
    if (event.type === TableActivateTypes.RowDetail) {
      this.isLoadingInnerTable$.next(true);

      this.poApi.getPurchaseOrderListPositions(event.row.id)
        .pipe(
          finalize(() => this.isLoadingInnerTable$.next(false)),
          takeUntil(this.destroy$)
        )
        .subscribe((positions: TradePositionModel[]) => {
          this.selectedInnerTableData$.next(this.prepareInnerTableColumns(positions));
        });
    }

    if (event.type === TableActivateTypes.Link) {
      this.router.navigate([`/trade/purchase-order/${event.row.id}`]);
    }
  }

  onManagePO(e: PurchaseOrder): void {
    this.router.navigate([`/trade/purchase-order/${e.id}`]);
  }

  public onExportCsvClick(): void {
    if (this.exportCsvRequest$.getValue()) { return; }
    this.exportCsvRequest$.next(true);

    const status = this.activeStatus;
    const currentDate = this.datePipe.transform(
      new Date(),
      'yyyy-MM-dd'
    );
    this.poApi.getPOListExport(`po_${status}_${currentDate}`, status)
      .pipe(takeUntil(this.destroy$))
      .subscribe((fileParams: FileUploadParams) => {
        const {url, title} = fileParams;
        this.downloadFile(url, title);
      });
  }

  public downloadFile(url, title) {
    this.fileService.downloadFile(url)
      .pipe(
        finalize(() => this.exportCsvRequest$.next(false)),
        takeUntil(this.destroy$),
      )
      .subscribe((res: any) => {
        this.fileSaverService.save(
          res.body,
          getContentDispositionFileName(res.headers.get('Content-Disposition')) || title
        );
      });
  }

  private changePOStatus(status: PoTabs): void {
    const ids = this.selectedRows.map((po: PurchaseOrder) => po.id);

    this.poApi.changePOStatusBatch(status, ids)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.selectedRows = [];
        this.getPOTabsCounters();
        this.getPOList(true);
      });
  }

  onDeleteClick(): void {
    const dialog = this.dialog.open(DangerModalComponent, {
      data: getDeleteModalData(this.translate)
    });

    dialog.afterClosed()
      .pipe(finalize(() => this.btnToClearLoadingStatus$.next('onDeleteClick')))
      .subscribe((response: CommonModalsActionsEnum) => {
        if (response === CommonModalsActionsEnum.CONFIRM) {
          if (this.activeStatus === PoTabs.Template) {
            this.deletePermanently();
            return;
          }
          this.changePOStatus(PoTabs.Deleted);
        }
      });
  }

  onDeletePermanentlyClick(): void {
    const dialog = this.dialog.open(DangerModalComponent, {
      data: getDeletePermanentlyModalData(this.translate)
    });

    dialog.afterClosed()
      .pipe(finalize(() => this.btnToClearLoadingStatus$.next('onDeletePermanentlyClick')))
      .subscribe((response: CommonModalsActionsEnum) => {
        if (response === CommonModalsActionsEnum.CONFIRM) {
          this.deletePermanently();
        }
      });
  }

  public deletePermanently(): void {
    const ids = this.selectedRows.map((po: PurchaseOrder) => po.id);
    this.poApi.deletePOPermanentlyBatch(ids)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.selectedRows = [];
        this.getPOTabsCounters();
        this.getPOList(true);
      });
  }

  public sortTable(e): void {
    let sortedProp = e.column.prop;

    if (sortedProp === 'statusValue') { sortedProp = 'status'; }
    if (sortedProp === 'deliveryDateValue') { sortedProp = 'deliveryDate'; }
    if (sortedProp === 'documentLink') { sortedProp = 'runpleId'; }
    if (sortedProp === 'linkedDocumentsLinks') { sortedProp = 'linkedDocumentRunpleId'; }
    if (sortedProp === 'templateNameLink') { sortedProp = 'templateName'; }
    if (sortedProp === 'lastUsedAt') { sortedProp = 'lastTemplateUsedAt'; }
    if (sortedProp === 'vendor') { sortedProp = 'vendorName'; }

    this.sort = {nameColumn: sortedProp, value: e.newValue.toUpperCase()};
    this.saveUserSorting(sortedProp, e.newValue.toUpperCase());
  }

  public saveUserSorting(sortBy: string, direction: 'ASC'|'DESC'): void {
    const sorting = {
      sortBy,
      direction,
      type: DocumentTypesUppercaseEnum.PO,
      status: this.activeStatus
    }
    this.userSortingService.saveUserSorting(sorting)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.getPOList(true));
  }

  public goToPage(page: number): void {
    const url = this.getNewPaginationUrl(this.router.url);

    this.pagination = { ...this.pagination, page: page + 1 };
    this.router.navigate([url, this.pagination.page], {queryParamsHandling: 'merge'});
  }

  public getNewPaginationUrl(oldUrl: string): string {
    return oldUrl.split('/').slice(0, -1).join('/');
  }


  private showMsg(type: string, message: string, status?: string): void {
    if (!status || status !== PoTabs.Deleted) {
      this.toasterService.notify({type, message});
    }
  }

  public get showSummaryBar(): boolean {
    return !!this.tableSummaryBarItems.length &&
      ( this.activeStatus === PoTabs.Draft ||
        this.activeStatus === PoTabs.Open ||
        this.activeStatus === PoTabs.Completed
      );
  }

  public hideSummaryBar(): boolean {
    return this.activeStatus === PoTabs.Canceled ||
      this.activeStatus === PoTabs.Deleted;
  }

  public get activeStatus(): PoTabs { return this.activeStatus$.getValue(); }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
