import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'projects/workspace/src/environments/environment';
import { selectCompanyProfile } from '../../administration/store/selectors';
import { CompanyProfile } from '../../administration/models/company-profile.model';
import { AppState } from '../../store/state/app.state';
import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { DocumentTextTemplateModel, ResponseModel } from '../models';
import { DocumentTextLocationEnum } from '../enums';
import { DisplayToaster } from '../decorators/toaster';
import { ToasterService } from 'common/src/modules/ui-components/toaster';

@Injectable()
export class DocumentTextTemplatesApiService {
  private wid: number;
  private apiEndpoint: string;

  private apiUrl(url: string = ''): string {
    return this.apiEndpoint + url;
  }

  constructor(
    private readonly toasterService: ToasterService,
    private readonly http: HttpClient,
    private readonly store: Store<AppState>,
  ) {
    this.store.select(selectCompanyProfile)
      .subscribe((profile: CompanyProfile) => {
        this.wid = profile.workspaceId;
        this.apiEndpoint =  `${environment.javaCommonsApiVersion}/workspaces/${this.wid}/text-templates`;
      });
  }

  public getTextTemplates(documentType: DocumentTypesUppercaseEnum, location: DocumentTextLocationEnum): Observable<DocumentTextTemplateModel[]> {
    return this.http.get<ResponseModel<DocumentTextTemplateModel[]>>(this.apiUrl(`/${documentType}/${location}`))
      .pipe(map((response: ResponseModel<DocumentTextTemplateModel[]>) => response.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public saveTextTemplate(
    documentType: DocumentTypesUppercaseEnum,
    location: DocumentTextLocationEnum,
    text: string,
    title: string
  ): Observable<DocumentTextTemplateModel> {
    return this.http.post(this.apiUrl(`/${documentType}/${location}`), { text, title })
      .pipe(map((response: ResponseModel<DocumentTextTemplateModel>) => response.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public deleteTextTemplate(templateId: number): Observable<any> {
    return this.http.delete(this.apiUrl(`/${templateId}`));
  }

}
