import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ProductsService } from 'common/src/modules/products/products.service';
import { ProductModel } from '../../../products';
import { CustomSearchFn } from '../../../rnpl-common/helpers';
import { ProductTypes } from '../../../products/product-types';
import { CommonModalsActionsEnum, DangerModalComponent, WarningModalComponent } from '../../modals-common';

@Component({
  selector: 'rnpl-product-variation-modal',
  templateUrl: './product-variation-modal.component.html',
  styleUrls: ['./product-variation-modal.component.scss'],
})
export class ProductVariationModalComponent extends BaseModalComponent implements OnInit {

  public productsList: ProductModel[] = [];
  public productData: ProductModel;
  public selectedProduct: ProductModel;
  public selectedProductInvalid: boolean = false;
  public customSearchFn = CustomSearchFn;

  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly submitRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    public toasterService: ToasterService,
    public productsService: ProductsService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ProductVariationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      productId: number,
      productVariation: ProductModel,
      productName: string,
      familyId: number,
      productType: ProductTypes,
    }
  ) {
    super(toasterService);
  }

  ngOnInit() {
    this.showDropdownSpin$.next(true);
    this.getProducts();
  }

  private getProducts(): void {
    this.productsService.getActiveProducts()
      .pipe(takeUntil(this._destroy))
      .subscribe((products: ProductModel[]) => {
        this.productsList = products
          .filter((product: ProductModel) => product.type === this.data.productType)
          .map((item: ProductModel) => ({
            ...item,
            searchLabel: `${item.runpleId} ${item.name} ${item.description}`
          }));

        if (this.data.productVariation.productId) {
          this.selectedProduct = this.productsList.find(p => +p.id === +this.data.productVariation.productId);
          this.productSelected();
        }
        this.showDropdownSpin$.next(false);
      });
  }

  public productSelected(): void {
    this.productsService.getProduct(+this.selectedProduct.id, null, true)
      .pipe(takeUntil(this._destroy))
      .subscribe((product: ProductModel) => this.productData = product);
  }

  public deleteVariation(): void {
    const dialog = this.dialog.open(DangerModalComponent, {
      data: {
        title: 'PRODUCTS_VARIATIONS.REMOVE_TITLE',
        message: 'PRODUCTS_VARIATIONS.REMOVE_MSG',
        confirmBtnText: 'BUTTON.REMOVE',
        confirmBtnIcon: 'minus-circle'
      }
    });

    dialog.afterClosed().subscribe(res => {
      if (res === CommonModalsActionsEnum.CONFIRM) {
        this.productsService.removeProductVariation(+this.data.productId, [+this.data.productVariation.productId])
          .pipe(takeUntil(this._destroy))
          .subscribe(() => this.dialogRef.close(CommonModalsActionsEnum.CONFIRM));
      }
    });
  }

  public submit(forceDifferentCategory = false): void {
    if (!this.selectedProduct) {
      this.selectedProductInvalid = true;
      return;
    }

    if (!forceDifferentCategory && this.data.familyId !== +this.selectedProduct.family.id) {
      const dialog = this.dialog.open(WarningModalComponent, {
        data: {
          title: 'PRODUCTS_VARIATIONS.ADD_VARIATION',
          message: 'PRODUCTS_VARIATIONS.DIFFERENT_CATEGORY_MSG',
          confirmBtnText: 'BUTTON.CONTINUE',
          confirmBtnIcon: 'arrow-right'
        }
      });

      dialog.afterClosed().subscribe(res => {
        if (res === CommonModalsActionsEnum.CONFIRM) {
          this.submit( true);
        }
      });
      return;
    }

    if (this.submitRequest$.getValue()) { return; }
    this.submitRequest$.next(true);

    this.productsService.addProductVariation(this.data.productId, [+this.selectedProduct.id])
      .pipe(
        finalize(() => this.submitRequest$.next(false)),
        takeUntil(this._destroy)
      )
      .subscribe(() => {
        this.dialogRef.close(CommonModalsActionsEnum.CONFIRM);
      });
  }

}
