import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCurrencyModule } from 'ngx-currency-custom';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';

// import { MetricConversionPipe } from 'common/src/modules/rnpl-common';
import { DiscountVolumeBasedFormComponent } from './discount-volume-based-form.component';
import { RnplCommonModule, UIComponentsModule } from 'common/src/modules';

@NgModule({
  declarations: [
    DiscountVolumeBasedFormComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    UIComponentsModule,
    RnplCommonModule,
    NgxCurrencyModule,
    TranslateModule,
    FormsModule
  ],
  exports: [
    DiscountVolumeBasedFormComponent,
  ],
  entryComponents: [DiscountVolumeBasedFormComponent],
  // providers: [MetricConversionPipe]
})
export class DiscountVolumeBasedFormModule { }
