import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

import { DocumentInfoTypeEnum, PositionsColumnModel, PositionTypeEnum } from '../../document-viewer.model';
import { PositionsModel } from 'projects/workspace/src/app/outgoing-invoice/models';
import { ProductTypes } from '../../../products/product-types';
import {
  PositionsContextMenuComponent
} from 'projects/workspace/src/app/shared/components/positions-context-menu/positions-context-menu.component';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { selectPdfSettings } from 'projects/workspace/src/app/store/selectors/shared.selectors';
import { PdfSettingsDesignModel, PdfSettingsModel, PdfSettingsPositionsModel } from 'projects/workspace/src/app/pdf-settings/models';
import {
  PdfSettingsDiscountUnitEnum,
  PdfSettingsPriceOptionEnum,
  PdfSettingsProductIdTypeEnum,
  PdfSettingsTableRowStyleEnum
} from 'projects/workspace/src/app/pdf-settings/enums';
import { CustomerTypeEnum } from '../../../../models';
import { PartnersTypeEnum } from 'projects/workspace/src/app/partners/corporate/enums';

@Component({
  selector: 'rnpl-positions-table',
  templateUrl: './positions-table.component.html',
  styleUrls: ['./positions-table.component.scss']
})
export class PositionsTableComponent implements OnChanges, OnDestroy {

  public pdfSettings: PdfSettingsModel;
  public productTypes = ProductTypes;
  public infoTypeEnum = DocumentInfoTypeEnum;
  public rowStyleEnum = PdfSettingsTableRowStyleEnum;
  public productIdTypeEnum = PdfSettingsProductIdTypeEnum;
  public priceOptionEnum = PdfSettingsPriceOptionEnum;
  public discountUnitEnum = PdfSettingsDiscountUnitEnum;

  public selectedPositionsList: any = {
    [ProductTypes.GOODS]: [],
    [ProductTypes.SERVICES]: [],
    [ProductTypes.DIGITAL]: [],
  };
  public columns: PositionsColumnModel[] = [];
  // public _totalDiscountNet: number|string;
  // public _totalDiscountGross: number|string;
  public deliveryDateColumnName: string;
  public deliveryDatePropKey: string;

  @Input() public positionsColumn: PositionsColumnModel[] = [];
  @Input() public positions: any;
  @Input() public startCount: number = 0;
  @Input() public isReadOnly: boolean = true;
  @Input() public deletable: boolean = true;
  @Input() public selectable: boolean = true;
  @Input() public tableTitle: string;
  @Input() public skipInfoBtn: boolean;
  @Input() public showShipInfo: boolean = false;
  @Input() public showBillInfo: boolean = false;
  @Input() public isSingleSelected: boolean;
  @Input() public isSmallBusiness: boolean = false;
  @Input() public showDiscount: boolean = false;
  @Input() public vatDisabled: boolean = false;
  @Input() public showSubtotalForProductTypes: boolean = true;
  @Input() public negativeTotalAmount: boolean = false;
  @Input() public totalAmountNet: number;
  @Input() public totalAmountGross: number;
  @Input() public partnerType: CustomerTypeEnum|PartnersTypeEnum;
  @Input() public lang: string;
  // @Input() set totalDiscountNet(value: number) {
  //   if (typeof value === 'string' && +value) {
  //     // for format '0,00', '10,56'
  //     this._totalDiscountNet = `-${value}`;
  //     return;
  //   }
  //   // for format 0, 540
  //   this._totalDiscountNet = +value ? (+value * -1) : value;
  // };
  // @Input() set totalDiscountGross(value: number) {
  //   if (typeof value === 'string' && +value) {
  //     this._totalDiscountGross = `-${value}`;
  //     return;
  //   }
  //   this._totalDiscountGross = +value ? (+value * -1) : value;
  // };
  @Input('selected') set selectedRowsSetter(rows: any) {
    this.selectedPositionsList = rows;
  }

  @Output() tableRowActionEmit: EventEmitter<any> = new EventEmitter();
  @Output() selectedPosition: EventEmitter<any> = new EventEmitter();
  @Output() actionListEmit: EventEmitter<any> = new EventEmitter();

  @ViewChild('contextMenuComponent', {static: false}) public contextMenuComponent: PositionsContextMenuComponent;

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(private store:  Store<AppState>) {
    this.store.select(selectPdfSettings)
      .pipe(takeUntil(this.destroy$))
      .subscribe((pdfSettings: PdfSettingsModel) => {
        this.pdfSettings = pdfSettings;
        this.filterColumns();
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes && (
        changes.hasOwnProperty('positionsColumn')
        || changes.hasOwnProperty('showDiscount')
        || changes.hasOwnProperty('partnerType')
        || changes.hasOwnProperty('vatDisabled')
        || changes.hasOwnProperty('isSmallBusiness')
      )
    ) {
      this.filterColumns();
    }
  }

  private filterColumns(): void {
    if (!this.pdfSettings || !this.positionsColumn.length) { return; }

    const filteredColumns = this.positionsColumn
      .filter((col: PositionsColumnModel) => !col.hasOwnProperty('showCondition') || col.showCondition)
      .filter((col: PositionsColumnModel) => col.type !== DocumentInfoTypeEnum.DISCOUNT || (this.positionsSettings.showDiscountOnPosition && this.showDiscount))
      .filter((col: PositionsColumnModel) => col.type !== DocumentInfoTypeEnum.VAT || (this.positionsSettings.showColumnVat && !this.isSmallBusiness))
      .filter((col: PositionsColumnModel) => col.type !== DocumentInfoTypeEnum.UNIT_PRICE || this.positionsSettings.showColumnUnitPrice)
      .filter((col: PositionsColumnModel) => col.type !== DocumentInfoTypeEnum.QUANTITY || this.positionsSettings.showColumnQty)
      .filter((col: PositionsColumnModel) => col.type !== DocumentInfoTypeEnum.DELIVERY_DATE || this.positionsSettings.showColumnDeliveryDate);

    this.columns = this.preparePricesColumns(filteredColumns);
  }

  public preparePricesColumns(columns: PositionsColumnModel[]): PositionsColumnModel[] {
    const unitPriceTitle: {[key in PdfSettingsPriceOptionEnum]: string} = {
      [PdfSettingsPriceOptionEnum.NET]: 'COLUMN.UNIT_PRICE_NET',
      [PdfSettingsPriceOptionEnum.GROSS]: 'COLUMN.UNIT_PRICE_GROSS',
    };
    const amountPriceTitle: {[key in PdfSettingsPriceOptionEnum]: string} = {
      [PdfSettingsPriceOptionEnum.NET]: 'COLUMN.AMOUNT_NET',
      [PdfSettingsPriceOptionEnum.GROSS]: 'COLUMN.AMOUNT_GROSS',
    };
    const discountPriceTitle: {[key in PdfSettingsPriceOptionEnum]: string} = {
      [PdfSettingsPriceOptionEnum.NET]: 'COMMON.DISCOUNT_NET',
      [PdfSettingsPriceOptionEnum.GROSS]: 'COMMON.DISCOUNT_GROSS',
    };
    const priceProp: {[key in PdfSettingsPriceOptionEnum]: string} = {
      [PdfSettingsPriceOptionEnum.NET]: 'propNet',
      [PdfSettingsPriceOptionEnum.GROSS]: 'propGross',
    };

    return columns
      .filter((col: PositionsColumnModel) => {
        if (col.type !== DocumentInfoTypeEnum.DELIVERY_DATE) {
          return true;
        }
        this.deliveryDateColumnName = col.columnName;
        this.deliveryDatePropKey = col.prop;
        return false;
      })
      .map((col: PositionsColumnModel) => {
      if (col.type === DocumentInfoTypeEnum.DISCOUNT) {
        col = {
          ...col,
          width: col.width || 124,
          // replace prop with right option (for net or gross) based on conditions
          prop: this.positionsSettings.discountUnit === PdfSettingsDiscountUnitEnum.AMOUNT
            ? this.positionsSettings.showColumnNetGross || this.isSmallBusiness
              ? (!this.isPartnerPrivate || this.isSmallBusiness)
                ? col.propNet
                : col.propGross
              : col[priceProp[this.positionsSettings.priceOption]]
            : col.propDiscountPercent,
          columnName: this.positionsSettings.discountUnit === PdfSettingsDiscountUnitEnum.AMOUNT
            ? this.positionsSettings.showColumnNetGross || this.isSmallBusiness
              ? (!this.isPartnerPrivate || this.isSmallBusiness)
                ? discountPriceTitle[PdfSettingsPriceOptionEnum.NET]
                : discountPriceTitle[PdfSettingsPriceOptionEnum.GROSS]
              : discountPriceTitle[this.positionsSettings.priceOption]
            : 'COMMON.DISCOUNT',
          additionalText: this.positionsSettings.discountUnit === PdfSettingsDiscountUnitEnum.AMOUNT
            ? '€'
            : '%',
          currencySourceFormat: this.positionsSettings.discountUnit === PdfSettingsDiscountUnitEnum.AMOUNT
            ? col.currencySourceFormat || false
            : true
        };
      }

      if (col.type === DocumentInfoTypeEnum.UNIT_PRICE || col.type === DocumentInfoTypeEnum.AMOUNT_PRICE) {
        col = {
          ...col,
          additionalText: '€', // todo: add from workspace currency
          // replace prop with right option (for net or gross) based on conditions
          prop: this.positionsSettings.showColumnNetGross || this.isSmallBusiness
            ? (!this.isPartnerPrivate || this.isSmallBusiness)
              ? col.propNet
              : col.propGross
            : col[priceProp[this.positionsSettings.priceOption]],
        };
      }

      if (col.type === DocumentInfoTypeEnum.UNIT_PRICE) {
        return {
          ...col,
          width: col.width || 144,
          type: DocumentInfoTypeEnum.CURRENCY, // after filtering and preparing change type
          columnName: this.positionsSettings.showColumnNetGross || this.isSmallBusiness
            ? (!this.isPartnerPrivate || this.isSmallBusiness)
              ? unitPriceTitle[PdfSettingsPriceOptionEnum.NET]
              : unitPriceTitle[PdfSettingsPriceOptionEnum.GROSS]
            : unitPriceTitle[this.positionsSettings.priceOption],
        };
      }

      if (col.type === DocumentInfoTypeEnum.AMOUNT_PRICE) {
        return {
          ...col,
          width: col.width || 128,
          type: DocumentInfoTypeEnum.CURRENCY, // after filtering and preparing change type
          columnName: this.positionsSettings.showColumnNetGross || this.isSmallBusiness
            ? (!this.isPartnerPrivate || this.isSmallBusiness)
              ? amountPriceTitle[PdfSettingsPriceOptionEnum.NET]
              : amountPriceTitle[PdfSettingsPriceOptionEnum.GROSS]
            : amountPriceTitle[this.positionsSettings.priceOption],
        };
      }

      // if (col.type === DocumentInfoTypeEnum.DELIVERY_DATE) {
      //   col = {
      //     ...col,
      //     width: col.width || 160,
      //   }
      // }

      if (col.type === DocumentInfoTypeEnum.QUANTITY) {
        col = {
          ...col,
          width: col.width || 88,
        };
      }

      if (col.type === DocumentInfoTypeEnum.VAT) {
        col = {
          ...col,
          width: col.width || 56,
          prop: this.vatDisabled
            ? ''
            : col.prop,
          additionalText: this.vatDisabled
            ? ''
            : '%',
        };
        console.log(col);
      }

      return col;
    });
  }

  public getTopOffset(position, initialValue: number): number {
    let offset = initialValue;

    if (this.tableTitle && this.positionsSettings.showSubTableName) {
      offset += 24;
    }

    if (this.positionsSettings.showProductImg) {
      offset += 9;
    } else if (this.positionsSettings.showProductId) {
      if (this.positionsSettings.productIdType === this.productIdTypeEnum.INTERNAL) {
        if (position.runpleId || position.productRunpleId) {
          offset += 9;
        }
      } else if (this.positionsSettings.productIdType === this.productIdTypeEnum.MANUFACTURER) {
        if (position.manufacturerCode) {
          offset += 9;
        }
      }
    }

    return offset;
  }

  public onContextMenu(event: MouseEvent, item: any): void {
    event.preventDefault();
    event.stopPropagation();

    this.contextMenuComponent.onContextMenu(event, item);
  }

  public tableRowAction(position: PositionsModel, index: number, positions): void {
    // if (this.isReadOnly) { return; }
    this.tableRowActionEmit.emit({position: position, rowIndex: index, positions: positions});
  }

  public onSelect(selected, productType): void {
    if (this.isSingleSelected) {

      if (!this.selectedPositionsList.includes(selected)) {
        this.selectedPositionsList.push(selected);
      } else {
        this.selectedPositionsList = this.selectedPositionsList.filter(item => item.id !== selected.id);
      }
    } else {
      if (!this.selectedPositionsList[productType] || !this.selectedPositionsList[productType].includes(selected)) {
        if (!this.selectedPositionsList[productType]) {
          this.selectedPositionsList[productType] = [];
        }
        this.selectedPositionsList[productType].push(selected);
      } else {
        this.selectedPositionsList[productType] = this.selectedPositionsList[productType]
          .filter(item => (item.id || item.positionId) !== (selected.id || selected.positionId));
      }
    }

    this.selectedPosition.emit(this.selectedPositionsList);
  }

  public isSelectedPosition(position): boolean {
    if (!this.selectedPositionsList) { return false; }

    if (!this.isSingleSelected) {
      if (!this.selectedPositionsList[position.productTypeForGrouping || position.productType]) { return false; }
      return this.selectedPositionsList[position.productTypeForGrouping || position.productType].includes(position);
    }

    return this.selectedPositionsList.includes(position);
  }

  public actionListHandler(e) {
    this.actionListEmit.next(e);
  }

  public hideProductLinkCondition(positionType: PositionTypeEnum): boolean {
    return positionType === PositionTypeEnum.DELIVERY_STANDARD ||
           positionType === PositionTypeEnum.DELIVERY_EXPRESS ||
           positionType === PositionTypeEnum.PREPAYMENT ||
           positionType === PositionTypeEnum.INVOICE ||
           positionType === PositionTypeEnum.INSURANCE;
  }

  public getNegativeTotalAmountNet(): number|string {
    if (typeof this.totalAmountNet === 'string' && +this.totalAmountNet) {
      // for format '0,00', '10,56'
      return `-${this.totalAmountNet}`;
    }
    // for format 0, 540
    return this.totalAmountNet ? (+this.totalAmountNet * -1) : this.totalAmountNet;
  }

  public getNegativeTotalAmountGross(): number|string {
    if (typeof this.totalAmountGross === 'string' && +this.totalAmountGross) {
      // for format '0,00', '10,56'
      return `-${this.totalAmountGross}`;
    }
    // for format 0, 540
    return this.totalAmountGross ? (+this.totalAmountGross * -1) : this.totalAmountGross;
  }

  public get designSettings(): PdfSettingsDesignModel {
    if (!this.pdfSettings) { return null; }

    return this.pdfSettings.settings.design;
  }

  public get positionsSettings(): PdfSettingsPositionsModel {
    if (!this.pdfSettings) { return null; }

    return this.pdfSettings.settings.positions;
  }

  get isPartnerPrivate(): boolean {
    return this.partnerType && this.partnerType === CustomerTypeEnum.PRIVATE_PARTNER;
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
