import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ProductModel, ProductPriceModel, ProductsService, RelatedProductModel } from '../../../products';
import { ProductTypes } from '../../../products/product-types';
import { CustomSearchFn, getProductSearchValue } from 'common/src/modules/rnpl-common/helpers';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { ProductRelationDirectionEnum } from 'projects/workspace/src/app/products-two-level/enums';
import { CommonModalsActionsEnum, DangerModalComponent } from '../../modals-common';

@Component({
  selector: 'rnpl-product-relation-modal',
  templateUrl: './product-relation-modal.component.html',
  styleUrls: ['./product-relation-modal.component.scss']
})
export class ProductRelationModalComponent extends BaseModalComponent implements OnInit {
  public readonly productTypes = ProductTypes;

  public netPrice: number;
  public grossPrice: number;
  public vat: number;
  public available: number;
  public unitType: string;
  public productsList: ProductModel[] = [];
  public selectedProduct: ProductModel = null;
  public quantity: number = 1;
  public necessarily: boolean = false;
  public selectedProductInvalid: boolean = false;
  public quantityInvalid: boolean = false;
  public customSearchFn = CustomSearchFn;

  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    public store: Store<AppState>,
    public toasterService: ToasterService,
    public translateService: TranslateService,
    public dialog: MatDialog,
    private productsService: ProductsService,
    public dialogRef: MatDialogRef<ProductRelationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      productId: number,
      relatedProduct?: RelatedProductModel,
      relatedProductDirection: ProductRelationDirectionEnum
    }
  ) {
    super(toasterService);
  }

  ngOnInit() {
    this.getProducts();
    if (this.data.relatedProduct) {
      this.quantity = this.data.relatedProduct.quantity;
      this.necessarily = this.data.relatedProduct.necessarily;
      this.netPrice = this.data.relatedProduct.netUnitPrice;
      this.grossPrice = this.data.relatedProduct.grossUnitPrice;
      this.vat = this.data.relatedProduct.vat;
      this.available = this.data.relatedProduct.available;
      this.unitType = this.data.relatedProduct.unitType;
    }
  }

  private getProducts(): void {
    this.showDropdownSpin$.next(true);
    this.productsService.getActiveProducts()
      .pipe(takeUntil(this._destroy))
      .subscribe((products: ProductModel[]) => {
        this.productsList = this.prepareProductsList(products);

        if (this.data.relatedProduct.productId) {
          this.selectedProduct = this.productsList.find(p => +p.id === +this.data.relatedProduct.productId);
          this.productSelected();
        }
      });
  }

  public prepareProductsList(products): ProductModel[] {
    return products
      .filter(item => +item.id !== this.data.productId)
      .map(item => ({
        ...item,
        searchLabel: getProductSearchValue(item, this.translateService)
      }));
  }

  public productSelected(): void {
    this.available = this.selectedProduct.available;
    this.unitType = this.selectedProduct.unit_type;

    this.productsService.getProduct(+this.selectedProduct.id, null, true)
      .pipe(takeUntil(this._destroy))
      .subscribe((product: ProductModel) => {
        this.netPrice = product.prices.price.netPrice;
        this.grossPrice = product.prices.price.grossPrice;
        this.vat = product.prices.price.vat;
        this.unitType = product.unitType;
      });
  }

  public updateRelation(fieldValue: any, fieldName: string): void {
    if (fieldName === 'quantity') {
      this.productsService.getProductVolumePrice(+this.selectedProduct.id, fieldValue)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: Partial<ProductPriceModel>) => {
          this.netPrice = res.netPrice;
          this.grossPrice = res.grossPrice;
        });
    }

    if (!this.data.relatedProduct.id) { return; }

    this.productsService.updateRelatedProduct(
      +this.data.productId,
      this.data.relatedProduct.id,
      this.data.relatedProductDirection,
      fieldName,
      fieldValue
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  public deleteRelation(): void {
    const dialog = this.dialog.open(DangerModalComponent, {
      data: {
        title: 'PRODUCTS_VARIATIONS.REMOVE_RELATION_TITLE',
        message: 'PRODUCTS_VARIATIONS.REMOVE_RELATION_MSG',
      }
    });

    dialog.afterClosed().subscribe((response: CommonModalsActionsEnum) => {
      if (response === CommonModalsActionsEnum.CONFIRM) {
        this.productsService.deleteRelatedProducts(
          +this.data.productId,
          [this.data.relatedProduct.id],
          this.data.relatedProductDirection
        )
          .pipe(takeUntil(this._destroy))
          .subscribe(() => this.dialogRef.close(true));
      }
    });
  }

  public addRelatedProduct(): void {
    if (!this.selectedProduct) {
      this.selectedProductInvalid = true;
      return;
    }
    if (!this.quantity && this.quantity !== 0) {
      this.quantityInvalid = true;
      return;
    }

    this.productsService
      .addRelatedProduct(
        this.data.productId,
        +this.selectedProduct.id,
        this.data.relatedProductDirection,
        this.quantity,
        this.necessarily,
      )
      .pipe(takeUntil(this._destroy))
      .subscribe((response: ProductModel) => this.dialogRef.close(response));
  }

}
