import { Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[dynamic-button-list-position]'
})
export class ButtonListPositionsDirective implements OnInit, OnDestroy {

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.checkWidth();
    window.addEventListener('resize', () => this.checkWidth());
  }

  private checkWidth() {
    const element = this.el.nativeElement;
    const modalContainer =  document.querySelector('.mat-dialog-container');
    const isOverflowing = modalContainer.getBoundingClientRect().left + modalContainer.getBoundingClientRect().width + element.scrollWidth - 32 > window.innerWidth;

    if (isOverflowing) {
      this.renderer.addClass(element, 'left');
    } else {
      this.renderer.removeClass(element, 'left');
    }
  }

  ngOnDestroy() {
    window.removeEventListener('resize', () => this.checkWidth(), true);
  }
}
