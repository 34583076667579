import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { ReplaySubject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { MetricConversionPipe } from '../../../rnpl-common';
import { ActionButtonsService } from '../../../../services/action-buttons.service';
import { SalesOrderModel } from 'projects/workspace/src/app/sales-order/models/sales-order.model';
import { SalesPosition } from 'projects/workspace/src/app/sales-order/models/sales-order-position.model';
import { generatePositionsCardsInfo } from 'projects/workspace/src/app/e-commerce/modules/eco-orders/pages/eco-order-positions/positions.config';
import { ProductTypes } from '../../../products/product-types';
import { UIStatesEnum } from '../../../../models';
import { TableColumnModel } from '../../../../models/table-column.model';
import { ECommerceApiService } from 'projects/workspace/src/app/e-commerce/services';
import {
  selectEcoOrderCurrentState,
  selectEcoOrderPositions
} from 'projects/workspace/src/app/e-commerce/modules/eco-orders/store/selectors';
import { ProductUnitModel, ResponseModel } from 'projects/workspace/src/app/shared/models';
import { SalesPositionsByProductType } from 'projects/workspace/src/app/e-commerce/modules/eco-orders/models/sales-order-position.model';
import {
  ControlTypeEnum,
  DisplayConditionsModel,
  PositionInfoListModel,
  PositionItemTypeEnum
} from '../../../position-card/position-card-models.model';
import { CommonModalsActionsEnum, DangerModalComponent } from '../../modals-common';
import { REMOVE_POSITION_MODAL_DATA } from '../../modals.contsans';
import { ProductUnitApiService } from 'projects/workspace/src/app/shared/services';

@Component({
  selector: 'rnpl-eco-edit-position-modal',
  templateUrl: './eco-edit-position-modal.component.html',
  styleUrls: ['./eco-edit-position-modal.component.scss']
})
export class EcoEditPositionModalComponent implements OnInit {
  public position: SalesPosition;
  public positions: SalesPosition[];
  public rowIndex: number;
  public currentState: UIStatesEnum;
  public positionItemTypeEnum: typeof PositionItemTypeEnum = PositionItemTypeEnum;
  public controlTypeEnum: typeof ControlTypeEnum = ControlTypeEnum;
  public unitsList: string[] = [];
  public unitsListSource: ProductUnitModel[] = [];
  public isReadonly: boolean;
  public showControls: boolean = true;
  public positionsCardInfoByTypes: {[key in ProductTypes]?: any[]} = {
    [ProductTypes.GOODS]: [] as TableColumnModel[],
    [ProductTypes.SERVICES]: [] as TableColumnModel[],
    [ProductTypes.DIGITAL]: [] as TableColumnModel[],
  };

  public readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    public store: Store<AppState>,
    public dialogRef: MatDialogRef<EcoEditPositionModalComponent>,
    private fb: FormBuilder,
    private metricPipe: MetricConversionPipe,
    private readonly dialog: MatDialog,
    public actionButtonsService: ActionButtonsService,
    private readonly translate: TranslateService,
    private readonly eCommerceApiService: ECommerceApiService,
    private productUnitApiService: ProductUnitApiService,
    @Inject(MAT_DIALOG_DATA) public data: {
      currentEcoOrder: SalesOrderModel;
      position: SalesPosition,
      positions: SalesPosition[],
      rowIndex: number
    }
  ) { }

  ngOnInit() {
    this.position = {
      ...this.data.position,
      marginData: {
        profit: this.data.position.marginNet,
        percent: this.data.position.marginRate,
        currency: '€'
      },
    };
    this.positions = this.data.positions;
    this.rowIndex = this.getRowIndex(this.position);

    this.getProductUnits();
    this.getPositionsCards();
    this.getCurrentEcoOrder();
    this.trackSalesOrderStateChanges();
  }

  public getProductUnits(): void {
    this.productUnitApiService.getUnits$(this.position.productType)
      .pipe(takeUntil(this.destroy$))
      .subscribe((unitsList: ProductUnitModel[]) => {
        this.unitsListSource = unitsList;
        if (!this.position.general) {
          this.unitsList = [this.position.unitType];
          return;
        } else {
          this.unitsList = unitsList.map(u => u.name);
        }
        this.redrawControls();
      });
  }

  private trackSalesOrderStateChanges(): void {
    this.store.select(selectEcoOrderCurrentState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: UIStatesEnum) => {
        this.isReadonly = state === UIStatesEnum.VIEW || !this.canEditPositions;
      });
  }

  private getCurrentEcoOrder(): void {
    this.eCommerceApiService.getECOById(this.data.currentEcoOrder.id).subscribe();
  }

  private getPositionsCards(): void {
    if (this.data.currentEcoOrder) {
      this.positionsCardInfoByTypes[this.position.productType] = generatePositionsCardsInfo(
        this.translate,
        this.position.productType,
        UIStatesEnum.CREATE,
        this.data.currentEcoOrder.status,
        this.data.currentEcoOrder.properties.vatDisabled
      );
    }
  }

  public getRowIndex(position): number {
    let rowIndex: number;
    this.positions.map((pos, index) => {
      if (pos.id === position.id) {
        rowIndex = index;
      }
    });
    return rowIndex;
  }

  private trackPositionsChanges(): void {
    this.store.select(selectEcoOrderPositions)
      .pipe(takeUntil(this.destroy$))
      .subscribe((positions: ResponseModel<any>) => { // todo type
        if (positions) {
          this.positions = positions.data[this.position.productType];
        }
        this.rowIndex = this.getRowIndex(this.position);
      });
  }

  public updatePosition(fieldName, fieldValue): void {
    this.eCommerceApiService
      .updateEcoOrderPosition(
        this.data.currentEcoOrder.id,
        +this.position.id,
        {
          fieldName: fieldName,
          fieldValue: fieldValue
        },
        this.data.currentEcoOrder.id
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.position = {
          ...response.data,
          marginData: {
            profit: response.data.marginNet,
            percent: response.data.marginRate,
            currency: '€'
          },
        };
        this.getCurrentEcoOrder();
      }, () => {
        if (fieldName === 'unitType') {
          this.redrawControls();
        }
      });
  }

  public redrawControls(): void {
    this.showControls = false;
    setTimeout(() => this.showControls = true);
  }

  public loadPositions(): void {
    this.eCommerceApiService.getEcoOrderPositions(this.data.currentEcoOrder.id)
      .pipe(
        debounceTime(1000),
        takeUntil(this.destroy$)
      )
      .subscribe((response: ResponseModel<SalesPositionsByProductType>) => {
        // updated via store
      });
  }

  public actionListHandler({row, moveTo}) {
    this.movePosition(row.id, moveTo);
  }

  private movePosition(posId: number, moveTo: number): void {
    // this.eCommerceApiService.changePositionOrder(posId, moveTo)
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((data) => {
    //     this.position = data;
    //     this.trackPositionsChanges();
    //     this.getCurrentEcoOrder();
    //   });
  }

  public trackByProp(index: number, item: PositionInfoListModel) {
    return item.prop;
  }

  public updateDatePicker(event, fieldName): void {
    this.updatePosition(fieldName, event.value ? new Date(event.value).toLocaleDateString('fr-CA') : null);
  }

  public deletePositionDialog(): void {
    const dialog = this.dialog.open(DangerModalComponent, {
      data: REMOVE_POSITION_MODAL_DATA,
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === CommonModalsActionsEnum.CONFIRM) {
        this.deletePositions([this.position.id]);
      }
    });
  }

  private deletePositions(ids: number[]): void {
    this.eCommerceApiService.deleteEcoOrderPositions(this.data.currentEcoOrder.id, ids)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: ResponseModel<null>) => {
        this.eCommerceApiService.getECOById(this.data.currentEcoOrder.id).subscribe();
      });
  }

  public displayConditionsHandler(position, conditionsList: DisplayConditionsModel[]): boolean {
    if (!conditionsList && !conditionsList.length) { return false; }

    return conditionsList.every(condition => {
      return condition.values.some((val) => {
        if (!!condition.productType) {
          return position[condition.prop] === val && condition.productType === position.productTypeForGrouping;
        } else {
          return position[condition.prop] === val;
        }
      });
    });
  }

  public displayProductInfo(): void {
    this.actionButtonsService.displaySidebarHandler(this.position.productId);
  }

  get allowFractionalValues(): boolean {
    if (!this.position.unitType || !this.unitsListSource.length) { return true; }
    const selectedUnit: ProductUnitModel = this.unitsListSource
      .find((itm: ProductUnitModel) => itm.name === this.position.unitType);
    const isFractionalQuantity: boolean = !!(this.position.quantity % 1);
    return (selectedUnit && !isFractionalQuantity)
      ? selectedUnit.allowFractionalValues
      : isFractionalQuantity;
  }

  get isGoods(): boolean {
    return this.position.productType === ProductTypes.GOODS && !this.position.general;
  }

  get canEditPositions(): boolean {
    return !this.data.currentEcoOrder.linkedDocuments.length ||
      (
        !!!this.data.currentEcoOrder.linkedDocuments
          .filter(doc => doc.type === 'outgoing_invoice')
          .filter(doc => doc.status === 'paid' || doc.status === 'open')
          .length
        &&
        !!!this.data.currentEcoOrder.linkedDocuments
          .filter(doc => doc.type === 'delivery_note')
          .filter(doc => doc.status === 'packing' || doc.status === 'packed_up' || doc.status === 'shipped' || doc.status === 'open')
          .length
      )
  }

}
