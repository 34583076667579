import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { get } from 'lodash';

import { MetricConversionPipe } from 'common/src/modules/rnpl-common';
import { ProductTypes } from 'common/src/modules/products/product-types';
import { BaseFormComponent } from 'projects/workspace/src/app/crm/partner-forms/components/base-form.component';
import { FormInputChangedModel } from 'projects/workspace/src/app/shared/models/form-input-value.model';
import { ProductUnitApiService } from 'projects/workspace/src/app/shared/services';
import { ProductUnitModel } from 'projects/workspace/src/app/shared/models';

@Component({
  selector: 'rnpl-product-form-hardcode',
  templateUrl: './product-form-hardcode.component.html',
  styleUrls: ['./form-hardcode.scss']
})
export class ProductFormHardcodeComponent extends BaseFormComponent implements OnInit, OnChanges, OnDestroy {

  @Input() productData: any;
  @Input() productType: ProductTypes = ProductTypes.GOODS; // todo remove default value
  // @Input() readonly: boolean = false;
  @Input() appendTo: string = 'body';
  @Input() counterAnimation: boolean = false;

  @Output() fieldChanged: EventEmitter<FormInputChangedModel> = new EventEmitter<FormInputChangedModel>();

  public controlId = Math.floor(Math.random() * 1000);
  public unitsList: ProductUnitModel[] = [];

  public readonly productTypes = ProductTypes;

  public lengthValues: string[] = ['mm', 'cm', 'm'];
  public weightValues: string[] = ['gr', 'kg', 't'];

  public unitValue: string = 'mm';

  public unitValueWeight: string = 'gr';

  public metricConversion = 'mm-mm';

  public metricConversionWeight = 'gr-gr';

  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private fb: FormBuilder,
    private metricPipe: MetricConversionPipe,
    private cdr: ChangeDetectorRef,
    private productUnitApiService: ProductUnitApiService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.form) { // no need to init form if its already inited at ngOnChanges
      this.initForm();
    }
    this.updateFormEditingStatus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('readonly')) {
      this.updateFormEditingStatus();
    }
    if (changes.hasOwnProperty('productType')) {
      this.getProductUnits();
    }
    if (changes.hasOwnProperty('productData') || changes.hasOwnProperty('productType')) {
      // update default values before form reiniting
      this.unitValue = 'mm';
      this.unitValueWeight = 'gr';
      this.initForm();
    }
  }

  public getProductUnits(): void {
    this.productUnitApiService.getUnits$(this.productType)
      .pipe(takeUntil(this.destroy$))
      .subscribe((unitsList: ProductUnitModel[]) => this.unitsList = unitsList);
  }

  public initForm(): void {
    this.form = new FormGroup({});

    switch (this.productType) {
      case ProductTypes.GOODS:
        this.form = this.fb.group({
          length: [+get(this.productData, 'length') || null, [Validators.required]],
          width: [+get(this.productData, 'width') || null, [Validators.required]],
          height: [+get(this.productData, 'height') || null, [Validators.required]],
          weight: [+get(this.productData, 'weight') || null, [Validators.required]],
          areaUnitType: [get(this.productData, 'areaUnitType') || 'mm'],
          weightUnitType: [get(this.productData, 'weightUnitType') || 'gr'],
          specifySizeAndWeight: [get(this.productData, 'specifySizeAndWeight', false)],
          unitType: [get(this.productData, 'unitType', 'pcs')],
        });

        this.specifySizeAndWeightChanged(get(this.productData, 'specifySizeAndWeight', true), false);
        this.valueHandler(this.areaUnitType.value, false);
        this.valueHandlerWeight(this.weightUnitType.value, false);
        break;
      case ProductTypes.SERVICES:
        this.form = this.fb.group({
          unitType: [get(this.productData, 'unitType', 'pcs')],
        });
        break;
      case ProductTypes.DIGITAL:
        this.form = this.fb.group({
          unitType: [get(this.productData, 'unitType', 'pcs')],
          // format: [get(this.productData, 'format') || null],
          // operationSystem: [get(this.productData, 'operationSystem') || null],
          // size: [get(this.productData, 'size') || null],
        });
        break;
    }
  }

  public getFormData(): any {

    const formData = this.form.getRawValue();

    if (this.productType === ProductTypes.GOODS) {
      if (this.unitValue !== 'mm') {
        formData.length = this.metricPipe.transform(formData.length, `${this.unitValue}-mm`);
        formData.width = this.metricPipe.transform(formData.width, `${this.unitValue}-mm`);
        formData.height = this.metricPipe.transform(formData.height, `${this.unitValue}-mm`);
      }

      if (this.unitValueWeight !== 'gr') {
        formData.weight = this.metricPipe.transform(formData.weight, `${this.unitValueWeight}-gr`);
      }
    }

    return {...formData};
  }

  public updateField(fieldValue: any, fieldName: string): void {
    this.fieldChanged.emit({fieldName, fieldValue});
  }

  public updateLengthField(fieldValue: any, fieldName: string): void {
    this.updateField(
      this.metricPipe.transform(fieldValue, `${this.unitValue}-mm`),
      fieldName
    );
  }

  public updateWeightField(fieldValue: any, fieldName: string): void {
    this.updateField(
      this.metricPipe.transform(fieldValue, `${this.unitValueWeight}-gr`),
      fieldName
    );
  }

  public specifySizeAndWeightChanged(isSpecify: boolean, isUpdateField: boolean): void {
    const controlsToDisable = ['length', 'width', 'height', 'weight', 'areaUnitType', 'weightUnitType'];

    // if toggle activated clear and disable dimensions fields
    if (!isSpecify) {
      controlsToDisable.forEach(controlName => {
        const control = this.form.get(controlName);
        if (!control) { return; }

        switch (controlName) {
          case 'areaUnitType':
            control.setValue('mm');
            this.unitValue = 'mm';
            break;
          case 'weightUnitType':
            control.setValue('gr');
            this.unitValueWeight = 'gr';
            break;
          default:
            control.setValue(null);
            break;
        }
        control.disable();
        this.cdr.detectChanges();
      });
    }

    // if toggle deactivated enable dimensions fields
    if (isSpecify && !this.readonly) {
      controlsToDisable.forEach(controlName => {
        const control = this.form.get(controlName);
        if (!control) { return; }

        control.enable();
        this.cdr.detectChanges();
      });
    }

    this.setSizeAndWeightValidators();

    if (isUpdateField) {
      this.updateField(isSpecify, 'specifySizeAndWeight');
    }
  }

  public setSizeAndWeightValidators(): void {
    const controlsNames = ['length', 'width', 'height', 'weight'];
    const someHasValue = controlsNames.some((name: string) => this.form.get(name).value);

    controlsNames.forEach(controlName => {
      const control = this.form.get(controlName);
      if (!control) { return; }

      if (!someHasValue || control.value) {
        control.setValidators(Validators.required);
      } else {
        control.clearValidators();
      }
      this.cdr.detectChanges();
    });
  }

  public valueHandler(value: string, isUpdateField: boolean): void {
    if (this.unitValue !== value) {
      this.areaUnitType.setValue(value);
      this.metricConversion = `${this.unitValue}-${value}`;
      this.unitValue = value;
      this.updateFormMetric();

      if (isUpdateField) {
        this.updateField(value, 'areaUnitType');
      }
    }
  }

  public valueHandlerWeight(value: string , isUpdateField: boolean): void {
    if (this.unitValueWeight !== value) {
      this.weightUnitType.setValue(value);
      this.metricConversionWeight = `${this.unitValueWeight}-${value}`;
      this.unitValueWeight = value;
      this.updateFormMetricWeight();

      if (isUpdateField) {
        this.updateField(value, 'weightUnitType');
      }
    }
  }

  public updateFormMetric(): void {
    const toUpdateList = [
      'length',
      'width',
      'height'
    ];
    toUpdateList.forEach((item) => {
      this[item].setValue(
        this.metricPipe.transform(Number(this[item].value), this.metricConversion)
      );
    });
  }

  public updateFormMetricWeight(): void {
    const toUpdateList = [
      'weight'
    ];
    toUpdateList.forEach((item) => {
      this[item].setValue(
        this.metricPipe.transform(Number(this[item].value), this.metricConversionWeight)
      );
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  get length() { return this.form.get('length'); }
  get width() { return this.form.get('width'); }
  get height() { return this.form.get('height'); }
  get weight() { return this.form.get('weight'); }

  get areaUnitType() { return this.form.get('areaUnitType'); }
  get weightUnitType() { return this.form.get('weightUnitType'); }

  get specifySizeAndWeight() { return this.form.get('specifySizeAndWeight'); }

}
