import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { get } from 'lodash';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ProductModel, ProductsService, RelatedProductModel } from '../../../products';
import { TradeOfferPositionsByProductType } from 'projects/workspace/src/app/trade-offer/models';
import { TradeOfferApiService } from 'projects/workspace/src/app/trade-offer/services/trade-offer-api.service';
import { TradePositionModel } from 'projects/workspace/src/app/trade/models';
import { ResponseModel } from 'projects/workspace/src/app/shared/models/response';
import { ProductTypes } from '../../../products/product-types';
import { CompositeProductModalComponent } from '../../modals-products';
import { CustomSearchFn, getProductSearchValue } from 'common/src/modules/rnpl-common/helpers';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { VAT_LIST } from 'projects/workspace/src/app/shared/constants/vat-list';
import { ActionButtonsService } from '../../../../services/action-buttons.service';
import { DocumentTypesUppercaseEnum } from '../../modals-common/link-document-modal/enums/ducument-types.enum';
import { RecalculatePositionApiService } from '../../../../services/recalculate-position-api.service';
import { ProductUnitModel } from 'projects/workspace/src/app/shared/models';
import { ProductUnitApiService } from 'projects/workspace/src/app/shared/services';

@Component({
  selector: 'rnpl-position-modal',
  templateUrl: './offer-position-modal.component.html',
  styleUrls: ['./offer-position-modal.component.scss']
})
export class OfferPositionModalComponent extends BaseModalComponent implements OnInit, OnDestroy { // todo

  public unitsList: string[] = [];
  public unitsListSource: ProductUnitModel[] = [];
  public companyProfile: CompanyProfile;
  public previousUnitType: string;

  public productTypeRadio: 'common'|'general' = 'common';
  public currentStep: 'positionInfo'|'relatedProducts' = 'positionInfo';

  public readonly productTypes = ProductTypes;

  public selectedProductInvalid: boolean = false;

  public productsList: ProductModel[] = [];
  public relatedProducts: RelatedProductModel[] = [];

  public selectedProduct: ProductModel = null;
  public selectedProductType: ProductTypes|string = ProductTypes.GOODS;

  public marginTotal: number = null;
  public marginPercent: number = null;

  public customSearchFn = CustomSearchFn;

  public form: FormGroup;
  public readonly vatList = VAT_LIST;

  readonly generalProductsList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly addPositionLoadingRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);


  constructor(
    public store: Store<AppState>,
    public toasterService: ToasterService,
    public translateService: TranslateService,
    public productsService: ProductsService,
    public tradeOfferApiService: TradeOfferApiService,
    public dialogRef: MatDialogRef<OfferPositionModalComponent>,
    public dialog: MatDialog,
    private fb: FormBuilder,
    public actionButtonsService: ActionButtonsService,
    private recalculatePositionApiService: RecalculatePositionApiService,
    private productUnitApiService: ProductUnitApiService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      tradeOfferId: number;
      salesPriceListName: string;
      vatBlocked: boolean;
    }
  ) {
    super(toasterService);

    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((companyProfile: CompanyProfile) => this.companyProfile = companyProfile);
  }

  ngOnInit() {
    this.initForm(this.selectedProduct);
    this.getProducts();
    this.getGeneralProductsListDropdown();
    this.productTypeControl.setValue(ProductTypes.GOODS);

    this.dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.actionButtonsService.hideSidebarHandler());
  }

  private getGeneralProductsListDropdown(): void {
    this.productsService.getGeneralProductsListDropdown(false)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        const filteredGeneralProducts = response
          .filter(glProduct => glProduct.entityForm === 'services')
          .filter(glProduct => glProduct.isVisibleForList);
        this.generalProductsList$.next(filteredGeneralProducts);
      });
  }

  private initForm(selectProduct: ProductModel = {} as ProductModel): void {
    this.form = this.fb.group({
      grossTotalPrice: [this.setCurrencyValue(get(selectProduct, 'prices.price.grossPrice')), Validators.required],
      grossUnitPrice: [this.setCurrencyValue(get(selectProduct, 'prices.price.grossPrice')), Validators.required],
      netTotalPrice: [this.setCurrencyValue(get(selectProduct, 'prices.price.netPrice')), Validators.required],
      productDescription: [get(selectProduct, 'description')],
      providedServices: [get(selectProduct, 'description')],
      netUnitPrice: [this.setCurrencyValue(get(selectProduct, 'prices.price.netPrice')), Validators.required],
      vat: [
        get(selectProduct, 'prices.price.vat') || get(selectProduct, 'prices.price.vat') === 0
          ? get(selectProduct, 'prices.price.vat')
          : get(selectProduct, 'accountingSettings.vatRateByDefault')
      ],
      discount: [get(selectProduct, 'prices.price.discount')],
      discountAmountNet: [this.setCurrencyValue(get(selectProduct, 'prices.price.discountNet'))],
      discountAmountGross: [this.setCurrencyValue(get(selectProduct, 'prices.price.discountGross'))],
      addDiscount: [!!get(selectProduct, 'prices.price.discount')],
      quantity: [1],
      addRemark: [false],
      remark: [],
      productType: [],
      unitType: [get(selectProduct, 'unitType') || 'pcs'],
    });
  }

  selectPositionChanges(selectedProduct: ProductModel): void {
    this.selectedProductType = selectedProduct.type || selectedProduct.productType;
    if (this.productTypeRadio === 'general') {
      this.selectedProductType = ProductTypes.GENERAL;
    }

    if (this.data.vatBlocked) {
      this.vat.disable({onlySelf: true, emitEvent: false});
    }

    this.getProductUnits();
    this.getProductById(+selectedProduct.id);
  }

  selectVariation(selectedVariation: ProductModel): void {
    this.selectedProduct = this.productsList.find(itm => +itm.id === +selectedVariation.productId);
    this.selectPositionChanges(this.selectedProduct);
  }

  public getProductUnits(): void {
    this.productUnitApiService.getUnits$(this.selectedProduct.type || this.selectedProduct.entityForm)
      .pipe(takeUntil(this.destroy$))
      .subscribe((unitsList: ProductUnitModel[]) => {
        this.unitsListSource = unitsList;
        this.unitsList = unitsList.map(u => u.name);
      });
  }

  get selectedGLProductType(): any {
    if (!this.generalProductsList$.getValue().length || !this.selectedProduct) { return null; }

    return this.generalProductsList$.getValue().find(generalProduct => generalProduct.id === this.selectedProduct.id);
  }

  public getProductById(productId) {
    this.productsService.getProduct(productId, this.data.salesPriceListName)
      .pipe(takeUntil(this._destroy))
      .subscribe(product => {
        this.initForm(product);
        if (this.data.vatBlocked) {
          const defaultPrice = this.setCurrencyValue(get(product, 'prices.price.netPrice'));
          this.vat.setValue(0);
          this.grossUnitPrice.setValue(defaultPrice);
          this.grossTotalPrice.setValue(defaultPrice);
        }

        this.relatedProducts = product.outgoingRelatedProducts.map(itm => ({
          ...itm,
          enabled: itm.necessarily
        }));

        this.marginTotal = null;
        this.marginPercent = null;
        this.updatePosition(1, 'quantity');
    });
  }

  private getProducts(): void {
    this.productsService.getActiveProducts(undefined, undefined, this.data.salesPriceListName)
      .pipe(takeUntil(this._destroy))
      .subscribe((products: ProductModel[]) => {
        this.productsList = this.prepareProductsList(products);
        this.showDropdownSpin$.next(false);
      });
  }

  public prepareProductsList(products): ProductModel[] {
    return products.map(item => ({
      ...item,
      searchLabel: getProductSearchValue(item, this.translateService)
    }));
  }

  public createProduct(productType: ProductTypes): void {
    const dialog = this.dialog.open(CompositeProductModalComponent, {
      data: {
        family: null,
        productType: productType
      },
      disableClose: true,
    });

    dialog.afterClosed().subscribe((product: ProductModel) => {
      if (product) {
        this.productsList.push(product);
        this.selectedProduct = product;
        this.selectedProductType = product.productType;
        this.selectPositionChanges(product);
      }
    });
  }

  public addOfferPosition(): void {
    if (!this.selectedProduct) {
      this.selectedProductInvalid = true;
      return;
    }

    const position: Partial<TradePositionModel> = {
      productType: this.selectedProductType as any
    };

    if (this.selectedProduct) {
      position.productId = +this.selectedProduct.id;
    }

    this.createTradeOfferPosition();
  }

  public createTradeOfferPosition() {
    if (this.addPositionLoadingRequest$.getValue()) { return; }
    this.addPositionLoadingRequest$.next(true);

    this.tradeOfferApiService
      .createTradeOfferPosition(
        this.data.tradeOfferId,
        {
          ...this.form.getRawValue(),
          grossTotalPrice: this.setCurrencyControl(this.grossTotalPrice.value),
          grossUnitPrice: this.setCurrencyControl(this.grossUnitPrice.value),
          netTotalPrice: this.setCurrencyControl(this.netTotalPrice.value),
          netUnitPrice: this.setCurrencyControl(this.netUnitPrice.value),
          productId: this.selectedProduct.id,
          productType: this.selectedProductType,
          productTypeForGrouping: this.selectedProductType,
          productDescription: !this.isServicesProductType() && !this.isGeneralServiceType
            ? this.productDescription.value
            : null,
          providedServices: this.isServicesProductType() || this.isGeneralServiceType
            ? this.providedServices.value
            : null,
          productRelatedIds: this.relatedProducts
            ? this.relatedProducts
              .filter(itm => itm.enabled)
              .map(itm => itm.id)
            : []
        })
      .pipe(
        finalize(() => this.addPositionLoadingRequest$.next(false)),
        takeUntil(this._destroy))
      .subscribe(this.subscribeHandler);
  }

  public setCurrencyControl(val): number {
    if (!val) { return val; }
    return +(val * 100).toFixed(0);
  }

  public subscribeHandler = (response: ResponseModel<TradeOfferPositionsByProductType>) => {
    this.tradeOfferApiService.getTradeOfferById(this.data.tradeOfferId).subscribe();
    this.dialogRef.close(response);
  }

  public updatePosition(fieldValue, fieldName): void {
    this.recalculatePositionApiService.recalculatePosition(DocumentTypesUppercaseEnum.OFR, {
      protoPosition: {
        ...this.form.getRawValue(),
        salesPriceListName: this.data.salesPriceListName,
        productId: this.selectedProduct.id,
        productTypeForGrouping: this.selectedProductType,
        productDescription: !this.isServicesProductType() && !this.isGeneralServiceType
          ? this.productDescription.value
          : null,
        providedServices: this.isServicesProductType() || this.isGeneralServiceType
          ? this.providedServices.value
          : null,
      },
      fieldUpdateRequest: {
        fieldName,
        fieldValue
      },
      type: this.selectedProductType
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe(product => {
        this.form.patchValue(product);
        this.previousUnitType = product.unitType;
        this.selectedProduct = {
          ...this.selectedProduct,
          description: this.selectedProductType === ProductTypes.SERVICES ? product.providedServices : product.productDescription
        };

        this.marginPercent = product.marginPercent;
        this.marginTotal = product.marginTotal;
      }, () => {
        if (fieldName === 'unitType') {
          // set previous value of unit type in case of error
          this.unitTypeControl.patchValue(this.previousUnitType);
          // redraw quantity control view
          setTimeout(() => this.quantity.patchValue(this.quantity.value), 10);
        }
      });
  }

  public updateDescription(description: string): void {
    this.selectedProduct = {
      ...this.selectedProduct,
      description: description
    };
  }

  public setCurrencyValue(value): number {
    if (!value) { return value; }
    return +(value / 1e2).toFixed(2);
  }

  public updateDescriptionByProductType(): void {
   if (this.selectedProductType === ProductTypes.SERVICES || this.isGeneralServiceType) {
       this.updatePosition(this.providedServices.value, 'providedServices');
   } else {
     this.updatePosition(this.productDescription.value, 'productDescription');
   }
  }

  public isServicesProductType(): boolean {
    return this.selectedProductType === ProductTypes.SERVICES;
  }

  get isGeneralServiceType(): boolean {
    if (!this.selectedProduct) { return false; }
    return this.selectedProduct.entityForm === ProductTypes.SERVICES;
  }

  get isGeneralProductType(): boolean {
    return this.selectedProductType === ProductTypes.GENERAL;
  }
  get typeServices(): boolean {
    return this.selectedProductType === ProductTypes.SERVICES;
  }
  get typeGoods(): boolean {
    return this.selectedProductType === ProductTypes.GOODS;
  }

  get allowFractionalValues(): boolean {
    if (!this.unitTypeControl.value || !this.unitsListSource.length) { return true; }
    return this.unitsListSource
      .find((itm: ProductUnitModel) => itm.name === this.unitTypeControl.value)
      .allowFractionalValues;
  }

  get grossTotalPrice(): FormControl { return this.form.get('grossTotalPrice') as FormControl; }
  get grossUnitPrice(): FormControl { return this.form.get('grossUnitPrice') as FormControl; }
  get addRemark(): FormControl { return this.form.get('addRemark') as FormControl; }
  get netTotalPrice(): FormControl { return this.form.get('netTotalPrice') as FormControl; }
  get netUnitPrice(): FormControl { return this.form.get('netUnitPrice') as FormControl; }
  get productDescription(): FormControl { return this.form.get('productDescription') as FormControl; }
  get providedServices(): FormControl { return this.form.get('providedServices') as FormControl; }
  get quantity(): FormControl { return this.form.get('quantity') as FormControl; }
  get unitTypeControl(): FormControl { return this.form.get('unitType') as FormControl; }
  get remark(): FormControl { return this.form.get('remark') as FormControl; }
  get vat(): FormControl { return this.form.get('vat') as FormControl; }
  get discount(): FormControl { return this.form.get('discount') as FormControl; }
  get discountAmountNet(): FormControl { return this.form.get('discountAmountNet') as FormControl; }
  get discountAmountGross(): FormControl { return this.form.get('discountAmountGross') as FormControl; }
  get addDiscount(): FormControl { return this.form.get('addDiscount') as FormControl; }
  get productTypeControl(): FormControl { return this.form.get('productType') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
