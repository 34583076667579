import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { get, isEmpty } from 'lodash';
import { Store } from '@ngrx/store';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { selectCompanyProfile, selectValidations } from 'projects/workspace/src/app/administration/store/selectors';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { AdministrationsApiService } from 'projects/workspace/src/app/administration/services/administrations-api.service';
import { AddressFormFactory } from 'projects/workspace/src/app/shared/forms/address-form.factory';
import { ValidationService } from 'projects/workspace/src/app/shared/services/validation.service';
import { ResponseModel, Validation } from 'projects/workspace/src/app/shared/models/response';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { CountryModel } from '../../../rnpl-common';
import { AddressModel } from '../../../../models';
import { SystemSettingsService } from '../../../system-settings';
import { InitialSetupBaseComponent } from '../initial-setup-base/initial-setup-base.component';

@Component({
  selector: 'rnpl-legal-info',
  templateUrl: './legal-info.component.html'
})
export class LegalInfoComponent extends InitialSetupBaseComponent implements OnInit, OnDestroy {

  // public legalTypeItems$: Observable<LegalType[]> = this.store.select(selectLegalTypes);
  public validations$: Observable<Validation[]> = this.store.select(selectValidations);

  // public loggedUser: AuthUserModel;
  public countries: CountryModel[];

  public form: FormGroup = new FormGroup({});
  // public accountingSettings: AccountingSettingsModel = null;
  // public forwardReportingType: 'STANDARD'|'SIMPLIFIED' = null;
  // public forwardSmallBusiness: boolean;

  constructor(
    public readonly store: Store<AppState>,
    private readonly fb: FormBuilder,
    public router: Router,
    private readonly cdr: ChangeDetectorRef,
    public validationService: ValidationService,
    // private readonly authService: AuthService,
    private readonly systemSettingsService: SystemSettingsService,
    public readonly administrationsApiService: AdministrationsApiService,
    // public readonly accountingSettingsService: AccountingSettingsService,
  ) {
    super(store, router, administrationsApiService);
    // this.loggedUser = this.authService.getUser();
  }

  ngOnInit() {
    // this.getAccountingSettings();
    this.getAvailableCountries();
    this.initForm();

    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((companyProfile: CompanyProfile) => {
        if (companyProfile && !isEmpty(companyProfile)) {
          this.form.patchValue(companyProfile, {emitEvent: false});
          // if (!companyProfile.firstName) {
          //   this.firstName.patchValue(this.loggedUser.firstName, {emitEvent: false});
          // }
          // if (!companyProfile.lastName) {
          //   this.lastName.patchValue(this.loggedUser.lastName, {emitEvent: false});
          // }
          // this.setLegalTypeValidations(companyProfile.legalType);
          // this.updateNoVatState(this.forwardSmallBusiness || this.noVat.value);
          // this.administrationsApiService.getLegalTypes(companyProfile.legalAddress.country_iso3).subscribe();
          this.updateCountryState();
        }
      });

    // this.bindLegalTypeChanges();

    this.validations$
      .pipe(takeUntil(this.destroy$))
      .subscribe(validations => {
        this.validationService.renderServerErrors(this.form, validations);
      });
  }

  // private getAccountingSettings (): void {
  //   this.accountingSettingsService.getAccountingSettings()
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe((settings: AccountingSettingsModel) => {
  //       this.accountingSettings = settings;
  //       this.forwardSmallBusiness = settings.smallBusiness.forward;
  //       this.forwardReportingType = settings.reportingSettings.forwardReportingType;
  //       this.updateNoVatState(this.forwardSmallBusiness || this.noVat.value);
  //     });
  // }

  private getAvailableCountries(): void {
    this.systemSettingsService.getAvailableCountries(1)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.countries = response.data;
      });
  }

  public initForm(companyProfile: CompanyProfile = {} as any): void {
    this.form = this.fb.group({
      // companyName: [get(companyProfile, 'companyName'), {validators: [Validators.required], updateOn: 'blur'}],
      companyRegistrationCountry: [{value: get(companyProfile, 'legalAddress.country_iso3'), disabled: true}, {validators: [Validators.required]}],
      // legalType: [get(companyProfile, 'legalType'), {validators: [Validators.required]}],
      // registrationNumber: [get(companyProfile, 'registrationNumber'), {validators: [Validators.required], updateOn: 'blur'}],
      // vatNumber: [get(companyProfile, 'vatNumber'), {validators:  [Validators.required, Validators.pattern(/^ATU[0-9]{8}$/)], updateOn: 'blur'}],
      // noVat: [get(companyProfile, 'noVat'), {validators: []}], // [ ]
      legalAddress: AddressFormFactory.getForm(get(companyProfile, 'legalAddress', {} as AddressModel), false),
      // email: [get(companyProfile, 'email'), {validators: [Validators.required, Validators.email], updateOn: 'blur'}],
      // phone: [get(companyProfile, 'phone'), {validators: [], updateOn: 'blur'}],
      // website: [get(companyProfile, 'website'), {validators: [], updateOn: 'blur'}],
      // firstName: [this.loggedUser.firstName, {validators: [Validators.required], updateOn: 'blur'}],
      // lastName: [this.loggedUser.lastName, {validators: [Validators.required], updateOn: 'blur'}],
    });

    // remove address_line from required fields
    this.legalAddress.get('address_line').disable({emitEvent: false});
    this.legalAddress.get('address_line').updateValueAndValidity({emitEvent: false});

    // this.noVat.valueChanges
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe(value => {
    //     this.updateNoVatState(value);
    //     this.vatNumber.reset('', {emitEvent: false});
    //   });
  }

  // public forwardSmallBusinessChanged(): void {
  //   this.updateNoVatState(this.forwardSmallBusiness);
  // }
  //
  // public updateNoVatState(noVat: boolean): void {
  //   noVat
  //     ? this.vatNumber.disable({emitEvent: false})
  //     : this.vatNumber.enable({emitEvent: false});
  //
  //   if (this.forwardSmallBusiness) {
  //     this.vatNumber.reset('', {emitEvent: false});
  //     if (this.legalType.value.type === 'GmbH') {
  //       this.vatNumber.clearValidators();
  //       this.vatNumber.updateValueAndValidity();
  //     }
  //   }
  //
  //   if (!this.forwardSmallBusiness && this.legalType.value.type === 'GmbH') {
  //     this.vatNumber.setValidators([Validators.required, Validators.pattern(/^ATU[0-9]{8}$/)]);
  //     this.vatNumber.updateValueAndValidity();
  //   }
  // }

  // public bindLegalTypeChanges(): void {
  //   this.legalType.valueChanges
  //     .pipe(
  //       startWith(get(this.companyProfile, 'legalType')),
  //       takeUntil(this.destroy$)
  //     )
  //     .subscribe((legalType: LegalType) => {
  //       this.setLegalTypeValidations(legalType);
  //     });
  // }

  // public setLegalTypeValidations(legalType: LegalType): void {
  //   if (legalType) {
  //     if (legalType.type === 'Einzelunternehmen' || legalType.type === 'GesbR') {
  //       this.registrationNumber.clearValidators();
  //       this.vatNumber.clearValidators();
  //     } else {
  //       this.registrationNumber.setValidators(Validators.required);
  //       this.vatNumber.setValidators([Validators.required, Validators.pattern(/^ATU[0-9]{8}$/)]);
  //     }
  //
  //     if (legalType.type === 'Einzelunternehmen') {
  //       this.firstName.setValidators(Validators.required);
  //       this.lastName.setValidators(Validators.required);
  //       this.companyName.clearValidators();
  //     } else {
  //       this.firstName.clearValidators();
  //       this.lastName.clearValidators();
  //       this.companyName.setValidators(Validators.required);
  //     }
  //
  //     if (legalType.type === 'Verein') {
  //       this.registrationNumber.setValidators(Validators.required);
  //       this.vatNumber.clearValidators();
  //     }
  //
  //     if (legalType.type === 'OG' || legalType.type === 'KG') {
  //       this.vatNumber.clearValidators();
  //     }
  //   }
  //
  //   this.firstName.updateValueAndValidity();
  //   this.lastName.updateValueAndValidity();
  //   this.companyName.updateValueAndValidity();
  //   this.registrationNumber.updateValueAndValidity();
  //   this.vatNumber.updateValueAndValidity();
  // }

  public updateCountryState(): void {
    const companyCountryISO3 = get(this.companyProfile, 'legalAddress.country_iso3');
    if (companyCountryISO3) {
      this.companyRegistrationCountry.setValue(companyCountryISO3, {emitEvent: false});
      this.legalAddressCountry.disable({emitEvent: false});
    } else {
      this.legalAddressCountry.enable({emitEvent: false});
    }
    this.cdr.detectChanges();
  }

  // public legalTypeChanged(): void {
  //   if (this.legalType && this.legalType.value && this.legalType.value.type) {
  //     const legalTypesForActivateSmallBusiness = ['Einzelunternehmen', 'GesbR', 'KG', 'OG', 'Verein'];
  //     if (legalTypesForActivateSmallBusiness.includes(this.legalType.value.type)) {
  //       this.forwardSmallBusiness = true;
  //       this.updateNoVatState(this.forwardSmallBusiness);
  //     } else {
  //       this.forwardSmallBusiness = false;
  //       this.updateNoVatState(this.forwardSmallBusiness || this.noVat.value);
  //     }
  //
  //     LEGAL_TYPES_FOR_EAR.includes(this.legalType.value.type)
  //       ? this.reportingTypeChanged('SIMPLIFIED')
  //       : this.reportingTypeChanged('STANDARD');
  //   }
  // }

  // public accountingMethodCanBeChanged(): boolean {
  //   return LEGAL_TYPES_FOR_EAR.includes(this.legalType.value.type);
  // }
  //
  // public isAustriaSmallBusiness(): boolean {
  //   return LEGAL_TYPES_AUSTRIA_SMALL_BUSINESS.includes(this.legalType.value.type);
  // }
  //
  // public reportingTypeChanged(reportingType: 'STANDARD'|'SIMPLIFIED'): void {
  //   this.forwardReportingType = reportingType;
  // }

  // public updateAccountingSettings$(): Observable<any> {
  //   const settings = {
  //     ...this.accountingSettings,
  //     reportingSettings: {
  //       ...this.accountingSettings.reportingSettings,
  //       forwardReportingType: this.forwardReportingType
  //     },
  //     smallBusiness: {
  //       ...this.accountingSettings.smallBusiness,
  //       forward: this.forwardSmallBusiness
  //     }
  //   };
  //
  //   return this.accountingSettingsService.updateAccountingSettings(settings);
  // }

  public getStepValidation(): boolean {
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    return this.form.valid;
  }

  public updateStep$(): Observable<boolean | CompanyProfile> {
    if (!this.getStepValidation()) {
      return of(false);
    }
    const formValue = this.form.getRawValue();
    const company = {
      ...this.companyProfile,
      ...formValue,
      legalAddress: {
        ...formValue.legalAddress,
        country_iso3: (this.companyRegistrationCountry.value)
          ? this.companyRegistrationCountry.value
          : formValue.legalAddress.country_iso3
      }
    };

    return this.administrationsApiService.updateCompanyProfile(company)
      .pipe(
        catchError(error => {
          return throwError(error);
        }),
        map((data: ResponseModel<CompanyProfile>) => data.data)
      );
  }

  // get companyName() { return this.form.get('companyName') as FormControl; }
  // get firstName() { return this.form.get('firstName') as FormControl; }
  // get lastName() { return this.form.get('lastName') as FormControl; }
  // get legalType() { return this.form.get('legalType') as FormControl; }
  // get registrationNumber() { return this.form.get('registrationNumber') as FormControl; }
  // get vatNumber() { return this.form.get('vatNumber') as FormControl; }
  // get noVat() { return this.form.get('noVat') as FormControl; }
  get legalAddress() { return this.form.get('legalAddress') as FormControl; }
  get companyRegistrationCountry() { return this.form.get('companyRegistrationCountry') as FormControl; }
  get legalAddressCountry() { return this.form.get('legalAddress').get('country_iso3') as FormControl; }
  // get email() { return this.form.get('email') as FormControl; }
  // get phone() { return this.form.get('phone') as FormControl; }
  // get website() { return this.form.get('website') as FormControl; }



  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
