export enum InitialSetupStepIdEnum {
  HELLO = 'hello',
  COMPANY_TYPE = 'companyType',
  COMPANY_DETAILS = 'companyDetails',
  LEGAL_INFO = 'legalInfo',
  CONTACT_INFO = 'contactInfo',
  ACCOUNTING_METHOD = 'accountingMethod',
  APP_FEATURES = 'appFeatures',
  FINISHING = 'finishing',
  ERROR = 'error',
}

export enum ReportingPeriodEnum {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY'
}
