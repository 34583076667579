import { PaymentTotalModel } from './total.model';
import { PayerModel } from './payer.model';
import { RecipientModel } from './recipient.model';
import { PartnerInfoModel } from './partner-info.model';
import { AccountantModel, AccountantInfoModel } from './accountant.model';
import { CustomerTypeEnum, EntityStateEnum, PaymentCardTypeEnum } from 'common/src/models';
import { File } from '../../incoming-invoice/models/incoming-invoice.model';
import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { FeeTypesEnum } from '../../accounting/accounting.constants';
import { CreditNotePurposeEnum } from '../../credit-note/enums';
import { FlagsEnum } from 'common/src/models/flags.enum';
import { LinkModel } from '../../shared/models';

export interface PaymentModel {
  reportingStatus: string;
  accountantInfo: AccountantInfoModel;
  bookedAt: Date;
  bookingDate: string;
  canceledAt: Date;
  categoryId: number;
  purposeId: number;
  createdAt: Date;
  addRemarks: boolean;
  automaticallyWithdrawn: boolean;
  creditCard: boolean;
  linkedDocumentNotRequired: boolean;
  withdrawalOrDeposit: boolean;
  privatePayment: boolean;
  selfTransferAutomaticallyEnabled: boolean;
  selfTransferCounterPaymentId: number;
  selfTransferEnabled: boolean
  paymentCardType: PaymentCardTypeEnum;
  overOpened?: boolean;
  deletedAt: Date;
  direction: PaymentDirectionEnum;
  editFlag: EditFlagModel;
  fees: FeeModel[];
  feesIncluded: boolean;
  editing: boolean;
  multipleLinkEnabled: boolean;
  multiplePartnerEnabled: boolean;
  linkedDocument: LinkedDocumentModel;
  linkedDocuments: LinkedDocumentModel[];
  myTargetCurrency: boolean;
  partnerType: CustomerTypeEnum;
  partnerInfo: PartnerInfoModel;
  bankAccount?: any;
  payer: PayerModel;
  paymentCurrency: string;
  paymentId: number;
  paymentMethod: string;
  rate: number;
  recipient: RecipientModel;
  reference: string;
  remarks: string;
  runpleId: string;
  runplePartnerId: number;
  status: string;
  targetAmount: number;
  targetAmountForeign: number;
  targetCurrency: string;
  totalAmount: number;
  totals: PaymentTotalModel;
  version: number;
  targetBalance: number;
  deal?: {
    id: number;
    runpleId: string;
  },
  corporatePayerInfo?: string;
  entityState?: EntityStateEnum;
  paymentCategory?: {
    categoryId?: number;
    name?: string;
  };
  documentLink?: LinkModel;
  templateNameLink?: LinkModel;
  lastPartnerName?: string;
  flags?: string[];
  bookReadinessStatus?: FlagsEnum;
  linkedDocumentLink?: LinkModel;
  linkedDocumentsLinks?: LinkModel[];
  rowActions: any;
  transactionId: string;
  undistributedAmount: number;
  templateName?: string;
  positions: PaymentPositionModel[];
  costCenter: {
    id: number;
    name: string;
    status: string;
  };
}

export interface PaymentPositionModel {
  amount: number;
  glAccountId: number;
  paymentId: number;
  positionId: number;
  productId: number;
  productName: string;
}

export enum PaymentDirectionEnum {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING',
}

export interface EditFlagModel {
  accountant: AccountantModel;
  editStartedAt: Date;
  edited: boolean;
  id: number;
  runpleId: string;
}

export interface FeeModel {
  feeAmount: number;
  feeName: string;
  feeType: FeeTypesEnum;
  feePaymentId: number | string;
}

export interface LinkedDocumentModel {
  documentId: number;
  proxyId: number;
  runpleId: string;
  status: string;
  documentLink?: LinkModel;
  total: number;
  type?: DocumentTypesUppercaseEnum;
  documentType?: DocumentTypesUppercaseEnum;
  purpose?: CreditNotePurposeEnum;
  documentTotalAmount?: string;
  documentOpenAmount?: string;
  amount?: string;
  paidAmount?: string;
  amountLocal?: string;
  updated?: Date;
  updatedAt?: Date;
  dueDate?: Date;
  issueDate?: Date;
}
