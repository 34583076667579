import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { NgxCurrencyModule } from 'ngx-currency-custom';
import { MatExpansionModule } from '@angular/material';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from "@ngx-translate/core";
import { SafePipeModule } from 'safe-pipe';
import { FileUploadModule } from 'ng2-file-upload';

import { routes } from './purchase-order-routing';
import { RnplCommonModule, UIComponentsModule } from 'common/src/modules';
import { PurchaseOrderApiService } from './services/purchase-order-api.service';
import { SidebarModule, ColumnsSettingsModule, DocumentListContainerModule, FilesAttachmentModule, DocumentsToggleSummaryModule, AddressCardModule } from '../shared/components';
import { TableTotalInfoModule } from './components/table-total-info/table-total-info.module';
import { BatchFormComponent } from './pages/purchase-order-management/batches/batch-form/batch-form.component';
import { PartnerFormsModule } from '../crm/partner-forms';
import { IsPurchaseOrder } from './guards/is-po.guard';
import { BatchesSidebarContentComponent } from './components/batches-sidebar-content/batches-sidebar-content.component';
import { CommonAddressFormModule } from '../shared/forms/common-address-form/common-address-form.module';
import * as fromPO from './store/reducers';
import {
  PositionsComponent,
  LogisticsComponent,
  AccountingComponent,
  PurchaseOrderListComponent,
  PurchaseOrderManagementComponent,
  BatchesComponent,
  BatchViewAndEditComponent,
  BatchViewLogisticsComponent,
  BatchViewCostsComponent,
  DocumentsComponent,
  PurchaseOrderFileComponent, PurchaseOrderAttachmentsComponent
} from './pages';
import { MetricConversionPipe } from 'common/src/modules/rnpl-common/pipes';
import { IncomingInvoiceApiService } from '../incoming-invoice/services/invoice-api.service';
import { InsuranceCostFormModule } from '../shared/forms/insurance-cost-form/insurance-cost-form.module';
import { DeliveryCostFormModule } from '../shared/forms/delivery-cost-form/delivery-cost-form.module';
import { PricesTableTotalInfoModule } from '../trade-prices/components/prices-table-total-info/prices-table-total-info.module';
import { FiltersModule } from 'common/src/modules/filters';
import { PositionCardModule } from 'common/src/modules/position-card/position-card.module';
import { DocumentViewerModule } from 'common/src/modules/document-viewer/document-viewer.module';
import { TrackShipmentService, UserTableSettingsService } from '../shared/services';
import { PurchaseOrderMegaHintComponent } from './components/purchase-order-mega-hint/purchase-order-mega-hint.component';
import { DeliveryServiceControlModule } from '../shared/forms/delivery-service-control/delivery-service-control.module';
import { PurchaseOrderRemarkComponent } from './pages/purchase-order-management/purchase-order-remark/purchase-order-remark.component';
import { BusinessPartnerComponent } from './components/business-partner/business-partner.component';

@NgModule({
  declarations: [
    PurchaseOrderListComponent,
    PurchaseOrderManagementComponent,
    PositionsComponent,
    LogisticsComponent,
    BatchesComponent,
    BatchFormComponent,
    BatchViewAndEditComponent,
    BatchViewLogisticsComponent,
    BatchViewCostsComponent,
    AccountingComponent,
    BatchesSidebarContentComponent,
    DocumentsComponent,
    PurchaseOrderFileComponent,
    PurchaseOrderMegaHintComponent,
    PurchaseOrderRemarkComponent,
    BusinessPartnerComponent,
    PurchaseOrderAttachmentsComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    UIComponentsModule,
    RnplCommonModule,
    SidebarModule,
    TableTotalInfoModule,
    ReactiveFormsModule,
    PartnerFormsModule,
    FormsModule,
    NgSelectModule,
    CommonAddressFormModule,
    InsuranceCostFormModule,
    DeliveryCostFormModule,
    PricesTableTotalInfoModule,
    StoreModule.forFeature(fromPO.POFeatureKey, {
      entity: fromPO.POReducer,
      list: fromPO.POListReducer,
      blocksValidations: fromPO.POBlocksValidationsReducer
    }),
    EffectsModule.forFeature([]),
    NgxCurrencyModule,
    MatExpansionModule,
    FiltersModule,
    ColumnsSettingsModule,
    TranslateModule,
    SafePipeModule,
    FileUploadModule,
    PositionCardModule,
    DocumentViewerModule,
    DeliveryServiceControlModule,
    DocumentListContainerModule,
    FilesAttachmentModule,
    DocumentsToggleSummaryModule,
    AddressCardModule,
  ],
  exports: [
    BatchFormComponent,
  ],
  providers: [
    PurchaseOrderApiService,
    IncomingInvoiceApiService,
    IsPurchaseOrder,
    MetricConversionPipe,
    DatePipe,
    UserTableSettingsService,
    TrackShipmentService
  ]
})
export class PurchaseOrderModule { }
