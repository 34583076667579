import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { ReportingPeriodsApiService } from 'projects/workspace/src/app/reporting-periods/services/reporting-periods-api.service';
import { CommonModalsActionsEnum, WarningModalComponent } from '../../modals-common';



@Component({
  selector: 'rnpl-reporting-periods-close-by-year-modal',
  templateUrl: './reporting-periods-close-by-year-modal.component.html'
})
export class ReportingPeriodsCloseByYearModalComponent implements OnDestroy {

  public yearControl: FormControl = new FormControl(null, [Validators.required]);
  public yearsList: number[] = [];

  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly confirmRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    public toasterService: ToasterService,
    public dialog: MatDialog,
    private reportingPeriodsApiService: ReportingPeriodsApiService,
    public dialogRef: MatDialogRef<ReportingPeriodsCloseByYearModalComponent>,
  ) {
    this.reportingPeriodsApiService.getAvailableToCloseReportingYears()
      .pipe(takeUntil(this.destroy$))
      .subscribe((years: number[]) => {
        this.showDropdownSpin$.next(false);
        this.yearsList = years;
        if (years && years.length) {
          this.yearControl.patchValue(years[years.length - 1]);
        }
      });
  }

  public submit(): void {
    if (this.confirmRequest$.getValue()) { return; }

    this.yearControl.updateValueAndValidity();
    this.yearControl.markAsTouched();

    if (this.yearControl.invalid) { return; }

    this.confirmRequest$.next(true);

    const dialog = this.dialog.open(WarningModalComponent, {
      data: {
        title: 'REPORTING_PERIODS.CLOSE_YEAR',
        message: 'REPORTING_PERIODS.CLOSE_PERIOD_CONFIRM_MSG',
        confirmBtnText: 'REPORTING_PERIODS.CLOSE',
        confirmBtnIcon: 'checkmark'
      }
    });

    dialog.afterClosed().subscribe(res => {
      if (res === CommonModalsActionsEnum.CONFIRM) {
        this.reportingPeriodsApiService.closeReportingPeriodsAnnually(this.yearControl.value)
          .pipe(
            finalize(() => this.confirmRequest$.next(false)),
            takeUntil(this.destroy$)
          )
          .subscribe(() => this.dialogRef.close(CommonModalsActionsEnum.SUCCESS));
      } else {
        this.confirmRequest$.next(false);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
