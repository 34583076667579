import { Component, EventEmitter, Input, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { CostCenterApiService } from '../../../cost-center/services/cost-center-api.service';
import { CostCenterModel } from '../../../cost-center/models';
import { ResponseList } from '../../models';

@Component({
  selector: 'rnpl-cost-center-control',
  templateUrl: './cost-center-control.component.html',
})
export class CostCenterControlComponent implements OnDestroy {

  public list: CostCenterModel[] = [];

  @Input() control: FormControl;
  @Input() selectedName: string;
  @Input() disabled: boolean = false;
  @Output() fieldChanged: EventEmitter<number> = new EventEmitter<number>();

  public loadingList$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private readonly costCenterApiService: CostCenterApiService,
  ) {
    this.getPriceLists();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.hasOwnProperty('disabled') && this.disabled && this.control) {
      this.control.disable();
    }
  }

  public getPriceLists(): void {
    this.costCenterApiService.getCostCenterActiveList()
      .pipe(
        finalize(() => this.loadingList$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe((response: ResponseList<CostCenterModel>) => {
        this.list = response.data.sort((a, b) => (a.name).localeCompare(b.name));
      });
  }

  public updateField(fieldValue: number): void {
    this.fieldChanged.emit(fieldValue);
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
