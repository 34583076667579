import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';

import { EmailTextFormComponent } from './email-text-form.component';
import { DocumentTextTemplatesApiService } from '../../services';

@NgModule({
  declarations: [
    EmailTextFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    TranslateModule,
  ],
  exports: [
    EmailTextFormComponent,
  ],
  entryComponents: [EmailTextFormComponent],
  providers: [DocumentTextTemplatesApiService]
})
export class EmailTextFormModule { }
