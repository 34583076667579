import { FormGroup } from '@angular/forms';
import { EventEmitter, Input, OnDestroy, Output, SimpleChanges, OnChanges } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

export class BaseFormComponent implements OnDestroy, OnChanges {

  @Input()
  displayErrors: boolean = false;

  @Input()
  readonly: boolean = false;

  @Output()
  statusChanged: EventEmitter<any> = new EventEmitter<any>();

  public destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  public form: FormGroup;
  valid: boolean = true;
  get invalid(): boolean {
    return !this.valid;
  }

  initForm(): void {
    if (!this.form) { return; }
    this.valid = this.form.valid;

    this.form.statusChanges
      .pipe(
        takeUntil(this.destroy)
      )
      .subscribe(status => {
        this.valid = status === 'VALID';
        this.statusChanged.emit(status);
      });
  }

  public updateFormEditingStatus(): void {
    if (!this.form) { return; }

    if (this.readonly) {
      setTimeout(() => {
        this.form.disable({ onlySelf: false });
      });
    } else {
      setTimeout(() => {
        this.form.enable({ onlySelf: false });
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('readonly')) {
      this.updateFormEditingStatus();
    }
  }

  ngOnDestroy(): void {
    this.destroy.next(null);
    this.destroy.complete();
  }
}
