import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import { DocumentTextTemplateModel } from '../../models';
import { DocumentTextLocationEnum } from '../../enums';
import { DocumentTextTemplatesApiService } from '../../services';
import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';
import {
  CommonModalsActionsEnum,
  DangerModalComponent,
  SavePdfTextTemplateModalComponent
} from 'common/src/modules/modals/modals-common';
import { DeleteTemplateModalData } from '../../constants';

@Component({
  selector: 'rnpl-email-text-form',
  templateUrl: './email-text-form.component.html',
})
export class EmailTextFormComponent implements OnDestroy, OnChanges {

  public controlId = Math.floor(Math.random() * 1000);
  public templatesList: DocumentTextTemplateModel[] = [];
  public selectedTemplate: DocumentTextTemplateModel = null;

  @Input() isReadonly = false;
  @Input() documentType: DocumentTypesUppercaseEnum;
  @Input() addCustomEmailTextControl: FormControl = new FormControl();
  @Input() customEmailTextControl: FormControl = new FormControl();

  @Output() fieldChanged: EventEmitter<{ fieldName: string, fieldValue: any }> = new EventEmitter<{ fieldName: string, fieldValue: any }>();

  readonly templatesListLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  readonly submitRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private readonly dialog: MatDialog,
    private readonly documentTextTemplatesApiService: DocumentTextTemplatesApiService,
  ) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.hasOwnProperty('documentType') && !this.templatesList.length) {
      this.getTemplates();
    }
  }

  private getTemplates(): void {
    this.documentTextTemplatesApiService.getTextTemplates(this.documentType, DocumentTextLocationEnum.CUSTOM_EMAIL_TEXT)
      .pipe(
        finalize(() => this.templatesListLoading$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe((templates: DocumentTextTemplateModel[]) => {
        this.templatesList = templates;
        this.checkSelectedTemplate();
      });
  }

  public templateSelected(): void {
    this.customEmailTextControl.patchValue(this.selectedTemplate.text);
    this.updated(this.selectedTemplate.text, 'customEmailText');
  }

  public saveTemplate(): void {
    this.dialog.open(SavePdfTextTemplateModalComponent, {
      data: {
        templates: this.templatesList,
        selectedTemplate: this.selectedTemplate,
        documentType: this.documentType,
        mode: DocumentTextLocationEnum.CUSTOM_EMAIL_TEXT,
        text: this.customEmailTextControl.value
      },
      disableClose: true
    }).afterClosed().subscribe((res: DocumentTextTemplateModel) => {
      if (res) {
        this.selectedTemplate = res;
        this.getTemplates();
      }
    });
  }

  public checkSelectedTemplate(): void {
    const fieldValue = this.customEmailTextControl.value;
    if (this.templatesList.length && fieldValue) {
      const selectedTemplate = this.templatesList.find(tpl => tpl.text === fieldValue);
      if (selectedTemplate) {
        this.selectedTemplate = selectedTemplate;
      }
    }
  }

  public deleteTemplate(): void {
    if (!this.selectedTemplate) { return; }

    this.dialog.open(DangerModalComponent, { data: DeleteTemplateModalData, disableClose: true })
      .afterClosed()
      .subscribe((response: CommonModalsActionsEnum) => {
        if (response === CommonModalsActionsEnum.CONFIRM) {
          this.documentTextTemplatesApiService.deleteTextTemplate(this.selectedTemplate.id)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
              this.selectedTemplate = null;
              this.getTemplates();
            });
        }
      });
  }

  public updated(fieldValue: any, fieldName: string): void {
    this.fieldChanged.emit({fieldValue, fieldName});
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
