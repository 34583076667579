import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ColumnTypeEnum, TableColumnModelExtended } from '../../../../../models/table-column.model';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { selectCompanyTimezoneOffset } from 'projects/workspace/src/app/administration/store/selectors';
import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { HighlightTypeEnum } from '../../../table-summary-bar/table-summary-bar.model';
import { PaymentDirectionEnum } from 'projects/workspace/src/app/payment/models/payment.model';

@Component({
  selector: 'rnpl-column-primary',
  templateUrl: './column-primary.component.html',
  styleUrls: ['./column-primary.component.scss']
})
export class ColumnPrimaryComponent implements OnChanges {

  @Input()
  value: string | number;

  @Input()
  column: TableColumnModelExtended;

  @Input()
  row: any;

  public unitTypeShortcut = { // todo move to common file
    unit: 'pcs',
    hours: 'h'
  };

  public highlightTypeEnum: typeof HighlightTypeEnum = HighlightTypeEnum;

  readonly documentTypes: typeof DocumentTypesUppercaseEnum = DocumentTypesUppercaseEnum;
  readonly paymentDirections: typeof PaymentDirectionEnum = PaymentDirectionEnum;

  public columnTypeEnum: typeof ColumnTypeEnum = ColumnTypeEnum;

  readonly companyProfileTimezoneOffset$: Observable<string> = this.store.select(selectCompanyTimezoneOffset);

  constructor(
    private readonly store: Store<AppState>,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('column') && changes.hasOwnProperty('value') && this.column.underscoreSplitting) {
      this.value = (this.value as string).split('_').join(' ');
    }
  }

  public getPaymentClass (columnType: string, value: number ): string {
    if (columnType === ColumnTypeEnum.AVAILABILITY) {
      if (+value < 0 ) {
        return 'outgoing-amount';
      }
      if (+value > 0) {
        return 'incoming-amount';
      }
    }
  }

}
