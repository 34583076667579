import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { get } from 'lodash';

import { CountryModel } from '../../../rnpl-common';
import { AddressTypeEnum } from 'projects/workspace/src/app/sales-order/enums/address-type.enum';
import { DeliveryTypesEnum, UIStatesEnum } from '../../../../models';
import { convertAddressToStringHelper } from '../../../rnpl-common/helpers';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { ToasterService } from '../../../ui-components/toaster';
import { AddressFormFactory } from 'projects/workspace/src/app/shared/forms/address-form.factory';
import { FormInputChangedModel } from 'projects/workspace/src/app/shared/models';
import { EcoOrderModel } from 'projects/workspace/src/app/e-commerce/modules/eco-orders/models';
import { selectEcoOrder, selectEcoOrderCurrentState } from 'projects/workspace/src/app/e-commerce/modules/eco-orders/store/selectors';
import { EcoFormService } from 'projects/workspace/src/app/e-commerce/modules/eco-orders/services/eco-form.service';
import { selectCountries } from 'projects/workspace/src/app/store/selectors/shared.selectors';
import { ECommerceApiService } from 'projects/workspace/src/app/e-commerce/services';

@Component({
  selector: 'rnpl-eco-delivery-info-modal',
  templateUrl: './eco-delivery-info-modal.component.html',
})
export class EcoDeliveryInfoModalComponent implements OnInit, OnDestroy {
  public countries: CountryModel[];
  public ecoOrder: EcoOrderModel;
  public addressTypeList = [];
  public addressTypeEnum: typeof AddressTypeEnum = AddressTypeEnum;
  public isReadonly: boolean = true;
  public readonly deliveryTypes = DeliveryTypesEnum;
  private convertAddressToString = convertAddressToStringHelper;

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private eCommerceApiService: ECommerceApiService,
    private toasterService: ToasterService,
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<EcoDeliveryInfoModalComponent>,
    private ecoFormService: EcoFormService,
    @Inject(MAT_DIALOG_DATA) public data: {
      form: FormGroup,
      pickUpAddressForm: FormGroup,
      deliveryToAddressForm: FormGroup
    }
  ) { }

  ngOnInit(): void {
    this.selectCountries();
    this.trackSalesOrderChanges();
    this.selectAndHandleOrderState();
  }

  private selectCountries() {
    this.store.select(selectCountries)
      .pipe(takeUntil(this.destroy$))
      .subscribe((countriesFromStore: CountryModel[]) => {
        this.countries = countriesFromStore;
      });
  }

  private trackSalesOrderChanges(): void {
    this.store.select(selectEcoOrder)
      .pipe(takeUntil(this.destroy$))
      .subscribe((ecoOrder: EcoOrderModel) => {
        this.ecoOrder = ecoOrder;
        this.ecoFormService.initDeliveryInfoForm(ecoOrder.deliveryInformation);
        this.data.form = this.ecoFormService.deliveryInfoForm;

        this.addressTypeList = [
          {value: AddressTypeEnum.BILLING, label: 'FORM.BILLING_ADDRESS', enabled: !this.isPickUpTypeSelected && this.isCustomerSelected},
          {value: AddressTypeEnum.DELIVERY, label: 'FORM.DELIVERY_ADDRESS', enabled: !this.isPickUpTypeSelected && this.isCustomerSelected},
          {value: AddressTypeEnum.WAREHOUSE, label: 'FORM.WAREHOUSE_ADDRESS', enabled: this.isPickUpTypeSelected},
          {value: AddressTypeEnum.USER_DEFINED, label: 'COMMON.NEW_ADDRESS', enabled: true},
          {value: AddressTypeEnum.SIMPLIFIED, label: 'COMMON.USER_DEFINED_ADDRESS', enabled: true},
        ].filter(i => i.enabled);

        // this.updateDeliveryInfoBlockValid();
        this.setFormsState();
      });
  }

  private selectAndHandleOrderState() {
    this.store.select(selectEcoOrderCurrentState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: UIStatesEnum) => {
        this.isReadonly = state === UIStatesEnum.VIEW;
        this.setFormsState();
      });
  }
  // private updateDeliveryInfoBlockValid(): void {
  //   const deliveryCostControls: FormControl[] = [this.deliveryCostsVat, this.deliveryCosts, this.deliveryCostsGross, this.deliveryCostsPayer];
  //   const insuranceCostControls: FormControl[] = [this.deliveryInsuranceVat, this.deliveryInsuranceCosts, this.deliveryInsuranceCostsGross, this.deliveryInsurancePayer];
  //
  //   if (this.data.form.get('isDeliveryCosts').value) {
  //     deliveryCostControls.forEach(control => control.setValidators(Validators.required));
  //   } else {
  //     deliveryCostControls.forEach(control => control.clearValidators());
  //   }
  //
  //   if (this.data.form.get('isInsuredDelivery').value) {
  //     insuranceCostControls.forEach(control => control.setValidators(Validators.required));
  //   } else {
  //     insuranceCostControls.forEach(control => control.clearValidators());
  //   }
  //
  //   [...deliveryCostControls, ...insuranceCostControls].forEach(control => control.updateValueAndValidity());
  // }

  public updateEcoOrderDeliveryInfo(fieldValue, fieldName: string): void {
    fieldName = 'deliveryInformation.' + fieldName;
    this.eCommerceApiService.updateEcoOrder(this.ecoOrder.id, {fieldValue, fieldName})
      .pipe(takeUntil(this.destroy$))
      .subscribe((ecoOrder: EcoOrderModel) => {
        this.ecoFormService.initDeliveryInfoForm(ecoOrder.deliveryInformation);
        this.data.form = this.ecoFormService.deliveryInfoForm;

        if (fieldValue === this.deliveryTypes.PICK_UP) {
          this.addressTemplateControl.setValue(AddressTypeEnum.WAREHOUSE);
        }

        if (fieldValue === this.deliveryTypes.DELIVERY) {
          this.addressTemplateControl.setValue(AddressTypeEnum.DELIVERY);
        }

        if (fieldName === 'deliveryInformation.addressTemplate') {
          this.data.deliveryToAddressForm = AddressFormFactory.getForm(get(ecoOrder, 'deliveryInformation.deliveryToAddress'));
          this.data.pickUpAddressForm = AddressFormFactory.getForm(get(ecoOrder, 'deliveryInformation.pickUpAddress'));
        }
      }); // updated via store
  }

  public updateAddressField(field: FormInputChangedModel, addressGroupName: string): void {
    const fieldName = addressGroupName + '.' + field.fieldName;
    this.updateEcoOrderDeliveryInfo(field.fieldValue, fieldName);
  }

  // public fillCustomerDeliveryAddress(): void {
    // this.eCommerceApiService.updateDeliveryAddressByCustomer(this.ecoOrder.id).subscribe();
  // }

  public fillOwnWarehouseDeliveryAddress(): void {
    // this.eCommerceApiService.updatePickUpAddressByWarehouse(this.ecoOrder.id).subscribe();
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  public get isPickUpTypeSelected(): boolean {
    return get(this, 'ecoOrder.deliveryInformation.deliveryType') === this.deliveryTypes.PICK_UP;
  }

  public get isCustomerSelected(): boolean {
    return !!get(this, 'ecoOrder.properties.company.runpleId');
  }

  public get pickUpAddressAsText(): string {
    if (!this.data.form) { return ''; }

    const pickUpAddressVal = this.data.pickUpAddressForm.value;
    return this.convertAddressToString(pickUpAddressVal, this.countries);
  }

  public get deliveryAddressAsText(): string {
    if (!this.data.form) { return ''; }

    const deliveryAddressVal = this.data.deliveryToAddressForm.value;
    return this.convertAddressToString(deliveryAddressVal, this.countries);
  }

  private setFormsState(): void {
    const opts = { onlySelf: true, emitEvent: false };
    if (this.isReadonly) {
      this.data.form.disable(opts);
      this.data.deliveryToAddressForm.disable(opts);
      this.data.pickUpAddressForm.disable(opts);
    } else {
      this.data.form.enable(opts);
      this.data.deliveryToAddressForm.enable(opts);
      this.data.pickUpAddressForm.enable(opts);
      this.cdr.detectChanges();
    }
  }

  public editAddress(): void {
    this.addressTemplateControl.setValue(AddressTypeEnum.USER_DEFINED);
    this.updateEcoOrderDeliveryInfo(AddressTypeEnum.USER_DEFINED, 'addressTemplate');
  }

  get deliveryTypeControl(): FormControl { return this.data.form.get('deliveryType') as FormControl; }
  get estimatedDeliveryDate(): FormControl { return this.data.form.get('estimatedDeliveryDate') as FormControl; }
  get deliveryCostsVat(): FormControl { return this.data.form.get('deliveryCostsVat') as FormControl; }
  get deliveryCosts(): FormControl { return this.data.form.get('deliveryCosts') as FormControl; }
  get deliveryCostsPayer(): FormControl { return this.data.form.get('deliveryCostsPayer') as FormControl; }
  get deliveryCostsGross(): FormControl { return this.data.form.get('deliveryCostsGross') as FormControl; }
  get deliveryInsuranceVat(): FormControl { return this.data.form.get('deliveryInsuranceVat') as FormControl; }
  get deliveryInsuranceCosts(): FormControl { return this.data.form.get('deliveryInsuranceCosts') as FormControl; }
  get deliveryInsuranceCostsGross(): FormControl { return this.data.form.get('deliveryInsuranceCostsGross') as FormControl; }
  get deliveryInsurancePayer(): FormControl { return this.data.form.get('deliveryInsurancePayer') as FormControl; }
  get isDeliveryCosts(): FormControl { return this.data.form.get('isDeliveryCosts') as FormControl; }
  get addressTemplateControl(): FormControl { return this.data.form.get('addressTemplate') as FormControl; }
  get nameControl(): FormControl { return this.data.form.get('name') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
