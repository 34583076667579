import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';

import {
  CompositeProductModel,
  FamilyModel,
  GeneralProductStatusesModel,
  ProductModel,
  ProductPriceModel,
  ProductsTabsCountersModel,
  ProductStatusesModel,
  ProductVariationsResponse,
  RelatedProductModel
} from './models';
import { AssignedAttributeModel } from '../system-settings/models';
import { ResponseList, ResponseModel } from 'projects/workspace/src/app/shared/models/response';
import { FilterModel } from '../../../../projects/workspace/src/app/warehouse/models/filter.model';
import { ProductTypes } from './product-types';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import {
  DecrementLoadingRequestsCount,
  IncrementLoadingRequestsCount,
  LoadCategoriesList,
  LoadProduct,
  LoadProductsList,
  UpdateProductsCurrentState,
  UpdateProductUpdatedAt
} from 'projects/workspace/src/app/products-two-level/store/actions/products.actions';
import { FileUploadParams } from '../../models/file-upload-params.model';
import { ProductStatusEnum } from 'projects/workspace/src/app/products-two-level/enums/product-status.enum';
import { DisplayToaster } from 'projects/workspace/src/app/shared/decorators/toaster';
import { FormInputChangedModel } from 'projects/workspace/src/app/shared/models/form-input-value.model';
import { ToasterService } from '../ui-components/toaster';
import { UIStatesEnum } from '../../models';
import { CommonModalsActionsEnum, WarningModalComponent } from '../modals/modals-common';
import { getAnotherUserEditErrorModalData } from '../modals/modals-common/common-modal.config';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { AdministrationsApiService } from 'projects/workspace/src/app/administration/services/administrations-api.service';
import { DEFAULT_SORT_DIRECTION, SECONDARY_SORT_DIRECTION } from 'projects/workspace/src/app/shared/constants';
import { ProductRelationDirectionEnum } from '../../../../projects/workspace/src/app/products-two-level/enums';

@Injectable()
export class ProductsService {

  public salesPriceListName: string = '';
  public companyProfile: CompanyProfile;

  constructor(
    private http: HttpClient,
    private toasterService: ToasterService,
    private administrationsApiService: AdministrationsApiService,
    private translateService: TranslateService,
    private readonly dialog: MatDialog,
    private readonly store: Store<AppState>
  ) {
    this.store.select(selectCompanyProfile)
      .subscribe((companyProfile: CompanyProfile) => {
        this.companyProfile = companyProfile;
      });
  }

  public getFamiliesTree(params?: HttpParams): Observable<any> {
    return this.http.get('/products/families/tree', {params})
      .pipe(
        map((response: ResponseModel<FamilyModel[]>) => {
          if (params.has('products_type') && params.get('products_type') === ProductTypes.GENERAL) {
            // sort general categories by translated titles
            return {
              ...response,
              data: response.data
                .map(itm => ({
                  ...itm,
                  titleKey: itm.title,
                  title: this.translateService.instant('GL_CATEGORY.' + itm.title)
                }))
                .sort((a, b) => a.title.localeCompare(b.title))
            }
          }
          return response;
        }),
        tap((response: ResponseModel<FamilyModel[]>) => {
          this.store.dispatch(LoadCategoriesList({
            productType: params['products_type'],
            categories: response.data
          }));
        })
      );
  }

  public getFamily(familyId): Observable<any> {
    return this.http.get(`/products/families/${familyId}`);
  }

  public createFamily(family: FamilyModel): Observable<any> {
    return this.http.post('/products/families', family);
  }

  public createRootFamily(family: FamilyModel, productType: ProductTypes): Observable<any> {
    return this.http.post(`/products/families/${productType}/root`, family)
      .pipe(
        tap(() => {
          if (!this.companyProfile.onboardingCompleted) {
            this.administrationsApiService.getOnboardingProcess().subscribe();
          }
        }),
      );
  }

  public updateFamily(family: FamilyModel): Observable<any> {
    return this.http.patch(`/products/families/${family.id}`, family);
  }

  public deleteFamily(familyId): Observable<any> {
    return this.http.delete(`/products/families/${familyId}`);
  }

  public getAvailableFamilyAttributes(form: string, familyId): Observable<any> {
    return this.http.get(`/products/forms/${form}/families/${familyId}/attributes/available`);
  }

  public getAllFamilyAttributes(familyId: string): Observable<any> {
    return this.http.get(`/products/families/${familyId}/attributes/available`)
      .pipe(map((response: any) => response.data));
  }

  public getAssignedFamilyAttributes(form: string, familyId: string | number): Observable<any> {
    return this.http.get(`/products/forms/${form}/families/${familyId}/attributes/assigned`)
      .pipe(map((response: any) => response.data));
  }

  public assignFamilyAttributes(form: string, familyId, attributes: Array<AssignedAttributeModel>): Observable<any> {
    const requestAttributes = attributes.map((attribute, index) => {
      return {
        attribute_id: attribute.attribute.id,
        layout: attribute.layout,
        position: index
      };
    });

    return this.http.put(
      `/products/forms/${form}/families/${familyId}/attributes/assigned`,
      {attributes: requestAttributes}
    )
      .pipe(
        tap(() => {
          if (!this.companyProfile.onboardingCompleted) {
            this.administrationsApiService.getOnboardingProcess().subscribe();
          }
        }),
      );
  }

  public getGeneralProductsByFamily(familyId, params?: HttpParams): Observable<any> {
    return this.http.get(`/products/families/${familyId}/general-products`, {params});
  }

  public getProductBlueprint(form: string, familyId: string|number): Observable<any> {
    return this.http.get(`/products/forms/${form}/families/${familyId}/blueprint`)
      .pipe(map((response: any) => response.data));
  }

  public getAssignedFamilyBranchAttrs(form: string, familyId: string): Observable<any> {
    return this.http.get(`/products/forms/${form}/families/${familyId}/attributes/assigned-to-branch`);
  }

  public createFamilyGeneralGood(familyId: string, data: any): Observable<any> {
    return this.http.post(`/products/families/${familyId}/general-goods`, data);
  }

  public createFamilyGeneralService(familyId: string, data: any): Observable<any> {
    return this.http.post(`/products/families/${familyId}/general-services`, data);
  }

  public createFamilyGeneralDigitalProduct(familyId: string, data: any): Observable<any> {
    return this.http.post(`/products/families/${familyId}/general-digital-products`, data);
  }

  public updateGeneralGood(generalGoodId: string | number, data: any): Observable<any> {
    return this.http.put(`/products/general-goods/${generalGoodId}`, data);
  }

  public updateGeneralDigitalProduct(generalGoodId: string | number, data: any): Observable<any> {
    return this.http.put(`/products/general-digital-products/${generalGoodId}`, data);
  }

  public updateGeneralService(generalGoodId: string | number, data: any): Observable<any> {
    return this.http.put(`/products/general-services/${generalGoodId}`, data);
  }

  public deleteFamilyGeneralProducts(familyId, ids: Array<number>): Observable<any> {
    const body = { ids: ids };
    return this.http.request('delete', `/products/families/${familyId}/general-products`, {body});
  }

  public getGeneralProduct(generalProductId: number | string): Observable<any> {
    return this.http.get(`/products/general-products/${generalProductId}`)
      .pipe(map((response: any) => response.data));
  }

  public getCategoriesTree(params?: HttpParams): Observable<any> {
    return this.http.get('/products/categories/tree', {params});
  }

  public initCategoriesTreeMode(productType: string): Observable<any> {
    return this.http.post('/products/categories/tree', {products_type: productType});
  }

  public initCategoriesSingleMode(productType: string): Observable<any> {
    const body = { products_type: productType };
    return this.http.request('delete', '/products/categories/tree', {body});
  }

  public createCategory(category: FamilyModel): Observable<any> {
    return this.http.post('/products/categories', category);
  }

  public deleteCategory(categoryId): Observable<any> {
    return this.http.delete(`/products/categories/${categoryId}`);
  }

  public updateCategory(categoryId: string, category: any): Observable<any> {
    return this.http.put(`/products/categories/${categoryId}`, category);
  }

  public changeCategoryStatus(categoryId, status: string): Observable<any> {
    return this.http.patch('/products/categories/status', {category_id: categoryId, status: status});
  }

  public getCategoryGeneralProducts(categoryId: string): Observable<any> {
    return this.http.get(`/products/categories/${categoryId}/general-products/grouped-by-families`);
  }

  public createProduct(generalProductId: string | number, product: ProductModel): Observable<any> {
    return this.http.post(`/products/general-products/${generalProductId}/products`, product);
  }

  public deleteProducts(ids: number[]): Observable<any> {
    const body = { ids };
    return this.http.request('delete', `/products`, {body});
  }

  public getProductsList(generalProductId: string | number): Observable<ProductModel[]> {
    return this.http.get(`/products/general-products/${generalProductId}/products`)
      .pipe(map((response: any) => response.data));
  }

  public changeGeneralProductsStatuses(statuses: GeneralProductStatusesModel): Observable<any> {
    return this.http.patch('/products/general-products/statuses', statuses);
  }

  public changeProductsStatuses(statuses: ProductStatusesModel): Observable<any> {
    return this.http.patch('/products/statuses', statuses);
  }

  public getProductsCounters(generalProductId: string | number): Observable<ProductsTabsCountersModel> {
    return this.http.get(`/products/general-products/${generalProductId}/counters`)
      .pipe(map((response: any) => response.data));
  }

  public getProduct(productId: number, salesPriceListName?: string, preventStoreLoad = false): Observable<ProductModel> {
    if (!preventStoreLoad) {
      this.store.dispatch(IncrementLoadingRequestsCount());
    }
    const params = {};
    if (salesPriceListName) {
      params['salesPriceListName'] = salesPriceListName;
    }

    return this.http.get<ResponseModel<ProductModel>>(`/products/${productId}`, { params })
      .pipe(
        tap((response: ResponseModel<ProductModel>) => {
          if (!preventStoreLoad) {
            this.store.dispatch(LoadProduct({product: response.data as ProductModel}));
          }
        }),
        map((response: ResponseModel<ProductModel>) => response.data),
        finalize(() => {
          if (!preventStoreLoad) {
            this.store.dispatch(DecrementLoadingRequestsCount());
            this.store.dispatch(UpdateProductUpdatedAt({updatedAt: new Date()}));
          }
        })
      );
  }

  public updateProduct(productId: number, productData: ProductModel): Observable<ProductModel> {
    return this.http.put(`/products/${productId}`, productData)
      .pipe(map((response: any) => response.data));
  }

  // public getAllGeneralGoods(): Observable<GeneralProductModel[]> {
  //   return this.http.get(`/products/general-goods`)
  //     .pipe(map((response: ResponseModel<GeneralProductModel[]>) => response.data));
  // }

  public addPropertiesToCollectiveField(
    form: string,
    familyId: string,
    attributeId: string,
    relatedFields: number[]
  ): Observable<AssignedAttributeModel> {
    return this.http.patch(
      `/products/forms/${form}/families/${familyId}/attributes/assigned/${attributeId}/properties`,
      {related_fields: relatedFields}
      );
  }

  public getActiveProducts(
    sort: FilterModel = {nameColumn: 'name', value: SECONDARY_SORT_DIRECTION},
    includeInactive = false,
    salesPriceListName?: string,
    variationsOfProductId?: number,
  ): Observable<ProductModel[]> {
    const params = {
      [`sort[${sort.nameColumn}]`]: sort.value,
      includeInactive: includeInactive.toString()
    };
    if (includeInactive) {
      params['includeInactive'] = includeInactive.toString();
    }
    if (salesPriceListName) {
      params['salesPriceListName'] = salesPriceListName;
    }
    if (variationsOfProductId) {
      params['variationsOfProductId'] = variationsOfProductId as any;
    }

    return this.http.get('/products', { params })
      .pipe(
        map((response: ResponseModel<ProductModel[]>) => {
          return  response.data
            .filter((product: ProductModel) => {
              return (product.type === ProductTypes.GOODS && this.companyProfile.productOptions.goodsEnabled) ||
                (product.type === ProductTypes.SERVICES && this.companyProfile.productOptions.servicesEnabled) ||
                (product.type === ProductTypes.DIGITAL && this.companyProfile.productOptions.digitalEnabled);
            });
        })
      );
  }

  public calculateRelatedProducts(
    productId: number,
    type: ProductRelationDirectionEnum,
    quantity: number,
    salesPriceListName: string,
    vatDisabled: boolean,
  ): Observable<RelatedProductModel[]> {
    const body = { type, quantity, salesPriceListName, vatDisabled };
    this.store.dispatch(IncrementLoadingRequestsCount());
    return this.http.post<ResponseModel<RelatedProductModel[]>>(`/products/${productId}/related`, body)
      .pipe(map((response: ResponseModel<RelatedProductModel[]>) => response.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public addRelatedProduct(
    productId: number,
    relatedProductId: number,
    relationDirection: ProductRelationDirectionEnum,
    quantity: number = null,
    necessarily: boolean = true,
  ): Observable<ProductModel> {
    const body = {
      productId: relatedProductId,
      quantity: quantity,
      necessarily: necessarily,
    };
    this.store.dispatch(IncrementLoadingRequestsCount());
    return this.http.post<ResponseModel<ProductModel>>(`/products/${productId}/related-products/${relationDirection}`, body)
      .pipe(
        tap((response: ResponseModel<ProductModel>) => {
          this.store.dispatch(LoadProduct({product: response.data as ProductModel}));
        }),
        map((response: ResponseModel<ProductModel>) => response.data),
        finalize(() => {
          this.store.dispatch(DecrementLoadingRequestsCount());
          this.store.dispatch(UpdateProductUpdatedAt({updatedAt: new Date()}));
        })
      );
  }

  @DisplayToaster({showErrorMessage: true})
  public updateRelatedProduct(
    productId: number,
    relatedProductId: number,
    relationDirection: ProductRelationDirectionEnum,
    fieldName: string,
    fieldValue: any,
    force = false
  ): Observable<ProductModel> {
    const body = { fieldName, fieldValue, force };
    this.store.dispatch(IncrementLoadingRequestsCount());
    return this.http.patch<ResponseModel<ProductModel>>(
      `/products/${productId}/related-products/${relationDirection}/${relatedProductId}`,
      body
    )
      .pipe(
        tap((response: ResponseModel<ProductModel>) => {
          this.store.dispatch(LoadProduct({product: response.data as ProductModel}));
        }),
        map((response: ResponseModel<ProductModel>) => response.data),
        finalize(() => {
          this.store.dispatch(DecrementLoadingRequestsCount());
          this.store.dispatch(UpdateProductUpdatedAt({updatedAt: new Date()}));
        })
      );
  }

  @DisplayToaster({showErrorMessage: true})
  public deleteRelatedProducts(
    productId: number,
    ids: number[],
    relationDirection: ProductRelationDirectionEnum,
  ): Observable<ProductModel> {
    const body = { ids };
    this.store.dispatch(IncrementLoadingRequestsCount());
    return this.http.request<ResponseModel<ProductModel>>(
      'delete',
      `/products/${productId}/related-products/${relationDirection}`,
      { body }
    )
      .pipe(
        tap((response: ResponseModel<ProductModel>) => {
          this.store.dispatch(LoadProduct({product: response.data as ProductModel}));
        }),
        map((response: ResponseModel<ProductModel>) => response.data),
        finalize(() => {
          this.store.dispatch(DecrementLoadingRequestsCount());
          this.store.dispatch(UpdateProductUpdatedAt({updatedAt: new Date()}));
        })
      );
  }

  public getProductVolumePrice(productId: number, quantity: number, salesPriceListName?: string): Observable<Partial<ProductPriceModel>> {
    const params = { quantity, salesPriceListName } as any;
    return this.http.get<ResponseModel<Partial<ProductPriceModel>>>(`/products/${productId}/volume`, { params })
      .pipe(map((response: ResponseModel<Partial<ProductPriceModel>>) => response.data));
  }

  public getAvailableProducts(
    invoiceId: number,
    invoiceType: 'outgoing-invoices' | 'incoming-invoices',
    sort: FilterModel = {nameColumn: 'name', value: SECONDARY_SORT_DIRECTION}
    ): Observable<ProductModel[]> {
    return this.http.get(`/${invoiceType}/${invoiceId}/products/available`,
      {
      params: {
        [`sort[${sort.nameColumn}]`]: sort.value
      }
    })
      .pipe(map((response: ResponseModel<ProductModel[]>) => {
        return  response.data
          .filter((product: ProductModel) => {
            return (product.type === ProductTypes.GOODS && this.companyProfile.productOptions.goodsEnabled) ||
              (product.type === ProductTypes.SERVICES && this.companyProfile.productOptions.servicesEnabled) ||
              (product.type === ProductTypes.DIGITAL && this.companyProfile.productOptions.digitalEnabled);
          });
      }));
  }

  // get services that have measure units with time
  public getContinuousServices(
    sort: FilterModel = {nameColumn: 'name', value: SECONDARY_SORT_DIRECTION},
    salesPriceListName?: string
  ): Observable<ProductModel[]> {
    const params = {
      [`sort[${sort.nameColumn}]`]: sort.value,
      ['filters[status]']: ProductStatusEnum.ACTIVE,
    };

    if (salesPriceListName) {
      params['salesPriceListName'] = salesPriceListName;
    }

    return this.http.get('/products/services/continuous', { params })
      .pipe(map((response: ResponseModel<ProductModel[]>) => response.data));
  }

  public getCompositeProducts(
    productType: ProductTypes,
    familyId: number,
    status: ProductStatusEnum,
    page: string = '1',
    per_page: string = '100',
    sort: FilterModel = {nameColumn: 'updatedAt', value: DEFAULT_SORT_DIRECTION},
    filters: any = {},
    priceListCategory?: 'ecommerce'
  ): Observable<ResponseList<CompositeProductModel>> {
    const params = {
      page,
      per_page,
      ['filters[status]']: status,
      [`sort[${sort.nameColumn}]`]: sort.value
    };

    if (priceListCategory) {
      params['priceListCategory'] = priceListCategory;
    }

    for (const [key, value] of Object.entries(filters)) {
      params[`filters[${key}]`] = Array.isArray(value) ? value.join(',') : value.toString();
    }

    return this.http.get<ResponseList<CompositeProductModel>>(`/products/family/${familyId}`, { params })
      .pipe(
        tap((products: ResponseList<CompositeProductModel>) => {
          this.store.dispatch(LoadProductsList({
            productType,
            entityKey: familyId,
            status: status as ProductStatusEnum,
            page: products.pagination.page,
            productsListData: {
              ...products,
              sort
            },
          }));
        })
      );
  }

  public getBulkProductsList(
    productType: ProductTypes,
    status: ProductStatusEnum,
    page: string = '1',
    per_page: string = '100',
    sort: FilterModel = {nameColumn: 'updatedAt', value: DEFAULT_SORT_DIRECTION},
    filters: any = {},
    priceListCategory?: 'ecommerce'
  ): Observable<ResponseList<CompositeProductModel>> {
    const params = {
      page,
      per_page,
      ['filters[status]']: status,
      [`sort[${sort.nameColumn}]`]: sort.value
    };

    if (priceListCategory) {
      params['priceListCategory'] = priceListCategory;
    }

    for (const [key, value] of Object.entries(filters)) {
      params[`filters[${key}]`] = Array.isArray(value) ? value.join(',') : value.toString();
    }

    return this.http.get<ResponseList<CompositeProductModel>>(`/products/${productType}/list`, { params })
      .pipe(
        tap((products: ResponseList<CompositeProductModel>) => {
          this.store.dispatch(LoadProductsList({
            productType,
            entityKey: 'bulk',
            status: status as ProductStatusEnum,
            page:  products.pagination.page,
            productsListData: {
              ...products,
              sort
            },
          }));
        })
      );
  }

  getProductsRunpleIds(productType: ProductTypes, status: ProductStatusEnum, familyId: string): Observable<any> {
    const params: any = { status };
    if (familyId) {
      params.familyId = familyId;
    }
    return this.http.get<any>(`/products/${productType}/runpleIds`, { params })
      .pipe(map((data: any) => data.data));
  }

  getProductsNames(productType: ProductTypes, status: ProductStatusEnum, familyId: string): Observable<any> {
    const params: any = { status };
    if (familyId) {
      params.familyId = familyId;
    }
    return this.http.get<any>(`/products/${productType}/names`, { params })
      .pipe(map((data: any) => data.data));
  }

  public createCompositeProduct(
    familyId: number,
    productType: ProductTypes,
    productData: CompositeProductModel
  ): Observable<CompositeProductModel> {
    return this.http.post<ResponseModel<CompositeProductModel>>(
      `/products/family/${familyId}/${productType}/products`,
      productData
    )
      .pipe(
        tap(() => {
          if (!this.companyProfile.onboardingCompleted) {
            this.administrationsApiService.getOnboardingProcess().subscribe();
          }
        }),
        map((response: ResponseModel<CompositeProductModel>) => response.data)
      );
  }

  updateProductField(
    productId: number|string,
    field: FormInputChangedModel,
    salesPriceListName?: string
  ): Observable<CompositeProductModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());
    const params = {};
    if (salesPriceListName) {
      params['salesPriceListName'] = salesPriceListName;
    }

    return this.http.patch<ResponseModel<CompositeProductModel>>(`/products/${productId}`, field, { params })
      .pipe(
        tap((response: ResponseModel<CompositeProductModel>) => {
          this.store.dispatch(LoadProduct({product: response.data as ProductModel}));
          if (!this.companyProfile.onboardingCompleted) {
            this.administrationsApiService.getOnboardingProcess().subscribe();
          }
        }),
        map((response: ResponseModel<CompositeProductModel>) => response.data),
        finalize(() => {
          this.store.dispatch(DecrementLoadingRequestsCount());
          this.store.dispatch(UpdateProductUpdatedAt({updatedAt: new Date()}));
        }),
        catchError(error => {
          this.handlePopupErrors(error, {productId: +productId});
          return throwError(error);
        })
      );
  }

  @DisplayToaster({showErrorMessage: true})
  public cloneProduct(productId: number): Observable<CompositeProductModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<CompositeProductModel>>('post', `/products/${productId}/clone`)
      .pipe(
        tap((response: ResponseModel<ProductModel>) => {
          this.store.dispatch(LoadProduct({product: response.data as ProductModel}));
          this.store.dispatch(UpdateProductsCurrentState({currentState: UIStatesEnum.EDIT}));
        }),
        map((response: ResponseModel<CompositeProductModel>) => response.data),
        finalize(() => {
          this.store.dispatch(DecrementLoadingRequestsCount());
          this.store.dispatch(UpdateProductUpdatedAt({updatedAt: new Date()}));
        })
      );
  }

  public lockProductEditing(productId: number, force = false): Observable<ProductModel> {
    const body = { force };
    const params = { salesPriceListName: this.salesPriceListName };

    return this.http.put(`/products/${productId}/locking`, body, { params })
      .pipe(
        tap((response: ResponseModel<ProductModel>) => {
          this.store.dispatch(LoadProduct({product: response.data as ProductModel}));
          this.store.dispatch(UpdateProductsCurrentState({currentState: UIStatesEnum.EDIT}));
        }),
        map((response: ResponseModel<ProductModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error, {productId});
          return throwError(error);
        })
      );
  }

  public unlockProductEditing(productId: number): Observable<ProductModel> {
    const params = { salesPriceListName: this.salesPriceListName };
    return this.http.put(`/products/${productId}/unlocking`, {}, { params })
      .pipe(
        tap((response: ResponseModel<ProductModel>) => {
          this.store.dispatch(LoadProduct({product: response.data as ProductModel}));
          this.store.dispatch(UpdateProductsCurrentState({currentState: UIStatesEnum.VIEW}));
        }),
        map((response: ResponseModel<ProductModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error, {productId});
          return throwError(error);
        })
      );
  }

  public moveProductToAnotherCategory(
    productId: number,
    familyId: string,
    force = false,
    container?: {[key: string]: any}
  ): Observable<ProductModel> {
    const body = {
      container,
      familyId,
      force
    };
    return this.http.request('patch', `/products/${productId}/category`, {body})
      .pipe(
        tap((response: ResponseModel<ProductModel>) => {
          this.store.dispatch(LoadProduct({product: response.data as ProductModel}));
        }),
        finalize(() => {
          this.store.dispatch(UpdateProductUpdatedAt({updatedAt: new Date()}));
        }),
        map((response: ResponseModel<ProductModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error, {productId, familyId, container});
          return throwError(error);
        })
      );
  }

  public moveProductsToAnotherCategory(productIds: number[], familyId: string, force = false): Observable<any> {
    const body = {
      productIds,
      familyId,
      force
    };
    return this.http.request('patch', `/products/category`, {body})
      .pipe(
        catchError(error => {
          this.handlePopupErrors(error, {productIds, familyId});
          return throwError(error);
        })
      );
  }

  public getProductVariations(productId: number): Observable<ProductVariationsResponse> {
    return this.http.get<ResponseModel<ProductVariationsResponse>>(`/products/${productId}/variations`)
      .pipe(
        map((response: ResponseModel<ProductVariationsResponse>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  @DisplayToaster({showErrorMessage: true})
  public addProductVariation(productId: number, ids: number[]): Observable<any> { // todo: type
    return this.http.post<ResponseModel<any>>(`/products/${productId}/variations`, { ids })
      .pipe(
        map((response: ResponseModel<any>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public removeProductVariation(productId: number, ids: number[]): Observable<any> { // todo: type
    const body = { ids };
    return this.http.request<ResponseModel<any>>('delete', `/products/${productId}/variations`, { body })
      .pipe(
        map((response: ResponseModel<any>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getGeneralProductsList(
    familyId: string,
    status: ProductStatusEnum,
    page: string = '1',
    per_page: string = '100',
    sort: FilterModel = {nameColumn: 'updatedAt', value: DEFAULT_SORT_DIRECTION},
    filters: any = {},
    isVisibleForList = false,
  ): Observable<ResponseList<CompositeProductModel>> {
    const params = {
      page,
      per_page,
      [`sort[${sort.nameColumn}]`]: sort.value
    };

    if (familyId) {
      params['filters[familyId]'] = familyId;
    }
    if (status) {
      params['filters[status]'] = status;
    }
    if (isVisibleForList) {
      params['filters[isVisibleForList]'] = isVisibleForList as any;
    }

    for (const [key, value] of Object.entries(filters)) {
      params[`filters[${key}]`] = Array.isArray(value) ? value.join(',') : value.toString();
    }

    return this.http.get<ResponseList<CompositeProductModel>>('/products/general/list', { params });
  }

  public activateGeneralProduct(productId: number): Observable<any> { // todo: model
    return this.http.request('patch', `/products/general/${productId}/activate-sales`);
  }

  public activateGeneralProductsByCategory(categoryId: number|string): Observable<any> { // todo: model
    return this.http.request('patch', `/products/general/family/${categoryId}/activate-sales`);
  }

  public deactivateGeneralProductsByCategory(categoryId: number|string): Observable<any> { // todo: model
    return this.http.request('patch', `/products/general/family/${categoryId}/deactivate-sales`);
  }

  public toggleGeneralCategoryVisibility(categoryId: number|string,  isVisibleForList: boolean): Observable<any> { // todo: model
    const params = { isVisibleForList };
    return this.http.put(`/products/families/${categoryId}/general-list/display`, params);
  }

  public getProductsListExport(title: string, productType: ProductTypes, status: ProductStatusEnum): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: `/products/${productType}/${status}/csv`,
      type: 'zip',
      title,
    };
    return of(fileParams);
  }

  public getGeneralProductsListDropdown(groupByFamily = true, type: 'document'|'payment' = 'document'): Observable<any> { // todo: model
    const params: any = { groupByFamily, type };
    return this.http.get('/products/general/dropdown', { params })
      .pipe(
        map((response: ResponseModel<any>) => {
          // sort general categories by translated titles
          return {
            ...response,
            data: response.data
              .map(itm => ({
                ...itm,
                title: this.translateService.instant('GL_CATEGORY.' + itm.category),
                searchLabel: groupByFamily
                  ? `${this.translateService.instant('GL_CATEGORY.' + itm.category)} ${itm.children.map(c => c.name).join(' ')}`
                  : itm.name,
              }))
              .map(itm => {
                return groupByFamily
                  ? {
                    ...itm,
                    children: itm.children.map(c => ({
                      ...c,
                      searchLabel: c.name
                    }))
                  }
                  : itm;
              })
              .sort((a, b) => a.title.localeCompare(b.title))
          };
        }),
        map((data: any) => data.data)
      );
  }

  getManufacturerCode(status: ProductStatusEnum, familyId: string): Observable<any> {
    const params: any = { status };
    if (familyId) {
      params.familyId = familyId;
    }
    return this.http.get<any>(`/products/goods/manufacturer-code`, { params })
      .pipe(map((data: any) => data.data));
  }

  getEanList(status: ProductStatusEnum, familyId: string): Observable<any> {
    const params: any = { status };
    if (familyId) {
      params.familyId = familyId;
    }
    return this.http.get<any>(`/products/goods/ean`, { params })
      .pipe(map((data: any) => data.data));
  }

  manufacturerList(status: ProductStatusEnum, familyId: string): Observable<any> {
    const params: any = { status };
    if (familyId) {
      params.familyId = familyId;
    }
    return this.http.get<any>(`/products/goods/manufacturer`, { params })
      .pipe(map((data: any) => data.data));
  }

  modelList(status: ProductStatusEnum, familyId: string): Observable<any> {
    const params: any = { status };
    if (familyId) {
      params.familyId = familyId;
    }
    return this.http.get<any>(`/products/goods/model`, { params })
      .pipe(map((data: any) => data.data));
  }

  colorList(status: ProductStatusEnum, familyId: string): Observable<any> {
    const params: any = { status };
    if (familyId) {
      params.familyId = familyId;
    }
    return this.http.get<any>(`/products/goods/color`, { params })
      .pipe(map((data: any) => data.data));
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  private handlePopupErrors(
    error: HttpErrorResponse,
    params?: {
      productId?: number,
      familyId?: string,
      container?: {[key: string]: any},
      productIds?: number[]
    }): void {
    if (!error || !error.error || !error.error.message) { return; }
    switch (error.error.message) {
      case 'anotherUserEditError':
      {
        const dialog = this.dialog.open(WarningModalComponent, {
          data: getAnotherUserEditErrorModalData(
            {
              document: error.error.data.entityName,
              userName: error.error.data.userName,
            },
            this.translateService
          )
        });

        dialog.afterClosed().subscribe(res => {
          if (res === CommonModalsActionsEnum.CONFIRM) {
            this.lockProductEditing(params.productId, true).subscribe();
          }
        });
      }
        break;
      case 'notAllProductRequiredAttributesFilled':
        {
          const dialog = this.dialog.open(WarningModalComponent, {
            data: {
              title: 'PRODUCTS.MOVE_PRODUCT_TITLE',
              message: 'PRODUCTS.MOVE_PRODUCT_MSG',
              confirmBtnText: 'BUTTON.MOVE',
              confirmBtnIcon: 'shuffle'
            }
          });

          dialog.afterClosed().subscribe(res => {
            if (res === CommonModalsActionsEnum.CONFIRM) {
              this.moveProductToAnotherCategory(params.productId, params.familyId, true, params.container)
                .subscribe(() => this.dialog.closeAll());
            }
          });
        }
        break;
      case 'notAllProductRequiredAttributesFilledMultiple':
        {
          const dialog = this.dialog.open(WarningModalComponent, {
            data: {
              title: 'PRODUCTS.MOVE_PRODUCTS_TITLE',
              message:  this.translateService.instant('PRODUCTS.MOVE_PRODUCTS_MSG', { quantity: error.error.data}),
              confirmBtnText: 'BUTTON.MOVE',
              confirmBtnIcon: 'shuffle'
            }
          });

          dialog.afterClosed().subscribe(res => {
            if (res === CommonModalsActionsEnum.CONFIRM) {
              this.moveProductsToAnotherCategory(params.productIds, params.familyId, true)
                .subscribe(() => this.dialog.getDialogById('moveProductsModalComponentId').close(CommonModalsActionsEnum.CONFIRM));
            }
          });
        }
        break;
      case 'notEditModeError':
        const documentName = this.translateService.instant('APP.PRODUCT');
        this.showMsg('warning', this.translateService.instant('COMMON.DOC_UPDATED_BY_USER', { document: documentName}));
        this.store.dispatch(UpdateProductsCurrentState({currentState: UIStatesEnum.VIEW}));
        this.getProduct(params.productId).subscribe();
        break;
      default:
        this.showMsg('error', error.error.message);
        break;
    }
  }

}
