import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { ProductsService, RelatedProductModel } from 'common/src/modules/products';
import { ActionButtonsService } from 'common/src/services/action-buttons.service';
import { ProductRelationDirectionEnum } from '../../../products-two-level/enums';

@Component({
  selector: 'rnpl-related-products-cards',
  templateUrl: './related-products-cards.component.html',
  styleUrls: ['./related-products-cards.component.scss']
})
export class RelatedProductsCardsComponent implements OnChanges, OnDestroy {

  @Input() public relatedProducts: RelatedProductModel[] = [];
  @Input() public quantity: number;
  @Input() public productId: number;
  @Input() public vatDisabled: boolean;
  @Input() public salesPriceListName;
  @Input() public type: ProductRelationDirectionEnum = ProductRelationDirectionEnum.OUTGOING;

  @Output() updated: EventEmitter<RelatedProductModel[]> = new EventEmitter<RelatedProductModel[]>();

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    public actionButtonsService: ActionButtonsService,
    public productsService: ProductsService,
    ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.hasOwnProperty('quantity') && this.quantity) {
      this.calculateRelatedProducts();
    }
  }

  public calculateRelatedProducts(): void {
    if (!this.productId) { return; }

    this.productsService.calculateRelatedProducts(
      this.productId,
      this.type,
      this.quantity,
      this.salesPriceListName,
      this.vatDisabled,
    )
      .pipe()
      .subscribe((res: RelatedProductModel[]) => {
        this.relatedProducts = this.relatedProducts.map((product: RelatedProductModel) => {
          const calculatedProduct = res.find(p => p.id === product.id);
          return {
            ...product,
            ...calculatedProduct
          };
        });
        this.updated.emit(this.relatedProducts);
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
