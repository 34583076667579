import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, finalize, takeUntil } from 'rxjs/operators';

import { UserColumnsSettingsService } from '../../services/user-columns-settings.service';
import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { ColumnsSettings } from '../../models';
import { TableColumnModel } from 'common/src/models/table-column.model';

@Component({
  selector: 'rnpl-columns-settings',
  templateUrl: './columns-settings.component.html',
  styleUrls: ['./columns-settings.component.scss'],
})
export class ColumnsSettingsComponent implements OnChanges, OnDestroy {

  public customizedColumns: { [key: string]: boolean };

  @Input() public documentStatus: string; // if document dont have statuses than pass document type
  @Input() public documentType: DocumentTypesUppercaseEnum|string;
  @Input() public sourceColumnsConfig: TableColumnModel[];
  @Input() public disabled = false;

  @Output() customized: EventEmitter<TableColumnModel[]> = new EventEmitter<TableColumnModel[]>();

  public refreshing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private valueChange$: Subject<{ prop: string, value: boolean }> = new Subject<{ prop: string, value: boolean }>();
  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(private userColumnsSettingsService: UserColumnsSettingsService) {
    this.valueChange$
      .pipe(
        debounceTime(250),
        takeUntil(this.destroy$)
      )
      .subscribe((change: { prop: string, value: boolean }) => this.updateColumnsSettings(change));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.hasOwnProperty('documentType') && this.documentType) ||
      (changes.hasOwnProperty('sourceColumnsConfig') && this.documentType && this.documentStatus) ||
      (changes.hasOwnProperty('documentStatus') && this.documentStatus)
    ) {
      this.getColumnsSettings();
    }
  }

  public refreshColumnsSettings(): void {
    this.refreshing$.next(true);
    this.getColumnsSettings();
  }

  private getColumnsSettings(): void {
    this.loading$.next(true);
    this.userColumnsSettingsService.getColumnsSettings(this.documentStatus, this.documentType)
      .pipe(
        finalize(() => {
          this.refreshing$.next(false);
          this.loading$.next(false);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((columnsSettings: ColumnsSettings) => {
        this.customizedColumns = columnsSettings.columns;
        if (!columnsSettings.columns) {
          this.customized.emit(this.sourceColumnsConfig);
        } else {
          this.filterColumnsBySettings();
        }
      });
  }

  private updateColumnsSettings(change: { prop: string, value: boolean }): void {
    const updateCustomizedColumns: {[key: string]: boolean} = {};
    this.sourceColumnsConfig.map((column: TableColumnModel) => {
      if (column.hasOwnProperty('customizable') && column.customizable) {
        updateCustomizedColumns[column.prop] = this.customizedColumns
          ? !!this.customizedColumns[column.prop]
          : true;
      }
    });
    updateCustomizedColumns[change.prop] = change.value;

    const columnsSettings: ColumnsSettings = {
      type: this.documentType,
      status: this.documentStatus,
      columns: updateCustomizedColumns
    };

    this.userColumnsSettingsService.saveColumnsSettings(columnsSettings)
      .pipe(takeUntil(this.destroy$))
      .subscribe((columnsSettings: ColumnsSettings) => {
        this.customizedColumns = columnsSettings.columns;
        this.filterColumnsBySettings();
      });
  }

  public toggleColumn(value: boolean, prop: string): void {
    this.valueChange$.next({value, prop});
  }

  private filterColumnsBySettings(): void {
    const filteredColumns: TableColumnModel[] = [...this.sourceColumnsConfig]
      .map(c => ({ ...c })) // clone
      .filter((c: TableColumnModel) => {
         if (c.hasOwnProperty('customizable')) {
           if (!this.customizedColumns.hasOwnProperty(c.prop) && !c.hiddenByDefault) {
             // always display new columns that missed in saved config, except hidden by default columns
             return true;
           }
           return this.customizedColumns[c.prop];
         }
         return true;
      });

    const notFixedColumns = filteredColumns.filter((c: TableColumnModel) => !c.maxWidth || c.maxWidth > 40);

    if (notFixedColumns && notFixedColumns.length && notFixedColumns.every(c => !!c.maxWidth)) {
        const lastIndex = filteredColumns
          .findIndex(c => c.prop === notFixedColumns[notFixedColumns.length - 1].prop);
        filteredColumns[lastIndex].maxWidth = null;
    }

    // const customizableFilteredColumns: TableColumnModel[] = filteredColumns
    //   .filter(c => c.hasOwnProperty('customizable') && c.customizable);
    //
    // // remove fixed width from last column if needed
    // if (customizableFilteredColumns.length && customizableFilteredColumns.every(c => !!c.maxWidth)) {
    //   const lastCustomizableIndex = filteredColumns
    //     .findIndex(c => c.prop === customizableFilteredColumns[customizableFilteredColumns.length - 1].prop);
    //   filteredColumns[lastCustomizableIndex].maxWidth = null;
    // }
    this.customized.emit(filteredColumns);
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
