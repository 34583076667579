export interface TableSummaryBarItemModel {
  label: string;
  value: string | Date | number;
  maxValue?: number;
  type: TableSummaryBarItemTypeEnum;
  valueClass?: string;
  titleIcon?: string;
  showWithStatuses?: any[];
  hideWithStatuses?: any[];
  currencySymbol?: string;
  postfix?: string;
  prefix?: string;
  inverse?: boolean;
  currencyConversion?: boolean;
  unitTypeShortcut?: boolean;
  realValue?: boolean;
  // dateFormat?: string;
  highlightTypes?: HighlightTypeEnum[];
  showWithDocumentStatus?: string[];
  hideWithDocumentStatus?: string[];
  availableOnStockTotal?: any;
  showWithLinkedSo?: boolean;
  basisWidth?: number;
  colorState?: string;
  displayed?: boolean;
  labelClass?: string;
  id?: string;
}

export enum TableSummaryBarItemTypeEnum {
  PRIMARY,
  WITH_CURRENCY,
  WITH_NUMBER,
  WITH_DATE_PRIMARY,
  WITH_PROFIT,
  STATUS_LINE,
  // STATUS_LINE_VAL_ONLY,
  STATUS_LINE_MAX_VAL_ONLY,
  // STATUS_LINE_DATE,
}

export enum HighlightTypeEnum {
  POSITIVE_TO_GREEN = 'POSITIVE_TO_GREEN',
  POSITIVE_TO_GREEN_400 = 'POSITIVE_TO_GREEN_400',
  POSITIVE_TO_RED = 'POSITIVE_TO_RED',
  POSITIVE_TO_RED_LIGHT = 'POSITIVE_TO_RED_LIGHT',
  POSITIVE_TO_ORANGE = 'POSITIVE_TO_ORANGE',
  POSITIVE_TO_YELLOW = 'POSITIVE_TO_YELLOW',
  NEGATIVE_TO_GREEN = 'NEGATIVE_TO_GREEN',
  NEGATIVE_TO_RED = 'NEGATIVE_TO_RED',
  DATE_IN_PAST_TO_RED = 'DATE_IN_PAST_TO_RED',
  DATE_IN_PAST_TO_GREEN = 'DATE_IN_PAST_TO_GREEN',
  DATE_IN_FUTURE_TO_RED = 'DATE_IN_FUTURE_TO_RED',
  DATE_IN_FUTURE_TO_GREEN = 'DATE_IN_FUTURE_TO_GREEN',
}

export enum HighlightClassColorEnum {
  GREEN = 'color-green-600',
  GREEN_400 = 'color-green-400',
  RED = 'color-danger-red',
  RED_LIGHT = 'color-red-300',
  ORANGE = 'color-badge-orange',
  YELLOW = 'color-yellow-600'
}
