import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  Appearance,
  loadStripe,
  Stripe,
  StripeElements,
  StripeError,
  StripePaymentElement,
  // StripePaymentElementChangeEvent
} from '@stripe/stripe-js';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { selectCompanyProfile } from '../../administration/store/selectors';
import { CompanyProfile } from '../../administration/models/company-profile.model';
import { AppState } from '../../store/state/app.state';
import { ResponseModel } from '../models';
import { LoadCompanyProfile, UpdateProfileUpdatedAt } from '../../administration/store/actions/company-profile.actions';
import { SubscriptionActivationPricesModel } from 'common/src/modules/subscription-activation/subscription-activation-prices.model';
import {
  SubscriptionBillingHistoryItemModel
} from 'common/src/modules/subscription-activation/billing-details/subscription-billing-history-item.model';
import {
  SubscriptionPaymentMethodDetailsModel
} from 'common/src/modules/subscription-activation/subscription-payment-method-details.model';

@Injectable()
export class StripePaymentService {

  public static PAYMENT_ELEMENT_ID = 'stripeSubscriptionPaymentElement';
  public static PAYMENT_UPDATE_ELEMENT_ID = 'stripeSubscriptionPaymentUpdateElement';

  // public stripePaymentCanBeMade = false;
  // public stripePaymentUpdates = false;

  private stripe: Stripe = null as any;
  private stripeUpdate: Stripe = null as any;
  private stripeElements: StripeElements = null as any;
  private stripeElementsUpdate: StripeElements = null as any;
  private paymentElement: StripePaymentElement = null as any;
  private paymentUpdateElement: StripePaymentElement = null as any;
  public paymentIntentId: string = null;

  private stripeAppearanceSettings: Appearance = {
    rules: {
      '.Input': {
        color: '#000000',
        fontSize: '14px',
        padding: '8px 12px 8px 12px',
        borderRadius: '10px',
        border: '2px solid #e0e2eb',
        boxShadow: 'none',
        lineHeight: '20px'
      },
      '.Input:focus': {
        boxShadow: 'none',
        borderColor: '#30364a',
        backgroundColor: '#30364a',
        color: '#fff'
      },
      '.Input::placeholder': {
        color: '#9ca4be'
      },
      '.Input--invalid': {
        boxShadow: 'none',
        borderColor: '#D32F2F'
      },
      '.Label': {
        fontWeight: '500',
        fontFamily: 'Inter, sans-serif',
        fontSize: '12px',
        color: '#5E6681',
        marginBottom: '2px',
        marginTop: '2px',
        letterSpacing: '0'
      },
      '.Error': {
        color: '#c02121',
        fontFamily: '"Inter", sans-serif',
        fontSize: '12px',
      },
    }
  };

  private wid: number;
  private readonly apiEndpoint: string = `${environment.javaApiVersion}/workspaces`;

  constructor(
    private http: HttpClient,
    private router: Router,
    private readonly store: Store<AppState>,
  ) {
    this.store.select(selectCompanyProfile)
      .subscribe((profile: CompanyProfile) => {
        this.wid = profile.workspaceId;
      });
  }

  public initStripe(promoCode?: string, callbackFn?: Function): void {
    this.loadStripe().then((stripe: Stripe|null) => {
      if (stripe) {
        this.stripe = stripe;
        this.getPaymentIntent$(window.location.href, promoCode).subscribe((res: any) => {
          this.paymentIntentId = res.data.id;
          this.stripeElements = this.stripe.elements({
            clientSecret: res.data.clientSecret,
            appearance: this.stripeAppearanceSettings,
            locale: 'de'
          });
          this.paymentElement = this.stripeElements.create('payment');
          // this.trackPaymentElementChange(this.paymentElement);
          // element with id `#${StripePaymentService.PAYMENT_ELEMENT_ID}` must be presented
          this.paymentElement.mount(`#${StripePaymentService.PAYMENT_ELEMENT_ID}`);
          if (callbackFn) {
            callbackFn();
          }
        });
      }
    });
  }

  public initStripeUpdate(): void {
    this.loadStripe().then((stripe: Stripe|null) => {
      if (stripe) {
        this.stripeUpdate = stripe;
        this.getUpdatePaymentIntent$(window.location.href).subscribe((res: any) => {
          this.stripeElementsUpdate = this.stripeUpdate.elements({
            clientSecret: res.data.clientSecret,
            appearance: this.stripeAppearanceSettings,
            locale: 'de'
          });
          this.paymentUpdateElement = this.stripeElementsUpdate.create('payment');
          // this.trackPaymentElementChange(this.paymentUpdateElement);
          // element with id `#${StripePaymentService.PAYMENT_ELEMENT_ID}` must be presented
          this.paymentUpdateElement.mount(`#${StripePaymentService.PAYMENT_UPDATE_ELEMENT_ID}`);
        });
      }
    });
  }

  async loadStripe(): Promise<Stripe|null> {
    let publishableKey = await this.getPublishableKey$().toPromise();

    if (!publishableKey && window.location.origin.includes('localhost:4200')) { // for developing and testing
      publishableKey = 'pk_test_108OkLvL13rvgAJ8pBrmmwdl00H7Jv5Lqe';
    }

    return await loadStripe(publishableKey);
  }

  // public trackPaymentElementChange(paymentElement: StripePaymentElement): void {
  //   if (!paymentElement) { return; }
  //
  //   paymentElement.on('change', (event: StripePaymentElementChangeEvent) => {
  //     this.stripePaymentCanBeMade = event.complete;
  //   });
  // }

  public confirmPayment(failedCallback?: Function): void {
    this.stripeElements.submit() // validate form state
      .then((result: {error?: StripeError}) => {
        if (result && result.error) {
          if (failedCallback) {
            failedCallback();
          }
          return;
        }

        this.stripe.confirmPayment({
          elements: this.stripeElements,
          confirmParams: {
            return_url: window.location.origin + '/start?activationSuccess'
          }
        }).then((result: {error: StripeError}) => {
          if (result.error) {
            // handle payment error
            if (failedCallback) {
              failedCallback();
            }
          }
        });
      });

  }

  public confirmUpdatePayment(successCallback: Function, failedCallback?: Function): void {
    this.stripeElementsUpdate.submit() // validate form state
      .then((result: {error?: StripeError}) => {
        if (result && result.error) {
          if (failedCallback) {
            failedCallback();
          }
          return;
        }

        this.stripeUpdate.confirmSetup({
          elements: this.stripeElementsUpdate,
          confirmParams: {
            return_url: window.location.href
          },
          redirect: 'if_required'
        }).then((result: {error: StripeError}) => {
          if (result.error) {
            if (failedCallback) {
              failedCallback();
            }
            // handle payment error
            return;
          }
          successCallback();
        });
      });
  }

  private getPaymentIntent$(confirmation: string, promoCode?: string): Observable<any> {
    return this.http.request('post', this.apiUrl('/payment-intent'), {body: {confirmation, promoCode}});
  }

  private getUpdatePaymentIntent$(confirmation: string): Observable<any> {
    return this.http.request('post', this.apiUrl('/update-intent'), {body: {confirmation}});
  }

  private getPublishableKey$(): Observable<string> {
    return this.http.get(`${environment.javaApiVersion}/utils/stripe/public-key`)
      .pipe(map(res => (res as any).data));
  }

  private apiUrl(url: string = ''): string {
    return `${this.apiEndpoint}/${this.wid}/subscription` + url;
  }

  public cancelSubscription(confirmation: string): Observable<ResponseModel<CompanyProfile>> {
    return this.http.post<ResponseModel<CompanyProfile>>(
      this.apiUrl('/cancel'),
      { confirmation },
    )
      .pipe(
        tap((response: ResponseModel<CompanyProfile>) => {
          this.store.dispatch(LoadCompanyProfile({ companyProfile: response.data as CompanyProfile }));
        })
      );
  }

  public deleteAccount(confirmation: string): Observable<string> {
    return this.http.post<ResponseModel<string>>(this.apiUrl('/delete'), { confirmation })
      .pipe(map((response: ResponseModel<string>) => response.data));
  }

  public reActivateSubscription(): Observable<ResponseModel<CompanyProfile>> {
    return this.http.request<ResponseModel<CompanyProfile>>(
      'post',
      this.apiUrl('/re-activate'),
    )
      .pipe(
        tap((response: ResponseModel<CompanyProfile>) => {
          this.store.dispatch(LoadCompanyProfile({ companyProfile: response.data as CompanyProfile }));
        })
      );
  }

  public cancelPaymentIntent(): Observable<ResponseModel<CompanyProfile>> {
    return this.http.delete<ResponseModel<CompanyProfile>>(this.apiUrl('/payment-intent'))
      .pipe(
        tap((response: ResponseModel<CompanyProfile>) => {
          this.store.dispatch(LoadCompanyProfile({ companyProfile: response.data as CompanyProfile }));
          this.store.dispatch(UpdateProfileUpdatedAt({ updatedAt: new Date() }));
        }),
      );
  }

  public cancelUpdateIntent(): Observable<ResponseModel<CompanyProfile>> {
    return this.http.delete<ResponseModel<CompanyProfile>>(this.apiUrl('/update-intent'));
  }

  public getCompanySubscriptionPrices(companyProfile: CompanyProfile, paymentIntentId?: string): Observable<SubscriptionActivationPricesModel> {
    const params = { };
    if (paymentIntentId) {
      params['paymentIntentId'] = paymentIntentId;
    }
    return this.http.post<ResponseModel<SubscriptionActivationPricesModel>>(
      this.apiUrl('/prices'),
      {...companyProfile},
      { params }
    )
      .pipe(
        map((data: ResponseModel<SubscriptionActivationPricesModel>) => data.data),
      );
  }

  public getCompanySubscriptionBillingHistory(): Observable<SubscriptionBillingHistoryItemModel[]> {
    return this.http.get<ResponseModel<SubscriptionBillingHistoryItemModel[]>>(this.apiUrl('/billing-history'))
      .pipe(
        map((data: ResponseModel<SubscriptionBillingHistoryItemModel[]>) => data.data),
      );
  }

  public getCompanySubscriptionPaymentMethod(): Observable<SubscriptionPaymentMethodDetailsModel> {
    return this.http.get<ResponseModel<SubscriptionPaymentMethodDetailsModel>>(this.apiUrl('/payment-method'))
      .pipe(
        map((data: ResponseModel<SubscriptionPaymentMethodDetailsModel>) => data.data),
      );
  }

}
