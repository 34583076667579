import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { finalize, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { ProductTypes } from '../../../products/product-types';
import { TableColumnModel } from '../../../../models/table-column.model';
import { TradePositionModel } from 'projects/workspace/src/app/trade/models';
import {
  ControlTypeEnum,
  DisplayConditionsModel,
  PositionInfoListModel,
  PositionItemTypeEnum
} from '../../../position-card/position-card-models.model';
import { VAT_LIST } from 'projects/workspace/src/app/shared/constants';
import { TradeOfferModel, TradeOfferPositionsByProductType } from 'projects/workspace/src/app/trade-offer/models';
import { generatePositionsCardsInfo } from 'projects/workspace/src/app/trade-offer/pages/positions/positions.config';
import { TradeOfferApiService } from 'projects/workspace/src/app/trade-offer/services/trade-offer-api.service';
import { UIStatesEnum } from '../../../../models';
import { ProductUnitModel, ResponseModel } from 'projects/workspace/src/app/shared/models';
import { selectTradeOfferPositions, selectTradeOfferState } from 'projects/workspace/src/app/trade-offer/store/selectors';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { ActionButtonsService } from '../../../../services/action-buttons.service';
import { CommonModalsActionsEnum, ConfirmModalComponent, DangerModalComponent } from '../../modals-common';
import { PositionsActionsType } from '../../../ui-components/table/custom-table.enums';
import {
  CHANGE_PARENT_QUANTITY_MODAL_DATA,
  REMOVE_POSITION_MODAL_DATA,
  REMOVE_POSITION_NECESSARILY_MODAL_DATA,
  REMOVE_POSITION_PARENT_MODAL_DATA
} from '../../modals.contsans';
import { ProductUnitApiService } from 'projects/workspace/src/app/shared/services';

@Component({
  selector: 'rnpl-offer-edit-position-modal',
  templateUrl: './offer-edit-position-modal.component.html',
  styleUrls: ['./offer-edit-position-modal.component.scss']
})
export class OfferEditPositionModalComponent implements OnInit, OnDestroy {

  public productTypes: typeof ProductTypes = ProductTypes;
  public controlTypeEnum: typeof ControlTypeEnum = ControlTypeEnum;
  public positionItemTypeEnum: typeof PositionItemTypeEnum = PositionItemTypeEnum;
  public position: TradePositionModel;
  public positions: TradePositionModel[];
  public rowIndex: number;
  public readonly vatList = VAT_LIST;
  public isReadOnly: boolean = true;
  public showControls: boolean = true;
  public positionsUpdateRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public positionsByProductType: {[key in ProductTypes]?: any[]};

  public positionsCardInfoByTypes: {[key in ProductTypes]?: any[]} = {
    [ProductTypes.GOODS]: [] as TableColumnModel[],
    [ProductTypes.SERVICES]: [] as TableColumnModel[],
    [ProductTypes.DIGITAL]: [] as TableColumnModel[],
    arbitrary: [] as TableColumnModel[]
  };

  public unitsList: string[] = [];
  public unitsListSource: ProductUnitModel[] = [];

  public readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    public dialogRef: MatDialogRef<OfferEditPositionModalComponent>,
    private tradeOfferApiService: TradeOfferApiService,
    private translateService: TranslateService,
    private readonly store: Store<AppState>,
    public actionButtonsService: ActionButtonsService,
    private readonly dialog: MatDialog,
    private productUnitApiService: ProductUnitApiService,
    @Inject(MAT_DIALOG_DATA) public data: {
      currentOffer: TradeOfferModel;
      position: TradePositionModel,
      positions: TradePositionModel[],
      rowIndex: number,
      positionsByProductType: {[key in ProductTypes]?: any[]}
    }
  ) { }

  ngOnInit() {
    this.position = {
      ...this.data.position,
      marginData: {
        profit: this.data.position.marginNet,
        percent: this.data.position.marginRate,
        currency: '€'
      },
    };
    this.positions = this.data.positions;
    this.positionsByProductType = this.data.positionsByProductType;

    this.rowIndex = this.getRowIndex(this.position);

    this.getProductUnits();
    this.getPositionsCards();
    this.getCurrentOffer();
    this.trackTradeOfferStateChanges();
    this.trackPositionsChanges();
  }

  public getProductUnits(): void {
    this.productUnitApiService.getUnits$(this.position.productType)
      .pipe(takeUntil(this.destroy$))
      .subscribe((unitsList: ProductUnitModel[]) => {
        this.unitsListSource = unitsList;
        if (!this.position.general) {
          this.unitsList = [this.position.unitType];
          return;
        } else {
          this.unitsList = unitsList.map(u => u.name);
        }
        this.redrawControls();
      });
  }

  private trackTradeOfferStateChanges(): void {
    this.store.select(selectTradeOfferState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: UIStatesEnum) => {
        this.isReadOnly = state === UIStatesEnum.VIEW;
      });
  }

  private getPositionsCards(): void {
    if (this.data.currentOffer) {
      this.positionsCardInfoByTypes[this.position.productType] = generatePositionsCardsInfo(
        this.translateService,
        this.position.productType,
        UIStatesEnum.CREATE,
        this.data.currentOffer.status,
        this.data.currentOffer.properties.vatDisabled || this.data.currentOffer.properties.smallBusiness
      );
    }
  }

  public getRowIndex(position): number {
    let rowIndex: number;
    this.positions.map((pos, index) => {
      if (pos.id === position.id) {
        rowIndex = index;
      }
    });
    return rowIndex;
  }

  public actionListHandler({actionType, row, moveTo}): void {
    if (actionType === PositionsActionsType.INFO) {
      this.actionButtonsService.displaySidebarHandler(row.productId);
      return;
    }
    this.movePosition(row.id, moveTo);
  }

  public displayProductInfo(productId): void {
    this.actionButtonsService.displaySidebarHandler(productId);
  }

  public deletePositionDialog(): void {
    let data = REMOVE_POSITION_MODAL_DATA;

    if (this.position.linked && this.position.linked.related) {
      data = REMOVE_POSITION_PARENT_MODAL_DATA;
    }

    if (this.position.necessarily) {
      data = REMOVE_POSITION_NECESSARILY_MODAL_DATA;
    }

    const dialog = this.dialog.open(DangerModalComponent, {
      data
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === CommonModalsActionsEnum.CONFIRM) {
        this.deletePositions([this.position.id]);
      }
    });
  }

  public deletePositions(ids: number[]): void {
    this.tradeOfferApiService.deleteTradeOfferPositions(this.data.currentOffer.id, ids)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.getCurrentOffer();
        this.dialogRef.close();
      });
  }

  public trackByProp(index: number, item: PositionInfoListModel) {
    return item.prop;
  }

  public displayConditionsHandler(position, conditionsList: DisplayConditionsModel[]): boolean {
    if (!conditionsList && !conditionsList.length) { return false; }

    return conditionsList.every(condition => {
      return condition.values.some((val) => {
        if (!!condition.productType) {
          return position[condition.prop] === val && condition.productType === position.productTypeForGrouping;
        } else {
          return position[condition.prop] === val;
        }
      });
    });
  }

  public updatePosition(fieldName, fieldValue, force = false): void {
    if (fieldName === 'quantity' && !force && this.position.linked && this.position.linked.related) {
      this.dialog.open(ConfirmModalComponent, {
        data: CHANGE_PARENT_QUANTITY_MODAL_DATA,
      }).afterClosed()
        .subscribe((res: CommonModalsActionsEnum) => {
          if (res === CommonModalsActionsEnum.CONFIRM) {
            this.updatePosition(fieldName, fieldValue, true);
          }
        });
      return;
    }

    this.tradeOfferApiService
      .updateTradeOfferPosition(
        +this.position.id,
        {
          fieldName: fieldName,
          fieldValue: fieldValue
        },
        this.data.currentOffer.id
      )
      .subscribe((response) => {
        this.position = {
          ...response.data,
          marginData: {
            profit: response.data.marginNet,
            percent: response.data.marginRate,
            currency: '€'
          },
        };
        this.getCurrentOffer();
      }, () => {
        if (fieldName === 'unitType') {
          this.redrawControls();
        }
      });
  }

  public redrawControls(): void {
    this.showControls = false;
    setTimeout(() => this.showControls = true);
  }

  public updateDatePicker(event, fieldName): void {
    this.updatePosition(fieldName, event.value ? new Date(event.value).toLocaleDateString('fr-CA') : null);
  }

  private getCurrentOffer(): void {
    this.tradeOfferApiService.getTradeOfferById(this.data.currentOffer.id)
      // .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  private movePosition(posId: number, moveTo: number): void {
    this.tradeOfferApiService.changePositionOrder(posId, moveTo)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.position = {
          ...data,
          marginData: {
            profit: data.marginNet,
            percent: data.marginRate,
            currency: '€'
          },
        };
        this.getCurrentOffer();
      });
  }

  private trackPositionsChanges(): void {
    this.positionsUpdateRequest$.next(true);
    this.store.select(selectTradeOfferPositions)
      .pipe(
        finalize(() => this.positionsUpdateRequest$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe((positions: ResponseModel<TradeOfferPositionsByProductType>) => {
        if (positions) {
          this.positions = positions.data[this.position.productType];
          this.positionsByProductType = positions.data;
        }
        this.rowIndex = this.getRowIndex(this.position);
      });
  }

  public changePosition({position, positions}): void {
    this.position = {
      ...position,
      marginData: {
        profit: position.marginNet,
        percent: position.marginRate,
        currency: '€'
      },
    };
    this.positions = positions;
    this.getPositionsCards();
    this.getCurrentRowIndex();
    this.getProductUnits();
    this.redrawControls();
  }

  public getCurrentRowIndex(): void {
    this.positionsByProductType[this.position.productType].map((pos, index) => {
      if (pos.order === this.position.order) {
        this.rowIndex = index;
      }
    });
  }

  get allowFractionalValues(): boolean {
    if (!this.position.unitType || !this.unitsListSource.length) { return true; }
    const selectedUnit: ProductUnitModel = this.unitsListSource
      .find((itm: ProductUnitModel) => itm.name === this.position.unitType);
    const isFractionalQuantity: boolean = !!(this.position.quantity % 1);
    return (selectedUnit && !isFractionalQuantity)
      ? selectedUnit.allowFractionalValues
      : isFractionalQuantity;
  }

  get isGoods(): boolean {
    return this.position.productType === ProductTypes.GOODS && !this.position.general;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
