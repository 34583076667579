import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { DocumentInfoTypeEnum, DocumentViewerModel } from '../../document-viewer.model';
import { selectCompanyTimezoneOffset } from 'projects/workspace/src/app/administration/store/selectors';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { STATUS_CLASSES } from 'projects/workspace/src/app/sales-order/sales-order.constants';
import { DocumentTypesUppercaseEnum } from '../../../modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { AddressTypeEnum } from 'projects/workspace/src/app/sales-order/enums/address-type.enum';

@Component({
  selector: 'rnpl-document-viewer-head-address-location',
  templateUrl: './document-viewer-head-address-location.component.html',
  styleUrls: ['./document-viewer-head-address-location.component.scss']
})
export class DocumentViewerHeadAddressLocationComponent { // todo remove after PDF settings release

  public DocumentInfoTypeEnum = DocumentInfoTypeEnum;
  public addressTypeEnum = AddressTypeEnum;
  readonly statusClasses: { [key: string]: string } = STATUS_CLASSES;

  readonly companyProfileTimezoneOffset$: Observable<string> = this.store.select(selectCompanyTimezoneOffset);

  @Input() public isLoading: boolean;
  @Input() public documentPreviewData: DocumentViewerModel;
  @Input() public documentTypeEnum?: DocumentTypesUppercaseEnum;
  @Input() public companyLogo: string;
  @Input() public hideHeadInfo: boolean;
  @Input() public documentName: string;
  @Input() public documentId: string;
  @Input() public documentPeriod: Date;
  @Input() public statusLabel: string;
  @Input() public isReadOnly: boolean;
  @Input() public customerBlockIsValid: boolean = true;
  @Input() public lang: string;

  @Output()
  selectCustomerEmit: EventEmitter<any> = new EventEmitter();

  constructor(
    private readonly store: Store<AppState>,
  ) { }

  public hasInfoItems(infoList: any[]): boolean {
    if (!infoList) { return false; }

    return infoList.some(itm => !!itm.value);
  }

  public goToAnchor() {
    try {
      document.getElementById('properties').scrollIntoView();
    } catch (e) { }
  }

  public selectCustomerAction(): void {
    this.selectCustomerEmit.emit();
  }

}
