export * from './initial-setup/initial-setup.component';
export * from './legal-info/legal-info.component';
export * from './initial-setup-base/initial-setup-base.component';
export * from './initial-setup-page/initial-setup-page.component';
export * from './finishing/finishing.component';
export * from './error-page/error-page.component';
export * from './company-type/company-type.component';
export * from './company-details/company-details.component';
export * from './contact-info/contact-info.component';
export * from './accounting-method/accounting-method.component';
export * from './app-features/app-features.component';
