import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { OutgoingInvoiceModel } from 'projects/workspace/src/app/outgoing-invoice/models';
import { OutgoingInvoiceListTabsEnum } from 'projects/workspace/src/app/outgoing-invoice/enums';


@Component({
  selector: 'rnpl-create-down-payment-modal',
  templateUrl: './create-down-payment-modal.component.html',
  styleUrls: ['./create-down-payment-modal.component.scss'],
})
export class CreateDownPaymentModalComponent implements OnDestroy {

  public amountControl: FormControl = new FormControl();
  public percentControl: FormControl = new FormControl();
  public currency = '€';

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  readonly submitRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    public router: Router,
    public dialogRef: MatDialogRef<CreateDownPaymentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      createFn: Function,
      calculateFn: Function,
      documentAmountGross: number;
    }
  ) {

  }

  public calculate(value: number, partType: 'ABSOLUTE'|'PERCENT'): void {
    this.data.calculateFn(+(this.data.documentAmountGross / 1e2).toFixed(2), value, partType)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: {amount: number, share: number}) => {
        this.amountControl.patchValue(res.amount);
        this.percentControl.patchValue(res.share);
      });
  }

  public submit(): void {
    if (this.submitRequest$.getValue()) { return; }
    this.submitRequest$.next(true);

    this.data.createFn(this.amountControl.value, this.percentControl.value)
      .pipe(
        finalize(() => this.submitRequest$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe((response: OutgoingInvoiceModel) => {
        if (response.status === OutgoingInvoiceListTabsEnum.DRAFT) {
          this.router.navigate(
            [`/accounting/outgoing-invoice/${response.invoiceId}`],
            {state: {setEditState: true, documentId: response.invoiceId}}
          );
        } else {
          this.router.navigate([`/accounting/outgoing-invoice/${response.invoiceId}`]);
        }
        this.dialogRef.close();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}

