import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { tap, map, catchError, finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { AppState } from '../../store/state/app.state';
import { environment } from '../../../environments/environment';
import { FilterModelNew } from '../../outgoing-invoice/models/filter-model-new';
import { ResponseList, ResponseModel } from '../../shared/models/response';
import { PaginationModel, UIStatesEnum } from 'common/src/models';
import { ReportingPeriodsDocumentsEnum, ReportingPeriodsListTabsEnum, ReportingPeriodTypeEnum } from '../enums';
import {
  CategoryAccountModel, ExternalAccountConfigModel,
  OffsetAccountModel, PartnerAccountModel,
  ReportingPeriodDocumentModel,
  ReportingPeriodModel,
  ReportingPeriodsPreferencesModel,
  UnreportedDocumentsListTotalsModel
} from '../models';
import {
  DecrementLoadingRequestsCount,
  IncrementLoadingRequestsCount,
  LoadReportingPeriod, LoadReportingPeriodsList,
  LoadReportingPeriodsPreferences,
  UpdateReportingPeriodPreferencesCurrentState,
  UpdateReportingPeriodPreferencesUpdatedAt,
  UpdateReportingPeriodUpdatedAt,
  UpdateShouldRefreshEntity
} from '../store/actions/reporting-periods.actions';
import { FormInputChangedModel } from '../../shared/models/form-input-value.model';
import { EditModel } from '../../outgoing-invoice/models/edit.model';
import { getAnotherUserEditErrorModalData } from 'common/src/modules/modals/modals-common/common-modal.config';
import { CommonModalsActionsEnum, WarningModalComponent } from 'common/src/modules/modals/modals-common';
import { FileUploadParams } from 'common/src/models/file-upload-params.model';
import { PartnersTypeEnum } from '../../partners/corporate/enums/partner-types.enum';
import { ProductTypes } from 'common/src/modules/products/product-types';
import { DEFAULT_SORT_DIRECTION } from '../../shared/constants';
import { selectCompanyProfile } from '../../administration/store/selectors';
import { CompanyProfile } from '../../administration/models/company-profile.model';


@Injectable({
  providedIn: 'root'
})
export class ReportingPeriodsApiService {
  private wid: number;
  private readonly apiEndpoint: string = `${environment.javaApiVersion}/reporting-period`;

  private apiUrl(url: string = ''): string {
    return this.apiEndpoint + url;
  }

  private apiUrlV2(url: string = ''): string {
    return `${environment.javaApiVersion}/workspaces/${this.wid}/reporting-periods` + url;
  }

  constructor(
    private readonly toasterService: ToasterService,
    private readonly translateService: TranslateService,
    private readonly http: HttpClient,
    private readonly dialog: MatDialog,
    private readonly store: Store<AppState>,
  ) {
    this.store.select(selectCompanyProfile)
      .subscribe((profile: CompanyProfile) => this.wid = profile.workspaceId);
  }

  public reopenReportingPeriod(periodId: number): Observable<any> {
    return this.http.request('post', this.apiUrlV2(`/${periodId}/reopen`))
      .pipe(
        catchError(error => {
          this.handlePreferencesPopupErrors(error);
          return throwError(error);
        })
      );
  }

  public closeReportingPeriodsAnnually(year: number): Observable<any> {
    return this.http.request('post', this.apiUrlV2(`/year/${year}/close`))
      .pipe(
        catchError(error => {
          this.handlePreferencesPopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getAvailableToCloseReportingYears(): Observable<number[]> {
    return this.http.get<ResponseModel<number[]>>(this.apiUrlV2('/year/available'))
      .pipe(
        catchError(error => {
          this.handlePreferencesPopupErrors(error);
          return throwError(error);
        }),
        map((response: ResponseModel<number[]>) => response.data)
      );
  }

  public closeReportingPeriodsBatch(periods: number[]): Observable<any> {
    const params = {
      periods: periods.join(','),
    };
    return this.http.post(this.apiUrlV2('/batch/close'), {}, {params})
      .pipe(
        catchError(error => {
          this.handlePreferencesPopupErrors(error);
          return throwError(error);
        })
      );
  }

  public closeReportingPeriodValidation(periodId: number): Observable<string[]> {
    return this.http.get(this.apiUrlV2(`/${periodId}/validate-before`))
      .pipe(map((response: ResponseModel<string[]>) => response.data));
  }

  public geReportingPeriodsList(
    periodType: ReportingPeriodTypeEnum,
    status: ReportingPeriodsListTabsEnum,
    pagination: PaginationModel,
    sort: FilterModelNew,
    filters: any
  ): Observable<ResponseList<ReportingPeriodModel>> {
    const params = {
      page: (pagination.page).toString(),
      sortBy: sort.sortBy,
      direction: sort.direction,
      length: pagination.per_page,
      // periodType,
      tab: status.toUpperCase()
    };
  //
  //   for (const [key, value] of Object.entries(filters)) {
  //     params[`filter.${key}`] = Array.isArray(value) ? value.join(',') : value
  //   }
  //
    return this.http.get<ResponseList<ReportingPeriodModel>>(this.apiUrl('/all'), { params })
      .pipe(
        tap((data: ResponseList<ReportingPeriodModel>) => {
          this.store.dispatch(LoadReportingPeriodsList({
            reportingPeriods: {
              [data.pagination.page]: {
                pagination: data.pagination,
                data: data.data
              }
            },
            status,
            page: data.pagination.page
          }));
        }),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getUnreportedDocumentsList(
    periodType: ReportingPeriodTypeEnum,
    status: ReportingPeriodsListTabsEnum,
    pagination: PaginationModel,
    sort: FilterModelNew,
    filters: any
  ): Observable<ResponseList<ReportingPeriodModel>> {
    const params = {
      page: (pagination.page).toString(),
      sortBy: sort.sortBy,
      direction: sort.direction,
      length: pagination.per_page,
      // periodType,
      // tab: status.toUpperCase()
    };
    //
    //   for (const [key, value] of Object.entries(filters)) {
    //     params[`filter.${key}`] = Array.isArray(value) ? value.join(',') : value
    //   }
    //
    return this.http.get<ResponseList<ReportingPeriodModel>>(this.apiUrl('/unreported/list'), { params })
      .pipe(
        tap((data: ResponseList<ReportingPeriodModel>) => {
          this.store.dispatch(LoadReportingPeriodsList({
            reportingPeriods: {
              [data.pagination.page]: {
                pagination: data.pagination,
                data: data.data
              }
            },
            status,
            page: data.pagination.page
          }));
        }),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getUnreportedDocumentsListTotals(wid: number): Observable<UnreportedDocumentsListTotalsModel> {
    const params = {
      wid: (wid).toString(),
    };
    return this.http.get<ResponseModel<UnreportedDocumentsListTotalsModel>>(this.apiUrl('/unreported/totals'), { params })
      .pipe(
        map((response: ResponseModel<UnreportedDocumentsListTotalsModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getReportingPeriodsListByStatuses(reportStatuses: string[], sortBy = 'status'): Observable<ReportingPeriodModel[]> {
    const params = {
      reportStatuses: reportStatuses.join(','),
      sortBy,
      direction: DEFAULT_SORT_DIRECTION,
    };

    return this.http.get<ResponseList<ReportingPeriodModel>>(this.apiUrl('/all'), { params})
      .pipe(
        map((response: ResponseList<ReportingPeriodModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getReportingPeriodById(id: string | number): Observable<ReportingPeriodModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.get<ResponseModel<ReportingPeriodModel>>(`${this.apiEndpoint}/${id}`)
      .pipe(
        tap((response: ResponseModel<ReportingPeriodModel>) => {
          this.store.dispatch(LoadReportingPeriod({ reportingPeriod: response.data }));
          this.store.dispatch(UpdateReportingPeriodUpdatedAt({ updatedAt: new Date() }));
          this.store.dispatch(UpdateShouldRefreshEntity({ isShouldRefresh: false }));
        }),
        map((response: ResponseModel<ReportingPeriodModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  public getReportingPeriodDocuments(reportingPeriodId: number): Observable<ReportingPeriodDocumentModel[]> {
    return this.http.get<ResponseModel<ReportingPeriodDocumentModel[]>>(this.apiUrl(`/${reportingPeriodId}/documents`))
      .pipe(
        map((response: ResponseModel<ReportingPeriodDocumentModel[]>) => response.data)
      );
  }

  public getReportingPeriodPayments(reportingPeriodId: number): Observable<ReportingPeriodDocumentModel[]> {
    return this.http.get<ResponseModel<ReportingPeriodDocumentModel[]>>(this.apiUrl(`/${reportingPeriodId}/payments`))
      .pipe(
        map((response: ResponseModel<ReportingPeriodDocumentModel[]>) => response.data)
      );
  }

  public updateOffsetAccount(account: OffsetAccountModel, field: FormInputChangedModel, wid: number): Observable<ReportingPeriodsPreferencesModel> {
    const params = {
      wid: (wid).toString(),
    };

    return this.http.patch<ResponseModel<ReportingPeriodsPreferencesModel>>(
      this.apiUrl(`/preferences/offset-accounts/${account.id}`),
      field,
      { params }
    )
      .pipe(
        tap((response: ResponseModel<ReportingPeriodsPreferencesModel>) => {
          this.store.dispatch(LoadReportingPeriodsPreferences({ preferences: response.data }));
          this.store.dispatch(UpdateReportingPeriodPreferencesUpdatedAt({ updatedAt: new Date() }));
        }),
        map((data: ResponseModel<ReportingPeriodsPreferencesModel>) => data.data),
        catchError(error => {
          this.handlePreferencesPopupErrors(error);
          return throwError(error);
        })
      );
  }

  public updateReportingPeriodsPreferences(field: FormInputChangedModel, wid: number): Observable<ReportingPeriodsPreferencesModel> {
    const params = {
      wid: (wid).toString(),
    };
    // this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<ReportingPeriodsPreferencesModel>>(this.apiUrl('/preferences'), field, { params })
      .pipe(
        tap((response: ResponseModel<ReportingPeriodsPreferencesModel>) => {
          this.store.dispatch(LoadReportingPeriodsPreferences({ preferences: response.data }));
          this.store.dispatch(UpdateReportingPeriodPreferencesUpdatedAt({ updatedAt: new Date() }));
        }),
        map((data: ResponseModel<ReportingPeriodsPreferencesModel>) => data.data),
        // finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePreferencesPopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getReportingPeriodCategoriesAccounts(
    wid: number,
    cashflowType: 'REVENUE'|'EXPENDITURE',
    productType: ProductTypes,
  ): Observable<CategoryAccountModel[]> {
    const params = {
      wid: (wid).toString(),
      cashflowType,
      productType,
    };

    return this.http.get<ResponseModel<CategoryAccountModel[]>>(this.apiUrl('/category-account/all'), { params })
      .pipe( map((data: ResponseModel<CategoryAccountModel[]>) => data.data),);
  }

  public updateReportingPeriodsCategoryAccount(field: FormInputChangedModel, accountId: number, wid: number, ignore = false): Observable<CategoryAccountModel> {
    const params = {
      wid: (wid).toString(),
      ignore: (ignore).toString(),
    };

    return this.http.post<ResponseModel<CategoryAccountModel>>(this.apiUrl(`/category-account/${accountId}`), field, { params })
      .pipe(
        tap((response: ResponseModel<CategoryAccountModel>) => {
          this.store.dispatch(UpdateReportingPeriodPreferencesUpdatedAt({ updatedAt: new Date() }));
        }),
        map((data: ResponseModel<CategoryAccountModel>) => data.data),
        catchError(error => {
          this.handlePreferencesPopupErrors(error, wid, {field, accountId});
          return throwError(error);
        })
      );
  }

  public getReportingPeriodPartnersAccounts(
    wid: number,
    pagination: PaginationModel,
    sort: FilterModelNew,
    filters: any = {}
  ): Observable<ResponseList<PartnerAccountModel>> {
    const params = {
      wid: (wid).toString(),
      page: (pagination.page).toString(),
      length: pagination.per_page,
      sortBy: sort.sortBy,
      direction: sort.direction,
    };

    for (const [key, value] of Object.entries(filters)) {
      params[key] = Array.isArray(value) ? value.join(',') : value.toString();
    }

    return this.http.get<ResponseList<PartnerAccountModel>>(this.apiUrl('/partner-account/all'), { params });
  }

  public getReportingPeriodsPartnerAccountConfig(): Observable<ExternalAccountConfigModel> {
    const params = {
      wid: (this.wid).toString(),
    };

    return this.http.get<ResponseModel<ExternalAccountConfigModel>>(this.apiUrl(`/partner-account/configuration`), { params })
      .pipe(
        map((data: ResponseModel<ExternalAccountConfigModel>) => data.data),
        catchError(error => {
          this.handlePreferencesPopupErrors(error);
          return throwError(error);
        })
      );
  }

  public updateReportingPeriodsPartnerAccountConfig(config: ExternalAccountConfigModel): Observable<ExternalAccountConfigModel> {
    const params = {
      wid: (this.wid).toString(),
    };

    return this.http.post<ResponseModel<ExternalAccountConfigModel>>(this.apiUrl(`/partner-account/configuration`), { ...config }, { params })
      .pipe(
        map((data: ResponseModel<ExternalAccountConfigModel>) => data.data),
        catchError(error => {
          this.handlePreferencesPopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getReportingPeriodPartnersAccountsExport(mode: 'ALL'|'CUSTOMERS'|'VENDORS' = 'ALL'): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: this.apiUrl(`/partner-account/download?mode=${mode}`),
      type: 'zip',
    };
    return of(fileParams);
  }

  public updateReportingPeriodsPartnerAccount(field: FormInputChangedModel, accountId: number, wid: number, ignore = false): Observable<PartnerAccountModel> {
    const params = {
      wid: (wid).toString(),
      ignore: (ignore).toString(),
    };

    return this.http.post<ResponseModel<PartnerAccountModel>>(this.apiUrl(`/partner-account/${accountId}`), field, { params })
      .pipe(
        tap((response: ResponseModel<PartnerAccountModel>) => {
          this.store.dispatch(UpdateReportingPeriodPreferencesUpdatedAt({ updatedAt: new Date() }));
        }),
        map((data: ResponseModel<PartnerAccountModel>) => data.data),
        catchError(error => {
          this.handlePreferencesPopupErrors(error, wid, {field, accountId});
          return throwError(error);
        })
      );
  }

  public getReportingPeriodsPreferences(wid: number): Observable<ReportingPeriodsPreferencesModel> {
    const params = {
      wid: (wid).toString(),
    };
    // this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.get<ResponseModel<ReportingPeriodsPreferencesModel>>(this.apiUrl('/preferences'), { params })
      .pipe(
        tap((response: ResponseModel<ReportingPeriodsPreferencesModel>) => {
          this.store.dispatch(LoadReportingPeriodsPreferences({ preferences: response.data }));
          this.store.dispatch(UpdateReportingPeriodPreferencesUpdatedAt({ updatedAt: new Date() }));
      //     this.store.dispatch(UpdateShouldRefreshEntity({ isShouldRefresh: false }));
        }),
        map((response: ResponseModel<ReportingPeriodsPreferencesModel>) => response.data),
        // finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  public reportingPeriodsPreferencesReorderColumns(
    orderedFields: any[],
    bookSymbol: ReportingPeriodsDocumentsEnum,
    wid: number
  ): Observable<any> {
    const params = {
      wid: (wid).toString(),
    };
    // this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<any>>(this.apiUrl(`/preferences/${bookSymbol}/reordering`), orderedFields, { params })
      .pipe(
        tap((response: ResponseModel<any>) => {
          this.store.dispatch(UpdateReportingPeriodPreferencesUpdatedAt({ updatedAt: new Date() }));
          this.store.dispatch(LoadReportingPeriodsPreferences({ preferences: response.data }));
          //     this.store.dispatch(UpdateShouldRefreshEntity({ isShouldRefresh: false }));
        }),
        map((response: ResponseModel<any>) => response.data),
        catchError(error => {
          this.handlePreferencesPopupErrors(error);
          return throwError(error);
        })
      //   // finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  public getPeriodsPreferencesNumberFormats(): Observable<{ [key: string]: string }[]> {
    return this.http.get<ResponseModel<{ [key: string]: string }[]>>(this.apiUrl('/preferences/format/number-formats'))
      .pipe(map((response: ResponseModel<{ [key: string]: string }[]>) => response.data),);
  }

  public getPeriodsPreferencesTimeFormats(): Observable<{ [key: string]: string }[]> {
    return this.http.get<ResponseModel<{ [key: string]: string }[]>>(this.apiUrl('/preferences/format/time-formats'))
      .pipe(map((response: ResponseModel<{ [key: string]: string }[]>) => response.data),);
  }

  public getPeriodsPreferencesDocFormats(): Observable<{ [key: string]: string }[]> {
    return this.http.get<ResponseModel<{ [key: string]: string }[]>>(this.apiUrl('/preferences/format/doc-formats'))
      .pipe(map((response: ResponseModel<{ [key: string]: string }[]>) => response.data),);
  }

  public getPeriodsPreferencesDateFormats(separator?: string): Observable<{ [key: string]: string }[]> {
    const params = { separator };
    return this.http.get<ResponseModel<{ [key: string]: string }[]>>(this.apiUrl('/preferences/format/date-formats'), { params })
      .pipe(
        map((response: ResponseModel<{ [key: string]: string }[]>) => response.data),
        map((data: { [key: string]: string }[]) => {
          return data.map(itm => ({
            ...itm,
            value: itm['value']
              .replace('dd', this.translateService.instant('UNITS.DD'))
              .replace('mm', this.translateService.instant('UNITS.MM'))
              .replace('yyyy', this.translateService.instant('UNITS.YYYY'))
              .replace('yy', this.translateService.instant('UNITS.YY'))
          }))
        })
        );
  }

  public getPreferencesPreviewExportParams(): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: this.apiUrl('/preferences/preview'),
      type: 'pdf',
    };
    return of(fileParams);
  }

  public getDownloadReportingPeriodsExportParams(wid: number): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: this.apiUrl(`/download?wid=${wid}`),
      type: 'pdf',
    };
    return of(fileParams);
  }

  public updatePeriodsPreferencesColumns(
    field: FormInputChangedModel,
    fieldId: number,
    bookSymbol: ReportingPeriodsDocumentsEnum,
    wid: number
  ): Observable<ReportingPeriodsPreferencesModel> {
    const params = {
      wid: (wid).toString(),
    };

    // this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<ReportingPeriodsPreferencesModel>>(this.apiUrl(`/preferences/${bookSymbol}/${fieldId}`), field, { params })
      .pipe(
        tap((response: ResponseModel<ReportingPeriodsPreferencesModel>) => {
          this.store.dispatch(LoadReportingPeriodsPreferences({ preferences: response.data }));
          this.store.dispatch(UpdateReportingPeriodPreferencesUpdatedAt({ updatedAt: new Date() }));
        }),
        map((response: ResponseModel<any>) => response.data),
        //   // finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
        catchError(error => {
          this.handlePreferencesPopupErrors(error);
          return throwError(error);
        })
      );
  }

  public editReportingPeriodsPreferences(wid: number, force = false): Observable<EditModel> {
    const params = force ? new HttpParams().set('force', force.toString()) : {};

    return this.http.get<EditModel>(`accounting/v1/utils/edit-flag/RP_PREF/${wid}/check-and-set`, {params})
      .pipe(
        tap(() => {
          this.store.dispatch(UpdateReportingPeriodPreferencesCurrentState({ currentState: UIStatesEnum.EDIT }));
        }),
        catchError(error => {
          this.handlePreferencesPopupErrors(error, wid);
          return throwError(error);
        })
      );
  }

  public commitReportingPeriodsPreferences(wid: number): Observable<EditModel> {
    return this.http.get<EditModel>(`accounting/v1/utils/edit-flag/RP_PREF/${wid}/commit`).pipe(
      tap(response => {
        this.store.dispatch(UpdateReportingPeriodPreferencesCurrentState({ currentState: UIStatesEnum.VIEW }));
      }),
      catchError(error => {
        this.handlePreferencesPopupErrors(error, wid);
        return throwError(error);
      })
    );
  }

  public reportSinglePeriod(
    wid: number,
    advisor: {id: number, type: PartnersTypeEnum},
    reportId: number,
    sendTo: string[],
    hiddenTo?: string[],
  ): Observable<ReportingPeriodModel> {
    const params = {
      wid: (wid).toString(),
    };

    const body = {
      advisor,
      sendTo,
      hiddenTo,
      reportId,
      workspaceId: wid,
    };

    return this.http.post<ResponseModel<ReportingPeriodModel>>(this.apiUrl('/report/one'), body, { params })
      .pipe(
        map((data: ResponseModel<ReportingPeriodModel>) => data.data),
        catchError(error => {
          this.handlePreferencesPopupErrors(error);
          return throwError(error);
        })
      );
  }

  public reportMultiplePeriods(wid: number, ids: number[]): Observable<ReportingPeriodModel> {
    const params = {
      wid: (wid).toString(),
    };

    return this.http.post<ResponseModel<ReportingPeriodModel>>(this.apiUrl('/report/all'), { ids }, { params })
      .pipe(
        map((data: ResponseModel<ReportingPeriodModel>) => data.data),
        catchError(error => {
          this.handlePreferencesPopupErrors(error);
          return throwError(error);
        })
      );
  }

  getUnreportedDocumentsCounter(): Observable<number> {
    return this.http.get<ResponseModel<number>>(this.apiUrl('/unreported/count'))
      .pipe(map((data: ResponseModel<number>) => data.data));
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  private handlePopupErrors(error: HttpErrorResponse, periodId?: number): void {
    if (error.error.errors && error.error.errors.length) {
      error.error.errors.forEach(errorText => {
        switch (errorText) {
          // case 'anotherUserEditError':
          //   // this.showMsg('error', 'You cannot edit records created by other users.');
          //   this.showMsg('error', `The record is being edited by ${error.error.data.userName}`);
          //   // {
          //   //   const dialog = this.dialog.open(WarningModalComponent, {
          //   //     data: getAnotherUserEditErrorModalData({
          //   //       document: error.error.data.entityName,
          //   //       userName: error.error.data.userName,
          //   //     })
          //   //   });
          //
          //   //   dialog.afterClosed().subscribe(res => {
          //   //     if (res === CommonModalsActionsEnum.CONFIRM) {
          //   //       // this.startCreditNoteEditing(+crnId, true).subscribe();
          //   //       // this.getCreditNoteById(+crnId).subscribe();
          //   //     }
          //   //   });
          //   // }
          //   break;
          default:
            this.showMsg('error', errorText);
            break
        }
      });
    }
  }

  private handlePreferencesPopupErrors(error: HttpErrorResponse, wid?: number, args?): void {
    if (error.error.errors && error.error.errors.length) {
      error.error.errors.forEach(errorText => {
        switch (errorText) {
          case 'anotherUserEditError':
            // this.showMsg('error', 'You cannot edit records created by other users.');
            // this.showMsg('error', `The preferences is being edited by ${error.error.data.userName}`);
            {
              const dialog = this.dialog.open(WarningModalComponent, {
                data: getAnotherUserEditErrorModalData(
                  {
                    document: error.error.data.entityName,
                    userName: error.error.data.userName,
                  },
                  this.translateService
                )
              });

              dialog.afterClosed().subscribe(res => {
                if (res === CommonModalsActionsEnum.CONFIRM) {
                  this.editReportingPeriodsPreferences(+wid, true).subscribe();
                }
              });
            }
            break;
          case 'isAlreadyUsed':
          {
            const dialog = this.dialog.open(WarningModalComponent, {
              data: {
                title: 'REPORTING_PERIODS.ALREADY_USED_TITLE',
                message: 'REPORTING_PERIODS.ALREADY_USED_MSG',
                confirmBtnText: 'REPORTING_PERIODS.ALREADY_USED_BUTTON',
                confirmBtnIcon: 'checkmark-circle'
              }
            });

            dialog.afterClosed().subscribe(res => {
              if (res === CommonModalsActionsEnum.CONFIRM) {
                this.updateReportingPeriodsPartnerAccount(args.field, args.accountId, wid, true).subscribe();
              }
            });
          }
            break;
          case 'notEditModeError':
            const documentName = this.translateService.instant('REPORTING_PERIODS.REPORTING_PERIODS_PREFERENCES');
            this.showMsg('warning', this.translateService.instant('COMMON.DOC_UPDATED_BY_USER', { document: documentName }));
            this.store.dispatch(UpdateReportingPeriodPreferencesCurrentState({ currentState: UIStatesEnum.VIEW }));
          //   // this.getCreditNoteById(+crnId).subscribe();
            break;
          default:
            this.showMsg('error', errorText);
            break
        }
      });
    }
  }

}
