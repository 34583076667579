import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { get } from 'lodash';

import { CreditNoteModel } from 'projects/workspace/src/app/credit-note/models';
import { CreditNotePositionModel, CRNPositionsModel } from 'projects/workspace/src/app/credit-note/models/credit-note-position.model';
import { PositionsModel } from 'projects/workspace/src/app/outgoing-invoice/models';
import { ProductTypes } from '../../../products/product-types';
import { UnitTypeTranslatePipe } from '../../../rnpl-common';
import { CommonModalsActionsEnum, DangerModalComponent, WarningModalComponent } from '../../modals-common';
import { CreditNoteApiService } from 'projects/workspace/src/app/credit-note/services/credit-note-api.service';
import {
  generatePositionsCardsInfo,
  generatePositionsCardsInfoArbitrary
} from 'projects/workspace/src/app/credit-note/pages/credit-note-positions/credit-note-positions.config';
import { UIStatesEnum } from '../../../../models';
import { CreditNotePurposeEnum } from 'projects/workspace/src/app/credit-note/enums';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import {
  ControlTypeEnum,
  DisplayConditionsModel,
  PositionInfoListModel,
  PositionItemTypeEnum
} from '../../../position-card/position-card-models.model';
import { CHANGE_CORRECTION_CALCULATION_MODAL_DATA, REMOVE_POSITION_MODAL_DATA } from '../../modals.contsans';
import { selectCreditNoteCurrentState, selectCreditNotePositions } from 'projects/workspace/src/app/credit-note/store/selectors';
import { PaymentDirectionEnum } from 'projects/workspace/src/app/payment/models/payment.model';
import { ProductUnitModel } from 'projects/workspace/src/app/shared/models';
import { ProductUnitApiService } from 'projects/workspace/src/app/shared/services';

@Component({
  selector: 'rnpl-credit-note-edit-position-modal',
  templateUrl: './credit-note-edit-position-modal.component.html',
  styleUrls: ['./credit-note-edit-position-modal.component.scss']
})
export class CreditNoteEditPositionModalComponent implements OnInit, OnDestroy {

  public position: CreditNotePositionModel;
  public positions: CreditNotePositionModel[];
  public rowIndex: number;
  public controlTypeEnum: typeof ControlTypeEnum = ControlTypeEnum;
  public positionItemTypeEnum: typeof PositionItemTypeEnum = PositionItemTypeEnum;
  public productTypes: typeof ProductTypes = ProductTypes;
  public crnDirections: typeof PaymentDirectionEnum = PaymentDirectionEnum;

  public positionsByProductType: {[key in ProductTypes]?: any[]};

  public unitsList: string[] = [];
  public unitsListSource: ProductUnitModel[] = [];
  public isReadOnly: boolean;
  public showControls: boolean = true;

  public positionsCardInfoByTypes = {
    [ProductTypes.GOODS]: [] as PositionInfoListModel[],
    [ProductTypes.SERVICES]: [] as PositionInfoListModel[],
    [ProductTypes.DIGITAL]: [] as PositionInfoListModel[],
    [ProductTypes.ARBITRARY]: [] as PositionInfoListModel[],
    [ProductTypes.CORRECTION]: [] as PositionInfoListModel[],
    [ProductTypes.OVERDUE_FEE]: [] as PositionInfoListModel[],
  };

  public readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    public dialogRef: MatDialogRef<CreditNoteEditPositionModalComponent>,
    private readonly dialog: MatDialog,
    private translateService: TranslateService,
    private unitTypeTranslatePipe: UnitTypeTranslatePipe,
    private creditNoteApiService: CreditNoteApiService,
    private productUnitApiService: ProductUnitApiService,
    private readonly store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: {
      currentCreditNote: CreditNoteModel;
      position: CreditNotePositionModel,
      positions: CreditNotePositionModel[],
      positionsByProductType: {[key in ProductTypes]?: any[]}
    }
  ) { }

  ngOnInit() {
    this.position = this.preparePosition(this.data.position);
    this.positions = this.data.positions;
    this.positionsByProductType = this.data.positionsByProductType;
    this.rowIndex = this.getRowIndex(this.position);

    this.getProductUnits();
    this.trackCreditNoteStateChanges();
    this.trackPositionsChanges();
    this.getPositionsCards();
  }

  public getProductUnits(): void {
    this.productUnitApiService.getUnits$(this.position.productType)
      .pipe(takeUntil(this.destroy$))
      .subscribe((unitsList: ProductUnitModel[]) => {
        this.unitsListSource = unitsList;
        if (!this.position.general) {
          this.unitsList = [this.position.unitType];
          return;
        } else {
          this.unitsList = unitsList.map(u => u.name);
        }
        this.redrawControls();
      });
  }

  private trackPositionsChanges(): void {
    this.store.select(selectCreditNotePositions)
      .pipe(takeUntil(this.destroy$))
      .subscribe((positions: CRNPositionsModel) => {
        if (positions) {
          this.positions = positions[this.position.productType];
          this.positionsByProductType = positions;
          this.rowIndex = this.getRowIndex(this.position);
        }
      });
  }

  private trackCreditNoteStateChanges(): void {
    this.store.select(selectCreditNoteCurrentState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: UIStatesEnum) => {
        this.isReadOnly = state === UIStatesEnum.VIEW;
      });
  }

  private preparePosition(position: CreditNotePositionModel) {
    const negativeCorrection = position.productType === ProductTypes.CORRECTION &&
      position.productTypeForGrouping === ProductTypes.CORRECTION &&
      position.correctionType === 'NEGATIVE';

    return {
      ...position,
      currencyNegativeRow: negativeCorrection,
      productName: `${position.description} ${!!position.productDescription ? '\n' + position.productDescription : ''}`,
      productNameServices: `${position.description} ${!!position.providedServices ? '\n' + position.providedServices : ''}`,
      quantityWithUnitType: {
        value: position.quantity * -1,
        unitType: this.unitTypeTranslatePipe.transform(position.unitType)
      },
      totalPrice: (+position.netTotalPrice * -1) * 100,
    };
  }

  public getRowIndex(position): number {
    let rowIndex: number;
    if (!this.positions) { return; }
    this.positions.map((pos, index) => {
      if (pos.positionId === position.positionId) {
        rowIndex = index;
      }
    });
    return rowIndex;
  }

  public getCurrentCreditNote(): void {
    this.creditNoteApiService.getCreditNoteById(this.data.currentCreditNote.id)
      // .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  public updatePosition(cell: string, value: string, force: boolean = false): void {
    // correction position soft validation
    if (cell === 'specifyAmountsSeparately' && !value && !force) {
      if (
        (this.position.netTotalPrice && (this.position.netTotalPrice !== '0.00')) ||
        (this.position.grossTotalPrice && (this.position.grossTotalPrice !== '0.00')) ||
        (this.position.vatTotal && (this.position.vatTotal !== 0))
      ) {
        const dialog = this.dialog.open(WarningModalComponent, {
          data: CHANGE_CORRECTION_CALCULATION_MODAL_DATA
        });

        dialog.afterClosed().subscribe(res => {
          if (res === CommonModalsActionsEnum.CONFIRM) {
            this.updatePosition(cell, value, true);
          } else {
            this.getCurrentCreditNote();
          }
        });
        return;
      }
    }

    this.patchPosition(cell, value);
  }

  public patchPosition(fieldName, fieldValue): void {
    this.creditNoteApiService.updateCreditNotePosition(
      this.data.currentCreditNote.id,
      {
        fieldName,
        fieldValue,
        positionId: this.position.positionId,
      }
    )
      // .pipe(takeUntil(this.destroy$))
      .subscribe((response: CreditNotePositionModel) => {
        this.position = this.preparePosition(response);
        this.getCurrentCreditNote();
      }, () => {
        if (fieldName === 'unitType') {
          this.redrawControls();
        }
      });
  }

  public redrawControls(): void {
    this.showControls = false;
    setTimeout(() => this.showControls = true);
  }

  private getPositionsCards(): void {
    if (this.data.currentCreditNote) {

      if (this.crnPurpose !== CreditNotePurposeEnum.COMMON_CORRECTION && this.position.productTypeForGrouping === ProductTypes.ARBITRARY) {
        this.positionsCardInfoByTypes[this.position.productTypeForGrouping] = generatePositionsCardsInfoArbitrary(UIStatesEnum.CREATE);
      } else {
        this.positionsCardInfoByTypes[this.position.productTypeForGrouping] = generatePositionsCardsInfo(
          this.position.productTypeForGrouping,
          UIStatesEnum.CREATE,
          this.crnPurpose,
          this.data.currentCreditNote.properties.direction,
          this.vatDisabled,
        );
      }
    }
  }

  private movePosition(posId: number, moveTo: number): void {
    this.creditNoteApiService.changePositionOrder(posId, moveTo, +this.data.currentCreditNote.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        data[this.position.productType].map(pos => {
          if (pos.productId === this.position.productId) {
            this.position = pos;
          }
        });

        this.getPositionsList();
        this.getCurrentCreditNote();
      });
  }

  public displayConditionsHandler(position, conditionsList: DisplayConditionsModel[]): boolean {
    if (!conditionsList && !conditionsList.length) { return false; }

    return conditionsList.every(condition => {
      return condition.values.some((val) => {
        if (!!condition.productType) {
          return position[condition.prop] === val && condition.productType === position.productTypeForGrouping;
        } else {
          return position[condition.prop] === val;
        }
      });
    });
  }

  public deletePositionDialog(): void {
    const dialog = this.dialog.open(DangerModalComponent, {
      data: REMOVE_POSITION_MODAL_DATA,
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === CommonModalsActionsEnum.CONFIRM) {
        this.deletePositions([this.position.positionId]);
      }
    });
  }

  private deletePositions(ids: number[]): void {
    this.creditNoteApiService.deleteCreditNotePositions(this.data.currentCreditNote.id, ids)
      .pipe(takeUntil(this.destroy$))
      .subscribe(()  => {
        this.getCurrentCreditNote();
        this.dialogRef.close();
      });
  }

  public actionListHandler({row, moveTo}) {
    this.movePosition(row.positionId, moveTo);
  }

  private getPositionsList(): void {
    this.creditNoteApiService.getCreditNotePositions(+this.data.currentCreditNote.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: PositionsModel) => {
        if (response) {
          this.positions = response[this.position.productType];
        }
      });
  }

  public trackByProp(index: number, item: PositionInfoListModel) {
    return item.prop;
  }

  public updateDatePicker(event, fieldName): void {
    this.updatePosition(event.value ? new Date(event.value).toLocaleDateString('fr-CA') : null, fieldName);
  }

  public changePosition({position, positions}): void {
    this.position = this.preparePosition(position);
    this.positions = positions;
    this.getCurrentRowIndex();
    this.getPositionsCards();
    this.getProductUnits();
    this.redrawControls();
  }

  public getCurrentRowIndex(): void {
    this.positionsByProductType[this.position.productTypeForGrouping].map((pos, index) => {
      if (pos.order === this.position.order && pos.positionId === this.position.positionId) {
        this.rowIndex = index;
      }
    });
  }

  get crnPurpose(): CreditNotePurposeEnum {
    return get(this.data, 'currentCreditNote.properties.purpose');
  }

  get vatDisabled(): boolean {
    return get(this.data, 'currentCreditNote.properties.vatDisabled')
      || get(this.data, 'currentCreditNote.properties.smallBusiness');
  }

  get isCorrectionType(): boolean {
    return this.data.position.positionType.toLowerCase() === ProductTypes.CORRECTION;
  }

  get crnDirection(): PaymentDirectionEnum {
    return get(this.data, 'currentCreditNote.properties.direction');
  }

  get isOinOrIinCorrection(): boolean {
    return this.crnPurpose === CreditNotePurposeEnum.OIN_CORRECTION ||
      this.crnPurpose === CreditNotePurposeEnum.IIN_CORRECTION;
  }

  get allowFractionalValues(): boolean {
    if (!this.position.unitType || !this.unitsListSource.length) { return true; }
    const selectedUnit: ProductUnitModel = this.unitsListSource
      .find((itm: ProductUnitModel) => itm.name === this.position.unitType);
    const isFractionalQuantity: boolean = !!(this.position.quantity % 1);
    return (selectedUnit && !isFractionalQuantity)
      ? selectedUnit.allowFractionalValues
      : isFractionalQuantity;
  }

  get isGoods(): boolean {
    return this.position.productType === ProductTypes.GOODS && !this.position.general;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
