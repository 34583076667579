import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { get } from 'lodash';

import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { CustomerTypeEnum, DeliveryTypesEnum, UIStatesEnum } from '../../../../models';
import { convertAddressToStringHelper } from '../../../rnpl-common/helpers';
import { CountryModel } from '../../../rnpl-common';
import { TradeOfferModel } from 'projects/workspace/src/app/trade-offer/models';
import { selectTradeOffer, selectTradeOfferState } from 'projects/workspace/src/app/trade-offer/store/selectors';
import { selectCountries } from 'projects/workspace/src/app/store/selectors/shared.selectors';
import { TradeOfferApiService } from 'projects/workspace/src/app/trade-offer/services/trade-offer-api.service';
import { FormInputChangedModel } from 'projects/workspace/src/app/shared/models';
import { OfferFormService } from 'projects/workspace/src/app/trade-offer/services/offer-form.service';
import { AddressTypeEnum } from 'projects/workspace/src/app/sales-order/enums/address-type.enum';
import { AddressFormFactory } from 'projects/workspace/src/app/shared/forms/address-form.factory';
import { ModalNameEnum } from '../../../../models/modal-name.enum';
import { TradeOfferListTabsEnum } from 'projects/workspace/src/app/trade-offer/enums';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';

@Component({
  selector: 'rnpl-offer-delivery-info-modal',
  templateUrl: './offer-delivery-info-modal.component.html',
})
export class OfferDeliveryInfoModalComponent implements OnInit {
  public companyProfile: CompanyProfile;
  public tradeOffer: TradeOfferModel;
  public countries: CountryModel[];
  public isReadonly: boolean = true;
  public readonly deliveryTypes = DeliveryTypesEnum;
  public addressTypeEnum: typeof AddressTypeEnum = AddressTypeEnum;
  private convertAddressToString = convertAddressToStringHelper;

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public modalNameEnum: typeof ModalNameEnum = ModalNameEnum;
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public addressTypeList = [];

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<OfferDeliveryInfoModalComponent>,
    public tradeOfferApiService: TradeOfferApiService,
    public offerFormsService: OfferFormService,
    @Inject(MAT_DIALOG_DATA) public data: {
      form: FormGroup;
      pickUpAddressForm: FormGroup;
      deliveryToAddressForm: FormGroup;
    }
  ) { }

  ngOnInit() {
    this.isLoading$.next(true);
    this.selectCountries();
    this.selectTradeOffer();
    this.selectTradeOfferState();

    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((companyProfile: CompanyProfile) => this.companyProfile = companyProfile);
  }

  private selectCountries(): void {
    this.store.select(selectCountries)
      .pipe(takeUntil(this.destroy$))
      .subscribe((countriesFromStore: CountryModel[]) => {
        this.countries = countriesFromStore;
        this.cdr.detectChanges();
      });
  }

  private selectTradeOffer(): void {
    this.store.select(selectTradeOffer)
      .pipe(takeUntil(this.destroy$))
      .subscribe((tradeOffer: TradeOfferModel) => {
        this.tradeOffer = tradeOffer;
        this.offerFormsService.initDeliveryInfoForm(tradeOffer.deliveryInformation);
        this.data.form = this.offerFormsService.deliveryInfoForm;
        this.updateAddressTypeList();
        this.isLoading$.next(false);
        this.updateDeliveryInfoBlockValid();
        this.setFormsState();
      });
  }

  private selectTradeOfferState(): void {
    this.store.select(selectTradeOfferState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: UIStatesEnum) => {
        this.isReadonly = state === UIStatesEnum.VIEW;
        this.setFormsState();
      });
  }

  private setFormsState(): void {
    const opts = {onlySelf: true, emitEvent: false};
    if (this.isReadonly) {
      this.data.form.disable(opts);
      this.data.deliveryToAddressForm.disable(opts);
      this.data.pickUpAddressForm.disable(opts);
    } else {
      this.data.form.enable(opts);
      this.data.deliveryToAddressForm.enable(opts);
      this.data.pickUpAddressForm.enable(opts);
      this.cdr.detectChanges();
    }

    if (this.tradeOffer.properties.vatDisabled || this.tradeOffer.properties.smallBusiness) {
      this.deliveryCostsVat.disable(opts);
      // this.deliveryInsuranceVat.disable(opts);
    }
    if (this.tradeOffer.status === TradeOfferListTabsEnum.TEMPLATE) {
      this.estimatedDeliveryDate.disable(opts);
    }
    if (!get(this.tradeOffer, 'properties.company.id')) {
      this.data.deliveryToAddressForm.disable(opts);
      this.data.pickUpAddressForm.disable(opts);
      this.addressTemplateControl.disable(opts);
    }
    if (this.isGeneralPartner) {
      this.nameControl.disable(opts);
    }
  }

  private updateDeliveryInfoBlockValid(): void {
    const deliveryCostControls: FormControl[] = [this.deliveryCostsVat, this.deliveryCosts, this.deliveryCostsGross, this.deliveryCostsPayer];
    // const insuranceCostControls: FormControl[] = [this.deliveryInsuranceVat, this.deliveryInsuranceCosts, this.deliveryInsuranceCostsGross, this.deliveryInsurancePayer];

    if (this.data.form.get('isDeliveryCosts').value) {
      deliveryCostControls.forEach(control => control.setValidators(Validators.required));
    } else {
      deliveryCostControls.forEach(control => control.clearValidators());
    }

    // if (this.data.form.get('isInsuredDelivery').value) {
    //   insuranceCostControls.forEach(control => control.setValidators(Validators.required));
    // } else {
    //   insuranceCostControls.forEach(control => control.clearValidators());
    // }

    // [...deliveryCostControls, ...insuranceCostControls].forEach(control => control.updateValueAndValidity());
    [...deliveryCostControls].forEach(control => control.updateValueAndValidity());
  }

  public updateTradeOfferDeliveryInfo(fieldValue, fieldName: string): void {
    fieldName = 'deliveryInformation.' + fieldName;
    this.tradeOfferApiService.updateTradeOffer(this.tradeOffer.id, {fieldValue, fieldName})
      .subscribe((tradeOffer: TradeOfferModel ) => {
        this.offerFormsService.initDeliveryInfoForm(tradeOffer.deliveryInformation);
        this.data.form = this.offerFormsService.deliveryInfoForm;

        if (fieldName === 'deliveryInformation.addressTemplate') {
          this.data.deliveryToAddressForm = AddressFormFactory.getForm(get(tradeOffer, 'deliveryInformation.deliveryToAddress'));
          this.data.pickUpAddressForm = AddressFormFactory.getForm(get(tradeOffer, 'deliveryInformation.pickUpAddress'));
        }
      }); // updated via store
  }

  public updateAddressTypeList(): void {
    this.addressTypeList = [
      {
        value: AddressTypeEnum.BILLING,
        label: 'FORM.BILLING_ADDRESS',
        enabled: !this.isPickUpTypeSelected && this.isCustomerSelected && !this.isGeneralPartner
      },
      {
        value: AddressTypeEnum.DELIVERY,
        label: 'FORM.DELIVERY_ADDRESS',
        enabled: !this.isPickUpTypeSelected && this.isCustomerSelected && !this.isGeneralPartner
      },
      {
        value: AddressTypeEnum.WAREHOUSE,
        label: 'FORM.WAREHOUSE_ADDRESS',
        enabled: this.isPickUpTypeSelected
      },
      {
        value: AddressTypeEnum.USER_DEFINED,
        label: 'COMMON.NEW_ADDRESS',
        enabled: true
      },
      {
        value: AddressTypeEnum.SIMPLIFIED,
        label: 'COMMON.USER_DEFINED_ADDRESS',
        enabled: true
      },
    ].filter(i => i.enabled);
  }

  public get isPickUpTypeSelected(): boolean {
    return get(this, 'tradeOffer.deliveryInformation.deliveryType') === this.deliveryTypes.PICK_UP;
  }

  public get deliveryAddressAsText(): string {
    if (!this.data.form) { return ''; }

    const deliveryAddressVal = this.data.deliveryToAddressForm.value;
    return this.convertAddressToString(deliveryAddressVal, this.countries);
  }

  public get pickUpAddressAsText(): string {
    if (!this.data.form) { return ''; }

    const pickUpAddressVal = this.data.pickUpAddressForm.value;
    return this.convertAddressToString(pickUpAddressVal, this.countries);
  }

  public get isCustomerSelected(): boolean {
    return !!get(this, 'tradeOffer.properties.company.runpleId');
  }

  // public fillCustomerDeliveryAddress(): void {
  //   this.tradeOfferApiService.updateDeliveryAddressByCustomer(this.tradeOffer.id)
  //     .subscribe(); // updated via store
  // }

  public updateAddressField(field: FormInputChangedModel, addressGroupName: string): void {
    const fieldName = addressGroupName + '.' + field.fieldName;
    this.updateTradeOfferDeliveryInfo(field.fieldValue, fieldName);
  }

  public fillOwnWarehouseDeliveryAddress(): void {
    this.tradeOfferApiService.updatePickUpAddressByWarehouse(this.tradeOffer.id)
      .subscribe(); // updated via store
  }

  public editAddress(): void {
    this.addressTemplateControl.setValue(AddressTypeEnum.USER_DEFINED);
    this.updateTradeOfferDeliveryInfo(AddressTypeEnum.USER_DEFINED, 'addressTemplate');
  }

  get wholesalesEnabled(): boolean {
    if (!this.companyProfile) { return false; }
    return this.companyProfile.subscriptionManagement.channelOptions.wholesalesEnabled;
  }

  public get isGeneralPartner(): boolean {
    return get(this, 'tradeOffer.properties.partnerType') === CustomerTypeEnum.GENERAL;
  }

  get deliveryTypeControl(): FormControl { return this.data.form.get('deliveryType') as FormControl; }
  get estimatedDeliveryDate(): FormControl { return this.data.form.get('estimatedDeliveryDate') as FormControl; }
  get deliveryCostsVat(): FormControl { return this.data.form.get('deliveryCostsVat') as FormControl; }
  get deliveryCosts(): FormControl { return this.data.form.get('deliveryCosts') as FormControl; }
  get deliveryCostsPayer(): FormControl { return this.data.form.get('deliveryCostsPayer') as FormControl; }
  get deliveryCostsGross(): FormControl { return this.data.form.get('deliveryCostsGross') as FormControl; }
  // get deliveryInsuranceVat(): FormControl { return this.data.form.get('deliveryInsuranceVat') as FormControl; }
  // get deliveryInsuranceCosts(): FormControl { return this.data.form.get('deliveryInsuranceCosts') as FormControl; }
  // get deliveryInsuranceCostsGross(): FormControl { return this.data.form.get('deliveryInsuranceCostsGross') as FormControl; }
  // get deliveryInsurancePayer(): FormControl { return this.data.form.get('deliveryInsurancePayer') as FormControl; }
  get isDeliveryCosts(): FormControl { return this.data.form.get('isDeliveryCosts') as FormControl; }
  get addressTemplateControl(): FormControl { return this.data.form.get('addressTemplate') as FormControl; }
  get nameControl(): FormControl { return this.data.form.get('name') as FormControl; }

}
