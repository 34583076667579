import { Component, Input } from '@angular/core';
import { AuthService } from 'common/src/auth/auth.service';

@Component({
  selector: 'rnpl-documents-toggle-summary',
  templateUrl: './documents-toggle-summary.component.html',
})
export class DocumentsToggleSummaryComponent {

  @Input() public showSummary: boolean;

  public openSummary: boolean = false;

  constructor(
    private authService: AuthService,
  ) {
  }

  public openToggleSummary(): void {
    this.openSummary = !this.openSummary;
  }

  get expandedSideMenu() {
    return this.authService.getUser().expandedSideMenu;
  }
}
