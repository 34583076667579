import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { get } from 'lodash';
import { debounceTime } from 'rxjs/internal/operators';
import { TranslateService } from '@ngx-translate/core';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ResponseList } from 'projects/workspace/src/app/shared/models/response';
import { TimeTrackingRecordModel, TimeTrackingSettingsModel } from 'projects/workspace/src/app/time-tracking/models';
import { TimeTrackingApiService } from 'projects/workspace/src/app/time-tracking/services/time-tracking-api.service';
import { TimeLoggingByEnum } from 'projects/workspace/src/app/time-tracking/enums';
import { PartnersTypeEnum } from 'projects/workspace/src/app/partners/corporate/enums/partner-types.enum';
import { UserListTabs } from 'projects/workspace/src/app/hrm/pages/user-list/enums/user-list-tabs.enum';
import { HrmService } from 'projects/workspace/src/app/hrm/hrm.service';
import { CustomerTypeEnum, UserModel } from '../../../../models';
import { AuthService } from '../../../../auth/auth.service';
import { CommonModalsActionsEnum, WarningModalComponent } from '../../modals-common';
import { PartnerModel } from 'projects/workspace/src/app/partners/corporate/models/partner.model';
import { ProductModel } from '../../../products/models';
import { ProductsService } from '../../../products/products.service';
import { PartnersApiService } from 'projects/workspace/src/app/partners/corporate/services/partner-api.service';
import {
  HALF_YEAR_LIST,
  MONTHS_LIST,
  PERIODS_LIST,
  QUARTERS_LIST,
  ReportsPeriodEnum,
  ROUND_UP_LIST, RoundingEnum
} from './time-tracking-log-time-modal.config';
import { CustomSearchFn, getProductSearchValueCamel } from 'common/src/modules/rnpl-common/helpers';
import { PRODUCTS_TYPE } from '../../../rnpl-common/constants/products-type';
import { daysBetweenDates } from 'projects/workspace/src/app/shared/helpers';
import { ProductTypes } from '../../../products/product-types';

@Component({
  selector: 'rnpl-time-tracking-log-time-modal',
  templateUrl: './time-tracking-log-time-modal.component.html',
})
export class TimeTrackingLogTimeModalComponent extends BaseModalComponent implements OnInit {
  public hourUnitType = 'h';
  public minuteUnitType = 'min';
  public yearsList: string[] = [];
  public monthsList: {[key: string]: string}[] = MONTHS_LIST;
  public periodsList: {[key: string]: string}[] = PERIODS_LIST;
  public roundsList: {[key: string]: string}[] = ROUND_UP_LIST;
  public quartersList: {[key: string]: string}[] = QUARTERS_LIST;
  public halfYearList: {[key: string]: string}[] = HALF_YEAR_LIST;

  public form: FormGroup;
  public availableServices: ProductModel[] = [];
  public availableEmployees: UserModel[] = [];

  public lengthValues: string[] = [this.minuteUnitType, this.hourUnitType]; // todo: rename
  public periods: typeof ReportsPeriodEnum = ReportsPeriodEnum;
  public timeFrom: Date = null;
  public timeTo: Date = null;
  public calculatedDurationTime: number = null;
  public roundedTime: number = null;
  public salesPriceListName: string;
  public selectedServiceWithPauUnit: boolean = false;

  public timeLoggingBy: typeof TimeLoggingByEnum = TimeLoggingByEnum;

  public customSearchFn = CustomSearchFn;

  public customerTypeEnum = CustomerTypeEnum;

  public productType = PRODUCTS_TYPE;
  public productTypes: typeof ProductTypes = ProductTypes;

  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly applyRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    public toasterService: ToasterService,
    public translateService: TranslateService,
    public dialog: MatDialog,
    public fb: FormBuilder,
    private productsService: ProductsService,
    private partnersApiService: PartnersApiService,
    private timeTrackingApiService: TimeTrackingApiService,
    private hrmService: HrmService,
    private authService: AuthService,
    private readonly datePipe: DatePipe,
    public dialogRef: MatDialogRef<TimeTrackingLogTimeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      record: TimeTrackingRecordModel,
      partnerInfo: any // todo type
    }
  ) {
    super(toasterService);
  }

  private static getYearsList(): string[] {
    const years: string[] = [];
    const startYear = 2020;
    const currentYear = new Date().getFullYear();

    for (let i = startYear; i <= currentYear; i++) {
      years.push(i.toString());
    }

    return years;
  }

  ngOnInit() {
    this.yearsList = TimeTrackingLogTimeModalComponent.getYearsList();
    this.initForm();
    this.getContinuousServices();
    this.getCombinedPartners();
    this.getActiveEmployees();

    this.timeTrackingApiService.getTimeTrackingSettings()
      .pipe(takeUntil(this._destroy))
      .subscribe((settings: TimeTrackingSettingsModel) => {
        if (!this.currentRecord) {
          this.timeConfigTypeControl.patchValue(settings.logTimeBy);
          this.periodControl.patchValue(settings.periodOption);
          this.roundingOptionControl.patchValue(settings.roundingOption);
          this.roundingEnabledControl.patchValue(settings.roundingEnabled);
          this.timeManualInputControl.patchValue(settings.manualDuration);
          this.billNeededControl.patchValue(settings.approveLoggedTime);
        }
      });

    if (this.currentRecord) {
      this.form.patchValue(this.currentRecord, {emitEvent: false});
      if (get(this.currentRecord, 'timeConfig.type') === TimeLoggingByEnum.DATE) {
        if (get(this.currentRecord, 'timeConfig.timeFrom')) {
          this.timeFrom = new Date(
            get(this.currentRecord, 'timeConfig.date', '') + ' ' + get(this.currentRecord, 'timeConfig.timeFrom', '')
          );
        }

        if (get(this.currentRecord, 'timeConfig.timeTo')) {
          this.timeTo = new Date(
            get(this.currentRecord, 'timeConfig.date', '') + ' ' + get(this.currentRecord, 'timeConfig.timeTo', '')
          );
        }
      }
      this.timeManualInputControl.patchValue(this.currentRecord.rawTrackedTime.manualInput, {emitEvent: false});
      this.selectedServiceWithPauUnit = this.currentRecord.loggedUnits === 'l.s.';
      if (this.selectedServiceWithPauUnit) {
        this.roundingEnabledControl.disable();
        this.timeManualInputControl.disable();
      } else {
        this.roundingEnabledControl.enable();
      }
      this.loggedUnitsControl.patchValue(
        this.currentRecord.loggedUnits === 'h'
          ? this.hourUnitType
          : this.currentRecord.loggedUnits || this.hourUnitType,
        {emitEvent: false}
        );
      this.pauseUnitsControl.patchValue(
        this.currentRecord.pauseUnits === 'h'
          ? this.hourUnitType
          : this.currentRecord.pauseUnits || this.minuteUnitType,
        {emitEvent: false}
      );
      this.calculatedDuration();
      this.getRoundedTime();
    }

    if (this.data && this.data.partnerInfo) {
      this.partnerIdControl.patchValue(this.data.partnerInfo.id);

      if (this.data.partnerInfo.type === CustomerTypeEnum.NOT_SAVED) {
        this.partnerTypeControl.patchValue(this.data.partnerInfo.type, {emitEvent: false});
        this.partnerUserDefinedControl.patchValue(this.data.partnerInfo.name, {emitEvent: false});
      }
    }
  }

  private initForm(): void {
    const currentMonth = new Date().toLocaleString('eng', { month: 'long' }).toUpperCase();
    const currentQuarter = Math.floor((new Date().getMonth() + 3) / 3);
    const currentHalfYear = Math.floor((new Date().getMonth() + 6) / 6);
    const currentYear = new Date().getFullYear();

    this.form = this.fb.group({
      roundingEnabled: false,
      roundingOption: [RoundingEnum.MIN30],
      billNeeded: [true],
      remarkEnabled: [false],
      employee: this.fb.group({
        id: [
          {
            value: this.authService.getUser().id,
            disabled: true
          },
          [Validators.required]
        ],
      }),
      pauseTime: [null],
      pauseUnits: [this.minuteUnitType],
      loggedTime: [null, [Validators.required]],
      loggedUnits: [this.minuteUnitType],
      partnerInfo: this.fb.group({
        id: [null, [Validators.required]],
        type: [PartnersTypeEnum.CORPORATE],
        name: [null, [Validators.required]]
      }),
      remark: [null],
      product: this.fb.group({
        id:  [null, [Validators.required]],
        providedServices: []
      }),
      timeConfig: this.fb.group({
        type: [TimeLoggingByEnum.DATE],
        date: [this.datePipe.transform(new Date(), 'yyyy-MM-dd')],
        timeFrom: [null, [Validators.required]],
        timeTo: [null, [Validators.required]],
        manualInput: [true, [Validators.required]],
        config: this.fb.group({
          period: [ReportsPeriodEnum.MONTH, [Validators.required]],
          year: [currentYear, [Validators.required]],
          month: [currentMonth, [Validators.required]],
          quarter: ['Q' + currentQuarter, [Validators.required]],
          halfYear: ['H' + currentHalfYear, [Validators.required]],
          dateFrom: [this.datePipe.transform(new Date(), 'yyyy-MM-dd')],
          dateTo: [],
        }),
      }),
    });

    this.partnerTypeControl.valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe(value => {
        // this.partnerUserDefinedControl.patchValue(null);
        // this.partnerIdControl.patchValue(null);

        if (value === CustomerTypeEnum.NOT_SAVED) {
          this.billNeededControl.patchValue(true);
          this.partnerIdControl.patchValue(0);
        }
      });

    this.timeConfigTypeControl.valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe(value => {
        if (!this.selectedServiceWithPauUnit) {
          if (value === this.timeLoggingBy.DATE.toUpperCase()) {
            this.loggedUnitsControl.patchValue(this.minuteUnitType, {emitEvent: false});
          } else {
            this.loggedUnitsControl.patchValue(this.hourUnitType, {emitEvent: false});
          }
        }
        this.pauseTimeControl.patchValue(null);
        this.loggedTimeControl.patchValue(null);
        this.calculatedDuration();
        this.getRoundedTime();
      });

    this.timeManualInputControl.valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe(value => {
        if (value) {
          this.timeConfigTimeFromControl.disable();
          this.timeConfigTimeToControl.disable();
        } else {
          this.timeConfigTimeFromControl.enable();
          this.timeConfigTimeToControl.enable();
        }
        if (!this.currentRecord) {
          this.pauseTimeControl.patchValue(null);
          this.loggedTimeControl.patchValue(null);
        }
      });

    this.loggedTimeControl.valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe(() => {
        this.getRoundedTime();
      });

    this.roundingEnabledControl.valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe(() => {
        this.getRoundedTime();
      });

    this.roundingOptionControl.valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe(() => {
        this.getRoundedTime();
      });

    this.timeConfigTimeFromControl.valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe(() => {
        this.calculatedDuration();
      });

    this.timeConfigTimeToControl.valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe(() => {
        this.calculatedDuration();
      });

    this.pauseTimeControl.valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe(() => {
        this.calculatedDuration();
      });
  }

  private getRoundedTime(): void {
    if (!this.loggedTimeControl.value || !this.roundingEnabledControl.value || this.selectedServiceWithPauUnit) {
      this.roundedTime = null;
      return;
    }

    const unitValue = this.loggedUnitsControl.value === this.hourUnitType ? 'h' : this.loggedUnitsControl.value;

    this.timeTrackingApiService.getRoundedTime(this.loggedTimeControl.value, unitValue, this.roundingOptionControl.value)
      .pipe(
        debounceTime(500),
        takeUntil(this._destroy)
      )
      .subscribe(res => this.roundedTime = res);
  }

  private getCombinedPartners(): void {
    // // todo
    // this.partnersApiService.getCombinedPartnersList(
    //   {
    //     availableForTimeTracking: true,
    //   }
    // )
    //   .pipe(takeUntil(this._destroy))
    //   .subscribe(partners => {
    //   });
  }

  private getContinuousServices(): void {
    this.showDropdownSpin$.next(true);
    this.productsService.getContinuousServices(undefined, this.salesPriceListName)
      .pipe(
        finalize(() => this.showDropdownSpin$.next(false)),
        takeUntil(this._destroy)
      )
      .subscribe((continuousServices: ProductModel[]) => {
        this.availableServices = this.prepareServicesList(continuousServices);

        if (this.currentRecord && this.currentRecord.product.id) {
          if (!continuousServices.some(service => +service.id === +this.currentRecord.product.id)) {
            this.availableServices = this.prepareServicesList([...this.availableServices, this.currentRecord.product as any]);
          }
        }
      });
  }

  public prepareServicesList(products): ProductModel[] {
    return products.map(item => ({
      ...item,
      searchLabel: getProductSearchValueCamel(item, this.translateService)
    }));
  }

  public serviceSelected(service: ProductModel): void {
    this.productProvidedServicesControl.patchValue(service.description);
    if (service.unitType === 'l.s.') {
      this.selectedServiceWithPauUnit = true;
      this.roundingEnabledControl.patchValue(false);
      this.roundingEnabledControl.disable();
      this.timeManualInputControl.patchValue(true, {emitEvent: false});
      this.timeManualInputControl.disable();
    } else {
      this.selectedServiceWithPauUnit = false;
      this.roundingEnabledControl.enable();
      this.timeManualInputControl.enable();
      if (this.currentRecord && this.currentRecord.loggedUnits === 'l.s.') {
        this.loggedUnitsControl.patchValue(this.minuteUnitType);
        this.pauseUnitsControl.patchValue(this.minuteUnitType);
        this.calculatedDuration();
      }
    }
  }

  private getActiveEmployees(): void {
    this.hrmService.getUsers(UserListTabs.Active, '1', '10000')
      .pipe(takeUntil(this._destroy))
      .subscribe((response: ResponseList<UserModel>) => {
        this.availableEmployees = response.data.map(user => ({
          ...user,
          id: +user.id
        }));
      });
  }

  public customerChanged(customer: PartnerModel): void {
    this.salesPriceListName = customer.salesPriceList;
    this.getContinuousServices();
    const billNeeded: boolean = customer.type === PartnersTypeEnum.CORPORATE
      ? customer.sepaPaymentProfile.billLoggedTime
      : customer.billLoggedTime;

    this.billNeededControl.patchValue(billNeeded);
  }

  public logTime(skipSoftValidation = false): void {
    if (this.applyRequest$.getValue()) { return; }
    this.applyRequest$.next(true);

    if (!this.softValidationPassed(skipSoftValidation)) {
      this.applyRequest$.next(false);
      return;
    }

    if (this.currentRecord) {
      this.submitEditing();
    } else {
      this.submitTimeLogging();
    }
  }

  public submitTimeLogging(): void {
    const record = {
      ...this.form.getRawValue(),
      pauseUnits: this.selectedServiceWithPauUnit
        ? 'l.s.'
        : this.pauseUnitsControl.value,
      loggedUnits: this.selectedServiceWithPauUnit
        ? 'l.s.'
        : this.loggedUnitsControl.value,
    };

    this.timeTrackingApiService.createRecord(record)
      .pipe(
        finalize(() => this.applyRequest$.next(false)),
        takeUntil(this._destroy)
      )
      .subscribe(res => this.dialogRef.close(res));
  }

  public softValidationPassed(skipSoftValidation?: boolean): boolean {
    if (this.selectedServiceWithPauUnit) { return true; }

    let softValidation: boolean = true;
    let hardValidation: boolean = true;

    if (this.isLoggingByDate && this.loggedTimeControl.value) {
      softValidation = this.loggedUnitsControl.value === this.minuteUnitType
        ? this.loggedTimeControl.value <= (8 * 60)
        : this.loggedTimeControl.value <= 8;

      hardValidation = this.loggedUnitsControl.value === this.minuteUnitType
        ? this.loggedTimeControl.value <= (24 * 60)
        : this.loggedTimeControl.value <= 24;

      if (this.timeConfigTimeFromControl.value && this.timeConfigTimeToControl.value) {
        const timeFrom = new Date(this.timeConfigDateControl.value + ' ' + this.timeConfigTimeFromControl.value);
        const timeTo = new Date(this.timeConfigDateControl.value + ' ' + this.timeConfigTimeToControl.value);
        const minutesDiff = this.minutesBetween(timeFrom, timeTo);

        const timePeriodValidation = this.loggedUnitsControl.value === this.minuteUnitType
          ? this.loggedTimeControl.value > minutesDiff
          : this.loggedTimeControl.value > minutesDiff / 60;

        if (timePeriodValidation) {
          this.toasterService.notify({
            type: 'error',
            message: 'TIME_TRACKING.UNABLE_LOG_TIME1'
          });
          return false;
        }
      }
    }

    if (this.isLoggingByPeriod) {
      if (this.periodControl.value === ReportsPeriodEnum.YEAR) {
        softValidation = this.getDaysRangeSoftValidation(240);
        hardValidation = this.getDaysRangeHardValidation(360);
      }

      if (this.periodControl.value === ReportsPeriodEnum.HALF_YEAR) {
        softValidation = this.getDaysRangeSoftValidation(120);
        hardValidation = this.getDaysRangeHardValidation(180);
      }

      if (this.periodControl.value === ReportsPeriodEnum.QUARTER) {
        softValidation = this.getDaysRangeSoftValidation(60);
        hardValidation = this.getDaysRangeHardValidation(90);
      }

      if (this.periodControl.value === ReportsPeriodEnum.MONTH) {
        softValidation = this.getDaysRangeSoftValidation(20);
        hardValidation = this.getDaysRangeHardValidation(30);
      }

      if (this.periodControl.value === ReportsPeriodEnum.RANGE && this.dateFromControl.value && this.dateToControl.value) {
        const daysBetween = daysBetweenDates(this.dateFromControl.value, this.dateToControl.value);
        softValidation = this.getDaysRangeSoftValidation(daysBetween);
        hardValidation = this.getDaysRangeHardValidation(daysBetween);
      }
    }

    if (!hardValidation) {
      this.toasterService.notify({
        type: 'error',
        message: 'TIME_TRACKING.UNABLE_LOG_TIME2'
      });
      return false;
    }

    if (!softValidation && !skipSoftValidation) {
      this.dialog
        .open(WarningModalComponent, {
          data: {
            title: 'TIME_TRACKING.TOO_MANY_HOURS',
            message: 'TIME_TRACKING.TOO_MANY_HOURS_MSG',
            confirmBtnIcon: 'timer',
            confirmBtnText: 'BUTTON.LOG_TIME',
          }
        })
        .afterClosed()
        .subscribe(res => {
          if (res === CommonModalsActionsEnum.CONFIRM) {
            this.logTime(true);
          }
        });
      return false;
    }

    return (softValidation || skipSoftValidation) && hardValidation;
  }

  public getDaysRangeSoftValidation(days: number): boolean {
    return this.loggedUnitsControl.value === this.minuteUnitType
      ? this.loggedTimeControl.value <= days * (8 * 60)
      : this.loggedTimeControl.value <= days * 8;
  }

  public getDaysRangeHardValidation(days: number): boolean {
    return  this.loggedUnitsControl.value === this.minuteUnitType
      ? this.loggedTimeControl.value <= days * (24 * 60)
      : this.loggedTimeControl.value <= days * 24;
  }

  public submitEditing(): void {
    const record = {
      ...this.currentRecord,
      ...this.form.getRawValue(),
      pauseUnits: this.selectedServiceWithPauUnit
        ? 'l.s.'
        : this.pauseUnitsControl.value,
      loggedUnits: this.selectedServiceWithPauUnit
        ? 'l.s.'
        : this.loggedUnitsControl.value,
    };

    this.timeTrackingApiService.updateRecord(this.currentRecord.id, record)
      .pipe(takeUntil(this._destroy))
      .subscribe(res => {
        this.timeTrackingApiService.finishTimeTrackingRecordEditing(this.currentRecord.id)
          .pipe(takeUntil(this._destroy))
          .subscribe(() => {
            this.dialogRef.close(res);
          });
      });
  }

  public deleteRecord(): void {
    this.timeTrackingApiService.finishTimeTrackingRecordEditing(this.currentRecord.id)
      .pipe(takeUntil(this._destroy))
      .subscribe(() => {

        this.timeTrackingApiService.deleteRecord(this.currentRecord.id)
          .pipe(takeUntil(this._destroy))
          .subscribe(() => this.dialogRef.close(true));

      });
  }

  public closeRecord(): void {
    if (this.currentRecord && this.currentRecord.id) {
      this.timeTrackingApiService.finishTimeTrackingRecordEditing(this.currentRecord.id)
        .pipe(takeUntil(this._destroy))
        .subscribe(() => {
          this.dialogRef.close();
        });
    } else {
      this.dialogRef.close();
    }
  }

  private minutesBetween(startDate, endDate): number {
    const diff = (endDate.getTime() - startDate.getTime()) / 1000 / 60;

    return Math.round(diff);
  }

  public calculatedDuration(): void {
    if (!this.timeConfigTimeFromControl.value || !this.timeConfigTimeToControl.value || this.timeManualInputControl.value) {
      if (this.timeManualInputControl.value) {
        this.calculatedDurationTime = this.loggedTimeControl.value;
        return;
      }
      this.calculatedDurationTime = null;
      return;
    }

    const timeFrom = new Date(this.timeConfigDateControl.value + ' ' + this.timeConfigTimeFromControl.value);
    const timeTo = new Date(this.timeConfigDateControl.value + ' ' + this.timeConfigTimeToControl.value);

    let duration = this.minutesBetween(timeFrom, timeTo);

    if (this.pauseTimeControl.value) {
      if (this.pauseUnitsControl.value === 'h') {
        duration = duration - (this.pauseTimeControl.value * 60);
      } else {
        duration = duration - this.pauseTimeControl.value;
      }
    }

    if (this.loggedUnitsControl.value === 'h') {
      duration = duration / 60;
    }

    duration = +duration.toFixed(2);

    this.loggedTimeControl.patchValue(duration, {emitEvent: false});
    this.getRoundedTime();

    this.calculatedDurationTime = duration;
  }

  get currentRecord(): TimeTrackingRecordModel {
    return get(this, 'data.record');
  }

  get isLoggingByDate(): boolean {
    return this.timeConfigTypeControl.value === TimeLoggingByEnum.DATE;
  }

  get isLoggingByPeriod(): boolean {
    return this.timeConfigTypeControl.value === TimeLoggingByEnum.PERIOD;
  }

  get partnerTypeControl(): FormControl { return this.form.get('partnerInfo').get('type') as FormControl; }
  get partnerUserDefinedControl(): FormControl { return this.form.get('partnerInfo').get('name') as FormControl; }
  get partnerIdControl(): FormControl { return this.form.get('partnerInfo').get('id') as FormControl; }

  get employeeIdControl(): FormControl { return this.form.get('employee').get('id') as FormControl; }

  get productIdControl(): FormControl { return this.form.get('product').get('id') as FormControl; }
  get productProvidedServicesControl(): FormControl { return this.form.get('product').get('providedServices') as FormControl; }

  get timeConfigTypeControl(): FormControl { return this.form.get('timeConfig').get('type') as FormControl; }
  get timeConfigTimeFromControl(): FormControl { return this.form.get('timeConfig').get('timeFrom') as FormControl; }
  get timeConfigTimeToControl(): FormControl { return this.form.get('timeConfig').get('timeTo') as FormControl; }
  get timeConfigDateControl(): FormControl { return this.form.get('timeConfig').get('date') as FormControl; }
  get timeManualInputControl(): FormControl { return this.form.get('timeConfig').get('manualInput') as FormControl; }

  get loggedTimeControl(): FormControl { return this.form.get('loggedTime') as FormControl; }
  get loggedUnitsControl(): FormControl { return this.form.get('loggedUnits') as FormControl; }
  get remarkControl(): FormControl { return this.form.get('remark') as FormControl; }
  get remarkEnabledControl(): FormControl { return this.form.get('remarkEnabled') as FormControl; }
  get billNeededControl(): FormControl { return this.form.get('billNeeded') as FormControl; }

  get roundingEnabledControl(): FormControl { return this.form.get('roundingEnabled') as FormControl; }
  get roundingOptionControl(): FormControl { return this.form.get('roundingOption') as FormControl; }

  get pauseTimeControl(): FormControl { return this.form.get('pauseTime') as FormControl; }
  get pauseUnitsControl(): FormControl { return this.form.get('pauseUnits') as FormControl; }

  get periodControl(): FormControl { return this.form.get('timeConfig').get('config').get('period') as FormControl; }
  get yearControl(): FormControl { return this.form.get('timeConfig').get('config').get('year') as FormControl; }
  get monthControl(): FormControl { return this.form.get('timeConfig').get('config').get('month') as FormControl; }
  get quarterControl(): FormControl { return this.form.get('timeConfig').get('config').get('quarter') as FormControl; }
  get halfYearControl(): FormControl { return this.form.get('timeConfig').get('config').get('halfYear') as FormControl; }
  get dateFromControl(): FormControl { return this.form.get('timeConfig').get('config').get('dateFrom') as FormControl; }
  get dateToControl(): FormControl { return this.form.get('timeConfig').get('config').get('dateTo') as FormControl; }

}
