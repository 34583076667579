import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';

import { AppState } from '../../store/state/app.state';
import { ResponseModel, ResponseList, FormInputChangedModel } from '../../shared/models';
import { CreditNoteModel, CreditNotesCountersModel, CreditNotesListTotalsModel } from '../models';
import { CreditNotePurposeEnum, CreditNotesListTabsEnum } from '../enums';
import { FilterModel } from '../../warehouse/models/filter.model';
import { FilterModelNew } from '../../outgoing-invoice/models/filter-model-new';
import { PaymentDirectionEnum, PaymentModel } from '../../payment/models/payment.model';
import { ChangeStatusOperationsEnum } from '../../outgoing-invoice/enums';
import { EditModel } from '../../outgoing-invoice/models/edit.model';
import {
  DecrementLoadingRequestsCount,
  IncrementLoadingRequestsCount,
  LoadCreditNote,
  LoadCreditNoteList,
  LoadCreditNotePositions,
  UpdateCreditNotePositionsCount,
  UpdateCreditNoteState,
  UpdateCreditNoteUpdatedAt
} from '../store/actions/credit-note.actions';
import { CreditNotePositionModel, CRNPositionsModel } from '../models/credit-note-position.model';
import { UpdatePositionsModel } from '../../outgoing-invoice/models';
import { environment } from 'projects/workspace/src/environments/environment';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { CommonModalsActionsEnum, InfoModalComponent, WarningModalComponent } from 'common/src/modules/modals/modals-common';
import { getAnotherUserEditErrorModalData } from 'common/src/modules/modals/modals-common/common-modal.config';
import { UIStatesEnum } from 'common/src/models';
import { FileUploadParams } from 'common/src/models/file-upload-params.model';
import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { AvailableSourcesModel } from '../models/available-sources.model';
import { EraCostMismatchModalData, EraQuantityMismatchModalData } from '../pages/credit-note/credit-note.config';
import { PurchaseOrder } from '../../purchase-order/models';
import { DEFAULT_SORT_DIRECTION } from '../../shared/constants';

@Injectable({
  providedIn: 'root'
})
export class CreditNoteApiService {
  private readonly apiEndpoint: string = `${environment.javaApiVersion}/crn`;

  private apiUrl(url: string = ''): string {
    return this.apiEndpoint + url;
  }

  constructor(
    private readonly toasterService: ToasterService,
    private readonly translateService: TranslateService,
    private readonly http: HttpClient,
    private readonly dialog: MatDialog,
    private readonly store: Store<AppState>
  ) { }

  // public createCreditNoteBlank(): Observable<CreditNoteModel> {
  //   return this.http.post<ResponseModel<CreditNoteModel>>(this.apiUrl('/blank'), {})
  //     .pipe(
  //       map((data: ResponseModel<CreditNoteModel>) => data.data),
  //       catchError(error => {
  //         this.handlePopupErrors(error);
  //         return throwError(error);
  //       })
  //     );
  // }

  public createCreditNoteForInvoice(invoiceId: number, purpose: CreditNotePurposeEnum, documentDirection?: PaymentDirectionEnum): Observable<CreditNoteModel> {
    const body = { invoiceId, purpose, documentDirection };
    return this.http.post<ResponseModel<CreditNoteModel>>(this.apiUrl('/create-predefined'), body)
      .pipe(
        map((data: ResponseModel<CreditNoteModel>) => data.data),
        catchError(error => {
          this.handlePopupErrors(error, invoiceId);
          return throwError(error);
        })
      );
  }

  public getCreditNoteList(
    filter: FilterModel = {nameColumn: 'status', value: 'active'},
    page: string = '1',
    per_page: string = '100',
    sort: FilterModelNew = { sortBy: 'tracking.updatedAt', direction: DEFAULT_SORT_DIRECTION },
    status: CreditNotesListTabsEnum,
    filters: any = {}
  ): Observable<ResponseList<CreditNoteModel>> {
    const params = {
      status: filter.value,
      page,
      sortBy: sort.sortBy,
      direction: sort.direction,
      length: per_page
    };

    for (const [key, value] of Object.entries(filters)) {
      params[key] = Array.isArray(value) ? value.join(',') : value.toString();
    }

    return this.http.get<ResponseList<CreditNoteModel>>(this.apiEndpoint, { params })
      .pipe(tap((data: ResponseList<CreditNoteModel>) => {
        this.store.dispatch(LoadCreditNoteList({
            creditNoteListData: {
              [data.pagination.page]: {
                pagination: data.pagination,
                sort,
                data: data.data
              }
            },
            status,
            page: data.pagination.page
          }));
      }));
  }

  getCreditNotesFilterRunpleIds(wid: number): Observable<any[]> {
    const params = {
      wid: wid.toString()
    };
    return this.http.get<ResponseModel<any[]>>(`${this.apiEndpoint}/filter/rids`, { params })
      .pipe(map((response: ResponseModel<any[]>) => response.data));
  }

  getCreditNotesFilterCostCenters(wid: number, status: CreditNotesListTabsEnum): Observable<{ key: number; value: string }[]> {
    const params = {
      wid: wid.toString()
    };
    return this.http.get<ResponseModel<{ key: number; value: string }[]>>(`${this.apiEndpoint}/filter/cost-center/${status}`, { params })
      .pipe(map((response: ResponseModel<{ key: number; value: string }[]>) => response.data));
  }

  getCreditNotesFilterGeneralProducts(status: CreditNotesListTabsEnum): Observable<any[]> {
    return this.http.get<ResponseModel<any[]>>(`${this.apiEndpoint}/filter/general-products/${status}`)
      .pipe(map((response: ResponseModel<any[]>) => response.data));
  }

  getCreditNotesFilterLinkedDocuments(): Observable<any[]> {
    return this.http.get<ResponseModel<any[]>>(`${this.apiEndpoint}/filter/linkedDocuments`)
      .pipe(map((response: ResponseModel<any[]>) => response.data));
  }

  // getCreditNotesFilterIBANs(): Observable<string[]> {
  //   return this.http.get<ResponseModel<string[]>>(`${this.apiEndpoint}/filter/ibans`)
  //     .pipe(
  //       map((response: ResponseModel<string[]>) => {
  //         return response.data
  //           .filter(itm => !!itm)
  //           .sort((a, b) => a.localeCompare(b));
  //       })
  //     );
  // }

  getCreditNotesFilterExternalNumbers(): Observable<string[]> {
    return this.http.get<ResponseModel<string[]>>(`${this.apiEndpoint}/filter/extnumbers`)
      .pipe(
        map((response: ResponseModel<string[]>) => {
          return response.data
            .filter(itm => !!itm)
            .sort((a, b) => a.localeCompare(b));
        })
      );
  }

  public getCreditNotesListTotals(status: CreditNotesListTabsEnum,  filters: any = {}): Observable<CreditNotesListTotalsModel> {
    const params = {};
    for (const [key, value] of Object.entries(filters)) {
      params[key] = Array.isArray(value) ? value.join(',') : value.toString();
    }
    return this.http.get<ResponseModel<CreditNotesListTotalsModel>>(`${this.apiEndpoint}/totals/${status}`, {params})
      .pipe(map((data: ResponseModel<CreditNotesListTotalsModel>) => data.data));
  }

  public getCreditNoteById(id: number): Observable<CreditNoteModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.get<ResponseModel<CreditNoteModel>>(this.apiUrl(`/${id}`))
      .pipe(
        tap((response: ResponseModel<CreditNoteModel>) => {
          this.store.dispatch(LoadCreditNote({creditNote: response.data}));
          this.store.dispatch(UpdateCreditNoteUpdatedAt({ updatedAt: new Date() }));
        }),
        map((response: ResponseModel<CreditNoteModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
      );
  }

  public updateCreditNotePart(id: number, fieldData: FormInputChangedModel): Observable<CreditNoteModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<CreditNoteModel>>(`${this.apiEndpoint}/${id}`, fieldData)
      .pipe(
        tap((response: ResponseModel<CreditNoteModel>) => {
          this.store.dispatch(LoadCreditNote({creditNote: response.data}));
          this.store.dispatch(UpdateCreditNoteUpdatedAt({ updatedAt: new Date() }));
        }),
        map((response: ResponseModel<CreditNoteModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, id);
          return throwError(error);
        })
      );
  }

  public bookPaymentBaseOnCreditNote(creditNoteId: number): Observable<PaymentModel> {
    const params = {
      type: 'CRN',
      id: String(creditNoteId)
    };
    return this.http.request<ResponseModel<PaymentModel>>('post', `${environment.javaApiVersion}/payments/based-on`, {params})
      .pipe(
        map((response: ResponseModel<PaymentModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error, creditNoteId);
          return throwError(error);
        })
      );
  }

  public allocatePayment(creditNoteId: number): Observable<PaymentModel> {
    return this.http.request<ResponseModel<PaymentModel>>('post', `${this.apiEndpoint}/${creditNoteId}/allocate-payment`)
      .pipe(
        map((response: ResponseModel<PaymentModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error, creditNoteId);
          return throwError(error);
        })
      );
  }

  public createCreditNoteFromERA(eraId: number): Observable<CreditNoteModel[]> {
    return this.http.request<ResponseModel<CreditNoteModel[]>>('put', this.apiUrl(`/based-on/${eraId}`))
      .pipe(
        map((data: ResponseModel<CreditNoteModel[]>) => data.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public changeCreditNoteStatus(creditNoteId: number, operation: ChangeStatusOperationsEnum, proceed = false): Observable<CreditNoteModel> {
    const params = new HttpParams()
      .set('proceed', proceed.toString());

    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<CreditNoteModel>>(this.apiUrl(`/${creditNoteId}/${operation}`), {}, { params })
      .pipe(
        tap((response: ResponseModel<CreditNoteModel>) => {
          this.store.dispatch(UpdateCreditNoteUpdatedAt({ updatedAt: new Date() }));
          this.store.dispatch(LoadCreditNote({creditNote: response.data as CreditNoteModel}));
        }),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        map((data: ResponseModel<CreditNoteModel>) => data.data),
      );
  }

  public changeBatchCreditNoteStatus(ids: number[], operation: ChangeStatusOperationsEnum, proceed = false): Observable<any> {
    const params = new HttpParams()
      .set('proceed', proceed.toString());

    return this.http.patch(this.apiUrl(`/batch/${operation}`), { ids }, { params });
  }

  public startCreditNoteEditing(crnId: number, force = false, proceed = false): Observable<EditModel> {
    const params = new HttpParams()
      .set('force', force.toString())
      .set('proceed', proceed.toString());

    return this.http.get<EditModel>(`accounting/v1/utils/edit-flag/${DocumentTypesUppercaseEnum.CRN}/${crnId}/check-and-set`, {params})
      .pipe(
        tap(() => {
          this.store.dispatch(UpdateCreditNoteState({ currentState: UIStatesEnum.EDIT }));
        }),
        catchError(error => {
          this.handlePopupErrors(error, crnId);
          return throwError(error);
        })
      );
  }

  public finishCreditNoteEditing(crnId: number): Observable<EditModel> {
    return this.http.get<EditModel>(`accounting/v1/utils/edit-flag/${DocumentTypesUppercaseEnum.CRN}/${crnId}/commit`)
      .pipe(
        tap(() => {
          this.store.dispatch(UpdateCreditNoteState({ currentState: UIStatesEnum.VIEW }));
        }),
        catchError(error => {
          this.handlePopupErrors(error, null);
          return throwError(error);
        })
      );
  }

  public getCreditNotePositions(crnId: number): Observable<CRNPositionsModel> {
    return this.http.get<ResponseModel<CRNPositionsModel>>(`${this.apiEndpoint}/${crnId}/positions`)
      .pipe(
        map((data: ResponseModel<CRNPositionsModel>) => data.data),
        tap((positions: CRNPositionsModel) => {
          this.store.dispatch(LoadCreditNotePositions({positions: positions}))
        }),
      );
  }

  public getAvailableCreditNotePositions(crnId: number): Observable<CRNPositionsModel> {
    return this.http.get<ResponseModel<CRNPositionsModel>>(`${this.apiEndpoint}/${crnId}/available-positions`)
      .pipe(
        map((data: ResponseModel<CRNPositionsModel>) => data.data)
      );
  }

  // public addCreditNotePositions(
  //   crnId: number,
  //   positionIds: number[] = [],
  //   arbitraryPositions: Partial<CreditNotePositionModel>[] = []
  // ): Observable<CRNPositionsModel> {
  //   this.store.dispatch(IncrementLoadingRequestsCount());
  //   const body = {
  //     ids: positionIds,
  //     positions: arbitraryPositions
  //   };
  //
  //   return this.http.post<ResponseModel<CRNPositionsModel>>(`${this.apiEndpoint}/${crnId}/positions`, body)
  //     .pipe(
  //       tap(() => {
  //         this.store.dispatch(UpdateCreditNoteUpdatedAt({ updatedAt: new Date() }));
  //       }),
  //       map((data: ResponseModel<CRNPositionsModel>) => data.data),
  //       finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
  //       catchError(error => {
  //         this.handlePopupErrors(error, crnId);
  //         return throwError(error);
  //       })
  //     );
  // }

  // public addCreditNoteCommonPosition(crnId: number, position: Partial<CreditNotePositionModel>): Observable<CRNPositionsModel> {
  //   this.store.dispatch(IncrementLoadingRequestsCount());
  //
  //   return this.http.post<ResponseModel<CRNPositionsModel>>(`${this.apiEndpoint}/${crnId}/positions/create`, position)
  //     .pipe(
  //       tap(() => {
  //         this.store.dispatch(UpdateCreditNoteUpdatedAt({ updatedAt: new Date() }));
  //       }),
  //       map((data: ResponseModel<CRNPositionsModel>) => data.data),
  //       finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
  //       catchError(error => {
  //         this.handlePopupErrors(error, crnId);
  //         return throwError(error);
  //       })
  //     );
  // }

  public createCorrectionPositions(
    crnId: number,
    body: { targetAmountNet: number; targetVat: number; targetAmountGross: number; }
  ): Observable<CRNPositionsModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.post<ResponseModel<CRNPositionsModel>>(`${this.apiEndpoint}/${crnId}/positions/correction`, body)
      .pipe(
        tap(() => {
          this.store.dispatch(UpdateCreditNoteUpdatedAt({ updatedAt: new Date() }));
        }),
        map((data: ResponseModel<CRNPositionsModel>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, crnId);
          return throwError(error);
        })
      );
  }

  public createPositionByPredefinedForm(crnId: number, position: Partial<CreditNotePositionModel>): Observable<CRNPositionsModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());
    return this.http.post<ResponseModel<CRNPositionsModel>>(`${this.apiEndpoint}/${crnId}/positions-predefined`, position)
      .pipe(
        tap(() => {
          this.store.dispatch(UpdateCreditNoteUpdatedAt({ updatedAt: new Date() }));
        }),
        map((data: ResponseModel<CRNPositionsModel>) => data.data),
        tap((positions: CRNPositionsModel) => {
          this.store.dispatch(UpdateCreditNoteUpdatedAt({ updatedAt: new Date() }));
          this.store.dispatch(LoadCreditNotePositions({positions: positions}));
        }),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, crnId);
          return throwError(error);
        })
      );
  }

  public updateCreditNotePosition(
    creditNoteId: number,
    updatePosition: UpdatePositionsModel
  ): Observable<CreditNotePositionModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<CreditNotePositionModel>>(
      `${this.apiEndpoint}/${creditNoteId}/positions/${updatePosition.positionId}`,
      updatePosition
    ).pipe(
      tap(() => {
        this.store.dispatch(UpdateCreditNoteUpdatedAt({ updatedAt: new Date() }));
      }),
      map((data: ResponseModel<CreditNotePositionModel>) => data.data),
      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
      catchError(error => {
        this.handlePopupErrors(error, creditNoteId);
        return throwError(error);
      })
    );
  }

  public deleteCreditNotePositions(creditNoteId: number, ids: number[]): Observable<CRNPositionsModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());
    const body = { ids };

    return this.http.request<ResponseModel<CRNPositionsModel>>(
      'delete',
      `${this.apiEndpoint}/${creditNoteId}/positions`,
      {body}
    ).pipe(
      tap(() => {
        this.store.dispatch(UpdateCreditNoteUpdatedAt({ updatedAt: new Date() }));
      }),
      map((data: ResponseModel<CRNPositionsModel>) => data.data),
      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
      catchError(error => {
        this.handlePopupErrors(error, creditNoteId);
        return throwError(error);
      })
    );
  }

  changePositionOrder(posId: number, moveToOrder: number, creditNoteId: number): any {
    const body = {
      orderPosition: moveToOrder,
      id: posId,
    }
    return this.http.patch(`${this.apiEndpoint}/${creditNoteId}/positions/${posId}/order/${moveToOrder}`, body)
      .pipe(
        map((data: ResponseModel<PurchaseOrder>) => data.data),
        catchError(error => {
          this.showMsg('error', error.error.message);
          return throwError(error);
        })
      );
  }

  public getPositionsCount(creditNoteId: number): Observable<number> {
    return this.http.get(`${this.apiEndpoint}/${creditNoteId}/positions/count`)
      .pipe(
        tap((data: ResponseModel<number>) => {
          this.store.dispatch(UpdateCreditNotePositionsCount({positionsCount: data.data}));
        }),
        map((data: ResponseModel<number>) => data.data)
      );
  }

  public getCreditNotesCounters(): Observable<CreditNotesCountersModel> {
    return this.http.get<ResponseModel<CreditNotesCountersModel>>(`${this.apiEndpoint}/count/by-status`)
      .pipe(map((data: ResponseModel<CreditNotesCountersModel>) => data.data));
  }

  public getCreditNoteAvailableSources(crnId: number): Observable<AvailableSourcesModel[]> {
    return this.http.get<ResponseModel<AvailableSourcesModel[]>>(`${this.apiEndpoint}/${crnId}/available-sources`)
      .pipe(map((data: ResponseModel<AvailableSourcesModel[]>) => data.data));
  }

  public getCreditNoteModalAvailableSources(purpose: CreditNotePurposeEnum): Observable<AvailableSourcesModel[]> {
    const params = new HttpParams().set('purpose', purpose);
    return this.http.get<ResponseModel<AvailableSourcesModel[]>>(`${this.apiEndpoint}/modal/available-sources`, {params})
      .pipe(map((data: ResponseModel<AvailableSourcesModel[]>) => data.data));
  }

  public reCheckVatStatus(crnId: number): Observable<CreditNoteModel> {
    return this.http.request<ResponseModel<CreditNoteModel>>('post', `${this.apiEndpoint}/${crnId}/vat-status`)
      .pipe(
        tap((response: ResponseModel<CreditNoteModel>) => {
          this.store.dispatch(LoadCreditNote({creditNote: response.data as CreditNoteModel}));
          this.store.dispatch(UpdateCreditNoteUpdatedAt({ updatedAt: new Date() }));
        }),
        map((data: ResponseModel<CreditNoteModel>) => data.data)
      );
  }

  public getCreditNoteListExport(status: string): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: `${this.apiEndpoint}/download?status=${status}`,
      type: 'zip',
    };
    return of(fileParams);
  }

  public getCreditNoteListExportAll(title: string): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: `${this.apiEndpoint}/download/all`,
      type: 'zip',
      title,
    };
    return of(fileParams);
  }

  public downloadCreditNoteImgFile(crnId: number, imageId: number, title: string): Observable<Partial<FileUploadParams>> {
    const fileParams: Partial<FileUploadParams> = {
      url: `${this.apiEndpoint}/${crnId}/files/${imageId}`,
      title,
    };
    return of(fileParams);
  }

  public deleteFile(crnId: number | string, fileId: string): Observable<CreditNoteModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.delete<ResponseModel<CreditNoteModel>>(`${this.apiEndpoint}/${crnId}/files/${fileId}`)
      .pipe(
        tap((response: ResponseModel<CreditNoteModel>) => {
          this.store.dispatch(LoadCreditNote({creditNote: response.data as CreditNoteModel}));
          this.store.dispatch(UpdateCreditNoteUpdatedAt({ updatedAt: new Date() }));
        }),
        map((response: ResponseModel<CreditNoteModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, crnId);
          return throwError(error);
        })
      );
  }

  // public sendCreditNoteEmail(crnId: number): Observable<any> {
  //   return this.http.post(this.apiUrl(`/${crnId}/email`), {})
  //     .pipe(
  //       catchError(error => {
  //         this.handlePopupErrors(error, crnId);
  //         return throwError(error);
  //       })
  //     );
  // }

  public getUploadPdfLink(id: string | number): string {
    return `${environment.javaApiUrl}${this.apiEndpoint}/${id}/files/upload`;
  }

  public getCRNExportParams(id: number, title: string): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: `${environment.javaApiVersion}/pdf/crn?id=${id}`,
      type: 'pdf',
      title,
    };
    return of(fileParams);
  }

  public downloadCRNXMLFile(ids: number[]): Observable<Partial<FileUploadParams>> {
    const fileParams: Partial<FileUploadParams> = {
      url: this.apiUrl(`/xml?ids=${ids.join(',')}`),
      type: 'xml',
    };
    return of(fileParams);
  }

  public linkPayment(crnId: number, paymentId: number): Observable<any[]> {
    return this.http.get<ResponseModel<any[]>>(`${this.apiEndpoint}/${crnId}/payments/${paymentId}/link`)
      .pipe(
        map((data: ResponseModel<any[]>) => data.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getPaymentsAvailable(crnId: number): Observable<PaymentModel[]> {
    return this.http.get<ResponseModel<PaymentModel[]>>(`${this.apiEndpoint}/${crnId}/payments/available`)
      .pipe(
        map((data: ResponseModel<PaymentModel[]>) => data.data)
      );
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  private handlePopupErrors(error: HttpErrorResponse, crnId?: string | number): void {
    error.error.errors.forEach(errorText => {
      switch (errorText) {
        case 'anotherUserEditError':
        {
          const dialog = this.dialog.open(WarningModalComponent, {
            data: getAnotherUserEditErrorModalData(
              {
                document: error.error.data.entityName,
                userName: error.error.data.userName,
              },
              this.translateService
            ),
            backdropClass: 'backdrop-none',
          });

          dialog.afterClosed().subscribe(res => {
            if (res === CommonModalsActionsEnum.CONFIRM) {
              this.startCreditNoteEditing(+crnId, true).subscribe();
              this.getCreditNoteById(+crnId).subscribe();
            }
          });
        }
          break;
        case 'The document has been sent to the accountant, if you edit it, you will need to re-send the document to the accountant. Proceed?':
        {
          const dialog = this.dialog.open(WarningModalComponent, {
            data: {
              title: 'COMMON.DOC_SENT_TO_ACCOUNTANT',
              message: 'COMMON.DOC_SENT_TO_ACCOUNTANT_MSG',
              confirmBtnText: 'BUTTON.EDIT',
              confirmBtnIcon: 'edit'
            }
          });

          dialog.afterClosed().subscribe(res => {
            if (res === CommonModalsActionsEnum.CONFIRM) {
              this.startCreditNoteEditing(+crnId, false, true).subscribe();
              this.getCreditNoteById(+crnId).subscribe();
            }
          });
        }
          break;
        case 'notEditModeError':
          const documentName = this.translateService.instant('APP.CREDIT_NOTE');
          this.showMsg('warning', this.translateService.instant('COMMON.DOC_UPDATED_BY_USER', { document: documentName }));
          this.store.dispatch(UpdateCreditNoteState({ currentState: UIStatesEnum.VIEW }));
          this.getCreditNoteById(+crnId).subscribe();
          break;
        case 'Mismatched quantity.':
        case 'Die Menge stimmt nich überein.':
          this.dialog.open(InfoModalComponent, {
            data: EraQuantityMismatchModalData
          });
          break;
        case 'Mismatched delivery costs.':
        case 'Lieferkosten stimmen nich überein.':
          this.dialog.open(InfoModalComponent, {
            data: EraCostMismatchModalData
          });
          break;
        default:
          this.showMsg('error', errorText);
          break
      }
    });
  }

}
