import { Component, EventEmitter, Input, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { TradePricesApiService } from '../../../trade-prices/services/trade-prices-api.service';
import { PriceListEntityModel } from '../../../trade-prices/models';

@Component({
  selector: 'rnpl-sales-price-list-control',
  templateUrl: './sales-price-list-control.component.html',
})
export class SalesPriceListControlComponent implements OnDestroy {

  public priceLists: PriceListEntityModel[] = [];

  @Input() control: FormControl;
  @Input() label: string = 'FORM.PRICE_LIST';
  @Input() selectDefault: boolean = false;
  @Input() disabled: boolean = false;
  @Input() showWithoutCustom: boolean = true;
  @Input() showEcoPrices: boolean = true;
  @Output() fieldChanged: EventEmitter<string> = new EventEmitter<string>();

  public loadingList$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private readonly tradePricesApiService: TradePricesApiService,
  ) {
    this.getPriceLists();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.hasOwnProperty('disabled') && this.disabled && this.control) {
      this.control.disable();
    }
  }

  public getPriceLists(): void {
    this.tradePricesApiService.getPriceLists()
      .pipe(
        finalize(() => this.loadingList$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe((lists: PriceListEntityModel[]) => {
        this.priceLists = lists.filter(itm => {
          if (this.showEcoPrices) { return true; }
          return itm.name !== 'ecommerce';
        });
        if (this.selectDefault && this.control && !this.control.value) {
          const defaultPriceListName: string = lists.find((itm: PriceListEntityModel) => itm.name === 'Default price list').name;
          this.control.patchValue(defaultPriceListName);
        }
      });
  }

  public updateField(fieldValue: string): void {
    this.fieldChanged.emit(fieldValue);
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
