import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from "@ngx-translate/core";

import { TableTotalInfoComponent } from './table-total-info.component';
import { RnplCommonModule, UIComponentsModule } from 'common/src/modules';
import { CrmService } from '../../../crm/crm.service';
import { SummaryComponent } from './summary/summary.component';
import { DiscountComponent } from './discount/discount.component';
import { DiscountFormModule, CostCenterControlModule } from '../../../shared/forms';
import { SortingVatDetailPipeModule } from '../../../shared/pipes/sorting-vat-detail-pipe/sorting-vat-detail-pipe.module';

@NgModule({
  declarations: [
    TableTotalInfoComponent,
    SummaryComponent,
    DiscountComponent
  ],
  imports: [
    CommonModule,
    UIComponentsModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    UIComponentsModule,
    RouterModule,
    RnplCommonModule,
    TranslateModule,
    DiscountFormModule,
    SortingVatDetailPipeModule,
    CostCenterControlModule,
  ],
  exports: [
    TableTotalInfoComponent,
    SummaryComponent,
    DiscountComponent
  ],
  providers: [
    CrmService
  ]
})
export class TableTotalInfoModule {

}
