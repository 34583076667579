import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { FileSaverService } from 'ngx-filesaver';
import { finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { get, isEqual } from 'lodash';

import { NavBarBtnModel, PaginationModel } from 'common/src/models';
import { TableActivateTypes } from 'common/src/modules/ui-components/table/custom-table.enums';
import { TableColumnModel } from 'common/src/models/table-column.model';
import { FileUploadParams } from 'common/src/models/file-upload-params.model';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { FileService } from 'common/src/services/file.service';
import {
  getTableSummaryBarItems,
  NavBarButtons,
  StockStatusListColumns
} from './return-stock-status-list.config';
import { FilterModel } from '../../models/filter.model';
import { ReturnStockStatusService } from '../../services';
import { ResponseList, UserSorting } from '../../../shared/models';
import { ReturnStockStatusListState } from '../../store/reducers';
import { AppState } from '../../../store/state/app.state';
import { selectReturnStockStatusList } from '../../store/selectors';
import { DEFAULT_PAGINATION, DEFAULT_SORT_DIRECTION } from '../../../shared/constants';
import { ReturnStockStatusDocumentModel, ReturnStockStatusModel } from '../../models';
import { TableSummaryBarItemModel } from 'common/src/modules/ui-components/table-summary-bar/table-summary-bar.model';
import {
  DocumentTypesEnum,
  DocumentTypesUppercaseEnum
} from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { TabDefinitionModel } from 'common/src/modules/ui-components/nav-tabs/tab-definition.model';
import {
  getStockStatusBinsInnerTableColumns,
  StockStatusDocumentsInnerTableColumns,
  StockStatusListTabs
} from '../stock-status-list/stock-status-list.config';
import { StockStatusBinsModel, StockStatusDocumentModel } from '../../models/stock-address.model';
import { TranslateService } from "@ngx-translate/core";
import { getContentDispositionFileName } from 'common/src/modules/rnpl-common/helpers';
import { getDocumentTypeLabel } from '../../../shared/helpers';
import { UserSortingService } from '../../../shared/services';

@Component({
  selector: 'rnpl-return-stock-status-list',
  templateUrl: './return-stock-status-list.component.html',
  styleUrls: ['./return-stock-status-list.component.scss'],
})
export class ReturnStockStatusListComponent implements OnInit, OnDestroy {
  public columns: TableColumnModel[] = StockStatusListColumns();
  public customizedColumns: TableColumnModel[] = [];
  public innerTableColumns: TableColumnModel[] = [];
  public pagination: PaginationModel = DEFAULT_PAGINATION;
  public productDataBy: 'documents'|'bins' = 'documents';

  public navBarButtons: NavBarBtnModel[] = NavBarButtons;
  public tableSummaryBarItems: TableSummaryBarItemModel[] = [];
  public documentTypesUppercaseEnum = DocumentTypesUppercaseEnum;

  readonly stockStatusList$: BehaviorSubject<ReturnStockStatusModel[]> = new BehaviorSubject([]);
  private listData$: BehaviorSubject<ReturnStockStatusListState> = new BehaviorSubject(null);
  // readonly selectedInnerTableData$: BehaviorSubject<ReturnStockStatusDocumentModel[]> = new BehaviorSubject([]);
  readonly selectedInnerTableData$: BehaviorSubject<StockStatusDocumentModel[] | StockStatusBinsModel[]> = new BehaviorSubject([]);

  readonly updatedAt$: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());
  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly isLoadingInnerTable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public tabs:  TabDefinitionModel[] = StockStatusListTabs;

  public sort: FilterModel =  {nameColumn: 'categoryPath', value: DEFAULT_SORT_DIRECTION};

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  @ViewChild('tableComponent', {static: false}) tableComponentRef;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly returnStockStatusService: ReturnStockStatusService,
    private readonly toasterService: ToasterService,
    private readonly location: Location,
    private readonly datePipe: DatePipe,
    private readonly fileService: FileService,
    private readonly fileSaverService: FileSaverService,
    private readonly store: Store<AppState>,
    private readonly cdr: ChangeDetectorRef,
    private titleService: Title,
    private translate: TranslateService,
    private userSortingService: UserSortingService
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.translate.instant('TAB.STOCK_STATUS_RETURNED_STOCK'));
    this.bindTOList();

    this.activatedRoute.paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe((params: ParamMap) => {
        const pageFromRouting = Number(params.get('page'));

        this.pagination = { ...this.pagination, page: pageFromRouting };
        this.tableSummaryBarItems = [];

        this.getReturnStockStatusList();
        this.cdr.detectChanges();
      });
  }

  private prepareStockStatusList (data: any[]): any[] {

    return data.map(stockStatus => {
      return {
        ...stockStatus,
        productLink: {
          label: stockStatus.productRunpleId,
          routerLink: `/products/product-view/${stockStatus.productId}`
        },
        productNameLink: {
          label: stockStatus.productName,
          routerLink: `/products/product-view/${stockStatus.productId}`
        }
      };
    })

  }

  private prepareStockStatusInnerList(data: any[]): any[] {
    return data.map(document => {
      return {
        ...document,
        documentLink: {
          label: document.runpleId,
          routerLink: this.linkToDocument(document),
        },
      };
    })

  }

  public bindTOList() {
    this.store.select(selectReturnStockStatusList)
      .pipe(takeUntil(this.destroy$))
      .subscribe((listData) => {
        this.listData$.next(listData);
      });

  }

  public getReturnStockStatusList(
    preventLoading = false,
    page = this.pagination.page
  ): void {
    if (!preventLoading) {
      this.isLoading$.next(true);
    }

    if (this.tableComponentRef) {
      this.tableComponentRef.collapseAll();
    }

    this.userSortingService.getUserSorting(DocumentTypesUppercaseEnum.STOCK_RETURN, DocumentTypesUppercaseEnum.STOCK_RETURN)
      .pipe(
        tap((sorting: UserSorting) => {
          this.sort = {
            nameColumn: sorting.sortBy || this.sort.nameColumn,
            value: sorting.direction || this.sort.value
          };
          const stockStatusByCurrentPage = this.listData$.getValue()[page];
          if (stockStatusByCurrentPage && isEqual(this.sort, stockStatusByCurrentPage.sort)) {
            this.tableSummaryBarItems = getTableSummaryBarItems(stockStatusByCurrentPage.totals);
            this.pagination = stockStatusByCurrentPage.pagination;
            this.sort = stockStatusByCurrentPage.sort;
            this.stockStatusList$.next(this.prepareStockStatusList(stockStatusByCurrentPage.data));
            this.isLoading$.next(false);
          }
        }),
        switchMap(() => this.returnStockStatusService.getReturnStockStatusList(
          page,
          this.pagination.per_page,
          this.sort
        )),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (data: ResponseList<ReturnStockStatusModel>) => {
          this.stockStatusList$.next(this.prepareStockStatusList(data.data));
          this.pagination = get(data, 'pagination', DEFAULT_PAGINATION);
          this.isLoading$.next(false);
          this.updatedAt$.next(new Date());
          this.sort = get(this, 'sort',  { nameColumn: 'categoryPath', value: DEFAULT_SORT_DIRECTION });
          this.pagination = get(this, 'pagination', DEFAULT_PAGINATION);
          this.tableSummaryBarItems = getTableSummaryBarItems(data.totals);
          const url = `/warehouse/stock-status/returned-stock/${this.pagination.page}`;
          this.location.go(url); // update url without reloading
        },
        (error) => {
          this.isLoading$.next(false);
          this.updatedAt$.next(null);
          this.showMsg('error', error.error.message);
        }
      );
  }

  public changeProductDataBy(source: 'documents'|'bins'): void {
    this.productDataBy = source;

    if (this.tableComponentRef) {
      this.tableComponentRef.collapseAll();
    }
  }

  public rowClickReceiver(event): void {
    // if (event.type === TableActivateTypes.Link) {
    //   if (event.column.name === 'Product ID') {
    //     this.router.navigate([`/products/product-view/${event.row.productId}`]);
    //   }
    // }

    if (event.type === TableActivateTypes.RowDetail) {
      this.isLoadingInnerTable$.next(true);
      let request$: Observable<any>;

      if (this.productDataBy === 'documents') {
        this.innerTableColumns = StockStatusDocumentsInnerTableColumns();

        request$ = this.returnStockStatusService.getReturnsStockListDocuments(event.row.id)
          .pipe(map(documents => {
            return documents.map((doc: StockStatusDocumentModel) => ({
              ...doc,
              unitType: event.row.unitType,
              typeLabel: this.translate.instant(getDocumentTypeLabel(doc.type))
            }));
          }));
      }

      if (this.productDataBy === 'bins') {
        this.innerTableColumns = getStockStatusBinsInnerTableColumns();

        request$ = this.returnStockStatusService.getReturnsStockListBins(event.row.id)
          .pipe(map(bins => {
            return bins.map((bin: StockStatusBinsModel) => ({
              ...bin,
              unitType: event.row.unitType,
              locationType: bin.locationType === 'general_stock'
                ? this.translate.instant('TAB.GENERAL_STOCK')
                : this.translate.instant('TAB.RETURNS_STOCK')
            }));
          }));
      }

      if (!request$) { return; }

      request$
        .pipe(
          finalize(() => this.isLoadingInnerTable$.next(false)),
          takeUntil(this.destroy$)
        )
        .subscribe((data: any[]) => {
          this.selectedInnerTableData$.next(this.prepareStockStatusInnerList(data));
        });
    }
  }

  public linkToDocument(document: ReturnStockStatusDocumentModel): string {
    let url: string = '';
    if (document.type === DocumentTypesEnum.ERA) {
      url = `/trade/exchanges-and-returns/${document.id}`;
    }

    return url;
  }

  public sortTable(e): void {
    let sortedProp = e.column.prop;

    if (sortedProp === 'productLink') {
      sortedProp = 'productRunpleId';
    }

    if (sortedProp === 'productNameLink') {
      sortedProp = 'productName';
    }

    this.sort = { nameColumn: sortedProp, value: e.newValue.toUpperCase() };
    this.saveUserSorting(sortedProp, e.newValue.toUpperCase());
  }

  public saveUserSorting(sortBy: string, direction: 'ASC'|'DESC'): void {
    const sorting = {
      sortBy,
      direction,
      type: DocumentTypesUppercaseEnum.STOCK_RETURN,
      status: DocumentTypesUppercaseEnum.STOCK_RETURN
    }
    this.userSortingService.saveUserSorting(sorting)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.getReturnStockStatusList(true, this.pagination.page));
  }

  public goToPage(page: number): void {
    const url = this.getNewPaginationUrl(this.router.url);

    this.pagination = { ...this.pagination, page: page + 1 };
    this.router.navigate([url, this.pagination.page]);
  }

  public getNewPaginationUrl(oldUrl: string): string {
    return oldUrl.split('/').slice(0, -1).join('/');
  }


  private showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  public actionsEvent(e: string): void {
    if (this[e]) {
      this[e]();
    }
  }

  public onExportCsvClick(): void {
    const currentDate = this.datePipe.transform(new Date(), 'yyMMdd_HHmm');
    this.returnStockStatusService.getReturnStockStatusListExportFile(`return_stock_status_${currentDate}`)
      .pipe()
      .subscribe((fileParams: FileUploadParams) => {
        const {url, title, type} = fileParams;
        this.downloadFile(url, title, type);
      }, error => {
        this.showMsg('error', error.error.message || error.error.errors);
      });
  }

  public downloadFile(url, title, type) {
    this.fileService.downloadFile(url)
      .subscribe((res: any) => {
        this.fileSaverService.save(res.body, getContentDispositionFileName(res.headers.get('Content-Disposition')));
      });
  }

  public get showSummaryBar(): boolean {
    return !!this.tableSummaryBarItems.length;
  }

  get translatedProductTypeBy(): string {
    return this.productDataBy === 'documents'
      ? 'TABLE_SUMMARY_BAR.DOCUMENTS'
      : 'BUTTON.BINS';
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
