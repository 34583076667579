export const CHANGE_CORRECTION_CALCULATION_MODAL_DATA = {
  title: 'MODALS.CORRECTION_AMOUNT_TITLE',
  message: 'MODALS.CORRECTION_AMOUNT_MSG',
  confirmBtnText: 'BUTTON.CONTINUE',
  confirmBtnIcon: 'edit'
};

export const REMOVE_POSITION_MODAL_DATA = {
  title: 'MODAL.REMOVE_POSITION',
  message: 'MODAL.REMOVE_POSITION_MSG',
  confirmBtnText: 'BUTTON.REMOVE',
  confirmBtnIcon: 'minus',
};

export const REMOVE_POSITION_PARENT_MODAL_DATA = {
  title: 'PRODUCTS_VARIATIONS.REMOVE_PARENT_PRODUCT_TITLE',
  message: 'PRODUCTS_VARIATIONS.REMOVE_PARENT_PRODUCT_MSG',
  confirmBtnText: 'BUTTON.REMOVE',
  confirmBtnIcon: 'minus',
};

export const REMOVE_POSITION_NECESSARILY_MODAL_DATA = {
  title: 'PRODUCTS_VARIATIONS.REMOVE_RELATED_PRODUCT_TITLE',
  message: 'PRODUCTS_VARIATIONS.REMOVE_RELATED_PRODUCT_MSG',
  confirmBtnText: 'BUTTON.REMOVE',
  confirmBtnIcon: 'minus',
};

export const REMOVE_POSITION_PARENT_OR_NECESSARILY_MODAL_DATA = {
  title: 'PRODUCTS_VARIATIONS.REMOVE_PARENT_POSITIONS_TITLE',
  message: 'PRODUCTS_VARIATIONS.REMOVE_PARENT_POSITIONS_MSG',
  confirmBtnText: 'BUTTON.REMOVE',
  confirmBtnIcon: 'minus',
};

export const CHANGE_PARENT_QUANTITY_MODAL_DATA = {
  title: 'PRODUCTS_VARIATIONS.CHANGE_PARENT_QUANTITY_TITLE',
  message: 'PRODUCTS_VARIATIONS.CHANGE_PARENT_QUANTITY_MSG',
  confirmBtnText: 'BUTTON.CONTINUE',
  confirmBtnIcon: 'arrow-right'
};
