import { NavBarBtnModel, UIStatesEnum } from 'common/src/models';
import { ProductTypes } from 'common/src/modules/products/product-types';
import { TradeOfferListTabsEnum } from '../../enums';
import { UNITS_LIST_BY_PRODUCT_TYPE } from 'common/src/modules/rnpl-common/constants';
import { VAT_LIST } from '../../../shared/constants';
import {
  ControlTypeEnum,
  InputDropdownTypeEnum,
  PositionInfoListModel,
  PositionItemTypeEnum
} from 'common/src/modules/position-card/position-card-models.model';
import { TranslateService } from '@ngx-translate/core';

export const PositionsButtons: NavBarBtnModel[] = [
  {
    classes: 'btn-white red',
    text: 'BUTTON.REMOVE',
    multipleText: 'BUTTON.REMOVE',
    badgeClass: 'rnpl-badge--red',
    iconName: 'minus-circle',
    actionName: 'onDeletePositionsClick',
    multiple: false
  }
];

export function generatePositionsCardsInfo(
  translateService: TranslateService,
  productType: ProductTypes,
  state: UIStatesEnum,
  documentStatus: TradeOfferListTabsEnum,
  vatBlocked: boolean,
): PositionInfoListModel[] {
  const cantEditColumn = (state === UIStatesEnum.VIEW);

  let positionInfoList: PositionInfoListModel[] = [
    {
      label: 'COLUMN.TYPE',
      prop: 'arbitraryProductType',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.ARBITRARY_POSITION_TYPE,
      hideWithProductType: [ProductTypes.GOODS, ProductTypes.SERVICES, ProductTypes.DIGITAL],
      showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE]
    },
    {
      label: 'COLUMN.NAME',
      prop: 'description',
      placeholder: 'FORM.ENTER_NAME',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT,
      hideWithProductType: [ProductTypes.GOODS, ProductTypes.SERVICES, ProductTypes.DIGITAL, ProductTypes.CORRECTION, ProductTypes.OVERDUE_FEE],
      showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE]
    },
    {
      label: 'FORM.DESCRIPTION',
      prop: 'productDescription',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.TEXTAREA,
      itemClass: 'col-12',
      isOptional: true,
      hideWithProductType: [ProductTypes.SERVICES, ProductTypes.ARBITRARY],
      showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE]
    },
    // {
    //   label: 'COLUMN.POSITION_NAME',
    //   prop: 'description',
    //   type: PositionItemTypeEnum.WITH_CONTROL,
    //   controlType: ControlTypeEnum.TEXTAREA,
    //   itemClass: 'col-12',
    //   hideWithProductType: [ProductTypes.GOODS, ProductTypes.DIGITAL, ProductTypes.SERVICES],
    //   showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE]
    // },
    {
      label: 'FORM.DESCRIPTION',
      prop: 'providedServices',
      isOptional: true,
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.TEXTAREA,
      itemClass: 'col-12',
      hideWithProductType: [ProductTypes.GOODS, ProductTypes.DIGITAL],
      showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE]
    },
    {
      label: 'COLUMN.QUANTITY',
      prop: 'quantity',
      unitType: 'unitType',
      type: cantEditColumn ? PositionItemTypeEnum.NUMBER : PositionItemTypeEnum.WITH_CONTROL,
      // controlType: productType !== ProductTypes.ARBITRARY ? ControlTypeEnum.INPUT_DROPDOWN : ControlTypeEnum.UNIT_TYPE,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.NUMBER,
      // listItems: UNITS_LIST_BY_PRODUCT_TYPE[productType]
    },
    {
      label: 'COLUMN.UNIT_PRICE_NET',
      prop: 'netUnitPrice',
      suffix: '€',
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      showUnitOnly: true,
    },
    {
      label: 'COLUMN.AMOUNT_NET',
      prop: 'netTotalPrice',
      suffix: '€',
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      showUnitOnly: true,
    },
    {
      label: 'COLUMN.VAT',
      prop: 'vat',
      suffix: '%',
      type: cantEditColumn ? PositionItemTypeEnum.PRIMARY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.DROPDOWN,
      listItems: VAT_LIST,
      bindLabel: 'label',
      bindValue: 'value',
      disabled: vatBlocked,
      vatBlocked: vatBlocked,
    },
    {
      label: 'COLUMN.UNIT_PRICE_GROSS',
      prop: 'grossUnitPrice',
      suffix: '€',
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      showUnitOnly: true,
    },
    {
      label: 'COLUMN.AMOUNT_GROSS',
      prop: 'grossTotalPrice',
      suffix: '€',
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      valueClass: 'font-weight-500',
      showUnitOnly: true,

    },
    {
      label: '',
      prop: '',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.DISCOUNT,
    },
    {
      label: 'COMMON.INTERNAL_REMARK_1',
      prop: 'remark',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.REMARK,
      bindValue: 'value',
    },
    {
      label: 'COLUMN.MARGIN',
      prop: 'marginData',
      suffix: '€',
      type: PositionItemTypeEnum.PROFIT,
      currencyConversion: true,
      hideWithProductType: [ProductTypes.DIGITAL, ProductTypes.ARBITRARY],
      showWithDocumentStatus: [
        TradeOfferListTabsEnum.DRAFT,
        TradeOfferListTabsEnum.OPEN,
        TradeOfferListTabsEnum.ACCEPTED,
      ],
      showWithUIState: [UIStatesEnum.VIEW]
    },
    {
      label: 'COLUMN.MARGIN',
      prop: 'marginData',
      suffix: '€',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.PROFIT,
      currencyConversion: true,
      hideWithProductType: [ProductTypes.DIGITAL, ProductTypes.ARBITRARY],
      showWithDocumentStatus: [
        TradeOfferListTabsEnum.DRAFT,
        TradeOfferListTabsEnum.OPEN,
        TradeOfferListTabsEnum.ACCEPTED,
      ],
      showWithUIState: [UIStatesEnum.CREATE, UIStatesEnum.EDIT]
    },
  ];

  positionInfoList = positionInfoList
    .filter((item) => {
      if (item.hideWithProductType) {
        return !item.hideWithProductType.includes(productType);
      }
      return true;
    }).filter((item) => {
      if (item.showWithUIState) {
        return item.showWithUIState.includes(state);
      }
      return true;
    }).filter((item) => {
      if (item.showWithDocumentStatus) {
        return item.showWithDocumentStatus.includes(documentStatus);
      }
      return true;
    });

  return positionInfoList;

}
