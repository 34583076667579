import { Injectable } from '@angular/core';
import { selectCompanyProfile } from './administration/store/selectors';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { isEmpty, isEqual } from 'lodash';
import { ActivatedRoute } from '@angular/router';

import { CompanyProfile } from './administration/models/company-profile.model';
import { ActivateSubscriptionModalComponent } from 'common/src/modules/modals/modals-subscription-activation';
import { SubscriptionActivationComponent } from 'common/src/modules/subscription-activation/subscription-activation/subscription-activation.component';
import { AuthUserPermissionsEnum } from 'common/src/auth/auth-user-permissions.enum';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { AuthUserModel } from 'common/src/auth/auth-user.model';
import { AuthService } from 'common/src/auth/auth.service';
import { AppState } from './store/state/app.state';
import { SubscriptionActivationTabEnum } from 'common/src/modules/subscription-activation/subscription-activation.emum';

@Injectable({providedIn: 'root'})
export class AppService {

  public profile: CompanyProfile;

  constructor(
    private activatedRoute: ActivatedRoute,
    private toasterService: ToasterService,
    private dialog: MatDialog,
    private authService: AuthService,
    private readonly store: Store<AppState>,
    ) {
    this.selectCompanyProfileFromStore();

    this.activatedRoute.queryParams.subscribe(params => {
      if (params.hasOwnProperty('activationSuccess')) {
        this.toasterService.notify({
          type: 'activate-subscription',
          icon: 'checkmark-circle',
          title: 'SUBSCRIPTION_PLAN.YOUR_SUBSCRIPTION_IS_NOW_ACTIVE',
          message: 'SUBSCRIPTION_PLAN.YOUR_SUBSCRIPTION_IS_NOW_ACTIVE_DESCR',
        });
      }
    });
  }

  public selectCompanyProfileFromStore() {
    this.store
      .select(selectCompanyProfile)
      .pipe(
        debounceTime(100), // wait for this.activatedRoute.queryParams update
        distinctUntilChanged(isEqual),
        filter(profile => !isEmpty(profile)),
      )
      .subscribe((profile: CompanyProfile) => {
        this.profile = profile;
        this.checkCompanySubscription();
      });
  }

  public checkCompanySubscription(): void {
    const loggedUser: AuthUserModel = this.authService.getUser();
    if (!loggedUser) { return; }

    const activateSubscriptionModal = this.dialog.getDialogById('subscriptionActivationModal');
    const dialogExist = this.dialog.getDialogById('activateSubscriptionModal');
    const subscriptionStatus: 'TRIAL'|'ACTIVATED'|'CANCELED' = this.profile.subscriptionManagement.subscriptionStatus;

    if (!dialogExist && !activateSubscriptionModal) {
      if (this.profile.status === 'PAUSED' && this.profile.entityState === 'EXISTS') {
        let modalType: 'workspaceLocked'|'expiredTrial';

        if (subscriptionStatus === 'TRIAL') { modalType = 'expiredTrial'; }
        if (subscriptionStatus === 'CANCELED') { modalType = 'workspaceLocked'; }

        if (this.profile.status === 'PAUSED' && subscriptionStatus === 'ACTIVATED') {
          this.dialog.open(SubscriptionActivationComponent, {
            id: 'subscriptionActivationModal',
            panelClass: 'launchpad-modal',
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            hasBackdrop: false,
            disableClose: true,
            closeOnNavigation: false,
            data: {
              activeTab: SubscriptionActivationTabEnum.CARD_DETAILS,
              initStripe: true
            }
          });
          return;
        }

        this.dialog.open(ActivateSubscriptionModalComponent, {
          data: {
            modalType,
            wid: this.profile.workspaceId,
            isAdmin: loggedUser.permissions.administration.includes(AuthUserPermissionsEnum.ADMIN),
            isAccountant: loggedUser.permissions.accounting &&
              (
                loggedUser.permissions.accounting.includes(AuthUserPermissionsEnum.ADMIN) ||
                loggedUser.permissions.accounting.includes(AuthUserPermissionsEnum.WRITE) ||
                loggedUser.permissions.accounting.includes(AuthUserPermissionsEnum.READ)
              )
          },
          disableClose: true,
          closeOnNavigation: false,
          id: 'activateSubscriptionModal',
          backdropClass: 'drop-sign-in',
          panelClass: 'sign-in-modal',
        });
        return;
      }

      if ((subscriptionStatus === 'TRIAL' && this.profile.status === 'PAUSED') && !this.profile.subscriptionManagement.master) {
        if (loggedUser.permissions.administration.includes(AuthUserPermissionsEnum.ADMIN)) {
          this.dialog.open(SubscriptionActivationComponent, {
            id: 'subscriptionActivationModal',
            hasBackdrop: false,
            disableClose: true,
            closeOnNavigation: false,
            panelClass: 'launchpad-modal',
            width: '100%',
            maxWidth: '100%',
            height: '100%'
          });
        }
      }
    }
  }

  public get showSideNav(): boolean {
    if (!this.profile) { return null; }
    const showSideNav = this.profile.subscriptionManagement.subscriptionPlanActive === 'FREE'
      || this.profile.subscriptionManagement.subscriptionPlanActive === 'ACCOUNTING_MONTHLY'
      || this.profile.subscriptionManagement.subscriptionPlanActive === 'ACCOUNTING_ANNUALLY';
    const headerHeight = showSideNav
      ? '0px'
      : '48px';

    // document.documentElement.style.setProperty('--header-height', '0px');
    // return true;
    document.documentElement.style.setProperty('--header-height', headerHeight);
    return showSideNav;
  }

}
