import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ShepherdService } from 'angular-shepherd';
import { finalize, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { get } from 'lodash';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ProductTypes } from '../../../products/product-types';
import { CreditNoteApiService } from 'projects/workspace/src/app/credit-note/services/credit-note-api.service';
import { CreditNotePositionModel } from 'projects/workspace/src/app/credit-note/models/credit-note-position.model';
import { CustomSearchFn } from '../../../rnpl-common/helpers';
import { PauseModalsHintsComponent } from '../../modals-subscription/pause-modals-hints/pause-modals-hints.component';
import { VAT_LIST } from 'projects/workspace/src/app/shared/constants';
import { DocumentTypesUppercaseEnum } from '../../modals-common/link-document-modal/enums/ducument-types.enum';
import { RecalculatePositionApiService } from '../../../../services/recalculate-position-api.service';
import { CORRECTION_POSITION_TYPES_LIST } from 'projects/workspace/src/app/accounting/accounting.constants';
import { CreditNotePurposeEnum } from 'projects/workspace/src/app/credit-note/enums';
import { CommonModalsActionsEnum, WarningModalComponent } from '../../modals-common';
import { CHANGE_CORRECTION_CALCULATION_MODAL_DATA } from '../../modals.contsans';
import { PositionAccountingSettings } from 'projects/workspace/src/app/outgoing-invoice/models';
import { PositionAccountingSettingsComponent } from 'projects/workspace/src/app/shared/components';
import { PurposeTypeEnum } from 'projects/workspace/src/app/purchase-order/enums';
import { ProductsService } from '../../../products';
import { ProductUnitModel } from 'projects/workspace/src/app/shared/models';
import { ProductUnitApiService } from 'projects/workspace/src/app/shared/services';

@Component({
  selector: 'rnpl-credit-note-position-modal',
  templateUrl: './credit-note-position-modal.component.html',
  styleUrls: ['./credit-note-position-modal.component.scss']
})
export class CreditNotePositionModalComponent extends BaseModalComponent implements OnInit {

  public selectedProductInvalid: boolean = false;
  public selectedProduct: Partial<CreditNotePositionModel> = null;
  public productsList: CreditNotePositionModel[] = [];
  public selectedProductType: ProductTypes = ProductTypes.GOODS;
  public accountingSettings: PositionAccountingSettings;

  public purposeTypeEnum = PurposeTypeEnum;
  public productTypes: typeof ProductTypes = ProductTypes;
  public productNameInvalid: boolean = false;
  public allowFractionalValues: boolean = true;
  public positionName: string = null;
  public form: FormGroup;
  public readonly vatList = VAT_LIST;
  public correctionPositionTypesList = CORRECTION_POSITION_TYPES_LIST;

  public customSearchFn = CustomSearchFn;

  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly addPositionRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  @ViewChild('hint', { static: false }) hint: PauseModalsHintsComponent;
  @ViewChild('positionAccountingSettings', { static: false }) positionAccountingSettingsRef: PositionAccountingSettingsComponent;


  constructor(
    public toasterService: ToasterService,
    public translateService: TranslateService,
    public creditNoteApiService: CreditNoteApiService,
    public productsService: ProductsService,
    public productUnitApiService: ProductUnitApiService,
    public dialogRef: MatDialogRef<CreditNotePositionModalComponent>,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private recalculatePositionApiService: RecalculatePositionApiService,
    @Inject(MAT_DIALOG_DATA) public data: {
      creditNoteId: number,
      isIncomingCorrection: boolean,
      vatBlocked: boolean,
      purpose: CreditNotePurposeEnum,
      disabledAccountingSettings: boolean,
    },
    private shepherdService: ShepherdService
  ) {
    super(toasterService);
  }

  ngOnInit() {
    this.initForm(this.selectedProduct);
    if (this.shepherdService.isActive) {
      this.shepherdService.cancel();
      this.shepherdService.complete();
    }

    this.getPositions();
  }

  private getPositions(): void {
    this.creditNoteApiService.getAvailableCreditNotePositions(this.data.creditNoteId)
      .pipe(
        finalize(() => {
          this.isLoading$.next(false);
          this.showDropdownSpin$.next(false);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(positionsByProductType => {
        for (const key in positionsByProductType) {
          if (positionsByProductType.hasOwnProperty(key)) {
            this.productsList.push(...positionsByProductType[key]);
          }
        }
        this.productsList = [...this.productsList];
      });
  }

  public addPosition(): void {
    if (this.addPositionRequest$.getValue()) { return; }

    if (!this.selectedProduct) {
      this.selectedProductInvalid = true;
      return;
    }

    const isDefaultPositionType = this.selectedProductType !== ProductTypes.CORRECTION;
    const isCorrectionPositionType = this.selectedProductType === ProductTypes.CORRECTION;

    const position: CreditNotePositionModel = {
      ...this.form.getRawValue(),
      positionId: this.selectedProduct.positionId,
      productTypeForGrouping: this.selectedProductType,
      generalProductId: isDefaultPositionType ? +this.selectedProduct.generalProductId : null,
      productId: isDefaultPositionType ? +this.selectedProduct.productId : null,
      runpleId: isDefaultPositionType ? this.selectedProduct.runpleId : null,
      productType: this.selectedProductType,
      vat: this.vat.value,
      sourcePositionId: this.selectedProduct.positionId,
      productDescription: !this.isProductTypeServices ? this.productDescription.value : null,
      providedServices: this.isProductTypeServices ? this.providedServices.value : null,
      grossUnitPrice: this.selectedProduct.grossUnitPrice,
      netUnitPrice: this.selectedProduct.netUnitPrice,
      accountingSettings: {
        ...this.accountingSettings,
        ...(this.positionAccountingSettingsRef && this.positionAccountingSettingsRef.getFormValue())
      }
    };

    if (isCorrectionPositionType) {
      if (!this.description.valid) {
        this.productNameInvalid = true;
        return;
      }
    }

    this.creditNoteApiService.createPositionByPredefinedForm(this.data.creditNoteId, position)
      // .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.dialogRef.close(response);
        this.getCreditNoteById();
      });
  }

  public getCreditNoteById() {
    this.creditNoteApiService.getCreditNoteById(this.data .creditNoteId).subscribe();
  }

  public displayCorrectionPosition(): void {
    this.changeProductType();
    this.selectedProductType = ProductTypes.CORRECTION;
    this.selectedProduct = { description: this.translateService.instant('FORM.CORRECTION') };
    this.initForm();
    this.correctionType.setValue('NEGATIVE');
    this.description.setValidators(Validators.required);

    if (!this.data.isIncomingCorrection) {
      this.correctionType.disable({ onlySelf: true, emitEvent: false });
    }

    if (this.data.vatBlocked) {
      this.vatTotal.disable({onlySelf: true, emitEvent: false});
      this.vat.setValue(0);
    }

    // wait <rnpl-position-accounting-settings> to draw
    // init position predefined data
    setTimeout(() => this.updatePosition(null, 'resolveRevenueGL'), 200);
  }

  public changeProductType(): void {
    this.selectedProduct = null;
    this.positionName = null;
    this.productNameInvalid = false;
    this.selectedProductInvalid = false;
  }

  public initForm(selectProduct: Partial<CreditNotePositionModel> = {} as Partial<CreditNotePositionModel>): void {
    this.form = this.fb.group({
      sourceAmountGross: [],
      netTotalPrice: [null, Validators.required],
      productDescription: [get(selectProduct, 'productDescription', '')],
      providedServices: [get(selectProduct, 'providedServices', '')],
      description: [get(selectProduct, 'description')],
      unitType: [get(selectProduct, 'unitType') || 'pcs'],
      netUnitPrice: [],
      sourceAmountNet: [],
      vat: [get(selectProduct, 'vat')],
      quantity: [0],
      remark: [],
      addRemark: [false],
      specifyAmountsSeparately: [],
      correctionType: [],
      vatTotal: [],
      grossTotalPrice: [null, Validators.required],
      productDescriptionUpdated: [false]
    });
  }

  selectPositionChanges(selectedProduct: Partial<CreditNotePositionModel>): void {
    this.selectedProductType = selectedProduct.productType;
    this.accountingSettings = null;
    if (this.positionAccountingSettingsRef) {
      this.positionAccountingSettingsRef.resetForm();
    }
    this.accountingSettings = selectedProduct.accountingSettings;

    this.initForm(selectedProduct);
    this.description.clearValidators();
    if (this.data.vatBlocked) {
      this.vat.setValue(0);
      this.vat.disable({onlySelf: true, emitEvent: false});
      this.vatTotal.disable({onlySelf: true, emitEvent: false});
    }
    if (this.isPrepaymentProductType) {
      this.quantity.patchValue(1);
      this.vat.disable({onlySelf: true, emitEvent: false});
      this.netTotalPrice.patchValue(selectedProduct.netTotalPrice, {onlySelf: true, emitEvent: false});
      this.grossTotalPrice.patchValue(selectedProduct.grossTotalPrice, {onlySelf: true, emitEvent: false});
      this.netTotalPrice.disable({onlySelf: true, emitEvent: false});
      this.grossTotalPrice.disable({onlySelf: true, emitEvent: false});
    }
    this.getUnitTypes();
  }

  public getUnitTypes(): void {
    this.productUnitApiService.getUnits$(this.selectedProductType)
      .pipe(takeUntil(this.destroy$))
      .subscribe((unitsList: ProductUnitModel[]) => {
        const allowFractionalValues = unitsList.find(u => u.name === this.unitType.value) &&
          unitsList.find(u => u.name === this.unitType.value).allowFractionalValues;
        this.allowFractionalValues = allowFractionalValues && (!this.isProductTypeGoods || this.isGeneralProductType);
      });
  }

  public updatePosition(value, fieldName, force: boolean = false): void {
    this.addPositionRequest$.next(true);

    if (fieldName === 'specifyAmountsSeparately' && !value && !force) {
      if (
        (this.netTotalPrice.value && (this.netTotalPrice.value !== '0.00')) ||
        (this.grossTotalPrice.value && (this.grossTotalPrice.value !== '0.00')) ||
        (this.vatTotal.value && (this.vatTotal.value !== 0))
      ) {
        const dialog = this.dialog.open(WarningModalComponent, {
          data: CHANGE_CORRECTION_CALCULATION_MODAL_DATA
        });

        dialog.afterClosed().subscribe(res => {
          if (res === CommonModalsActionsEnum.CONFIRM) {
            this.updatePosition(value, fieldName, true);
          } else {
            this.specifyAmountsSeparately.setValue(true);
          }
        });
        return;
      }
    }

    this.recalculatePositionApiService.recalculatePosition(DocumentTypesUppercaseEnum.CRN, {
      protoPosition: {
        ...this.form.getRawValue(),
        productId: this.selectedProduct.productId,
        vat: this.vat.value,
        sourcePositionId: this.selectedProduct.positionId,
        productTypeForGrouping: this.selectedProductType,
        productType: this.selectedProductType,
        productDescription: !this.isProductTypeServices ? this.productDescription.value : null,
        providedServices: this.isProductTypeServices ? this.providedServices.value : null,
        grossUnitPrice: this.selectedProduct.grossUnitPrice,
        netUnitPrice: this.selectedProduct.netUnitPrice,
        correctionType: this.isCorrectionProductType ? 'NEGATIVE' : this.correctionType.value,
        accountingSettings: {
          ...this.accountingSettings,
          ...(this.positionAccountingSettingsRef && this.positionAccountingSettingsRef.getFormValue())
        }
      },
      fieldUpdateRequest: {
        fieldName: fieldName,
        fieldValue: value
      },
      type: this.selectedProductType,
      crnPurpose: this.data.purpose,
      documentId: this.data.creditNoteId,
    })
      // .pipe(takeUntil(this.destroy$))
      .subscribe(product => {
        this.addPositionRequest$.next(false);
        this.form.patchValue(product);
      });
  }

  public updateDescription(description: string): void {
    this.selectedProduct = {
      ...this.selectedProduct,
      productDescription: description,
      providedServices: description,
    };
  }

  public updateDescriptionByProductType(): void {
    if (this.isProductTypeServices) {
      this.updatePosition(this.providedServices.value, 'providedServices');
    } else {
      this.updatePosition(this.productDescription.value, 'productDescription');
    }
  }

  get isProductTypeGoods(): boolean {
    return this.selectedProductType === ProductTypes.GOODS;
  }

  get isProductTypeServices(): boolean {
    return this.selectedProductType === ProductTypes.SERVICES;
  }

  get isProductTypeDigital(): boolean {
    return this.selectedProductType === ProductTypes.DIGITAL;
  }

  get isCorrectionProductType(): boolean {
    return this.selectedProductType === ProductTypes.CORRECTION;
  }

  get isPrepaymentProductType(): boolean {
    return this.selectedProductType === ProductTypes.PREPAYMENT;
  }

  public isOinOrIinCorrection(): boolean {
    return this.data.purpose === CreditNotePurposeEnum.OIN_CORRECTION ||
           this.data.purpose === CreditNotePurposeEnum.IIN_CORRECTION;
  }

  get isIinCorrection(): boolean {
    return this.data.purpose === CreditNotePurposeEnum.IIN_CORRECTION;
  }
  get isGeneralProductType(): boolean {
    return this.selectedProduct.general;
  }

  get isLogistics(): boolean {
    return this.selectedProduct.logistics;
  }

  get isPurposeInternal(): boolean {
    return this.selectedProduct.purpose === PurposeTypeEnum.INTERNAL_USAGE;
  }

  get specifyRemark(): FormControl { return this.form.get('addRemark') as FormControl; }
  get netTotalPrice(): FormControl { return this.form.get('netTotalPrice') as FormControl; }
  get netUnitPrice(): FormControl { return this.form.get('netUnitPrice') as FormControl; }
  get productDescription(): FormControl { return this.form.get('productDescription') as FormControl; }
  get providedServices(): FormControl { return this.form.get('providedServices') as FormControl; }
  get description(): FormControl { return this.form.get('description') as FormControl; }
  get quantity(): FormControl { return this.form.get('quantity') as FormControl; }
  get remark(): FormControl { return this.form.get('remark') as FormControl; }
  get vat(): FormControl { return this.form.get('vat') as FormControl; }
  get sourceAmountNet(): FormControl { return this.form.get('sourceAmountNet') as FormControl; }
  get sourceAmountGross(): FormControl { return this.form.get('sourceAmountGross') as FormControl; }
  get specifyAmountsSeparately(): FormControl { return this.form.get('specifyAmountsSeparately') as FormControl; }
  get correctionType(): FormControl { return this.form.get('correctionType') as FormControl; }
  get vatTotal(): FormControl { return this.form.get('vatTotal') as FormControl; }
  get grossTotalPrice(): FormControl { return this.form.get('grossTotalPrice') as FormControl; }
  get unitType(): FormControl { return this.form.get('unitType') as FormControl; }

}
