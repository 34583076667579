import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { CustomerTypeEnum, PaginationModel } from 'common/src/models';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { AppState } from '../../store/state/app.state';
import { environment } from '../../../environments/environment';
import { ResponseList, ResponseModel } from '../../shared/models/response';
import {
  TimeTrackingBillingScheduleModel,
  TimeTrackingListTotalsModel,
  TimeTrackingProductModel,
  TimeTrackingRecordModel,
  TimeTrackingSettingsModel
} from '../models';
import { TimeTrackingListTabsEnum } from '../enums';
import { FilterModelNew } from '../../outgoing-invoice/models/filter-model-new';
import { OutgoingInvoiceModel } from '../../outgoing-invoice/models';
import { LoadRecordsList } from '../store/actions/time-tracking.actions';
import { FileUploadParams } from 'common/src/models/file-upload-params.model';
import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { ChangeStatusOperationsEnum } from '../../outgoing-invoice/enums';
import { RoundingEnum } from 'common/src/modules/modals/modals-time-tracking/time-tracking-log-time-modal/time-tracking-log-time-modal.config';
import { DEFAULT_SORT_DIRECTION } from '../../shared/constants';
import { selectCompanyProfile } from '../../administration/store/selectors';
import { CompanyProfile } from '../../administration/models/company-profile.model';

@Injectable({
  providedIn: 'root'
})
export class TimeTrackingApiService {
  private readonly apiEndpoint: string = `${environment.javaServicesApiVersion}`;

  private wid: number;

  private recordsApiUrl(url: string = ''): string {
    return this.apiEndpoint + '/records' + url;
  }

  private billingApiUrl(url: string = ''): string {
    return this.apiEndpoint + '/billing' + url;
  }

  constructor(
    private readonly toasterService: ToasterService,
    private readonly translateService: TranslateService,
    private readonly http: HttpClient,
    private readonly store: Store<AppState>,
  ) {
    this.store.select(selectCompanyProfile)
      .subscribe((companyProfile: CompanyProfile) => {
        this.wid = companyProfile.workspaceId;
      });
  }

  public getScheduleBilling(): Observable<TimeTrackingBillingScheduleModel> {
    return this.http.get<ResponseModel<TimeTrackingBillingScheduleModel>>(this.billingApiUrl('/schedule'))
      .pipe(
        map((response: ResponseModel<TimeTrackingBillingScheduleModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getScheduleBillingPartnersList(): Observable<any> {
    return this.http.get<ResponseModel<any>>(this.billingApiUrl('/partners'))
      .pipe(
        map((response: ResponseModel<any>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getPartnersBillingList(): Observable<any> {
    return this.http.get<ResponseModel<any>>(this.apiEndpoint + '/partners/list')
      .pipe(
        map((response: ResponseModel<any>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public updateScheduleBilling(billingSchedule: Partial<TimeTrackingBillingScheduleModel>): Observable<TimeTrackingBillingScheduleModel> {
    const body = { ...billingSchedule };
    return this.http.request<ResponseModel<TimeTrackingBillingScheduleModel>>('post', this.billingApiUrl('/schedule'), { body })
      .pipe(
        map((response: ResponseModel<TimeTrackingBillingScheduleModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public createRecord(record: Partial<TimeTrackingRecordModel>): Observable<TimeTrackingRecordModel> {
    const body = { ...record };
    return this.http.request<ResponseModel<TimeTrackingRecordModel>>('post', this.recordsApiUrl(), { body })
      .pipe(
        map((response: ResponseModel<TimeTrackingRecordModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public updateRecord(recordId: number, record: Partial<TimeTrackingRecordModel>): Observable<TimeTrackingRecordModel> {
    const body = { ...record };
    return this.http.request<ResponseModel<TimeTrackingRecordModel>>('patch', this.recordsApiUrl(`/${recordId}`), { body })
      .pipe(
        map((response: ResponseModel<TimeTrackingRecordModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public deleteRecord(recordId: number): Observable<any> {
    return this.http.delete(this.recordsApiUrl(`/${recordId}`))
      .pipe(
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getPartnersList(
    status: TimeTrackingListTabsEnum,
    pagination: PaginationModel,
    sort: FilterModelNew,
    filters: any
  ): Observable<ResponseList<TimeTrackingRecordModel>> {
    const params = {
      page: pagination.page,
      sortBy: sort.sortBy,
      direction: sort.direction,
      length: pagination.per_page,
    }

    for (const [key, value] of Object.entries(filters)) {
      params[`filter.${key}`] = Array.isArray(value) ? value.join(',') : value
    }

    return this.http.get<ResponseList<TimeTrackingRecordModel>>(
      this.apiEndpoint + '/partners/', { params })
      .pipe(
        tap((data: ResponseList<TimeTrackingRecordModel>) => {
          this.store.dispatch(LoadRecordsList({
            recordsListData: {
              [data.pagination.page]: {
                pagination: data.pagination,
                sort,
                data: data.data
              }
            },
            status, page: data.pagination.page
          }));
        }),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getAllAvailablePartnersList(sort = { sortBy: 'createdAt', direction: DEFAULT_SORT_DIRECTION }): Observable<any> {
    return this.http.get<ResponseList<any>>(
      this.apiEndpoint + '/partners/',
      {
        params: {
          sortBy: 'id',
          direction: sort.direction,
          ['filter.hasOpen']: 'true'
        },
      })
      .pipe(
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getAllAvailableToOINProducts(partnerIds: number[]): Observable<TimeTrackingProductModel[]> {
    return this.http.get<ResponseModel<TimeTrackingProductModel[]>>(
      this.recordsApiUrl('/products'),
      {
        params: {
          ['filter.partnerIds']: partnerIds.join(','),
          ['filter.status']: TimeTrackingListTabsEnum.OPEN,
        },
      })
      .pipe(
        map(response => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getRecordsList(
    status: TimeTrackingListTabsEnum,
    pagination: PaginationModel,
    sort: FilterModelNew,
    filters: any
  ): Observable<ResponseList<TimeTrackingRecordModel>> {
    const params = {
      page: pagination.page,
      sortBy: sort.sortBy,
      direction: sort.direction,
      length: pagination.per_page,
    }

    for (const [key, value] of Object.entries(filters)) {
      params[`filter.${key}`] = Array.isArray(value) ? value.join(',') : value
    }

    return this.http.get<ResponseList<TimeTrackingRecordModel>>(this.recordsApiUrl('/'), { params })
      .pipe(
        tap((data: ResponseList<TimeTrackingRecordModel>) => {
          this.store.dispatch(LoadRecordsList({
            recordsListData: {
              [data.pagination.page]: {
                pagination: data.pagination,
                sort,
                data: data.data
              }
            },
            status, page: data.pagination.page
          }));
        }),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getRecordsListDetails(
    status: TimeTrackingListTabsEnum,
    filters: any,
    sort = { sortBy: 'createdAt', direction: DEFAULT_SORT_DIRECTION }
  ): Observable<TimeTrackingRecordModel[]> {
    const params = {
      ['filter.status']: status.toUpperCase(),
      sortBy: sort.sortBy,
      direction: sort.direction,
    }

    for (const [key, value] of Object.entries(filters)) {
      params[`filter.${key}`] = Array.isArray(value) ? value.join(',') : value
    }

    return this.http.get<ResponseList<TimeTrackingRecordModel>>(
      this.recordsApiUrl('/'), { params })
        .pipe(
          map((response: ResponseList<TimeTrackingRecordModel>) => response.data),
          catchError(error => {
            this.handlePopupErrors(error);
            return throwError(error);
          })
        );
  }

  public getOpenRecordsByPartner(filters: any, toProductUnits = false): Observable<TimeTrackingRecordModel[]> {
    const params = {
      ['filter.status']: TimeTrackingListTabsEnum.OPEN.toUpperCase(),
      ['filter.toProductUnits']: toProductUnits.toString(),
    }

    for (const [key, value] of Object.entries(filters)) {
      params[`filter.${key}`] = Array.isArray(value) ? value.join(',') : value
    }

    return this.http.get<ResponseList<TimeTrackingRecordModel>>(
      this.recordsApiUrl('/'),{ params })
      .pipe(
        map((response: ResponseList<TimeTrackingRecordModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getRecordById(recordId: number): Observable<TimeTrackingRecordModel> {
    return this.http.get<ResponseModel<TimeTrackingRecordModel>>(this.recordsApiUrl(`/${recordId}`))
      .pipe(
        map((response: ResponseModel<TimeTrackingRecordModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getTimeTrackingListCounts(): Observable<number> {
    return this.http.get<ResponseModel<number>>(this.recordsApiUrl(`/count`))
      .pipe(
        map((response: ResponseModel<number>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getTimeTrackingListTotals(filters: any = {}, wid = this.wid): Observable<TimeTrackingListTotalsModel> {
    const params = {
      wid: wid.toString()
    };

    for (const [key, value] of Object.entries(filters)) {
      params[`filter.${key}`] = Array.isArray(value) ? value.join(',') : value
    }
    return this.http.get<ResponseModel<TimeTrackingListTotalsModel>>(this.recordsApiUrl(`/totals`), {params})
      .pipe(
        map((response: ResponseModel<TimeTrackingListTotalsModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getTimeTrackingSettings(): Observable<TimeTrackingSettingsModel> {
    return this.http.get<ResponseModel<TimeTrackingSettingsModel>>(this.recordsApiUrl(`/settings`))
      .pipe(
        map((response: ResponseModel<TimeTrackingSettingsModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public updateTimeTrackingSettings(settings: TimeTrackingSettingsModel): Observable<TimeTrackingSettingsModel> {
    return this.http.post<ResponseModel<TimeTrackingSettingsModel>>(this.recordsApiUrl(`/settings`), {...settings})
      .pipe(
        map((response: ResponseModel<TimeTrackingSettingsModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public resetTimeTrackingSettings(): Observable<TimeTrackingSettingsModel> {
    return this.http.post<ResponseModel<TimeTrackingSettingsModel>>(this.recordsApiUrl(`/settings/reset-default`), {})
      .pipe(
        map((response: ResponseModel<TimeTrackingSettingsModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public deleteRecords(recordIds: number[]): Observable<any> {
    return  this.http.request('delete', this.recordsApiUrl(), { body: { ids: recordIds } })
      .pipe(
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public doRecordsAction(recordIds: number[], action: ChangeStatusOperationsEnum): Observable<any> {
    return  this.http.request('patch', this.recordsApiUrl(`/action/${action}`), { body: { ids: recordIds } })
      .pipe(
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public resolveRecordsIssues(recordIds: number[], partnerType: CustomerTypeEnum, partnerId: number): Observable<TimeTrackingRecordModel[]> {
    return  this.http.request<ResponseModel<TimeTrackingRecordModel[]>>('post', this.recordsApiUrl('/resolve'), {
      body: {
        recordIds,
        partnerType,
        partnerId,
      },
    })
      .pipe(
        map(response => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public createOINBasedOnTimeTrackingRecords(recordIds: number[], groupingFormData?: any): Observable<OutgoingInvoiceModel> {
    const body = {
      ids: recordIds,
    };

    const params = groupingFormData
      ? {
          grouping: groupingFormData.grouping,
          providedServicesEnabled: groupingFormData.providedServicesEnabled,
        }
      : {};
    return this.http.post<ResponseModel<OutgoingInvoiceModel>>(this.recordsApiUrl('/billing/create-oin'), body, { params })
      .pipe(
        map(res => res.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getRecordsListExport(
    opts?: {status?: string, createdWithin?: string, periodFrom?: string, periodTo?: string}
  ): Observable<FileUploadParams> {
    let url = `/export?status=${opts.status || 'open,billed'}`;

    if (opts.periodFrom) {
      url = `${url}&periodFrom=${opts.periodFrom}`;
    }
    if (opts.periodTo) {
      url = `${url}&periodTo=${opts.periodTo}`;
    }

    const fileParams: FileUploadParams = {
      url: this.recordsApiUrl(url),
      type: 'zip',
    };
    return of(fileParams);
  }

  public startTimeTrackingRecordEditing(recordId: number, force = false): Observable<any> { // EditModel
    const params = force ? new HttpParams().set('force', force.toString()) : {};

    return this.http.get<any>(`services/v1/utils/edit-flag/${DocumentTypesUppercaseEnum.TTR}/${recordId}/check-and-set`, {params})
      .pipe(
        catchError(error => {
          this.handlePopupErrors(error, recordId);
          return throwError(error);
        })
      );
  }

  public addRecordsToOIN(recordsIds: number[], invoiceId: number, force = false, groupingForm: any): Observable<OutgoingInvoiceModel> {
    const params = {
      force: force.toString(),
      invoiceId: invoiceId.toString(),
      ...groupingForm
    };

    const body = { ids: recordsIds };

    return this.http.request<ResponseModel<OutgoingInvoiceModel>>('post', this.recordsApiUrl('/billing/add-oin'), {
      params,
      body
    })
      .pipe(
        map(response => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public finishTimeTrackingRecordEditing(recordId: number): Observable<any> { // EditModel
    return this.http.get<any>(`services/v1/utils/edit-flag/${DocumentTypesUppercaseEnum.TTR}/${recordId}/commit`)
      .pipe(
        // tap(() => {
        //   this.store.dispatch(UpdateCreditNoteState({ currentState: UIStatesEnum.EDIT }));
        // }),
        catchError(error => {
          this.handlePopupErrors(error, recordId);
          return throwError(error);
        })
      );
  }

  public getRoundedTime(duration: number, unit: string, roundTo: RoundingEnum): Observable<number> { // EditModel
    const params = {
      duration: duration.toString(),
      unit,
      roundTo
    };
    return this.http.get<ResponseModel<number>>(`services/v1/utils/duration/round`, {params})
      .pipe(
        map((res) => res.data),
        catchError(error => {
          // this.handlePopupErrors(error, recordId);
          return throwError(error);
        })
      );
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  private handlePopupErrors(error: HttpErrorResponse, recordId?: number): void {
    if (error.error.errors && error.error.errors.length) {
      error.error.errors.forEach(errorText => {
        switch (errorText) {
          case 'anotherUserEditError':
            // this.showMsg('error', 'You cannot edit records created by other users.');
            this.showMsg('error', this.translateService.instant('MESSAGES.RECORD_EDITED_BY', { userName: error.error.data.userName}));
            // {
            //   const dialog = this.dialog.open(WarningModalComponent, {
            //     data: getAnotherUserEditErrorModalData({
            //       document: error.error.data.entityName,
            //       userName: error.error.data.userName,
            //     })
            //   });

            //   dialog.afterClosed().subscribe(res => {
            //     if (res === CommonModalsActionsEnum.CONFIRM) {
            //       // this.startCreditNoteEditing(+crnId, true).subscribe();
            //       // this.getCreditNoteById(+crnId).subscribe();
            //     }
            //   });
            // }
            break;
          case 'notEditModeError':
            this.showMsg('warning', this.translateService.instant('COMMON.DOC_UPDATED_BY_USER', { document: error.error.data.entityName}));
            // this.store.dispatch(UpdateCreditNoteState({ currentState: UIStatesEnum.VIEW }));
            // this.getCreditNoteById(+crnId).subscribe();
            break;
          default:
            this.showMsg('error', errorText);
            break
        }
      });
    }
  }

}
