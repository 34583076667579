import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { get, isEqual } from 'lodash';

import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { selectCountries, selectCreditNote, selectCreditNoteCurrentState } from 'projects/workspace/src/app/credit-note/store/selectors';
import { CreditNoteModel } from 'projects/workspace/src/app/credit-note/models';
import { CreditNotePurposeEnum, CreditNotesListTabsEnum } from 'projects/workspace/src/app/credit-note/enums';
import { UpdatePropertiesBlockValid } from 'projects/workspace/src/app/credit-note/store/actions/credit-note.actions';
import { CustomerTypeEnum, UIStatesEnum } from '../../../../models';
import { DocumentTypesUppercaseEnum } from '../../modals-common/link-document-modal/enums/ducument-types.enum';
import { PaymentListTabsEnum } from 'projects/workspace/src/app/payment/enums';
import { FormInputChangedModel } from 'projects/workspace/src/app/shared/models';
import { CreditNoteApiService } from 'projects/workspace/src/app/credit-note/services/credit-note-api.service';
import { PartnersTypeEnum } from 'projects/workspace/src/app/partners/corporate/enums';
import { CountryModel } from '../../../rnpl-common';
import { AddressTypeEnum } from 'projects/workspace/src/app/sales-order/enums/address-type.enum';
import { CreditNoteFormsService } from 'projects/workspace/src/app/credit-note/services/credit-note-forms.service';
import { convertAddressToStringHelper } from '../../../rnpl-common/helpers';

@Component({
  selector: 'rnpl-credit-note-select-partner-modal',
  templateUrl: './credit-note-select-partner-modal.component.html'
})
export class CreditNoteSelectPartnerModalComponent implements OnInit {

  public countries: CountryModel[] = [];
  public form: FormGroup;
  public billingDataForm: FormGroup;
  public isReadonly: boolean = true;
  public creditNote: CreditNoteModel;
  public currentState: UIStatesEnum;
  public addressTypeEnum: typeof AddressTypeEnum = AddressTypeEnum;
  public addressTypeForGeneralPartner = [
    {value: AddressTypeEnum.USER_DEFINED, label: 'COMMON.NEW_ADDRESS'},
    {value: AddressTypeEnum.SIMPLIFIED, label: 'COMMON.USER_DEFINED_ADDRESS'},
  ];

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    public dialogRef: MatDialogRef<CreditNoteSelectPartnerModalComponent>,
    private fb: FormBuilder,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
    public creditNoteFormService: CreditNoteFormsService,
    private creditNoteApiService: CreditNoteApiService,
  ) { }

  ngOnInit() {
    this.trackCountry();
    this.trackCreditNoteChanges();
    this.trackCreditNoteStateChanges();
  }

  private trackCountry(): void {
    this.store.select(selectCountries)
      .pipe(takeUntil(this.destroy$))
      .subscribe((countries: CountryModel[]) => this.countries = countries);
  }

  private trackCreditNoteStateChanges(): void {
    this.store.select(selectCreditNoteCurrentState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: UIStatesEnum) => {
        this.currentState = state;
        this.isReadonly = state === UIStatesEnum.VIEW;
        this.setControlsState();
      });
  }

  private trackCreditNoteChanges(): void {
    this.store.select(selectCreditNote)
      .pipe(
        distinctUntilChanged(isEqual),
        takeUntil(this.destroy$)
      )
      .subscribe((creditNote: CreditNoteModel) => {
        this.creditNote = creditNote;
        this.initForm();
        this.form.patchValue(creditNote);
        this.creditNoteFormService.initForm(creditNote);
        this.billingDataForm = this.creditNoteFormService.form;
        this.contactIdControl.patchValue(get(creditNote, 'properties.contact.id'));
        this.store.dispatch(UpdatePropertiesBlockValid({ propertiesBlockValid: this.form.valid }));
        this.setControlsState();
      });
  }

  private initForm(): void {
    this.form = this.fb.group({
      properties: this.fb.group({
        partner: [null, [Validators.required]],
        partnerType: [],
        partnerName: [null, [Validators.required]],
        partnerVat: [],
        phone: [],
        sendEmailStatus: this.fb.group({
          emailRecipient: [],
        }),
      }),
      contactId: [],
    }, {updateOn: 'blur'});
  }

  private setControlsState(): void {
    const opts = {emitEvent: false};

    if (this.isView()) {
      this.form.disable(opts);
      this.billingDataForm.disable(opts);
      // this.creditNoteFormService.billingAddress.disable(opts);
      return;
    } else {
      this.form.enable(opts);
      this.billingDataForm.enable(opts);
      // this.creditNoteFormService.billingAddress.enable(opts);
      this.cdr.detectChanges();

      if (get(this, 'creditNote.status') !== CreditNotesListTabsEnum.DRAFT) {
        this.form.get('properties').get('sourceDocument').get('documentId').disable(opts);
      }

      if (
        this.creditNote.properties.purpose === CreditNotePurposeEnum.COMMON_CORRECTION &&
        (this.hasLinkedIPBWithBookedStatus || this.hasLinkedOPBWithBookedStatus)
      ) {
        this.partner.disable(opts);
      }

      if (this.creditNote.properties.purpose === CreditNotePurposeEnum.OIN_CORRECTION) {
        this.partner.disable(opts);
        this.partnerName.disable(opts);
      }

      if (this.hasLinkedPayment && this.partnerType.value === PartnersTypeEnum.GENERAL) {
        this.partner.disable(opts);
        this.partnerType.disable(opts);
        this.partnerName.disable(opts);
      }

      if (get(this.creditNote, 'properties.partner.generalType') === 'inland') {
        this.billingAddress.get('country_iso3').disable(opts);
      } else {
        this.billingAddress.get('country_iso3').enable(opts);
      }
    }
  }

  public isView(): boolean {
    return (this.currentState === UIStatesEnum.VIEW);
  }

  public creditNoteFieldChanged(fieldValue, fieldName: string): void {
    if (fieldName === 'properties.companyContact.id') { fieldName = 'properties.contact'; }
    if (fieldName === 'properties.email') { fieldName = 'properties.sendEmailStatus.emailRecipient'; }

    this.creditNoteChanged({ fieldValue, fieldName });
  }

  public creditNoteChanged(event: FormInputChangedModel): void {
    this.creditNoteApiService.updateCreditNotePart(this.creditNote.id, event)
      .subscribe(); // updated via store
  }

  public creditNotePartnerChanged(partnerData): void {
    this.creditNoteFieldChanged(
      {
        type: partnerData.partnerType,
        id: partnerData.partnerId
      },
      'properties.' + partnerData.partnerIdFieldName
    );
  };

  public updateInput(fieldName: string, fieldValue: any): void {
    this.creditNoteChanged({fieldName, fieldValue});
  }

  public editAddress(): void {
    this.addressTemplateControl.setValue(AddressTypeEnum.USER_DEFINED);
    this.updateInput('billingInformation.addressTemplate', AddressTypeEnum.USER_DEFINED);
  }

  public get addressAsText(): string {
    if (!this.billingAddress) { return ''; }

    return convertAddressToStringHelper(this.billingAddress.value, this.countries);
  }

  get hasLinkedPayment(): boolean {
    if (!get(this, 'creditNote.linkedDocuments')) { return false; }
    return get(this, 'creditNote.linkedDocuments')
      .some(doc => (doc.type === DocumentTypesUppercaseEnum.IPB || doc.type === DocumentTypesUppercaseEnum.OPB));
  }

  get hasLinkedIPBWithBookedStatus(): boolean {
    if (!get(this, 'creditNote.linkedDocuments')) { return false; }
    return get(this, 'creditNote.linkedDocuments')
      .some(doc => (doc.type === DocumentTypesUppercaseEnum.IPB &&
        doc.status.toLowerCase() === PaymentListTabsEnum.BOOKED));
  }

  get hasLinkedOPBWithBookedStatus(): boolean {
    if (!get(this, 'creditNote.linkedDocuments')) { return false; }
    return get(this, 'creditNote.linkedDocuments')
      .some(doc => (doc.type === DocumentTypesUppercaseEnum.OPB &&
        doc.status.toLowerCase() === PaymentListTabsEnum.BOOKED));
  }

  get isPartnerTypeCorporate(): boolean {
    return this.partnerType.value === CustomerTypeEnum.CORPORATE_PARTNER;
  }

  get isPartnerTypeGeneral(): boolean {
    return this.partnerType.value === CustomerTypeEnum.GENERAL;
  }

  get partner(): FormControl { return this.form.get('properties').get('partner') as FormControl; }
  get partnerType(): FormControl { return this.form.get('properties').get('partnerType') as FormControl; }
  get partnerName(): FormControl { return this.form.get('properties').get('partnerName') as FormControl; }
  get partnerVat(): FormControl { return this.form.get('properties').get('partnerVat') as FormControl; }
  get phoneControl(): FormControl { return this.form.get('properties').get('phone') as FormControl; }
  get contactIdControl(): FormControl { return this.form.get('contactId') as FormControl; }
  get emailRecipientControl(): FormControl { return this.form.get('properties').get('sendEmailStatus').get('emailRecipient') as FormControl; }
  get billingAddress(): FormGroup { return this.billingDataForm.get('billingAddress') as FormGroup; }
  get addressTemplateControl(): FormControl { return this.billingDataForm.get('addressTemplate') as FormControl; }
  // get countryControl(): FormControl { return this.creditNoteFormService.billingAddress.get('country_iso3') as FormControl; }

}
