import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import {
  FileUploader,
  FileItem,
  FilterFunction,
  FileLikeObject
} from 'ng2-file-upload';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash';


import { ImageModel } from 'common/src/models';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { AuthService } from 'common/src/auth/auth.service';
import { AppContextService } from '../../services';
import { TrackInputChanges } from 'projects/workspace/src/app/shared/decorators/track-input-changes';
import { ChangesStrategy } from 'projects/workspace/src/app/shared/enums/change-strategy.enum';
import { CommonModalsActionsEnum, ConfirmModalComponent, WarningModalComponent } from '../../../modals/modals-common';
import {
  IncomingInvoiceOcrErrorModalComponent
} from '../../../modals/modals-incoming-invoice/incoming-invoice-ocr-error-modal/incoming-invoice-ocr-error-modal.component';
import { FileSizePipe } from '../../pipes';
import { ImageCropperModalComponent } from 'projects/workspace/src/app/shared/components/image-cropper-modal/image-cropper-modal.component';
import { BlankIinModalComponent } from '../../../modals/modals-incoming-invoice/blank-iin-modal/blank-iin-modal.component';

export interface UploadedImage {
  apiModel?: ImageModel;
  fileItem?: FileItem;
  previewSrc?: any;
}

@Component({
  selector: 'rnpl-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit, OnChanges {
  private static TYPE_REG_EXP = /(jpg|jpeg|png|svg|image\/svg\+xml|bmp|dib|rle|pdf)$/i;
  private static LOADING_TOAST_KEY = 'fileUploadingToast';

  @Input()
  @HostBinding('class.multiple')
  isMultiple: boolean = false;

  @Input('images')
  set existingImages(images: Array<ImageModel>) {
    this._images = images
      .filter((image: ImageModel) => !!image);
  }

  @Input() public isOptional: boolean = false;
  @Input() apiUrl: string;
  @Input() maxFileSize: number = 10000000;
  @Input() retryQueryParam: string;
  @Input() isPreloader: boolean = true;
  @Input() disabled: boolean = false;
  @Input() loadingToastText: string = '';
  @Input() singleFileView: boolean = false;
  @Input() hasLinkedDocument: boolean = false;
  @Input() icon: string = 'cloud-upload';
  @Input() itemAlias: string = 'image';
  @Input() label: string;
  @Input() croppedImg: boolean;

  @Output() changed: EventEmitter<Array<ImageModel>> = new EventEmitter<Array<ImageModel>>();
  @Output() parsing: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();
  @Output() parsed: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

  get images(): Array<ImageModel> {
    return this._images;
  }

  get isAnyUploading(): boolean {
    if (!this.images || !this.images.length) { return false; }

    return this.images.some(img => img && img.fileItem && img.fileItem.isUploading);
  }

  get isAnyUploadingProgress(): number {
    if (!this.isAnyUploading) { return 0; }

    const uploadingImages = this.images.filter(img => img && img.fileItem && img.fileItem.isUploading);

    return uploadingImages[0].fileItem.progress;
  }

  uploader: FileUploader;

  private _images: Array<ImageModel> = [];
  private invalidFiles: Set<string> = new Set<string>();
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private appContext: AppContextService,
              private authService: AuthService,
              private elementRef: ElementRef,
              private toasterService: ToasterService,
              private translateService: TranslateService,
              private filesize: FileSizePipe,
              private dialog: MatDialog,
  ) {}

  @TrackInputChanges<string>('apiUrl', 'initUploader', ChangesStrategy.Each)
  ngOnChanges(changes: SimpleChanges) {}

  ngOnInit() {
    this.initUploader();
  }

  public initUploader() {
    this.uploader = new FileUploader({
      url: this.apiUrl || this.appContext.getFromContext('apiBase') + '/file/image',
      authTokenHeader: 'authorization',
      authToken: `Bearer ${this.authService.getToken()}`,
      itemAlias: this.itemAlias,
      removeAfterUpload: true,
      maxFileSize: this.maxFileSize,
      filters: [
        {
          name: 'fileType',
          fn: this.typeFilter
        }
      ]
    });
    this.uploader.onAfterAddingFile = (file) => {
      this.handleOnAfterAddingAll();
      file.withCredentials = false;
    };

    this.uploader.onWhenAddingFileFailed = this.handleInvalidFile.bind(this);
    this.uploader.onSuccessItem = this.handleSuccessUploadedImage.bind(this);
    this.uploader.onErrorItem = this.handleErrorUploadedImage.bind(this);
    this.uploader.onAfterAddingAll = () => this.handleOnAfterAddingAll.bind(this);
    // this.uploader.onCompleteAll = this.handleOnCompleteAll.bind(this);
  }

  /**
   * Handles selecting image files
   *
   * @param files A list of selected images
   */
  handleFiles(files: Array<File>): void {
    if (this.disabled) { return; }

    const filesArr = Array.from(files);

    if (!this.isMultiple && filesArr.length > 1) {
      // todo: add toaster error
      return;
    }

    if (filesArr.length) {
      this.uploader.queue.forEach((file: FileItem) => {
          const fileReader = new FileReader();
          fileReader.onload = () => {
            this._images.push({
              previewSrc: fileReader.result,
              fileItem: file,
            });
          };
          fileReader.readAsDataURL(file._file);
        });

      this.uploader.uploadAll();

      this.clearInput();
    }
  }

  clearInput() {
    try {
      const inputFile = this.elementRef.nativeElement.querySelector('input');

      if (inputFile) {
        inputFile.value = null;
      }
    } catch (e) {}

  }
  /**
   * Removes image from list
   *
   * @param index An index of removing image
   */
  removeImage(index: number) {
    this._images.splice(index, 1);
    this.changed.emit(this.images);
  }
  /**
   * Emits file list change event
   */
  private handleUploadedAll(): void {
    this.changed.emit(this.images.map(image => image.apiModel));
  }

  /**
   * Handles file upload response
   *
   * @param file A file item
   * @param response Response from server
   */
  private handleSuccessUploadedImage(file: FileItem, response) {
    if (response) {
      response = JSON.parse(response);
    }
    const uploadedFile = this.findImageByFile(file);
    if (uploadedFile && response) {
      uploadedFile.apiModel = response.data;
    }
    this.changed.emit(this.images);
  }

  /**
   * Handles errors
   *
   * @param file A file item
   * @param response Response from server
   */
  private handleErrorUploadedImage(file: FileItem, response) {
    response = JSON.parse(response);

    if (response.errors && response.errors.length) {
      response.errors.forEach(error => {
        switch (error) {
          case 'attachmentNotUniqueError':
            this.notUniqFileError(file, response.data);
            break;
          case '1750':
            this.ocrFileError(file);
            break;
          case 'documentBlank':
            this.documentBlankError(file);
            break;
          default:
            this.toasterService.notify({
              type: ToasterService.MESSAGE_TYPE_ERROR,
              message: error
            });
            break;
        }
      });
    }

    if (response.message) {
      switch (response.message) {
        case 'attachmentNotUniqueError':
          this.notUniqFileError(file, response.data);
          break;
        default:
          this.toasterService.notify({
            type: ToasterService.MESSAGE_TYPE_ERROR,
            message: response.message
          });
          break;
      }
    }

    this.hideLoadingToast();
    this._images = this._images.filter(img => !img.fileItem.isError);
  }

  public ocrFileError(file: FileItem): void {
    file = cloneDeep(file);
    const dialog = this.dialog.open(IncomingInvoiceOcrErrorModalComponent, {
      data: {
        hasLinkedPo: this.hasLinkedDocument
      },
      disableClose: true
    });

    dialog.afterClosed().subscribe((response: { params: string, matching: boolean }) => {
      if (response.params) {
        // return file to queue
        this.uploader.queue.push(file);

        if (response.matching) {
          this.parsing.emit();
        }

        // set Url with forced parameters
        if (this.apiUrl && response.params) {
          this.uploader.setOptions({
            url: this.apiUrl + response.params
          });
        }

        this.handleOnAfterAddingAll();
        this.uploader.uploadItem(file);

        // reinit uploader
        this.initUploader();
      } else {
        this.hideLoadingToast();
      }
    });
  }

  public documentBlankError(file: FileItem): void {
    file = cloneDeep(file);
    const dialog = this.dialog.open(BlankIinModalComponent);

    dialog.afterClosed().subscribe((res: { queryParams: string, parse: boolean }) => {
      if (res.queryParams) {
        if (res.parse) {
          this.parsing.emit();
        }
        // return file to queue
        this.uploader.queue.push(file);

        // set Url with forced parameters
        if (this.apiUrl && res.queryParams) {
          this.uploader.setOptions({
            url: this.apiUrl + res.queryParams
          });
        }

        this.handleOnAfterAddingAll();
        this.uploader.uploadItem(file);

        // reinit uploader
        this.initUploader();
      } else {
        this.hideLoadingToast();
      }
    });
  }

  public notUniqFileError(file: FileItem, responseData): void {
    const documents = responseData && responseData.join(', ');
    const dialog = this.dialog.open(WarningModalComponent, {
      data: {
        title: 'BUTTON.UPLOAD_FILE',
        message: this.translateService.instant('IIN.FILE_ALREADY_UPLOADED_TO_DOCUMENTS', {documents}),
        confirmBtnText: 'BUTTON.UPLOAD_FILE',
        confirmBtnIcon: 'cloud-upload'
      }
    });

    dialog.afterClosed().subscribe(res => {
      if (res === CommonModalsActionsEnum.CONFIRM) {
        // return file to queue
        this.uploader.queue.push(file);

        // set Url with forced parameters
        if (this.apiUrl && this.retryQueryParam) {
          this.uploader.setOptions({
            url: this.apiUrl + this.retryQueryParam
          });
        }

        this.handleOnAfterAddingAll();
        this.uploader.uploadItem(file);

        // reinit uploader
        this.initUploader();
      } else {
        this.hideLoadingToast();
      }
    });
  }

  /**
   * Finds image at images list by file
   *
   * @param file A file item
   */
  private findImageByFile(file: FileItem): UploadedImage {
    return this._images
      .filter(image => !!image.fileItem)
      .find(image => image.fileItem.file.name === file.file.name && !image.apiModel);
  }

  /**
   * Finds image at images list by file name
   *
   * @param name Name of file
   */
  private findImageByName(name: string): ImageModel {
    return this._images
      .find(image => {
        return (image.fileItem && image.fileItem.file.name === name)
          || (image.apiModel && image.apiModel.title === name);
      });
  }

  /**
   * Displays file filter error
   *
   * @param file An invalid file
   * @param filter A triggered filter
   */
  private handleInvalidFile(file: FileLikeObject, filter: FilterFunction) {
    if (!this.invalidFiles.has(file.name)) {
      this.invalidFiles.add(file.name);
    }

    switch (filter.name) {

      case 'fileType':
        this.toasterService.notify({
          type: ToasterService.MESSAGE_TYPE_WARNING,
          message: this.translateService.instant('COMMON.INVALID_FILE_TYPE', {file: file.name})
        });
        break;

      case 'fileSize':
        this.toasterService.notify({
          type: ToasterService.MESSAGE_TYPE_WARNING,
          message: this.translateService.instant(
            'COMMON.OVERSIZE_FILE',
            {
              file: file.name,
              size: this.filesize.transform(this.maxFileSize)
            }
          )
        });
        break;

      default:
    }
  }

  private handleOnAfterAddingAll() {
    this.isLoading$.next(true);
    if (this.loadingToastText) {
      this.toasterService.notifyRequestMessage({key: ImageUploadComponent.LOADING_TOAST_KEY, message: this.loadingToastText});
    }
  }

  // private handleOnCompleteAll() {
  //   if (this.loadingToastText) {
  //     this.isLoading$.next(false);
  //     this.toasterService.hideRequestMessage(ImageUploadComponent.LOADING_TOAST_KEY);
  //   }
  // }

  public hideLoadingToast(): void {
    this.parsed.emit();
    this.isLoading$.next(false);
    if (this.loadingToastText) {
      this.toasterService.hideRequestMessage(ImageUploadComponent.LOADING_TOAST_KEY);
    }
  }

  /**
   * Image filter by type
   *
   * @param file An invalid file
   */
  private typeFilter(file: FileLikeObject): boolean {
    return !!ImageUploadComponent.TYPE_REG_EXP.exec(file.type);
  }

  /**
   * Image filter by duplicate
   *
   * @param file An invalid file
   */
  private duplicateFilter(file: FileLikeObject): boolean {
    return !this.uploader.queue.find((item: FileItem) => item.file.name === file.name);
  }

  public drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this._images, event.previousIndex, event.currentIndex);
    this.changed.emit(this.images);
  }

  public fileChangeEvent(event: any, files): void {
    const availableTypesToCrop = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/heic',
    ];
    if (files.length && !availableTypesToCrop.includes(files[0].type)) {
      this.uploader.addToQueue([files[0]]);
      this.handleFiles([files[0]]);
      return;
    }

    if (event) {
      const dialogRef = this.dialog.open(ImageCropperModalComponent,
        {
          data: {image: event, filename: files[0].name},
          maxWidth: '700px',
          // width: '700px',
          panelClass: 'crop-image-modal',
        }
      );

     dialogRef.afterClosed().subscribe(result => {
       if (result) {
         this.uploader.addToQueue([result]);
         this.handleFiles([result]);
       } else {
         this.clearInput();
       }
     });
    }
  }


}
