import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { catchError, finalize, switchMap, takeUntil, tap, toArray } from 'rxjs/operators';
import { concat, EMPTY, of, ReplaySubject } from 'rxjs';

import { DocumentTypesUppercaseEnum } from '../link-document-modal/enums/ducument-types.enum';
import { RnplCommonService } from '../../../rnpl-common/services/rnpl-common.service';
import { EMAIL_SENT_STATUS_CLASSES } from 'projects/workspace/src/app/shared/constants';
import { FileAttachmentModel } from 'projects/workspace/src/app/shared/models';
import {
  FilesAttachmentApiService
} from 'projects/workspace/src/app/shared/components/files-attachment/files-attachment-api.service';
import { FileAttachmentTypeEnum } from 'projects/workspace/src/app/shared/enums';
import { ToasterService } from '../../../ui-components/toaster';
import { FileService } from '../../../../services/file.service';

@Component({
  selector: 'rnpl-send-email-modal',
  templateUrl: './send-email-modal.component.html',
  styleUrls: ['./send-email-modal.component.scss'],
})
export class SendEmailModalComponent implements OnInit {

  public emails: string = null;
  public emailEmptyValidation: boolean = false;
  public setStatusToSent: boolean = false;
  public addAttachments: boolean = false;
  public addAllAttachments: boolean = false;
  public attachments: FileAttachmentModel[] = [];
  public addCustomEmailTextControl: FormControl = new FormControl();
  public customEmailTextControl: FormControl = new FormControl();

  public fileAttachmentTypeEnum = FileAttachmentTypeEnum;
  public emailSentStatusClasses: {[key: string]: string } = EMAIL_SENT_STATUS_CLASSES;
  public attachmentItmIcon: {[key in FileAttachmentTypeEnum]?: string} = {
    [FileAttachmentTypeEnum.PDF]: 'icon-format-pdf',
    [FileAttachmentTypeEnum.IMAGE]: 'icon-format-image',
    [FileAttachmentTypeEnum.ARCHIVE]: 'icon-format-zip',
    [FileAttachmentTypeEnum.DOCUMENT]: 'icon-format-word',
    [FileAttachmentTypeEnum.TABLE]: 'icon-format-xlsx',
    [FileAttachmentTypeEnum.TEXT]: 'icon-format-txt',
  };

  @ViewChild('UploadFileEmailModalInput', {static: false}) public uploadFileEmailModalInputEl: ElementRef;

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    public translateService: TranslateService,
    public toasterService: ToasterService,
    public commonService: RnplCommonService,
    private fileService: FileService,
    public filesAttachmentApiService: FilesAttachmentApiService,
    public dialogRef: MatDialogRef<SendEmailModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      documentId: number,
      documentType: DocumentTypesUppercaseEnum,
      contactEmail: string,
      emailStatus: string,
      attachmentsAllowed: boolean,
      customTextAllowed: boolean,
      isAccountingAttachments: boolean,
      addCustomEmailText: boolean,
      customEmailText: string,
    }
  ) {
    this.addCustomEmailTextControl.patchValue(this.data.addCustomEmailText);
    this.customEmailTextControl.patchValue(this.data.customEmailText);
  }

  ngOnInit(): void {
    if (this.data && this.data.contactEmail) {
      this.emails = this.data.contactEmail;
    }

    if (this.data && this.data.attachmentsAllowed) {
      this.getAttachments();
    }
  }

  public getAttachments(selectUploaded = false): void {
    const request$ = this.data.isAccountingAttachments
      ? this.filesAttachmentApiService.getAccountingAttachments(this.data.documentType, this.data.documentId)
      : this.filesAttachmentApiService.getAttachments(this.data.documentType, this.data.documentId);

    request$
      .pipe(takeUntil(this.destroy$))
      .subscribe((attachments: FileAttachmentModel[]) => {
        const existedAttachments = [...this.attachments];
        this.attachments = attachments.map(attachment => {
          const existedAttachment = existedAttachments.find(a => a.id === attachment.id);
          return {
            ...attachment,
            enabled: existedAttachment
              ? existedAttachment.enabled
              : selectUploaded && !existedAttachment
                ? true
                : this.addAllAttachments
          };
        });
      });
  }

  public addAllAttachmentsChanged(): void {
    this.attachments = this.attachments.map(attachment => ({
      ...attachment,
      enabled: this.addAllAttachments
    }));
  }

  public uploadFile(filesList: FileList): void {
    if (!filesList || !filesList.length) { return; }

    const requests$ = [];
    Array.from(filesList).map((file, index) => {
      requests$.push(
        of(null)
          .pipe(
            tap(() => {
              this.toasterService.notifyRequestMessage({
                key: 'fileAttach' + index,
                message: this.translateService.instant('ATTACHMENTS.FILE_UPLOADING', {name: file.name})
              });
            }),
            switchMap(() => {
              return this.data.isAccountingAttachments
                ? this.filesAttachmentApiService.uploadAccountingAttachment(this.data.documentType, this.data.documentId, file)
                : this.filesAttachmentApiService.uploadAttachment(this.data.documentType, this.data.documentId, file);
            }),
            catchError(() => EMPTY),
            finalize(() => this.toasterService.hideRequestMessage('fileAttach' + index))
          )
      );
    });

    concat(...requests$)
      .pipe(
        toArray(),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.uploadFileEmailModalInputEl.nativeElement.value = null;
        this.getAttachments(true);
      });
  }

  public downloadFile(fileId: number, name: string): void {
    if (!this.data.documentType || !this.data.documentId) { return; }

    const request$ = this.data.isAccountingAttachments
      ? this.filesAttachmentApiService.getDownloadAccountingAttachment(this.data.documentType, this.data.documentId, fileId)
      : this.filesAttachmentApiService.getDownloadAttachment(fileId);

    request$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.data.isAccountingAttachments
          ? this.fileService.downloadFileByUrl(res.url, name)
          : this.fileService.downloadFileByUrl(res.tempUrl, name);
      });
  }

  public sendEmail(): void {
    if (!this.emails) {
      this.emailEmptyValidation = true;
      return;
    }

    const emails = this.emails.split(',');
    const attachmentIds: number[] = this.addAttachments
      ? this.attachments
        .filter(itm => itm.enabled)
        .map(itm => itm.id)
      : [];

    this.commonService.sendEmail(
      emails,
      this.data.documentType,
      this.data.documentId,
      this.setStatusToSent,
      attachmentIds,
      this.addCustomEmailTextControl.value,
      this.customEmailTextControl.value,
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => this.dialogRef.close(res));
  }

  public get sumSize(): number {
    return this.attachments
      .filter(attachment => attachment.enabled)
      .reduce((acc, attachment) => acc + attachment.size, 0);
  }

}

