import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { RelatedProductsCardsComponent } from './related-products-cards.component';
import { ActionButtonsService } from 'common/src/services/action-buttons.service';
import { RnplCommonModule } from 'common/src/modules';


@NgModule({
  declarations: [
    RelatedProductsCardsComponent
  ],
  exports: [
    RelatedProductsCardsComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    RnplCommonModule,
    RouterModule
  ],
  providers: [
    ActionButtonsService
  ]
})
export class RelatedProductsCardsModule { }
