import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { get } from 'lodash';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ProductModel, ProductsService } from '../../../products';
import { ProductTypes } from '../../../products/product-types';
import { CompositeProductModalComponent } from '../../modals-products';
import { CreditNotePositionModel, CRNPositionsModel } from 'projects/workspace/src/app/credit-note/models/credit-note-position.model';
import { CreditNoteApiService } from 'projects/workspace/src/app/credit-note/services/credit-note-api.service';
import { CustomSearchFn, getProductSearchValue } from 'common/src/modules/rnpl-common/helpers';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { VAT_LIST } from 'projects/workspace/src/app/shared/constants';
import { DocumentTypesUppercaseEnum } from '../../modals-common/link-document-modal/enums/ducument-types.enum';
import { RecalculatePositionApiService } from '../../../../services/recalculate-position-api.service';
import { CORRECTION_POSITION_TYPES_LIST } from 'projects/workspace/src/app/accounting/accounting.constants';
import { CommonModalsActionsEnum, WarningModalComponent } from '../../modals-common';
import { CreditNotePurposeEnum } from 'projects/workspace/src/app/credit-note/enums';
import { CHANGE_CORRECTION_CALCULATION_MODAL_DATA } from '../../modals.contsans';
import {
  PositionAccountingSettingsComponent
} from 'projects/workspace/src/app/shared/components/position-accounting-settings/position-accounting-settings.component';
import { PaymentDirectionEnum } from 'projects/workspace/src/app/payment/models/payment.model';
import { PositionAccountingSettings } from 'projects/workspace/src/app/outgoing-invoice/models';
import { PurposeTypeEnum } from 'projects/workspace/src/app/purchase-order/enums';
import { ProductUnitApiService } from 'projects/workspace/src/app/shared/services';
import { ProductUnitModel } from 'projects/workspace/src/app/shared/models';

@Component({
  selector: 'rnpl-credit-note-common-position-modal',
  templateUrl: './credit-note-common-position-modal.component.html',
  styleUrls: ['./credit-note-common-position-modal.component.scss']
})
export class CreditNoteCommonPositionModalComponent extends BaseModalComponent implements OnInit {

  public purposeType = PurposeTypeEnum;
  public productTypes = ProductTypes;
  public companyProfile: CompanyProfile;
  public productTypeRadio: 'common'|'general' = 'common';
  // public previousDropdownPosition: 'bottom'|'top'|'auto' = null;
  public accountingSettings: PositionAccountingSettings;
  public previousUnitType: string;

  public selectedProductInvalid: boolean = false;
  public productNameInvalid: boolean = false;

  public productsList: ProductModel[] = [];
  public productsListByType: ProductModel[] = [];
  public generalCategoriesSourceList: any[] = [];

  public isProductFound: boolean = false;
  public selectedProduct: ProductModel = null;
  public positionName: string = null;
  public selectedProductType: ProductTypes = ProductTypes.GOODS;
  public crnDirections: typeof PaymentDirectionEnum = PaymentDirectionEnum;
  public selectedGeneralCategory: any = null;

  public unitsList: string[] = [];
  public unitsListSource: ProductUnitModel[] = [];
  public customSearchFn = CustomSearchFn;

  public form: FormGroup;
  public readonly vatList = VAT_LIST;
  public correctionPositionTypesList = CORRECTION_POSITION_TYPES_LIST;

  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  readonly generalCategoriesFilteredList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  readonly generalProductsList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  readonly createPositionRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  @ViewChild('positionAccountingSettings', { static: false }) positionAccountingSettingsRef: PositionAccountingSettingsComponent;

  constructor(
    public store: Store<AppState>,
    public toasterService: ToasterService,
    public translateService: TranslateService,
    private productsService: ProductsService,
    private creditNoteApiService: CreditNoteApiService,
    public dialogRef: MatDialogRef<CreditNoteCommonPositionModalComponent>,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private recalculatePositionApiService: RecalculatePositionApiService,
    private productUnitApiService: ProductUnitApiService,
    @Inject(MAT_DIALOG_DATA) public data: {
      creditNoteId: number,
      isIncomingCorrection: boolean,
      disabledAccountingSettings: boolean,
      vatBlocked: boolean,
      direction: PaymentDirectionEnum,
      purpose: CreditNotePurposeEnum,
    }
  ) {
    super(toasterService);

    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((companyProfile: CompanyProfile) => {
        this.companyProfile = companyProfile;
      });
  }

  ngOnInit() {
    this.initForm();
    this.getProducts();
    this.getGeneralProductsListDropdown();

    this.productTypeRadio = this.crnDirection === PaymentDirectionEnum.OUTGOING
      ? 'common'
      : 'general';
  }

  private getProducts(): void {
    this.productsService.getActiveProducts()
      .pipe(takeUntil(this._destroy))
      .subscribe((products: ProductModel[]) => {
        this.productsList = this.prepareProductsList(products);
        this.filterProducts();
        this.showDropdownSpin$.next(false);
      });
  }

  private getGeneralProductsListDropdown(): void {
    this.productsService.getGeneralProductsListDropdown(this.crnDirection === this.crnDirections.INCOMING)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        const filteredResponse = response.filter(itm => itm.isVisibleForList);
        if (this.crnDirection === this.crnDirections.OUTGOING) {
          this.generalProductsList$.next(filteredResponse);
        }
        if (this.crnDirection === this.crnDirections.INCOMING) {
          this.generalCategoriesSourceList = filteredResponse;
          this.filterCategories();
        }
      });
  }

  public selectSearch(e): void {
    if (this.productTypeRadio === 'general') {
      if (e.term) {
        const filtered = this.generalCategoriesFilteredList$.getValue().filter(category => CustomSearchFn(e.term, category));
        this.generalCategoriesFilteredList$.next(filtered);
      } else {
        this.filterCategories();
        this.generalCategoriesFilteredList$.next(this.generalCategoriesSourceList);
      }
    }
  }

  public resetGeneralCategoriesList(): void {
    this.generalCategoriesFilteredList$.next(this.generalCategoriesSourceList);
  }

  public clearProductsList(): void {
    this.generalProductsList$.next([]);
    this.resetGeneralCategoriesList();
  }

  public selectCategory(category): void {
    this.selectedGeneralCategory = category;
    this.generalProductsList$.next(category.children);
  }

  public fixDropdownPosition(productType: 'common'|'general', dropdownPosition: 'bottom'|'top'|'auto'): void {
    // if (productType === 'general') {
    //   this.previousDropdownPosition = dropdownPosition;
    // } else {
    //   this.previousDropdownPosition = null;
    // }
  }

  private initForm(selectProduct: ProductModel = {} as ProductModel): void {
    this.form = this.fb.group({
      grossTotalPrice: [null, Validators.required],
      grossUnitPrice: [null, Validators.required],
      netTotalPrice: [null, Validators.required],
      productDescription: [get(selectProduct, 'description')],
      providedServices: [get(selectProduct, 'description')],
      description: [get(selectProduct, 'name')],
      netUnitPrice: [null, Validators.required],
      vat: [20],
      delivered: [],
      providedFrom: [],
      providedTo: [],
      quantity: [1],
      remark: [],
      addRemark: [false],
      productType: [],
      unitType: [get(selectProduct, 'unitType') || get(selectProduct, 'unit_type') || 'pcs'],
      specifyAmountsSeparately: [],
      correctionType: [],
      vatTotal: [],
      productDescriptionUpdated: [false],
      purpose: [(this.form && this.purpose && this.purpose.value) || PurposeTypeEnum.INTERNAL_USAGE, {updateOn: 'change'}],
    });

    this.patchPurposeControl();
  }

  private patchPurposeControl(): void {
    // we cant select purpose for services or correction positions
    if (this.form && this.selectedProduct && (this.isServicesProductType || this.isCorrectionProductType || this.isGeneralServiceType)) {
      this.purpose.setValue(null);
      this.purpose.disable();
    } else {
      this.cdr.detectChanges();
      this.purpose.enable();
    }
    this.filterCategories();
  }

  public getProductById(productId) {
    this.productsService.getProduct(productId)
      .pipe(takeUntil(this._destroy))
      .subscribe(product => {
        this.accountingSettings = product.accountingSettings;
        this.unitType.patchValue(product.unitType);
        this.accountingSettings = product.accountingSettings;
        this.getDefaultAccountingSettings();
      });
  }

  selectPositionChanges(selectedProduct: ProductModel): void {
    this.selectedProductType = selectedProduct.type || selectedProduct.productType;
    if (this.productTypeRadio === 'general') {
      this.selectedProductType = ProductTypes.GENERAL;
    }

    this.accountingSettings = null;
    if (this.positionAccountingSettingsRef) {
      this.positionAccountingSettingsRef.resetForm();
    }
    if (selectedProduct.accountingSettings) {
      this.accountingSettings = { ...selectedProduct.accountingSettings };
      this.accountingSettings.productTypeId = selectedProduct.accountingSettings.glProductTypeId;
    }
    this.description.clearValidators();
    this.initForm(selectedProduct);
    this.quantity.setValue(1);
    if (this.data.vatBlocked) {
      this.vat.setValue(0);
      this.vat.disable({onlySelf: true, emitEvent: false});
      this.vatTotal.disable({onlySelf: true, emitEvent: false});
    }

    if (this.selectedProduct.id) {
      this.getProductById(this.selectedProduct.id);
    } else {
      this.getDefaultAccountingSettings();
    }

    this.getProductUnits();
  }

  public getProductUnits(): void {
    this.productUnitApiService.getUnits$(this.selectedProduct.type || this.selectedProduct.entityForm)
      .pipe(takeUntil(this.destroy$))
      .subscribe((unitsList: ProductUnitModel[]) => {
        this.unitsListSource = unitsList;
        this.unitsList = unitsList.map(u => u.name);
      });
  }

  public getDefaultAccountingSettings(): void {
    if (this.crnDirection === PaymentDirectionEnum.OUTGOING) {
      // wait <rnpl-position-accounting-settings> to draw
      // init position predefined data
      setTimeout(() => this.updatePosition(null, 'resolveRevenueGL'), 200);
    } else {
      // init position predefined data
      // wait for this.positionAccountingSettingsRef to render
      const purpose = this.isServicesProductType || this.isGeneralServiceType || this.isCorrectionProductType
        ? null
        : this.purpose.value;
      setTimeout(() => this.updatePosition(purpose, 'purpose'), 200);
    }
  }

  public purposeChanged(): void {
    if (this.purpose.value === PurposeTypeEnum.RESALE) {
      // set to 'common' to hide general products if this.productTypeRadio === 'general'
      this.productTypeRadio = 'common';
    }

    // // updatePositionData
    this.updatePosition(this.purpose.value || PurposeTypeEnum.INTERNAL_USAGE, 'purpose');
  }

  public prepareProductsList(products): ProductModel[] {
    return products.map(item => ({
      ...item,
      searchLabel: getProductSearchValue(item, this.translateService)
    }));
  }

  public filterProducts(): void {
    this.productsListByType = this.productsList
      .filter((product: ProductModel) => product.type === this.selectedProductType);
  }

  public filterCategories(): void {
    if (this.isCommonIinCorrection() && this.purpose.value === PurposeTypeEnum.INTERNAL_USAGE) {
      const filteredCategories = this.generalCategoriesSourceList
        .map(category => ({
          ...category,
          children: category.children.filter(c => c.standaloneAllowed)
        }))
        .filter(c => c.children && c.children.length);
      this.generalCategoriesFilteredList$.next(filteredCategories);
    } else {
      this.resetGeneralCategoriesList();
    }
  }

  public createProduct(productType): void {
    const dialog = this.dialog.open(CompositeProductModalComponent, {
      data: {
        family: null,
        productType: productType
      },
      disableClose: true,
    });

    dialog.afterClosed().subscribe((product: ProductModel) => {
      if (product) {
        this.productsListByType.push(product);
        this.productsList.push(product);
        this.selectedProduct = product;
        this.selectedProductType = product.productType;
        this.selectPositionChanges(product);
        this.isProductFound = false;
      }
    });
  }

  public addPosition(): void {
    if (this.selectedProductType === ProductTypes.CORRECTION) {
        if (!this.description.valid) {
        this.productNameInvalid = true;
        return;
      }
    } else {
      if (!this.selectedProduct) {
        this.selectedProductInvalid = true;
        return;
      }
    }

    if (this.createPositionRequest$.getValue()) { return; }
    this.createPositionRequest$.next(true);

    const selectedNotCorrection = this.selectedProduct && this.selectedProductType !== ProductTypes.CORRECTION;

    const position: Partial<CreditNotePositionModel> = {
      ...this.form.getRawValue(),
      positionId: this.selectedProduct.id,
      productType: this.selectedProductType,
      productTypeForGrouping: this.selectedProductType,
      manufacturerCode: get(this.selectedProduct, 'manufacturer_code') || get(this.selectedProduct, 'manufacturerCode'),
      productId: selectedNotCorrection ? +this.selectedProduct.id : null,
      runpleId: selectedNotCorrection ? this.selectedProduct.runple_id : null,
      unitType: this.unitType.value,
      productDescription: !this.isServicesProductType && !this.isGeneralServiceType
        ? this.productDescription.value
        : null,
      providedServices: this.isServicesProductType || this.isGeneralServiceType
        ? this.providedServices.value
        : null,
      accountingSettings: {
        ...this.accountingSettings,
        ...(this.positionAccountingSettingsRef && this.positionAccountingSettingsRef.getFormValue())
      }
    };

    this.creditNoteApiService.createPositionByPredefinedForm(this.data.creditNoteId, position)
      .pipe(finalize(() => this.createPositionRequest$.next(false)))
      .subscribe((response: CRNPositionsModel) => this.dialogRef.close(response));
  }

  // public displayUserDefinedPosition(): void {
  //   this.changeProductType();
  //   this.selectedProductType = ProductTypes.ARBITRARY;
  //   this.selectedProduct = { name: this.translateService.instant('POSITIONS.USER_DEFINED_POSITION') };
  //   this.positionName = null;
  //   this.initForm();
  //   this.vat.setValue(20);
  //   this.description.setValidators(Validators.required);
  //   this.productTypeControl.setValue(ProductTypes.GOODS);
  //   if (this.data.vatBlocked) {
  //     this.vat.disable({onlySelf: true, emitEvent: false});
  //     this.vat.setValue(0);
  //     this.vatTotal.disable({onlySelf: true, emitEvent: false});
  //   } else {
  //     this.vat.setValue(20);
  //   }
  // }

  // public changeProductType(): void {
  //   this.selectedProduct = null;
  //   this.positionName = null;
  //   this.productNameInvalid = false;
  //   this.selectedProductInvalid = false;
  //   this.isProductFound = false;
  //   this.filterProducts();
  // }

  public displayCorrectionPosition(): void {
    this.selectedProductType = ProductTypes.CORRECTION;
    this.selectedProduct = { name: this.translateService.instant('FORM.CORRECTION') };
    this.positionName = null;
    this.initForm();
    this.correctionType.setValue('NEGATIVE');
    this.description.setValidators(Validators.required);

    if (!this.data.isIncomingCorrection) {
      this.correctionType.disable({ onlySelf: true, emitEvent: false });
    }

    if (this.data.vatBlocked) {
      this.vatTotal.disable({onlySelf: true, emitEvent: false});
      this.vat.setValue(0);
    }

    this.getDefaultAccountingSettings();
  }

  public updatePosition(value, fieldName, force: boolean = false): void {
    if (!this.selectedProduct) { return; }

    this.createPositionRequest$.next(true);

    switch (fieldName) {
      case 'delivered':
      case 'providedFrom':
      case 'providedTo':
        value = this.datePipe.transform(value, 'yyyy-MM-dd');
        break;
    }

    if (fieldName === 'specifyAmountsSeparately' && !value && !force) {
      if (
        (this.netTotalPrice.value && (this.netTotalPrice.value !== '0.00')) ||
        (this.grossTotalPrice.value && (this.grossTotalPrice.value !== '0.00')) ||
        (this.vatTotal.value && (this.vatTotal.value !== 0))
      ) {
        const dialog = this.dialog.open(WarningModalComponent, {
          data: CHANGE_CORRECTION_CALCULATION_MODAL_DATA
        });

        dialog.afterClosed().subscribe(res => {
          if (res === CommonModalsActionsEnum.CONFIRM) {
            this.updatePosition(value, fieldName, true);
          } else {
            this.specifyAmountsSeparately.setValue(true);
          }
        });
        return;
      }
    }

    this.recalculatePositionApiService.recalculatePosition(DocumentTypesUppercaseEnum.CRN, {
      protoPosition: {
        ...this.form.getRawValue(),
        purpose: this.isServicesProductType || this.isGeneralServiceType || this.isCorrectionProductType
          ? null
          : this.purpose.value || PurposeTypeEnum.INTERNAL_USAGE,
        productId: +this.selectedProduct.id,
        productType: this.selectedProductType,
        productTypeForGrouping: this.selectedProductType,
        productDescription: !this.isServicesProductType && !this.isGeneralServiceType
          ? this.productDescription.value
          : null,
        providedServices: this.isServicesProductType || this.isGeneralServiceType
          ? this.providedServices.value
          : null,
        correctionType: this.isCorrectionProductType ? 'NEGATIVE' : this.correctionType.value,
        accountingSettings: {
          ...this.accountingSettings,
          ...(this.positionAccountingSettingsRef && this.positionAccountingSettingsRef.getFormValue()),
          productTypeId: get(this.accountingSettings, 'productTypeId') || get(this.accountingSettings, 'glProductTypeId')
        }
      },
      fieldUpdateRequest: {
        fieldName: fieldName,
        fieldValue: value
      },
      type: this.selectedProductType,
      crnPurpose: CreditNotePurposeEnum.COMMON_CORRECTION,
      documentId: this.data.creditNoteId,
    })
      .pipe(finalize(() => this.createPositionRequest$.next(false)))
      .subscribe(product => {
        this.form.patchValue({
          ...product,
          grossTotalPrice: product.grossTotalPrice,
          grossUnitPrice: product.grossUnitPrice,
          netTotalPrice: product.netTotalPrice,
          netUnitPrice: product.netUnitPrice,
          discountAmountNet: product.discountAmountNet,
          vatTotal: product.vatTotal,
        });
        this.previousUnitType = product.unitType;
        if (product.accountingSettings) {
          this.accountingSettings = product.accountingSettings;
        }
      }, () => {
        if (fieldName === 'unitType') {
          // set previous value of unit type in case of error
          this.unitType.patchValue(this.previousUnitType);
          // redraw quantity control view
          setTimeout(() => this.quantity.patchValue(this.quantity.value), 10);
        }
      });
  }

  public updateDescription(description: string): void {
    this.selectedProduct = {
      ...this.selectedProduct,
      description: description
    };
  }

  public updateDescriptionByProductType(): void {
    if (this.isServicesProductType || this.isGeneralServiceType) {
      this.updatePosition(this.providedServices.value, 'providedServices');
    } else {
      this.updatePosition(this.productDescription.value, 'productDescription');
    }
  }

  get isGoodsProductType(): boolean {
    return this.selectedProductType === ProductTypes.GOODS;
  }

  get isServicesProductType(): boolean {
    return this.selectedProductType === ProductTypes.SERVICES;
  }

  get isDigitalProductType(): boolean {
    return this.selectedProductType === ProductTypes.DIGITAL;
  }

  get isCorrectionProductType(): boolean {
    return this.selectedProductType === ProductTypes.CORRECTION;
  }

  get isGeneralGoodsType(): boolean {
    if (!this.selectedProduct) { return false; }
    return this.isGeneralProductType && this.selectedProduct.entityForm === ProductTypes.GOODS;
  }

  get isGeneralServiceType(): boolean {
    if (!this.selectedProduct) { return false; }
    return this.isGeneralProductType &&  this.selectedProduct.entityForm === ProductTypes.SERVICES;
  }

  get isGeneralDigitalType(): boolean {
    if (!this.selectedProduct) { return false; }
    return this.isGeneralProductType &&  this.selectedProduct.entityForm === ProductTypes.DIGITAL;
  }

  get isGeneralProductType(): boolean {
    return this.selectedProductType === ProductTypes.GENERAL;
  }

  public isOinOrIinCorrection(): boolean {
    return this.data.purpose === CreditNotePurposeEnum.OIN_CORRECTION ||
      this.data.purpose === CreditNotePurposeEnum.IIN_CORRECTION;
  }

  public isCommonIinCorrection(): boolean {
    return this.data.purpose === CreditNotePurposeEnum.COMMON_CORRECTION && this.crnDirection === PaymentDirectionEnum.INCOMING;
  }

  get crnDirection(): PaymentDirectionEnum {
    return this.data.direction;
  }

  get isResale(): boolean {
    return this.purpose.value === PurposeTypeEnum.RESALE;
  }

  get isLogistics(): boolean {
    return this.selectedProduct.logistics;
  }

  get allowFractionalValues(): boolean {
    if (!this.unitType.value || !this.unitsListSource.length) { return true; }
    return this.unitsListSource
      .find((itm: ProductUnitModel) => itm.name === this.unitType.value)
      .allowFractionalValues;
  }

  get grossTotalPrice(): FormControl { return this.form.get('grossTotalPrice') as FormControl; }
  get grossUnitPrice(): FormControl { return this.form.get('grossUnitPrice') as FormControl; }
  get specifyRemark(): FormControl { return this.form.get('addRemark') as FormControl; }
  get netTotalPrice(): FormControl { return this.form.get('netTotalPrice') as FormControl; }
  get netUnitPrice(): FormControl { return this.form.get('netUnitPrice') as FormControl; }
  get productDescription(): FormControl { return this.form.get('productDescription') as FormControl; }
  get providedServices(): FormControl { return this.form.get('providedServices') as FormControl; }
  get description(): FormControl { return this.form.get('description') as FormControl; }
  get quantity(): FormControl { return this.form.get('quantity') as FormControl; }
  get remark(): FormControl { return this.form.get('remark') as FormControl; }
  get discount(): FormControl { return this.form.get('discount') as FormControl; }
  get discountAmountNet(): FormControl { return this.form.get('discountAmountNet') as FormControl; }
  get providedFrom(): FormControl { return this.form.get('providedFrom') as FormControl; }
  get providedTo(): FormControl { return this.form.get('providedTo') as FormControl; }
  get vat(): FormControl { return this.form.get('vat') as FormControl; }
  get unitType(): FormControl { return this.form.get('unitType') as FormControl; }
  get specifyAmountsSeparately(): FormControl { return this.form.get('specifyAmountsSeparately') as FormControl; }
  get correctionType(): FormControl { return this.form.get('correctionType') as FormControl; }
  get vatTotal(): FormControl { return this.form.get('vatTotal') as FormControl; }
  get purpose(): FormControl { return this.form.get('purpose') as FormControl; }

}
