import { NavBarBtnModel, UIStatesEnum } from 'common/src/models';
import { ProductTypes } from 'common/src/modules/products/product-types';
import { VAT_LIST } from '../../../shared/constants';
import {
  ControlTypeEnum,
  InputDropdownTypeEnum,
  PositionInfoListModel,
  PositionItemTypeEnum
} from 'common/src/modules/position-card/position-card-models.model';
import { ARBITRARY_POSITION_TYPES_LIST, CORRECTION_POSITION_TYPES_LIST } from '../../../accounting/accounting.constants';

export const ProductTypeTranslateKey = {
  [ProductTypes.GOODS]: 'APP.PRODUCT',
  [ProductTypes.SERVICES]: 'APP.SERVICE',
  [ProductTypes.DIGITAL]: 'APP.DIGITAL_PRODUCT'
}

export const PositionsButtons: NavBarBtnModel[] = [
  {
    classes: 'btn-white red',
    text: 'BUTTON.DELETE',
    multipleText: 'BUTTON.REMOVE',
    badgeClass: 'rnpl-badge--red',
    iconName: 'minus-circle',
    actionName: 'onDeletePositionsClick',
    multiple: false
  },
];


export function generatePositionsCardsInfo(
  // translateService: TranslateService,
  productType: ProductTypes,
  productTypeForGrouping: ProductTypes,
  state: UIStatesEnum,
  vatBlocked: boolean,
  isFinalInvoice: boolean,
  isRegularInvoice: boolean,
  correctionType: 'POSITIVE'|'NEGATIVE',
  editableFinalInvoicePosition: boolean
): PositionInfoListModel[] {
  const cantEditColumn = (state === UIStatesEnum.VIEW);
  const prepaymentPosition = productType === ProductTypes.PREPAYMENT;
  const prepaymentPositionRegularInvoice = isRegularInvoice && productType === ProductTypes.PREPAYMENT;

  let positionInfoList: PositionInfoListModel[] = [
    {
      label: 'COLUMN.TYPE',
      prop: 'productType',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.ARBITRARY_POSITION_TYPE,
      hideWithProductTypeGrouping: [ProductTypes.GOODS, ProductTypes.SERVICES, ProductTypes.DIGITAL, ProductTypes.CORRECTION, ProductTypes.OVERDUE_FEE, ProductTypes.CREDIT, ProductTypes.PREPAYMENT, ProductTypes.INVOICE],
      showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE]
    },
    {
      label: 'COLUMN.NAME',
      prop: 'description',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.TEXTAREA,
      disabled: (isFinalInvoice && !editableFinalInvoicePosition) || prepaymentPositionRegularInvoice,
      hideWithProductTypeGrouping: [ProductTypes.GOODS, ProductTypes.SERVICES, ProductTypes.DIGITAL, ProductTypes.ARBITRARY, ProductTypes.OVERDUE_FEE],
      showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE]
    },
    {
      label: 'COLUMN.NAME',
      prop: 'description',
      placeholder: 'FORM.ENTER_NAME',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT,
      hideWithProductTypeGrouping: [
        ProductTypes.GOODS,
        ProductTypes.SERVICES,
        ProductTypes.DIGITAL,
        ProductTypes.CORRECTION,
        ProductTypes.OVERDUE_FEE,
        ProductTypes.INVOICE,
        ProductTypes.PREPAYMENT,
        ProductTypes.CREDIT,
      ],
      disabled: isFinalInvoice || prepaymentPositionRegularInvoice,
      showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE]
    },
    {
      label: 'FORM.DESCRIPTION',
      prop: 'productDescription',
      isOptional: true,
      disabled: (isFinalInvoice && !editableFinalInvoicePosition),
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.TEXTAREA,
      hideWithProductTypeGrouping: [
        ProductTypes.SERVICES,
        ProductTypes.ARBITRARY,
        ProductTypes.CORRECTION,
        ProductTypes.OVERDUE_FEE,
        ProductTypes.CREDIT,
        ProductTypes.INVOICE,
        ProductTypes.PREPAYMENT,
      ],
      showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE]
    },
    {
      label: 'FORM.DESCRIPTION',
      prop: 'productDescription',
      isOptional: true,
      disabled: ((isFinalInvoice && !editableFinalInvoicePosition) && correctionType === 'NEGATIVE') && prepaymentPosition,
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.TEXTAREA,
      hideWithProductTypeGrouping: [
        ProductTypes.GOODS,
        ProductTypes.SERVICES,
        ProductTypes.DIGITAL,
        ProductTypes.ARBITRARY,
        ProductTypes.CORRECTION,
        ProductTypes.OVERDUE_FEE
      ],
      showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE]
    },
    {
      label: 'FORM.DESCRIPTION',
      prop: 'providedServices',
      isOptional: true,
      disabled: (isFinalInvoice && !editableFinalInvoicePosition),
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.TEXTAREA,
      hideWithProductTypeGrouping: [
        ProductTypes.GOODS,
        ProductTypes.DIGITAL,
        ProductTypes.CORRECTION,
        ProductTypes.CREDIT,
        ProductTypes.OVERDUE_FEE,
        ProductTypes.PREPAYMENT,
        ProductTypes.INVOICE,
      ],
      showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE]
    },
    {
      label: 'FORM.SPECIFY_AMOUNTS_SEPARATELY',
      prop: 'specifyAmountsSeparately',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.TOGGLE,
      itemClass: 'col-12',
      isOptional: true,
      disabled: (isFinalInvoice && !editableFinalInvoicePosition),
      hideWithProductType: [
        ProductTypes.ARBITRARY,
        ProductTypes.GOODS,
        ProductTypes.SERVICES,
        ProductTypes.DIGITAL,
        ProductTypes.CREDIT,
        ProductTypes.OVERDUE_FEE,
        ProductTypes.PREPAYMENT,
        ProductTypes.INVOICE,
      ],
      showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE]
    },
    {
      label: 'COLUMN.TYPE',
      prop: cantEditColumn ? 'productTypeTranslate' : 'productType',
      type: cantEditColumn ? PositionItemTypeEnum.PRIMARY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.DROPDOWN,
      listItems: ARBITRARY_POSITION_TYPES_LIST,
      bindLabel: 'label',
      bindValue: 'value',
      disabled: (isFinalInvoice && !editableFinalInvoicePosition),
      hideWithProductTypeGrouping: [
        ProductTypes.GOODS,
        ProductTypes.SERVICES,
        ProductTypes.DIGITAL,
        ProductTypes.CORRECTION,
        ProductTypes.OVERDUE_FEE,
        ProductTypes.PREPAYMENT,
        ProductTypes.INVOICE,
      ],
    },
    {
      label: 'COLUMN.TYPE',
      prop: cantEditColumn ? 'correctionTypeLabel' : 'correctionType',
      type: cantEditColumn ? PositionItemTypeEnum.PRIMARY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.DROPDOWN,
      listItems: CORRECTION_POSITION_TYPES_LIST,
      bindLabel: 'label',
      bindValue: 'value',
      disabled: true,
      hideWithProductTypeGrouping: [
        ProductTypes.ARBITRARY,
        ProductTypes.GOODS,
        ProductTypes.SERVICES,
        ProductTypes.DIGITAL,
        ProductTypes.CREDIT,
        ProductTypes.OVERDUE_FEE,
        ProductTypes.PREPAYMENT,
        ProductTypes.INVOICE,
      ],
    },
    {
      label: 'COLUMN.QUANTITY',
      prop: 'quantity',
      unitType: 'unitType',
      type: cantEditColumn ? PositionItemTypeEnum.NUMBER : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      disabled: (isFinalInvoice && !editableFinalInvoicePosition),
      inputDropdownType: InputDropdownTypeEnum.NUMBER,
      hideWithProductType: [
        ProductTypes.CORRECTION,
        ProductTypes.CREDIT,
        ProductTypes.OVERDUE_FEE,
        ProductTypes.PREPAYMENT,
        ProductTypes.INVOICE,
      ]
    },
    {
      label: 'COLUMN.UNIT_PRICE_NET',
      prop: 'netUnitPrice',
      suffix: '€',
      disabled: (isFinalInvoice && !editableFinalInvoicePosition),
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      hideWithProductType: [
        ProductTypes.CORRECTION,
        ProductTypes.CREDIT,
        ProductTypes.OVERDUE_FEE,
        ProductTypes.PREPAYMENT,
        ProductTypes.INVOICE,
      ],
      showUnitOnly: true,
    },
    {
      label: 'COLUMN.AMOUNT',
      prop: 'netTotalPrice',
      suffix: '€',
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      currencyNegativeRow: true,
      showUnitOnly: true,
      hideWithProductType: [
        ProductTypes.GOODS,
        ProductTypes.SERVICES,
        ProductTypes.DIGITAL,
        ProductTypes.ARBITRARY,
        ProductTypes.CORRECTION,
        ProductTypes.OVERDUE_FEE,
        ProductTypes.PREPAYMENT,
        ProductTypes.INVOICE,
      ],
    },
    {
      label: 'COLUMN.AMOUNT_NET',
      prop: 'netTotalPrice',
      suffix: '€',
      disabled: (isFinalInvoice && !editableFinalInvoicePosition) || prepaymentPositionRegularInvoice,
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      currencyNegativeRow: true,
      showUnitOnly: true,
      hideWithProductType: [ProductTypes.CREDIT],
    },
    {
      label: 'COLUMN.VAT',
      prop: 'vat',
      suffix: '%',
      type: cantEditColumn ? PositionItemTypeEnum.PRIMARY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.DROPDOWN,
      listItems: VAT_LIST,
      bindLabel: 'label',
      bindValue: 'value',
      disabled: vatBlocked || (isFinalInvoice && !editableFinalInvoicePosition) || prepaymentPosition,
      vatBlocked: vatBlocked,
      hideWithProductType: [ProductTypes.CORRECTION, ProductTypes.CREDIT, ProductTypes.INVOICE,]
    },
    {
      label: 'COLUMN.VAT',
      prop: 'vatTotal',
      suffix: '€',
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      currencyNegativeRow: true,
      currencySourceFormat: true,
      disabled: vatBlocked || (isFinalInvoice && !editableFinalInvoicePosition) || prepaymentPosition,
      vatBlocked: vatBlocked,
      showUnitOnly: true,
      hideWithProductType: [
        ProductTypes.ARBITRARY,
        ProductTypes.SERVICES,
        ProductTypes.GOODS,
        ProductTypes.DIGITAL,
        ProductTypes.CREDIT,
        ProductTypes.OVERDUE_FEE,
        ProductTypes.PREPAYMENT,
      ]
    },
    {
      label: 'COLUMN.UNIT_PRICE_GROSS',
      prop: 'grossUnitPrice',
      suffix: '€',
      disabled: (isFinalInvoice && !editableFinalInvoicePosition),
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      hideWithProductType: [
        ProductTypes.CORRECTION,
        ProductTypes.CREDIT,
        ProductTypes.OVERDUE_FEE,
        ProductTypes.PREPAYMENT,
        ProductTypes.INVOICE,
      ],
      showUnitOnly: true,
    },
    {
      label: 'COLUMN.AMOUNT_GROSS',
      prop: 'grossTotalPrice',
      suffix: '€',
      disabled: (isFinalInvoice && !editableFinalInvoicePosition) || prepaymentPositionRegularInvoice,
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      currencyNegativeRow: true,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      valueClass: 'font-weight-500',
      showUnitOnly: true,
      hideWithProductType: [ProductTypes.CREDIT],
    },
    {
      label: 'COLUMN.PROVIDED_FROM',
      prop: 'providedFrom',
      isOptional: true,
      disabled: (isFinalInvoice && !editableFinalInvoicePosition),
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.DATE,
      hideWithProductType: [ProductTypes.GOODS, ProductTypes.CORRECTION, ProductTypes.OVERDUE_FEE],
      hideWithProductTypeGrouping: [
        ProductTypes.SERVICES,
        ProductTypes.DIGITAL,
        ProductTypes.CREDIT,
        ProductTypes.PREPAYMENT,
        ProductTypes.INVOICE,
      ],
      showWithUIState: [
        UIStatesEnum.EDIT,
        UIStatesEnum.CREATE
      ],
    },
    {
      label: 'COLUMN.PROVIDED_FROM',
      prop: 'providedFrom',
      isOptional: true,
      disabled: (isFinalInvoice && !editableFinalInvoicePosition),
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.DATE,
      hideWithProductTypeGrouping: [
        ProductTypes.ARBITRARY,
        ProductTypes.GOODS,
        ProductTypes.CORRECTION,
        ProductTypes.CREDIT,
        ProductTypes.OVERDUE_FEE,
        ProductTypes.PREPAYMENT,
        ProductTypes.INVOICE,
      ],
      showWithUIState: [
        UIStatesEnum.EDIT,
        UIStatesEnum.CREATE
      ],
    },
    {
      label: 'COLUMN.PROVIDED_TO',
      prop: 'providedTo',
      isOptional: true,
      disabled: (isFinalInvoice && !editableFinalInvoicePosition),
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.DATE,
      hideWithProductType: [ProductTypes.GOODS, ProductTypes.CORRECTION, ProductTypes.OVERDUE_FEE, ProductTypes.CREDIT,],
      hideWithProductTypeGrouping: [
        ProductTypes.SERVICES,
        ProductTypes.DIGITAL,
        ProductTypes.PREPAYMENT,
        ProductTypes.INVOICE,
      ],
      showWithUIState: [
        UIStatesEnum.EDIT,
        UIStatesEnum.CREATE
      ],
    },
    {
      label: 'COLUMN.PROVIDED_TO',
      prop: 'providedTo',
      isOptional: true,
      disabled: (isFinalInvoice && !editableFinalInvoicePosition),
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.DATE,
      hideWithProductTypeGrouping: [
        ProductTypes.ARBITRARY,
        ProductTypes.GOODS,
        ProductTypes.CORRECTION,
        ProductTypes.CREDIT,
        ProductTypes.OVERDUE_FEE,
        ProductTypes.PREPAYMENT,
        ProductTypes.INVOICE,
      ],
      showWithUIState: [
        UIStatesEnum.EDIT,
        UIStatesEnum.CREATE
      ],
    },
    {
      label: 'COLUMN.PERIOD',
      prop: 'providedWithin',
      disabled: (isFinalInvoice && !editableFinalInvoicePosition),
      type: PositionItemTypeEnum.PRIMARY,
      hideWithProductType: [
        ProductTypes.GOODS,
        ProductTypes.CORRECTION,
        ProductTypes.SERVICES,
        ProductTypes.DIGITAL,
        ProductTypes.CREDIT,
        ProductTypes.OVERDUE_FEE,
        ProductTypes.PREPAYMENT,
        ProductTypes.INVOICE,
      ],
      showWithUIState: [UIStatesEnum.VIEW],
    },
    {
      label: 'COLUMN.PERIOD',
      prop: 'providedWithin',
      disabled: (isFinalInvoice && !editableFinalInvoicePosition),
      type: PositionItemTypeEnum.PRIMARY,
      hideWithProductTypeGrouping: [
        ProductTypes.ARBITRARY,
        ProductTypes.GOODS,
        ProductTypes.CORRECTION,
        ProductTypes.CREDIT,
        ProductTypes.OVERDUE_FEE,
        ProductTypes.PREPAYMENT,
        ProductTypes.INVOICE,
      ],
      showWithUIState: [UIStatesEnum.VIEW],
    },
    {
      label: 'COLUMN.DELIVERY_DATE',
      prop: 'delivered',
      disabled: (isFinalInvoice && !editableFinalInvoicePosition),
      type: cantEditColumn ? PositionItemTypeEnum.WITH_DATE : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.DATE,
      isOptional: true,
      hintId: 'deliveredPositionColumn',
      hideWithProductType: [
        ProductTypes.SERVICES,
        ProductTypes.DIGITAL,
        ProductTypes.CORRECTION,
        ProductTypes.CREDIT,
        ProductTypes.GOODS,
        ProductTypes.OVERDUE_FEE,
        ProductTypes.PREPAYMENT,
        ProductTypes.INVOICE,
      ],
    },
    {
      label: 'COLUMN.DELIVERY_DATE',
      prop: 'delivered',
      disabled: (isFinalInvoice && !editableFinalInvoicePosition),
      type: cantEditColumn ? PositionItemTypeEnum.WITH_DATE : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.DATE,
      isOptional: true,
      hintId: 'deliveredPositionColumn',
      hideWithProductType: [
        ProductTypes.ARBITRARY,
        ProductTypes.SERVICES,
        ProductTypes.DIGITAL,
        ProductTypes.CORRECTION,
        ProductTypes.CREDIT,
        ProductTypes.OVERDUE_FEE,
        ProductTypes.PREPAYMENT,
        ProductTypes.INVOICE,
      ],
    },
    {
      label: '',
      prop: '',
      disabled: (isFinalInvoice && !editableFinalInvoicePosition),
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.DISCOUNT,
      hideWithProductType: [
        ProductTypes.OVERDUE_FEE,
        ProductTypes.CORRECTION,
        ProductTypes.CREDIT,
        ProductTypes.PREPAYMENT,
        ProductTypes.INVOICE,
      ]
    },
    {
      label: 'COMMON.INTERNAL_REMARK_1',
      prop: 'remark',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.REMARK,
      bindValue: 'value',
      hideWithProductType: [
        ProductTypes.CORRECTION,
        ProductTypes.CREDIT,
      ],
    },
  ];

  positionInfoList = positionInfoList
    .filter((item) => !item.hideWithProductTypeGrouping || !item.hideWithProductTypeGrouping.includes(productTypeForGrouping))
    .filter((item) => !item.hideWithProductType || !item.hideWithProductType.includes(productType))
    .filter((item) => !item.showWithUIState || item.showWithUIState.includes(state));

  return positionInfoList;

}
