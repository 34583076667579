import { Component, Input, OnDestroy, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { get } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { MetricConversionPipe } from 'common/src/modules/rnpl-common/pipes';
import { VAT_LIST } from '../../constants';
import { ProductModel, ProductsService } from 'common/src/modules/products';
import { ProductTypes } from 'common/src/modules/products/product-types';
import { InfoItemModel, InfoItemType } from 'common/src/modules/ui-components/info-components/models/info-item.model';
import { TradePricesApiService } from '../../../trade-prices/services/trade-prices-api.service';
import { CommonModalsActionsEnum, WarningModalComponent } from 'common/src/modules/modals/modals-common';
import { SalesPriceListProduct } from '../../../trade-prices/models';

@Component({
  selector: 'rnpl-discount-volume-based-form',
  templateUrl: './discount-volume-based-form.component.html',
  styleUrls: ['./discount-volume-based-form.component.scss'],
})
export class DiscountVolumeBasedFormComponent implements OnDestroy, OnChanges {

  public volumes: any = [];
  public productTypes = ProductTypes;
  public readonly vatList = VAT_LIST;
  public localCurrency: string = '€';

  @Input() product: ProductModel;
  @Input() isReadonly: boolean = false;
  @Input() isSmallBusiness: boolean = false;
  @Input() showPurchasePrice: boolean = false;
  @Input() priceId: number;
  @Input() salesPriceListName: string;

  @Output() updated: EventEmitter<boolean> = new EventEmitter<boolean>();

  public readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private metricPipe: MetricConversionPipe,
    private dialog: MatDialog,
    private tradePricesApiService: TradePricesApiService,
    private productsService: ProductsService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.hasOwnProperty('product') || changes.hasOwnProperty('productPriceId')) {
        this.getVolumes();
      }
    }
  }

  public updateProductField(fieldValue: any, fieldName: string): void {
    if (fieldName === 'primeCosts') {
      fieldValue = this.toInteger(fieldValue.replace('.', '').replace(',', '.'));
    }
    fieldName = 'prices.price.' + fieldName;
    fieldValue = {
      value: fieldValue,
      salesPriceListName: this.salesPriceListName
    };
    this.productsService.updateProductField(this.product.id, {fieldName, fieldValue}, this.salesPriceListName)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.updated.emit(true));
  }


  public updateField(volumeId: number, fieldName: string, fieldValue: any): void {
    fieldValue = fieldValue === '' ? null : fieldValue;
    if (fieldName === 'marginRate' && fieldValue) {
      fieldValue = +fieldValue.replace('.', '').replace(',', '.');
    }
    this.updateVolume(volumeId, fieldName, fieldValue);
  }

  public updateQuantityField(volumeId: number, fieldName: string, fieldValue: any): void {
    fieldValue = fieldValue === '' ? null : fieldValue;
    if (fieldValue) {
      fieldValue = fieldValue.replace('.', '').replace(',', '.');
    }
    this.updateVolume(volumeId, fieldName, fieldValue);
  }

  public updateCurrencyField(volumeId: number, fieldName: string, fieldValue: any): void {
    fieldValue = fieldValue === '' ? null : fieldValue;
    if (fieldValue) {
      fieldValue = this.toInteger(fieldValue.replace('.', '').replace(',', '.'));
    }
    this.updateVolume(volumeId, fieldName, fieldValue);
  }

  public updatePriceField(fieldValue: any, fieldName: string): void {
    if (fieldName === 'primeCosts') {
      if (fieldValue) {
        fieldValue = this.toInteger(fieldValue.replace('.', '').replace(',', '.'));
      }

      if (!fieldValue && fieldValue !== 0) {
        fieldValue = null;
      }
    }
    this.tradePricesApiService.updatePriceListProduct(
      fieldName,
      fieldValue,
      this.priceId,
      this.product.prices.price.id,
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe((product: SalesPriceListProduct) => {
        this.product = {
          ...this.product,
          prices: {
            ...this.product.prices,
            price: {
              ...this.product.prices.price,
              vat: product.vat,
              primeCosts: product.primeCosts,
              grossPrice: product.grossPrice,
            }
          }
        };
        this.getVolumes();
        this.updated.emit(true);
      });
  }

  private toInteger(value: number | string): number {
    if (value === 0) { return value; }
    if (!value) { return; }
    return +this.metricPipe.transform(+value, 'TO-INTEGER').toFixed(0);
  }

  public fillControlValueWithConversion(value: number | string): number {
    if (value === 0) { return value; }
    if (!value) { return null; }
    return this.metricPipe.transform(+value, 'TO-FRACTIONAL') || null;
  }

  public addVolume(): void {
    this.tradePricesApiService.createVolume(this.product.prices.price.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(volumes => {
        this.volumes = volumes;
        this.updated.emit(true);
      });
  }

  public removeVolume(volumeId: number): void {
    const dialog = this.dialog.open(WarningModalComponent, {
      data: {
        title: 'VOLUME_DISCOUNT.REMOVE_MODAL_TTL',
        message: 'VOLUME_DISCOUNT.REMOVE_MODAL_MSG',
        confirmBtnText: 'BUTTON.REMOVE',
        confirmBtnIcon: 'minus',
      }
    });

    dialog.afterClosed().subscribe(res => {
      if (res === CommonModalsActionsEnum.CONFIRM) {
        this.tradePricesApiService.deleteVolume(this.product.prices.price.id, volumeId)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.getVolumes();
            this.updated.emit(true);
          });
      }
    });
  }

  public updateVolume(volumeId: number, fieldName: string, fieldValue: any): void {
    this.tradePricesApiService.updateVolume(this.product.prices.price.id, volumeId, fieldName, fieldValue)
      .pipe(takeUntil(this.destroy$))
      .subscribe(volumes => {
        this.volumes = volumes;
        this.updated.emit(true);
      });
  }

  public getVolumes(): void {
    if (!this.product) { return; }
    this.tradePricesApiService.getVolumes(this.product.prices.price.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(volumes => {
        this.isLoading$.next(false);
        this.volumes = volumes;
      });
  }

  get waPurchasePriceInfoItem(): InfoItemModel[] {
    return [
      {
        label: 'COLUMN.PURCHASE_PRICE_NET',
        value: get(this.product, 'prices.purchaseData.purchasePrice'),
        type: InfoItemType.WITH_CURRENCY,
        tableItem: true,
      }
    ];
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
