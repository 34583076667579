import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TranslatesApiService {

  private initCompeted = false;
  private readonly languages: string[] = ['en', 'de'];
  public translations: {[key: string]: Object} = {};

  constructor(
    private readonly http: HttpClient,
  ) {
  }

  public initTranslations(): Observable<any> {
    if (this.initCompeted) { return; }
    return forkJoin(this.languages.map(lang => this.getTranslation(lang)))
      .pipe(finalize(() => this.initCompeted = true));
  }

  private getTranslation(lang: string): Observable<any> {
    return this.http.get(`assets/i18n/${lang}.json`)
      .pipe(tap(res => this.translations[lang] = res))
  }

  public getTranslationValue(key: string, lang: string): string {
    if (!this.translations || !this.translations[lang]) { return key; }
    if (key.includes(' ')) { return key; }

    let value = this.translations[lang];

    key.split('.').map(itm => {
      value = value[itm] as any;
    });

    return value as any;
  }

}
