import { TableColumnModelExtended } from 'common/src/models/table-column.model';
import { TableActivateTypes } from 'common/src/modules/ui-components/table/custom-table.enums';
import { NavBarBtnModel } from 'common/src/models';
import {
  HighlightTypeEnum,
  TableSummaryBarItemModel,
  TableSummaryBarItemTypeEnum
} from 'common/src/modules/ui-components/table-summary-bar/table-summary-bar.model';
import { ReturnStockStatusTotalsModel } from '../../models';

export function StockStatusListColumns(): TableColumnModelExtended[] {
 return [
   {
     cellTemplate: 'numberRows',
     cellClass: 'center',
     name: 'Nr.',
     width: 40,
     maxWidth: 40,
     sortable: false,
     resizeable: false,
     customizable: true,
     headerClass: 'center',
   },
   {
     cellTemplate: 'withLinkPrimary',
     headerTemplate: 'headerPrimary',
     name: 'COLUMN.PRODUCT_ID',
     prop: 'productLink',
     cellClass: 'link-primary',
     sortable: true,
     customizable: true,
     width: 152,
     maxWidth: 152,
     clicktype: TableActivateTypes.Link,
   },
   {
     cellTemplate: 'withLinkPrimary',
     headerTemplate: 'headerPrimary',
     cellClass: 'link-primary inline',
     name: 'COLUMN.PRODUCT_NAME',
     preventTranslate: true,
     prop: 'productNameLink',
     sortable: true,
   },
   {
     cellTemplate: 'primaryWithNumber',
     headerTemplate: 'headerWithHints',
     hintsId: 'orderedColumn',
     name: 'COLUMN.RETURN',
     prop: 'returned',
     sortable: true,
     cellClass: 'right',
     unitTypeShortcut: true,
     customizable: true,
     width: 120,
     maxWidth: 120,
   },
   {
     cellTemplate: 'primaryWithNumber',
     headerTemplate: 'headerWithHints',
     hintsId: 'onStockColumn',
     name: 'COLUMN.ON_STOCK',
     prop: 'onStock',
     sortable: true,
     cellClass: 'right',
     unitTypeShortcut: true,
     customizable: true,
     width: 120,
     maxWidth: 120,
   },
   {
     cellTemplate: 'primaryWithNumber',
     headerTemplate: 'headerWithHints',
     hintsId: 'soldColumn',
     name: 'COLUMN.SOLD',
     prop: 'sold',
     sortable: true,
     cellClass: 'right',
     unitTypeShortcut: true,
     customizable: true,
     width: 120,
     maxWidth: 120,
   },
   {
     cellTemplate: 'primaryWithNumber',
     headerTemplate: 'headerWithHints',
     hintsId: 'availableColumn',
     name: 'COLUMN.AVAILABLE',
     prop: 'available',
     sortable: true,
     cellClass: 'right',
     unitTypeShortcut: true,
     customizable: true,
     width: 120,
     maxWidth: 120,
     highlightTypes: [HighlightTypeEnum.POSITIVE_TO_GREEN, HighlightTypeEnum.NEGATIVE_TO_RED],
   },
   {
     cellTemplate: 'primary',
     headerTemplate: 'headerPrimary',
     name: 'COLUMN.CATEGORY',
     prop: 'categoryPath',
     sortable: true,
     customizable: true,
   },
   {
     cellTemplate: 'withClickableIcon',
     name: '',
     width: 42,
     maxWidth: 42,
     sortable: false,
     resizeable: false,
     clicktype: TableActivateTypes.RowDetail,
     frozenRight: true,
     icon: 'arrow-micro-down',
     cellClass: 'px-4 cursor-pointer'
   }
 ];
}

// export function ReturnStockStatusInnerTableColumns(translateService: TranslateService): TableColumnModelExtended[] {
//  return [
//    {
//      cellTemplate: 'numberRows',
//      cellClass: 'center',
//      name: 'Nr.',
//      width: 38,
//      maxWidth: 38,
//      sortable: false,
//      resizeable: false,
//      headerClass: 'center'
//    },
//    {
//      cellTemplate: 'primary',
//      headerTemplate: 'headerPrimary',
//      name: 'COLUMN.DOCUMENT_TYPE',
//      prop: 'typeLabel',
//      sortable: true,
//      width: 140,
//      maxWidth: 140,
//    },
//    {
//      cellTemplate: 'withLinkPrimary',
//      headerTemplate: 'headerPrimary',
//      name: 'COLUMN.DOCUMENT_ID',
//      prop: 'documentLink',
//      cellClass: 'link-primary',
//      sortable: true,
//      width: 172,
//      maxWidth: 172,
//      // clicktype: TableActivateTypes.Link,
//    },
//    {
//      cellTemplate: 'primary',
//      headerTemplate: 'headerPrimary',
//      name: 'COLUMN.PARTNER_NAME',
//      prop: 'partnerName',
//      sortable: false,
//    },
//    {
//      cellTemplate: 'withDate',
//      headerTemplate: 'headerPrimary',
//      name: 'FORM.ESTIMATED_DELIVERY_DATE',
//      prop: 'estimatedDeliveryDate',
//      sortable: false,
//      width: 203,
//      minWidth: 203,
//    },
//    {
//      cellTemplate: 'primaryWithNumber',
//      headerTemplate: 'headerPrimary',
//      name: 'COLUMN.QUANTITY',
//      prop: 'open',
//      unitTypeShortcut: true,
//      sortable: false,
//      width: 98,
//      maxWidth: 98,
//    },
//  ];
// }

export const NavBarButtons: NavBarBtnModel[] = [
  {
    classes: 'btn-white',
    text: 'BUTTON.EXPORT',
    iconName: 'file-csv',
    disabled: false,
    actionName: 'onExportCsvClick',
    hintsId: 'exportBtn',
    multiple: false,
  },
];

export function getTableSummaryBarItems(totals: ReturnStockStatusTotalsModel): TableSummaryBarItemModel[] {
  return [
    {
      label: 'APP.PRODUCTS',
      titleIcon: 'box',
      type: TableSummaryBarItemTypeEnum.WITH_NUMBER,
      value: totals.products,
      id: 'totalRowsProduct',
    },
    {
      label: 'DOC_SUMMARY.EXPIRED',
      type: TableSummaryBarItemTypeEnum.WITH_NUMBER,
      highlightTypes: [HighlightTypeEnum.POSITIVE_TO_ORANGE],
      value: totals.expired,
      displayed: !!totals.expired
    },
  ];
}
