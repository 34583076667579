export const DASHBOARDS_PERIODS_LIST: {value: string; label: string}[] = [
  {value: 'CURRENT_MONTH', label: 'FORM.CURRENT_MONTH'},
  {value: 'PREVIOUS_MONTH', label: 'FORM.PREVIOUS_MONTH'},
  {value: 'CURRENT_QUARTER', label: 'FORM.CURRENT_QUARTER'},
  {value: 'PREVIOUS_QUARTER', label: 'FORM.PREVIOUS_QUARTER'},
  {value: 'CURRENT_YEAR', label: 'CASH_FLOW.CURRENT_YEAR'},
  {value: 'PREVIOUS_YEAR', label: 'CASH_FLOW.PREVIOUS_YEAR'},
  // {value: 'USER_DEFINED', label: 'USER_DEFINED'},
];

export const DASHBOARDS_UDF_RANGES: {value: string; label: string}[] = [
  {value: 'month', label: 'FORM.MONTH'},
  {value: 'quarter', label: 'FORM.QUARTER'},
  {value: 'year', label: 'FORM.YEAR'},
];

export const DASHBOARDS_MONTHS: {value: number; label: string}[] = [
  {value: 1, label: 'MONTHS.JAN'},
  {value: 2, label: 'MONTHS.FEB'},
  {value: 3, label: 'MONTHS.MAR'},
  {value: 4, label: 'MONTHS.APR'},
  {value: 5, label: 'MONTHS.MAY'},
  {value: 6, label: 'MONTHS.JUN'},
  {value: 7, label: 'MONTHS.JUL'},
  {value: 8, label: 'MONTHS.AUG'},
  {value: 9, label: 'MONTHS.SEP'},
  {value: 10, label: 'MONTHS.OCT'},
  {value: 11, label: 'MONTHS.NOV'},
  {value: 12, label: 'MONTHS.DEC'},
];

export const DASHBOARDS_QUARTERS: {value: number; label: string}[] = [
  {value: 1, label: 'RANGES.JAN_MAR'},
  {value: 2, label: 'RANGES.APR_JUN'},
  {value: 3, label: 'RANGES.JUL_SEP'},
  {value: 4, label: 'RANGES.OCT_DEC'},
];
