import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { map, tap, finalize, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { AppState } from '../../../../store/state/app.state';
import { environment } from '../../../../../environments/environment';
import {
  EcoSettingsDeliveryModel,
  EcoSettingsDeliveryServiceModel,
  EcoSettingsPaymentsModel,
  EcoSettingsCategoryAssignedAttributeModel,
  EcoSettingsBlockModel,
  EcoSettingsBrandingModel,
  EcoSettingsAnnouncementModel,
  EcoSettingsBlockUpdateModel,
  EcoSettingsProductPageModel,
  EcoSettingsCustomerCareModel,
  EcoSettingsPredefinedCategoryModel,
  EcoSettingsDeliveryCountryModel,
  EcoSettingsStaticPageModel,
  EcoSettingsSocialsModel,
  EcoSettingsReturnsModel,
  EcoSettingsReturnCountryModel,
  EcoIntegrationModel
} from '../models';
import { FormInputChangedModel, ResponseModel } from '../../../../shared/models';
import {
  DecrementLoadingRequestsCount,
  IncrementLoadingRequestsCount,
  UpdateEcoSettingsTracking,
  UpdateEcoSettingsUpdatedAt
} from '../store/actions/eco-settings.actions';
import { FamilyModel } from 'common/src/modules/products';
import { AddressModel } from 'common/src/models';
import { EcoIntegrationTypeEnum, EcoStaticPageTypeEnum } from '../enums';
import { selectCompanyProfile } from '../../../../administration/store/selectors';
import { CompanyProfile } from '../../../../administration/models/company-profile.model';
import { DisplayToaster } from '../../../../shared/decorators/toaster';

@Injectable({
  providedIn: 'root'
})
export class EcoSettingsApiService {
  private readonly apiEndpoint: string = `${environment.javaEcommerceApiVersion}/admin`;
  private wid: number;

  private apiUrl(url: string = ''): string {
    return this.apiEndpoint + url;
  }

  constructor(
    private readonly toasterService: ToasterService,
    private readonly translateService: TranslateService,
    private readonly http: HttpClient,
    private readonly store: Store<AppState>,
  ) {
      this.store.select(selectCompanyProfile)
        .subscribe((profile: CompanyProfile) => {
          this.wid = profile.workspaceId;
        });
  }

  public getSliderSpeedList(): Observable<{value: number, label: string}[]> {
    return of([
      {value: 1, label: this.translateService.instant('ECOMMERCE.N_SECONDS', {n: 1})},
      {value: 2, label: this.translateService.instant('ECOMMERCE.N_SECONDS', {n: 2})},
      {value: 3, label: this.translateService.instant('ECOMMERCE.N_SECONDS', {n: 3})},
      {value: 4, label: this.translateService.instant('ECOMMERCE.N_SECONDS', {n: 4})},
    ]);
  }

  public getEcoUploadImageLink(): string {
    return `${environment.javaApiUrl}${environment.javaEcommerceApiVersion}/image`;
  }

  public publishEcoSettings(): Observable<any> {
    return this.http.request('post', this.apiUrl('/settings/all/publish'))
      .pipe(
        tap(() => this.toasterService.notify({type: 'success', message: 'ECOMMERCE.SUCCESS_PUBLISH_TOAST'})),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public trackEcoSettings(): Observable<{ changed: boolean, publishedAt: Date }> {
    return this.http.get<ResponseModel<{ changed: boolean, publishedAt: Date }>>(this.apiUrl('/settings/tracking'))
      .pipe(
        map((response: ResponseModel<{ changed: boolean, publishedAt: Date }>) => response.data),
        tap((tracking: { changed: boolean, publishedAt: Date }) => {
          this.store.dispatch(UpdateEcoSettingsTracking({tracking}));
        }),
      );
  }

  public getEcoIntegrations(): Observable<EcoIntegrationModel[]> {
    return this.http.get<ResponseModel<EcoIntegrationModel[]>>(this.apiUrl(`/${this.wid}/settings/integrations`))
      .pipe(map((response: ResponseModel<EcoIntegrationModel[]>) => response.data));
  }

  public getEcoIntegration(
    integrationType: EcoIntegrationTypeEnum,
    integrationId: number,
  ): Observable<EcoIntegrationModel> {
    return this.http.get<ResponseModel<EcoIntegrationModel>>(
      this.apiUrl(`/${this.wid}/settings/integrations/${integrationType}/${integrationId}`))
      .pipe(map((response: ResponseModel<EcoIntegrationModel>) => response.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public addEcoIntegration(
    integrationBody: EcoIntegrationModel
  ): Observable<EcoIntegrationModel> {
    const body = integrationBody;
    return this.http.post<ResponseModel<EcoIntegrationModel>>(
      this.apiUrl(`/${this.wid}/settings/integrations`),
      body
    )
      .pipe(map((response: ResponseModel<EcoIntegrationModel>) => response.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public updateEcoIntegration(
    integrationType: EcoIntegrationTypeEnum,
    integrationId: number,
    integrationBody: EcoIntegrationModel
  ): Observable<EcoIntegrationModel> {
    const body = integrationBody;
    return this.http.put<ResponseModel<EcoIntegrationModel>>(
      this.apiUrl(`/${this.wid}/settings/integrations/${integrationType}/${integrationId}`),
      body
    )
      .pipe(map((response: ResponseModel<EcoIntegrationModel>) => response.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public deleteEcoIntegration(integrationType: EcoIntegrationTypeEnum, integrationId: number): Observable<any> {
    return this.http.delete<ResponseModel<any>>(this.apiUrl(`/${this.wid}/settings/integrations/${integrationType}/${integrationId}`));
  }

  // public getEcoSettingsCountries(): Observable<any> {
  //   return this.http.get<ResponseModel<any>>(`${environment.javaEcommerceApiVersion}/utils/countries`)
  //     .pipe(map((response: ResponseModel<any>) => response.data));
  // }

  // BRANDING
  public getEcoSettingsBranding(published = false): Observable<EcoSettingsBrandingModel> {
    const params = { published: published as any };
    return this.http.get<ResponseModel<EcoSettingsBrandingModel>>(this.apiUrl('/settings/branding'), { params })
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsBrandingModel>) => response.data)
      );
  }

  public updateEcoSettingsBranding(field: FormInputChangedModel): Observable<EcoSettingsBrandingModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<EcoSettingsBrandingModel>>(this.apiUrl('/settings/branding'), field)
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsBrandingModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        finalize(() => this.trackEcoSettings().subscribe()),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  // public publishEcoSettingsBranding(): Observable<any> {
  //   return this.http.request<ResponseModel<any>>('post', this.apiUrl('/settings/branding/publish'));
  // }
  // @END BRANDING


  // GLOBAL ANNOUNCEMENTS
  public getEcoSettingsAnnouncement(): Observable<EcoSettingsAnnouncementModel> {
    return this.http.get<ResponseModel<EcoSettingsAnnouncementModel>>(this.apiUrl('/settings/global-announcement'))
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsAnnouncementModel>) => response.data)
      );
  }

  public updateEcoSettingsAnnouncement(field: FormInputChangedModel): Observable<EcoSettingsAnnouncementModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<EcoSettingsAnnouncementModel>>(this.apiUrl('/settings/global-announcement'), field)
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsAnnouncementModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        finalize(() => this.trackEcoSettings().subscribe()),
      );
  }

  // public publishEcoSettingsAnnouncement(): Observable<any> {
  //   return this.http.request<ResponseModel<any>>('post', this.apiUrl('/settings/global-announcement/publish'));
  // }
  // @END GLOBAL ANNOUNCEMENTS


  // SOCIALS
  public getEcoSettingsSocials(): Observable<EcoSettingsSocialsModel> {
    return this.http.get<ResponseModel<EcoSettingsSocialsModel>>(this.apiUrl('/settings/socials'))
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsSocialsModel>) => response.data)
      );
  }

  public updateEcoSettingsSocials(field: FormInputChangedModel): Observable<EcoSettingsSocialsModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<EcoSettingsSocialsModel>>(this.apiUrl('/settings/socials'), field)
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsSocialsModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        finalize(() => this.trackEcoSettings().subscribe()),
      );
  }

  // public publishEcoSettingsSocials(): Observable<any> {
  //   return this.http.request<ResponseModel<any>>('post', this.apiUrl('/settings/socials/publish'));
  // }
  // @END SOCIALS


  // PRODUCT PAGE
  public getEcoSettingsProductPage(): Observable<EcoSettingsProductPageModel> {
    return this.http.get<ResponseModel<EcoSettingsProductPageModel>>(this.apiUrl('/settings/product-page'))
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsProductPageModel>) => response.data)
      );
  }

  public updateEcoSettingsProductPage(field: FormInputChangedModel): Observable<EcoSettingsProductPageModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<EcoSettingsProductPageModel>>(this.apiUrl('/settings/product-page'), field)
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsProductPageModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        finalize(() => this.trackEcoSettings().subscribe()),
      );
  }

  // public publishEcoSettingsProductPage(): Observable<any> {
  //   return this.http.request<ResponseModel<any>>('post', this.apiUrl('/settings/product-page/publish'));
  // }
  // @END PRODUCT PAGE



  // CUSTOMER CARE
  public getEcoSettingsCustomerCare(): Observable<EcoSettingsCustomerCareModel> {
    return this.http.get<ResponseModel<EcoSettingsCustomerCareModel>>(this.apiUrl('/settings/customer-care'))
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsCustomerCareModel>) => response.data)
      );
  }

  public updateEcoSettingsCustomerCare(field: FormInputChangedModel): Observable<EcoSettingsCustomerCareModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<EcoSettingsCustomerCareModel>>(this.apiUrl('/settings/customer-care'), field)
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsCustomerCareModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        finalize(() => this.trackEcoSettings().subscribe()),
      );
  }

  // public publishEcoSettingsCustomerCare(): Observable<any> {
  //   return this.http.request<ResponseModel<any>>('post', this.apiUrl('/settings/customer-care/publish'));
  // }
  // @END CUSTOMER CARE


  // PAYMENTS METHODS
  public getEcoSettingsPayments(): Observable<EcoSettingsPaymentsModel> {
    return this.http.get<ResponseModel<EcoSettingsPaymentsModel>>(this.apiUrl('/settings/payments'))
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsPaymentsModel>) => response.data)
      );
  }

  public updateEcoSettingsPayments(field: FormInputChangedModel): Observable<EcoSettingsPaymentsModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<EcoSettingsPaymentsModel>>(this.apiUrl('/settings/payments'), field)
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsPaymentsModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        finalize(() => this.trackEcoSettings().subscribe()),
        // catchError(error => {
        //   this.handlePopupErrors(error);
        //   return throwError(error);
        // })
      );
  }

  // public publishEcoSettingsPayments(): Observable<any> {
  //   return this.http.request<ResponseModel<any>>('post', this.apiUrl('/settings/payments/publish'));
  // }
  // @END PAYMENTS METHODS


  // RETURNS
  public getEcoSettingsReturns(): Observable<EcoSettingsReturnsModel> {
    return this.http.get<ResponseModel<EcoSettingsReturnsModel>>(this.apiUrl('/settings/returns'))
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsReturnsModel>) => response.data)
      );
  }

  public updateEcoSettingsReturnsCountry(
    deliveryCountrySettings: EcoSettingsReturnCountryModel,
    deliveryCountryId: number
  ): Observable<EcoSettingsReturnCountryModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<EcoSettingsReturnCountryModel>>(
      this.apiUrl(`/settings/returns/countries/${deliveryCountryId}`),
      deliveryCountrySettings
    )
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsReturnCountryModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        finalize(() => this.trackEcoSettings().subscribe()),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public createEcoSettingsReturnsCountry(deliveryCountrySettings: EcoSettingsReturnCountryModel): Observable<EcoSettingsReturnCountryModel> {
    return this.http.post<ResponseModel<EcoSettingsReturnCountryModel>>(
      this.apiUrl('/settings/returns/countries'),
      {...deliveryCountrySettings}
    )
      .pipe(
        map((response: ResponseModel<EcoSettingsReturnCountryModel>) => response.data),
        finalize(() => this.trackEcoSettings().subscribe()),
      );
  }

  public updateEcoSettingsReturnsField(field: FormInputChangedModel): Observable<EcoSettingsReturnsModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<EcoSettingsReturnsModel>>(
      this.apiUrl(`/settings/returns`),
      field
    )
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsReturnsModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        finalize(() => this.trackEcoSettings().subscribe()),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public updateEcoSettingsReturnsCountryField(deliveryCountrySettingsId: number, field: FormInputChangedModel): Observable<EcoSettingsDeliveryCountryModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<EcoSettingsDeliveryCountryModel>>(
      this.apiUrl(`/settings/returns/countries/${deliveryCountrySettingsId}/field`),
      field
    )
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsDeliveryCountryModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        finalize(() => this.trackEcoSettings().subscribe()),
        // catchError(error => {
        //   this.handlePopupErrors(error, salesOrderId);
        //   return throwError(error);
        // })
      );
  }

  public ecoSettingsReturnsRemoveCountry(deliveryCountrySettingsId: number): Observable<EcoSettingsReturnsModel> {
    return this.http.delete<ResponseModel<EcoSettingsReturnsModel>>(this.apiUrl(`/settings/returns/countries/${deliveryCountrySettingsId}`))
      .pipe(
        map((response: ResponseModel<EcoSettingsReturnsModel>) => response.data),
        finalize(() => this.trackEcoSettings().subscribe()),
      );
  }

  // public publishEcoSettingsReturns(): Observable<any> {
  //   return this.http.request<ResponseModel<any>>('post', this.apiUrl('/settings/returns/publish'));
  // }
  // @END RETURNS


  // DELIVERY TERMS
  public getEcoSettingsDeliveryServices(): Observable<EcoSettingsDeliveryServiceModel[]> {
    return this.http.get<ResponseModel<EcoSettingsDeliveryServiceModel[]>>(this.apiUrl('/settings/delivery-services'))
      .pipe(map((response: ResponseModel<EcoSettingsDeliveryServiceModel[]>) => response.data));
  }

  public getEcoSettingsDelivery(): Observable<EcoSettingsDeliveryModel> {
    return this.http.get<ResponseModel<EcoSettingsDeliveryModel>>(this.apiUrl('/settings/delivery'))
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsDeliveryModel>) => response.data)
      );
  }

  public updateEcoSettingsDelivery(
    deliveryCountrySettings: EcoSettingsDeliveryCountryModel,
    deliveryCountrySettingsId: number
  ): Observable<EcoSettingsDeliveryCountryModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<EcoSettingsDeliveryCountryModel>>(
      this.apiUrl(`/settings/delivery/countries/${deliveryCountrySettingsId}`),
      deliveryCountrySettings
    )
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsDeliveryCountryModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        finalize(() => this.trackEcoSettings().subscribe()),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getEcoSettingsDeliveryCorporateAddress(): Observable<AddressModel> {
    return this.http.get<ResponseModel<AddressModel>>(this.apiUrl(`/settings/delivery/corporate-address`))
      .pipe(map((response: ResponseModel<AddressModel>) => response.data));
  }

  public createEcoSettingsDeliveryCountry(deliveryCountrySettings: EcoSettingsDeliveryCountryModel): Observable<EcoSettingsDeliveryCountryModel> {
    return this.http.post<ResponseModel<EcoSettingsDeliveryCountryModel>>(this.apiUrl('/settings/delivery/countries'), {...deliveryCountrySettings})
      .pipe(
        map((response: ResponseModel<EcoSettingsDeliveryCountryModel>) => response.data),
        finalize(() => this.trackEcoSettings().subscribe()),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public updateEcoSettingsDeliveryField(deliveryCountrySettingsId: number, field: FormInputChangedModel): Observable<EcoSettingsDeliveryCountryModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<EcoSettingsDeliveryCountryModel>>(
      this.apiUrl(`/settings/delivery/countries/${deliveryCountrySettingsId}/field`),
      field
    )
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsDeliveryCountryModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        finalize(() => this.trackEcoSettings().subscribe()),
        // catchError(error => {
        //   this.handlePopupErrors(error, salesOrderId);
        //   return throwError(error);
        // })
      );
  }

  public ecoSettingsDeliveryRemoveCountry(deliveryCountrySettingsId: number): Observable<EcoSettingsDeliveryModel> {
    return this.http.delete<ResponseModel<EcoSettingsDeliveryModel>>(this.apiUrl(`/settings/delivery/countries/${deliveryCountrySettingsId}`))
      .pipe(
        map((response: ResponseModel<EcoSettingsDeliveryModel>) => response.data),
        finalize(() => this.trackEcoSettings().subscribe()),
        );
  }

  // public publishEcoSettingsDelivery(): Observable<any> {
  //   return this.http.request<ResponseModel<any>>('post', this.apiUrl('/settings/delivery/publish'));
  // }
  // @END DELIVERY TERMS


  // PRODUCT CARD
  public getEcoSettingsCategoriesTree(): Observable<FamilyModel[]> {
    return this.http.get<ResponseModel<FamilyModel[]>>(this.apiUrl('/category/tree'))
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<FamilyModel[]>) => response.data)
      );
  }

  public getEcoSettingsCategoriesList(): Observable<FamilyModel[]> {
    return this.http.get<ResponseModel<FamilyModel[]>>(this.apiUrl('/category/list'))
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<FamilyModel[]>) => response.data)
      );
  }

  public getEcoSettingsCategoryAssignedAttrs(categoryId: number): Observable<EcoSettingsCategoryAssignedAttributeModel[]> {
    return this.http.get<ResponseModel<EcoSettingsCategoryAssignedAttributeModel[]>>(
      this.apiUrl(`/category/${categoryId}/attributes/assigned`)
    )
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsCategoryAssignedAttributeModel[]>) => response.data)
      );
  }

  public getEcoSettingsCategoryAvailableAttrs(categoryId: number): Observable<string[]> {
    return this.http.get<ResponseModel<string[]>>(this.apiUrl(`/category/${categoryId}/attributes/available`))
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<string[]>) => response.data)
      );
  }

  public addEcoSettingsCategoryAttr(
    familyId : number,
    fieldName: string,
    position: number,
    size: '1x1'|'1x2'|'1x3' = '1x1'
  ): Observable<EcoSettingsCategoryAssignedAttributeModel[]> {
    const body = {
      fieldName,
      position,
      size
    };
    return this.http.post<ResponseModel<EcoSettingsCategoryAssignedAttributeModel[]>>(this.apiUrl(`/category/${familyId }/attributes`), body)
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsCategoryAssignedAttributeModel[]>) => response.data),
        finalize(() => this.trackEcoSettings().subscribe()),
      );
  }

  public updateEcoSettingsCategoryAttr(
    familyId : number,
    attributeId : number,
    fieldName: string,
    position: number,
    size = '1x1'
  ): Observable<EcoSettingsCategoryAssignedAttributeModel[]> {
    const body = {
      fieldName,
      position,
      size
    };
    return this.http.patch<ResponseModel<EcoSettingsCategoryAssignedAttributeModel[]>>(
      this.apiUrl(`/category/${familyId }/attributes/${attributeId}`),
      body
    )
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsCategoryAssignedAttributeModel[]>) => response.data),
        finalize(() => this.trackEcoSettings().subscribe()),
      );
  }

  public updateEcoSettingsCategoryAttrOrder(familyId : number, ids : number[]): Observable<null> {
    const body = { ids };
    return this.http.patch<null>(this.apiUrl(`/category/${familyId }/attributes/order`), body)
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        finalize(() => this.trackEcoSettings().subscribe()),
      );
  }

  public removeEcoSettingsCategoryAttr(categoryId: number, attrId: number): Observable<EcoSettingsCategoryAssignedAttributeModel[]> {
    return this.http.delete<ResponseModel<EcoSettingsCategoryAssignedAttributeModel[]>>(
      this.apiUrl(`/category/${categoryId}/attributes/${attrId}`)
    )
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsCategoryAssignedAttributeModel[]>) => response.data),
        finalize(() => this.trackEcoSettings().subscribe()),
      );
  }

  // public publishEcoSettingsProductCard(categoryId: number): Observable<any> {
  //   return this.http.request<ResponseModel<any>>('post', this.apiUrl(`/category/${categoryId}/attributes/publish`));
  // }
  // @END PRODUCT CARD


  // HOMEPAGE BLOCKS
  public getEcoSettingsBlocks(): Observable<EcoSettingsBlockModel[]> {
    return this.http.get<ResponseModel<EcoSettingsBlockModel[]>>(this.apiUrl('/blocks'))
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsBlockModel[]>) => response.data)
      );
  }

  public ecoSettingsDeleteBlock(blockId: number): Observable<any> {
    return this.http.delete<ResponseModel<any>>(this.apiUrl(`/blocks/${blockId}`))
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        finalize(() => this.trackEcoSettings().subscribe()),
      );
  }

  public ecoSettingsCreateBlock(data: EcoSettingsBlockUpdateModel): Observable<EcoSettingsBlockModel> {
    return this.http.post<ResponseModel<EcoSettingsBlockModel>>(this.apiUrl('/blocks'), data)
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsBlockModel>) => response.data),
        finalize(() => this.trackEcoSettings().subscribe()),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public ecoSettingsUpdateBlock(blockId: number, data: EcoSettingsBlockUpdateModel): Observable<EcoSettingsBlockModel> {
    return this.http.patch<ResponseModel<EcoSettingsBlockModel>>(this.apiUrl(`/blocks/${blockId}`), data)
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsBlockModel>) => response.data),
        finalize(() => this.trackEcoSettings().subscribe()),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public ecoSettingsUpdateBlocksOrder(ids: number[]): Observable<EcoSettingsBlockModel[]> {
    return this.http.patch<ResponseModel<EcoSettingsBlockModel[]>>(this.apiUrl(`/blocks/positions`), {ids})
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsBlockModel[]>) => response.data),
        finalize(() => this.trackEcoSettings().subscribe())
      );
  }

  // public publishEcoSettingsBlocks(): Observable<any> {
  //   return this.http.request<ResponseModel<any>>('post', this.apiUrl(`/blocks/publish`));
  // }
  // @END HOMEPAGE BLOCKS


  // BESTSELLER BLOCKS
  public getEcoSettingsBestseller(): Observable<EcoSettingsPredefinedCategoryModel> {
    return this.http.get<ResponseModel<EcoSettingsPredefinedCategoryModel>>(this.apiUrl('/settings/bestseller'))
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsPredefinedCategoryModel>) => response.data)
      );
  }

  public updateEcoSettingsBestseller(field: FormInputChangedModel): Observable<EcoSettingsPredefinedCategoryModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<EcoSettingsPredefinedCategoryModel>>(this.apiUrl('/settings/bestseller'), field)
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsPredefinedCategoryModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        finalize(() => this.trackEcoSettings().subscribe())
      );
  }

  // public publishEcoSettingsBestseller(): Observable<any> {
  //   return this.http.request<ResponseModel<any>>('post', this.apiUrl(`/settings/bestseller/publish`));
  // }
  // @END BESTSELLER BLOCKS


  // NEW ITEMS BLOCKS
  public getEcoSettingsNewItems(): Observable<EcoSettingsPredefinedCategoryModel> {
    return this.http.get<ResponseModel<EcoSettingsPredefinedCategoryModel>>(this.apiUrl('/settings/new-item'))
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsPredefinedCategoryModel>) => response.data)
      );
  }

  public updateEcoSettingsNewItems(field: FormInputChangedModel): Observable<EcoSettingsPredefinedCategoryModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<EcoSettingsPredefinedCategoryModel>>(this.apiUrl('/settings/new-item'), field)
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsPredefinedCategoryModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        finalize(() => this.trackEcoSettings().subscribe())
      );
  }

  // public publishEcoSettingsNewItems(): Observable<any> {
  //   return this.http.request<ResponseModel<any>>('post', this.apiUrl(`/settings/new-item/publish`));
  // }
  // @END NEW ITEMS BLOCKS


  // SALE BLOCKS
  public getEcoSettingsSale(): Observable<EcoSettingsPredefinedCategoryModel> {
    return this.http.get<ResponseModel<EcoSettingsPredefinedCategoryModel>>(this.apiUrl('/settings/sale'))
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsPredefinedCategoryModel>) => response.data)
      );
  }

  public updateEcoSettingsSale(field: FormInputChangedModel): Observable<EcoSettingsPredefinedCategoryModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<EcoSettingsPredefinedCategoryModel>>(this.apiUrl('/settings/sale'), field)
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsPredefinedCategoryModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        finalize(() => this.trackEcoSettings().subscribe())
      );
  }

  // public publishEcoSettingsSale(): Observable<any> {
  //   return this.http.request<ResponseModel<any>>('post', this.apiUrl(`/settings/sale/publish`));
  // }
  // @END SALE BLOCKS


  // STATIC PAGES BLOCKS
  public getEcoSettingsStaticPage(pageType: EcoStaticPageTypeEnum): Observable<EcoSettingsStaticPageModel> {
    const pageTypeStr: string = pageType.toLowerCase().split('_').join('-');

    return this.http.get<ResponseModel<EcoSettingsStaticPageModel>>(this.apiUrl(`/static/${pageTypeStr}`))
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsStaticPageModel>) => response.data)
      );
  }

  public updateEcoSettingsStaticPage(pageType: EcoStaticPageTypeEnum, field: FormInputChangedModel): Observable<EcoSettingsStaticPageModel> {
    const pageTypeStr: string = pageType.toLowerCase().split('_').join('-');
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<EcoSettingsStaticPageModel>>(this.apiUrl(`/static/${pageTypeStr}`), field)
      .pipe(
        tap(() => this.store.dispatch(UpdateEcoSettingsUpdatedAt({updatedAt: new Date()}))),
        map((response: ResponseModel<EcoSettingsStaticPageModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        finalize(() => this.trackEcoSettings().subscribe())
      );
  }

  // public publishEcoSettingsStaticPage(pageType: EcoStaticPageTypeEnum): Observable<any> {
  //   const pageTypeStr: string = pageType.toLowerCase().split('_').join('-');
  //
  //   return this.http.request<ResponseModel<any>>('post', this.apiUrl(`/static/${pageTypeStr}/publish`));
  // }
  // @END STATIC PAGES BLOCKS


  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  private handlePopupErrors(error: HttpErrorResponse): void {
    if (error.error.errors) {
      error.error.errors.forEach(errorText => {
        this.showMsg('error', errorText);
      });
    }

    if (error.error.message) {
      this.showMsg('error', error.error.message);
    }
  }

}
