import { AppActionModel, AppActionsListModel, AppNameEnum, LaunchpadNavEnum, LaunchPadNavModel } from './launchpad.model';
import { ProductTypes } from '../products/product-types';
import { NavItem } from '../ui-components/head-navigation-process-based/nav-items.config';

export function getLaunchpadNav(shortList: boolean): LaunchPadNavModel[] {
  const launchPadNav: LaunchPadNavModel[] = [
    {
      tabName: LaunchpadNavEnum.START_SCREEN,
      // label: 'Startbildschirm'
      label: 'CONTROL_CENTER.SHORTCUTS'
    },
    {
      tabName: LaunchpadNavEnum.SALES,
      label: 'MODULE.SALES',
      hidden: shortList
    },
    {
      tabName: LaunchpadNavEnum.PURCHASE,
      label: 'MODULE.PURCHASES',
      hidden: shortList
    },
    {
      tabName: LaunchpadNavEnum.ACCOUNTING,
      label: 'MODULE.ACCOUNTING',
      hidden: shortList
    },
    {
      tabName: LaunchpadNavEnum.WAREHOUSE,
      label: 'MODULE.WAREHOUSE',
      hidden: shortList
    },
    {
      tabName: LaunchpadNavEnum.ANALYTICS,
      label: 'MODULE.ANALYTICS',
      hidden: shortList
    },
    {
      tabName: LaunchpadNavEnum.MANAGEMENT,
      label: 'APP.MANAGEMENT',
      hidden: shortList
    },
    {
      tabName: LaunchpadNavEnum.ALL_FUNCTIONS,
      label: 'Alle Funktionen'
    },
  ];

  return launchPadNav.filter(itm => !itm.hasOwnProperty('hidden') || !itm.hidden);
}

export function getListItemsByActiveTab(
  activeTab: LaunchpadNavEnum,
  canViewAccounting: boolean,
  canViewAdministration: boolean,
  canEditAdministration: boolean,
  canViewAnalytics: boolean,
  canViewEcommerce: boolean,
  canViewPartners: boolean,
  canViewProducts: boolean,
  canViewRetail: boolean,
  canViewServices: boolean,
  canViewTeam: boolean,
  canViewWarehouse: boolean,
  canViewWholesale: boolean,
  wholesaleEnabled: boolean,
  ecommerceEnabled: boolean,
  servicesEnabled: boolean,
  goodsProductTypeEnabled: boolean,
  servicesProductTypeEnabled: boolean,
  digitalProductTypeEnabled: boolean,
  corporatePartnersEnabled: boolean,
  privatePartnersEnabled: boolean,
  canManageWarehouse: boolean,
  canManageProducts: boolean,
  canManagePartners: boolean,
  canManageAccounting: boolean,
  isFreeOrAccountingPlan: boolean
): NavItem[] {

  const productTypeRedirect = goodsProductTypeEnabled
    ? ProductTypes.GOODS
    : servicesProductTypeEnabled
      ? ProductTypes.SERVICES
      : ProductTypes.DIGITAL;

  const OFFERS_ITEM: NavItem = {
    name: 'APP.OFFERS',
    show: canViewWholesale && (wholesaleEnabled || isFreeOrAccountingPlan || (servicesEnabled && !wholesaleEnabled && !ecommerceEnabled)),
    iconName: 'offer',
    route: '/trade/offers',
    action: 'createNewOffer',
    mainColor: 'yellow',
    type: AppNameEnum.OFFERS
  };

  const SALES_ORDERS_ITEM: NavItem = {
    name: 'APP.SALES_ORDERS',
    show: canViewWholesale && wholesaleEnabled,
    iconName: 'sales-order',
    route: '/trade/sales-order',
    action: 'createNewOrder',
    mainColor: 'yellow',
    type: AppNameEnum.SALES_ORDERS
  };

  const PURCHASE_ORDERS_ITEM: NavItem = {
    name: 'APP.PURCHASE_ORDERS',
    show: (canViewWholesale || canViewEcommerce) && (wholesaleEnabled || ecommerceEnabled),
    iconName: 'purchase-order',
    route: '/trade/purchase-order',
    action: 'createNewPurchaseOrder',
    mainColor: 'yellow',
    type: AppNameEnum.PURCHASE_ORDERS
  };

  const SALES_PRICES_ITEM: NavItem = {
    name: 'APP.PRICES',
    show: (canViewWholesale || canViewServices) && (wholesaleEnabled || servicesEnabled || isFreeOrAccountingPlan),
    iconName: 'pricetag',
    route: `/trade/prices/sales`,
    mainColor: 'yellow',
    type: AppNameEnum.SALES_PRICES
  };

  const PURCHASE_PRICES_ITEM: NavItem = {
    name: 'APP.PRICES',
    show: (canViewWholesale || canViewServices || canViewEcommerce),
    iconName: 'pricetag',
    route: `/trade/prices/purchase`,
    mainColor: 'yellow',
    type: AppNameEnum.PURCHASE_PRICES
  };

  const ERA_ITEM: NavItem = {
    name: 'APP.RETURNS',
    show: canViewWholesale && (wholesaleEnabled || ecommerceEnabled),
    iconName: 'exchange-return',
    route: '/trade/exchanges-and-returns',
    action: 'createNewERA',
    mainColor: 'yellow',
    type: AppNameEnum.RETURNS
  };

  const INCOMING_DELIVERIES_ITEM: NavItem = {
    name: 'APP.INCOMING_DELIVERIES',
    show: canViewWarehouse && (wholesaleEnabled || ecommerceEnabled),
    iconName: 'incoming-delivery',
    route: '/warehouse/incoming-delivery',
    mainColor: 'orange',
    type: AppNameEnum.INCOMING_DELIVERIES
  };

  const OUTGOING_DELIVERIES_ITEM: NavItem = {
    name: 'APP.OUTGOING_DELIVERIES',
    show: canViewWarehouse && (wholesaleEnabled || ecommerceEnabled),
    iconName: 'outgoing-delivery',
    route: '/warehouse/outgoing-delivery',
    action: 'createNewDeliveryNote',
    mainColor: 'orange',
    type: AppNameEnum.OUTGOING_DELIVERIES
  };

  const IIN_ITEM: NavItem = {
    name: 'APP.INCOMING_INVOICES',
    show: canViewAccounting,
    iconName: 'incoming-invoice',
    route: '/accounting/incoming-invoice',
    action: 'createNewIncomingInvoice',
    mainColor: 'violet',
    type: AppNameEnum.INCOMING_INVOICES
  };

  const OIN_ITEM: NavItem = {
    name: 'APP.OUTGOING_INVOICES',
    show: canViewAccounting,
    iconName: 'outgoing-invoice',
    route: '/accounting/outgoing-invoice',
    action: 'createNewInvoice',
    mainColor: 'violet',
    type: AppNameEnum.OUTGOING_INVOICES
  };

  const STOCK_STATUS_ITEM: NavItem = {
    name: 'APP.STOCK_STATUS',
    show: goodsProductTypeEnabled && canViewWarehouse && (wholesaleEnabled || ecommerceEnabled),
    iconName: 'cubes',
    route: '/warehouse/stock-status',
    mainColor: 'orange',
    type: AppNameEnum.STOCK_STATUS
  };

  const OPEN_ITEMS_LIST_ITEM: NavItem = {
    name: 'APP.OPEN_ITEM_LIST',
    show: canViewAccounting,
    iconName: 'open-items-list',
    route: '/accounting/open-items',
    mainColor: 'violet',
    type: AppNameEnum.OPEN_ITEM_LIST
  };

  const PAYMENT_ITEM: NavItem = {
    name: 'APP.PAYMENTS',
    show: canViewAccounting,
    iconName: 'payment',
    route: '/accounting/payments',
    mainColor: 'violet',
    type: AppNameEnum.PAYMENTS
  };

  const CRN_ITEM: NavItem = {
    name: 'APP.CREDIT_NOTES',
    show: canViewAccounting,
    iconName: 'credit-note',
    route: '/accounting/credit-note',
    action: 'createNewCreditNote',
    mainColor: 'violet',
    type: AppNameEnum.CREDIT_NOTES
  };

  const REPORTING_PERIODS_ITEMS: NavItem[] = [
    {
      name: 'REPORTING_PERIODS.REPORTING_PERIODS',
      route: '/accounting/reporting-periods/list',
      iconName: 'accountant-report',
      show: canViewAccounting,
      mainColor: 'violet',
      type: AppNameEnum.REPORTING_PERIODS
    },
  ];

  const BANK_ACCOUNTS_ITEM: NavItem = {
    name: 'APP.BANK_ACCOUNTS',
    show: canViewAccounting,
    iconName: 'account',
    route: '/accounting/bank-accounts',
    action: 'createBankAccount',
    mainColor: 'violet',
    type: AppNameEnum.BANK_ACCOUNT
  };

  const GL_ACCOUNTS_ITEM: NavItem = {
    name: 'GL_ACCOUNTS.LEDGER_ACCOUNTS',
    show: canViewAccounting,
    iconName: 'gl-accounts',
    route: '/accounting/gl-accounts',
    mainColor: 'violet',
    type: AppNameEnum.GL_ACCOUNT
  };

  const FIXED_ASSETS_ITEM: NavItem = {
    name: 'FIXED_ASSETS.ASSETS_DIRECTORY',
    show: canViewAccounting,
    iconName: 'fixed-assets',
    route: '/accounting/fixed-assets',
    mainColor: 'violet',
    type: AppNameEnum.FIXED_ASSETS
  };

  const SCHEDULED_PAYMENT_ITEM: NavItem = {
    name: 'SCHEDULED_PAYMENT.SCHEDULED_PAYMENTS',
    show: canViewAccounting,
    iconName: 'recurring-costs',
    route: '/accounting/scheduled-payment',
    action: 'createNewScheduledPayment',
    mainColor: 'violet',
    type: AppNameEnum.SCHEDULED_PAYMENTS
  };

  const PAYMENT_CALENDAR_ITEM: NavItem = {
    name: 'APP.PAYMENTS_CALENDAR',
    show: canViewAccounting,
    iconName: 'payments-calendar',
    route: '/accounting/payments-calendar',
    mainColor: 'violet',
    type: AppNameEnum.PAYMENT_CALENDAR
  };

  const TIME_TRACKING_ITEM: NavItem = {
    name: 'APP.TIME_TRACKING',
    show: canViewServices && servicesEnabled,
    iconName: 'timer',
    route: '/services/time-tracking',
    action: 'onLogTimeClick',
    mainColor: 'green',
    type: AppNameEnum.TIME_TRACKING
  };

  const SUBSCRIPTIONS_ITEM: NavItem = {
    name: 'APP.SUBSCRIPTIONS',
    show: canViewServices && servicesEnabled,
    iconName: 'subscriptions',
    route: '/services/subscription',
    action: 'createNewSubscription',
    mainColor: 'green',
    type: AppNameEnum.SUBSCRIPTIONS
  };

  const ACCOUNTING_SETTINGS_ITEM: NavItem = {
    name: 'SETTINGS.ACCOUNTING_SETTINGS',
    show: canViewAccounting && canManageAccounting,
    iconName: 'options-2',
    route: '/accounting/settings',
    mainColor: 'violet',
    type: AppNameEnum.ACCOUNTING_DASHBOARD
  };

  // const TRADE_DASHBOARD_ITEM: NavItem = {
  //   name: 'DASHBOARDS.TRADE_DATA',
  //   iconName: 'cash',
  //   show: canViewWholesale && wholesaleEnabled,
  //   route: '/analytics/trade-dashboard',
  //   mainColor: 'mint',
  //   type: AppNameEnum.TRADE_DASHBOARD
  // };

  const ACCOUNTING_DASHBOARD_ITEM: NavItem = {
    name: 'DASHBOARDS.ACCOUNTING_DATA',
    iconName: 'calculator',
    show: canViewAccounting,
    route: '/analytics/accounting-dashboard',
    mainColor: 'mint',
    type: AppNameEnum.ACCOUNTING_DASHBOARD
  };

  // const WAREHOUSE_DASHBOARD_ITEM: NavItem = {
  //   name: 'DASHBOARDS.WAREHOUSE_DATA',
  //   iconName: 'warehouse',
  //   show: canViewWarehouse && (ecommerceEnabled || wholesaleEnabled),
  //   route: '/analytics/warehouse-dashboard',
  //   mainColor: 'mint',
  //   type: AppNameEnum.WAREHOUSE_DASHBOARD
  // };

  const REPORTS_ITEM: NavItem = {
    name: 'REPORTS.REPORTS',
    iconName: 'reports',
    show: true,
    route: '/analytics/reports',
    mainColor: 'mint',
    type: AppNameEnum.REPORTS
  };

  const CASHFLOW_ITEM: NavItem = {
    name: 'CASH_FLOW.CASH_FLOW',
    iconName: 'cashflow',
    show: true,
    route: '/analytics/cashflow',
    mainColor: 'mint',
    type: AppNameEnum.CASHFLOW
  };

  const TRANSACTION_ITEM = {
    name: 'APP.TRANSACTIONS',
    show: canViewWarehouse && (wholesaleEnabled || ecommerceEnabled),
    iconName: 'stocktransfer',
    route: '/warehouse/transactions',
    action: 'createTransaction',
    mainColor: 'orange',
    type: AppNameEnum.TRANSACTIONS
  };

  const STOCKTAKING_ITEM = {
    name: 'Stocktaking',
    show: canViewWarehouse && (wholesaleEnabled || ecommerceEnabled),
    iconName: 'checkbox-off',
    route: '/warehouse/stocktaking',
    mainColor: 'orange',
    type: AppNameEnum.STOCKTAKING
  };

  const WAREHOUSE_ITEM = {
    name: 'WAREHOUSE.WAREHOUSE_MANAGEMENT',
    show: canViewWarehouse && (wholesaleEnabled || ecommerceEnabled),
    iconName: 'warehouse',
    route: '/warehouse/warehouse-profile',
    mainColor: 'orange',
    type: AppNameEnum.WAREHOUSE_DASHBOARD
  };

  const WAREHOUSE_SETTINGS_ITEM = {
    name: 'SETTINGS.WAREHOUSE_SETTINGS',
    show: canViewWarehouse && (wholesaleEnabled || ecommerceEnabled) && canManageWarehouse,
    iconName: 'options-2',
    route: '/warehouse/settings/delivery-services',
    mainColor: 'orange',
    type: AppNameEnum.WAREHOUSE_DASHBOARD
  };

  const MANAGEMENT = [
    {
      name: 'COMMON.GOODS',
      iconName: 'box',
      show: goodsProductTypeEnabled && canViewProducts,
      route: `/products/${ProductTypes.GOODS}`,
      action: 'createNewProduct',
      mainColor: 'purple',
      type: AppNameEnum.GOODS
    },
    {
      name: 'APP.DIGITAL_PRODUCTS',
      iconName: 'digital-product',
      show: digitalProductTypeEnabled && canViewProducts,
      route: `/products/${ProductTypes.DIGITAL}`,
      action: 'createNewDigitalProduct',
      mainColor: 'purple',
      type: AppNameEnum.DIGITAL
    },
    {
      name: 'APP.SERVICES',
      iconName: 'checklist',
      show: servicesProductTypeEnabled && canViewProducts,
      route: `/products/${ProductTypes.SERVICES}`,
      action: 'createNewService',
      mainColor: 'purple',
      type: AppNameEnum.SERVICES
    },
    {
      name: 'GL_ACCOUNTS.GENERAL_PRODUCTS',
      iconName: 'dashboard',
      show: canViewProducts,
      route: '/products/general-products',
      mainColor: 'purple',
      type: AppNameEnum.GENERAL_PRODUCTS
    },
    {
      name: 'APP.CATEGORIES',
      iconName: 'folder-open',
      show: canViewProducts,
      route: `/products/settings/categories/${productTypeRedirect}`,
      mainColor: 'purple',
      type: AppNameEnum.CATEGORIES
    },
    {
      name: 'SETTINGS.PRODUCTS_SETTINGS',
      show: canViewProducts && canManageProducts,
      iconName: 'options-2',
      route: `/products/products-settings`,
      mainColor: 'purple',
      type: AppNameEnum.PRODUCTS_SETTINGS
    },
    {
      name: 'LAUNCH_PAD.COMPANIES',
      iconName: 'factory',
      show: corporatePartnersEnabled && canViewPartners,
      route: `/partners-new/corporate`,
      action: 'createNewPartner',
      mainColor: 'brown',
      type: AppNameEnum.CORPORATE_PARTNERS
    },
    {
      name: 'APP.PRIVATE_PARTNERS',
      iconName: 'person',
      show: privatePartnersEnabled && canViewPartners,
      route: `/partners-new/private`,
      action: 'createNewPrivatePartner',
      mainColor: 'brown',
      type: AppNameEnum.PRIVATE_PARTNERS
    },
    {
      name: 'SETTINGS.PARTNERS_SETTINGS',
      show: canViewPartners && canManagePartners,
      iconName: 'options-2',
      route: `/partners-new/settings`,
      mainColor: 'brown',
      type: AppNameEnum.PARTNERS_SETTINGS
    },
    {
      name: 'APP.EMPLOYEES',
      show: canViewTeam,
      iconName: 'people',
      route: '/team',
      action: 'createNewEmployee',
      mainColor: 'sky',
      type: AppNameEnum.EMPLOYEES
    },
    {
      name: 'APP.TRANSACTIONS',
      show: canViewWarehouse && (wholesaleEnabled || ecommerceEnabled),
      iconName: 'stocktransfer',
      route: '/warehouse/transactions',
      action: 'createTransaction',
      mainColor: 'orange',
      type: AppNameEnum.TRANSACTIONS
    },
    {
      name: 'Stocktaking',
      show: canViewWarehouse && (wholesaleEnabled || ecommerceEnabled),
      iconName: 'checkbox-off',
      route: '/warehouse/stocktaking',
      mainColor: 'orange',
      type: AppNameEnum.TRANSACTIONS
    },
    {
      name: 'WAREHOUSE.WAREHOUSE_MANAGEMENT',
      show: canViewWarehouse && (wholesaleEnabled || ecommerceEnabled),
      iconName: 'warehouse',
      route: '/warehouse/warehouse-profile',
      mainColor: 'orange',
      type: AppNameEnum.WAREHOUSE_DASHBOARD
    },
    {
      name: 'MODULE.ADMINISTRATION',
      show: canViewAdministration,
      route: '/administration/company-profile',
      iconName: 'settings',
      mainColor: 'black',
      type: AppNameEnum.ADMINISTRATION
    },
    {
      name: 'PDF_SETTINGS.PDF_DESIGNER',
      show: canEditAdministration,
      route: '/pdf-designer',
      iconName: 'pdf-designer',
      mainColor: 'black',
      type: AppNameEnum.PDF_DESIGNER
    },
  ];

  const SALES_MENU_ITEMS: NavItem[] = [
    OFFERS_ITEM,
    SALES_ORDERS_ITEM,
    ERA_ITEM,
    TIME_TRACKING_ITEM,
    SUBSCRIPTIONS_ITEM,
    OUTGOING_DELIVERIES_ITEM,
    OIN_ITEM,
    SALES_PRICES_ITEM,
    STOCK_STATUS_ITEM,
  ];

  const PURCHASE_MENU_ITEMS: NavItem[] = [
    PURCHASE_ORDERS_ITEM,
    INCOMING_DELIVERIES_ITEM,
    IIN_ITEM,
    PURCHASE_PRICES_ITEM,
    STOCK_STATUS_ITEM
  ];

  const ACCOUNTING_MENU_ITEMS: NavItem[] = [
    OPEN_ITEMS_LIST_ITEM,
    OIN_ITEM,
    IIN_ITEM,
    PAYMENT_ITEM,
    SCHEDULED_PAYMENT_ITEM,
    PAYMENT_CALENDAR_ITEM,
    CRN_ITEM,
    ...REPORTING_PERIODS_ITEMS,
    BANK_ACCOUNTS_ITEM,
    GL_ACCOUNTS_ITEM,
    FIXED_ASSETS_ITEM,
    ACCOUNTING_SETTINGS_ITEM
  ];

  const ANALYTICS_MENU_ITEMS: NavItem[] = [
    // TRADE_DASHBOARD_ITEM,
    ACCOUNTING_DASHBOARD_ITEM,
    // WAREHOUSE_DASHBOARD_ITEM,
    CASHFLOW_ITEM,
    REPORTS_ITEM,
  ];

  const WAREHOUSE_MENU_ITEMS: NavItem[] = [
    STOCK_STATUS_ITEM,
    INCOMING_DELIVERIES_ITEM,
    OUTGOING_DELIVERIES_ITEM,
    TRANSACTION_ITEM,
    STOCKTAKING_ITEM,
    WAREHOUSE_ITEM,
    WAREHOUSE_SETTINGS_ITEM
  ];

  switch (activeTab) {
    case LaunchpadNavEnum.SALES:
      return SALES_MENU_ITEMS;
    case LaunchpadNavEnum.PURCHASE:
      return PURCHASE_MENU_ITEMS;
    case LaunchpadNavEnum.ACCOUNTING:
      return ACCOUNTING_MENU_ITEMS;
    case LaunchpadNavEnum.ANALYTICS:
      return ANALYTICS_MENU_ITEMS;
    case LaunchpadNavEnum.MANAGEMENT:
      return MANAGEMENT;
    case LaunchpadNavEnum.WAREHOUSE:
      return WAREHOUSE_MENU_ITEMS;
  }
}

export const StartScreenContextMenuActions: AppActionsListModel = {
  actions: [
    {
      action: 'setEditMode',
      title: 'BUTTON.EDIT',
      icon: 'edit'
    },
    {
      action: 'addShortcutsModal',
      title: 'LAUNCH_PAD.ADD_SHORTCUT',
      icon: 'plus'
    },
  ]
};

export const RemoveActionConfig: AppActionModel = {
  action: 'removeFromStartScreen',
  title: 'LAUNCH_PAD.REMOVE_FROM_START_SCREEN',
  icon: 'minus-circle'
};
