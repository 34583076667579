import { cloneDeep } from 'lodash';
import { UserShortcutEnum } from 'projects/workspace/src/app/hrm/enums';
import { ProductTypes } from '../../../products/product-types';
import {
  getAccountingSettingsActionItem, getPartnersSettingsActionItem, getProductsSettingsActionItem,
  getServicesSettingsActionItem,
  getTradeSettingsActionItem, getWarehouseSettingsActionItem
} from '../../../start-screen-page/app-data.config';

export function userAvailableShortcutsHelper(shortcuts: UserShortcutEnum[], config: any = ShortcutsConfig()): any { // todo model
  const availableGroup = cloneDeep(availableShortcutsGroupConfig);

  shortcuts.forEach((shortcut: UserShortcutEnum) => {
    const matchedGroup = availableGroup.find(g => g.group.includes(shortcut));
    if (matchedGroup && config[shortcut]) {
      matchedGroup.shortcuts.push(config[shortcut]);
    }
  });

  return availableGroup;
}

export const availableShortcutsGroupConfig = [ // todo model
  {
    title: 'MODULE.TRADE',
    icon: 'cash',
    group: [
      UserShortcutEnum.OFFERS,
      UserShortcutEnum.SALES_ORDERS,
      UserShortcutEnum.PURCHASE_ORDERS,
      UserShortcutEnum.PRICES,
      UserShortcutEnum.RETURNS,
    ],
    shortcuts: []
  },
  {
    title: 'MODULE.E_COMMERCE',
    icon: 'shopping-cart',
    group: [
      UserShortcutEnum.ECOMMERCE_ORDERS,
      UserShortcutEnum.ECOMMERCE_PRODUCTS,
      UserShortcutEnum.ECOMMERCE_PRICES,
      UserShortcutEnum.ECOMMERCE_CORPORATE_PARTNERS,
      UserShortcutEnum.ECOMMERCE_PRIVATE_PARTNERS,
    ],
    shortcuts: []
  },
  {
    title: 'MODULE.SERVICES',
    icon: 'checklist',
    group: [
      UserShortcutEnum.TIME_TRACKING,
      UserShortcutEnum.SUBSCRIPTIONS,
    ],
    shortcuts: []
  },
  {
    title: 'MODULE.ACCOUNTING',
    icon: 'calculator',
    group: [
      UserShortcutEnum.OUTGOING_INVOICES,
      UserShortcutEnum.INCOMING_INVOICES,
      UserShortcutEnum.PAYMENTS,
      UserShortcutEnum.SCHEDULED_PAYMENT,
      UserShortcutEnum.PAYMENT_CALENDAR,
      UserShortcutEnum.CREDIT_NOTES,
      UserShortcutEnum.OPEN_ITEM_LIST,
      UserShortcutEnum.REPORTING_PERIODS,
      UserShortcutEnum.SCHEDULE_PAYMENTS,
      UserShortcutEnum.FIXED_ASSETS,
      UserShortcutEnum.GOODS_REGISTER,
      UserShortcutEnum.COST_CENTER,
      UserShortcutEnum.BANK_ACCOUNT,
      UserShortcutEnum.GL_ACCOUNT,
    ],
    shortcuts: []
  },
  {
    title: 'MODULE.WAREHOUSE',
    icon: 'warehouse',
    group: [
      UserShortcutEnum.STOCK_STATUS,
      UserShortcutEnum.INCOMING_DELIVERIES,
      UserShortcutEnum.OUTGOING_DELIVERIES,
      UserShortcutEnum.TRANSACTIONS,
      UserShortcutEnum.MANAGEMENT,
      UserShortcutEnum.STOCKTAKING,
    ],
    shortcuts: []
  },
  {
    title: 'MODULE.PRODUCTS',
    icon: 'box',
    group: [
      UserShortcutEnum.GOODS,
      UserShortcutEnum.SERVICES,
      UserShortcutEnum.DIGITAL_PRODUCTS,
      UserShortcutEnum.CATEGORIES,
      UserShortcutEnum.GENERAL_PRODUCTS,
      UserShortcutEnum.PRODUCT_SETTINGS,
      UserShortcutEnum.SCANNED_BARCODES
    ],
    shortcuts: []
  },
  {
    title: 'MODULE.PARTNERS',
    icon: 'heart',
    group: [
      UserShortcutEnum.CORPORATE_PARTNERS,
      UserShortcutEnum.PRIVATE_PARTNERS,
      UserShortcutEnum.PARTNER_SETTINGS
    ],
    shortcuts: []
  },
  {
    title: 'MODULE.TEAM',
    icon: 'people',
    group: [
      UserShortcutEnum.EMPLOYEES]
    ,
    shortcuts: []
  },
  {
    title: 'MODULE.ANALYTICS',
    icon: 'business-intelligence',
    group: [
      // UserShortcutEnum.TRADE_DATA,
      UserShortcutEnum.ACCOUNTING_DATA,
      UserShortcutEnum.CASHFLOW,
      // UserShortcutEnum.WAREHOUSE_DATA,
    ],
    shortcuts: []
  },
  {
    title: 'MODULE.ADMINISTRATION',
    icon: 'settings-2x',
    group: [
      UserShortcutEnum.COMPANY_PROFILE,
    ],
    shortcuts: []
  },
];

export function ShortcutsConfig(
  showProductsSettings: boolean = false,
  showPartnersSettings: boolean = false,
  showWarehouseSettings: boolean = false,
  showAccountingSettings: boolean = false,
  showWholesaleSettings: boolean = false,
  showServicesSettings: boolean = false,
  showGoods: boolean = false,
  showServices: boolean = false,
  showDigitalProducts: boolean = false,
  showCorporatePartners: boolean = false,
  showPrivatePartners: boolean = false,
  showSalesPrices: boolean = false,
  showPurchasePrices: boolean = false,
) {
  return {
    // TRADE
    [UserShortcutEnum.OFFERS]: {
      key: UserShortcutEnum.OFFERS,
      title: 'APP.OFFERS',
      icon: 'offer',
      color: 'yellow',
      routerLink: '/trade/offers',
      action: 'createNewOffer',
      actionList: [
        {
          action: 'createNewOffer',
          title: 'BUTTON.CREATE_OFFER',
          icon: 'plus-square'
        },
        {
          router: '/trade/offers',
          title: 'LAUNCH_PAD.OPEN_OFFERS_LIST',
          icon: 'list'
        },
        getTradeSettingsActionItem(showWholesaleSettings)
      ]
    },
    [UserShortcutEnum.SALES_ORDERS]: {
      key: UserShortcutEnum.SALES_ORDERS,
      title: 'APP.SALES_ORDERS',
      icon: 'sales-order',
      color: 'yellow',
      routerLink: '/trade/sales-order',
      action: 'createNewOrder',
      actionList: [
        {
          action: 'createNewOrder',
          title: 'BUTTON.CREATE_SALES_ORDER',
          icon: 'plus-square'
        },
        {
          router: '/trade/sales-order',
          title: 'LAUNCH_PAD.OPEN_SO_LIST',
          icon: 'list'
        },
        getTradeSettingsActionItem(showWholesaleSettings)
      ]
    },
    [UserShortcutEnum.PURCHASE_ORDERS]: {
      key: UserShortcutEnum.PURCHASE_ORDERS,
      title: 'APP.PURCHASE_ORDERS',
      icon: 'purchase-order',
      color: 'yellow',
      routerLink: '/trade/purchase-order',
      action: 'createNewPurchaseOrder',
      actionList: [
        {
          action: 'createNewPurchaseOrder',
          title: 'BUTTON.CREATE_PO',
          icon: 'plus-square'
        },
        {
          router: '/trade/purchase-order',
          title: 'LAUNCH_PAD.OPEN_PO_LIST',
          icon: 'list'
        },
        getTradeSettingsActionItem(showWholesaleSettings)
      ]
    },
    [UserShortcutEnum.PRICES]: {
      key: UserShortcutEnum.PRICES,
      title: 'APP.PRICES',
      icon: 'pricetag',
      color: 'yellow',
      routerLink: showSalesPrices ? '/trade/prices/sales' : '/trade/prices/purchase',
      actionList: [
        {
          router: '/trade/prices/sales',
          title: 'LAUNCH_PAD.OPEN_SALES_PRICE',
          icon: 'list',
          hide: !showSalesPrices
        },
        {
          router: '/trade/prices/purchase',
          title: 'LAUNCH_PAD.OPEN_PURCHASE_PRICE',
          icon: 'list',
          hide: !showPurchasePrices || !showSalesPrices
        },
        getTradeSettingsActionItem(showWholesaleSettings)
      ]
    },
    [UserShortcutEnum.RETURNS]: {
      key: UserShortcutEnum.RETURNS,
      title: 'APP.RETURNS',
      icon: 'exchange-return',
      color: 'yellow',
      routerLink: '/trade/exchanges-and-returns',
      action: 'createNewERA',
      actionList: [
        {
          action: 'createNewERA',
          title: 'BUTTON.CREATE_AUTHORIZATION',
          icon: 'plus-square'
        },
        {
          router: '/trade/exchanges-and-returns',
          title: 'LAUNCH_PAD.OPEN_RETURNS_LIST',
          icon: 'list'
        },
        getTradeSettingsActionItem(showWholesaleSettings)
      ]
    },
    // ECOMMERCE
    [UserShortcutEnum.ECOMMERCE_ORDERS]: {
      key: UserShortcutEnum.ECOMMERCE_ORDERS,
      title: 'ECOMMERCE.ECO_ORDER',
      icon: 'sales-order',
      color: 'red',
      routerLink: '/e-commerce/orders',
      actionList: [
        {
          router: '/e-commerce/orders',
          title: 'LAUNCH_PAD.OPEN_ORDERS_LIST',
          icon: 'list'
        },
      ]
    },
    [UserShortcutEnum.ECOMMERCE_PRODUCTS]: {
      key: UserShortcutEnum.ECOMMERCE_PRODUCTS,
      title: 'APP.PRODUCTS',
      icon: 'box',
      color: 'red',
      routerLink: '/e-commerce/products',
      actionList: [
        {
          router: '/e-commerce/products',
          title: 'LAUNCH_PAD.OPEN_PRODUCTS_LIST',
          icon: 'list'
        },
      ]
    },
    [UserShortcutEnum.ECOMMERCE_PRICES]: {
      key: UserShortcutEnum.ECOMMERCE_PRICES,
      title: 'APP.PRICES',
      icon: 'pricetag',
      color: 'red',
      routerLink: '/e-commerce/prices',
      actionList: [
        {
          router: '/e-commerce/prices',
          title: 'LAUNCH_PAD.OPEN_SALES_PRICE',
          icon: 'list',
        },
      ]
    },
    [UserShortcutEnum.ECOMMERCE_CORPORATE_PARTNERS]: {
      key: UserShortcutEnum.ECOMMERCE_CORPORATE_PARTNERS,
      title: 'APP.CORPORATE_CUSTOMERS',
      icon: 'factory',
      color: 'red',
      routerLink: '/e-commerce/customers/corporate',
      actionList: [
        {
          router: `/e-commerce/customers/corporate`,
          title: 'LAUNCH_PAD.OPEN_COMPANIES',
          icon: 'list',
        },
      ]
    },
    [UserShortcutEnum.ECOMMERCE_PRIVATE_PARTNERS]: {
      key: UserShortcutEnum.ECOMMERCE_PRIVATE_PARTNERS,
      title: 'APP.PRIVATE_CUSTOMERS',
      icon: 'person',
      color: 'red',
      routerLink: '/e-commerce/customers/private',
      actionList: [
        {
          router: `/e-commerce/customers/private`,
          title: 'DATA_IMPORT.OPEN_PP',
          icon: 'list',
        },
      ]
    },
    // SUBSCRIPTIONS
    [UserShortcutEnum.TIME_TRACKING]: {
      key: UserShortcutEnum.TIME_TRACKING,
      title: 'APP.TIME_TRACKING',
      icon: 'timer',
      color: 'green',
      routerLink: '/services/time-tracking',
      action: 'onLogTimeClick',
      actionList: [
        {
          action: 'onLogTimeClick',
          title: 'BUTTON.LOG_TIME',
          icon: 'timer'
        },
        {
          router: '/services/time-tracking',
          title: 'LAUNCH_PAD.OPEN_LOG',
          icon: 'list'
        },
        getServicesSettingsActionItem(showServicesSettings)
      ]
    },
    [UserShortcutEnum.SUBSCRIPTIONS]: {
      key: UserShortcutEnum.SUBSCRIPTIONS,
      title: 'APP.SUBSCRIPTIONS',
      icon: 'subscriptions',
      color: 'green',
      routerLink: '/services/subscription',
      action: 'createNewSubscription',
      actionList: [
        {
          action: 'createNewSubscription',
          title: 'BUTTON.CREATE_SUBSCRIPTION',
          icon: 'plus-square'
        },
        {
          router: '/services/subscription',
          title: 'LAUNCH_PAD.OPEN_SBC_LIST',
          icon: 'list'
        },
        getServicesSettingsActionItem(showServicesSettings)
      ]
    },
    // ACCOUNTING
    [UserShortcutEnum.OUTGOING_INVOICES]: {
      key: UserShortcutEnum.OUTGOING_INVOICES,
      title: 'APP.OUTGOING_INVOICES',
      icon: 'outgoing-invoice',
      color: 'violet',
      routerLink: '/accounting/outgoing-invoice',
      action: 'createNewInvoice',
      actionList: [
        {
          action: 'createNewInvoice',
          title: 'BUTTON.CREATE_OIN',
          icon: 'plus-square'
        },
        {
          router: '/accounting/outgoing-invoice',
          title: 'LAUNCH_PAD.OPEN_OIN_LIST',
          icon: 'list'
        },
        getAccountingSettingsActionItem(showAccountingSettings),
      ]
    },
    [UserShortcutEnum.INCOMING_INVOICES]: {
      key: UserShortcutEnum.INCOMING_INVOICES,
      title: 'APP.INCOMING_INVOICES',
      icon: 'incoming-invoice',
      color: 'violet',
      routerLink: '/accounting/incoming-invoice',
      action: 'createNewIncomingInvoice',
      actionList: [
        {
          action: 'createNewIncomingInvoice',
          title: 'BUTTON.CREATE_INCOMING_INVOICE',
          icon: 'plus-square'
        },
        {
          router: '/accounting/incoming-invoice',
          title: 'LAUNCH_PAD.OPEN_IIN_LIST',
          icon: 'list'
        },
        getAccountingSettingsActionItem(showAccountingSettings),
      ]
    },
    [UserShortcutEnum.PAYMENTS]: {
      key: UserShortcutEnum.PAYMENTS,
      title: 'APP.PAYMENTS',
      icon: 'payment',
      color: 'violet',
      routerLink: '/accounting/payments',
      actionList: [
        {
          router: '/accounting/payments',
          title: 'LAUNCH_PAD.OPEN_PAYMENTS_LIST',
          icon: 'list'
        },
        getAccountingSettingsActionItem(showAccountingSettings),
      ]
    },
    [UserShortcutEnum.SCHEDULED_PAYMENT]: {
      key: UserShortcutEnum.SCHEDULED_PAYMENT,
      title: 'SCHEDULED_PAYMENT.SCHEDULED_PAYMENTS',
      icon: 'recurring-costs',
      color: 'violet',
      routerLink: '/accounting/scheduled-payment',
      action: 'createNewScheduledPayment',
      actionList: [
        {
          action: 'createNewScheduledPayment',
          title: 'SCHEDULED_PAYMENT.CREATE_SCHEDULED_PAYMENT',
          icon: 'plus-square'
        },
        {
          router: '/accounting/scheduled-payment',
          title: 'LAUNCH_PAD.OPEN_SCHEDULE_PAYMENTS',
          icon: 'list'
        },
        getAccountingSettingsActionItem(showAccountingSettings),
      ]
    },
    [UserShortcutEnum.PAYMENT_CALENDAR]: {
      key: UserShortcutEnum.PAYMENT_CALENDAR,
      title: 'APP.PAYMENTS_CALENDAR',
      icon: 'payments-calendar',
      color: 'violet',
      routerLink: '/accounting/payments-calendar',
      actionList: [
        {
          router: '/accounting/payments-calendar',
          title: 'LAUNCH_PAD.OPEN_PAYMENTS_CALENDAR',
          icon: 'list'
        },
        getAccountingSettingsActionItem(showAccountingSettings),
      ]
    },
    [UserShortcutEnum.CREDIT_NOTES]: {
      key: UserShortcutEnum.CREDIT_NOTES,
      title: 'APP.CREDIT_NOTES',
      icon: 'credit-note',
      color: 'violet',
      routerLink: '/accounting/credit-note',
      action: 'createNewCreditNote',
      actionList: [
        {
          action: 'createNewCreditNote',
          title: 'BUTTON.CREATE_CREDIT_NOTE',
          icon: 'plus-square'
        },
        {
          router: '/accounting/credit-note',
          title: 'LAUNCH_PAD.OPEN_CRN_LIST',
          icon: 'list'
        },
        getAccountingSettingsActionItem(showAccountingSettings),
      ]
    },
    [UserShortcutEnum.OPEN_ITEM_LIST]: {
      key: UserShortcutEnum.OPEN_ITEM_LIST,
      title: 'APP.OPEN_ITEM_LIST',
      icon: 'open-items-list',
      color: 'violet',
      routerLink: '/accounting/open-items',
      actionList: [
        {
          router: '/accounting/open-items',
          title: 'LAUNCH_PAD.OPEN_OPEN_ITEMS_LIST',
          icon: 'list'
        },
        getAccountingSettingsActionItem(showAccountingSettings),
      ]
    },
    [UserShortcutEnum.REPORTING_PERIODS]: {
      key: UserShortcutEnum.REPORTING_PERIODS,
      title: 'REPORTING_PERIODS.REPORTING_PERIODS',
      icon: 'accountant-report',
      color: 'violet',
      routerLink: '/accounting/reporting-periods',
      actionList: [
        {
          router: '/accounting/reporting-periods/list',
          title: 'LAUNCH_PAD.OPEN_REPORTING_PERIODS',
          icon: 'list'
        },
        getAccountingSettingsActionItem(showAccountingSettings),
      ]
    },
    [UserShortcutEnum.FIXED_ASSETS]: {
      key: UserShortcutEnum.FIXED_ASSETS,
      title: 'FIXED_ASSETS.ASSETS_DIRECTORY',
      icon: 'fixed-assets',
      color: 'violet',
      routerLink: '/accounting/fixed-assets',
      actionList: [
        {
          router: '/accounting/fixed-assets',
          title: 'LAUNCH_PAD.OPEN_FIXED_ASSETS',
          icon: 'list'
        },
        getAccountingSettingsActionItem(showAccountingSettings),
      ]
    },
    [UserShortcutEnum.GOODS_REGISTER]: {
      key: UserShortcutEnum.GOODS_REGISTER,
      title: 'GOODS_REGISTER.GOODS_REGISTER',
      icon: 'goods-register',
      color: 'violet',
      routerLink: '/accounting/goods-register',
      actionList: [
        {
          router: '/accounting/goods-register',
          title: 'GOODS_REGISTER.OPEN_GOODS_REGISTER',
          icon: 'list'
        },
        getAccountingSettingsActionItem(showAccountingSettings),
      ]
    },
    [UserShortcutEnum.COST_CENTER]: {
      key: UserShortcutEnum.COST_CENTER,
      title: 'COST_CENTER.COST_CENTERS',
      icon: 'costs_center',
      color: 'violet',
      routerLink: '/accounting/cost-center',
      actionList: [
        {
          router: '/accounting/cost-center',
          title: 'COST_CENTER.OPEN_COST_CENTER',
          icon: 'list'
        },
        getAccountingSettingsActionItem(showAccountingSettings),
      ]
    },
    [UserShortcutEnum.BANK_ACCOUNT]: {
      key: UserShortcutEnum.BANK_ACCOUNT,
      title: 'APP.BANK_ACCOUNTS',
      icon: 'account',
      color: 'violet',
      routerLink: '/accounting/bank-accounts',
      actionList: [
        {
          action: 'createBankAccount',
          title: 'BANK_ACCOUNTS.CREATE_ACCOUNT',
          icon: 'plus-square'
        },
        {
          router: '/accounting/bank-accounts',
          title: 'LAUNCH_PAD.OPEN_BANK_ACCOUNTS',
          icon: 'list'
        },
        getAccountingSettingsActionItem(showAccountingSettings),
      ]
    },
    [UserShortcutEnum.GL_ACCOUNT]: {
      key: UserShortcutEnum.GL_ACCOUNT,
      title: 'GL_ACCOUNTS.LEDGER_ACCOUNTS',
      icon: 'gl-accounts',
      color: 'violet',
      routerLink: '/accounting/gl-accounts',
      actionList: [
        {
          router: '/accounting/gl-accounts',
          title: 'LAUNCH_PAD.OPEN_GL_ACCOUNTS',
          icon: 'list'
        },
        getAccountingSettingsActionItem(showAccountingSettings),
      ]
    },
    // WAREHOUSE
    [UserShortcutEnum.STOCK_STATUS]: {
      key: UserShortcutEnum.STOCK_STATUS,
      title: 'APP.STOCK_STATUS',
      icon: 'cubes',
      color: 'orange',
      routerLink: '/warehouse/stock-status/general-stock',
      actionList: [
        {
          router: '/warehouse/stock-status',
          title: 'LAUNCH_PAD.OPEN_STOCK_STATUS',
          icon: 'list'
        },
        getWarehouseSettingsActionItem(showWarehouseSettings),
      ]
    },
    [UserShortcutEnum.INCOMING_DELIVERIES]: {
      key: UserShortcutEnum.INCOMING_DELIVERIES,
      title: 'APP.INCOMING_DELIVERIES',
      icon: 'incoming-delivery',
      color: 'orange',
      routerLink: '/warehouse/incoming-delivery',
      actionList: [
        {
          router: '/warehouse/incoming-delivery',
          title: 'LAUNCH_PAD.OPEN_INCOMING_DELIVERY_LIST',
          icon: 'list'
        },
        getWarehouseSettingsActionItem(showWarehouseSettings),
      ]
    },
    [UserShortcutEnum.OUTGOING_DELIVERIES]: {
      key: UserShortcutEnum.OUTGOING_DELIVERIES,
      title: 'APP.OUTGOING_DELIVERIES',
      icon: 'outgoing-delivery',
      color: 'orange',
      routerLink: '/warehouse/outgoing-delivery',
      action: 'createNewDeliveryNote',
      actionList: [
        {
          action: 'createNewDeliveryNote',
          title: 'BUTTON.CREATE_OUTGOING_DELIVERY',
          icon: 'plus-square'
        },
        {
          router: '/warehouse/outgoing-delivery',
          title: 'LAUNCH_PAD.OPEN_OUTGOING_DELIVERY_LIST',
          icon: 'list'
        },
        getWarehouseSettingsActionItem(showWarehouseSettings),
      ]
    },
    [UserShortcutEnum.STOCKTAKING]: {
      key: UserShortcutEnum.STOCKTAKING,
      title: 'STOCKTAKING.STOCKTAKING',
      icon: 'stocktaking',
      color: 'orange',
      routerLink: '/warehouse/stocktaking',
      actionList: [
        {
          router: '/warehouse/stocktaking',
          title: 'LAUNCH_PAD.OPEN_STOCKTAKING_LIST',
          icon: 'list'
        },
        getWarehouseSettingsActionItem(showWarehouseSettings),
      ]
    },
    [UserShortcutEnum.TRANSACTIONS]: {
      key: UserShortcutEnum.TRANSACTIONS,
      title: 'APP.TRANSACTIONS',
      icon: 'stocktransfer',
      color: 'orange',
      routerLink: '/warehouse/transactions',
      action: 'createTransaction',
      actionList: [
        {
          action: 'createTransaction',
          title: 'BUTTON.CREATE_TRANSACTION',
          icon: 'plus-square'
        },
        {
          router: '/warehouse/transactions',
          title: 'LAUNCH_PAD.OPEN_TRANSACTION_LIST',
          icon: 'list'
        },
        getWarehouseSettingsActionItem(showWarehouseSettings),
      ]
    },
    [UserShortcutEnum.MANAGEMENT]: {
      key: UserShortcutEnum.MANAGEMENT,
      title: 'APP.MANAGEMENT',
      icon: 'warehouse',
      color: 'orange',
      routerLink: '/warehouse',
      actionList: [
        {
          router: '/warehouse/warehouse-profile',
          title: 'LAUNCH_PAD.OPEN_WAREHOUSE_MANAGEMENT',
          icon: 'list'
        },
        getWarehouseSettingsActionItem(showWarehouseSettings),
      ]
    },
    // PRODUCTS
    [UserShortcutEnum.GOODS]: {
      key: UserShortcutEnum.GOODS,
      title: 'COMMON.GOODS',
      icon: 'box',
      color: 'purple',
      routerLink: `/products/${ProductTypes.GOODS}`,
      action: 'createNewProduct',
      actionList: [
        {
          action: 'createNewProduct',
          title: 'BUTTON.CREATE_PRODUCT',
          icon: 'plus-square'
        },
        {
          router: `/products/${ProductTypes.GOODS}`,
          title: 'DATA_IMPORT.OPEN_GOODS',
          icon: 'list'
        },
        getProductsSettingsActionItem(showProductsSettings),
      ]
    },
    [UserShortcutEnum.SERVICES]: {
      key: UserShortcutEnum.SERVICES,
      title: 'APP.SERVICES',
      icon: 'checklist',
      color: 'purple',
      routerLink: `/products/${ProductTypes.SERVICES}`,
      action: 'createNewService',
      actionList: [
        {
          action: 'createNewService',
          title: 'BUTTON.CREATE_SERVICE',
          icon: 'plus-square'
        },
        {
          router: `/products/${ProductTypes.SERVICES}`,
          title: 'DATA_IMPORT.OPEN_SERVICES',
          icon: 'list'
        },
        getProductsSettingsActionItem(showProductsSettings),
      ]
    },
    [UserShortcutEnum.DIGITAL_PRODUCTS]: {
      key: UserShortcutEnum.DIGITAL_PRODUCTS,
      title: 'APP.DIGITAL_PRODUCTS',
      icon: 'digital-product',
      color: 'purple',
      routerLink: `/products/${ProductTypes.DIGITAL}`,
      action: 'createNewDigitalProduct',
      actionList: [
        {
          action: 'createNewDigitalProduct',
          title: 'BUTTON.CREATE_DGT_PRODUCT',
          icon: 'plus-square'
        },
        {
          router: `/products/${ProductTypes.DIGITAL}`,
          title: 'DATA_IMPORT.OPEN_DIGITAL',
          icon: 'list'
        },
        getProductsSettingsActionItem(showProductsSettings),
      ]
    },
    [UserShortcutEnum.GENERAL_PRODUCTS]: {
      key: UserShortcutEnum.GENERAL_PRODUCTS,
      title: 'GL_ACCOUNTS.GENERAL_PRODUCTS',
      icon: 'dashboard',
      color: 'purple',
      routerLink: '/products/general-products',
      actionList: [
        {
          router: `/products/general-products`,
          title: 'LAUNCH_PAD.OPEN_GENERAL_PRODUCT_LIST',
          icon: 'list'
        },
        getProductsSettingsActionItem(showProductsSettings),
      ]
    },
    [UserShortcutEnum.CATEGORIES]: {
      key: UserShortcutEnum.CATEGORIES,
      title: 'APP.CATEGORIES',
      icon: 'folder-open',
      color: 'purple',
      routerLink: `/products/settings/categories/${ProductTypes.GOODS}`,
      actionList: [
        {
          router: `/products/settings/categories/${ProductTypes.GOODS}`,
          title: 'LAUNCH_PAD.OPEN_CATEGORIES',
          icon: 'list'
        },
        getProductsSettingsActionItem(showProductsSettings),
      ]
    },
    [UserShortcutEnum.PRODUCT_SETTINGS]: {
      key: UserShortcutEnum.PRODUCT_SETTINGS,
      title: 'SETTINGS.PRODUCTS_SETTINGS',
      icon: 'options-2',
      color: 'purple',
      routerLink: '/products/products-settings',
      actionList: [
        {
          router: `/products/products-settings`,
          title: 'LAUNCH_PAD.OPEN_PRODUCTS_SETTINGS',
          icon: 'options-2'
        },
      ]
    },
    // PARTNERS
    [UserShortcutEnum.CORPORATE_PARTNERS]: {
      key: UserShortcutEnum.CORPORATE_PARTNERS,
      title: 'APP.CORPORATE_PARTNERS_1',
      icon: 'factory',
      color: 'brown',
      routerLink: '/partners-new/corporate',
      action: 'createNewPartner',
      actionList: [
        {
          action: 'createNewPartner',
          title: 'LAUNCH_PAD.CREATE_COMPANY',
          icon: 'plus-square'
        },
        {
          router: `/partners-new/corporate`,
          title: 'LAUNCH_PAD.OPEN_COMPANIES',
          icon: 'list'
        },
        getPartnersSettingsActionItem(showPartnersSettings),
      ]
    },
    [UserShortcutEnum.PRIVATE_PARTNERS]: {
      key: UserShortcutEnum.PRIVATE_PARTNERS,
      title: 'APP.PRIVATE_PARTNERS',
      icon: 'person',
      color: 'brown',
      routerLink: '/partners-new/private',
      action: 'createNewPrivatePartner',
      actionList: [
        {
          action: 'createNewPrivatePartner',
          title: 'LAUNCH_PAD.CREATE_PP',
          icon: 'plus-square'
        },
        {
          router: `/partners-new/private`,
          title: 'DATA_IMPORT.OPEN_PP',
          icon: 'list'
        },
        getPartnersSettingsActionItem(showPartnersSettings),
      ]
    },
    [UserShortcutEnum.PARTNER_SETTINGS]: {
      key: UserShortcutEnum.PARTNER_SETTINGS,
      title: 'SETTINGS.PARTNERS_SETTINGS',
      icon: 'options-2',
      color: 'brown',
      routerLink: '/partners-new/settings',
      actionList: [
        {
          router: `/partners-new/settings`,
          title: 'LAUNCH_PAD.OPEN_PARTNERS_SETTINGS',
          icon: 'options-2'
        },
      ]
    },
    // TEAM
    [UserShortcutEnum.EMPLOYEES]: {
      key: UserShortcutEnum.EMPLOYEES,
      title: 'APP.EMPLOYEES',
      icon: 'people',
      color: 'sky',
      routerLink: '/team',
      action: 'createNewEmployee',
      actionList: [
        {
          action: 'createNewEmployee',
          title: 'TEAM.CREATE_EMPLOYEE',
          icon: 'plus-square'
        },
        {
          router: `/team`,
          title: 'LAUNCH_PAD.OPEN_EMPLOYEE',
          icon: 'list'
        },
      ]
    },
    // ANALYTICS
    // [UserShortcutEnum.TRADE_DATA]: {
    //   key: UserShortcutEnum.TRADE_DATA,
    //   title: 'DASHBOARDS.TRADE_DATA',
    //   icon: 'cash',
    //   color: 'mint',
    //   routerLink: '/analytics/trade-dashboard',
    //   actionList: [
    //     {
    //       router: '/analytics/trade-dashboard',
    //       title: 'LAUNCH_PAD.OPEN_TRADE_DASHBOARD',
    //       icon: 'list'
    //     },
    //   ]
    // },
    [UserShortcutEnum.ACCOUNTING_DATA]: {
      key: UserShortcutEnum.ACCOUNTING_DATA,
      title: 'DASHBOARDS.ACCOUNTING_DATA',
      icon: 'calculator',
      color: 'mint',
      routerLink: '/analytics/accounting-dashboard',
      actionList: [
        {
          router: '/analytics/accounting-dashboard',
          title: 'LAUNCH_PAD.OPEN_ACCOUNTING_DASHBOARD',
          icon: 'list'
        },
      ]
    },
    [UserShortcutEnum.CASHFLOW]: {
      key: UserShortcutEnum.CASHFLOW,
      title: 'CASH_FLOW.CASH_FLOW',
      icon: 'cashflow',
      color: 'mint',
      routerLink: '/analytics/cashflow',
      actionList: [
        {
          router: '/analytics/cashflow',
          title: 'CASH_FLOW.OPEN_CASHFLOW_LIST',
          icon: 'list'
        },
      ]
    },
    // [UserShortcutEnum.WAREHOUSE_DATA]: {
    //   key: UserShortcutEnum.WAREHOUSE_DATA,
    //   title: 'DASHBOARDS.WAREHOUSE_DATA',
    //   icon: 'warehouse',
    //   color: 'mint',
    //   routerLink: '/analytics/warehouse-dashboard',
    //   actionList: [
    //     {
    //       router: '/analytics/warehouse-dashboard',
    //       title: 'LAUNCH_PAD.OPEN_WAREHOUSE_DASHBOARD',
    //       icon: 'list'
    //     },
    //   ]
    // },
    // ADMINISTRATION
    [UserShortcutEnum.COMPANY_PROFILE]: {
      key: UserShortcutEnum.COMPANY_PROFILE,
      title: 'ADMINISTRATION.COMPANY_PROFILE',
      icon: 'factory',
      color: 'grey',
      routerLink: '/administration/company-profile',
      actionList: [
        {
          router: '/administration/company-profile',
          title: 'LAUNCH_PAD.OPEN_ADMINISTRATION',
          icon: 'list'
        },
      ]
    },
  };
}

export const quickActionsConfig = { // todo model
// TRADE
  [UserShortcutEnum.OFFERS]: {
    key: UserShortcutEnum.OFFERS,
    title: 'BUTTON.CREATE_OFFER',
    icon: 'offer',
    color: 'yellow',
    action: 'createNewOffer'
  },
  [UserShortcutEnum.SALES_ORDERS]: {
    key: UserShortcutEnum.SALES_ORDERS,
    title: 'OFFER.FORM.CREATE_SO',
    icon: 'sales-order',
    color: 'yellow',
    action: 'createNewOrder'
  },
  [UserShortcutEnum.PURCHASE_ORDERS]: {
    key: UserShortcutEnum.PURCHASE_ORDERS,
    title: 'BUTTON.CREATE_PO',
    icon: 'purchase-order',
    color: 'yellow',
    action: 'createNewPurchaseOrder'
  },
  [UserShortcutEnum.RETURNS]: {
    key: UserShortcutEnum.RETURNS,
    title: 'BUTTON.CREATE_RETURN',
    icon: 'exchange-return',
    color: 'yellow',
    action: 'createNewERA',
  },
  // SUBSCRIPTIONS
  [UserShortcutEnum.TIME_TRACKING]: {
    key: UserShortcutEnum.TIME_TRACKING,
    title: 'BUTTON.TRACK_TIME',
    icon: 'timer',
    color: 'green',
    action: 'onLogTimeClick',
  },
  [UserShortcutEnum.SUBSCRIPTIONS]: {
    key: UserShortcutEnum.SUBSCRIPTIONS,
    title: 'BUTTON.CREATE_SUBSCRIPTION',
    icon: 'subscriptions',
    color: 'green',
    action: 'createNewSubscription'
  },
  // ACCOUNTING
  [UserShortcutEnum.OUTGOING_INVOICES]: {
    key: UserShortcutEnum.OUTGOING_INVOICES,
    title: 'BUTTON.CREATE_OIN',
    icon: 'outgoing-invoice',
    color: 'violet',
    action: 'createNewInvoice'
  },
  [UserShortcutEnum.INCOMING_INVOICES]: {
    key: UserShortcutEnum.INCOMING_INVOICES,
    title: 'BUTTON.CREATE_INCOMING_INVOICE',
    icon: 'incoming-invoice',
    color: 'violet',
    action: 'createNewIncomingInvoice'
  },
  [UserShortcutEnum.SCHEDULE_PAYMENTS]: {
    key: UserShortcutEnum.SCHEDULE_PAYMENTS,
    title: 'SCHEDULED_PAYMENT.CREATE_SCHEDULED_PAYMENT',
    icon: 'recurring-costs',
    color: 'violet',
    action: 'createNewScheduledPayment'
  },
  [UserShortcutEnum.CREDIT_NOTES]: {
    key: UserShortcutEnum.CREDIT_NOTES,
    title: 'BUTTON.CREATE_CREDIT_NOTE',
    icon: 'credit-note',
    color: 'violet',
    action: 'createNewCreditNote'
  },
  // WAREHOUSE
  [UserShortcutEnum.OUTGOING_DELIVERIES]: {
    key: UserShortcutEnum.OUTGOING_DELIVERIES,
    title: 'FORM.CREATE_DELIVERY_NOTE',
    icon: 'outgoing-delivery',
    color: 'orange',
    action: 'createNewDeliveryNote'
  },
  // PRODUCTS
  [UserShortcutEnum.GOODS]: {
    key: UserShortcutEnum.GOODS,
    title: 'BUTTON.CREATE_PRODUCT',
    icon: 'box',
    color: 'purple',
    action: 'createNewProduct'
  },
  [UserShortcutEnum.SERVICES]: {
    key: UserShortcutEnum.SERVICES,
    title: 'BUTTON.CREATE_SERVICE',
    icon: 'checklist',
    color: 'purple',
    action: 'createNewService'
  },
  [UserShortcutEnum.DIGITAL_PRODUCTS]: {
    key: UserShortcutEnum.DIGITAL_PRODUCTS,
    title: 'BUTTON.CREATE_DGT_PRODUCT',
    icon: 'digital-product',
    color: 'purple',
    action: 'createNewDigitalProduct'
  },
  [UserShortcutEnum.GENERAL_PRODUCTS]: {
    key: UserShortcutEnum.GENERAL_PRODUCTS,
    title: 'BUTTON.CREATE_DGT_PRODUCT',
    icon: 'digital-product',
    color: 'purple',
    action: 'createNewDigitalProduct'
  },
  [UserShortcutEnum.SCANNED_BARCODES]: {
    key: UserShortcutEnum.SCANNED_BARCODES,
    title: 'ADMINISTRATION.SCANNED_BARCODES',
    icon: 'barcode',
    color: 'purple',
    action: 'barcodeDialogToggle'
  },
  // PARTNERS
  [UserShortcutEnum.CORPORATE_PARTNERS]: {
    key: UserShortcutEnum.CORPORATE_PARTNERS,
    title: 'LAUNCH_PAD.CREATE_COMPANY',
    icon: 'factory',
    color: 'brown',
    action: 'createNewPartner'
  },
  [UserShortcutEnum.PRIVATE_PARTNERS]: {
    key: UserShortcutEnum.PRIVATE_PARTNERS,
    title: 'LAUNCH_PAD.CREATE_PP',
    icon: 'person',
    color: 'brown',
    action: 'createNewPrivatePartner'
  },
};
