import { TranslateService } from '@ngx-translate/core';

import { ColumnTypeEnum, TableColumnModel, TableColumnModelExtended } from 'common/src/models/table-column.model';
import { TableSummaryBarItemModel, TableSummaryBarItemTypeEnum } from 'common/src/modules/ui-components/table-summary-bar/table-summary-bar.model';
import { NavBarBtnModel, UIStatesEnum } from 'common/src/models';
import { TableActivateTypes } from 'common/src/modules/ui-components/table/custom-table.enums';
import { ProductTypes } from 'common/src/modules/products/product-types';
import { EcoOrderListTabsEnum } from '../../enums';
import { UNITS_LIST_BY_PRODUCT_TYPE } from 'common/src/modules/rnpl-common/constants';
import { VAT_LIST } from '../../../../../shared/constants/vat-list';
import {
  ControlTypeEnum, InputDropdownTypeEnum,
  PositionInfoListModel,
  PositionItemTypeEnum
} from 'common/src/modules/position-card/position-card-models.model';
import { SalesOrderListTabsEnum } from '../../../../../sales-order/enums';

export const PositionsButtons: NavBarBtnModel[] = [
  {
    classes: 'btn-white red',
    text: 'BUTTON.REMOVE',
    multipleText: 'BUTTON.REMOVE',
    badgeClass: 'rnpl-badge--red',
    iconName: 'minus-circle',
    actionName: 'onDeletePositionsClick',
    multiple: false
  }
];

const startTablePart: TableColumnModel[] = [
  {
    cellTemplate: 'withSelection',
    headerTemplate: 'headerSelections',
    cellClass: 'center',
    name: 'selection',
    width: 40,
    maxWidth: 40,
    sortable: false,
    resizeable: false,
    frozenLeft: true,
  }
];

const actions: TableColumnModel[] = [
  {
    cellTemplate: 'withClickableIcon',
    name: '',
    width: 40,
    maxWidth: 40,
    sortable: false,
    resizeable: false,
    clicktype: TableActivateTypes.DeleteRow,
    icon: 'minus-circle',
    cellClass: 'remove-btn-grey',
    frozenRight: true,
  }
];

export function generateTableColumns(
  productType: ProductTypes,
  state: UIStatesEnum,
  documentStatus: EcoOrderListTabsEnum,
  // vatBlocked: boolean,
  // hasLinkedTimeTrackingRecord: boolean,
): TableColumnModelExtended[] {
  const cantEditColumn = (state === UIStatesEnum.VIEW);

  const idColNames = {
    [ProductTypes.GOODS]: 'COLUMN.PRODUCT_ID',
    [ProductTypes.SERVICES]: 'COLUMN.SERVICE_ID',
    [ProductTypes.DIGITAL]: 'COLUMN.DGT_PRODUCT_ID',
  };

  const nameColsNames = {
    [ProductTypes.GOODS]: 'COLUMN.PRODUCT_NAME',
    [ProductTypes.SERVICES]: 'COLUMN.SERVICES_NAME',
    [ProductTypes.DIGITAL]: 'COLUMN.DGT_PRODUCT_NAME',
  };

  let endTablePart: TableColumnModelExtended[] = [
    {
      cellTemplate: 'numberRows',
      cellClass: 'center',
      name: 'Nr.',
      width: 40,
      maxWidth: 40,
      sortable: false,
      resizeable: false,
      headerClass: 'center',
    },
    {
      cellTemplate: 'withLinkPrimary',
      headerTemplate: 'headerPrimary',
      cellClass: 'link-primary',
      name: idColNames[productType],
      prop: 'productLink',
      sortable: true,
      width: 172,
      maxWidth: 172,
    },
    {
      cellTemplate: 'withLinkPrimary',
      headerTemplate: 'headerPrimary',
      cellClass: 'link-primary inline',
      name: nameColsNames[productType],
      preventTranslate: true,
      prop: 'productNameLink',
      sortable: true,
    },
    {
      cellTemplate: state === UIStatesEnum.VIEW ? 'primary' : 'withTextarea',
      headerTemplate: 'headerPrimary',
      name: 'FORM.DESCRIPTION',
      prop: 'providedServices',
      sortable: true,
      sourceTextFormat: true,
      type: ColumnTypeEnum.TEXT,
      hideWithProductType: [ProductTypes.GOODS, ProductTypes.DIGITAL],
      width: 470,
      maxWidth: 470,
    },
    {
      cellTemplate: cantEditColumn ? 'primaryWithNumber' : 'withInput',
      headerTemplate: 'headerWithHints',
      name: 'COLUMN.QUANTITY',
      prop: 'quantity',
      sortable: true,
      type: ColumnTypeEnum.INT_OR_FLOAT_ONLY,
      decimal: 0,
      unitTypeShortcut: true,
      cellClass: 'right font-weight-500',
      width: 112,
      maxWidth: 112,
    },
    {
      cellTemplate: 'withStatusLine',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.OPEN',
      prop: 'openData',
      sortable: true,
      type: ColumnTypeEnum.STATUS_LINE_OPEN_AMOUNT,
      width: 164,
      maxWidth: 164,
      showWithDocumentStatus: [EcoOrderListTabsEnum.OPEN],
      showWithUIState: [UIStatesEnum.VIEW],
      hideWithProductType: [ProductTypes.DIGITAL, ProductTypes.SERVICES],
    },
    {
      cellTemplate: (productType === ProductTypes.DIGITAL || cantEditColumn) ? 'withUnitType' : 'withDropdown',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.UNITS',
      prop: 'unitType',
      sortable: true,
      cellClass: 'overflow-visible',
      listItems: UNITS_LIST_BY_PRODUCT_TYPE[productType],
      appendTo: '.relative-wrapper',
      bindLabel: 'label',
      bindValue: 'value',
      capitalizeFirstLetter: true,
      width: 106,
      maxWidth: 106,
    },
    {
      cellTemplate: cantEditColumn ? 'primary' : 'withInput',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.UNIT_PRICE_NET',
      prop: 'netUnitPrice',
      sortable: true,
      additionalText: '€', //todo: add from workspace currency
      currencyConversion: true,
      type: ColumnTypeEnum.NUMBER,
      cellClass: 'right',
      headerClass: 'right',
      width: 158,
      maxWidth: 158,
    },
    {
      cellTemplate: cantEditColumn ? 'primary' : 'withInput',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.AMOUNT_NET',
      prop: 'netTotalPrice',
      type: ColumnTypeEnum.NUMBER,
      sortable: true,
      additionalText: '€', //todo: add from workspace currency
      currencyConversion: true,
      cellClass: 'right',
      headerClass: 'right',
      width: 158,
      maxWidth: 158,
    },
    {
      cellTemplate: cantEditColumn ? 'primary' : 'withDropdown',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.VAT',
      prop: 'vat',
      sortable: true,
      // disabled: vatBlocked,
      cellClass: 'overflow-visible right',
      headerClass: 'right',
      appendTo: 'body',
      additionalText: '%',
      listItems: VAT_LIST,
      bindLabel: 'label',
      bindValue: 'value',
      width: 104,
      maxWidth: 104,
    },
    {
      cellTemplate: cantEditColumn ? 'primary' : 'withInput',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.UNIT_PRICE_GROSS',
      prop: 'grossUnitPrice',
      sortable: true,
      cellClass: 'right',
      headerClass: 'right',
      additionalText: '€', //todo: add from workspace currency
      currencyConversion: true,
      type: ColumnTypeEnum.NUMBER,
      width: 158,
      maxWidth: 158,
    },
    {
      cellTemplate: cantEditColumn ? 'primary' : 'withInput',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.AMOUNT_GROSS',
      prop: 'grossTotalPrice',
      sortable: true,
      additionalText: '€', //todo: add from workspace currency
      currencyConversion: true,
      type: ColumnTypeEnum.NUMBER,
      cellClass: 'right font-weight-500',
      headerClass: 'right',
      width: 158,
      maxWidth: 158,
    },
    {
      cellTemplate: 'withInput',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.PROVIDED_FROM',
      prop: 'providedFrom',
      sortable: true,
      resizeable: false,
      width: 288,
      type: ColumnTypeEnum.DATE,
      hideWithProductType: [ProductTypes.GOODS],
      showWithUIState: [
        UIStatesEnum.EDIT,
        UIStatesEnum.CREATE
      ]
    },
    {
      cellTemplate: 'withInput',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.PROVIDED_TO',
      prop: 'providedTo',
      sortable: true,
      resizeable: false,
      width: 288,
      type: ColumnTypeEnum.DATE,
      hideWithProductType: [ProductTypes.GOODS],
      showWithUIState: [
        UIStatesEnum.EDIT,
        UIStatesEnum.CREATE
      ]
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.PERIOD',
      prop: 'providedWithin',
      sortable: true,
      resizeable: false,
      width: 172,
      type: ColumnTypeEnum.TEXT,
      hideWithProductType: [ProductTypes.GOODS],
      showWithUIState: [UIStatesEnum.VIEW]
    },
    {
      cellTemplate: 'withProfit',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.MARGIN',
      prop: 'marginData',
      sortable: true,
      currencyConversion: true,
      cellClass: 'right font-weight-500',
      headerClass: 'right',
      width: 150,
      maxWidth: 150,
      hideWithProductType: [ProductTypes.SERVICES, ProductTypes.DIGITAL],
      showWithDocumentStatus: [
        EcoOrderListTabsEnum.OPEN,
        EcoOrderListTabsEnum.COMPLETED,
      ]
    },
    // {
    //   cellTemplate: state === UIStatesEnum.VIEW ? 'primary' : 'withTextarea',
    //   name: 'Remark',
    //   prop: 'remark',
    //   sortable: true,
    //   sourceTextFormat: true,
    //   type: ColumnTypeEnum.TEXT,
    //   width: 470,
    // },
    {
      cellTemplate: 'withClickableIcon',
      name: '',
      width: 40,
      maxWidth: 40,
      sortable: false,
      resizeable: false,
      clicktype: TableActivateTypes.Sidebar,
      icon: 'info',
      cellClass: 'px-3',
      frozenRight: true,
      showWithUIState: [UIStatesEnum.VIEW]
    }
  ];

  endTablePart = endTablePart
    .filter((col: TableColumnModelExtended) => {
    if (col.hideWithProductType) {
      return !col.hideWithProductType.includes(productType);
    }
    return true;
  }).filter((col: TableColumnModelExtended) => {
    if (col.showWithDocumentStatus) {
      return col.showWithDocumentStatus.includes(documentStatus);
    }
    return true;
  }).filter((col: TableColumnModelExtended) => {
    if (col.showWithUIState) {
      return col.showWithUIState.includes(state);
    }
    return true;
  });

  const actionsPart = cantEditColumn ? [] : actions;

  if ((state === UIStatesEnum.CREATE || state === UIStatesEnum.EDIT) && !cantEditColumn) {
    return startTablePart.concat(endTablePart, actionsPart);
  }
  return endTablePart.concat(actionsPart);
}

export function getTableSummaryBarItems(
  totals: any, // todo
  documentStatus: EcoOrderListTabsEnum
): TableSummaryBarItemModel[] {

  const tableSummaryBarItems: TableSummaryBarItemModel[] = [
    {
      label: 'TABLE_SUMMARY_BAR.POSITIONS',
      type: TableSummaryBarItemTypeEnum.WITH_NUMBER,
      value: totals.totalCount,
      titleIcon: 'box',
      basisWidth: 167
    },
    {
      label: 'TABLE_SUMMARY_BAR.TOTAL_AMOUNT_NET',
      type: TableSummaryBarItemTypeEnum.WITH_CURRENCY,
      value: totals.totalAmountNet,
      currencySymbol: '€',
      currencyConversion: true,
      basisWidth: 172
    },
    {
      label: 'TABLE_SUMMARY_BAR.TOTAL_VAT',
      type: TableSummaryBarItemTypeEnum.WITH_CURRENCY,
      value: totals.totalVat,
      currencySymbol: '€',
      currencyConversion: true,
      basisWidth: 172
    },
    {
      label: 'TABLE_SUMMARY_BAR.TOTAL_AMOUNT_GROSS',
      type: TableSummaryBarItemTypeEnum.WITH_CURRENCY,
      value: totals.totalAmountGross,
      currencySymbol: '€',
      currencyConversion: true,
      basisWidth: 172
    },
  ];

  const productsItems: TableSummaryBarItemModel[] = [
    {
      label: 'TABLE_SUMMARY_BAR.PRODUCTS_PCS',
      type: TableSummaryBarItemTypeEnum.WITH_NUMBER,
      value: totals.productsPcs,
      hideWithDocumentStatus: [EcoOrderListTabsEnum.OPEN],
      basisWidth: 135
    },
    {
      label: 'TABLE_SUMMARY_BAR.OPEN_PRODUCTS',
      type: TableSummaryBarItemTypeEnum.STATUS_LINE_MAX_VAL_ONLY,
      value: totals.openProductsPcs,
      maxValue: totals.productsPcs,
      inverse: true,
      unitTypeShortcut: true,
      showWithDocumentStatus: [EcoOrderListTabsEnum.OPEN],
      basisWidth: 135
    }
  ];

  const servicesPcsItems: TableSummaryBarItemModel[] = [
    {
      label: 'TABLE_SUMMARY_BAR.SERVICES_PCS',
      type: TableSummaryBarItemTypeEnum.WITH_NUMBER,
      value: totals.servicesPcs,
      hideWithDocumentStatus: [],
      basisWidth: 135
    },
  ];

  const servicesHrsItems: TableSummaryBarItemModel[] = [
    {
      label: 'TABLE_SUMMARY_BAR.SERVICES_H',
      type: TableSummaryBarItemTypeEnum.WITH_NUMBER,
      value: totals.servicesHours,
      hideWithDocumentStatus: [],
      basisWidth: 135
    },
  ];

  const digitalProductsPcsItems: TableSummaryBarItemModel[] = [
    {
      label: 'TABLE_SUMMARY_BAR.DGT_PRODUCT_PCS',
      type: TableSummaryBarItemTypeEnum.WITH_NUMBER,
      value: totals.digitalProductsPcs,
      basisWidth: 135
    },
  ];

  const digitalProductsHrsItems: TableSummaryBarItemModel[] = [
    {
      label: 'TABLE_SUMMARY_BAR.DGT_PRODUCT_H',
      type: TableSummaryBarItemTypeEnum.WITH_NUMBER,
      value: totals.digitalProductsHours,
      basisWidth: 135
    },
  ];

  if (totals.digitalProductsPcs) {
    tableSummaryBarItems.splice(1, 0, ...digitalProductsPcsItems);
  }

  if (totals.digitalProductsHours) {
    tableSummaryBarItems.splice(1, 0, ...digitalProductsHrsItems);
  }

  if (totals.servicesHours) {
    tableSummaryBarItems.splice(1, 0, ...servicesHrsItems);
  }

  if (totals.servicesPcs) {
    tableSummaryBarItems.splice(1, 0, ...servicesPcsItems);
  }

  if (totals.productsPcs) {
    tableSummaryBarItems.splice(1, 0, ...productsItems);
  }

  return tableSummaryBarItems
    .filter((col: TableSummaryBarItemModel) => {
    if (col.hideWithDocumentStatus) {
      return !col.hideWithDocumentStatus.includes(documentStatus);
    }
    return true;
  }).filter((col: TableSummaryBarItemModel) => {
    if (col.showWithDocumentStatus) {
      return col.showWithDocumentStatus.includes(documentStatus);
    }
    return true;
  });
}

export function generatePositionsCardsInfo(
  translateService: TranslateService,
  productType: ProductTypes,
  state: UIStatesEnum,
  documentStatus: string,
  vatBlocked: boolean,
): PositionInfoListModel[] {
  const cantEditColumn = (state === UIStatesEnum.VIEW);

  let positionInfoList: PositionInfoListModel[] = [
    {
      label: 'FORM.DESCRIPTION',
      prop: 'productDescription',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.TEXTAREA,
      itemClass: 'col-12',
      isOptional: true,
      hideWithProductType: [ProductTypes.SERVICES],
      showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE]
    },
    {
      label: 'FORM.DESCRIPTION',
      prop: 'providedServices',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.TEXTAREA,
      itemClass: 'col-12',
      isOptional: true,
      hideWithProductType: [ProductTypes.GOODS, ProductTypes.DIGITAL],
      showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE]
    },
    {
      label: 'COLUMN.QUANTITY',
      hintId: 'quantityColumn',
      prop: 'quantity',
      unitType: 'unitType',
      type: cantEditColumn ? PositionItemTypeEnum.NUMBER : PositionItemTypeEnum.WITH_CONTROL,
      forcedType: PositionItemTypeEnum.PRIMARY,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.NUMBER,
    },
    {
      label: 'COLUMN.UNIT_PRICE_NET',
      prop: 'netUnitPrice',
      suffix: '€',
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      forcedType: PositionItemTypeEnum.CURRENCY,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      currencyIsOriginal: true,
    },
    {
      label: 'COLUMN.AMOUNT_NET',
      prop: 'netTotalPrice',
      suffix: '€',
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      forcedType: PositionItemTypeEnum.CURRENCY,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      currencyIsOriginal: true,
    },
    {
      label: 'COLUMN.VAT',
      prop: 'vat',
      suffix: '%',
      type: cantEditColumn ? PositionItemTypeEnum.PRIMARY : PositionItemTypeEnum.WITH_CONTROL,
      forcedType: PositionItemTypeEnum.PRIMARY,
      controlType: ControlTypeEnum.DROPDOWN,
      listItems: VAT_LIST,
      bindLabel: 'label',
      bindValue: 'value',
      disabled: vatBlocked,
      vatBlocked: vatBlocked,
    },
    {
      label: 'COLUMN.UNIT_PRICE_GROSS',
      prop: 'grossUnitPrice',
      suffix: '€',
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      forcedType: PositionItemTypeEnum.CURRENCY,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      currencyIsOriginal: true,
    },
    {
      label: 'COLUMN.AMOUNT_GROSS',
      prop: 'grossTotalPrice',
      suffix: '€',
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      forcedType: PositionItemTypeEnum.CURRENCY,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      valueClass: 'font-weight-500',
      currencyIsOriginal: true,
    },
    {
      label: '',
      prop: '',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.DISCOUNT,
    },
    {
      label: 'COMMON.INTERNAL_REMARK_1',
      prop: 'remark',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.REMARK,
      bindValue: 'value',
    },
    {
      label: 'COLUMN.OPEN',
      suffix: '€',
      type: PositionItemTypeEnum.STATUS_LINE,
      colorState: 'main-600',
      prop: 'openData',
      hintId: 'openColumn',
      showWithDocumentStatus: [SalesOrderListTabsEnum.OPEN],
      showWithUIState: [UIStatesEnum.VIEW],
      hideWithProductType: [ProductTypes.DIGITAL, ProductTypes.SERVICES],
    },
    {
      label: 'COLUMN.MARGIN',
      prop: 'marginData',
      suffix: '€',
      type: PositionItemTypeEnum.PROFIT,
      currencyConversion: true,
      hideWithProductType: [ProductTypes.DIGITAL],
      showWithDocumentStatus: [
        SalesOrderListTabsEnum.DRAFT,
        SalesOrderListTabsEnum.OPEN,
        SalesOrderListTabsEnum.COMPLETED,
      ],
      showWithUIState: [UIStatesEnum.VIEW]
    },
    {
      label: 'COLUMN.MARGIN',
      prop: 'marginData',
      suffix: '€',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.PROFIT,
      currencyConversion: true,
      hideWithProductType: [ProductTypes.DIGITAL],
      showWithDocumentStatus: [
        SalesOrderListTabsEnum.DRAFT,
        SalesOrderListTabsEnum.OPEN,
        SalesOrderListTabsEnum.COMPLETED,
      ],
      showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE]
    },
  ];

  positionInfoList = positionInfoList
    .filter((item) => {
      if (item.hideWithProductType) {
        return !item.hideWithProductType.includes(productType);
      }
      return true;
    }).filter((item) => {
      if (item.showWithUIState) {
        return item.showWithUIState.includes(state);
      }
      return true;
    }).filter((item) => {
      if (item.showWithDocumentStatus) {
        return item.showWithDocumentStatus.includes(documentStatus);
      }
      return true;
    });

  return positionInfoList;
}
