import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { AiLoaderComponent } from './ai-loader.component';
import { RnplCommonModule } from 'common/src/modules';


@NgModule({
  declarations: [
    AiLoaderComponent
  ],
  exports: [
    AiLoaderComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RnplCommonModule
  ],
})
export class AiLoaderModule { }
