import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { get } from 'lodash';

import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import {
  selectCountries,
  selectCreditNote,
  selectCreditNoteCurrentState
} from 'projects/workspace/src/app/credit-note/store/selectors';
import { CreditNoteModel } from 'projects/workspace/src/app/credit-note/models';
import { BankTransferMethodsEnum, PaymentMethodsEnum, UIStatesEnum } from '../../../../models';
import { CreditNotePurposeEnum, CreditNotesListTabsEnum } from 'projects/workspace/src/app/credit-note/enums';
import { PaymentDirectionEnum } from 'projects/workspace/src/app/payment/models/payment.model';
import { FormInputChangedModel } from 'projects/workspace/src/app/shared/models';
import { CreditNoteApiService } from 'projects/workspace/src/app/credit-note/services/credit-note-api.service';
import { convertAddressToStringHelper } from '../../../rnpl-common/helpers';
import { CountryModel } from '../../../rnpl-common';
import { DocumentTypesUppercaseEnum } from '../../modals-common/link-document-modal/enums/ducument-types.enum';
import { IncomingInvoiceListTabsEnum } from 'projects/workspace/src/app/incoming-invoice/enums';
import { CreditNoteFormsService } from 'projects/workspace/src/app/credit-note/services/credit-note-forms.service';
import { AddressTypeEnum } from 'projects/workspace/src/app/sales-order/enums/address-type.enum';
import { PartnersTypeEnum } from 'projects/workspace/src/app/partners/corporate/enums';
import { CommonModalsActionsEnum, ConfirmModalComponent } from '../../modals-common';
import { UPDATE_SMALL_BUSINESS_MODAL_CONFIG } from 'projects/workspace/src/app/shared/constants';
import { selectAccountingSettings } from 'projects/workspace/src/app/store/selectors/shared.selectors';
import { AccountingSettingsModel } from 'projects/workspace/src/app/accounting/accounting-settings-module/models';

@Component({
  selector: 'rnpl-creadit-note-billing-info-modal',
  templateUrl: './creadit-note-billing-info-modal.component.html',
})
export class CreaditNoteBillingInfoModalComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public accountingSettings: AccountingSettingsModel;
  public paymentMethod: PaymentMethodsEnum;
  public currentState: UIStatesEnum;
  public creditNote: CreditNoteModel;
  public isReadonly: boolean = true;
  public addressTypeEnum: typeof AddressTypeEnum = AddressTypeEnum;
  public addressTypeList = [];
  public addressTypeForGeneralPartner = [
    {value: AddressTypeEnum.USER_DEFINED, label: 'COMMON.NEW_ADDRESS'},
    {value: AddressTypeEnum.SIMPLIFIED, label: 'COMMON.USER_DEFINED_ADDRESS'},
  ];
  public crnDirections: typeof PaymentDirectionEnum = PaymentDirectionEnum;
  public crnPurposes: typeof CreditNotePurposeEnum = CreditNotePurposeEnum;
  public crnStatuses: typeof CreditNotesListTabsEnum = CreditNotesListTabsEnum;
  public paymentMethods: typeof PaymentMethodsEnum = PaymentMethodsEnum;
  public bankTransferMethodsEnum: typeof BankTransferMethodsEnum = BankTransferMethodsEnum;
  public countries: CountryModel[] = [];

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    public dialogRef: MatDialogRef<CreaditNoteBillingInfoModalComponent>,
    public creditNoteFormService: CreditNoteFormsService,
    private readonly store: Store<AppState>,
    private readonly cdr: ChangeDetectorRef,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private creditNoteApiService: CreditNoteApiService,
  ) { }

  ngOnInit() {
    this.store.select(selectAccountingSettings)
      .pipe(takeUntil(this.destroy$))
      .subscribe((accountingSettings: AccountingSettingsModel) => this.accountingSettings = accountingSettings);

    this.trackCountry();
    this.trackCreditNoteChanges();
    this.trackCreditNoteStateChanges();
  }

  private trackCreditNoteChanges(): void {
    this.store.select(selectCreditNote)
      .pipe(takeUntil(this.destroy$))
      .subscribe((creditNote: CreditNoteModel) => {
        this.creditNote = creditNote;
        if (creditNote) {
          this.paymentMethod = get(creditNote, 'billingInformation.paymentMethod', this.paymentMethods.BANK_TRANSFER);
          // this.updateBillingInfoBlockValid();

          this.addressTypeList = [
            {value: AddressTypeEnum.BILLING, label: 'FORM.BILLING_ADDRESS', enabled: this.isCustomerSelected},
            {value: AddressTypeEnum.DELIVERY, label: 'FORM.DELIVERY_ADDRESS', enabled: this.isCustomerSelected},
            {value: AddressTypeEnum.USER_DEFINED, label: 'COMMON.NEW_ADDRESS', enabled: true},
            {value: AddressTypeEnum.SIMPLIFIED, label: 'COMMON.USER_DEFINED_ADDRESS', enabled: true},
          ].filter(i => i.enabled);

          this.creditNoteFormService.initForm(creditNote);
          if (!this.form) {
            this.form = this.creditNoteFormService.form;
          }

          this.setFormsState();
        }
      });
  }

  private trackCreditNoteStateChanges(): void {
    this.store.select(selectCreditNoteCurrentState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: UIStatesEnum) => {
        this.currentState = state;
        this.isReadonly = state === UIStatesEnum.VIEW;
        this.setFormsState();
      });
  }

  private trackCountry(): void {
    this.store.select(selectCountries)
      .pipe(takeUntil(this.destroy$))
      .subscribe((countries: CountryModel[]) => this.countries = countries);
  }

  private setFormsState(): void {
    const opts = {onlySelf: true, emitEvent: false};
    if (
      this.isReadonly ||
      (
        !get(this, 'creditNote.properties.sourceDocument.documentId') &&
        (this.crnPurpose === CreditNotePurposeEnum.IIN_CORRECTION || this.crnPurpose === CreditNotePurposeEnum.OIN_CORRECTION)
      )
    ) {
      this.form.disable(opts);
      return;
    } else {
      this.form.enable(opts);
      this.cdr.detectChanges();
    }

    if (this.crnDirection === this.crnDirections.INCOMING || !get(this.creditNote, 'properties.partner.id')) {
      this.billingAddress.disable(opts); // prevent address form validation
    }

    if (!get(this.creditNote, 'properties.partner.id')) {
      this.addressTemplateControl.disable(opts);
    }

    if (this.creditNote.billingInformation.bankAccount) {
      this.partnerIban.disable(opts);
      this.partnerBic.disable(opts);
    }

    if (
      this.creditNote.properties.purpose !== CreditNotePurposeEnum.COMMON_CORRECTION
      || this.creditNote.properties.smallBusiness
      || this.hasLinkedOIN
      || this.hasLinkedIIN
    ) {
      this.vatDisabledControl.disable(opts);
    } else {
      this.vatDisabledControl.enable(opts);
    }

    if (get(this.creditNote, 'properties.partner.generalType') === 'inland') {
      this.creditNoteFormService.billingAddress.get('country_iso3').disable(opts);
    }
  }

  public updateInput(fieldName: string, fieldValue: any): void {
    this.updateCreditNotePart({fieldName, fieldValue});
  }

  public updateSmallBusiness(fieldName: string, fieldValue: any): void {
    if (this.accountingSettings.smallBusiness.forward === fieldValue) {
      this.updateInput(fieldName, fieldValue);
      return;
    }

    const dialog = this.dialog.open(ConfirmModalComponent, UPDATE_SMALL_BUSINESS_MODAL_CONFIG);

    dialog.afterClosed().subscribe((res: CommonModalsActionsEnum) => {
      if (res === CommonModalsActionsEnum.CONFIRM) {
        this.updateInput(fieldName, fieldValue);
      } else if (res === CommonModalsActionsEnum.REJECT) {
        this.router.navigate(['/accounting/settings/general-settings']);
      } else {
        this.creditNoteFormService.initForm(this.creditNote);
        this.form = this.creditNoteFormService.form;
      }
    });
  }

  public updateCreditNotePart(fieldData: FormInputChangedModel): void {
    this.creditNoteApiService.updateCreditNotePart(this.creditNote.id, fieldData)
      .subscribe(); // creditNote updated via store
  }

  public get deliveryAddressAsText(): string {
    if (!this.form) { return ''; }

    const billingAddressVal = this.billingAddress.value;
    return convertAddressToStringHelper(billingAddressVal, this.countries);
  }

  // public fillPartnerBillingAddress(): void {
  //   this.updateCreditNotePart({
  //     fieldValue: null,
  //     fieldName: 'billingInformation.billingAddress.use-partner'
  //   });
  // }

  public editAddress(): void {
    this.addressTemplateControl.setValue(AddressTypeEnum.USER_DEFINED);
    this.updateInput('billingInformation.addressTemplate', AddressTypeEnum.USER_DEFINED);
  }

  public get isCustomerSelected(): boolean {
    return !!get(this, 'creditNote.properties.partner.runpleId');
  }

  // get showUsePartnerAddressButton(): boolean {
  //   return !this.isReadonly &&
  //     (
  //       (this.crnPurpose === CreditNotePurposeEnum.IIN_CORRECTION && get(this, 'creditNote.properties.sourceDocument.documentId')) ||
  //       (this.crnPurpose === CreditNotePurposeEnum.COMMON_CORRECTION && get(this, 'creditNote.properties.partner.id'))
  //     );
  // }

  get crnPurpose(): CreditNotePurposeEnum {
    return get(this, 'creditNote.properties.purpose');
  }

  get crnDirection(): PaymentDirectionEnum {
    return get(this, 'creditNote.properties.direction');
  }

  get hasLinkedIINStatusOpen(): boolean {
    return get(this, 'creditNote.linkedDocuments', [])
      .some(doc => doc.type === DocumentTypesUppercaseEnum.IIN && doc.status.toLowerCase() === IncomingInvoiceListTabsEnum.OPEN);
  }

  get hasLinkedOIN(): boolean {
    if (!get(this, 'creditNote.linkedDocuments')) { return false; }
    return get(this, 'creditNote.linkedDocuments')
      .some(doc => doc.type === DocumentTypesUppercaseEnum.OIN);
  }

  get hasLinkedIIN(): boolean {
    if (!get(this, 'creditNote.linkedDocuments')) { return false; }
    return get(this, 'creditNote.linkedDocuments')
      .some(doc => doc.type === DocumentTypesUppercaseEnum.IIN);
  }

  get isGeneralPartner(): boolean {
    return this.creditNote && this.creditNote.properties && this.creditNote.properties.partnerType === PartnersTypeEnum.GENERAL;
  }

  get partnerIban(): FormControl { return this.form.get('partnerIban') as FormControl; }
  get partnerBic(): FormControl { return this.form.get('partnerBic') as FormControl; }
  get reference(): FormControl { return this.form.get('reference') as FormControl; }
  get billingAddress(): FormGroup { return this.form.get('billingAddress') as FormGroup; }
  get subtractControl(): FormControl { return this.form.get('subtract') as FormControl; }
  get bankAccountControl(): FormControl { return this.form.get('bankAccount') as FormControl; }
  get vatDisabledControl(): FormControl { return this.form.get('vatDisabled') as FormControl; }
  get smallBusinessControl(): FormControl { return this.form.get('smallBusiness') as FormControl; }
  get addressTemplateControl(): FormControl { return this.form.get('addressTemplate') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
