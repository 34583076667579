import { Component, Input } from '@angular/core';

import { ColumnTable } from '../../models/column-model';
import { VatStatusEnum } from '../../../../../models';
import { SalesOrderListTabsEnum } from 'projects/workspace/src/app/sales-order/enums';
import { OutgoingInvoiceListTabsEnum } from 'projects/workspace/src/app/outgoing-invoice/enums';
import { TradeOfferListTabsEnum } from 'projects/workspace/src/app/trade-offer/enums';
import { UserListTabs } from 'projects/workspace/src/app/hrm/pages/user-list/enums/user-list-tabs.enum';
import { FlagsEnum } from 'common/src/models/flags.enum';
import { DirectionDocumentEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/document-direction.enum';
import { PaymentListTabsEnum } from 'projects/workspace/src/app/payment/enums';
import { ProductStatusEnum } from 'projects/workspace/src/app/products-two-level/enums/product-status.enum';
import { DeliveryNoteListTabsEnum } from 'projects/workspace/src/app/delivery-note/enums';
import { SubscriptionListTabsEnum } from 'projects/workspace/src/app/subscription/enums';
import { DocumentTypesUppercaseEnum } from '../../../../modals/modals-common/link-document-modal/enums/ducument-types.enum';

@Component({
  selector: 'rnpl-column-with-type-status',
  templateUrl: './column-with-type-status.component.html',
  styleUrls: ['./column-with-type-status.component.scss']
})
export class ColumnWithTypeStatusComponent {
  @Input() value: string | string[];
  @Input() column: ColumnTable;
  @Input() documentType: DocumentTypesUppercaseEnum;

  public vatBadgeClasses: { [key: string]: string } = {
    [VatStatusEnum.NA]: null,
    [VatStatusEnum.PENDING]: 'rnpl-badge--grey',
    [VatStatusEnum.NOT_VERIFIED]: 'rnpl-badge--grey',
    [VatStatusEnum.PASSED]: 'rnpl-badge--green',
    [VatStatusEnum.FAILED]: 'rnpl-badge--red'
  };

  public defaultClasses: { [key: string]: string } = {
    // Sales Order
    [SalesOrderListTabsEnum.DRAFT]: 'rnpl-badge--grey',
    [SalesOrderListTabsEnum.DELETED]: 'rnpl-badge--empty',
    [SalesOrderListTabsEnum.COMPLETED]: 'rnpl-badge--green',
    [SalesOrderListTabsEnum.CANCELED]: 'rnpl-badge--red',

    // Outgoing incoice
    [OutgoingInvoiceListTabsEnum.OPEN]: 'rnpl-badge--blue',
    [OutgoingInvoiceListTabsEnum.PAID]: 'rnpl-badge--green',
    // Trade offer
    [TradeOfferListTabsEnum.OPEN]: 'rnpl-badge--blue',
    [TradeOfferListTabsEnum.ACCEPTED]: 'rnpl-badge--green',
    [TradeOfferListTabsEnum.DECLINED]: 'rnpl-badge--red',
    // Users
    [UserListTabs.Active]: 'rnpl-badge--blue',
    [UserListTabs.Inactive]: 'rnpl-badge--red',
    [UserListTabs.Pending]: 'rnpl-badge--grey',
    // Document direction
    [DirectionDocumentEnum.INCOMING]: 'rnpl-badge--jade',
    [DirectionDocumentEnum.OUTGOING]: 'rnpl-badge--red',
    // Payments
    [PaymentListTabsEnum.BOOKED]: 'rnpl-badge--green',
    // Flags
    [FlagsEnum.EDITING]: 'rnpl-badge--main-400',
    [FlagsEnum.OVERPAID]: 'rnpl-badge--yellow',
    [FlagsEnum.DELAYED]: 'rnpl-badge--yellow',
    [FlagsEnum.EQUALIZED]: 'rnpl-badge--yellow',
    [FlagsEnum.ENDED]: 'rnpl-badge--green',
    [FlagsEnum.READY]: 'rnpl-badge--blue',
    [FlagsEnum.BLOCKED]: 'rnpl-badge--grey',
    [FlagsEnum.ALLOCATED.toLowerCase()]: 'rnpl-badge--blue',
    // Products
    [ProductStatusEnum.CORRUPTED]: 'rnpl-badge--yellow',
    // Delivery note
    [DeliveryNoteListTabsEnum.PACKING]: 'rnpl-badge--blue',
    [DeliveryNoteListTabsEnum.PACKED_UP]: 'rnpl-badge--blue',
    [DeliveryNoteListTabsEnum.SHIPPED]: 'rnpl-badge--green',
    // Subscriptions
    [SubscriptionListTabsEnum.PAUSED]: 'rnpl-badge--grey',
    // Accounting periods
    ['ready']: 'rnpl-badge--blue',
    ['unreported']: 'rnpl-badge--blue',
    ['reported']: 'rnpl-badge--green',
    ['partially']: 'rnpl-badge--blue-to-green',
    ['partially_reported']: 'rnpl-badge--blue-to-green',
    ['closed']: 'rnpl-badge--main-400',
    // Common statuses
    ['ended']: 'rnpl-badge--green',
    ['warehoused']: 'rnpl-badge--green',
    ['delivered']: 'rnpl-badge--green',
    ['checked']: 'rnpl-badge--green',
    ['match']: 'rnpl-badge--green',
    ['delayed']: 'rnpl-badge--yellow',
    ['overdue']: 'rnpl-badge--yellow',
    ['difference']: 'rnpl-badge--yellow',
    ['editing']: 'rnpl-badge--main-400',
    ['incoming']: 'rnpl-badge--jade ',
    ['outgoing']: 'rnpl-badge--purple',
    ['partially_delivered']: 'rnpl-badge--blue',
    ['partially_billed']: 'rnpl-badge--blue',
    ['billed']: 'rnpl-badge--blue',
    ['received']: 'rnpl-badge--blue',
    ['processing']: 'rnpl-badge--blue',
    ['approved']: 'rnpl-badge--blue',
    ['review']: 'rnpl-badge--blue',
    ['partially_paid']: 'rnpl-badge--blue-to-green',
    ['partially_warehoused']: 'rnpl-badge--blue-to-green',
    ['partially_shipped']: 'rnpl-badge--blue-to-green',
    ['partially_prepared']: 'rnpl-badge--blue-to-green',
    ['not_required']: 'rnpl-badge--grey',
    ['primary']: 'rnpl-badge--tomato',
    ['regular']: 'rnpl-badge--violet',
    ['minor']: 'rnpl-badge--brown',
    ['shortage']: 'rnpl-badge--yellow',
    ['blocked']: 'rnpl-badge--grey',
    ['credit']: 'rnpl-badge--main-400',
  };
}
