import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { PaymentDirectionEnum, PaymentModel } from 'projects/workspace/src/app/payment/models/payment.model';
import { IncomingInvoiceApiService } from 'projects/workspace/src/app/incoming-invoice/services/invoice-api.service';
import { PurchaseOrderApiService } from 'projects/workspace/src/app/purchase-order/services/purchase-order-api.service';
import { SubscriptionApiService } from 'projects/workspace/src/app/subscription/services/subscription-api.service';
import { DeliveryNoteApiService } from 'projects/workspace/src/app/delivery-note/services/delivery-note-api.service';
import { IncomingInvoiceModel } from 'projects/workspace/src/app/incoming-invoice/models/incoming-invoice.model';
import { OutgoingInvoiceModel } from 'projects/workspace/src/app/outgoing-invoice/models';
import { TradeOfferApiService } from 'projects/workspace/src/app/trade-offer/services/trade-offer-api.service';
import { SalesOrderApiService } from 'projects/workspace/src/app/sales-order/services/sales-order-api.service';
import { ExchangeApiService } from 'projects/workspace/src/app/exchange/services/exchange-api.service';
import { SubscriptionModel } from 'projects/workspace/src/app/subscription/models/subscription.model';
import { InvoiceApiService } from 'projects/workspace/src/app/outgoing-invoice/services/invoice-api.service';
import { PaymentApiService } from 'projects/workspace/src/app/payment/services/payment-api.service';
import { TradeOfferModel } from 'projects/workspace/src/app/trade-offer/models';
import { SalesOrderModel } from 'projects/workspace/src/app/sales-order/models/sales-order.model';
import { PurchaseOrder } from 'projects/workspace/src/app/purchase-order/models';
import { ExchangeModel } from 'projects/workspace/src/app/exchange/models';
import { DeliveryNote } from 'projects/workspace/src/app/delivery-note/models/delivery-note.model';
import { CreateNewEmployeeModalComponent } from '../modules/modals/modals-hrm';
import { CreateNewPartnerModalComponent, CreateNewPrivatePartnerModalComponent } from '../modules/modals/modals-crm';
import { CompositeProductModalComponent } from '../modules/modals/modals-products/composite-product-modal/composite-product-modal.component';
import { ProductTypes } from '../modules/products/product-types';
import { TimeTrackingLogTimeModalComponent } from '../modules/modals/modals-time-tracking';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { CreateNewTransactionModalComponent } from '../modules/modals/modals-transactions';
import { CreateNewBankAccountModalComponent } from '../modules/modals/modals-bank-accounts';
import { UserPermissionsService } from './user-permissions.service';
import { ToasterService } from '../modules/ui-components/toaster';
import { BankAccountsApiService } from 'projects/workspace/src/app/bank-accounts/services/bank-accounts-api.service';
import { BankAccountModel } from 'projects/workspace/src/app/bank-accounts/models';
import { ScheduledPaymentApiService } from 'projects/workspace/src/app/scheduled-payment/services/scheduled-payment-api.service';
import { ScheduledPaymentModel } from 'projects/workspace/src/app/scheduled-payment/models';
import { CommonModalsActionsEnum, InfoModalComponent } from '../modules/modals/modals-common';
import { SubscriptionPlanEnum } from '../modules/subscription-activation/subscription-activation.emum';
import { CreditNoteCreateModalComponent } from '../modules/modals/modals-credit-note/credit-note-create-modal/credit-note-create-modal.component';
import { PlanUpgradingRequireModalConfig } from '../../../projects/workspace/src/app/shared/constants';


@Injectable()
export class ActionButtonsService {

  public paymentType: typeof PaymentDirectionEnum = PaymentDirectionEnum;
  public companyProfile: CompanyProfile;
  public barcodeDialogEmitter: Subject<any> = new Subject<any>();
  public destroy$: Subject<any> = new Subject<any>();

  public displayHints: Subject<any> = new Subject<any>();
  public displaySidebar: Subject<number> = new Subject<number>();
  public hidePositionSidebar: Subject<any> = new Subject<any>();

  constructor( public router: Router,
               private invoiceApiService: InvoiceApiService,
               private store: Store<AppState>,
               private incomingInvoiceApiService: IncomingInvoiceApiService,
               private scheduledPaymentApiService: ScheduledPaymentApiService,
               private tradeOfferApi: TradeOfferApiService,
               private deliveryNoteApiService: DeliveryNoteApiService,
               private paymentApiService: PaymentApiService,
               private exchangeApiService: ExchangeApiService,
               private readonly salesOrderApi: SalesOrderApiService,
               private readonly poApi: PurchaseOrderApiService,
               private readonly subscriptionApi: SubscriptionApiService,
               private readonly bankAccountsApiService: BankAccountsApiService,
               private dialog: MatDialog,
               private readonly userPermissionsService: UserPermissionsService,
               private readonly toasterService: ToasterService,
  ) {
    this.store.select(selectCompanyProfile)
      .subscribe((companyProfile: CompanyProfile) => {
        this.companyProfile = companyProfile;
      });
  }

  public callAction(actionName: string): void {
    if (this.companyProfile.status === 'PAUSED' && this.companyProfile.entityState === 'EXISTS') {
      // subscription activation is over
      return;
    }
    if (actionName && this[actionName]) {
      this[actionName]();
    } else {
      console.warn(`Can not call quick action with ${actionName}, it doesn't exist`);
    }
  }

  public createNewInvoice() {
    this.invoiceApiService.createOutgoingInvoiceBlank()
      .subscribe((data: OutgoingInvoiceModel) => {
          this.router.navigate([`/accounting/outgoing-invoice/${data.invoiceId}`]);
        });
  }

  public createNewIncomingInvoice() {
    this.incomingInvoiceApiService.createIncomingInvoiceBlank()
      .subscribe((data: IncomingInvoiceModel) => {
          this.router.navigate([`/accounting/incoming-invoice/${data.id}`]);
        });
  }

  public createNewScheduledPayment() {
    this.scheduledPaymentApiService.createScheduledPaymentBlank()
      .subscribe((data: ScheduledPaymentModel) => {
        this.router.navigate([`/accounting/scheduled-payment/${data.id}`]);
      });
  }

  public createNewOffer() {
    this.tradeOfferApi.createTradeOfferDraft()
      .subscribe((data: TradeOfferModel) => {
          this.router.navigate([`/trade/offers/${data.id}`]);
        });
  }

  public createNewOrder() {
    this.salesOrderApi.createSalesOrderDraft()
      .subscribe((data: SalesOrderModel) => {
        this.router.navigate([`/trade/sales-order/${data.id}`]);
      });
  }

  public createNewPurchaseOrder() {
    this.poApi.createDefaultPurchaseOrder()
      .subscribe((data: PurchaseOrder) => {
          this.router.navigate([`/trade/purchase-order/${data.id}`]);
        });
  }

  public createNewERA() {
    this.dialog.open(InfoModalComponent, {
      data: {
        title: 'DASHBOARDS.UPDATING_MODAL_TITLE',
        message: 'DASHBOARDS.UPDATING_MODAL_MSG',
        hideConfirmBtn: true,
        cancelBtnClass: 'btn-primary',
        cancelBtnText: 'BUTTON.CLOSE',
      },
      disableClose: true,
    });
    return;
    this.exchangeApiService.createExchangeDraft()
      .subscribe((data: ExchangeModel) => {
        this.router.navigate([`/trade/exchanges-and-returns/${data.id}`]);
      });
  }

  public createNewDeliveryNote() {
    this.deliveryNoteApiService.createDeliveryNoteDraft()
      .subscribe((deliveryNote: DeliveryNote) => {
          this.router.navigate([`/warehouse/outgoing-delivery/${deliveryNote.id}`]);
        });
  }

  public createNewIncomingPayment() {
    this.paymentApiService.createPaymentBlank(this.paymentType.INCOMING)
      .subscribe((payment: PaymentModel) => {
          this.router.navigate([`/accounting/payments/${payment.paymentId}`]);
        });
  }

  public createNewOutgoingPayment() {
    this.paymentApiService.createPaymentBlank(this.paymentType.OUTGOING)
      .subscribe((payment: PaymentModel) => {
          this.router.navigate([`/accounting/payments/${payment.paymentId}`]);
        });
  }

  public createNewSubscription() {
    this.subscriptionApi.createSubscriptionDraft()
      .subscribe((data: SubscriptionModel) => {
        this.router.navigate([`/services/subscription/${data.id}`]);
      });
  }

  public createNewEmployee() {
    if (this.isActionDisabledByPlan()) { return; }

    this.dialog.open(CreateNewEmployeeModalComponent, {
      data: {},
      disableClose: true,
    });
  }

  public createNewProduct() {
    this.dialog.open(CompositeProductModalComponent, {
      data: {
        family: null,
        productType: ProductTypes.GOODS
      },
      disableClose: true,
    });
  }

  public createNewService() {
    this.dialog.open(CompositeProductModalComponent, {
      data: {
        family: null,
        productType: ProductTypes.SERVICES
      },
      disableClose: true,
    });
  }

  public createNewDigitalProduct() {
    this.dialog.open(CompositeProductModalComponent, {
      data: {
        family: null,
        productType: ProductTypes.DIGITAL
      },
      disableClose: true,
    });
  }

  public createNewPartner() {
    this.dialog.open(CreateNewPartnerModalComponent, {
      disableClose: true,
    });
  }

  public createNewPrivatePartner() {
    this.dialog.open(CreateNewPrivatePartnerModalComponent, {
      disableClose: true,
    });
  }

  public createTransaction() {
    this.dialog.open(CreateNewTransactionModalComponent, {
      disableClose: true,
      minWidth: '640px',
      maxWidth: '640px',
    });
  }

  public createNewCreditNote() {
    this.dialog.open(CreditNoteCreateModalComponent, {
      disableClose: true,
    });
  }

  public onLogTimeClick(): void {
    this.dialog.open(TimeTrackingLogTimeModalComponent, {
      data: null,
      disableClose: true,
    });
  }

  public barcodeDialogToggle() {
    this.barcodeDialogEmitter.next();
  }

  public goToCategories(): void {
    if (!this.companyProfile) { return; }
    if (this.companyProfile.productOptions.goodsEnabled) {
      this.router.navigate([`/products/settings/categories/${ProductTypes.GOODS}`]);
      return;
    }
    if (this.companyProfile.productOptions.servicesEnabled) {
      this.router.navigate([`/products/settings/categories/${ProductTypes.SERVICES}`]);
      return;
    }
    if (this.companyProfile.productOptions.digitalEnabled) {
      this.router.navigate([`/products/settings/categories/${ProductTypes.DIGITAL}`]);
      return;
    }
  }

  public createBankAccountManually(): void {
    if (!this.userPermissionsService.canManageAccounting()) {
      this.toasterService.notify({type: 'error', message: 'COMMON.NO_ACCESS_ACCOUNTING'});
      return;
    }

    this.bankAccountsApiService.getBankAccountPrimary()
      .pipe(takeUntil(this.destroy$))
      .subscribe((bankAccount: BankAccountModel) => {
        this.dialog.open(CreateNewBankAccountModalComponent, {
          data: {
            primaryBankAccountExists: bankAccount && bankAccount.primaryAccount
          },
          disableClose: true,
        });
      });
  }

  public createBankAccount(): void {
    if (!this.userPermissionsService.canManageAccounting()) {
      this.toasterService.notify({type: 'error', message: 'COMMON.NO_ACCESS_ACCOUNTING'});
      return;
    }

    if (this.companyProfile.subscriptionManagement.subscriptionPlanActive === SubscriptionPlanEnum.FREE) {
      this.dialog.open(InfoModalComponent, { data: PlanUpgradingRequireModalConfig, disableClose: true, })
        .afterClosed()
        .subscribe(response => {
          if (response === CommonModalsActionsEnum.CONFIRM) {
            this.router.navigate(['/administration/subscription-management'])
          }
        });
      return;
    }

    this.router.navigate(['/accounting/bank-accounts/create-banks-list']);
  }

  public isActionDisabledByPlan(): boolean {
    const isActionDisabledByPlan = this.isFreePlan || this.isSoloPlan;

    if (isActionDisabledByPlan) {
      this.dialog.open(InfoModalComponent, { data: PlanUpgradingRequireModalConfig, disableClose: true, })
        .afterClosed()
        .subscribe(response => {
          if (response === CommonModalsActionsEnum.CONFIRM) {
            this.router.navigate(['/administration/subscription-management'])
          }
        });
    }

    return isActionDisabledByPlan;
  }

  // public goToCompanyProfile(): void {
  //   this.router.navigate(['/administration/company-profile']);
  // }

  public goToSalesPrice(): void {
    this.router.navigate(['/trade/prices/sales']);
  }

  public goToBinLocation(): void {
    this.router.navigate(['/warehouse/warehouse-profile/bin-locations']);
  }

  public startHints(): void {
    this.displayHints.next();
  }

  public displaySidebarHandler(productId: number): void {
    this.displaySidebar.next(productId);
  }

  public hideSidebarHandler(): void {
    this.hidePositionSidebar.next();
  }

  // DATA IMPORT MODALS

  public openProductsImportModal(): void {

  }
  public openPartnersImportModal(): void {

  }
  public openPricesImportModal(): void {

  }
  public openStockImportModal(): void {

  }

  get isFreePlan(): boolean {
    return this.companyProfile.subscriptionManagement.subscriptionPlanActive === SubscriptionPlanEnum.FREE;
  }

  get isSoloPlan(): boolean {
    return this.companyProfile.subscriptionManagement.subscriptionPlanActive === SubscriptionPlanEnum.SOLO_MONTHLY ||
      this.companyProfile.subscriptionManagement.subscriptionPlanActive === SubscriptionPlanEnum.SOLO_ANNUALLY;
  }

}
