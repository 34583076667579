import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { FilterModel } from '../../warehouse/models/filter.model';
import { ResponseList, ResponseListNested, ResponseModel } from '../../shared/models';
import { ProductTypes } from 'common/src/modules/products/product-types';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import {
  PurchasePriceListModel,
  SalesPriceListModel,
  PricesInfoModel,
  PriceListEntityModel,
  SalesPriceListProduct
} from '../models';
import { FileUploadParams } from 'common/src/models/file-upload-params.model';
import { DisplayToaster } from '../../shared/decorators/toaster';
import { AppState } from '../../store/state/app.state';
import { LoadPricesPurchaseList, LoadPricesSalesList } from '../store/actions/prices.actions';
import { DEFAULT_SORT_DIRECTION } from '../../shared/constants';
import { CompanyProfile } from '../../administration/models/company-profile.model';
import { selectCompanyProfile } from '../../administration/store/selectors';
import { AdministrationsApiService } from '../../administration/services/administrations-api.service';

@Injectable()
export class TradePricesApiService {

  public companyProfile: CompanyProfile;

  constructor(
    private readonly toasterService: ToasterService,
    private readonly translateService: TranslateService,
    private readonly http: HttpClient,
    private readonly store: Store<AppState>,
    private administrationsApiService: AdministrationsApiService,
  ) {
    this.store.select(selectCompanyProfile)
      .subscribe((companyProfile: CompanyProfile) => {
        this.companyProfile = companyProfile;
      });
  }

  getSalesPricesList(
    productType: ProductTypes,
    salesPriceName: string,
    page: string = '1',
    per_page: string = '100',
    sort: FilterModel = {nameColumn: 'id', value: DEFAULT_SORT_DIRECTION},
    filters: any = {}
  ): Observable<ResponseListNested<SalesPriceListModel>> {
    const params = {
      page,
      per_page,
      [`sort[${sort.nameColumn}]`]: sort.value,
      productType
    };

    if (salesPriceName) {
      params['name'] = salesPriceName;
    }

    for (const [key, value] of Object.entries(filters)) {
      params[`filters[${key}]`] = Array.isArray(value) ? value.join(',') : value.toString();
    }

    return this.http.get<ResponseListNested<SalesPriceListModel>>(`/v2/sales-prices/by-params`, { params })
      .pipe(
          tap((data: ResponseListNested<SalesPriceListModel>) => {
          this.store.dispatch(LoadPricesSalesList({
            pricesSalesListData: {
              [data.pagination.page]: {
                pagination: data.pagination,
                sort,
                data: data.data
              }
            },
            productType
          }));
        })
      );
  }

  @DisplayToaster({showErrorMessage: true})
  getProductSalesInfo(productId: number, salesPriceListName?: string): Observable<PricesInfoModel> {
    const params = { };
    if (salesPriceListName) {
      params['salesPriceListName'] = salesPriceListName;
    }

    return this.http.get<ResponseModel<PricesInfoModel>>(`/sales-prices/products/${productId}/info`, { params })
    .pipe(
      map((response: ResponseModel<PricesInfoModel>) => response.data)
    );
  }

  getPurchasePricesList(
    productType: ProductTypes,
    page: string = '1',
    per_page: string = '100',
    sort: FilterModel = {nameColumn: 'id', value: DEFAULT_SORT_DIRECTION},
    filters: any = {}
  ): Observable<ResponseListNested<PurchasePriceListModel>>  {
    const params = {
      page,
      per_page,
      [`sort[${sort.nameColumn}]`]: sort.value,
    };

    for (const [key, value] of Object.entries(filters)) {
      params[`filters[${key}]`] = Array.isArray(value) ? value.join(',') : value.toString();
    }

    return this.http.get<ResponseListNested<any>>(`/purchase-prices/trade/${productType}`, { params })
      .pipe(
        tap((data: ResponseListNested<PurchasePriceListModel>) => {
          this.store.dispatch(LoadPricesPurchaseList({
            pricesPurchaseListData: {
              [data.pagination.page]: {
                pagination: data.pagination,
                sort,
                data: data.data
              }
            },
            productType
          }));
        })
      );
  }

  @DisplayToaster({showErrorMessage: true})
  updatePriceListProduct(
    fieldName: string,
    fieldValue: string | number,
    priceId: number,
    productId: number,
  ): Observable<SalesPriceListProduct> {
    const body = {
      [fieldName]: fieldValue
    };

    return this.http.patch<ResponseModel<SalesPriceListProduct>>(
      `/v2/sales-prices/${priceId}/products/${productId}`, body)
      .pipe(
        tap(() => {
          if (!this.companyProfile.onboardingCompleted) {
            this.administrationsApiService.getOnboardingProcess().subscribe();
          }
        }),
        map((response: ResponseModel<SalesPriceListProduct>) => response.data)
      );
  }

  @DisplayToaster({showErrorMessage: true})
  calcPrice(
    fieldName: string,
    form: {
      netPrice: number;
      vat: number;
      grossPrice: number;
    },
  ): Observable<{ netPrice: number; vat: number; grossPrice: number; }> {

    return this.http.post<ResponseModel<{ netPrice: number; vat: number; grossPrice: number; }>>(
      `/v2/sales-prices/price/calc`, { ...form, fieldName })
      .pipe(
        map((response: ResponseModel<{ netPrice: number; vat: number; grossPrice: number; }>) => response.data)
      );
  }

  @DisplayToaster({showErrorMessage: true})
  updatePurchasePriceListProduct(
    fieldName: string,
    fieldValue: string | number,
    priceId: number,
    productId: number,
  ): Observable<any> {
    const body = {
      [fieldName]: fieldValue
    };

    return this.http.patch<ResponseList<PurchasePriceListModel>>(`/purchase-prices/${priceId}/products/${productId}`, body);
  }

  updateProductPrices(productId: number, priceData: any): Observable<any> {
    const body = { ...priceData };

    return this.http.patch<ResponseList<PurchasePriceListModel>>(`/sales-prices/products/${productId}`, body);
  }

  updateProductPricesGoods(body: {fieldName: string, fieldValue: any, force?: boolean}): Observable<PurchasePriceListModel> {
    return this.http.patch<PurchasePriceListModel>('/purchase-prices/trade/goods', body);
  }

  getSalesPriceExportCsvParams(salesPriceId: number): Observable<Partial<FileUploadParams>> {
    const fileParams: Partial<FileUploadParams> = {
      url: `/sales-prices/${salesPriceId}/csv`,
    };
    return of(fileParams);
  }

  getPurchasePriceExportCsvParams(purchasePriceId): Observable<Partial<FileUploadParams>> {
    const fileParams: Partial<FileUploadParams> = {
      url: `/purchase-prices/${purchasePriceId}/csv`,
    };
    return of(fileParams);
  }

  @DisplayToaster({showErrorMessage: true})
  createPriceList(body: PriceListEntityModel): Observable<any> {
    return this.http.post('/sales-prices', body);
  }

  @DisplayToaster({showErrorMessage: true})
  updatePriceList(
    body: {
      previousName: string,
      newName: string,
      productType: string;
    },
  ): Observable<ResponseListNested<SalesPriceListModel>> {
    return this.http.patch<ResponseListNested<SalesPriceListModel>>(`/v2/sales-prices`, body);
  }

  @DisplayToaster({showErrorMessage: true})
  deletePriceList(name: string): Observable<ResponseListNested<SalesPriceListModel>> {
    const params = { name };
    return this.http.delete<ResponseListNested<SalesPriceListModel>>(`/v2/sales-prices`, { params });
  }

  getPriceLists(): Observable<PriceListEntityModel[]> {
    return this.http.get<ResponseModel<PriceListEntityModel[]>>('/v2/sales-prices/list')
      .pipe(
        map((response: ResponseModel<PriceListEntityModel[]>) => {
          return response.data.map((salesPrice: PriceListEntityModel) => ({
            ...salesPrice,
            title: salesPrice.name
              .replace('Default price list', this.translateService.instant('MULTIPLE_PRICE_LISTS.DEFAULT_PRICE_LIST'))
              .replace('ecommerce', this.translateService.instant('MULTIPLE_PRICE_LISTS.ECO_PRICE_LIST'))
          }));
        })
      );
  }

  getVolumes(productPriceId: number): Observable<any> {
    return this.http.get<any>(`/v2/sales-prices/product-price/${productPriceId}/volumes`)
      .pipe(map((response: ResponseModel<any>) => response.data));
  }

  createVolume(productPriceId: number): Observable<any> {
    return this.http.request<any>('post', `/v2/sales-prices/product-price/${productPriceId}/volumes`)
      .pipe(map((response: ResponseModel<any>) => response.data));
  }

  @DisplayToaster({showErrorMessage: true})
  updateVolume(
    productPriceId: number,
    volumeId: number,
    fieldName: string,
    fieldValue: any,
    force: boolean = false
  ): Observable<any> {
    const body = { fieldName, fieldValue, force };
    return this.http.patch<any>(`/v2/sales-prices/product-price/${productPriceId}/volumes/${volumeId}`, body)
      .pipe(map((response: ResponseModel<any>) => response.data));
  }

  deleteVolume(productPriceId: number, volumeId: number): Observable<any> {
    return this.http.delete<any>(`/v2/sales-prices/product-price/${productPriceId}/volumes/${volumeId}`);
  }

}
