import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { takeUntil } from 'rxjs/operators';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ProductsService } from 'common/src/modules/products/products.service';
import { ProductModel } from '../../../products';
import { CommonModalsActionsEnum, DangerModalComponent } from '../../modals-common';

@Component({
  selector: 'rnpl-product-variation-view-modal',
  templateUrl: './product-variation-view-modal.component.html',
  styleUrls: ['./product-variation-view-modal.component.scss'],
})
export class ProductVariationViewModalComponent extends BaseModalComponent {

  constructor(
    public toasterService: ToasterService,
    public productsService: ProductsService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ProductVariationViewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      productId: number,
      productVariation: ProductModel,
    }
  ) {
    super(toasterService);
  }

  public deleteVariation(): void {
    const dialog = this.dialog.open(DangerModalComponent, {
      data: {
        title: 'PRODUCTS_VARIATIONS.REMOVE_TITLE',
        message: 'PRODUCTS_VARIATIONS.REMOVE_MSG',
        confirmBtnText: 'BUTTON.REMOVE',
        confirmBtnIcon: 'minus-circle'
      }
    });

    dialog.afterClosed().subscribe(res => {
      if (res === CommonModalsActionsEnum.CONFIRM) {
        this.productsService.removeProductVariation(+this.data.productId, [+this.data.productVariation.productId])
          .pipe(takeUntil(this._destroy))
          .subscribe(() => this.dialogRef.close(CommonModalsActionsEnum.CONFIRM));
      }
    });
  }

}
