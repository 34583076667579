import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, finalize, map, takeUntil } from 'rxjs/operators';
import { NavigationEnd, Router, RouterEvent, UrlSegment } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { BreakpointObserver } from '@angular/cdk/layout';
import { get, isEqual } from 'lodash';
import { Store } from '@ngrx/store';

import { PurchaseOrder } from '../../models';
import { RnplCommonService } from 'common/src/modules/rnpl-common/services';
import { FileUploadParams } from 'common/src/models/file-upload-params.model';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { FileService } from 'common/src/services/file.service';
import { ApiResponse, CustomerTypeEnum, DeliveryTypesEnum, NavBarBtnModel, UIStatesEnum, VatStatusEnum } from 'common/src/models';
import { PurchaseOrderApiService } from '../../services/purchase-order-api.service';
import { PoTabs } from '../../enums';
import {
  CannotProceedErrModalData,
  getButtonsByStatus,
  getCreateDocumentSteps,
  getDocumentToDoList,
  getPoTabs,
  getViewDocumentList
} from './purchase-order-management.config';
import { AppState } from '../../../store/state/app.state';
import {
  isShouldRefresh,
  loadingRequestsCount,
  purchaseOrderUpdatedAt,
  selectPoCurrentState,
  selectPoPositions,
  selectPoPositionsCount,
  selectPurchaseOrder,
  selectPurchaseOrderDeliveryInfoBlockValid,
  selectPurchaseOrderPositionsBlockValid,
} from '../../store/selectors';
import { ClearPOPositions, LoadCountries, LoadPOPartners, UpdatePOCurrentState } from '../../store/actions/po.actions';
import {
  CommonModalsActionsEnum,
  ConfirmModalComponent,
  DangerModalComponent,
  InfoModalComponent,
  WarningModalComponent
} from 'common/src/modules/modals/modals-common';
import { CorporatePartnerModel } from '../../../crm/models';
import { CrmService } from '../../../crm/crm.service';
import { FileSaverService } from 'ngx-filesaver';
import { Title } from '@angular/platform-browser';
import { getLinkQueueState } from '../../../store/selectors/app.selectors';
import { STATUS_CLASSES } from '../../purchase-order.constants';
import {
  CreateDocumentStepsModel,
  DocumentToDoListModel,
  LinkedDocumentsModel,
  ViewDocumentListModel
} from 'common/src/models/view-document-list.model';
import { IncomingInvoiceApiService } from '../../../incoming-invoice/services/invoice-api.service';
import { ICON_COLOR_BY_STATUS } from '../../../outgoing-invoice/pages/outgoing-invoice/outgoing-invoice.config';
import { TABS_ICONS } from '../../../warehouse/modules/incoming-delivery/pages/incoming-delivery-list/incoming-delivery-list.config';
import {
  IncomingDeliveryCancelWarehousingModalComponent,
  IncomingDeliveryProductsReceiptModalComponent,
  IncomingDeliveryProductsReturnModalComponent,
  IncomingDeliveryWarehousingModalComponent
} from 'common/src/modules/modals/modals-incoming-delivery';
import { StockAreaEnum } from '../../../warehouse/modules/transactions/enums';
import { TranslateService } from '@ngx-translate/core';
import { UserPermissionsService } from 'common/src/services/user-permissions.service';
import { SalesOrderApiService } from '../../../sales-order/services/sales-order-api.service';
import { SalesOrderModel } from '../../../sales-order/models/sales-order.model';
import { getDocumentUrl } from '../../../shared/helpers';
import { CreateSoModalComponent, PoPositionModalComponent } from 'common/src/modules/modals/modals-purchase-order';
import { TrackShipmentHelper } from '../../../delivery-note/helpers/track-shipment.helper';
import { DocumentTemplateSaveModalComponent, DocumentTemplatesModalComponent } from 'common/src/modules/modals/modals-documents-templates';
import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { DocumentsTemplatesApiService } from '../../../shared/services';
import { AutocorrectionModalComponent } from 'common/src/modules/modals/modals-common/autocorrection-modal/autocorrection-modal.component';
import { TabDefinitionModel } from 'common/src/modules/ui-components/nav-tabs/tab-definition.model';
import { DocumentPreviewTabsEnum } from 'common/src/models/document-preview-tabs.enum';
import { IncomingDeliveryListTabsEnum } from '../../../warehouse/modules/incoming-delivery/enums';
import { ResponseModel } from '../../../shared/models';
import { TradeOfferPositionsByProductType } from '../../../trade-offer/models';
import { MODAL_CONFIG } from '../../../shared/constants/documents-modals-basic-config';
import { DeleteTemplateModalData } from '../../../shared/constants';
import { ICON_BY_DOCUMENT_TYPE } from '../../../shared/constants/icon-by-document-type';

@Component({
  selector: 'rnpl-purchase-order-management',
  templateUrl: './purchase-order-management.component.html',
  styleUrls: ['./purchase-order-management.component.scss']
})
export class PurchaseOrderManagementComponent implements OnDestroy, OnInit {
  @ViewChild('Properties', { static: true }) Properties:TemplateRef<any>;
  @ViewChild('Position', { static: true }) Position:TemplateRef<any>;
  @ViewChild('DeliveryInfo', { static: true }) DeliveryInfo:TemplateRef<any>;
  @ViewChild('PoFile', { static: true }) PoFile:TemplateRef<any>;
  @ViewChild('BusinessPartner', { static: true }) BusinessPartner:TemplateRef<any>;

  public navBarButtons: NavBarBtnModel[] = [];
  public positionsCount: number = null;
  public positionsTotals = {
    openProductsPcs: 0,
    productsPcs: 0,
    unbilledProductsPcs: 0,
    resaleProductsPcs: 0,
  };

  readonly activeTab$: BehaviorSubject<DocumentPreviewTabsEnum> = new BehaviorSubject(DocumentPreviewTabsEnum.DOCUMENT);
  readonly UIStates: typeof UIStatesEnum = UIStatesEnum;

  public currentPurchaseOrder: PurchaseOrder;
  public currentState: UIStatesEnum;

  public batchesIcons = TABS_ICONS;
  // public documentsIconName = ICON_BY_STATUS;
  public iconColor = ICON_COLOR_BY_STATUS;
  public documentPreviewTabsEnum: typeof DocumentPreviewTabsEnum = DocumentPreviewTabsEnum;

  public deliveryInfoBlockValid: boolean = false;
  public positionsBlockValid: boolean = false;
  public hideViewer: boolean = false;
  public isNarrowScreen: boolean;
  public templatePreviewApplied: boolean = false;

  public createDocumentSteps: CreateDocumentStepsModel[] = [];
  public todoDocumentList: DocumentToDoListModel[] = [];

  readonly purchaseOrderUpdatedAt$: Observable<Date>;
  readonly loadingRequestsCount$: Observable<number>;
  private readonly previousLink$: BehaviorSubject<string> = new BehaviorSubject(null);

  readonly documentStatus$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  readonly statusClasses: { [key: string]: string } = STATUS_CLASSES;
  public viewDocumentItems: ViewDocumentListModel[] = [];
  public poTabs: TabDefinitionModel[] = [];
  public sendCreateRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly destroy$: Subject<void> = new Subject();
  readonly checkVatRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  readonly btnToClearLoadingStatus$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  readonly confirmReceiptRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  readonly linkIncomingInvoiceRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  // public linkedDocumentsList: Array<DocumentStepModel> = [];

  constructor(
    private readonly poApi: PurchaseOrderApiService,
    private readonly salesOrderApiService: SalesOrderApiService,
    private readonly crmService: CrmService,
    private readonly toasterService: ToasterService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly commonService: RnplCommonService,
    private readonly store: Store<AppState>,
    private readonly fileService: FileService,
    private readonly fileSaverService: FileSaverService,
    private readonly invoiceApiService: IncomingInvoiceApiService,
    private titleService: Title,
    private translate: TranslateService,
    private userPermissionsService: UserPermissionsService,
    private breakpointObserver: BreakpointObserver,
    // private actionButtonsService: ActionButtonsService,
    private documentsTemplatesApiService: DocumentsTemplatesApiService,
  ) {
    this.purchaseOrderUpdatedAt$ = this.store.select(purchaseOrderUpdatedAt).pipe(takeUntil(this.destroy$));
    this.loadingRequestsCount$ = this.store.select(loadingRequestsCount).pipe(takeUntil(this.destroy$));
  }

  ngOnInit(): void {

    const getCurrentSegment = (): DocumentPreviewTabsEnum => get(this.router,
      'browserUrlTree.root.children.primary.segments',
      [new UrlSegment(DocumentPreviewTabsEnum.DOCUMENT, {})]).pop().path;

    this.activeTab$.next(getCurrentSegment()); // todo check usage

    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        filter((event: RouterEvent) => event.url.includes('trade/purchase-order')),
        map((event: RouterEvent) => event.url
          .replace('trade/purchase-order/', '')
          .split('/')
          .filter((s: string) => !!s)
          .slice(1)
          .shift()
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.activeTab$.next(getCurrentSegment()));

    this.store.select(getLinkQueueState)
      .pipe(
        filter((linkQueue) => linkQueue.length >= 2),
        map((linkQueue) => linkQueue[linkQueue.length - 2].link),
        takeUntil(this.destroy$)
      )
      .subscribe((linkQueue) => {
        this.previousLink$.next(linkQueue);
      });

    this.keepPoChanges();
    this.keepStateChanges();
    this.keepPoPositionsCountChanges();
    this.trackPositionsChanges();
    this.trackIsShouldRefreshChanges();
    this.trackDeliveryInfoBlockValid();
    this.trackPositionsBlockValid();
    this.getCountries();
    this.getPartners();
    this.detectScreenSize();
    this.trackApplyTemplate();
  }

  public trackApplyTemplate(): void {
    this.documentsTemplatesApiService.appliedPreviewTemplateDocumentId$
      .pipe(
        filter(id => !!id),
        takeUntil(this.destroy$)
      )
      .subscribe((id: number) => {
        this.templatePreviewApplied = true;
        this.poApi.getPO(id)
          .pipe(takeUntil(this.destroy$))
          .subscribe();
      });
  }

  private detectScreenSize(): void {
    this.breakpointObserver.observe([
      "(max-width: 1679px)"
    ]).pipe(
      map((result) => result.matches),
      distinctUntilChanged(isEqual),
      takeUntil(this.destroy$)
    )
      .subscribe((matches: boolean) => {
        this.isNarrowScreen = matches;
        this.updateViewDocumentItems();
      });
  }

  private trackIsShouldRefreshChanges(): void {
    this.store.select(isShouldRefresh)
      .pipe(takeUntil(this.destroy$))
      .subscribe((isShouldRefresh: boolean) => {
        if (isShouldRefresh) { this.refreshPurchaseOrderById(); }
      });
  }

  private keepPoChanges(): void {
    this.store.select(selectPurchaseOrder)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        if (this.templatePreviewApplied) {
          this.currentPurchaseOrder = {
            ...response,
            status: this.currentPurchaseOrder.status,
            runpleId: this.currentPurchaseOrder.runpleId
          };
          this.documentStatus$.next(get(response, 'statusValue'));
          this.documentStatus$.next(this.currentPurchaseOrder.status);
        } else {
          this.currentPurchaseOrder = response;
          this.documentStatus$.next(get(response, 'statusValue'));
        }
        this.navBarButtons = getButtonsByStatus(this.currentState, this.currentPurchaseOrder.properties.allowedSoCreation);
        // this.linkedDocumentsList = this.getLinkedDocuments(get(response, 'linkedDocuments'));
        this.updateViewDocumentItems();
        this.titleService.setTitle(`${this.translate.instant('APP.PURCHASE_ORDERS')}: ${get(response, 'runpleId') || this.translate.instant('PO.NEW_PURCHASE_ORDERS')}`);
        this.poTabs = getPoTabs(this.currentPurchaseOrder);
      });
  }

  private keepStateChanges(): void {
    this.store.select(selectPoCurrentState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: UIStatesEnum) => {
        this.currentState = state;
        // this.linkedDocumentsList = this.getLinkedDocuments(get(this.currentPurchaseOrder, 'linkedDocuments'));
        this.navBarButtons = getButtonsByStatus(state, this.currentPurchaseOrder.properties.allowedSoCreation);
        this.updateViewDocumentItems();
      });
  }

  private keepPoPositionsCountChanges(): void {
    this.store.select(selectPoPositionsCount)
      .pipe(takeUntil(this.destroy$))
      .subscribe((count: number) => {
        this.positionsCount = count || 0;
        this.updateViewDocumentItems();
      });
  }

  private trackPositionsChanges(): void {
    this.store.select(selectPoPositions)
      .pipe(takeUntil(this.destroy$))
      .subscribe((positions: ResponseModel<TradeOfferPositionsByProductType>) => {
        if (positions && positions.totals) {
          this.positionsTotals = positions.totals;
          this.updateViewDocumentItems();
        }
      })
  }

  private trackDeliveryInfoBlockValid(): void {
    this.store.select(selectPurchaseOrderDeliveryInfoBlockValid)
      .pipe(takeUntil(this.destroy$))
      .subscribe((deliveryInfoBlockValid: boolean) => {
        this.deliveryInfoBlockValid = deliveryInfoBlockValid;
        this.updateViewDocumentItems();
      });
  }

  private trackPositionsBlockValid(): void {
    this.store.select(selectPurchaseOrderPositionsBlockValid)
      .pipe(takeUntil(this.destroy$))
      .subscribe((positionsBlockValid: boolean) => {
        this.positionsBlockValid = positionsBlockValid;
        this.updateViewDocumentItems();
      });
  }

  private updateViewDocumentItems(): void {
    this.viewDocumentItems = getViewDocumentList(
      this.positionsCount,
      this.currentPurchaseOrder,
      this.deliveryInfoBlockValid,
      this.showDocumentBarWithStateOrLinkDoc,
      this.positionsBlockValid,
      this.isNarrowScreen,
      this.currentState,
      this.isTemplate
    );

    this.createDocumentSteps = getCreateDocumentSteps(
      this.currentPurchaseOrder,
      this.deliveryInfoBlockValid,
      this.positionsBlockValid,
    );

    this.todoDocumentList = getDocumentToDoList(
      this.currentPurchaseOrder.properties.vatStatus === VatStatusEnum.PASSED,
      this.currentPurchaseOrder,
      this.showETA && !this.currentPurchaseOrder.properties.positionsConfirmedAt,
      this.hideProductReceiptAction,
      this.hideProductWarehousingAction,
      !this.currentPurchaseOrder.properties.allowedIinCreation
      || (this.currentState === UIStatesEnum.EDIT || this.flagEditing)
      || this.disabledInvoice,
      this.prepareLinkedDocumentsList(DocumentTypesUppercaseEnum.SALES_ORDER),
      this.getBatchesAsLinkedDocuments(),
      this.prepareLinkedDocumentsList(DocumentTypesUppercaseEnum.INCOMING_INVOICE),
      this.positionsTotals
    );
  }

  public refreshPurchaseOrderById(ignoreEditState = true) {
    if (ignoreEditState || (this.currentState !== UIStatesEnum.EDIT)) {
      this.poApi.getPO(this.currentPurchaseOrder.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(); // PO will be updated via store
    }
  }

  public close(): void {
    switch (this.currentState) {
      case UIStatesEnum.EDIT:
        this.goToList(true);
        break;
      case UIStatesEnum.VIEW:
        this.goToList(false);
        break;
      case UIStatesEnum.CREATE:
        if (this.isTemplate) {
          this.goToList(false);
          return
        }
        this.closeOrCreateConfirmation();
        break;
    }
  }

  private goToList(finishEditing = true): void {
    if (finishEditing) {
      this.disableEdit(finishEditing);
      return;
    }
    this.closeDocument();
  }

  private closeOrCreateConfirmation(): void {
    const dialog = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: 'PO.MODAL.CREATE_PO',
        message: 'PO.MODAL.CREATE_PO_MESSAGE',
        isRejectButton: true,
        rejectBtnText: 'BUTTON.DELETE',
        confirmBtnText: 'BUTTON.CREATE',
        confirmBtnIcon: 'plus-square',
        titleIcon: 'question-mark-circle',
        titleColor: 'main-100',
      }
    });

    dialog.afterClosed().subscribe((res: CommonModalsActionsEnum) => {
      if (res === CommonModalsActionsEnum.CONFIRM) {
        this.onCreateClick();
      } else if (res === CommonModalsActionsEnum.REJECT) {
        this.deletePermanently();
      }
    });
  }

  onExportPdfClick(): void {
    this.downloadPurchaseOrderPdf(this.currentPurchaseOrder.id);
  }

  public downloadPurchaseOrderPdf(id: number) {
    this.poApi.getPurchaseOrderExportParams(id, this.currentPurchaseOrder.runpleId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((fileParams: FileUploadParams) => {
        const {url, title, type} = fileParams;
        this.downloadFile(url, title, type);
      }, error => {
        this.showMsg('error', error.error.message || error.error.errors);
      });
  }

  downloadFile(url, title, type) {
    this.fileService.downloadFile(url)
      .subscribe((res: any) => {
        this.fileSaverService.save(res.body, `${res.filename || title}.${type}`);
      });
  }

  onEditPOClick(): void {
    if (
      this.isCompleted
      || this.isCanceled
      || this.isDeleted
      || this.currentState !== UIStatesEnum.VIEW
    ) {
      return;
    }

    this.poApi.purchaseOrderSetEdit(this.currentPurchaseOrder.id)
      .pipe(
        finalize(() => this.btnToClearLoadingStatus$.next('onEditPOClick')),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  onFinishEditingClick(): void {
    this.poApi.purchaseOrderUnsetEdit(this.currentPurchaseOrder.id)
      .pipe(
        finalize(() => this.btnToClearLoadingStatus$.next('onFinishEditingClick')),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private disableEdit(isCloseDocument: boolean = false): void {
    this.poApi.purchaseOrderUnsetEdit(this.currentPurchaseOrder.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (isCloseDocument) {
          this.closeDocument();
        }
        this.refreshPurchaseOrderById();
      }, error => {
        this.handlePopupErrors(error);
      });
  }

  onProceedClick(): void {
    this.poChangeStatus(PoTabs.Open, 'onProceedClick', false);
  }

  onClonePOClick(): void {
    this.poApi.clonePO(this.currentPurchaseOrder.id)
      .pipe(
        finalize(() => this.btnToClearLoadingStatus$.next('onClonePOClick')),
        takeUntil(this.destroy$)
      )
      .subscribe((purchaseOrder: PurchaseOrder) => {
        this.router.navigate(['/trade/purchase-order', purchaseOrder.id]);
      });
  }

  onDeleteClick(): void {
    const data = this.isTemplate
      ? DeleteTemplateModalData
      : {
        title: 'MODAL.DELETE',
        message: 'PO.MODAL.DELETE_PO_MESSAGE',
        confirmBtnText: 'BUTTON.DELETE',
        confirmBtnIcon: 'trash-2'
      };
    const dialog = this.dialog.open(DangerModalComponent, { data });

    dialog.afterClosed()
      .pipe(
        finalize(() => this.btnToClearLoadingStatus$.next('onDeleteClick')),
        takeUntil(this.destroy$)
      )
      .subscribe((response: CommonModalsActionsEnum) => {
        if (response === CommonModalsActionsEnum.CONFIRM) {
          if (this.isTemplate) {
            this.deletePermanently();
            return;
          }
          this.poChangeStatus(PoTabs.Deleted,null, true);
        }
      });
  }

  onCancelPOClick(): void {
    this.poChangeStatus(PoTabs.Canceled, 'onCancelPOClick', true);
  }

  onRestoreClick(): void {
    this.poChangeStatus(PoTabs.Draft, 'onRestoreClick', false);
  }


  public closeDocument(status: PoTabs = PoTabs.Draft): void {
    if (this.previousLink) {
      this.router.navigateByUrl(this.previousLink);
    } else {
      this.router.navigate(['/trade/purchase-order/list', status, 1]);
    }
  }

  public get previousLink(): string {
    return this.previousLink$.getValue();
  }


  onDeletePermClick() {
    const dialog = this.dialog.open(DangerModalComponent, {
      data: {
        title: 'MODAL.DELETE_PERMANENTLY',
        message: 'PO.MODAL.DELETE_PERMANENTLY_MESSAGE',
        confirmBtnText: 'BUTTON.DELETE',
        confirmBtnIcon: 'trash-2'
      }
    });

    dialog.afterClosed()
      .pipe(
        finalize(() => this.btnToClearLoadingStatus$.next('onDeletePermClick')),
        takeUntil(this.destroy$)
      )
      .subscribe((response: CommonModalsActionsEnum) => {
        if (response === CommonModalsActionsEnum.CONFIRM) {
          this.deletePermanently();
        }
      });
  }

  onCreateClick(): void { // Accept draft creation
    this.poApi.createAcceptPurchaseOrder(this.currentPurchaseOrder.id)
      .pipe(
        finalize(() => this.btnToClearLoadingStatus$.next('createPoButton')),
        takeUntil(this.destroy$)
      )
      .subscribe((response: PurchaseOrder) => {
        this.showMsg('success', 'SUCCESS_CREATED.PO');
        this.router.navigate([`/trade/purchase-order/list/${response.status}/1`]);
      });
  }

  actionsEvent(e: string, disabled = false): void {
    if (!disabled) {
      this[e]();
    }
  }

  private showMsg(type: string, message: string, status?: string): void {
    if (!status || status !== PoTabs.Deleted) {
      this.toasterService.notify({type, message});
    }
  }

  private poChangeStatus(
    status: PoTabs,
    btnName?: string,
    isCloseDocument: boolean = false
  ): void {
    this.poApi.changePurchaseOrdersStatus(status, this.currentPurchaseOrder.id)
      .pipe(
        finalize(() => {
          if (btnName) { this.btnToClearLoadingStatus$.next(btnName); }
          if (status === PoTabs.Open) {
            this.sendCreateRequest$.next(true);
          }
        }),
        catchError((error: HttpErrorResponse) => {
          this.handlePopupErrors(error.error.message);
          return throwError(error);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.store.dispatch(UpdatePOCurrentState({currentState: this.getNextUIState(status)}));

        if (isCloseDocument) {
          this.closeDocument(status);
        }
      });
  }

  private handlePopupErrors(error: string): void {
    switch (error) {
      case 'poForUnitsAlreadyCreated':
        this.dialog.open(InfoModalComponent, {
          data: CannotProceedErrModalData
        });
        break;
      default:
        this.showMsg('error', error);
        break
    }
  }

  public getNextUIState(status: PoTabs): UIStatesEnum {
    if (status === PoTabs.Completed || status === PoTabs.Open) {
      return UIStatesEnum.VIEW;
    } else {
      return status === PoTabs.Draft ? UIStatesEnum.EDIT : UIStatesEnum.VIEW;
    }
  }

  private deletePermanently(): void {
    this.poApi.deletePurchaseOrderPermanently([this.currentPurchaseOrder.id])
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.closeDocument(this.currentPurchaseOrder.status as PoTabs);
      }, (error => {
        this.toasterService.notifyError(error.error.message)
      }));
  }

  private getCountries(): void {
    this.commonService.getCountries()
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.store.dispatch(LoadCountries({countries: response.data}));
      });
  }

  private getPartners(): void {
    this.crmService.getPartners()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: ApiResponse<CorporatePartnerModel[]>) => {
        const partners = response.data
          .map(partner => ({
            ...partner,
            id: +(partner.id) as any
          }));
        this.store.dispatch(LoadPOPartners({ partners }));
      });
  }

  public actionsEventWithValue(e: { actionName: string, returnValue: any }): void {
    if (this[e.actionName]) {
      this[e.actionName](e.returnValue);
    }
  }

  public onActionReceived(btnAction: string): void {
    if (this[btnAction]) {
      this[btnAction]();
    }
  }

  public unlinkSalesOrder(documentId): void {
    if (!documentId) { return; }
    const dialog = this.dialog.open(WarningModalComponent, {
      data: {
        title: 'SO_PO.PO_UNLINK_TITLE',
        message: 'SO_PO.PO_UNLINK_MSG',
        confirmBtnText: 'BUTTON.UNLINK',
        confirmBtnIcon: 'unlink'
      }
    });

    dialog.afterClosed().subscribe((res: CommonModalsActionsEnum) => {
      if (res === CommonModalsActionsEnum.CONFIRM) {
        this.poApi.unlinkPurchaseOrderWithSalesOrder(this.currentPurchaseOrder.id, documentId)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.showMsg('success', 'PO.HAS_BEEN_UNLINKED');
          });
      }
    });
  }

  // public getLinkedDocuments(linkedDocuments): Array<DocumentStepModel> {
  //
  //   const salesOrder: DocumentStepModel = {
  //     title: {
  //       type: TitleTypeEnum.primary,
  //       label: 'APP.SALES_ORDERS',
  //     },
  //     descr: this.salesOrderDocumentsStatus() === StepStatusEnum.In_progress ? 'STATUS.PROCESSING' : 'STATUS.COMPLETED',
  //     status: this.salesOrderDocumentsStatus(),
  //     icon: 'sales-order',
  //     documents: []
  //   };
  //
  //   const billing: DocumentStepModel = {
  //     title: {
  //       type: !!get(this.currentPurchaseOrder, 'properties.billingDate') ? TitleTypeEnum.date : TitleTypeEnum.primary,
  //       label: get(this.currentPurchaseOrder, 'properties.billingDate') || 'DOC_BLOCK.BILLING'
  //     },
  //     descr: get(this.currentPurchaseOrder, 'billing', 'pending'),
  //     icon: 'incoming-invoice',
  //     status: this.billingStatus,
  //     buttonIcon: 'incoming-invoice',
  //     buttonTitle: 'BUTTON.CREATE_INVOICE',
  //     disabled: this.disabledInvoice,
  //     action: (!this.currentPurchaseOrder.properties.allowedIinCreation || (this.currentState === UIStatesEnum.EDIT || this.flagEditing))
  //       ? false
  //       : 'linkIncomingInvoice',
  //     // hideAction: this.poFullyPaid,
  //     hintId: 'billingStep',
  //     documents: []
  //   };
  //
  //   const delivery: DocumentStepModel = {
  //     title: {
  //       type: TitleTypeEnum.primary,
  //       label: 'DOC_SUMMARY.DELIVERY'
  //     },
  //     descr: get(this.currentPurchaseOrder, 'delivery'),
  //     icon: 'box',
  //     status: this.deliveryStatus,
  //     hideAction: this.hideDeliveryAction,
  //     hintId: 'deliveryStep',
  //     // buttonIcon: 'goods-receipt',
  //     // buttonTitle: 'Product receipt',
  //     // action: this.isOpenStatus && !this.flagEditing &&  this.currentState !== UIStatesEnum.EDIT ? 'confirmReceipt' : false,
  //     action: this.canTrackDelivery() ? 'trackShipment' : false,
  //     buttonIcon: 'car',
  //     buttonTitle: 'WAREHOUSE.DELIVERY_NOTE.TRACK_DELIVERY',
  //     class: 'white-grey reorder',
  //     // disabled: (this.currentState !== UIStatesEnum.VIEW) || this.flagEditing || (this.currentPurchaseOrder.status === PoTabs.Draft),
  //     actionsMultiple: {
  //       classes: 'btn-dark w-full',
  //       text: !this.hideProductReceiptAction ? 'BUTTON.PRODUCTS_RECEIPT' : 'BUTTON.PRODUCT_RETURN',
  //       iconName: !this.hideProductReceiptAction ? 'goods-receipt' : 'undo',
  //       disabled: false,
  //       multiple: false,
  //       dropdownClass: 'inner-blue-400',
  //       allowLoadingSpinner: true,
  //       actionName: !this.hideProductReceiptAction ? 'productReceipt' : 'productReturn',
  //       // disabledMainAction: true,
  //       actions: [
  //         {
  //           actionTitle: 'BUTTON.PRODUCTS_RECEIPT',
  //           actionName: 'productReceipt',
  //           actionIcon: 'goods-receipt',
  //           hideAction: this.hideProductReceiptAction,
  //         },
  //         {
  //           actionTitle: 'BUTTON.PRODUCT_RETURN',
  //           actionName: 'productReturn',
  //           actionIcon: 'undo',
  //           hideAction: this.hideProductReturnAction,
  //         },
  //         {
  //           actionTitle: 'BUTTON.PRODUCT_WAREHOUSED',
  //           actionName: 'productWarehousing',
  //           actionIcon: 'warehousing',
  //           hideAction: this.hideProductWarehousingAction,
  //         },
  //         {
  //           actionTitle: 'INCOMING_DELIVERY.CANCEL_WAREHOUSING',
  //           actionName: 'productCancelWarehousing',
  //           actionIcon: 'undo',
  //           hideAction: this.hideProductCancelWarehousingAction,
  //         },
  //       ],
  //     },
  //     documents: []
  //   };
  //
  //   const current_document: DocumentStepModel = {
  //     title: {
  //       type: TitleTypeEnum.date,
  //       label: get(this.currentPurchaseOrder ,'properties.openedAt')
  //     },
  //     descr: 'DOC_SUMMARY.PO_CREATED',
  //     status: StepStatusEnum.Success,
  //     currentDocument: true,
  //     icon: 'purchase-order',
  //     hintId: 'currentStep',
  //     action: false,
  //     documents: []
  //   };
  //
  //   const canceled_document: DocumentStepModel = {
  //     title: {
  //       type: TitleTypeEnum.date,
  //       label: get(this.currentPurchaseOrder ,'properties.canceledAt')
  //     },
  //     descr: 'DOC_SUMMARY.PO_CANCELED',
  //     status: StepStatusEnum.Canceled,
  //     currentDocument: true,
  //     icon: 'close-square',
  //     action: false,
  //     documents: []
  //   };
  //
  //   const deleted_document: DocumentStepModel = {
  //     title: {
  //       type: TitleTypeEnum.date,
  //       label: get(this.currentPurchaseOrder ,'properties.deletedAt')
  //     },
  //     descr: 'DOC_SUMMARY.PO_DELETED',
  //     status: StepStatusEnum.Deleted,
  //     currentDocument: true,
  //     icon: 'trash-2',
  //     action: false,
  //     documents: []
  //   };
  //
  //   const end_step: DocumentStepModel = {
  //     documentState: true,
  //     endStep: true,
  //     icon: 'checkbox-on',
  //     title: {
  //       type: this.currentPurchaseOrder.status === PoTabs.Completed ? TitleTypeEnum.date : TitleTypeEnum.primary,
  //       label: this.currentPurchaseOrder.status === PoTabs.Completed ? get(this.currentPurchaseOrder ,'properties.completedAt') : 'DOC_SUMMARY.PO_COMPLETED'
  //     },
  //     descr: this.currentPurchaseOrder.status === PoTabs.Completed ? 'DOC_SUMMARY.PO_COMPLETED' : 'DOC_SUMMARY.WAITING_FOR_DELIVERY_AND_BILLING',
  //     status: this.currentPurchaseOrder.status === PoTabs.Completed ? StepStatusEnum.Success : null,
  //   };
  //
  //   if (this.currentPurchaseOrder.properties.batches.length) {
  //     this.currentPurchaseOrder.properties.batches.forEach(batch => {
  //       delivery.documents.push({
  //         label: batch.runpleId,
  //         type: DocumentTypeEnum.Primary,
  //         icon: this.batchesIcons[batch.status],
  //         iconColor: this.iconColor[batch.status],
  //       })
  //     });
  //   }
  //
  //   if (this.currentPurchaseOrder.properties.positionsConfirmedAt) {
  //     delivery.title = {
  //       label: this.currentPurchaseOrder.properties.positionsConfirmedAt,
  //       type: TitleTypeEnum.date
  //     };
  //   }
  //
  //   if (this.showETA && !this.currentPurchaseOrder.properties.positionsConfirmedAt) {
  //     delivery.documents.unshift({
  //       type: DocumentTypeEnum.StatusLine,
  //       statusLineType: 'date',
  //       label: 'DOC_SUMMARY.DELIVERY_DATE',
  //       icon: 'calendar',
  //       value: this.currentPurchaseOrder.createdAt,
  //       expectedDate: this.currentPurchaseOrder.deliveryInformation.estimatedDeliveryDate,
  //       statusLineWithLabel: true,
  //     });
  //   }
  //
  //   if (!get(this.currentPurchaseOrder,'properties.company.noVat')) {
  //     current_document.documents.push({
  //       type: DocumentTypeEnum.VatNumberCheck,
  //       vatStatus: this.currentPurchaseOrder.properties.vatStatus
  //     })
  //   }
  //
  //   linkedDocuments.map((doc: any) => {
  //     doc = {
  //       ...doc,
  //       routerLink: `/accounting/incoming-invoice/${doc.documentId}`,
  //     };
  //
  //     switch (doc.type) {
  //       case 'incoming_invoice':
  //         if (this.getDocumentWithStatus(doc.status)) {
  //           billing.documents.push({
  //             ...doc,
  //             icon: this.documentsIconName[doc.status],
  //             iconColor: this.iconColor[doc.status],
  //             type: DocumentTypeEnum.linkDocument
  //           });
  //         }
  //         break;
  //       case 'sales_order':
  //         if (
  //           doc.status === SalesOrderListTabsEnum.DRAFT ||
  //           doc.status === SalesOrderListTabsEnum.OPEN ||
  //           doc.status === SalesOrderListTabsEnum.COMPLETED
  //         ) {
  //           const properties = {
  //             ...doc,
  //             icon: this.documentsIconName[doc.status],
  //             iconColor: this.iconColor[doc.status],
  //             routerLink: getDocumentUrl(doc.type, doc.documentId),
  //             type: DocumentTypeEnum.linkDocument,
  //             action: 'unlinkSalesOrder',
  //             id: doc.documentId,
  //             actionIcon: 'unlink',
  //             actionClass: 'yellow',
  //           };
  //           salesOrder.documents.push(properties);
  //         }
  //         break;
  //     }
  //   });
  //
  //   if (this.isCanceled && this.purchaseOrderFromOpenStatus) {
  //     return [
  //       current_document,
  //       canceled_document
  //     ];
  //   }
  //
  //   if (this.isDeleted && this.purchaseOrderFromOpenStatus) {
  //     return [
  //       current_document,
  //       canceled_document,
  //       deleted_document
  //     ];
  //   }
  //
  //   if (
  //     this.currentPurchaseOrder.linkedDocuments
  //       .some(doc => (
  //         (doc.type === 'sales_order') && (
  //           doc.status === SalesOrderListTabsEnum.DRAFT ||
  //           doc.status === SalesOrderListTabsEnum.OPEN ||
  //           doc.status === SalesOrderListTabsEnum.COMPLETED
  //         )
  //       ))
  //   ) {
  //     return [
  //       current_document,
  //       delivery,
  //       billing,
  //       end_step,
  //       salesOrder
  //     ];
  //   }
  //
  //   return [
  //     current_document,
  //     delivery,
  //     billing,
  //     end_step
  //   ];
  // }

  public trackShipment(): void {
    const trackingCode = this.currentPurchaseOrder.deliveryInformation.trackingCode;
    if (!this.currentPurchaseOrder.deliveryInformation.deliveryService.name || !trackingCode) { return; }

    TrackShipmentHelper(this.currentPurchaseOrder.deliveryInformation.deliveryService.name, trackingCode);
  }

  linkIncomingInvoice(): void {
    if (this.linkIncomingInvoiceRequest$.getValue()) { return; }
    this.linkIncomingInvoiceRequest$.next(true);

    this.invoiceApiService.createInvoiceFromPO(this.currentPurchaseOrder.id)
      .pipe(
        finalize(() => this.linkIncomingInvoiceRequest$.next(false)),
        takeUntil(this.destroy$),
      )
      .subscribe((incomingInvoice) => {
        this.showMsg('success', 'IIN.CREATED_SUCCESS');
        this.router.navigate([`/accounting/incoming-invoice/${incomingInvoice.invoiceId}`], {state: {uiState: UIStatesEnum.CREATE}});
      }, (error: HttpErrorResponse) => {
        this.showMsg('error', error.error.message || error.error.errors);
      })
  }

  // public salesOrderDocumentsStatus(): StepStatusEnum {
  //   if (!this.currentPurchaseOrder.linkedDocuments.length) { return; }
  //
  //   const linkedPurchaseOrders = this.currentPurchaseOrder.linkedDocuments.filter(doc => doc.type === 'sales_order');
  //
  //   if (!linkedPurchaseOrders.length) { return; }
  //
  //   return linkedPurchaseOrders.some(doc => doc.status === SalesOrderListTabsEnum.DRAFT || doc.status === SalesOrderListTabsEnum.OPEN)
  //     ? StepStatusEnum.In_progress
  //     : StepStatusEnum.Success;
  // }

  public productReceipt(): void {
    if (this.confirmReceiptRequest$.getValue()) { return; }
    this.confirmReceiptRequest$.next(true);

    const dialog = this.dialog.open(IncomingDeliveryProductsReceiptModalComponent, {
      data: {
        poId: this.currentPurchaseOrder.id,
        fromPo: true,
      },
      disableClose: true,
      panelClass: 'mat-dialog-container--w-full',
      maxWidth: '1500px'
    });

    dialog.afterClosed()
      .pipe(
        finalize(() => this.confirmReceiptRequest$.next(false)),
        takeUntil(this.destroy$),
      )
      .subscribe(() => this.refreshPurchaseOrderById());
  }

  public productReturn(): void {
    const dialog = this.dialog.open(IncomingDeliveryProductsReturnModalComponent, {
      data: {
        poId: this.currentPurchaseOrder.id,
        fromPo: true
      },
      disableClose: true,
      panelClass: 'mat-dialog-container--w-full',
      maxWidth: '1360px'
    });

    dialog.afterClosed().subscribe(() => this.refreshPurchaseOrderById());
  }

  public productWarehousing(): void {
    const dialog = this.dialog.open(IncomingDeliveryWarehousingModalComponent, {
      data: {
        location: StockAreaEnum.GENERAL_STOCK,
        documentId: this.currentPurchaseOrder.id,
        fromPo: true,
      },
      disableClose: true,
      panelClass: 'mat-dialog-container--w-full',
      maxWidth: '1360px'
    });

    dialog.afterClosed().subscribe(() => this.refreshPurchaseOrderById());
  }

  public productCancelWarehousing(): void {
    if (!this.userPermissionsService.canEditWholesale()) {
      this.toasterService.notify({
        type: 'error',
        message: 'NO_ACCESS.WAREHOUSE'
      });
      return;
    }
    const dialog = this.dialog.open(IncomingDeliveryCancelWarehousingModalComponent, {
      data: {
        location: StockAreaEnum.GENERAL_STOCK,
        documentId: this.currentPurchaseOrder.id,
        fromPo: true,
      },
      disableClose: true,
      panelClass: 'mat-dialog-container--w-full',
      maxWidth: '1360px'
    });

    dialog.afterClosed().subscribe(() => this.refreshPurchaseOrderById());
  }

  public onCreateSalesOrderClick(): void {
    const dialog = this.dialog.open(CreateSoModalComponent, {
      data: {
        poId: this.currentPurchaseOrder.id,
      },
      panelClass: 'mat-dialog-container--w-full',
      maxWidth: '1360px'
    });

    dialog.afterClosed().subscribe((salesOrder: SalesOrderModel) => {
      this.btnToClearLoadingStatus$.next('onCreateSalesOrderClick');
      if (salesOrder) {
        this.router.navigate(['/trade/sales-order', salesOrder.id]);
      }
    });
  }

  private getDocumentWithStatus(status: string): boolean {
    return status.toLowerCase() !== 'deleted' && status.toLowerCase() !== 'canceled';
  }

  public trackByTemplate(index: number, item: ViewDocumentListModel) {
    return item.template;
  }

  public toggleViewer(): void {
    this.hideViewer = !this.hideViewer;
  }

  // public changeTypeView(type: TypeViewEnum): void {
  //   this.typeView = type;
  // }

  public saveAsTemplate(): void {
    this.dialog.open(DocumentTemplateSaveModalComponent, {
      data: {
        documentType: DocumentTypesUppercaseEnum.PO,
        documentId: this.currentPurchaseOrder.id
      },
      disableClose: true
    });
  }

  public useTemplate(): void {
    const currentDocumentID: number = this.currentPurchaseOrder.id;
    this.dialog.open(DocumentTemplatesModalComponent, {
      data: {
        documentType: DocumentTypesUppercaseEnum.PO,
        documentId: this.currentPurchaseOrder.id
      },
      disableClose: true,
      minWidth: '560px',
      maxWidth: '560px',
      backdropClass: 'dragged-modal-type',
      panelClass: ['dragged-panel', 'sm-modal'],
      position: {
        top: MODAL_CONFIG.position.top,
        right: `16px`,
      }
    }).afterClosed()
      .subscribe((documentId: number) => {
        const id: number = !documentId ? currentDocumentID : documentId;
        this.templatePreviewApplied = false;
        this.poApi.getPO(id)
          .pipe(takeUntil(this.destroy$))
          .subscribe();
      });
  }

  public createDocumentFromTemplate(): void {
    this.poApi.createDocumentFromTemplate(this.currentPurchaseOrder.id)
      .pipe(
        finalize(() => this.btnToClearLoadingStatus$.next('createDocumentFromTemplate')),
        takeUntil(this.destroy$)
      )
      .subscribe((documentId: number) => {
        this.router.navigate([`/trade/purchase-order/${documentId}`]);
      });
  }

  public autocorrectPositions(): void {
    this.dialog.open(AutocorrectionModalComponent, {
      data: {
        documentId: this.currentPurchaseOrder.id,
        documentAmountNet: this.currentPurchaseOrder.summary.totalAmount.net,
        documentAmountGross: this.currentPurchaseOrder.summary.totalAmount.gross,
        documentVat: this.currentPurchaseOrder.summary.totalAmount.vat,
        documentType: DocumentTypesUppercaseEnum.PO,
      },
      disableClose: true
    }).afterClosed().subscribe((res: CommonModalsActionsEnum) => {
      if (res === CommonModalsActionsEnum.CONFIRM) {
        this.refreshPurchaseOrderById();
      }
    });
  }

  private prepareLinkedDocumentsList(documentType: DocumentTypesUppercaseEnum): LinkedDocumentsModel[] {
    let linkedDocuments: LinkedDocumentsModel[] = [] ;

    if (this.currentPurchaseOrder.linkedDocuments && this.currentPurchaseOrder.linkedDocuments.length) {
      this.currentPurchaseOrder.linkedDocuments.map(doc => {
        if (doc.status && this.getDocumentWithStatus(doc.status) && doc.type === documentType) {
          linkedDocuments.push({
            title: doc.documentRunpleId,
            routerLink: getDocumentUrl(doc.type, doc.documentId),
            icon: ICON_BY_DOCUMENT_TYPE[documentType],
            iconColor: this.iconColor[doc.status],
            status: doc.status,
            action: documentType === 'sales_order' && !this.isCompleted ? 'unlinkSalesOrder' : null,
            id: doc.documentId,
            actionIcon: 'unlink',
            actionClass: 'yellow',
          });
        }
      });

      if (this.isCompleted && documentType === DocumentTypesUppercaseEnum.INCOMING_INVOICE) {
        linkedDocuments = linkedDocuments.filter((item: LinkedDocumentsModel) => item.status.toLowerCase() === 'paid');
      }
    }
    return linkedDocuments;
  }

  private getBatchesAsLinkedDocuments(): LinkedDocumentsModel[] {
    const linkedDocuments: LinkedDocumentsModel[] = [] ;

      if (!!this.currentPurchaseOrder.properties.batches && !!this.currentPurchaseOrder.properties.batches.length) {
        this.currentPurchaseOrder.properties.batches.map(batch => {
          linkedDocuments.push({
            title: batch.runpleId,
            icon: this.batchesIcons[batch.status],
            iconColor: this.iconColor[batch.status],
            status: batch.status,
            actionList: this.currentState !== UIStatesEnum.VIEW || this.isCompleted
              ? []
              : [
                  {
                    actionName: 'productWarehousing',
                    actionTitle: 'BUTTON.PRODUCT_WAREHOUSED',
                    actionIcon: 'warehousing',
                    hideAction: batch.status === IncomingDeliveryListTabsEnum.WAREHOUSED,
                  },
                  {
                    actionName: 'productReturn',
                    actionTitle: 'BUTTON.PRODUCT_RETURN',
                    actionIcon: 'undo',
                    hideAction: batch.status === IncomingDeliveryListTabsEnum.WAREHOUSED,
                  },
                  {
                    actionName: 'productCancelWarehousing',
                    actionTitle: 'INCOMING_DELIVERY.CANCEL_WAREHOUSING',
                    actionIcon: 'undo',
                    hideAction: batch.status !== IncomingDeliveryListTabsEnum.WAREHOUSED,
                  },
                ]
          });
        });
      }
      return linkedDocuments;
  }

  // get invoiceIsOpenOrDraftOrPendingStatus (): boolean {
  //   const documents = get(this.currentPurchaseOrder, 'linkedDocuments');
  //   return documents.some(doc => (
  //     doc.type === 'incoming_invoice' &&
  //     (
  //       doc.status.toLowerCase() === 'open' ||
  //       doc.status.toLowerCase() === 'draft' ||
  //       doc.status.toLowerCase() === 'pending'
  //     )
  //   ));
  // }
  //
  // get poFullyPaid(): boolean {
  //   const invoicesAmount = this.currentPurchaseOrder.linkedDocuments
  //     .filter(doc => doc.type === 'incoming_invoice')
  //     .filter(doc => {
  //       const status: string = doc.status.toLowerCase()
  //       return status === IncomingInvoiceListTabsEnum.OPEN.toLowerCase() || status === IncomingInvoiceListTabsEnum.PAID.toLowerCase();
  //     })
  //     .reduce((acc: number, doc) => {
  //       return doc.amount ? acc + doc.amount : acc;
  //     }, 0);
  //
  //   return this.currentPurchaseOrder.summary.totalAmount.gross === invoicesAmount;
  // }

  // get isStatusOpen(): boolean {
  //   return (this.currentPurchaseOrder.status === PoTabs.Open);
  // }

  get showDocumentBar(): boolean {
    return this.showDocumentBarByStatus && this.showDocumentBarWithStateOrLinkDoc;
  }

  get showDocumentBarByStatus(): boolean {
    return this.currentPurchaseOrder.status === PoTabs.Open ||
      this.currentPurchaseOrder.status === PoTabs.Completed ||
      (this.isCanceled && this.purchaseOrderFromOpenStatus) ||
      (this.isDeleted && this.purchaseOrderFromOpenStatus);
  }

  get purchaseOrderFromOpenStatus(): boolean {
    return !!get(this.currentPurchaseOrder, 'properties.openedAt');
  }

  get isCanceled(): boolean {
    return get(this.currentPurchaseOrder, 'status') === PoTabs.Canceled;
  }

  get isCompleted(): boolean {
    return get(this.currentPurchaseOrder, 'status') === PoTabs.Completed;
  }

  get isDeleted(): boolean {
    return get(this.currentPurchaseOrder, 'status') === PoTabs.Deleted;
  }

  get showDocumentBarWithStateOrLinkDoc(): boolean {
    return this.currentState !== this.UIStates.CREATE || !!get(this.currentPurchaseOrder, 'linkedDocuments').length
  }

  get disabledInvoice(): boolean {
    return this.currentPurchaseOrder.status === PoTabs.Draft ||
      this.currentState === UIStatesEnum.CREATE;
  }

  get flagEditing(): boolean {
    if (!!get(this.currentPurchaseOrder, 'flags.length')) {
      return this.currentPurchaseOrder.flags.some(flag => flag === 'editing');
    }
    return false;
  }

  // get invoiceIsOpenOrDraftOrPaidOrPendingStatus (): boolean {
  //   const documents = get(this.currentPurchaseOrder, 'linkedDocuments');
  //   return documents.some(doc => (
  //     (doc.type === 'outgoing_invoice') &&
  //     (doc.status.toLowerCase() === 'open' ||
  //       doc.status.toLowerCase() === 'draft' ||
  //       doc.status.toLowerCase() === 'paid' ||
  //       doc.status.toLowerCase() === 'pending')
  //   ));
  // }

  // get deliveryStatus(): StepStatusEnum {
  //   if (!this.currentPurchaseOrder.delivery) { return StepStatusEnum.Pending }
  //
  //   switch (this.currentPurchaseOrder.delivery) {
  //     case 'delivered' :
  //     case 'warehoused' :
  //       return StepStatusEnum.Success;
  //     case 'pending':
  //       return StepStatusEnum.Pending;
  //     default:
  //       return StepStatusEnum.In_progress;
  //   }
  // }

  // get billingStatus(): StepStatusEnum {
  //   if (!this.currentPurchaseOrder.billing) { return StepStatusEnum.Pending }
  //
  //   switch (this.currentPurchaseOrder.billing) {
  //     case  'paid' :
  //       return StepStatusEnum.Success;
  //     case 'pending':
  //       return StepStatusEnum.Pending;
  //     default:
  //       return StepStatusEnum.In_progress;
  //   }
  // }

  get showETA(): boolean {
    return this.currentPurchaseOrder.deliveryInformation.estimatedDeliveryDate &&
      this.currentPurchaseOrder.deliveryInformation.deliveryType === DeliveryTypesEnum.DELIVERY &&
      (this.currentPurchaseOrder.status === PoTabs.Draft || this.currentPurchaseOrder.status === PoTabs.Open);
  }

  // get isOpenStatus(): boolean {
  //   return this.currentPurchaseOrder.status === PoTabs.Open;
  // }
  get isDraft(): boolean {
    return this.currentPurchaseOrder.status === PoTabs.Draft;
  }
  get isTemplate(): boolean {
    return this.currentPurchaseOrder.status === PoTabs.Template;
  }

  get hideProductReceiptAction(): boolean {
    return !get(this.currentPurchaseOrder, 'properties.allowedProductReceipt') || this.currentState === UIStatesEnum.EDIT;
  }

  // get hideProductReturnAction(): boolean {
  //   return !get(this.currentPurchaseOrder, 'properties.allowedProductReturn') || this.currentState === UIStatesEnum.EDIT;
  // }

  get hideProductWarehousingAction(): boolean {
    return !get(this.currentPurchaseOrder, 'properties.allowedProductWarehousing');
  }

  // get hideProductCancelWarehousingAction(): boolean {
  //   return !get(this.currentPurchaseOrder, 'properties.allowedCancelWarehousing');
  // }

  // get hideDeliveryAction(): boolean {
  //   return this.currentState === UIStatesEnum.EDIT ||
  //     this.flagEditing ||
  //     (
  //       this.hideProductReceiptAction &&
  //       this.hideProductReturnAction &&
  //       this.hideProductWarehousingAction &&
  //       this.hideProductCancelWarehousingAction &&
  //       !this.canTrackDelivery()
  //     );
  // }

  get isGeneralPartner(): boolean {
    return get(this.currentPurchaseOrder, 'properties.company.type') === CustomerTypeEnum.GENERAL;
  }

  // public canTrackDelivery(): boolean {
  //   const allowedDeliveryServices: DeliveryServiceEnum[] = [
  //     DeliveryServiceEnum.AUSTRIAN_POST,
  //     DeliveryServiceEnum.DHL,
  //     DeliveryServiceEnum.UPS,
  //     DeliveryServiceEnum.DPD,
  //   ];
  //   return this.currentPurchaseOrder.status === PoTabs.Open
  //     && this.currentPurchaseOrder.deliveryInformation.trackingCode
  //     && allowedDeliveryServices.includes(this.currentPurchaseOrder.deliveryInformation.deliveryService.name);
  // }

  public createNewPosition(): void {
    const dialog = this.dialog.open(PoPositionModalComponent, {
      data: {
        purchaseOrderId: this.currentPurchaseOrder.id,
        purchaseOrderStatus: this.currentPurchaseOrder.status,
        vatBlocked: this.currentPurchaseOrder.properties.vatDisabled
      },
      disableClose: true,
    });

    dialog.afterClosed().subscribe(res => {
      if (res) {
        this.poApi.getPO(this.currentPurchaseOrder.id)
          .pipe(takeUntil(this.destroy$))
          .subscribe();
      }
    });
  }

  public updatePoVatStatus(): void {
    if (this.checkVatRequest$.getValue()) { return; }
    this.checkVatRequest$.next(true);

    this.poApi.updatePO(this.currentPurchaseOrder.id, {fieldValue: '', fieldName: 'properties.vatStatus'})
      .pipe(
        finalize(() => this.checkVatRequest$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe(); // updated via store
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
    this.store.dispatch(ClearPOPositions());
  }
}
