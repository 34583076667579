import { createAction, props } from '@ngrx/store';

import { UIStatesEnum } from 'common/src/models';
import { EcoOrderListTabsEnum, SalesOrderListTabsEnum } from '../../enums';
import { SummaryInfoModel } from '../../models/summary-info-model';
import { EcoOrdersListState } from '../reducers';
import { ResponseModel } from '../../../../../shared/models/response';
import { EcoOrderModel } from '../../models';

export const LoadEcoOrdersList = createAction(
  '[Eco order] Load Eco orders List',
  props<{ ecoOrderListData: EcoOrdersListState, status: EcoOrderListTabsEnum, page: number }>()
);

export const UpdateShouldRefreshEntity = createAction(
  '[Eco order] Update Eco order should refresh flag',
  props<{ isShouldRefresh: boolean }>()
);

export const LoadEcoOrder = createAction(
  '[Eco order] Load Eco order',
  props<{ ecoOrder: EcoOrderModel }>()
);

export const LoadEcoOrderPositions = createAction(
  '[Eco order Positions] Load Eco order Positions',
  props<{ positions: ResponseModel<any> }>() // todo
);

export const UpdateEcoOrder = createAction(
  '[Eco order] Update Eco order',
  props<{ ecoOrder: EcoOrderModel }>()
);

export const UpdateEcoOrderPositionsCount = createAction(
  '[Eco order] Update Eco order Positions Count',
  props<{ positionsCount: number }>()
);

export const ClearSalesOrder = createAction(
  '[Eco order] Clear Eco order'
);

export const ClearEcoOrderState = createAction(
  '[Eco order] Clear Eco order state'
);

export const UpdateEcoOrderCurrentState = createAction(
  '[Eco order] Update Eco order Current State',
  props<{ currentState: UIStatesEnum }>()
);

export const UpdateSalesOrderSummary = createAction(
  '[Eco order] Update Eco order Summary',
  props<{ summary: SummaryInfoModel }>()
);

export const UpdateSalesOrderStatus = createAction(
  '[Eco order] Update Eco order Status',
  props<{ status: SalesOrderListTabsEnum }>()
);

export const UpdateEcoOrderUpdatedAt = createAction(
  '[Eco order] Update Eco order updatedAt',
  props<{ updatedAt: Date }>()
);

export const ClearSalesOrderUpdatedAt = createAction(
  '[Eco order] Clear Eco order updatedAt',
);

// export const LoadSalesOrderPartners = createAction(
//   '[Eco order] Load Eco order Partners',
//   props<{ partners: CorporatePartnerModel[] }>()
// );

export const IncrementLoadingRequestsCount = createAction(
  '[Eco order] Increment Loading Requests Count'
);

export const DecrementLoadingRequestsCount = createAction(
  '[Eco order] Decrement Loading Requests Count'
);

export const UpdatePropertiesBlockValid = createAction(
  '[Eco order] Update Eco order Properties Block Valid',
  props<{ propertiesBlockValid: boolean }>()
);

export const UpdateDeliveryInfoBlockValid = createAction(
  '[Eco order] Update Eco order Delivery Info Block Valid',
  props<{ deliveryInfoBlockValid: boolean }>()
);

export const UpdateBillingInfoBlockValid = createAction(
  '[Eco order] Update Eco order Billing Info Block Valid',
  props<{ billingInfoBlockValid: boolean }>()
);

export const UpdatePositionsBlockValid = createAction(
  '[Eco order] Update Eco order Positions Block Valid',
  props<{ positionsBlockValid: boolean }>()
);
