import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { get } from 'lodash';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ProductModel, ProductsService } from '../../../products';
import { ProductTypes } from '../../../products/product-types';
import { SalesPosition, SalesPositionsByProductType } from 'projects/workspace/src/app/sales-order/models/sales-order-position.model';
import { ResponseModel } from 'projects/workspace/src/app/shared/models/response';
import { CompositeProductModalComponent } from '../../modals-products';
import { CustomSearchFn } from 'common/src/modules/rnpl-common/helpers';
import { PRODUCTS_TYPE } from '../../../rnpl-common/constants/products-type';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { VAT_LIST } from 'projects/workspace/src/app/shared/constants/vat-list';
import { ActionButtonsService } from '../../../../services/action-buttons.service';
import { RecalculatePositionApiService } from '../../../../services/recalculate-position-api.service';
import { DocumentTypesUppercaseEnum } from '../../modals-common/link-document-modal/enums/ducument-types.enum';
import { SalesOrderListTabsEnum } from 'projects/workspace/src/app/sales-order/enums';
import { ProductUnitApiService } from 'projects/workspace/src/app/shared/services';
import { ProductUnitModel } from 'projects/workspace/src/app/shared/models';
import { ECommerceApiService } from 'projects/workspace/src/app/e-commerce/services';

@Component({
  selector: 'rnpl-ecommerce-order-position-modal',
  templateUrl: './ecommerce-order-position-modal.component.html',
  styleUrls: ['./ecommerce-order-position-modal.component.scss']
})
export class EcommerceOrderPositionModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
  public companyProfile: CompanyProfile;

  public unitsList: string[] = [];
  public unitsListSource: ProductUnitModel[] = [];
  public readonly productTypes = ProductTypes;
  public previousUnitType: string;

  public productsList: ProductModel[] = [];

  public selectedProduct: ProductModel = null;
  public selectedProductInvalid: boolean = false;

  public marginTotal: number = null;
  public marginPercent: number = null;

  public customSearchFn = CustomSearchFn;

  public selectedProductType;

  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly addPositionRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public form: FormGroup;
  public readonly vatList = VAT_LIST;

  constructor(
    public store: Store<AppState>,
    public toasterService: ToasterService,
    public dialog: MatDialog,
    private productsService: ProductsService,
    private eCommerceApiService: ECommerceApiService,
    private translate: TranslateService,
    public actionButtonsService: ActionButtonsService,
    public dialogRef: MatDialogRef<EcommerceOrderPositionModalComponent>,
    private fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private recalculatePositionApiService: RecalculatePositionApiService,
    private productUnitApiService: ProductUnitApiService,
    @Inject(MAT_DIALOG_DATA) public data: {
      ecoOrderId: number;
      ecoOrderStatus: SalesOrderListTabsEnum;
      salesPriceListName: string,
      vatBlocked: boolean;
    }
  ) {
    super(toasterService);
    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((companyProfile: CompanyProfile) => {
        this.companyProfile = companyProfile;
      });
  }

  ngOnInit() {
    this.initForm();
    this.getProducts();

    this.dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.actionButtonsService.hideSidebarHandler();
      });
  }

  private initForm(selectProduct: ProductModel = {} as ProductModel): void {
    this.form = this.fb.group({
      grossTotalPrice: [this.setCurrencyValue(get(selectProduct, 'prices.price.grossPrice')), Validators.required],
      grossUnitPrice: [this.setCurrencyValue(get(selectProduct, 'prices.price.grossPrice')), Validators.required],
      netTotalPrice: [this.setCurrencyValue(get(selectProduct, 'prices.price.netPrice')), Validators.required],
      netUnitPrice: [this.setCurrencyValue(get(selectProduct, 'prices.price.netPrice')), Validators.required],
      productDescription: [get(selectProduct, 'description')],
      providedServices: [get(selectProduct, 'description')],
      vat: [
        get(selectProduct, 'prices.price.vat') || get(selectProduct, 'prices.price.vat') === 0
          ? get(selectProduct, 'prices.price.vat')
          : get(selectProduct, 'accountingSettings.vatRateByDefault')
      ],
      discount: [get(selectProduct, 'prices.price.discount')],
      discountAmountNet: [this.setCurrencyValue(get(selectProduct, 'prices.price.discountNet'))],
      discountAmountGross: [this.setCurrencyValue(get(selectProduct, 'prices.price.discountGross'))],
      addDiscount: [!!get(selectProduct, 'prices.price.discount')],
      quantity: [1],
      addRemark: [false],
      remark: [''],
      unitType: [get(selectProduct, 'unitType') || 'pcs'],
    });
  }

  selectPositionChanges(selectedProduct: ProductModel): void {
    this.selectedProductType = selectedProduct.type || selectedProduct.productType;
    if (this.data.vatBlocked) {
      this.vat.disable({onlySelf: true, emitEvent: false});
    }

    this.getProductById(+selectedProduct.id);
    this.getProductUnits();
  }

  public getProductUnits(): void {
    this.productUnitApiService.getUnits$(this.selectedProduct.type || this.selectedProduct.entityForm)
      .pipe(takeUntil(this.destroy$))
      .subscribe((unitsList: ProductUnitModel[]) => {
        this.unitsListSource = unitsList;
        this.unitsList = unitsList.map(u => u.name);
      });
  }

  public getProductById(productId, fromPo: boolean = false) {
    this.productsService.getProduct(productId, this.data.salesPriceListName)
      .pipe(takeUntil(this._destroy))
      .subscribe(product => {
        this.initForm(product);
        if (this.data.vatBlocked) {
          const defaultPrice = this.setCurrencyValue(get(product, 'prices.price.netPrice'));
          this.vat.setValue(0);
          this.grossUnitPrice.setValue(defaultPrice);
          this.grossTotalPrice.setValue(defaultPrice);
        }

        this.marginTotal = null;
        this.marginPercent = null;
        this.updatePosition(1, 'quantity');
      });
  }

  private getProducts(): void {
    this.showDropdownSpin$.next(true);
    this.productsService.getActiveProducts(undefined, undefined, this.data.salesPriceListName)
      .pipe(takeUntil(this._destroy))
      .subscribe((products: ProductModel[]) => {
        this.productsList = products
          .filter(item => item.type === ProductTypes.GOODS)
          .map(item => ({
            ...item,
            searchLabel: `${item.name} ${this.translatedProductType(item.type)} ${item.net_price || 'n/a'} ${item.available} ${item.runple_id} ${item.description}`
          }));
        this.showDropdownSpin$.next(false);
      });
  }

  public translatedProductType(productType: ProductTypes): string {
    return this.translate.instant(PRODUCTS_TYPE[productType]);
  }

  public createProduct(productType: ProductTypes): void {
    const dialog = this.dialog.open(CompositeProductModalComponent, {
      data: {
        family: null,
        productType: productType
      },
      disableClose: true,
    });

    dialog.afterClosed().subscribe((product: ProductModel) => {
      if (product) {
        this.productsList.push(product);
        this.selectedProduct = product;
        this.selectPositionChanges(product);
        this.selectedProductType = product.productType;
        this.initForm(this.selectedProduct);
      }
    });
  }

  public addPosition(): void {
    if (!this.selectedProduct) {
      this.selectedProductInvalid = true;
      return;
    }

    if (this.addPositionRequest$.getValue()) { return; }
    this.addPositionRequest$.next(true);

    const position: SalesPosition = {} as SalesPosition;

    position.productId = +this.selectedProduct.id;

    this.eCommerceApiService
      .createEcoOrderPosition(
        this.data.ecoOrderId,
        {
          ...this.form.getRawValue(),
          ...position,
          grossTotalPrice: this.setCurrencyControl(this.grossTotalPrice.value),
          grossUnitPrice: this.setCurrencyControl(this.grossUnitPrice.value),
          netTotalPrice: this.setCurrencyControl(this.netTotalPrice.value),
          netUnitPrice: this.setCurrencyControl(this.netUnitPrice.value),
          productTypeForGrouping: this.selectedProductType,
          productDescription: !this.typeServices
            ? this.description.value
            : null,
          providedServices: this.typeServices
            ? this.providedServices.value
            : null,
        })
      .pipe(
        finalize(() => this.addPositionRequest$.next(false)),
        takeUntil(this._destroy)
      )
      .subscribe(this.subscriberHandler);
  }

  public subscriberHandler = (response: ResponseModel<SalesPositionsByProductType>) => {
    this.dialogRef.close(response);
  }

  public setCurrencyControl(val): number {
    if (!val) { return val; }
    return +(val * 100).toFixed(0);
  }

  public updatePosition(value, fieldName): void {
    this.recalculatePositionApiService.recalculatePosition(DocumentTypesUppercaseEnum.SO, {
      protoPosition: {
        ...this.form.getRawValue(),
        salesPriceListName: this.data.salesPriceListName,
        productId: this.selectedProduct.id,
        productTypeForGrouping: this.selectedProductType,
        productDescription: !this.typeServices
          ? this.description.value
          : null,
        providedServices: this.typeServices
          ? this.providedServices.value
          : null,
      },
      fieldUpdateRequest: {
        fieldName: fieldName,
        fieldValue: value
      },
      type: this.selectedProductType
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe(product => {
        this.form.patchValue(product);
        this.previousUnitType = product.unitType;
        this.marginPercent = product.marginPercent;
        this.marginTotal = product.marginTotal;
      }, () => {
        if (fieldName === 'unitType') {
          // set previous value of unit type in case of error
          this.unitTypeControl.patchValue(this.previousUnitType);
          // redraw quantity control view
          setTimeout(() => this.quantity.patchValue(this.quantity.value), 10);
        }
      });
  }

  public setCurrencyValue(value): number {
    if (!value) { return value; }
    return +(value / 1e2).toFixed(2);
  }

  public updateDescription(description: string): void {
    this.selectedProduct = {
      ...this.selectedProduct,
      description: description
    };
  }

  public updateDescriptionByProductType(): void {
    if (this.selectedProductType === ProductTypes.SERVICES) {
      this.updatePosition(this.providedServices.value, 'providedServices');
    } else {
      this.updatePosition(this.description.value, 'productDescription');
    }
  }

  get typeServices(): boolean {
    return this.selectedProductType === ProductTypes.SERVICES;
  }
  get typeGoods(): boolean {
    return this.selectedProductType === ProductTypes.GOODS;
  }

  get allowFractionalValues(): boolean {
    if (!this.unitTypeControl.value || !this.unitsListSource.length) { return true; }
    return this.unitsListSource
      .find((itm: ProductUnitModel) => itm.name === this.unitTypeControl.value)
      .allowFractionalValues;
  }

  get grossTotalPrice(): FormControl { return this.form.get('grossTotalPrice') as FormControl; }
  get grossUnitPrice(): FormControl { return this.form.get('grossUnitPrice') as FormControl; }
  get addRemark(): FormControl { return this.form.get('addRemark') as FormControl; }
  get netTotalPrice(): FormControl { return this.form.get('netTotalPrice') as FormControl; }
  get netUnitPrice(): FormControl { return this.form.get('netUnitPrice') as FormControl; }
  get description(): FormControl { return this.form.get('productDescription') as FormControl; }
  get providedServices(): FormControl { return this.form.get('providedServices') as FormControl; }
  get quantity(): FormControl { return this.form.get('quantity') as FormControl; }
  get remark(): FormControl { return this.form.get('remark') as FormControl; }
  get vat(): FormControl { return this.form.get('vat') as FormControl; }
  get discount(): FormControl { return this.form.get('discount') as FormControl; }
  get discountAmountNet(): FormControl { return this.form.get('discountAmountNet') as FormControl; }
  get discountAmountGross(): FormControl { return this.form.get('discountAmountGross') as FormControl; }
  get addDiscount(): FormControl { return this.form.get('addDiscount') as FormControl; }
  get unitTypeControl(): FormControl { return this.form.get('unitType') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
