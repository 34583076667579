import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { FiltersControlBaseComponent } from '../filters-control-base/filters-control-base.component';
import { BankAccountsApiService } from 'projects/workspace/src/app/bank-accounts/services/bank-accounts-api.service';
import { BankAccountModel } from 'projects/workspace/src/app/bank-accounts/models';

@Component({
  selector: 'rnpl-filters-control-bank-account',
  templateUrl: './filters-control-bank-account.component.html',
})
export class FiltersControlBankAccountComponent extends FiltersControlBaseComponent implements OnInit {

  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly list$: BehaviorSubject<BankAccountModel[]> = new BehaviorSubject<BankAccountModel[]>([]);

  constructor(
    private bankAccountsApiService: BankAccountsApiService,
    public translateService: TranslateService
  ) {
    super(translateService);
  }

  ngOnInit() {
    this.getCombinedPartners();
  }

  private getCombinedPartners(): void {
    this.bankAccountsApiService.getActiveBankAccounts()
      .pipe(takeUntil(this.destroy$))
      .subscribe(bankAccounts => {
        this.showDropdownSpin$.next(false);
        this.list$.next(bankAccounts);
      });
  }

}
