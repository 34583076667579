import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatMenuModule } from '@angular/material';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgOtpInputModule } from 'ng-otp-input';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxCurrencyModule } from 'ngx-currency-custom';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPrintModule } from 'ngx-print';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { UIComponentsModule } from '../ui-components/ui-components.module';
import { RnplCommonModule } from '../rnpl-common';
import { HrmModule } from 'projects/workspace/src/app/hrm/hrm.module';
import { ProductFormHardcodeModule } from '../products';
import { DynamicFormsModule, UsersCommonModule } from '..';

import {
  ChangeEmailModalComponent,
  ChangePasswordModalComponent,
  CreateNewEmployeeModalComponent,
} from './modals-hrm';
import {
  CreateNewWarehouseModalComponent,
  CreateNewBinLocationModalComponent,
} from './modals-warehouse';
import {
  CreateNewBatchModalComponent, CreateSoModalComponent,
  PoPositionModalComponent,
} from './modals-purchase-order';
import { PurchaseOrderModule } from 'projects/workspace/src/app/purchase-order/purchase-order.module';
import { SignInModalComponent } from './modals-sign-in';
import { CommonPagesModule } from '../../pages/common-pages.module';
import {
  OfferAddDiscountModalComponent,
  OfferBillingInfoModalComponent,
  OfferDeliveryInfoModalComponent,
  OfferPositionModalComponent,
  TradeOfferModalHintsComponent,
  OfferEditPositionModalComponent,
  OfferAddRemarkModalComponent
} from './modals-trade-offer';
import { TradeOfferApiService } from 'projects/workspace/src/app/trade-offer/services/trade-offer-api.service';
import {
  CreateNewPartnerModalComponent,
  CreateNewContactModalComponent,
  CreateNewPrivatePartnerModalComponent
} from './modals-crm';
import { PartnerFormModule } from 'projects/workspace/src/app/crm/components/partner-form/partner-form.module';
import {
  CorporatePartnerFormModule
} from 'projects/workspace/src/app/crm/partner-forms/components/corporate-partner-form/corporate-partner-form.module';
import { CeoInfoFormModule } from 'projects/workspace/src/app/crm/partner-forms/components/ceo-info-form/ceo-info-form.module';
import { AddressFormModule } from 'projects/workspace/src/app/crm/partner-forms/components/address-form/address-form.module';
import {
  OutgoingInvoiceLinkSalesOrderModalComponent,
  OutgoingInvoicePositionModalComponent,
} from './modals-outgoing-invoice';
import {
  ConfirmModalComponent,
  DangerModalComponent,
  InfoModalComponent,
  SendEmailModalComponent,
  WarningModalComponent,
  SkipHintModalComponent,
  AddPdfTextModalComponent,
  SavePdfTextTemplateModalComponent,
  AllocatePaymentModalComponent,
  CreateDownPaymentModalComponent
} from './modals-common';
import {
  ProductVariationModalComponent,
  CategoryModalComponent,
  CompositeProductModalComponent,
  FamilyAssignedAttrModalComponent,
  MoveProductsModalComponent,
  MoveSingleProductModalComponent,
  ProductRelationModalComponent,
  ProductPriceModalComponent,
  FamilyAssignedModalHintsComponent,
  CompositeProductHintsComponent,
  ProductVariationViewModalComponent,
} from './modals-products';
import { SalesOrderApiService } from 'projects/workspace/src/app/sales-order/services/sales-order-api.service';
import {
  CreatePoModalComponent,
  ForceCompleteSoModalComponent,
  LinkOfferModalComponent,
  ResolveConflictModalComponent,
  SalesOrderConfirmServicesModalComponent,
  SalesOrderPositionModalComponent
} from './modals-sales-order';
import { TradeApiService } from 'projects/workspace/src/app/trade';
import { WizardService } from '../wizard';
import { LinkDocumentModalComponent } from './modals-common/link-document-modal/link-document-modal.component';
import { WarehouseModule } from 'projects/workspace/src/app/warehouse/warehouse.module';
import { PackUpModalComponent } from './modals-warehouse/pack-up-modal/pack-up-modal.component';
import {
  PuckUpModalComponent,
  GeneratePickListModalComponent,
  GeneratePickListErrorModalComponent,
  AddProductModalComponent,
  PrereservationModalComponent,
  AddProductForLinkedModalComponent,
  PackUpShipmentSuccessModalComponent,
  PackUpShipmentModalComponent,
  SelectPartnerModalComponent,
  EditProductModalComponent,
  AddRemarkModalComponent,
  DeliveryInfoModalComponent
} from './modals-delivery-note';
import {
  DeliveryNoteUnitsFormModule
} from 'projects/workspace/src/app/delivery-note/components/delivery-note-units-form/delivery-note-units-form.module';
import {
  IncomingInvoicePositionModalComponent,
  LinkPurchaseOrderModalComponent,
  LinkScheduledPaymentModalComponent
} from './modals-incoming-invoice';
import {
  CreditNoteCommonPositionModalComponent,
  CreditNoteCreateModalComponent,
  CreditNotePositionModalComponent,
} from './modals-credit-note';
import { LinkSalesOrderComponent } from './modals-common/link-sales-order/link-sales-order.component';
import { RouterModule } from '@angular/router';
import { SubscriptionPositionModalComponent, SubscriptionPauseModalComponent } from './modals-subscription';
import { SubscriptionApiService } from 'projects/workspace/src/app/subscription/services/subscription-api.service';
import { PartnersApiService } from 'projects/workspace/src/app/partners/corporate/services/partner-api.service';
import { PrivatePartnersApiService } from 'projects/workspace/src/app/partners/private/services/partner-api.service';
import {
  ExchangeAddProductCompensationComponent,
  ExchangeAddProductModalComponent,
  ExchangeAddProductModalNewComponent,
  ExchangeAddProductPricesComponent,
} from './modals-exchange';
import { ExchangeApiService } from 'projects/workspace/src/app/exchange/services/exchange-api.service';
import {
  TimeTrackingAssignSalesOrderModalComponent,
  TimeTrackingBillingScheduleModalComponent,
  TimeTrackingBillTimeModalComponent,
  TimeTrackingExportListModalComponent,
  TimeTrackingGroupingFormComponent,
  TimeTrackingGroupingModalComponent,
  TimeTrackingLogTimeModalComponent,
  TimeTrackingResolveIssueModalComponent
} from './modals-time-tracking';
import { SafePipeModule } from 'safe-pipe';
import {
  EcommerceOrderPositionModalComponent,
  EcommerceProductPriceModalComponent,
  EcoProductsActivationModalComponent
} from './modals-ecommerce';
import {
  AddBannerModalComponent,
  AddCategoryModalComponent,
  CardPaymentsStripeModalComponent,
  SliderModalComponent,
  UpdateBlockColorModalComponent,
  UpdateCardPaymentsModalComponent,
  UpdateDeliveryTermModalComponent,
  UpdatePaymentUponPickupModalComponent,
  UpdatePurchaseOnInvoiceModalComponent,
  UpdateReturnsTermModalComponent
} from './modals-ecommerce-settings';
import {
  IncomingDeliveryCancelWarehousingModalComponent,
  IncomingDeliveryProductsReceiptModalComponent,
  IncomingDeliveryProductsReturnModalComponent,
  IncomingDeliveryWarehousingModalComponent
} from './modals-incoming-delivery';
import { CreateNewTransactionModalComponent } from './modals-transactions';
import { ChangePasswordComponent } from './modals-sign-in/change-password/change-password.component';
import {
  ReportingPeriodsCloseByYearModalComponent,
  ReportingPeriodsReportPeriodModalComponent,
  ReportingPeriodsScheduleReportingModalComponent
} from './modals-reporting-periods';
import { NewTransactionsHintsComponent } from './modals-transactions/new-transactions-hints/new-transactions-hints.component';
import { ProductReceiptHintsComponent } from './modals-incoming-delivery/product-receipt-hints/product-receipt-hints.component';
import { WarehousingHintsComponent } from './modals-incoming-delivery/warehousing-hints/warehousing-hints.component';
import { PackUpHintsComponent } from './modals-warehouse/pack-up-modal/pack-up-hints/pack-up-hints.component';
import { LogTimeModalsHintsComponent } from './modals-time-tracking/log-time-modals-hints/log-time-modals-hints.component';
import { BillTimeHintsComponent } from './modals-time-tracking/bill-time-hints/bill-time-hints.component';
import { GroupingModalHintsComponent } from './modals-time-tracking/grouping-modal-hints/grouping-modal-hints.component';
import { PauseModalsHintsComponent } from './modals-subscription/pause-modals-hints/pause-modals-hints.component';
import { PositionModalsHintsComponent } from './modals-outgoing-invoice/position-modals-hints/position-modals-hints.component';
import { CreditNotePositionHintsComponent } from './modals-credit-note/credit-note-position-hints/credit-note-position-hints.component';
import { AddProductHintsComponent } from './modals-exchange/add-product-hints/add-product-hints.component';
import { CreatePartnerHintsComponent } from './modals-crm/create-partner-hints/create-partner-hints.component';
import { CreateNewUserHintsComponent } from './modals-hrm/create-new-user-hints/create-new-user-hints.component';
import { InfoHintsCardModule } from '../info-hints-card/info-hints-card.module';
import {
  ActivateSubscriptionModalComponent,
  CancelSubscriptionModalComponent,
  DeleteAccountModalComponent,
  UpdatePaymentCardModalComponent
} from './modals-subscription-activation';
import { ConnectBankAccountModalComponent, CreateNewBankAccountModalComponent, SyncPaymentsModalComponent } from './modals-bank-accounts';
import { BankAccountFormModule } from 'projects/workspace/src/app/bank-accounts/forms';
import {
  AccountingSettingsSendEmailModalComponent,
  GlAllocationRuleModalComponent,
  OinSendNoticeEmailModalComponent
} from './modals-accounting-settings';
import { OverdueNoticeFormModule } from 'projects/workspace/src/app/accounting/accounting-settings-module/forms';
import { ShortcutsModalComponent } from './modals-control-center';
import { LaunchpadModalComponent } from './launchpad-modal/launchpad-modal.component';
import { AddDocumentModalComponent, LinkMultiplePaymentsModalComponent, LinkSpModalComponent } from './modals-payments';
import { PositionCardModule } from '../position-card/position-card.module';
import {
  AustrianPostSettingsModalComponent,
  DhlSettingsModalComponent,
  GlsSettingsModalComponent,
  UpsSettingsModalComponent
} from './modals-delivery-services';
import {
  EcoDeliveryFormModule
} from 'projects/workspace/src/app/e-commerce/modules/ecommerce-settings/forms/eco-delivery-form/eco-delivery-form.module';
import { BankAccountDocumentFormModule } from 'projects/workspace/src/app/shared/forms/bank-account-document-form/bank-account-document-form.module';
import {
  ScheduledPaymentPositionModalComponent,
  ScheduledPaymentScheduleFormModalComponent,
  ScheduledPaymentsLinkModalComponent
} from './modals-scheduled-payment';
import {
  ScheduledPaymentBillingScheduleFormModule
} from 'projects/workspace/src/app/scheduled-payment/components/scheduled-payment-billing-schedule-form/scheduled-payment-billing-schedule-form.module';
import { SalesOrderAddRemarkModalComponent } from './modals-sales-order/sales-order-add-remark-modal/sales-order-add-remark-modal.component';
import { SalesOrderEditPositionModalComponent } from './modals-sales-order/sales-order-edit-position-modal/sales-order-edit-position-modal.component';
import { SidebarModule } from 'projects/workspace/src/app/shared/components/sidebar/sidebar.module';
import { PricesTableTotalInfoModule } from 'projects/workspace/src/app/trade-prices/components/prices-table-total-info/prices-table-total-info.module';
import { SalesOrderAddDiscountModalComponent } from './modals-sales-order/sales-order-add-discount-modal/sales-order-add-discount-modal.component';
import { DiscountFormModule } from 'projects/workspace/src/app/shared/forms/discount-form/discount-form.module';
import { SalesOrderDeliveryInfoModalComponent } from './modals-sales-order/sales-order-delivery-info-modal/sales-order-delivery-info-modal.component';
import { CommonAddressFormModule } from 'projects/workspace/src/app/shared/forms/common-address-form/common-address-form.module';
import { DeliveryCostFormModule } from 'projects/workspace/src/app/shared/forms/delivery-cost-form/delivery-cost-form.module';
import { InsuranceCostFormModule } from 'projects/workspace/src/app/shared/forms/insurance-cost-form/insurance-cost-form.module';
import { SalesOrderBillingInfoModalComponent } from './modals-sales-order/sales-order-billing-info-modal/sales-order-billing-info-modal.component';
import { DueWithinFormModule } from 'projects/workspace/src/app/shared/forms/due-within-form/due-within-form.module';
import { ScontoFormModule } from 'projects/workspace/src/app/shared/forms/sconto-form/sconto-form.module';
import { OfferSelectPartnerModalComponent } from './modals-trade-offer/offer-select-partner-modal/offer-select-partner-modal.component';
import { OutgoingInvoiceSelectPartnerModalComponent } from './modals-outgoing-invoice';
import { OutgoingInvoiceEditPositionModalComponent } from './modals-outgoing-invoice';
import { OutgoingInvoiceAddRemarkModalComponent } from './modals-outgoing-invoice';
import { OutgoingInvoiceAddDiscountModalComponent } from './modals-outgoing-invoice';
import { OutgoingInvoiceBillingInfoModalComponent } from './modals-outgoing-invoice';
import { CreditNoteSelectPartnerModalComponent } from './modals-credit-note';
import { CreditNoteEditPositionModalComponent } from './modals-credit-note';
import { CreditNoteAddRemarkModalComponent } from './modals-credit-note';
import { CreaditNoteBillingInfoModalComponent } from './modals-credit-note';
import { SbcSelectPartnerModalComponent } from './modals-subscription';
import { SubscriptionEditPositionModalComponent } from './modals-subscription';
import { SubscriptionAddDiscountModalComponent } from './modals-subscription';
import { SubscriptionAddRemarkModalComponent } from './modals-subscription';
import { SubscriptionAddBillingInfoModalComponent } from './modals-subscription';
import { AddressCardModule } from 'projects/workspace/src/app/shared/components/address-card/address-card.module';
import { SalesOrderModalHintsComponent } from './modals-sales-order/sales-order-modal-hints/sales-order-modal-hints.component';
import { SubscriptionModalHintsComponent } from './modals-subscription/subscription-modal-hints/subscription-modal-hints.component';
import { OutgoingInvoiceModalHintsComponent } from './modals-outgoing-invoice/outgoing-invoice-modal-hints/outgoing-invoice-modal-hints.component';
import { EcoBillingInfoModalComponent } from './modals-ecommerce/eco-billing-info-modal/eco-billing-info-modal.component';
import { CompanyNameControlModule } from 'projects/workspace/src/app/shared/forms/company-name-control/company-name-control.module';
import { EcoEditPositionModalComponent } from './modals-ecommerce/eco-edit-position-modal/eco-edit-position-modal.component';
import { EcoAddRemarkModalComponent } from './modals-ecommerce/eco-add-remark-modal/eco-add-remark-modal.component';
import { EcoDeliveryInfoModalComponent } from './modals-ecommerce/eco-delivery-info-modal/eco-delivery-info-modal.component';
import { EcoAddPartnerModalComponent } from './modals-ecommerce/eco-add-partner-modal/eco-add-partner-modal.component';
import { EcoAddDiscountModalComponent } from './modals-ecommerce/eco-add-discount-modal/eco-add-discount-modal.component';
import { LaunchPadAddShortcutsModalComponent } from './launch-pad-add-shortcuts-modal/launch-pad-add-shortcuts-modal.component';
import { QuickActionsModalComponent } from './quick-actions-modal/quick-actions-modal.component';
import { StartScreenPageModule } from '../start-screen-page/start-screen-page.module';
import {
  IncomingInvoiceOcrErrorModalComponent
} from './modals-incoming-invoice/incoming-invoice-ocr-error-modal/incoming-invoice-ocr-error-modal.component';
import {
  DocumentTemplateSameNameModalComponent,
  DocumentTemplateSaveModalComponent,
  DocumentTemplatesModalComponent
} from './modals-documents-templates';
import { DocumentsTemplatesApiService, DocumentTextTemplatesApiService } from 'projects/workspace/src/app/shared/services';
import { AutocorrectionModalComponent } from './modals-common/autocorrection-modal/autocorrection-modal.component';
import { CreateNewGlAccountModalComponent } from './modals-gl-accounts';
import { ExpansionPanelModule } from 'projects/workspace/src/app/shared/components/expansion-panel/expansion-panel.module';
import { GlAccountDropdownModule, GlProductTypeDropdownModule } from 'projects/workspace/src/app/gl-accounts/components';
import {
  ModalContainerModule,
  PositionAccountingSettingsModule,
  PositionsNavigateButtonsModule,
  RelatedProductsCardsModule
} from 'projects/workspace/src/app/shared/components';
import { PurposeFormModule } from 'projects/workspace/src/app/shared/forms/purpose-form/purpose-form.module';
import { DeliveryServiceControlModule } from 'projects/workspace/src/app/shared/forms/delivery-service-control/delivery-service-control.module';
import { SoSelectPartnerModalComponent } from './modals-sales-order/select-partner-modal/so-select-partner-modal.component';
import { NgxMaskModule } from 'ngx-mask';
import { LogoModule } from '../ui-components/logo/logo.module';
import { NumberFormatPipeModule } from 'projects/workspace/src/app/shared/pipes/number-format-pipe/number-format-pipe.module';
import { DueWithinDateFormModule, EmailTextFormModule } from 'projects/workspace/src/app/shared/forms';
import { ProductVariationsControlModule } from 'projects/workspace/src/app/shared/forms';
import {
  ArbitraryPositionTypeControlModule
} from 'projects/workspace/src/app/shared/forms/arbitrary-position-type-control/arbitrary-position-type-control.module';


const PAYMENTS_MODALS = [
  LinkMultiplePaymentsModalComponent,
  AddDocumentModalComponent,
  LinkSpModalComponent
];

const GL_ACCOUNTS_MODALS = [
  CreateNewGlAccountModalComponent
];

const BANK_ACCOUNTS_MODALS = [
  CreateNewBankAccountModalComponent,
  ConnectBankAccountModalComponent,
  SyncPaymentsModalComponent
];

const DELIVERY_SERVICES_MODALS = [
  AustrianPostSettingsModalComponent,
  DhlSettingsModalComponent,
  GlsSettingsModalComponent,
  UpsSettingsModalComponent,
];

const SUBSCRIPTION_ACTIVATION_MODALS = [
  ActivateSubscriptionModalComponent,
  CancelSubscriptionModalComponent,
  UpdatePaymentCardModalComponent,
  DeleteAccountModalComponent,
];

const ACCOUNTING_SETTINGS_MODALS = [
  AccountingSettingsSendEmailModalComponent,
  OinSendNoticeEmailModalComponent,
  GlAllocationRuleModalComponent,
];

const REPORTING_PERIODS_MODALS = [
  ReportingPeriodsReportPeriodModalComponent,
  ReportingPeriodsScheduleReportingModalComponent,
  ReportingPeriodsCloseByYearModalComponent,
];

const TRANSACTIONS_MODALS = [
  CreateNewTransactionModalComponent
];

const SCHEDULED_PAYMENT_MODALS = [
  ScheduledPaymentPositionModalComponent,
  ScheduledPaymentsLinkModalComponent,
  ScheduledPaymentScheduleFormModalComponent
];

const INCOMING_DELIVERY_MODALS = [
  IncomingDeliveryProductsReceiptModalComponent,
  IncomingDeliveryProductsReturnModalComponent,
  IncomingDeliveryWarehousingModalComponent,
  IncomingDeliveryCancelWarehousingModalComponent
];

const ECOMMERCE_MODALS = [
  EcommerceProductPriceModalComponent,
  EcommerceOrderPositionModalComponent,
  EcoProductsActivationModalComponent,
  EcoBillingInfoModalComponent,
  EcoAddRemarkModalComponent,
  EcoDeliveryInfoModalComponent,
  EcoAddPartnerModalComponent,
];

const ECOMMERCE_SETTINGS_MODALS = [
  AddBannerModalComponent,
  AddCategoryModalComponent,
  SliderModalComponent,
  UpdateDeliveryTermModalComponent,
  UpdateReturnsTermModalComponent,
  UpdatePaymentUponPickupModalComponent,
  UpdatePurchaseOnInvoiceModalComponent,
  UpdateCardPaymentsModalComponent,
  CardPaymentsStripeModalComponent,
  UpdateBlockColorModalComponent,
];

const EXCHANGE_MODALS = [
  ExchangeAddProductModalComponent,
  ExchangeAddProductPricesComponent,
  ExchangeAddProductCompensationComponent,
  ExchangeAddProductModalNewComponent,
  EcoEditPositionModalComponent,
];

const TIME_TRACKING_MODALS = [
  TimeTrackingLogTimeModalComponent,
  TimeTrackingBillTimeModalComponent,
  TimeTrackingBillingScheduleModalComponent,
  TimeTrackingAssignSalesOrderModalComponent,
  TimeTrackingResolveIssueModalComponent,
  TimeTrackingGroupingFormComponent,
  TimeTrackingGroupingModalComponent,
  TimeTrackingExportListModalComponent,
];

const CREDIT_NOTE_MODALS = [
  CreditNotePositionModalComponent,
  CreditNoteCommonPositionModalComponent,
  CreditNoteSelectPartnerModalComponent,
  CreditNoteEditPositionModalComponent,
  CreditNoteAddRemarkModalComponent,
  CreaditNoteBillingInfoModalComponent,
  CreditNoteCreateModalComponent,
];

const DELIVERY_NOTE_MODALS = [
  PuckUpModalComponent,
  GeneratePickListModalComponent,
  GeneratePickListErrorModalComponent,
  AddProductModalComponent,
  PrereservationModalComponent,
  AddProductForLinkedModalComponent,
  PackUpShipmentSuccessModalComponent,
  PackUpShipmentModalComponent,
  SelectPartnerModalComponent,
  EditProductModalComponent,
  AddRemarkModalComponent,
  DeliveryInfoModalComponent,
];

const PRODUCTS_MODALS = [
  CompositeProductModalComponent,
  FamilyAssignedAttrModalComponent,
  CategoryModalComponent,
  ProductPriceModalComponent,
  MoveProductsModalComponent,
  MoveSingleProductModalComponent,
  ProductRelationModalComponent,
  ProductVariationModalComponent,
  ProductVariationViewModalComponent,
  FamilyAssignedModalHintsComponent,
  CompositeProductHintsComponent
];

const TRADE_OFFER_MODALS = [
  OfferPositionModalComponent,
  OfferSelectPartnerModalComponent,
  OfferEditPositionModalComponent,
  OfferAddRemarkModalComponent,
  OfferAddDiscountModalComponent,
  OfferBillingInfoModalComponent,
  OfferDeliveryInfoModalComponent,
];

const SUBSCRIPTION_MODALS = [
  SubscriptionPositionModalComponent,
  SubscriptionPauseModalComponent,
  SbcSelectPartnerModalComponent,
  SubscriptionEditPositionModalComponent,
  SubscriptionAddDiscountModalComponent,
  SubscriptionAddRemarkModalComponent,
  SubscriptionAddBillingInfoModalComponent,
];

const SALES_ORDER_MODALS = [
  ResolveConflictModalComponent,
  SalesOrderPositionModalComponent,
  LinkOfferModalComponent,
  SalesOrderConfirmServicesModalComponent,
  CreatePoModalComponent,
  ForceCompleteSoModalComponent,
  SoSelectPartnerModalComponent,
  SalesOrderAddRemarkModalComponent,
  SalesOrderEditPositionModalComponent,
  SalesOrderAddDiscountModalComponent,
  SalesOrderDeliveryInfoModalComponent,
  SalesOrderBillingInfoModalComponent,
];

const PURCHASE_ORDER_MODALS = [
  CreateNewBatchModalComponent,
  PoPositionModalComponent,
  CreateSoModalComponent
];

const HRM_MODALS = [
  ChangeEmailModalComponent,
  ChangePasswordModalComponent,
  CreateNewEmployeeModalComponent,
];

const CRM_MODALS = [
  CreateNewPartnerModalComponent,
  CreateNewContactModalComponent,
  CreateNewPrivatePartnerModalComponent
];

const OUTGOING_INVOICE_MODALS = [
  OutgoingInvoicePositionModalComponent,
  OutgoingInvoiceLinkSalesOrderModalComponent,
  OutgoingInvoiceSelectPartnerModalComponent,
  OutgoingInvoiceEditPositionModalComponent,
  OutgoingInvoiceAddRemarkModalComponent,
  OutgoingInvoiceAddDiscountModalComponent,
  OutgoingInvoiceBillingInfoModalComponent,
];

const INCOMING_INVOICE_MODALS = [
  IncomingInvoicePositionModalComponent,
  IncomingInvoiceOcrErrorModalComponent,
  LinkPurchaseOrderModalComponent,
  LinkScheduledPaymentModalComponent,
];

const WAREHOUSE_MODALS = [
  CreateNewWarehouseModalComponent,
  PackUpModalComponent,
  CreateNewBinLocationModalComponent
];

const SIGN_IN_MODALS = [
  SignInModalComponent,
];

const COMMON_MODALS = [
  ConfirmModalComponent,
  WarningModalComponent,
  DangerModalComponent,
  LinkDocumentModalComponent,
  InfoModalComponent,
  LinkSalesOrderComponent,
  SendEmailModalComponent,
  AutocorrectionModalComponent,
  AddPdfTextModalComponent,
  SavePdfTextTemplateModalComponent,
  AllocatePaymentModalComponent,
  CreateDownPaymentModalComponent,
];

const DOCUMENTS_TEMPLATES_MODALS = [
  DocumentTemplatesModalComponent,
  DocumentTemplateSaveModalComponent,
  DocumentTemplateSameNameModalComponent
];

const MODALS = [
  ...PAYMENTS_MODALS,
  ...DELIVERY_NOTE_MODALS,
  ...WAREHOUSE_MODALS,
  ...HRM_MODALS,
  ...PURCHASE_ORDER_MODALS,
  ...SIGN_IN_MODALS,
  ...TRADE_OFFER_MODALS,
  ...SALES_ORDER_MODALS,
  ...CRM_MODALS,
  ...OUTGOING_INVOICE_MODALS,
  ...COMMON_MODALS,
  ...PRODUCTS_MODALS,
  ...INCOMING_INVOICE_MODALS,
  ...CREDIT_NOTE_MODALS,
  ...SUBSCRIPTION_MODALS,
  ...EXCHANGE_MODALS,
  ...TIME_TRACKING_MODALS,
  ...ECOMMERCE_MODALS,
  ...ECOMMERCE_SETTINGS_MODALS,
  ...INCOMING_DELIVERY_MODALS,
  ...TRANSACTIONS_MODALS,
  ...REPORTING_PERIODS_MODALS,
  ...SUBSCRIPTION_ACTIVATION_MODALS,
  ...BANK_ACCOUNTS_MODALS,
  ...GL_ACCOUNTS_MODALS,
  ...ACCOUNTING_SETTINGS_MODALS,
  ...DELIVERY_SERVICES_MODALS,
  ...DOCUMENTS_TEMPLATES_MODALS,
  SkipHintModalComponent,
  ShortcutsModalComponent,
  LaunchpadModalComponent,
  ...SCHEDULED_PAYMENT_MODALS,
  LaunchPadAddShortcutsModalComponent,
  QuickActionsModalComponent,
];

@NgModule({
  declarations: [
    ...MODALS,
    ChangePasswordComponent,
    NewTransactionsHintsComponent,
    ProductReceiptHintsComponent,
    WarehousingHintsComponent,
    PackUpHintsComponent,
    LogTimeModalsHintsComponent,
    BillTimeHintsComponent,
    GroupingModalHintsComponent,
    PauseModalsHintsComponent,
    PositionModalsHintsComponent,
    CreditNotePositionHintsComponent,
    AddProductHintsComponent,
    CreatePartnerHintsComponent,
    CreateNewUserHintsComponent,
    TradeOfferModalHintsComponent,
    SalesOrderModalHintsComponent,
    SubscriptionModalHintsComponent,
    OutgoingInvoiceModalHintsComponent,
    EcoAddDiscountModalComponent,
  ],
  exports: [...MODALS],
  imports: [
    CommonModule,
    UIComponentsModule,
    MatMenuModule,
    RnplCommonModule,
    WarehouseModule,
    HrmModule,
    PurchaseOrderModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgSelectModule,
    NgxCurrencyModule,
    DynamicFormsModule,
    CommonPagesModule,
    NgOtpInputModule,
    PartnerFormModule,
    CorporatePartnerFormModule,
    CeoInfoFormModule,
    AddressFormModule,
    ProductFormHardcodeModule,
    DragDropModule,
    DeliveryNoteUnitsFormModule,
    SafePipeModule,
    UsersCommonModule,
    TranslateModule,
    InfoHintsCardModule,
    BankAccountFormModule,
    OverdueNoticeFormModule,
    PositionCardModule,
    EcoDeliveryFormModule,
    BankAccountDocumentFormModule,
    ScheduledPaymentBillingScheduleFormModule,
    PositionCardModule,
    MatDialogModule,
    SidebarModule,
    PricesTableTotalInfoModule,
    DiscountFormModule,
    CommonAddressFormModule,
    DeliveryCostFormModule,
    InsuranceCostFormModule,
    DueWithinFormModule,
    DueWithinDateFormModule,
    ScontoFormModule,
    PositionCardModule,
    AddressCardModule,
    EcoDeliveryFormModule,
    CompanyNameControlModule,
    PositionCardModule,
    NgxPrintModule,
    StartScreenPageModule,
    PositionsNavigateButtonsModule,
    GooglePlaceModule,
    ExpansionPanelModule,
    GlAccountDropdownModule,
    GlProductTypeDropdownModule,
    PositionAccountingSettingsModule,
    PurposeFormModule,
    ArbitraryPositionTypeControlModule,
    DeliveryServiceControlModule,
    PerfectScrollbarModule,
    NgxMaskModule,
    ModalContainerModule,
    NumberFormatPipeModule,
    EmailTextFormModule,
    LogoModule,
    ProductVariationsControlModule,
    RelatedProductsCardsModule,
  ],
  entryComponents: [...MODALS],
  providers: [
    TradeOfferApiService,
    SalesOrderApiService,
    TradeApiService,
    WizardService,
    SubscriptionApiService,
    PartnersApiService,
    PrivatePartnersApiService,
    ExchangeApiService,
    DocumentsTemplatesApiService,
    DocumentTextTemplatesApiService
  ]
})
export class ModalsModule { }
