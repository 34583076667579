import { MatDialogConfig } from '@angular/material';

export const MAT_DIALOG_OPTIONS: MatDialogConfig = {
  ...new MatDialogConfig(),
  width: 'auto',
  position: {
    top: '76px'
  }
};

export const MODAL_CONFIG = {
  backdropClass: 'dragged-modal-type',
  minWidth: `624px`,
  maxWidth: `624px`,
  panelClass: 'dragged-panel',
  disableClose: true,
  position: {
    top: MAT_DIALOG_OPTIONS.position.top,
    left: `calc(50% - 504px)`,
  }
};
