import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { debounceTime, finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { ProductTypes } from '../../../products/product-types';
import { TableColumnModel } from '../../../../models/table-column.model';
import { SalesPosition, SalesPositionsByProductType } from 'projects/workspace/src/app/subscription/models/subscription-position.model';
import { SubscriptionApiService } from 'projects/workspace/src/app/subscription/services/subscription-api.service';
import { SubscriptionModel } from 'projects/workspace/src/app/subscription/models/subscription.model';
import { ProductUnitModel, ResponseModel } from 'projects/workspace/src/app/shared/models';
import { UnitTypeTranslatePipe } from '../../../rnpl-common';
import {
  generatePositionsCardsInfo,
} from 'projects/workspace/src/app/subscription/pages/subscription-positions/subscription-positions.config';
import { UIStatesEnum } from '../../../../models';
import { ActionButtonsService } from '../../../../services/action-buttons.service';
import { CommonModalsActionsEnum, DangerModalComponent } from '../../modals-common';
import {
  ControlTypeEnum,
  DisplayConditionsModel,
  PositionInfoListModel,
  PositionItemTypeEnum
} from '../../../position-card/position-card-models.model';
import { PositionsActionsType } from '../../../ui-components/table/custom-table.enums';
import { selectSubscriptionCurrentState, selectSubscriptionPositions } from 'projects/workspace/src/app/subscription/store/selectors';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { REMOVE_POSITION_MODAL_DATA } from '../../modals.contsans';
import { ProductUnitApiService } from '../../../../../../projects/workspace/src/app/shared/services';

@Component({
  selector: 'rnpl-subscription-edit-position-modal',
  templateUrl: './subscription-edit-position-modal.component.html',
  styleUrls: ['./subscription-edit-position-modal.component.scss']
})
export class SubscriptionEditPositionModalComponent implements OnInit {

  public positionsCardInfoByTypes: {[key in ProductTypes]?: any[]} = {
    [ProductTypes.SERVICES]: [] as TableColumnModel[],
    [ProductTypes.DIGITAL]: [] as TableColumnModel[]
  };

  public position: SalesPosition;
  public positions: SalesPosition[];
  public rowIndex: number;
  public positionItemTypeEnum: typeof PositionItemTypeEnum = PositionItemTypeEnum;
  public controlTypeEnum: typeof ControlTypeEnum = ControlTypeEnum;

  public unitsList: string[] = [];
  public unitsListSource: ProductUnitModel[] = [];
  public isReadonly: boolean;
  public showControls: boolean = true;
  public positionsByProductType: {[key in ProductTypes]?: any[]};

  public positionsUpdateRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    public dialogRef: MatDialogRef<SubscriptionEditPositionModalComponent>,
    public subscriptionApi: SubscriptionApiService,
    private translate: TranslateService,
    private unitTypeTranslatePipe: UnitTypeTranslatePipe,
    private datePipe: DatePipe,
    public actionButtonsService: ActionButtonsService,
    private readonly dialog: MatDialog,
    private productUnitApiService: ProductUnitApiService,
    private readonly store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: {
      currentSubscription: SubscriptionModel;
      position: SalesPosition,
      positions: SalesPosition[],
      positionsByProductType: {[key in ProductTypes]?: any[]}
    }
  ) { }

  ngOnInit() {
    this.position = this.preparePosition(this.data.position);
    this.positions = this.data.positions;
    this.positionsByProductType = this.data.positionsByProductType;
    this.rowIndex = this.getRowIndex(this.position);

    this.getProductUnits();
    this.getPositionsCards();
    this.trackSubscriptionStateChanges();
    this.trackPositionsChanges();
  }

  public getProductUnits(): void {
    this.productUnitApiService.getUnits$(this.position.productType)
      .pipe(takeUntil(this.destroy$))
      .subscribe((unitsList: ProductUnitModel[]) => {
        this.unitsListSource = unitsList;
        if (!this.position.general) {
          this.unitsList = [this.position.unitType];
          return;
        } else {
          this.unitsList = unitsList.map(u => u.name);
        }
        this.redrawControls();
      });
  }

  private trackSubscriptionStateChanges(): void {
    this.store.select(selectSubscriptionCurrentState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: UIStatesEnum) => {
        this.isReadonly = state === UIStatesEnum.VIEW;
      });
  }

  private trackPositionsChanges(): void {
    this.positionsUpdateRequest$.next(true);
    this.store.select(selectSubscriptionPositions)
      .pipe(
        finalize(() => this.positionsUpdateRequest$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe((positions: ResponseModel<SalesPositionsByProductType>) => {
        if (positions) {
          this.positions = positions.data[this.position.productType];
          this.positionsByProductType = positions.data;
        }
        this.rowIndex = this.getRowIndex(this.position);
      });
  }

  public actionListHandler({actionType, row, moveTo}): void {
    if (actionType === PositionsActionsType.INFO) {
      this.actionButtonsService.displaySidebarHandler(row.productId);
      return;
    }
    this.movePosition(row.id, moveTo);
  }

  private movePosition(posId: number, moveTo: number): void {
    this.subscriptionApi.changePositionOrder(posId, moveTo)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.position = data;
        // this.loadPositions();
        this.getCurrentSubscription();
      });
  }

  public loadPositions(): void {
    this.subscriptionApi.getSubscriptionPositions(this.data.currentSubscription.id)
      .pipe(
        debounceTime(1000),
        takeUntil(this.destroy$)
      )
      .subscribe((response: ResponseModel<SalesPositionsByProductType>) => {
        if (response.data) {
          this.positions = response.data[this.position.productType];
        }
        this.rowIndex = this.getRowIndex(this.position);
      });
  }

  public getCurrentSubscription(): void {
    this.subscriptionApi.getSubscriptionById(this.data.currentSubscription.id).subscribe();
  }

  public getRowIndex(position): number {
    let rowIndex: number;
    this.positions.map((pos, index) => {
      if (pos.id === position.id) {
        rowIndex = index;
      }
    });
    return rowIndex;
  }

  private preparePosition(position: SalesPosition) {
      return {
        ...position,
        productName: `${position.description} ${!!position.productDescription ? '\n' + position.productDescription : ''}`,
        productNameServices: `${position.description} ${!!position.providedServices ? '\n' + position.providedServices : ''}`,
        providedWithin: this.getProvidedWithinValue(position),
        quantityWithUnitType: {
          value: position.quantity,
          unitType: this.unitTypeTranslatePipe.transform(position.unitType)
        },
        marginData: {
          profit: position.marginNet,
          percent: position.marginRate,
          currency: '€'
        },
      };
  }

  private getProvidedWithinValue(pos: SalesPosition): string {
    if (!pos.providedFrom) {
      return '-';
    }

    const providedFrom: string = this.datePipe.transform(pos.providedFrom, 'dd.MM.yy');
    const providedTo: string = pos.providedTo ? '-' + this.datePipe.transform(pos.providedTo, 'dd.MM.yy') : '';

    return `${providedFrom}${providedTo}`;
  }

  private getPositionsCards(): void {
    if (this.data.currentSubscription) {
      this.positionsCardInfoByTypes[this.position.productType] = generatePositionsCardsInfo(
        this.translate,
        this.position.productType,
        UIStatesEnum.CREATE,
        this.data.currentSubscription.status,
        this.data.currentSubscription.properties.vatDisabled || this.data.currentSubscription.properties.smallBusiness
      );
    }
  }

  public deletePositionDialog(): void {
    const dialog = this.dialog.open(DangerModalComponent, {
      data: REMOVE_POSITION_MODAL_DATA,
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === CommonModalsActionsEnum.CONFIRM) {
        this.deletePositions([this.position.id]);
      }
    });
  }

  private deletePositions(ids: number[]): void {
    this.subscriptionApi.deleteSubscriptionPositions(this.data.currentSubscription.id, ids)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.loadPositions();
        this.getCurrentSubscription();
        this.dialogRef.close();
      });
  }

  public trackByProp(index: number, item: PositionInfoListModel) {
    return item.prop;
  }

  public displayConditionsHandler(position, conditionsList: DisplayConditionsModel[]): boolean {
    if (!conditionsList && !conditionsList.length) { return false; }

    return conditionsList.every(condition => {
      return condition.values.some((val) => {
        if (!!condition.productType) {
          return position[condition.prop] === val && condition.productType === position.productTypeForGrouping;
        } else {
          return position[condition.prop] === val;
        }
      });
    });
  }

  public updatePosition(cell: string, value: string): void {
    const field = {
      fieldName: cell,
      fieldValue: value
    };

    this.subscriptionApi.updateSubscriptionPosition(this.position.id, field, this.data.currentSubscription.id)
      // .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.position = this.preparePosition(response.data);
        this.getCurrentSubscription();
      }, () => {
        if (cell === 'unitType') {
          this.redrawControls();
        }
      });
  }

  public redrawControls(): void {
    this.showControls = false;
    setTimeout(() => this.showControls = true);
  }

  public updateDatePicker(event, fieldName): void {
    this.updatePosition(event.value ? new Date(event.value).toLocaleDateString('fr-CA') : null, fieldName);
  }

  public displayProductInfo(): void {
    this.actionButtonsService.displaySidebarHandler(this.position.productId);
  }

  public changePosition({position, positions}): void {
    this.position = this.preparePosition(position);
    this.positions = positions;
    this.getPositionsCards();
    this.getCurrentRowIndex();
    this.getProductUnits();
    this.redrawControls();
  }

  public getCurrentRowIndex(): void {
    this.positionsByProductType[this.position.productType].map((pos, index) => {
      if (pos.order === this.position.order) {
        this.rowIndex = index;
      }
    });
  }

  get allowFractionalValues(): boolean {
    if (!this.position.unitType || !this.unitsListSource.length) { return true; }
    const selectedUnit: ProductUnitModel = this.unitsListSource
      .find((itm: ProductUnitModel) => itm.name === this.position.unitType);
    const isFractionalQuantity: boolean = !!(this.position.quantity % 1);
    return (selectedUnit && !isFractionalQuantity)
      ? selectedUnit.allowFractionalValues
      : isFractionalQuantity;
  }

  get isGoods(): boolean {
    return this.position.productType === ProductTypes.GOODS && !this.position.general;
  }

}
