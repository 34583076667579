import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { finalize, catchError, map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { flattenDeep } from 'lodash';

import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { FileUploadParams } from 'common/src/models/file-upload-params.model';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { ResponseModel, ResponseList } from '../../shared/models/response';
import { SummaryInvoiceModel } from '../models/summary-invoice.model';
import {
  InvoiceCountersModel,
  // LinkedDocumentsTotals,
  OutgoingInvoiceListTotalsModel,
  OutgoingInvoiceModel, OutgoingInvoiceOverdueNoticeModel,
  OutgoingInvoicePositionModel,
  PositionsModel,
  // PositionsTotals,
  UpdatePositionsModel
} from '../models';
import {
  LoadOutgoingInvoice,
  UpdateOutgoingInvoiceState,
  UpdateOutgoingInvoiceSummary,
  LoadOutgoingInvoicesList,
  UpdateShouldRefreshEntity,
  IncrementLoadingRequestsCount,
  UpdateOutgoingInvoiceUpdatedAt,
  DecrementLoadingRequestsCount,
  // UpdateOutgoingPositionsTotals,
  LoadOutgoingInvoicePositions,
} from '../store/actions/outgoing-invoice.actions';
import { AppState } from '../../store/state/app.state';
import { OutgoingPaymentModel } from '../models/payment.model';
import { ChangeStatusOperationsEnum, OinTypeEnum, OutgoingInvoiceListTabsEnum } from '../enums';
import { FilterModelNew } from '../models/filter-model-new';
import { EditModel } from '../models/edit.model';
import { InvoiceCheck } from '../../sales-order/models/invoice-check.model';
// import { UpdateSalesOrderExistingInvoice } from '../../sales-order/store/actions/sales-order.actions';
import { UIStatesEnum, PaginationModel, PredefinedDocumentDataModel, CustomerTypeEnum } from 'common/src/models';
import { PaymentModel } from '../../payment/models/payment.model';
import { DisplayToaster } from '../../shared/decorators/toaster';
import { environment } from 'projects/workspace/src/environments/environment';
import { CommonModalsActionsEnum, InfoModalComponent, WarningModalComponent } from 'common/src/modules/modals/modals-common';
import { getAnotherUserEditErrorModalData } from 'common/src/modules/modals/modals-common/common-modal.config';
import { EraQuantityMismatchModalData } from '../pages/outgoing-invoice/outgoing-invoice.config';
import { PurchaseOrder } from '../../purchase-order/models';
import { ProductModel } from 'common/src/modules/products';

@Injectable({
  providedIn: 'root'
})
export class InvoiceApiService {
  private readonly apiEndpoint: string = `${environment.javaApiVersion}/invoices/outgoing`; // todo: refactor

  private apiUrl(url: string = ''): string {
    return this.apiEndpoint + url;
  }

  constructor(
    private readonly http: HttpClient,
    private readonly dialog: MatDialog,
    private readonly store: Store<AppState>,
    private readonly toasterService: ToasterService,
    private readonly translateService: TranslateService,
  ) { }

  public createOutgoingInvoicePredefined(params?: PredefinedDocumentDataModel): Observable<OutgoingInvoiceModel> {
    return this.http.post<ResponseModel<OutgoingInvoiceModel>>(this.apiUrl('/create-predefined'), params)
      .pipe(
        map((data: ResponseModel<OutgoingInvoiceModel>) => data.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public createOutgoingInvoiceBlank(): Observable<OutgoingInvoiceModel> {
    return this.http.post<ResponseModel<OutgoingInvoiceModel>>(this.apiUrl('/blank'), {})
      .pipe(
        map((data: ResponseModel<OutgoingInvoiceModel>) => data.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public createOutgoingInvoiceDraft(invoice: OutgoingInvoiceModel): Observable<OutgoingInvoiceModel> {
    return this.http.post<ResponseModel<OutgoingInvoiceModel>>(this.apiUrl('/create'), invoice)
      .pipe(
        tap((response: ResponseModel<OutgoingInvoiceModel>) => {
          this.store.dispatch(LoadOutgoingInvoice({ outgoingInvoice: response.data }))
        }),
        map((data: ResponseModel<OutgoingInvoiceModel>) => data.data)
      );
  }

  public cloneOutgoingInvoice(invoiceId: number | string): Observable<OutgoingInvoiceModel> {
    return this.http.request<ResponseModel<OutgoingInvoiceModel>>('post', `${this.apiEndpoint}/${invoiceId}/clone`)
      .pipe(
        tap((response: ResponseModel<OutgoingInvoiceModel>) => {
          this.store.dispatch(LoadOutgoingInvoice({ outgoingInvoice: response.data }));
          this.store.dispatch(UpdateOutgoingInvoiceState({ currentState: UIStatesEnum.CREATE }));
        }),
        map((response: ResponseModel<OutgoingInvoiceModel>) => response.data)
      );
  }

  public forceCompleteOutgoingInvoice(invoiceId: number): Observable<OutgoingInvoiceModel> {
    return this.http.request<ResponseModel<OutgoingInvoiceModel>>('patch', `${this.apiEndpoint}/${invoiceId}/force`)
      .pipe(
        tap((response: ResponseModel<OutgoingInvoiceModel>) => {
          this.store.dispatch(LoadOutgoingInvoice({ outgoingInvoice: response.data }));
        }),
        map((response: ResponseModel<OutgoingInvoiceModel>) => response.data)
      );
  }

  public cancelForceCompleteOutgoingInvoice(invoiceId: number): Observable<OutgoingInvoiceModel> {
    return this.http.request<ResponseModel<OutgoingInvoiceModel>>('patch', `${this.apiEndpoint}/${invoiceId}/cancel-force`)
      .pipe(
        tap((response: ResponseModel<OutgoingInvoiceModel>) => {
          this.store.dispatch(LoadOutgoingInvoice({ outgoingInvoice: response.data }));
        }),
        map((response: ResponseModel<OutgoingInvoiceModel>) => response.data)
      );
  }

  public proceedOutgoingInvoice(invoiceId: number, skipOFRQtyValidation: boolean): Observable<OutgoingInvoiceModel> {
    const params = new HttpParams()
      .set('skipOFRQtyValidation', skipOFRQtyValidation.toString());

    return this.http.get<ResponseModel<OutgoingInvoiceModel>>(this.apiUrl(`/open/${invoiceId}`), {params})
      .pipe(
        tap((response: ResponseModel<OutgoingInvoiceModel>) => {
          this.store.dispatch(LoadOutgoingInvoice({ outgoingInvoice: response.data }))
        }),
        map((data: ResponseModel<OutgoingInvoiceModel>) => data.data)
      );
  }

  public createPayment(invoiceId: number): Observable<OutgoingPaymentModel> {
    return this.http.post<ResponseModel<OutgoingPaymentModel>>(this.apiUrl(`/payments/${invoiceId}/book`), {})
      .pipe(
        map((data: ResponseModel<OutgoingPaymentModel>) => data.data)
      );
  }

  public loadPaymentsList(invoiceId: number): Observable<OutgoingPaymentModel[]> {
    return this.http.get<ResponseModel<OutgoingPaymentModel[]>>(this.apiUrl(`/${invoiceId}/payments`))
      .pipe(
        map((data: ResponseModel<OutgoingPaymentModel[]>) => data.data)
      );
  }

  public getOutgoingInvoiceList(
    status: OutgoingInvoiceListTabsEnum,
    pagination: PaginationModel,
    sort: FilterModelNew,
    filters: any = {}
  ): Observable<ResponseList<OutgoingInvoiceModel>> {

    const body = {
      status: status.toUpperCase(),
      page: pagination.page,
      sortBy: sort.sortBy,
      direction: sort.direction,
      length: pagination.per_page,
    };

    for (const [key, value] of Object.entries(filters)) {
      if (key === 'soId') {
        // ['1', '2, 3, 4'] => ['1', '2', '3', '4'],
        body[key] = flattenDeep((value as string[]).map(itm => itm.split(',')))
      } else {
        body[key] = value;
      }
    }

    return this.http.post<ResponseList<OutgoingInvoiceModel>>(this.apiUrl('/list'), body)
      .pipe(tap((data: ResponseList<OutgoingInvoiceModel>) => {
        this.store.dispatch(LoadOutgoingInvoicesList({
          outgoingInvoiceListData: {
            [data.pagination.page]: {
              pagination: data.pagination, sort,
              data: data.data
            }
          },
          status, page: data.pagination.page
        }));
      }));
  }

  getOutgoingInvoicesFilterRunpleIds(wid: number): Observable<any[]> {
    const params = {
      wid: wid.toString()
    };
    return this.http.get<ResponseModel<any[]>>(`${this.apiEndpoint}/filter/rids`, { params })
      .pipe(map((response: ResponseModel<any[]>) => response.data));
  }

  getOutgoingInvoicesFilterGeneralProducts(status: OutgoingInvoiceListTabsEnum): Observable<any[]> {
    return this.http.get<ResponseModel<any[]>>(`${this.apiEndpoint}/filter/general-products/${status}`)
      .pipe(map((response: ResponseModel<any[]>) => response.data));
  }

  public getOutgoingInvoiceListTotals(status: OutgoingInvoiceListTabsEnum, filters: any = {}): Observable<OutgoingInvoiceListTotalsModel> {
    const params = {};
    for (const [key, value] of Object.entries(filters)) {
      params[key] = Array.isArray(value) ? value.join(',') : value.toString();
    }
    return this.http.get<ResponseModel<OutgoingInvoiceListTotalsModel>>(`${this.apiEndpoint}/totals/${status}`, {params})
      .pipe(map((data: ResponseModel<OutgoingInvoiceListTotalsModel>) => data.data));
  }

  public getOutgoingInvoiceCounters(): Observable<InvoiceCountersModel> {
    return this.http.get<ResponseModel<InvoiceCountersModel>>(this.apiUrl('/count/by-status'))
      .pipe(map((data: ResponseModel<InvoiceCountersModel>) => data.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public getSummaryInfo(invoiceId: number): Observable<SummaryInvoiceModel> {
    return this.http.get<ResponseModel<SummaryInvoiceModel>>(this.apiUrl(`/summary/${invoiceId}`))
    .pipe(
      tap((response: ResponseModel<SummaryInvoiceModel>) => {
        this.store.dispatch(UpdateOutgoingInvoiceSummary({outgoingInvoiceSummary: response.data as SummaryInvoiceModel}));
      }),
      map((data: ResponseModel<SummaryInvoiceModel>) => data.data)
    );
  }

  // public createOutgoingInvoicePosition(
  //   invoiceId: number,
  //   position: OutgoingInvoicePositionModel
  // ): Observable<OutgoingInvoicePositionModel> {
  //   return this.http.post<ResponseModel<OutgoingInvoicePositionModel>>(this.apiUrl(`/positions/${invoiceId}/create`), position)
  //     .pipe(
  //       map((data: ResponseModel<OutgoingInvoicePositionModel>) => data.data),
  //       catchError(error => {
  //         this.handlePopupErrors(error, invoiceId);
  //         return throwError(error);
  //       })
  //     );
  // }

  public createPositionByPredefinedForm(
    invoiceId: number,
    position: OutgoingInvoicePositionModel
  ): Observable<OutgoingInvoicePositionModel> {
    return this.http.post<ResponseModel<OutgoingInvoicePositionModel>>(this.apiUrl(`/${invoiceId}/positions-predefined`), position)
      .pipe(
        map((data: ResponseModel<OutgoingInvoicePositionModel>) => data.data),
        catchError(error => {
          this.handlePopupErrors(error, invoiceId);
          return throwError(error);
        })
      );
  }

  public addCreditPosition(
    invoiceId: number,
    position: { description: string, amountNet: number }
  ): Observable<OutgoingInvoicePositionModel> {
    return this.http.post<ResponseModel<OutgoingInvoicePositionModel>>(this.apiUrl(`/positions/${invoiceId}/credit`), position)
      .pipe(
        map((data: ResponseModel<OutgoingInvoicePositionModel>) => data.data),
        catchError(error => {
          this.handlePopupErrors(error, invoiceId);
          return throwError(error);
        })
      );
  }

  // public createOutgoingInvoicePositionFromRecord(invoiceId: number, recordId: number, position: OutgoingInvoicePositionModel): Observable<OutgoingInvoicePositionModel> {
  //   return this.http.post<ResponseModel<OutgoingInvoicePositionModel>>(this.apiUrl(`/${invoiceId}/position/of-record/${recordId}`), position)
  //     .pipe(
  //       map((data: ResponseModel<OutgoingInvoicePositionModel>) => data.data),
  //       catchError(error => {
  //         this.handlePopupErrors(error, invoiceId);
  //         return throwError(error);
  //       })
  //     );
  // }

  public getOutgoingInvoicePositionFromRecordProto(invoiceId: number, recordId: number): Observable<OutgoingInvoicePositionModel> {
    const params = new HttpParams().set('invoiceId', invoiceId.toString()).set('recordId', recordId.toString());
    return this.http.get<ResponseModel<OutgoingInvoicePositionModel>>(`${environment.javaApiVersion}/positions/proto/record`, {params})
      .pipe(
        map((data: ResponseModel<OutgoingInvoicePositionModel>) => data.data),
        catchError(error => {
          this.handlePopupErrors(error, invoiceId);
          return throwError(error);
        })
      );
  }

  // public addCorrectionPosition(invoiceId: number, positionId: number): Observable<OutgoingInvoicePositionModel> {
  //   return this.http.request<ResponseModel<OutgoingInvoicePositionModel>>('post', this.apiUrl(`/${invoiceId}/positions/${positionId}/correction`))
  //     .pipe(
  //       map((data: ResponseModel<OutgoingInvoicePositionModel>) => data.data),
  //       catchError(error => {
  //         this.handlePopupErrors(error, invoiceId);
  //         return throwError(error);
  //       })
  //     );
  // }

  public getOutgoingInvoicePositions(invoiceId: number): Observable<PositionsModel> {
    return this.http.get<ResponseModel<PositionsModel>>(this.apiUrl(`/positions/${invoiceId}/get`))
      .pipe(
        map((data: ResponseModel<PositionsModel>) => data.data),
        tap((positions: PositionsModel) => {
          this.store.dispatch(LoadOutgoingInvoicePositions({positions: positions}))
        })
      );
  }

  // public getOutgoingInvoicePositionsTotals(invoiceId: number): Observable<PositionsTotals> {
  //   return this.http.get<ResponseModel<PositionsTotals>>(this.apiUrl(`/${invoiceId}/positions/totals`))
  //     .pipe(
  //       tap((response: ResponseModel<PositionsTotals>) => {
  //         this.store.dispatch(UpdateOutgoingPositionsTotals({outgoingPositionsTotals: response.data}));
  //       }),
  //       map((data: ResponseModel<PositionsTotals>) => data.data)
  //     );
  // }

  public deleteOutgoingInvoicePositions(invoiceId: number, ids: number[], hasLinkedOFR, confirmed = false): Observable<PositionsModel> {
    const body = { ids };
    const params = { confirmed: confirmed as any };
    return this.http.request<ResponseModel<PositionsModel>>(
      'delete',
      this.apiUrl(`/positions/${invoiceId}/delete`),
      {body, params}
    ).pipe(
      map((data: ResponseModel<PositionsModel>) => data.data),
      catchError(error => {
        this.handlePopupErrors(error, invoiceId, { ids, hasLinkedOFR });
        return throwError(error);
      })
    );
  }

  changePositionOrder(posId: number, moveToOrder: number, invoiceId: number): any {
    const body = {
      orderPosition: moveToOrder,
      id: posId,
    }
    return this.http.patch(this.apiUrl(`/${invoiceId}/positions/${posId}/order/${moveToOrder}`), body)
      .pipe(
        map((data: ResponseModel<PurchaseOrder>) => data.data),
        catchError(error => {
          this.showMsg('error', error.error.message);
          return throwError(error);
        })
      );
  }

  public updateOutgoingInvoicePosition(
    invoiceId: number,
    updatePosition: UpdatePositionsModel
  ): Observable<OutgoingInvoicePositionModel> {
    return this.http.post<ResponseModel<OutgoingInvoicePositionModel>>(
      this.apiUrl(`/positions/${invoiceId}/update`),
      updatePosition
    )
      .pipe(
        map((data: ResponseModel<OutgoingInvoicePositionModel>) => data.data),
        catchError(error => {
          this.handlePopupErrors(error, invoiceId);
          return throwError(error);
        })
      );
  }

  public getPositionsCount(invoiceId: number): Observable<number> {
    return this.http.get(this.apiUrl(`/positions/${invoiceId}/count`))
      .pipe(map((data: ResponseModel<number>) => data.data));
  }

  public getAvailablePositionsFromSO(invoiceId: number, wid: number): Observable<ProductModel[]> {
    const params = {
      wid: wid.toString()
    };
    return this.http.get<ResponseModel<ProductModel[]>>(this.apiUrl(`/${invoiceId}/products/available`), { params })
      .pipe(
        map((data: ResponseModel<ProductModel[]>) => data.data),
      );
  }

  public getAvailablePrepaymentPositions(salesOrderId: number, wid: number, type: OinTypeEnum): Observable<any[]> {
    const params = { type };
    return this.http.get<ResponseModel<any[]>>(
      `${environment.javaApiVersion}/workspaces/${wid}/prepayment/sales-order/${salesOrderId}/positions`,
      { params }
    )
      .pipe(
        map((data: ResponseModel<any[]>) => data.data),
      );
  }

  public getPaymentsCount(invoiceId: number): Observable<number> {
    return this.http.get(this.apiUrl(`/${invoiceId}/payments/count`))
      .pipe(map((data: ResponseModel<number>) => data.data));
  }

  public getInvoicesExportParams(oinId: number, oinOnly = true): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: `${environment.javaApiVersion}/pdf/oin?id=${oinId}&oin-only=${oinOnly}`,
      type: 'pdf',
    };
    return of(fileParams);
  }

  public getOutgoingInvoiceListExport(title: string, status: string): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: `${this.apiEndpoint}/download?status=${status}`,
      type: 'zip',
      title,
    };
    return of(fileParams);
  }

  public getOutgoingInvoiceListExportAll(title: string): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: `${this.apiEndpoint}/download/all`,
      type: 'zip',
      title,
    };
    return of(fileParams);
  }

  public updateInvoice(invoiceData: OutgoingInvoiceModel): Observable<OutgoingInvoiceModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.post<ResponseModel<OutgoingInvoiceModel>>(this.apiUrl(`/update`), invoiceData)
      .pipe(
        tap((response: ResponseModel<OutgoingInvoiceModel>) => {
          this.store.dispatch(LoadOutgoingInvoice({ outgoingInvoice: response.data }));
          this.store.dispatch(UpdateOutgoingInvoiceUpdatedAt({ updatedAt: new Date() }));
        }),
        map((data: ResponseModel<OutgoingInvoiceModel>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, invoiceData.invoiceId);
          return throwError(error);
        })
      );
  }

  public updateOutgoingInvoiceField(invoiceId: number, fieldName: string, fieldValue: any): Observable<OutgoingInvoiceModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    const body = { fieldName, fieldValue };

    return this.http.patch<ResponseModel<OutgoingInvoiceModel>>(`${this.apiEndpoint}/${invoiceId}/field-update`, body)
      .pipe(
        tap((response: ResponseModel<OutgoingInvoiceModel>) => {
          this.store.dispatch(LoadOutgoingInvoice({ outgoingInvoice: response.data }));
          this.store.dispatch(UpdateOutgoingInvoiceUpdatedAt({ updatedAt: new Date() }));
        }),
        map((data: ResponseModel<OutgoingInvoiceModel>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, invoiceId);
          return throwError(error);
        })
      );
  }

  @DisplayToaster({ showErrorMessage: true })
  public getOutgoingInvoiceById(invoiceId: number): Observable<OutgoingInvoiceModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.get<ResponseModel<OutgoingInvoiceModel>>(this.apiUrl(`/get/${invoiceId}`))
      .pipe(
        tap((response: ResponseModel<OutgoingInvoiceModel>) => {
          this.store.dispatch(UpdateOutgoingInvoiceUpdatedAt({ updatedAt: new Date() }));
          this.store.dispatch(LoadOutgoingInvoice({ outgoingInvoice: response.data }));
          this.store.dispatch(UpdateShouldRefreshEntity({ isShouldRefresh: false }));
        }),
        map((data: ResponseModel<OutgoingInvoiceModel>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  @DisplayToaster({ showErrorMessage: true })
  public linkOutgoingInvoiceWithSalesOrder(invoiceId: number, soId: number): Observable<OutgoingInvoiceModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<OutgoingInvoiceModel>>('patch', `${this.apiEndpoint}/${invoiceId}/link/${soId}`)
      .pipe(
        tap((response: ResponseModel<OutgoingInvoiceModel>) => {
          this.store.dispatch(UpdateOutgoingInvoiceUpdatedAt({ updatedAt: new Date() }));
          this.store.dispatch(LoadOutgoingInvoice({ outgoingInvoice: response.data }));
        }),
        map((response: ResponseModel<OutgoingInvoiceModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  @DisplayToaster({ showErrorMessage: true })
  public unlinkOutgoingInvoiceWithSalesOrder(invoiceId: number): Observable<OutgoingInvoiceModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<OutgoingInvoiceModel>>('patch', `${this.apiEndpoint}/${invoiceId}/unlink`)
      .pipe(
        tap((response: ResponseModel<OutgoingInvoiceModel>) => {
          this.store.dispatch(UpdateOutgoingInvoiceUpdatedAt({ updatedAt: new Date() }));
          this.store.dispatch(LoadOutgoingInvoice({ outgoingInvoice: response.data }));
        }),
        map((response: ResponseModel<OutgoingInvoiceModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  @DisplayToaster({ showErrorMessage: true })
  public unlinkOutgoingInvoiceWithTimeTrackingRecord(invoiceId: number, recordsIds: number[]): Observable<OutgoingInvoiceModel> {
    const body = { ids: recordsIds };
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.post<ResponseModel<OutgoingInvoiceModel>>(`${this.apiEndpoint}/${invoiceId}/records/unlink`, body)
      .pipe(
        tap((response: ResponseModel<OutgoingInvoiceModel>) => {
          this.store.dispatch(UpdateOutgoingInvoiceUpdatedAt({ updatedAt: new Date() }));
          this.store.dispatch(LoadOutgoingInvoice({ outgoingInvoice: response.data }));
        }),
        map((response: ResponseModel<OutgoingInvoiceModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  @DisplayToaster({ showErrorMessage: true })
  public getOutgoingInvoicesAvailableForTimeTracking(partnerId: number, partnerType: CustomerTypeEnum): Observable<OutgoingInvoiceModel[]> {
    const requestParams = new HttpParams()
      .set('pid', partnerId.toString())
      .set('type', partnerType);

    return this.http.get<ResponseModel<OutgoingInvoiceModel[]>>(`${this.apiEndpoint}/records/invoice-list`, {params: requestParams})
      .pipe(
        map((response: ResponseModel<OutgoingInvoiceModel[]>) => response.data)
      );
  }

  public changeOutgoingInvoicesStatus(
    invoiceIds: number[],
    operation: ChangeStatusOperationsEnum,
    status: OutgoingInvoiceListTabsEnum,
    proceed = false
  ): Observable<any> {
    const params = new HttpParams()
      .set('proceed', proceed.toString());

    const body = { invoiceIds, operation, status };
    return this.http.post<ResponseModel<any>>(this.apiUrl(`/batch/update-status`), body, { params });
  }

  public deleteOutgoingInvoiceDraft(invoiceId: number): Observable<OutgoingInvoiceModel> {
    return this.http.delete<ResponseModel<OutgoingInvoiceModel>>(this.apiUrl(`/delete/${invoiceId}`))
      .pipe(
        tap((response: ResponseModel<OutgoingInvoiceModel>) => {
          if (response.data) {
            this.store.dispatch(LoadOutgoingInvoice({ outgoingInvoice: response.data }));
          }
        }),
        map((data: ResponseModel<OutgoingInvoiceModel>) => data.data)
      );
  }

  public createInvoiceFromSO(salesOrderId: string | number, invoiceType: OinTypeEnum = OinTypeEnum.REGULAR): Observable<InvoiceCheck> {
    const params = new HttpParams().set('type', invoiceType);

    return this.http.request<ResponseModel<InvoiceCheck>>('put', this.apiUrl(`/based-on/${salesOrderId}`), { params })
    .pipe(map((data: ResponseModel<InvoiceCheck>) => data.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public createInvoiceFromTO(offerId: string | number, invoiceType: OinTypeEnum = OinTypeEnum.REGULAR): Observable<InvoiceCheck> {
    const params = new HttpParams().set('type', invoiceType);

    return this.http.request<ResponseModel<InvoiceCheck>>('put', this.apiUrl(`/based-on/offer/${offerId}`), { params })
      .pipe(map((data: ResponseModel<InvoiceCheck>) => data.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public createInvoiceFromERA(eraId: number): Observable<InvoiceCheck> {
    return this.http.request<ResponseModel<InvoiceCheck>>('put', this.apiUrl(`/based-on/era/${eraId}`))
      .pipe(map((data: ResponseModel<InvoiceCheck>) => data.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public createBillingDocumentFromERA(eraId: number): Observable<any> {
    return this.http.request<ResponseModel<any>>('post', `${environment.javaApiVersion}/integration/based-on/era/${eraId}`)
      .pipe(map((response: ResponseModel<any>) => response.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public createInvoicePayment(invoiceId: string | number): Observable<PaymentModel> {
    const requestParams = new HttpParams()
      .set('id', invoiceId.toString())
      .set('type', DocumentTypesUppercaseEnum.OIN as string);

    return this.http.request<ResponseModel<PaymentModel>>('post', `${environment.javaApiVersion}/payments/based-on`, {params: requestParams})
      .pipe(map((data: ResponseModel<PaymentModel>) => data.data));
  }

  // @DisplayToaster({showErrorMessage: true})
  // public allocatePayment(invoiceId: string | number): Observable<PaymentModel> {
  //   return this.http.request<ResponseModel<PaymentModel>>('post', this.apiUrl(`/${invoiceId}/allocate-payment`))
  //     .pipe(map((data: ResponseModel<PaymentModel>) => data.data));
  // }

  // public getInvoiceFromSO(salesOrderId: string | number): Observable<InvoiceCheck> {
  //   return this.http.request<ResponseModel<InvoiceCheck>>('get', this.apiUrl(`/based-on/${salesOrderId}`))
  //   .pipe(
  //     tap((response: ResponseModel<InvoiceCheck>) => {
  //       this.store.dispatch(UpdateSalesOrderExistingInvoice({isInvoiceExist: !!response.data}));
  //     }),
  //     map((data: ResponseModel<InvoiceCheck>) => data.data)
  //   );
  // }

  public editOutgoingInvoice(oinId: number, force = false, proceed = false): Observable<EditModel> {
    const params = new HttpParams()
      .set('force', force.toString())
      .set('proceed', proceed.toString());

    return this.http.get<EditModel>(`accounting/v1/utils/edit-flag/${DocumentTypesUppercaseEnum.OIN}/${oinId}/check-and-set`, {params})
      .pipe(
        tap(() => {
          this.store.dispatch(UpdateOutgoingInvoiceState({currentState: UIStatesEnum.EDIT}));
        }),
        catchError(error => {
          this.handlePopupErrors(error, oinId);
          return throwError(error);
        })
      );
  }

  public commitOutgoingInvoice(oinId: number, forced = false): Observable<EditModel> {
    const params = new HttpParams()
      .set('forced', forced.toString());

    return this.http.get<EditModel>(`accounting/v1/utils/edit-flag/${DocumentTypesUppercaseEnum.OIN}/${oinId}/commit`, {params})
      .pipe(
        tap(response => {
          this.store.dispatch(UpdateOutgoingInvoiceState({currentState: UIStatesEnum.VIEW}));
        }),
        catchError(error => {
          this.handlePopupErrors(error, oinId);
          return throwError(error);
        })
      );
  }

  // public validateOutgoingInvoice(invoiceId: number): Observable<OutgoingInvoiceModel> {
  //   return this.http.get<OutgoingInvoiceModel>(this.apiUrl(`/validate/${invoiceId}`))
  //     .pipe(
  //       catchError(error => {
  //         this.handlePopupErrors(error, invoiceId);
  //         return throwError(error);
  //       })
  //     );
  // }

  // public assignCustomerBillingAddress(invoiceId: number, customerId: number): Observable<OutgoingInvoiceModel> {
  //   return this.http.patch<ResponseModel<OutgoingInvoiceModel>>(this.apiUrl(`/${invoiceId}/assign-address/${customerId}`), {})
  //     .pipe(
  //       tap((response: ResponseModel<OutgoingInvoiceModel>) => {
  //         this.store.dispatch(LoadOutgoingInvoice({ outgoingInvoice: response.data }));
  //       }),
  //       map((data: ResponseModel<OutgoingInvoiceModel>) => data.data),
  //       catchError(error => {
  //         this.handlePopupErrors(error, invoiceId);
  //         return throwError(error);
  //       })
  //     );
  // }

  // @DisplayToaster({showErrorMessage: true})
  // public sendOutgoingInvoiceEmail(outgoingInvoiceId: number): Observable<any> {
  //   return this.http.post(this.apiUrl(`/${outgoingInvoiceId}/email`), {});
  // }

  // public getOutgoingInvoiceLinkedDocumentsTotals(invoiceId: number): Observable<LinkedDocumentsTotals> {
  //   return this.http.get<ResponseModel<LinkedDocumentsTotals>>(`${this.apiEndpoint}/${invoiceId}/linked/totals`)
  //     .pipe(
  //       map((data: ResponseModel<LinkedDocumentsTotals>) => data.data)
  //     );
  // }

  public getOutgoingInvoiceOverdueNotices(invoiceId: number): Observable<OutgoingInvoiceOverdueNoticeModel[]> {
    return this.http.get<ResponseModel<OutgoingInvoiceOverdueNoticeModel[]>>(`${this.apiEndpoint}/${invoiceId}/overdue-notices`)
      .pipe(
        map((data: ResponseModel<OutgoingInvoiceOverdueNoticeModel[]>) => data.data)
      );
  }

  public reCheckVatStatus(invoiceId: number | string): Observable<OutgoingInvoiceModel> {
    return this.http.request<ResponseModel<OutgoingInvoiceModel>>('post', `${this.apiEndpoint}/${invoiceId}/vat-status`)
      .pipe(
        tap((response: ResponseModel<OutgoingInvoiceModel>) => {
          this.store.dispatch(LoadOutgoingInvoice({ outgoingInvoice: response.data }));
        }),
        map((response: ResponseModel<OutgoingInvoiceModel>) => response.data)
      );
  }

  @DisplayToaster({ showErrorMessage: true })
  public linkPayment(invoiceId: number, paymentId: number): Observable<any[]> {
    return this.http.get<ResponseModel<any[]>>(`${this.apiEndpoint}/${invoiceId}/payments/${paymentId}/link`)
      .pipe(
        map((data: ResponseModel<any[]>) => data.data)
      );
  }

  public getPaymentsAvailable(invoiceId: number): Observable<PaymentModel[]> {
    return this.http.get<ResponseModel<PaymentModel[]>>(`${this.apiEndpoint}/${invoiceId}/payments/available`)
      .pipe(
        map((data: ResponseModel<PaymentModel[]>) => data.data)
      );
  }

  getOutgoingInvoiceFilterIBANs(): Observable<string[]> {
    return this.http.get<ResponseModel<string[]>>(`${this.apiEndpoint}/filter/ibans`)
      .pipe(
        map((response: ResponseModel<string[]>) => {
          return response.data
            .filter(itm => !!itm)
            .sort((a, b) => a.localeCompare(b));
        })
      );
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  private handlePopupErrors(
    error: HttpErrorResponse,
    oinId?: string | number,
    params?: {
      ids?: number[],
      hasLinkedOFR?: boolean
    }
  ): void {
    if (error.error.errors && error.error.errors.length) {
      error.error.errors.forEach(errorText => {
        switch (errorText) {
          case 'anotherUserEditError':
          {
            const dialog = this.dialog.open(WarningModalComponent, {
              data: getAnotherUserEditErrorModalData(
                {
                  document: error.error.data.entityName,
                  userName: error.error.data.userName,
                },
                this.translateService
              ),
              backdropClass: 'backdrop-none',
            });

            dialog.afterClosed().subscribe(res => {
              if (res === CommonModalsActionsEnum.CONFIRM) {
                this.editOutgoingInvoice(+oinId, true).subscribe();
                this.getOutgoingInvoiceById(+oinId).subscribe();
              }
            });
          }
          break;
          case 'confirmationAfterPositionDeleteIsRequired':
            {
              const dialog = this.dialog.open(WarningModalComponent, {
                data: {
                  title: 'POSITIONS.REMOVE_POSITION',
                  message: (params && params.hasLinkedOFR) ? 'POSITIONS.REMOVE_POSITION_OIN_OFR_MSG' : 'POSITIONS.REMOVE_POSITION_OIN_MSG',
                  confirmBtnText: 'BUTTON.CONTINUE',
                  confirmBtnIcon: 'trash-2'
                }
              });

              dialog.afterClosed().subscribe(res => {
                if (res === CommonModalsActionsEnum.CONFIRM) {
                  this.deleteOutgoingInvoicePositions(+oinId, params.ids, params.hasLinkedOFR, true)
                    .pipe(tap(() => this.getOutgoingInvoiceById(+oinId).subscribe()))
                    .subscribe();
                }
              });
            }
            break;
          case 'The document has been sent to the accountant, if you edit it, you will need to re-send the document to the accountant. Proceed?':
          {
            const dialog = this.dialog.open(WarningModalComponent, {
              data: {
                title: 'COMMON.DOC_SENT_TO_ACCOUNTANT',
                message: 'COMMON.DOC_SENT_TO_ACCOUNTANT_MSG',
                confirmBtnText: 'BUTTON.EDIT',
                confirmBtnIcon: 'edit'
              }
            });

            dialog.afterClosed().subscribe(res => {
              if (res === CommonModalsActionsEnum.CONFIRM) {
                this.editOutgoingInvoice(+oinId, false, true).subscribe();
                this.getOutgoingInvoiceById(+oinId).subscribe();
              }
            });
          }
          break;
          case 'notEditModeError':
            const documentName = this.translateService.instant('APP.OUTGOING_INVOICE');
            this.showMsg('warning', this.translateService.instant('COMMON.DOC_UPDATED_BY_USER', { document: documentName }));
            this.store.dispatch(UpdateOutgoingInvoiceState({currentState: UIStatesEnum.VIEW}));
            this.getOutgoingInvoiceById(+oinId).subscribe();
            break;
          case 'Mismatched quantity.':
          case 'Die Menge stimmt nich überein.':
            this.dialog.open(InfoModalComponent, {
              data: EraQuantityMismatchModalData
            });
            break;
          case 'mismatchOFRtoOINQuantity':
            // skip this error
          //   this.dialog.open(WarningModalComponent, {
          //     data: {
          //       title: 'OIN.OIN_OFR_MISMATCH_QUANTITY_TTL',
          //       message: 'OIN.OIN_OFR_MISMATCH_QUANTITY_MSG',
          //       confirmBtnIcon: 'arrow-right',
          //       confirmBtnText: 'BUTTON.CONTINUE',
          //     }
          //   })
          //     .afterClosed()
          //     .subscribe(res => {
          //       if (res === CommonModalsActionsEnum.CONFIRM) {
          //         this.commitOutgoingInvoice(+oinId, true);
          //       }
          //     });
            break;
          default:
            this.showMsg('error', errorText);
            break;
        }
      });
    }
  }

}
