import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({name: 'unitTypeTranslatePipe'})
export class UnitTypeTranslatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {
  }
  transform(value: string, translate: boolean = true, ...args: any[]): string {
    if (!value) { return this.translateService.instant('FORM.PCS'); }

    let translateKey: string;

    switch (value.toLowerCase()) {
      case 'pcs':
        translateKey = 'FORM.PCS';
        break;
      case 'h':
        translateKey = 'UNITS.H';
        break;
      case 'min':
        translateKey = 'UNITS.MIN';
        break;
      case 'pd':
        translateKey = 'UNITS.PD';
        break;
      case 'd':
        translateKey = 'UNITS.D';
        break;
      case 'l.s.':
        translateKey = 'UNITS.LS';
        break;
    }

    if (translateKey && translate) {
      return this.translateService.instant(translateKey);
    }

    return translateKey || value;
  }
}
