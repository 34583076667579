import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { get } from 'lodash';

import { ProductTypes } from 'common/src/modules/products/product-types';
import { BaseFormComponent } from 'projects/workspace/src/app/crm/partner-forms/components/base-form.component';
import { FormInputChangedModel } from 'projects/workspace/src/app/shared/models/form-input-value.model';

@Component({
  selector: 'rnpl-create-product-form-name',
  templateUrl: './create-product-form-name.component.html'
})
export class CreateProductFormNameComponent extends BaseFormComponent implements OnInit, OnChanges, OnDestroy {
  public nameControlData = {
    [ProductTypes.GOODS]: {
      label: 'COLUMN.PRODUCT_NAME',
      placeholder: 'FORM.ENTER_PRODUCT_NAME'
    },
    [ProductTypes.SERVICES]: {
      label: 'COLUMN.SERVICE_NAME',
      placeholder: 'FORM.ENTER_SERVICE_NAME'
    },
    [ProductTypes.DIGITAL]: {
      label: 'FORM.DGT_PRODUCT_NAME',
      placeholder: 'FORM.ENTER_DGT_PRODUCT_NAME'
    },
  };

  public subscriptions: Subscription[] = [];

  @Input() productData: any;
  @Input() productType: ProductTypes;
  // @Input() readonly: boolean = false;
  @Input() counterAnimation: boolean = false;
  // @Input() set dynamicFormCollectiveValue(value: string) {
  //   this.dynamicFormCollectiveValue$ = of(value);
  // }

  @Output() fieldChanged: EventEmitter<FormInputChangedModel> = new EventEmitter<FormInputChangedModel>();

  public readonly productTypes = ProductTypes;
  public controlId = Math.floor(Math.random() * 1000);

  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // private dynamicFormCollectiveValue$: Observable<string> = new Observable();
  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    this.updateFormEditingStatus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('readonly')) {
      this.updateFormEditingStatus();
    }
    if (changes.hasOwnProperty('productData') || changes.hasOwnProperty('productType')) {
      this.initForm();
    }
    // if (changes.hasOwnProperty('dynamicFormCollectiveValue')) {
    //   this.formSubscriptions();
    // }
  }

  public initForm(): void {
    this.form = this.fb.group({
      name: [get(this.productData, 'name') || null, Validators.required],
      description: [get(this.productData, 'description') || null],
      unitType: [get(this.productData, 'unitType', 'pcs')],
      generatedDescription: [get(this.productData, 'generatedDescription') || false],
    });

    // if (this.productType === ProductTypes.GOODS) {
    //   const manufacturerControl = new FormControl(get(this.productData, 'manufacturer') || null);
    //   const modelControl = new FormControl(get(this.productData, 'model') || null);
    //   const basicColorControl = new FormControl(get(this.productData, 'basicColor') || null);
    //
    //   this.form.addControl('manufacturer', manufacturerControl);
    //   this.form.addControl('model', modelControl);
    //   this.form.addControl('basicColor', basicColorControl);
    // }
    //
    // this.formSubscriptions();
  }

  // public formSubscriptions(): void {
  //   if (!this.form) { return; }
  //   this.subscriptions.forEach(sub => sub.unsubscribe());
  //   let collectiveDescriptionSub$: Observable<string> = of('');
  //
  //   if (this.productType === ProductTypes.GOODS) {
  //     collectiveDescriptionSub$ = this.form.valueChanges
  //       .pipe(
  //         startWith(this.form.value),
  //         // get only required attrs
  //         map(({manufacturer, model, basicColor}) => ({manufacturer, model, basicColor})),
  //         map(form => {
  //           let collectiveValue = '';
  //           if (form.manufacturer) {
  //             collectiveValue += `Hersteller - ${form.manufacturer}`;
  //           }
  //           if (form.model) {
  //             if (collectiveValue) { collectiveValue += ' '; }
  //             collectiveValue += `Modell - ${form.model}`;
  //           }
  //           if (form.basicColor) {
  //             if (collectiveValue) { collectiveValue += ' '; }
  //             collectiveValue += `Farbe - ${form.basicColor}`;
  //           }
  //           return collectiveValue;
  //         }),
  //         takeUntil(this.destroy$)
  //       );
  //   }
  //
  //   const descriptionValueSubs$ = combineLatest([
  //     collectiveDescriptionSub$,
  //     this.dynamicFormCollectiveValue$
  //   ])
  //     .pipe(
  //       filter(() => this.generatedDescriptionControl.value),
  //       map(([hardcoded, dynamic]) => ({hardcoded, dynamic})),
  //       filter(val => !!val.hardcoded || !!val.dynamic),
  //       map(val => {
  //         if (val.hardcoded && val.dynamic) {
  //           return `${val.hardcoded} ${val.dynamic}`;
  //         }
  //         return val.hardcoded || val.dynamic;
  //       }),
  //       takeUntil(this.destroy$)
  //     )
  //     .subscribe(val => this.descriptionControl.patchValue(val, {emitEvent: false}));
  //
  //   const generatedDescriptionSubs$ = this.generatedDescriptionControl.valueChanges
  //     .pipe(
  //       startWith(this.generatedDescriptionControl.value),
  //       takeUntil(this.destroy$)
  //     )
  //     .subscribe(val => {
  //       if (val) {
  //         this.descriptionControl.disable({emitEvent: false});
  //       } else if (!this.readonly) {
  //         this.descriptionControl.enable({emitEvent: false});
  //       }
  //     });
  //
  //   this.subscriptions.push(generatedDescriptionSubs$);
  //   this.subscriptions.push(descriptionValueSubs$);
  // }

  public getFormData(): any {
    const formData = this.form.getRawValue();
    return {...formData};
  }

  public updateField(fieldValue: any, fieldName: string): void {
    this.fieldChanged.emit({fieldName, fieldValue});
  }

  get nameControl(): FormControl { return this.form.get('name') as FormControl; }
  get descriptionControl(): FormControl { return this.form.get('description') as FormControl; }
  // get generatedDescriptionControl(): FormControl { return this.form.get('generatedDescription') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
