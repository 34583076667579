import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { WarehouseActiveItemsResolver } from '../warehouse/resolvers/warehouse-active-items.resolver';
import { AuthGuard } from 'common/src/auth/auth.guard';
import {
  ReturnStockStatusListComponent,
  WarehousePreferencesComponent,
  WarehouseInformationComponent,
  WarehouseMainComponent,
  WarehouseProfileComponent,
  StockStatusListComponent,
  WarehouseBinLocationsComponent
} from './pages';
import { BinLocationsTabsEnum } from './enums';
import { InitialSetupGuard } from 'common/src/modules/initial-setup/initial-setup.guard';

const routes: Routes = [
  {
    path: '',
    component: WarehouseMainComponent,
    resolve: {
      warehouses: WarehouseActiveItemsResolver,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'warehouse-profile',
      },
      {
        path: 'outgoing-delivery',
        canLoad: [AuthGuard, InitialSetupGuard],
        loadChildren: () => import('./../delivery-note/delivery-note.module').then(m => m.DeliveryNoteModule),
      },
      {
        path: 'incoming-delivery',
        canLoad: [AuthGuard, InitialSetupGuard],
        loadChildren: () => import('./modules/incoming-delivery/incoming-delivery.module').then(m => m.IncomingDeliveryModule),
      },
      {
        path: 'transactions',
        canLoad: [AuthGuard, InitialSetupGuard],
        loadChildren: () => import('./modules/transactions/transactions.module').then(m => m.TransactionsModule),
      },
      {
        path: 'stocktaking',
        canLoad: [AuthGuard, InitialSetupGuard],
        loadChildren: () => import('./modules/stocktaking/stocktaking.module').then(m => m.StocktakingModule),
      },
      {
        path: 'settings',
        canLoad: [AuthGuard, InitialSetupGuard],
        loadChildren: () => import('./warehouse-settings-module/warehouse-settings.module').then(m => m.WarehouseSettingsModule),
      },
      {
        path: 'warehouse-profile',
        component: WarehouseProfileComponent,
        data: {
          title: 'Warehouse Profile',
        },
        children: [
          {
            path: '',
            redirectTo: 'address-and-contacts',
            pathMatch: 'full',
          },
          {
            path: 'address-and-contacts',
            component: WarehouseInformationComponent,
            data: {
              title: 'WAREHOUSE.ADDRESS_AND_CONTACTS',
            },
          },
          {
            path: 'bin-locations',
            data: {
              title: 'WAREHOUSE.BIN_LOCATIONS',
            },
            children: [
              {
                path: '',
                redirectTo: `${BinLocationsTabsEnum.GENERAL_STOCK}/1`,
                pathMatch: 'full',
              },
              {
                path: ':location/:page',
                component: WarehouseBinLocationsComponent,
              },
            ]
          },
          {
            path: 'preferences',
            component: WarehousePreferencesComponent,
            data: {
              title: 'DOC_BLOCK.PREFERENCES',
            },
          },
        ],
      },
      {
        path: 'stock-status',
        children: [
          {
            path: '',
            redirectTo: 'general-stock/1',
            pathMatch: 'full'
          },
          {
            path: 'general-stock',
            redirectTo: 'general-stock/1',
            pathMatch: 'full'
          },
          {
            path: '',
            redirectTo: 'returned-stock/1',
            pathMatch: 'full'
          },
          {
            path: 'general-stock/:page',
            component: StockStatusListComponent,
            data: {
              title: 'Stock status'
            }
          },
          {
            path: 'returned-stock/:page',
            component: ReturnStockStatusListComponent,
            data: {
              title: 'Return stock status'
            }
          }
        ]
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WarehouseNewRoutingModule {}
