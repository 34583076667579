import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslatesApiService } from '../../services';

@Pipe({
  name: 'translateIn',
})
export class TranslateInPipe implements PipeTransform {
  constructor(
    private translate: TranslateService,
    private translatesApiService: TranslatesApiService,
  ) {}

  transform(value: string, lang: string): string {
    return !lang
      ? this.translate.instant(value)
      : this.translatesApiService.getTranslationValue(value, lang) || this.translate.instant(value);
  }
}
