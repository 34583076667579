import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { get, isEmpty } from 'lodash';
import { Store } from '@ngrx/store';
import { map, takeUntil } from 'rxjs/operators';

import { selectCompanyProfile, selectValidations } from 'projects/workspace/src/app/administration/store/selectors';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { AdministrationsApiService } from 'projects/workspace/src/app/administration/services/administrations-api.service';
import { ValidationService } from 'projects/workspace/src/app/shared/services/validation.service';
import { ResponseModel, Validation } from 'projects/workspace/src/app/shared/models/response';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { InitialSetupBaseComponent } from '../initial-setup-base/initial-setup-base.component';

@Component({
  selector: 'rnpl-contact-info',
  templateUrl: './contact-info.component.html'
})
export class ContactInfoComponent extends InitialSetupBaseComponent implements OnInit, OnDestroy {

  public validations$: Observable<Validation[]> = this.store.select(selectValidations);

  public form: FormGroup = new FormGroup({});

  constructor(
    public readonly store: Store<AppState>,
    private readonly fb: FormBuilder,
    public router: Router,
    public validationService: ValidationService,
    public readonly administrationsApiService: AdministrationsApiService,
  ) {
    super(store, router, administrationsApiService);
  }

  ngOnInit() {
    this.initForm();

    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((companyProfile: CompanyProfile) => {
        if (companyProfile && !isEmpty(companyProfile)) {
          this.form.patchValue(companyProfile, {emitEvent: false});
        }
      });

    this.validations$
      .pipe(takeUntil(this.destroy$))
      .subscribe(validations => {
        this.validationService.renderServerErrors(this.form, validations);
      });
  }

  public initForm(companyProfile: CompanyProfile = {} as any): void {
    this.form = this.fb.group({
      email: [get(companyProfile, 'email'), {validators: [Validators.email], updateOn: 'blur'}],
      phone: [get(companyProfile, 'phone'), {validators: [], updateOn: 'blur'}],
      website: [get(companyProfile, 'website'), {validators: [], updateOn: 'blur'}],
    });
  }

  public getStepValidation(): boolean {
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    return this.form.valid;
  }

  public updateStep$(): Observable<boolean | CompanyProfile> {
    if (!this.getStepValidation) {
      return of(false);
    }
    const formValue = this.form.getRawValue();
    const company = {
      ...this.companyProfile,
      ...formValue,
    };

    return this.administrationsApiService.updateCompanyProfile(company)
      .pipe(map((data: ResponseModel<CompanyProfile>) => data.data));
  }

  get email() { return this.form.get('email') as FormControl; }
  get phone() { return this.form.get('phone') as FormControl; }
  get website() { return this.form.get('website') as FormControl; }



  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
