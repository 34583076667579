import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, ReplaySubject, throwError } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';


import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { AdministrationsApiService } from 'projects/workspace/src/app/administration/services/administrations-api.service';
import { ToasterService } from '../../../ui-components/toaster';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { StripePaymentService } from 'projects/workspace/src/app/shared/services';
import { environment } from 'projects/workspace/src/environments/environment';


@Component({
  selector: 'rnpl-delete-account-modal',
  templateUrl: './delete-account-modal.component.html',
})
export class DeleteAccountModalComponent implements OnDestroy {

  public form: FormGroup = this.fb.group({
    password: ['', [Validators.required]],
  });
  public passErrorText: string = null;

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  readonly submitRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    public dialogRef: MatDialogRef<DeleteAccountModalComponent>,
    public fb: FormBuilder,
    public toasterService: ToasterService,
    public store: Store<AppState>,
    public stripePaymentService: StripePaymentService,
    public administrationsApiService: AdministrationsApiService,
  ) {}

  public submit(): void {
    if (this.submitRequest$.getValue()) { return; }

    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.invalid) { return; }

    this.submitRequest$.next(true);

    this.stripePaymentService.deleteAccount(this.passwordControl.value)
      .pipe(
        finalize(() => this.submitRequest$.next(false)),
        catchError((error: HttpErrorResponse) => {
          const errorsList: string[] = error.error.errors;
          const passError = error.error.messages.find(err => err.name === 'password');
          if (passError) {
            this.passwordControl.setErrors({ passwordNotCorrect: true });
            this.passErrorText = passError.message;
            errorsList.splice(passError.message);
          }
          this.toasterService.notify({type: 'error', message: errorsList});
          return throwError(error);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((idKey: string) => {
          if (environment.production) {
            window.open(`https://runple.com/survey?id=${idKey}`);
          } else {
            window.open(`https://dev.runple.com/survey?id=${idKey}`);
          }
      });
  }

  get passwordControl(): FormControl { return this.form.get('password') as FormControl; }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
