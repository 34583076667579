import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';

import { ProductVariationsControlComponent } from './product-variations-control.component';
import { RnplCommonModule } from 'common/src/modules';
import { NumberFormatPipeModule } from '../../pipes/number-format-pipe/number-format-pipe.module';
import { SafePipeModule } from 'safe-pipe';

@NgModule({
  declarations: [
    ProductVariationsControlComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    TranslateModule,
    RnplCommonModule,
    NumberFormatPipeModule,
    SafePipeModule
  ],
  exports: [
    ProductVariationsControlComponent,
  ],
  entryComponents: [ProductVariationsControlComponent],
})
export class ProductVariationsControlModule { }
