import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { get } from 'lodash';

import { FormInputChangedModel } from '../../models/form-input-value.model';
import { BankAccountModel } from '../../../bank-accounts/models';
import { BankAccountsApiService } from '../../../bank-accounts/services/bank-accounts-api.service';
import { AppState } from '../../../store/state/app.state';
import { selectAccountingSettings } from '../../../store/selectors/shared.selectors';
import { AccountingSettingsModel } from '../../../accounting/accounting-settings-module/models';

@Component({
  selector: 'rnpl-bank-account-document-form',
  templateUrl: './bank-account-document-form.component.html',
})
export class BankAccountDocumentFormComponent implements OnChanges, OnDestroy {
  public workspaceCurrency: string = 'EUR';

  @Input() label: string = 'BANK_ACCOUNTS.BANK_ACCOUNT';
  @Input() bankAccountControl: FormControl = new FormControl();
  @Input() ibanControl: FormControl = new FormControl();
  @Input() bicControl: FormControl = new FormControl();
  @Input() filterByWorkspaceCurrency: boolean = false;
  @Input() showValidations: boolean = false;
  @Input() hideIban: boolean = false;
  @Input() hideBic: boolean = false;
  @Input() bindBankValue: string = null;
  @Input() bankAccountUserDefined: boolean;
  @Input() bankAccountName: string;

  @Output() bankChanged: EventEmitter<FormInputChangedModel> = new EventEmitter<FormInputChangedModel>();
  @Output() fieldChanged: EventEmitter<FormInputChangedModel> = new EventEmitter<FormInputChangedModel>();

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public bankAccounts$: Observable<BankAccountModel[]> = this.bankAccountsApiService.getActiveBankAccounts()
    .pipe(
      // map((bankAccounts: BankAccountModel[]) => bankAccounts.filter((bankAccount: BankAccountModel) => !bankAccount.editMode)),
      map((bankAccounts: BankAccountModel[]) => {
        return bankAccounts.filter((bankAccount: BankAccountModel) => {
          if (this.filterByWorkspaceCurrency) {
            return bankAccount.currencyCode === this.workspaceCurrency;
          }
          return true
        })
      }),
      finalize(() => this.showDropdownSpin$.next(false))
    );

  constructor(
    private store: Store<AppState>,
    private bankAccountsApiService: BankAccountsApiService
  ) {
    this.store.select(selectAccountingSettings)
      .pipe(takeUntil(this.destroy$))
      .subscribe((settings: AccountingSettingsModel) => this.workspaceCurrency = settings.currencyCode);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (
        changes.hasOwnProperty('bankAccountUserDefined') ||
        changes.hasOwnProperty('bankAccountControl') ||
        changes.hasOwnProperty('bankAccountName')
      ) {
        if (this.bankAccountUserDefined || (this.bankAccountName && !this.bankAccountControl.value)) {
          this.bankAccountControl.patchValue(
            this.bindBankValue === 'id' ? 0 : {},
            {emitEvent: false}
          );
        }

        if (!this.bankAccountUserDefined) {
          this.ibanControl.disable({emitEvent: false});
          this.bicControl.disable({emitEvent: false});
        }
      }
    }
  }

  public updateBank(fieldValue: any, fieldName: string): void {
    this.bankChanged.emit({fieldName, fieldValue});
  }

  public updateField(fieldValue: any, fieldName: string): void {
    this.fieldChanged.emit({fieldName, fieldValue});
  }

  bankSearchFn(term: string, bankAccount: BankAccountModel) {
    term = term.toLocaleLowerCase();
    const searchedProps = [
      get(bankAccount, 'accountName'),
      get(bankAccount, 'institution.name'),
      get(bankAccount, 'bic'),
      get(bankAccount, 'iban'),
    ];
    return searchedProps.some(prop => prop && prop.toLocaleLowerCase().indexOf(term) > -1)
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
