export enum FiltersControlsNamesEnum {
  DATE_PERIODS = 'DATE_PERIODS',
  DATES_RANGE = 'DATES_RANGE',
  DELIVERY_TYPE = 'DELIVERY_TYPE',
  BASE_DROPDOWN = 'BASE_DROPDOWN',
  PARTNER = 'PARTNER',
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  EMPLOYEE = 'EMPLOYEE',
  PRODUCT = 'PRODUCT',
  PRODUCT_CATEGORY = 'PRODUCT_CATEGORY',
  PRODUCT_TYPE = 'PRODUCT_TYPE',
  DROPDOWN_OBSERVABLE_STRING = 'DROPDOWN_OBSERVABLE_STRING',
  LINKED_DOCUMENT = 'LINKED_DOCUMENT',
  COUNTRY = 'COUNTRY',
  TOGGLE = 'TOGGLE',
}
