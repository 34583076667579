import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatMenuModule } from '@angular/material';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FileSaverService } from 'ngx-filesaver';
import { NgxMaskModule } from 'ngx-mask';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import {
  TableModule,
  UIComponentsModule,
  WizardModule,
  RnplCommonModule,
  UsersCommonModule
} from 'common/src/modules';
// import { WarehouseRoutingModule } from './warehouse-routing.module';
import { WarehouseNewRoutingModule } from './warehouse-new-routing.module';
import {
  ReturnStockStatusListComponent,
  WarehouseBinLocationsComponent
} from './pages';
import {
  StockAddressesService,
  BinLocationService
} from './services';
import { WarehouseListComponent } from './pages/warehouse-list/warehouse-list.component';
import { WarehouseGeneralInfoComponent } from './pages/warehouse-general-info/warehouse-general-info.component';
import { WarehouseFormStocksComponent } from './pages/warehouse-form-stocks/warehouse-form-stocks.component';
import { PartnerFormsModule } from '../crm/partner-forms';
import { MetricConversionPipe } from 'common/src/modules/rnpl-common';
import { WarehouseActiveItemsResolver } from './resolvers/warehouse-active-items.resolver';
import { SearchService } from './services/search-service';
import * as fromWarehouse from './store/reducers';
import { SidebarModule } from '../shared/components/sidebar/sidebar.module';
import { FileService } from 'common/src/services/file.service';
import { CommonAddressFormModule } from '../shared/forms/common-address-form/common-address-form.module';
import { FiltersModule } from 'common/src/modules/filters';
import {
  WarehouseProfileComponent,
  WarehouseMainComponent,
  WarehousePreferencesComponent,
  StockStatusListComponent,
  WarehouseInformationComponent
} from './pages';
import { UserSortingService } from '../shared/services';
import { StockStatusMegaHintComponent } from './components/stock-status-mega-hint/stock-status-mega-hint.component';
import { BinLocationMegaHintComponent } from './components/bin-location-mega-hint/bin-location-mega-hint.component';
import { ColumnsSettingsModule } from '../shared/components/columns-settings/columns-settings.module';
import { DocumentListContainerModule } from '../shared/components/document-list-container/document-list-container.module';

@NgModule({
  declarations: [
    WarehouseMainComponent,
    WarehouseProfileComponent,
    WarehouseListComponent,
    WarehouseGeneralInfoComponent,
    WarehouseFormStocksComponent,
    WarehouseInformationComponent,
    WarehousePreferencesComponent,
    StockStatusListComponent,
    ReturnStockStatusListComponent,
    WarehouseBinLocationsComponent,
    StockStatusMegaHintComponent,
    BinLocationMegaHintComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // WarehouseRoutingModule,
    WarehouseNewRoutingModule,
    UIComponentsModule,
    TableModule,
    WizardModule,
    NgSelectModule,
    UIComponentsModule,
    RnplCommonModule,
    PartnerFormsModule,
    MatMenuModule,
    UsersCommonModule,
    NgxMaskModule.forRoot(),
    StoreModule.forFeature(
      fromWarehouse.WarehouseFeatureKey,
      {
        entity: fromWarehouse.WarehouseReducer,
        list: fromWarehouse.StockStatusListReducer,
        binLocationsList: fromWarehouse.BinLocationsListReducer,
      }
    ),
    EffectsModule.forFeature([]),
    SidebarModule,
    CommonAddressFormModule,
    FiltersModule,
    ColumnsSettingsModule,
    TranslateModule,
    DocumentListContainerModule,
    PerfectScrollbarModule,
  ],
  providers: [
    StockAddressesService,
    FileService,
    FileSaverService,
    SearchService,
    MetricConversionPipe,
    WarehouseActiveItemsResolver,
    DecimalPipe,
    DatePipe,
    BinLocationService,
    UserSortingService
  ],
  exports: [
    WarehouseFormStocksComponent,
  ],
  bootstrap: []
})
export class WarehouseModule { }
