import { NavBarBtnModel } from '../../models';

export const CONNECT_BANK_ACTIONS: NavBarBtnModel[] = [
  {
    classes: 'btn-grey',
    text: 'OPEN_BANKING.CONNECT_BANK',
    iconName: 'link',
    disabled: false,
    multiple: false,
    actionName: 'connectBank',
    spinnerColor: '#5E6681',
    dropdownClass: 'inner-blue-400',
    width: 144,
    actions: [
      {
        actionTitle: 'OPEN_BANKING.CONNECT_BANK',
        actionName: 'connectBank',
        actionIcon: 'link'
      },
      {
        actionTitle: 'CARDLESS_BA.CREATE_ACCOUNT_MANUALLY',
        actionName: 'createNewBankAccount',
        actionIcon: 'plus-square',
      }
    ],
  }
];

export const WAREHOUSE_ACTIONS: NavBarBtnModel[] = [
  {
    classes: 'btn-grey',
    text: 'ONBOARDING.WAREHOUSE_GOODS',
    iconName: 'warehousing',
    disabled: false,
    multiple: false,
    actionName: 'warehouseGoods',
    spinnerColor: '#5E6681',
    dropdownClass: 'inner-blue-400',
    width: 144,
    actions: [
      {
        actionTitle: 'ONBOARDING.WAREHOUSE_GOODS',
        actionName: 'warehouseGoods',
        actionIcon: 'warehousing'
      },
      {
        actionTitle: 'ONBOARDING.SKIP_STEP',
        actionName: 'skipWarehouseStep',
        actionIcon: 'close',
      }
    ],
  }
];

export const SET_UP_PDF_ACTIONS: NavBarBtnModel[] = [
  {
    classes: 'btn-grey',
    text: 'ONBOARDING.SET_UP_PDF_DESIGN_SHORT',
    iconName: 'pdf-designer',
    disabled: false,
    multiple: false,
    actionName: 'setUpPdf',
    spinnerColor: '#5E6681',
    dropdownClass: 'inner-blue-400',
    width: 144,
    actions: [
      {
        actionTitle: 'ONBOARDING.SET_UP_PDF_DESIGN_SHORT',
        actionName: 'setUpPdf',
        actionIcon: 'pdf-designer'
      },
      {
        actionTitle: 'ONBOARDING.SKIP_STEP',
        actionName: 'skipPdfStep',
        actionIcon: 'close',
      }
    ],
  }
];

export const DELIVERY_SERVICES_ACTIONS: NavBarBtnModel[] = [
  {
    classes: 'btn-grey',
    text: 'ONBOARDING.CONNECT_SERVICE',
    iconName: 'link',
    disabled: false,
    multiple: false,
    actionName: 'connectService',
    spinnerColor: '#5E6681',
    dropdownClass: 'inner-blue-400',
    width: 144,
    actions: [
      {
        actionTitle: 'ONBOARDING.CONNECT_SERVICE',
        actionName: 'connectService',
        actionIcon: 'link'
      },
      {
        actionTitle: 'ONBOARDING.SKIP_STEP',
        actionName: 'skipDeliveryServiceStep',
        actionIcon: 'close',
      }
    ],
  }
];

export const IMPORT_DATA_ACTIONS: NavBarBtnModel[] = [
  {
    classes: 'btn-grey',
    text: 'DATA_IMPORT.IMPORT_DATA',
    iconName: 'cloud-upload',
    disabled: false,
    multiple: false,
    actionName: 'importData',
    spinnerColor: '#5E6681',
    dropdownClass: 'inner-blue-400',
    width: 144,
    actions: [
      {
        actionTitle: 'DATA_IMPORT.IMPORT_DATA',
        actionName: 'importData',
        actionIcon: 'cloud-upload'
      },
      {
        actionTitle: 'ONBOARDING.SKIP_STEP',
        actionName: 'skipImportDataStep',
        actionIcon: 'close'
      },
    ],
  }
];
