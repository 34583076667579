import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { ProductTypes } from '../../products/product-types';
import {
  ActionsReorderingPositionModel,
  ControlTypeEnum,
  DisplayConditionsModel,
  PositionInfoListModel,
  PositionItemTypeEnum
} from '../position-card-models.model';
import { PositionsActionsType, TableActivateTypes } from '../../ui-components/table/custom-table.enums';
import { PurposeTypeEnum } from 'projects/workspace/src/app/purchase-order/enums';
import { takeUntil } from 'rxjs/operators';
import { ProductUnitModel } from 'projects/workspace/src/app/shared/models';
import { ProductUnitApiService } from 'projects/workspace/src/app/shared/services';

@Component({
  selector: 'rnpl-position-card',
  templateUrl: './position-card.component.html',
  styleUrls: ['./position-card.component.scss']
})
export class PositionCardComponent implements OnDestroy, OnChanges {
  public selectedPositionsList: any = {
    [ProductTypes.GOODS]: [],
    [ProductTypes.SERVICES]: [],
    [ProductTypes.DIGITAL]: [],
  };

  public unitsList: string[] = [];
  public unitsListSource: ProductUnitModel[] = [];
  public openedAccountingSettingsIds: number[] = [];

  public readonly positionItemTypeEnum = PositionItemTypeEnum;
  public readonly tableActivateTypes = TableActivateTypes;
  public readonly controlTypeEnum = ControlTypeEnum;
  public readonly purposeType = PurposeTypeEnum;
  public readonly productTypes = ProductTypes;

  protected destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  @Input() public productType: ProductTypes;
  @Input() public positions: any[];
  @Input() public positionsCardInfo: PositionInfoListModel[];
  @Input() tableIndex: number;
  @Input() isReadOnly: boolean;
  @Input() disabledAccountingSettings: boolean;
  @Input() cardSelectionAllowed: boolean = true;
  @Input() hideSideBarToggle: boolean;
  @Input() skipInfoBtn: boolean;
  @Input() skipPositionActions: boolean;
  @Input() hideRemoveBtn: boolean;
  @Input() displayEditBtn: boolean;
  @Input() displayPackUpBtn: boolean;
  @Input() displayAddCorrectionPositionBtn: boolean;
  @Input() isSingleSelected: boolean;
  @Input() currency = 'EUR';
  @Input() currencyIsOriginal: boolean;
  @Input() isDocumentType: boolean;
  @Input() hideMainInfo: boolean;
  @Input() hasTimeTrackingIndicator: boolean;
  @Input() showManufacturerCode: boolean = false;
  @Input() showAccountingSettings: boolean = false;
  @Input() displayAssets: boolean = true;
  @Input() optionalTaxCode: boolean = false;
  @Input() optionalGlDebit: boolean = false;
  @Input() ignorePurpose: boolean = false;
  @Input() displayPurposeForm: boolean = false;
  @Input() privateUsageAllowed: boolean = false;
  @Input() showShipInfo: boolean = false;
  @Input() showBillInfo: boolean = false;
  @Input() showUnitOnly: boolean = false;
  @Input() generalProductChangeAllowed: boolean = false;

  @Input('selected') set selectedRowsSetter(rows: any) {
    this.selectedPositionsList = rows;
  }

  // @Input() selected: any;

  @Output() openPositionSideBar: EventEmitter<any> = new EventEmitter();
  @Output() editPosition: EventEmitter<any> = new EventEmitter();
  @Output() selectedRows: EventEmitter<any> = new EventEmitter();
  @Output() rowAction: EventEmitter<{position: any, actionType: TableActivateTypes | PositionsActionsType}> = new EventEmitter();

  @Output() fieldChanged: EventEmitter<any> = new EventEmitter();
  @Output() selectedPosition: EventEmitter<any> = new EventEmitter();

  @Output() public positionsActionList: EventEmitter<ActionsReorderingPositionModel> = new EventEmitter();

  constructor(private productUnitApiService: ProductUnitApiService) {
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.hasOwnProperty('productType') && this.productType) {
      this.getProductUnits();
    }
  }

  public getProductUnits(): void {
    this.productUnitApiService.getUnits$(this.productType)
      .pipe(takeUntil(this.destroy$))
      .subscribe((unitsList: ProductUnitModel[]) => {
        this.unitsListSource = unitsList;
        this.unitsList = unitsList.map(u => u.name);
      });
  }

  public allowFractionalValues(unitType: string, quantity: number): boolean {
    if (!unitType || !this.unitsListSource.length) { return true; }
    const selectedUnitType = this.unitsListSource.find((itm: ProductUnitModel) => itm.name === unitType);
    const isFractionalQuantity: boolean = quantity && !!(quantity % 1);
    return (selectedUnitType && !isFractionalQuantity)
      ? selectedUnitType.allowFractionalValues
      : true;
  }

  public isGoods(position): boolean {
    return position.productType === ProductTypes.GOODS && !position.general;
  }

  public onOpenSidebar(pos): void { this.openPositionSideBar.emit(pos); }
  public onEditPosition(position): void { this.editPosition.emit({position}); }

  public onSelect(selected, productType): void {
    if (this.isSingleSelected) {

      if (!this.selectedPositionsList.includes(selected)) {
        this.selectedPositionsList.push(selected);
      } else {
        this.selectedPositionsList = this.selectedPositionsList.filter(item => item.id !== selected.id);
      }
    } else {
        if (!this.selectedPositionsList[productType] || !this.selectedPositionsList[productType].includes(selected)) {
          this.selectedPositionsList[productType].push(selected);
        } else {
          this.selectedPositionsList[productType] = this.selectedPositionsList[productType]
            .filter(item => (item.id || item.positionId) !== (selected.id || selected.positionId));
        }
    }

    this.selectedPosition.emit(this.selectedPositionsList);
  }

  public isSelectedPosition(position): boolean {
    if (!this.selectedPositionsList) { return false; }

    if (!this.isSingleSelected) {
      if (!this.selectedPositionsList[position.productTypeForGrouping || position.productType]) { return false; }
      return this.selectedPositionsList[position.productTypeForGrouping || position.productType].includes(position);
    }
    return this.selectedPositionsList.includes(position);
  }

  public updateValueHandler(value, position, filedName, positionIndex): void {
    const data = {
      value: value,
      cell: filedName,
      rowIndex: positionIndex,
      row: position
    };

    this.fieldChanged.emit(data);
  }

  public accountingSettingsPanelChanged(isOpen: boolean, positionId: number): void {
    if (isOpen) {
      this.openedAccountingSettingsIds.push(positionId);
    } else {
      this.openedAccountingSettingsIds = this.openedAccountingSettingsIds.filter(id => id !== positionId);
    }
  }

  public isAccountingSettingsOpen(positionId: number): boolean {
    return this.openedAccountingSettingsIds.includes(positionId);
  }

  public updateDatePicker(event, position, fieldName, positionIndex): void {
    this.updateValueHandler(event.value ? new Date(event.value).toLocaleDateString('fr-CA') : null, position, fieldName, positionIndex);
  }

  public onRowAction(event: {position: any, actionType: TableActivateTypes | PositionsActionsType}): void {
    this.rowAction.emit(event);
  }

  public trackByProp(index: number, item: PositionInfoListModel) {
    return item.prop;
  }

  public trackByOrdered(index: number, position) {
    return position;
  }

  public displayConditionsHandler(position, conditionsList: DisplayConditionsModel[]): boolean {
    if (!conditionsList && !conditionsList.length) { return false; }

    return conditionsList.every(condition => {
      return condition.values.some((val) => {
        if (!!condition.productType) {
          return position[condition.prop] === val && condition.productType === position.productTypeForGrouping;
        } else {
          return position[condition.prop] === val;
        }
      });
    });
  }

  public columnActionHandler(positionData: ActionsReorderingPositionModel): void {
   const { actionType, rowIndex, row, moveTo, returnData } = positionData;

    this.positionsActionList.emit({
      rowIndex,
      actionType,
      row,
      moveTo,
      returnData
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
