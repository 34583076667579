import { NavBarBtnModel, UIStatesEnum } from 'common/src/models';
import { ProductTypes } from 'common/src/modules/products/product-types';
import { CreditNotePurposeEnum } from '../../enums';
import { VAT_LIST } from '../../../shared/constants/vat-list';
import { PaymentDirectionEnum } from '../../../payment/models/payment.model';
import {
  ControlTypeEnum,
  InputDropdownTypeEnum,
  PositionInfoListModel,
  PositionItemTypeEnum
} from 'common/src/modules/position-card/position-card-models.model';
import { PURPOSES_LIST } from '../../../trade/trade.constants';
import { ARBITRARY_POSITION_TYPES_LIST, CORRECTION_POSITION_TYPES_LIST } from '../../../accounting/accounting.constants';

export const ProductTypeTranslateKey = {
  [ProductTypes.GOODS]: 'APP.PRODUCT',
  [ProductTypes.SERVICES]: 'APP.SERVICE',
  [ProductTypes.DIGITAL]: 'APP.DIGITAL_PRODUCT'
}

export const PositionsButtons: NavBarBtnModel[] = [
  {
    classes: 'btn-white red',
    text: 'BUTTON.REMOVE',
    multipleText: 'BUTTON.REMOVE',
    badgeClass: 'rnpl-badge--grey',
    iconName: 'minus-circle',
    actionName: 'onDeletePositionsClick',
    multiple: false,
  },
];


export function generatePositionsCardsInfo(
  productType: ProductTypes,
  state: UIStatesEnum,
  crnPurpose: CreditNotePurposeEnum,
  direction: PaymentDirectionEnum,
  vatDisabled: boolean,
): PositionInfoListModel[] {
  const cantEditColumn = (state === UIStatesEnum.VIEW);

  let positionInfoList: PositionInfoListModel[] = [
    {
      label: 'COLUMN.TYPE',
      prop: 'productType',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.ARBITRARY_POSITION_TYPE,
      hideWithProductTypeGrouping: [ProductTypes.GOODS, ProductTypes.SERVICES, ProductTypes.DIGITAL, ProductTypes.CORRECTION, ProductTypes.OVERDUE_FEE],
      showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE]
    },
    {
      label: 'FORM.DESCRIPTION',
      prop: 'productDescription',
      isOptional: true,
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.TEXTAREA,
      hideWithProductType: [ProductTypes.SERVICES, ProductTypes.ARBITRARY, ProductTypes.CORRECTION, ProductTypes.OVERDUE_FEE],
      showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE],
      // showCondition: crnPurpose === CreditNotePurposeEnum.OIN_CORRECTION
      //   || (crnPurpose === CreditNotePurposeEnum.COMMON_CORRECTION && direction === PaymentDirectionEnum.OUTGOING),
    },
    {
      label: 'FORM.DESCRIPTION',
      prop: 'providedServices',
      isOptional: true,
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.TEXTAREA,
      // showCondition: crnPurpose === CreditNotePurposeEnum.OIN_CORRECTION
      //   || (crnPurpose === CreditNotePurposeEnum.COMMON_CORRECTION && direction === PaymentDirectionEnum.OUTGOING),
      hideWithProductType: [ProductTypes.GOODS, ProductTypes.DIGITAL, ProductTypes.ARBITRARY, ProductTypes.CORRECTION, ProductTypes.OVERDUE_FEE, ProductTypes.PREPAYMENT],
      showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE],
    },
    // {
    //   label: 'FORM.DESCRIPTION',
    //   prop: 'description',
    //   isOptional: true,
    //   type: PositionItemTypeEnum.WITH_CONTROL,
    //   controlType: ControlTypeEnum.TEXTAREA,
    //   showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE],
    //   hideWithProductType: [ProductTypes.GOODS, ProductTypes.DIGITAL, ProductTypes.SERVICES],
    // },
    {
      label: 'COLUMN.POSITION_NAME',
      prop: 'description',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.TEXTAREA,
      itemClass: 'col-12 p-0',
      showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE],
      hideWithProductType: [ProductTypes.GOODS, ProductTypes.DIGITAL, ProductTypes.SERVICES, ProductTypes.OVERDUE_FEE, ProductTypes.PREPAYMENT],
    },
    {
      label: 'COLUMN.NAME',
      prop: 'description',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.TEXTAREA,
      itemClass: 'col-12 p-0',
      disabled: true,
      showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE],
      hideWithProductType: [ProductTypes.GOODS, ProductTypes.DIGITAL, ProductTypes.SERVICES, ProductTypes.OVERDUE_FEE, ProductTypes.CORRECTION, ProductTypes.ARBITRARY,  ProductTypes.PREPAYMENT],
    },
    {
      label: 'FORM.SPECIFY_AMOUNTS_SEPARATELY',
      prop: 'specifyAmountsSeparately',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.TOGGLE,
      itemClass: 'col-12',
      isOptional: true,
      hideWithProductType: [ProductTypes.GOODS, ProductTypes.SERVICES, ProductTypes.DIGITAL, ProductTypes.ARBITRARY, ProductTypes.OVERDUE_FEE, ProductTypes.PREPAYMENT],
      showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE]
    },
    {
      label: 'COLUMN.TYPE',
      prop: cantEditColumn ? 'correctionTypeLabel' : 'correctionType',
      type: cantEditColumn ? PositionItemTypeEnum.PRIMARY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.DROPDOWN,
      listItems: CORRECTION_POSITION_TYPES_LIST,
      bindLabel: 'label',
      bindValue: 'value',
      disabled: direction === PaymentDirectionEnum.OUTGOING,
      hideWithProductType: [ProductTypes.GOODS, ProductTypes.SERVICES, ProductTypes.DIGITAL, ProductTypes.ARBITRARY, ProductTypes.OVERDUE_FEE, ProductTypes.PREPAYMENT],
    },
    {
      label: 'COLUMN.INVOICE_QUANTITY',
      prop: 'sourceQuantity',
      unitType: true,
      type: PositionItemTypeEnum.NUMBER,
      showCondition: (state !== UIStatesEnum.VIEW)
        && (crnPurpose === CreditNotePurposeEnum.OIN_CORRECTION || crnPurpose === CreditNotePurposeEnum.IIN_CORRECTION),
      hideWithProductType: [ProductTypes.CORRECTION, ProductTypes.PREPAYMENT],
    },
    {
      label: 'COLUMN.QUANTITY',
      prop: 'quantity',
      unitType: 'unitType',
      type: cantEditColumn ? PositionItemTypeEnum.NUMBER : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.NUMBER,
      hideWithProductType: [ProductTypes.CORRECTION, ProductTypes.PREPAYMENT],
    },
    {
      label: 'COLUMN.UNIT_PRICE_NET',
      prop: 'netUnitPrice',
      suffix: '€',
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      showCondition: crnPurpose === CreditNotePurposeEnum.COMMON_CORRECTION,
      hideWithProductType: [ProductTypes.CORRECTION],
      showUnitOnly: true,
    },
    {
      label: 'COLUMN.INVOICE_AMOUNT_NET',
      prop: 'sourceAmountNet',
      suffix: '€',
      type: PositionItemTypeEnum.CURRENCY,
      showCondition: (state !== UIStatesEnum.VIEW)
        && (crnPurpose === CreditNotePurposeEnum.OIN_CORRECTION || crnPurpose === CreditNotePurposeEnum.IIN_CORRECTION),
      hideWithProductType: [ProductTypes.CORRECTION],
    },
    {
      label: 'COLUMN.AMOUNT_NET',
      prop: 'netTotalPrice',
      currencyNegativeRow: true,
      suffix: '€',
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      showUnitOnly: true,
    },
    {
      label: 'COLUMN.VAT',
      prop: 'vat',
      suffix: '%',
      type: PositionItemTypeEnum.PRIMARY,
      showCondition: crnPurpose === CreditNotePurposeEnum.OIN_CORRECTION || crnPurpose === CreditNotePurposeEnum.IIN_CORRECTION,
      hideWithProductType: [ProductTypes.CORRECTION],
    },
    {
      label: 'COLUMN.VAT',
      prop: 'vat',
      suffix: '%',
      type: cantEditColumn ? PositionItemTypeEnum.PRIMARY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.DROPDOWN,
      listItems: VAT_LIST,
      bindLabel: 'label',
      bindValue: 'value',
      disabled: vatDisabled,
      vatBlocked: vatDisabled,
      showCondition: crnPurpose === CreditNotePurposeEnum.COMMON_CORRECTION || (productType === ProductTypes.PREPAYMENT),
      hideWithProductType: [ProductTypes.CORRECTION],
    },
    {
      label: 'COLUMN.VAT',
      prop: 'vat',
      suffix: '%',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.DROPDOWN,
      listItems: VAT_LIST,
      bindLabel: 'label',
      bindValue: 'value',
      disabled: true,
      vatBlocked: vatDisabled,
      showCondition: productType === ProductTypes.PREPAYMENT,
    },
    {
      label: 'COLUMN.INVOICE_AMOUNT_GROSS',
      prop: 'sourceAmountGross',
      suffix: '€',
      type: PositionItemTypeEnum.CURRENCY,
      showCondition: (state !== UIStatesEnum.VIEW)
        && (crnPurpose === CreditNotePurposeEnum.OIN_CORRECTION || crnPurpose === CreditNotePurposeEnum.IIN_CORRECTION),
      hideWithProductType: [ProductTypes.CORRECTION],
    },
    {
      label: 'COLUMN.PURPOSE',
      prop: 'purposeTranslate',
      type: PositionItemTypeEnum.PRIMARY,
      showCondition: crnPurpose === CreditNotePurposeEnum.IIN_CORRECTION,
      hideWithProductType: [ProductTypes.CORRECTION, ProductTypes.PREPAYMENT],
    },
    {
      label: 'COLUMN.UNIT_PRICE_GROSS',
      prop: 'grossUnitPrice',
      suffix: '€',
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      showCondition: crnPurpose === CreditNotePurposeEnum.COMMON_CORRECTION,
      hideWithProductType: [ProductTypes.CORRECTION],
      showUnitOnly: true,
    },
    {
      label: 'COLUMN.VAT',
      prop: 'vatTotal',
      suffix: '€',
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      currencyNegativeRow: true,
      currencySourceFormat: true,
      disabled: vatDisabled,
      vatBlocked: vatDisabled,
      showUnitOnly: true,
      hideWithProductType: [ProductTypes.SERVICES, ProductTypes.ARBITRARY, ProductTypes.GOODS, ProductTypes.DIGITAL, ProductTypes.OVERDUE_FEE, ProductTypes.PREPAYMENT],
    },
    {
      label: 'COLUMN.AMOUNT_GROSS',
      prop: 'grossTotalPrice',
      currencyNegativeRow: true,
      suffix: '€',
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      listItems: PURPOSES_LIST,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      valueClass: 'font-weight-500',
      showUnitOnly: true,
    },
    {
      label: 'COMMON.INTERNAL_REMARK_1',
      prop: 'remark',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.REMARK,
      bindValue: 'value',
      hideWithProductType: [ProductTypes.CORRECTION],
    },
  ];

  return positionInfoList
    .filter(itm => {
      if (itm.hasOwnProperty('showCondition')) {
        return itm.showCondition;
      }
      return true;
    })
    .filter((col: PositionInfoListModel) => {
      if (col.hideWithProductType) {
        return !col.hideWithProductType.includes(productType);
      }
      return true;
    }).filter((col: PositionInfoListModel) => {
      if (col.showWithUIState) {
        return col.showWithUIState.includes(state);
      }
      return true;
    });
}

export function generatePositionsCardsInfoArbitrary(state: UIStatesEnum): PositionInfoListModel[] {
  const cantEditColumn = (state === UIStatesEnum.VIEW);

  return [
    {
      label: 'COLUMN.TYPE',
      prop: 'productType',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.ARBITRARY_POSITION_TYPE,
      hideWithProductType: [ProductTypes.GOODS, ProductTypes.SERVICES, ProductTypes.DIGITAL, ProductTypes.CORRECTION, ProductTypes.OVERDUE_FEE],
    },
    {
      label: 'COLUMN.POSITION_NAME',
      prop: 'description',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT,
      itemClass: 'col-12 p-0',
    },
    {
      label: 'COLUMN.AMOUNT_GROSS',
      prop: 'grossTotalPrice',
      suffix: '€',
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      valueClass: 'font-weight-500',
      showUnitOnly: true,
    },
    {
      label: 'COMMON.INTERNAL_REMARK_1',
      prop: 'remark',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.REMARK,
      bindValue: 'value',
    },
  ].filter((col: PositionInfoListModel) => {
    if (col.showWithUIState) {
      return col.showWithUIState.includes(state);
    }
    return true;
  });
}

