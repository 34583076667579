import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

import { UIComponentsModule, RnplCommonModule } from 'common/src/modules';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PartnerFormComponent } from './partner-form.component';
import { CorporatePartnerFormModule } from '../../partner-forms/components/corporate-partner-form/corporate-partner-form.module';

@NgModule({
  declarations: [
    PartnerFormComponent,
  ],
  imports: [
    CommonModule,
    UIComponentsModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    RnplCommonModule,
    CorporatePartnerFormModule,
  ],
  exports: [
    PartnerFormComponent,
  ],
  providers: [
  ]
})

export class PartnerFormModule {
}
