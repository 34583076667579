import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';

import { AppState } from '../../store/state/app.state';
import { ResponseList, ResponseModel } from '../../shared/models';
import { environment } from 'projects/workspace/src/environments/environment';
import { FilterModelNew } from '../../outgoing-invoice/models/filter-model-new';
import {
  DecrementLoadingRequestsCount,
  IncrementLoadingRequestsCount,
  LoadCostCenter,
  LoadCostCenterList,
  UpdateCostCenterUpdatedAt,
  UpdateShouldRefreshEntity
} from '../store/actions/cost-center.actions';
import { DEFAULT_SORT_DIRECTION } from '../../shared/constants';
import { selectCompanyProfile } from '../../administration/store/selectors';
import { CompanyProfile } from '../../administration/models/company-profile.model';
import { CostCenterTabsEnum } from '../enums';
import { DisplayToaster } from '../../shared/decorators/toaster';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { CostCenterDocumentModel, CostCenterModel, CostCenterTotalsModel } from '../models';

@Injectable()
export class CostCenterApiService {
  private readonly apiEndpoint: string = `${environment.javaApiVersion}/workspaces`;
  private wid: number;

  private apiUrl(url: string = ''): string {
    return this.apiEndpoint + `/${this.wid}/cost-centers` + url;
  }

  constructor(
    private readonly http: HttpClient,
    private readonly toasterService: ToasterService,
    private readonly translateService: TranslateService,
    private readonly store: Store<AppState>
  ) {
    this.store.select(selectCompanyProfile)
      .subscribe((companyProfile: CompanyProfile) => this.wid = companyProfile.workspaceId);
  }

  public getCostCenterList(
    status: CostCenterTabsEnum,
    page: string = '1',
    per_page: string = '100',
    sort: FilterModelNew = { sortBy: 'updatedAt', direction: DEFAULT_SORT_DIRECTION },
    filters: any = {}
  ): Observable<ResponseList<CostCenterModel>> {
    let params = new HttpParams()
      .set('status', status)
      .set('page', page)
      .set('sortBy', sort.sortBy)
      .set('direction', sort.direction)
      .set('length', per_page);

    for (const [key, value] of Object.entries(filters)) {
      Array.isArray(value)
        ? value.forEach(itm => params = params.append(key, itm))
        : params = params.append(key, value.toString());
    }

    return this.http.get<ResponseList<CostCenterModel>>(this.apiUrl(), { params })
      .pipe(tap((data: ResponseList<CostCenterModel>) => {
        this.store.dispatch(LoadCostCenterList({
          costCenterListData: {
            [data.pagination.page]: {
              pagination: data.pagination,
              sort,
              data: data.data
            }
          },
          status
        }));
      }));
  }

  public getCostCenterActiveList(): Observable<ResponseList<CostCenterModel>> {
    const params = new HttpParams()
      .set('status', CostCenterTabsEnum.ACTIVE)
      .set('sortBy', 'updatedAt')
      .set('direction', DEFAULT_SORT_DIRECTION);

    return this.http.get<ResponseList<CostCenterModel>>(this.apiUrl(), { params });
  }

  public getCostCenterListTotals(
    status: CostCenterTabsEnum,
    filters: any = {}
  ): Observable<CostCenterTotalsModel> {
    let params = new HttpParams()
      .set('status', status);

    for (const [key, value] of Object.entries(filters)) {
      Array.isArray(value)
        ? value.forEach(itm => params = params.append(key, itm))
        : params = params.append(key, value.toString());
    }
    return this.http.get<ResponseModel<CostCenterTotalsModel>>(this.apiUrl('/totals'), {params})
      .pipe(map((data: ResponseModel<CostCenterTotalsModel>) => data.data));
  }

  public getCostCenterEntityById(id: number): Observable<CostCenterModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.get<ResponseModel<CostCenterModel>>(this.apiUrl(`/${id}`))
      .pipe(
        tap((response: ResponseModel<CostCenterModel>) => {
          this.store.dispatch(LoadCostCenter({ costCenter: response.data }));
          this.store.dispatch(UpdateCostCenterUpdatedAt({ updatedAt: new Date() }));
          this.store.dispatch(UpdateShouldRefreshEntity({ isShouldRefresh: false }));
        }),
        map((data: ResponseModel<CostCenterModel>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  @DisplayToaster({showErrorMessage: true})
  public createCostCenterEntity(name: string): Observable<CostCenterModel> {
    const body = { name };
    return this.http.post<ResponseModel<CostCenterModel>>(this.apiUrl(), body)
      .pipe(map((data: ResponseModel<CostCenterModel>) => data.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public updateCostCenterEntity(id: number, name: string): Observable<CostCenterModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());
    const body = { name };
    return this.http.patch<ResponseModel<CostCenterModel>>(this.apiUrl(`/${id}`), body)
      .pipe(
        tap((response: ResponseModel<CostCenterModel>) => {
          this.store.dispatch(LoadCostCenter({ costCenter: response.data }));
          this.store.dispatch(UpdateCostCenterUpdatedAt({ updatedAt: new Date() }));
          this.store.dispatch(UpdateShouldRefreshEntity({ isShouldRefresh: false }));
        }),
        map((data: ResponseModel<CostCenterModel>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  @DisplayToaster({showErrorMessage: true})
  public changeCostCenterStatus(id: number, status): Observable<CostCenterModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<CostCenterModel>>('post', this.apiUrl(`/${id}/status/${status}`))
      .pipe(
        tap((response: ResponseModel<CostCenterModel>) => {
          this.store.dispatch(LoadCostCenter({ costCenter: response.data }));
          this.store.dispatch(UpdateCostCenterUpdatedAt({ updatedAt: new Date() }));
          this.store.dispatch(UpdateShouldRefreshEntity({ isShouldRefresh: false }));
        }),
        map((data: ResponseModel<CostCenterModel>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  @DisplayToaster({showErrorMessage: true})
  public deleteCostCenter(id: number): Observable<any> {
    return this.http.delete( this.apiUrl(`/${id}`));
  }

  public getCostCenterDocuments(
    id: number,
    page: string = '1',
    per_page: string = '100',
    sort: FilterModelNew = { sortBy: 'updatedAt', direction: DEFAULT_SORT_DIRECTION },
    costType?: 'REVENUE'|'EXPENSES'
  ): Observable<ResponseList<CostCenterDocumentModel>> {
    let params = new HttpParams()
      .set('page', page)
      .set('sortBy', sort.sortBy)
      .set('direction', sort.direction)
      .set('length', per_page);
    if (costType) {
      params = params.set('costType', costType);

    }
    // this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.get<ResponseList<CostCenterDocumentModel>>(this.apiUrl(`/${id}/documents`), {params})
      .pipe(
        tap((response: ResponseList<CostCenterDocumentModel>) => {
          // this.store.dispatch(LoadCostCenter({ costCenter: response.data }));
          // this.store.dispatch(UpdateCostCenterUpdatedAt({ updatedAt: new Date() }));
          // this.store.dispatch(UpdateShouldRefreshEntity({ isShouldRefresh: false }));
        }),
        // map((data: ResponseList<CostCenterDocumentModel>) => data.data),
        // finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  public getCostCenterDocumentsTotals(id: number): Observable<CostCenterTotalsModel> {
    return this.http.get<ResponseModel<CostCenterTotalsModel>>(this.apiUrl(`/${id}/documents/totals`))
      .pipe(map((data: ResponseModel<CostCenterTotalsModel>) => data.data));
  }

  costCenterFilterNames(status: CostCenterTabsEnum): Observable<string[]> {
    return this.http.get<ResponseModel<string[]>>(this.apiUrl(`/filter/name/${status}`))
      .pipe(map((response: ResponseModel<string[]>) => response.data));
  }

  public getCostCenterListExportFile(status: CostCenterTabsEnum): Observable<HttpResponse<Blob>> {
    const params = new HttpParams()
      .set('status', status);

    return this.http.get(
      this.apiUrl('/export'),
      {
        observe: 'response',
        responseType: 'blob',
        params
      },
    );
  }

}
