import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { get } from 'lodash';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ProductModel, ProductsService } from '../../../products';
import { ProductTypes } from '../../../products/product-types';
import { SalesPositionsByProductType } from 'projects/workspace/src/app/sales-order/models/sales-order-position.model';
import { SubscriptionApiService } from 'projects/workspace/src/app/subscription/services/subscription-api.service';
import { ResponseModel } from 'projects/workspace/src/app/shared/models/response';
import { CompositeProductModalComponent } from '../../modals-products';
import { CustomSearchFn, getProductSearchValue } from 'common/src/modules/rnpl-common/helpers';
import { PRODUCTS_TYPE } from '../../../rnpl-common/constants/products-type';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { VAT_LIST } from 'projects/workspace/src/app/shared/constants';
import { DocumentTypesUppercaseEnum } from '../../modals-common/link-document-modal/enums/ducument-types.enum';
import { RecalculatePositionApiService } from '../../../../services/recalculate-position-api.service';
import { ActionButtonsService } from '../../../../services/action-buttons.service';

@Component({
  selector: 'rnpl-subscription-position-modal',
  templateUrl: './subscription-position-modal.component.html',
  styleUrls: ['./subscription-position-modal.component.scss']
})
export class SubscriptionPositionModalComponent extends BaseModalComponent implements OnInit, OnDestroy {

  public companyProfile: CompanyProfile;
  public productType = PRODUCTS_TYPE;

  public readonly productTypes = ProductTypes;

  public productsList: ProductModel[] = [];
  public productsListByType: ProductModel[] = [];

  public selectedProduct: ProductModel = null;
  public isProductFound: boolean = false;
  public selectedProductInvalid: boolean = false;
  public productNameInvalid: boolean = false;
  public allowFractionalValues: boolean = true;

  public positionName: string = null;

  public availableProductTypes: ProductTypes[] = [ProductTypes.SERVICES, ProductTypes.DIGITAL];
  public customSearchFn = CustomSearchFn;

  public form: FormGroup;
  public readonly vatList = VAT_LIST;

  public selectedProductType: ProductTypes|string = ProductTypes.GOODS;

  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    public store: Store<AppState>,
    public toasterService: ToasterService,
    public translateService: TranslateService,
    public dialog: MatDialog,
    private productsService: ProductsService,
    private subscriptionApiService: SubscriptionApiService,
    public dialogRef: MatDialogRef<SubscriptionPositionModalComponent>,
    private fb: FormBuilder,
    private recalculatePositionApiService: RecalculatePositionApiService,
    public actionButtonsService: ActionButtonsService,
    @Inject(MAT_DIALOG_DATA) public data: {
      subscriptionId: number,
      salesPriceListName: string,
      vatBlocked: boolean
    }
  ) {
    super(toasterService);
    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((companyProfile: CompanyProfile) => {
        this.companyProfile = companyProfile;
      });
  }

  ngOnInit() {
    this.getProducts();
    this.initForm(this.selectedProduct);
  }

  private initForm(selectProduct: ProductModel) {
    this.form = this.fb.group({
      grossTotalPrice: [this.setCurrencyValue(get(selectProduct, 'prices.price.grossPrice')), Validators.required],
      grossUnitPrice: [this.setCurrencyValue(get(selectProduct, 'prices.price.grossPrice')), Validators.required],
      netTotalPrice: [this.setCurrencyValue(get(selectProduct, 'prices.price.netPrice')), Validators.required],
      productDescription: [get(selectProduct, 'description')],
      providedServices: [get(selectProduct, 'description')],
      netUnitPrice: [this.setCurrencyValue(get(selectProduct, 'prices.price.netPrice')), Validators.required],
      vat: [
        get(selectProduct, 'prices.price.vat') || get(selectProduct, 'prices.price.vat') === 0
          ? get(selectProduct, 'prices.price.vat')
          : get(selectProduct, 'accountingSettings.vatRateByDefault')
      ],
      discount: [get(selectProduct, 'prices.price.discount')],
      discountAmountNet: [this.setCurrencyValue(get(selectProduct, 'prices.price.discountNet'))],
      discountAmountGross: [this.setCurrencyValue(get(selectProduct, 'prices.price.discountGross'))],
      addDiscount: [!!get(selectProduct, 'prices.price.discount')],
      quantity: [1],
      addRemark: [false],
      remark: [''],
      unitType: [get(selectProduct, 'unitType') || 'pcs'],
    });
  }

  private getProducts(): void {
    this.showDropdownSpin$.next(true);
    this.productsService.getActiveProducts(undefined, undefined, this.data.salesPriceListName)
      .pipe(takeUntil(this._destroy))
      .subscribe((products: ProductModel[]) => {
        this.productsList = this.prepareProductsList(products);
        this.filterProducts();
      });
  }

  selectPositionChanges(selectedProduct: ProductModel): void {
    this.selectedProductType = selectedProduct.type || selectedProduct.productType;

    if (this.data.vatBlocked) {
      this.vat.disable({onlySelf: true, emitEvent: false});
    }

    this.getProductById(+selectedProduct.id);
  }

  public getProductById(productId) {
    this.productsService.getProduct(productId, this.data.salesPriceListName)
      .pipe(takeUntil(this._destroy))
      .subscribe(product => {
        this.initForm(product);
        this.allowFractionalValues = product.unitTypeModel.allowFractionalValues;
        if (this.data.vatBlocked) {
          const defaultPrice = this.setCurrencyValue(product.prices.price.netPrice);
          this.vat.setValue(0);
          this.grossUnitPrice.setValue(defaultPrice);
          this.grossTotalPrice.setValue(defaultPrice);
        }
        this.updatePosition(1, 'quantity');
    });
  }


  public prepareProductsList(products): ProductModel[] {
    return products.map(item => ({
      ...item,
      searchLabel: getProductSearchValue(item, this.translateService)
    }));
  }

  public filterProducts(): void {
    this.productsListByType = this.productsList
      .filter((product: ProductModel) => this.availableProductTypes.includes(product.type));
    this.showDropdownSpin$.next(false);
  }

  public createProduct(productType: ProductTypes): void {
    const dialog = this.dialog.open(CompositeProductModalComponent, {
      data: {
        family: null,
        productType: productType
      },
      disableClose: true,
    });

    dialog.afterClosed().subscribe((product: ProductModel) => {
      if (product) {
        this.productsListByType.push(product);
        this.productsList.push(product);
        this.selectedProduct = product;
        this.selectedProductType = product.productType;
        this.selectPositionChanges(product);
        this.isProductFound = false;
      }
    });
  }

  public addPosition(): void {
    if (!this.selectedProduct) {
      this.selectedProductInvalid = true;
      return;
    }

    this.subscriptionApiService
      .createSubscriptionPosition(
        this.data.subscriptionId,
        {
          ...this.form.getRawValue(),
          grossTotalPrice: this.setCurrencyControl(this.grossTotalPrice.value),
          grossUnitPrice: this.setCurrencyControl(this.grossUnitPrice.value),
          netTotalPrice: this.setCurrencyControl(this.netTotalPrice.value),
          netUnitPrice: this.setCurrencyControl(this.netUnitPrice.value),
          productId: this.selectedProduct.id,
          productType: this.selectedProductType,
          productTypeForGrouping: this.selectedProductType,
        })
      .pipe(takeUntil(this._destroy))
      .subscribe(this.subscriberHandler);
  }

  public setCurrencyControl(val): number {
    if (!val) { return val; }
    return +(val * 100).toFixed(0);
  }

  public subscriberHandler = (response: ResponseModel<SalesPositionsByProductType>) => {
    // this.subscriptionApiService.getSubscriptionById(this.data.subscriptionId).subscribe();
    this.dialogRef.close(response);
  }

  public updatePosition(value, fieldName): void {
    this.recalculatePositionApiService.recalculatePosition(DocumentTypesUppercaseEnum.SBC, {
      protoPosition: {
        ...this.form.getRawValue(),
        salesPriceListName: this.data.salesPriceListName,
        grossTotalPrice: this.grossTotalPrice.value,
        grossUnitPrice: this.grossUnitPrice.value,
        netTotalPrice: this.netTotalPrice.value,
        netUnitPrice: this.netUnitPrice.value,
        productId: this.selectedProduct.id,
        productTypeForGrouping: this.selectedProductType,
      },
      fieldUpdateRequest: {
        fieldName: fieldName,
        fieldValue: value
      },
      type: this.selectedProductType
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe(product => {
        this.form.patchValue({
          ...product,
          grossTotalPrice: product.grossTotalPrice,
          grossUnitPrice: product.grossUnitPrice,
          netTotalPrice: product.netTotalPrice,
          netUnitPrice: product.netUnitPrice,
          discountAmount: product.discountAmount,
          discountAmountNet: product.discountAmountNet,
          discountAmountGross: product.discountAmountGross,
        });
      });
  }

  public setCurrencyValue(value): number {
    if (!value) { return value; }
    return +(value / 1e2).toFixed(2);
  }

  public updateDescription(description: string): void {
    this.selectedProduct = {
      ...this.selectedProduct,
      description: description
    };
  }

  public updateDescriptionByProductType(): void {
    if (this.selectedProductType === ProductTypes.SERVICES || this.selectedProductType === ProductTypes.ARBITRARY) {
      this.updatePosition(this.providedServices.value, 'providedServices');
    } else {
      this.updatePosition(this.productDescription.value, 'productDescription');
    }
  }

  public typeServicesOrArbitrary(): boolean {
    return this.selectedProductType === ProductTypes.SERVICES || this.selectedProductType === ProductTypes.ARBITRARY;
  }

  get grossTotalPrice(): FormControl { return this.form.get('grossTotalPrice') as FormControl; }
  get grossUnitPrice(): FormControl { return this.form.get('grossUnitPrice') as FormControl; }
  get addRemark(): FormControl { return this.form.get('addRemark') as FormControl; }
  get netTotalPrice(): FormControl { return this.form.get('netTotalPrice') as FormControl; }
  get netUnitPrice(): FormControl { return this.form.get('netUnitPrice') as FormControl; }
  get productDescription(): FormControl { return this.form.get('productDescription') as FormControl; }
  get providedServices(): FormControl { return this.form.get('providedServices') as FormControl; }
  get quantity(): FormControl { return this.form.get('quantity') as FormControl; }
  get remark(): FormControl { return this.form.get('remark') as FormControl; }
  get vat(): FormControl { return this.form.get('vat') as FormControl; }
  get discount(): FormControl { return this.form.get('discount') as FormControl; }
  get discountAmountNet(): FormControl { return this.form.get('discountAmountNet') as FormControl; }
  get discountAmountGross(): FormControl { return this.form.get('discountAmountGross') as FormControl; }
  get addDiscount(): FormControl { return this.form.get('addDiscount') as FormControl; }
  get unitTypeControl(): FormControl { return this.form.get('unitType') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
