import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { environment } from '../../../../environments/environment';
import { AppState } from '../../../store/state/app.state';
import { ResponseModel } from '../../../shared/models/response';
import { DisplayToaster } from '../../../shared/decorators/toaster';
import { AccountingSettingsModel, OverdueNoticeItemModel, OverdueNoticeSettingsModel } from '../models';
import { OverdueNoticeTypeEnum } from '../enums';
import { LoadAccountingSettingsSuccess } from '../../../store/actions/shared.actions';
import { selectCompanyProfile } from '../../../administration/store/selectors';
import { CompanyProfile } from '../../../administration/models/company-profile.model';
import {
  DocumentTypesUppercaseEnum
} from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { DocumentNumberSettingsModel } from '../../../shared/models';
import { isEmpty } from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class AccountingSettingsService {
  private readonly apiEndpoint: string = `${environment.javaApiVersion}/accounting/settings`;
  private readonly apiStripeEndpoint: string = `${environment.javaApiVersion}/stripe`;

  private wid: number;

  private apiUrl(url: string = ''): string {
    return this.apiEndpoint + url;
  }

  private stripeApiUrl(url: string = ''): string {
    return this.apiStripeEndpoint + url;
  }

  constructor(
    private readonly toasterService: ToasterService,
    private readonly http: HttpClient,
    private readonly store: Store<AppState>,
  ) {
    this.store.select(selectCompanyProfile)
      .pipe(filter(cp => !!cp && !isEmpty(cp)))
      .subscribe((profile: CompanyProfile) => {
        this.wid = profile.workspaceId;
      });
  }

  public getOverdueNoticeSettings(): Observable<OverdueNoticeSettingsModel> {
    return this.http.get<ResponseModel<OverdueNoticeSettingsModel>>(this.apiUrl('/overdue-notice'))
      .pipe(map((settings: ResponseModel<OverdueNoticeSettingsModel>) => settings.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public updateOverdueNoticeSettings(settings: OverdueNoticeSettingsModel): Observable<OverdueNoticeSettingsModel> {
    return this.http.patch<ResponseModel<OverdueNoticeSettingsModel>>(this.apiUrl('/overdue-notice'), {...settings})
      .pipe(map((settings: ResponseModel<OverdueNoticeSettingsModel>) => settings.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public sendOverdueNoticeTestEmail(
    emails: string[],
    overdueNotice: OverdueNoticeItemModel,
    overdueNoticeType: OverdueNoticeTypeEnum
  ): Observable<any> {
    const params = { overdueNoticeType };
    const body = { emails, overdueNotice };
    return this.http.post(this.apiUrl('/overdue-notice/email/test'), body, {params});
  }

  @DisplayToaster({showErrorMessage: true})
  public sendManualNoticeOINEmail(
    oinId: number,
    emails: string[],
    overdueNotice: OverdueNoticeItemModel,
  ): Observable<any> {
    const body = { emails, overdueNotice };
    return this.http.post(this.apiUrl(`/overdue-notice/email/${oinId}`), body);
  }

  // @DisplayToaster({showErrorMessage: true})
  public getAccountingSettings(wid: number = this.wid): Observable<AccountingSettingsModel> {
    const params = {
      wid: (wid).toString(),
    };

    return this.http.get<ResponseModel<AccountingSettingsModel>>(this.apiUrl(), { params })
      .pipe(
        tap((response: ResponseModel<AccountingSettingsModel>) => {
          this.store.dispatch(LoadAccountingSettingsSuccess({ accountingSettings: response.data }));
        }),
        map((response: ResponseModel<AccountingSettingsModel>) => response.data)
      );
  }

  @DisplayToaster({showErrorMessage: true})
  public updateAccountingSettings(accountingSettings: AccountingSettingsModel, ignore?: string,  wid: number = this.wid): Observable<AccountingSettingsModel> {
    const params = {
      wid: (wid).toString(),
    };

    if (ignore) {
      params['ignore'] = ignore;
    }

    return this.http.patch<ResponseModel<AccountingSettingsModel>>(this.apiUrl(), { ...accountingSettings }, { params })
      .pipe(
        tap((response: ResponseModel<AccountingSettingsModel>) => {
          this.store.dispatch(LoadAccountingSettingsSuccess({ accountingSettings: response.data }));
        }),
        map((response: ResponseModel<AccountingSettingsModel>) => response.data)
      );
  }

  public getNextRunpleIdDocumentNumberSettings(
    documentType: DocumentTypesUppercaseEnum,
    runpleIdSettings: DocumentNumberSettingsModel,
    wid: number = this.wid,
  ): Observable<string> {
    const params = {
      wid: (wid).toString(),
    };

    return this.http.post<ResponseModel<string>>(this.apiUrl(`/document-id/${documentType}/next-runple-id`), {...runpleIdSettings}, { params })
      .pipe(map((response: ResponseModel<string>) => response.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public resetAccountingDocumentSettings(documentType: DocumentTypesUppercaseEnum, wid: number = this.wid): Observable<AccountingSettingsModel> {
    const params = {
      wid: (wid).toString(),
    };

    return this.http.post<ResponseModel<AccountingSettingsModel>>(this.apiUrl(`/${documentType}/reset-default`), {}, { params })
      .pipe(
        tap((response: ResponseModel<AccountingSettingsModel>) => {
          this.store.dispatch(LoadAccountingSettingsSuccess({ accountingSettings: response.data }));
        }),
        map((response: ResponseModel<AccountingSettingsModel>) => response.data)
      );
  }

  @DisplayToaster({showErrorMessage: true})
  public resetAccountingDocumentNumberSettings(documentType: DocumentTypesUppercaseEnum, wid: number = this.wid): Observable<AccountingSettingsModel> {
    const params = {
      wid: (wid).toString(),
    };

    return this.http.post<ResponseModel<AccountingSettingsModel>>(this.apiUrl(`/document-id/${documentType}/reset-default`), {}, { params })
      .pipe(
        tap((response: ResponseModel<AccountingSettingsModel>) => {
          this.store.dispatch(LoadAccountingSettingsSuccess({ accountingSettings: response.data }));
        }),
        map((response: ResponseModel<AccountingSettingsModel>) => response.data)
      );
  }

  // @DisplayToaster({showErrorMessage: true})
  public accountingSettingsSkipSyncPaymentsDialog(wid: number = this.wid): Observable<AccountingSettingsModel> {
    const params = {
      wid: (wid).toString(),
    };

    return this.http.patch<ResponseModel<AccountingSettingsModel>>(this.apiUrl('/skip-sync-payments-dialog'), { params })
      .pipe(
        tap((response: ResponseModel<AccountingSettingsModel>) => {
          this.store.dispatch(LoadAccountingSettingsSuccess({ accountingSettings: response.data }));
        }),
        map((response: ResponseModel<AccountingSettingsModel>) => response.data)
      );
  }

  public createStripeAccount(wid: number = this.wid, predefined = false): Observable<any> {
    const params = {
      wid: (wid).toString(),
      predefined: predefined.toString()
    };
    return this.http.request('post', this.stripeApiUrl(), { params });
  }

  public updateStripeAccount(returnURL: string, wid: number = this.wid): Observable<any> {
    const params = {
      wid: (wid).toString(),
    };
    return this.http.put(this.stripeApiUrl(), { returnURL },{ params })
      .pipe(
        map((response: ResponseModel<any>) => response.data)
      );
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

}
