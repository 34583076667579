import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, throwError, of } from 'rxjs';
import { finalize, map, tap, catchError, switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { AppState } from '../../store/state/app.state';
import { ResponseList, ResponseModel } from '../../shared/models/response';
import { FeeModel, PaymentDirectionEnum, PaymentModel } from '../models/payment.model';
import { DecrementLoadingRequestsCount, IncrementLoadingRequestsCount, LoadCurrencies, LoadPayment, UpdatePaymentState, UpdatePaymentUpdatedAt, UpdateShouldRefreshEntity, } from '../store/actions/payment.actions';
import { FilterModelNew } from '../../outgoing-invoice/models/filter-model-new';
import { PaymentCountersModel } from '../models/payments-counters.model';
import { ChangeStatusOperationsEnum } from '../../outgoing-invoice/enums';
import { CurrencyModel } from '../models/currency.model';
import { SearchDocumentResponse } from 'common/src/modules/modals/modals-common/link-document-modal/model/search-document-response';
import {
  DocumentTypesEnum,
  DocumentTypesUppercaseEnum
} from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { DirectionDocumentEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/document-direction.enum';
import { UIStatesEnum, PaginationModel } from 'common/src/models';
import { EditModel } from '../../outgoing-invoice/models/edit.model';
import { environment } from 'projects/workspace/src/environments/environment';
import { PaymentListTabsEnum } from '../enums';
import { LoadPaymentsList } from '../store/actions/payment.actions';
import { FileUploadParams } from 'common/src/models/file-upload-params.model';
import { PaymentsListTotalsModel } from '../models/payments-list-totals.model';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { CommonModalsActionsEnum, WarningModalComponent } from 'common/src/modules/modals/modals-common';
import { getAnotherUserEditErrorModalData } from 'common/src/modules/modals/modals-common/common-modal.config';
import { AvailableAssignsModel } from 'common/src/modules/modals/modals-payments/link-document-modal/model/available-assigns.model';
import { FormInputChangedModel } from '../../shared/models';
import { selectCompanyProfile } from '../../administration/store/selectors';
import { CompanyProfile } from '../../administration/models/company-profile.model';

@Injectable({
  providedIn: 'root',
})
export class PaymentApiService {
  private wid: number;
  private readonly apiEndpoint: string = `${environment.javaApiVersion}/payments`;

  private apiV2Url(url: string = ''): string {
    return `${environment.javaApiVersion}/workspaces/${this.wid}/payments` + url;
  }

  constructor(
    private readonly toasterService: ToasterService,
    private readonly translateService: TranslateService,
    private readonly http: HttpClient,
    private readonly dialog: MatDialog,
    private readonly store: Store<AppState>
  ) {
    this.store.select(selectCompanyProfile)
      .subscribe((profile: CompanyProfile) => {
        this.wid = profile.workspaceId;
      });
  }

  public createPaymentBlank(direction: PaymentDirectionEnum): Observable<PaymentModel> {
    return this.http.request<ResponseModel<PaymentModel>>(
        'post',
        this.apiEndpoint,
        {
          params: {
            direction
          }
        }
      )
      .pipe(
        map((data: ResponseModel<PaymentModel>) => data.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getPaymentById(id: string | number): Observable<PaymentModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.get<ResponseModel<PaymentModel>>(`${this.apiEndpoint}/${id}`)
      .pipe(
        tap((response: ResponseModel<PaymentModel>) => {
          this.store.dispatch(LoadPayment({ payment: response.data }));
          this.store.dispatch(UpdatePaymentUpdatedAt({ updatedAt: new Date() }));
          this.store.dispatch(UpdateShouldRefreshEntity({ isShouldRefresh: false }));
        }),
        map((response: ResponseModel<PaymentModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  public getAvailableLinks(type: DocumentTypesEnum, direction: DirectionDocumentEnum) : Observable<SearchDocumentResponse[]> {
    return this.http.get<ResponseList<SearchDocumentResponse>>(`${this.apiEndpoint}/available-links`, {
      params: {
        type,
        direction
      }
    })
    .pipe(
      map((response: ResponseList<SearchDocumentResponse>) => response.data)
    );
  }

  public getAvailableAssigns(paymentId: number) : Observable<AvailableAssignsModel[]> {
    return this.http.get<ResponseList<AvailableAssignsModel>>(`${this.apiEndpoint}/${paymentId}/available-assigns`)
      .pipe(
        map((response: ResponseList<AvailableAssignsModel>) => response.data)
      );
  }

  public validatePayment(id: string | number): Observable<any> {
    return this.http.get(`${this.apiEndpoint}/${id}/validate`);
  }

  public updatePayment(id: string | number, payment: PaymentModel): Observable<PaymentModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.put<ResponseModel<PaymentModel>>(`${this.apiEndpoint}/${id}`, payment)
      .pipe(
        tap((response: ResponseModel<PaymentModel>) => {
          this.store.dispatch(LoadPayment({ payment: response.data }));
          this.store.dispatch(UpdatePaymentUpdatedAt({ updatedAt: new Date() }));
        }),
        map((response: ResponseModel<PaymentModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, id);
          return throwError(error);
        })
      );
  }

  public getPaymentList(
    status: PaymentListTabsEnum,
    pagination: PaginationModel,
    sort: FilterModelNew,
    filters: any = {}
  ): Observable<ResponseList<PaymentModel>> {

    const params = {
      status: status.toUpperCase(),
      page: pagination.page,
      sortBy: sort.sortBy,
      direction: sort.direction,
      length: pagination.per_page,
    };

    for (const [key, value] of Object.entries(filters)) {
      params[key] = Array.isArray(value) ? value.join(',') : value.toString();
    }

    return this.http.get<ResponseList<PaymentModel>>(`${this.apiEndpoint}`, { params })
      .pipe(tap((data: ResponseList<PaymentModel>) => {
        this.store.dispatch(LoadPaymentsList({
          paymentListData: {
            [data.pagination.page]: {
              pagination: data.pagination,
              sort,
              data: data.data
            }
          },
          status, page: data.pagination.page
        }));
      }));
  }

  getPaymentsFilterRunpleIds(wid: number): Observable<any[]> {
    const params = {
      wid: wid.toString()
    };
    return this.http.get<ResponseModel<any[]>>(`${this.apiEndpoint}/filter/rids`, { params })
      .pipe(map((response: ResponseModel<any[]>) => response.data));
  }

  getPaymentsFilterCostCenters(wid: number, status: PaymentListTabsEnum): Observable<{ key: number; value: string }[]> {
    const params = {
      wid: wid.toString()
    };
    return this.http.get<ResponseModel<{ key: number; value: string }[]>>(`${this.apiEndpoint}/filter/cost-center/${status}`, { params })
      .pipe(map((response: ResponseModel<{ key: number; value: string }[]>) => response.data));
  }

  getPaymentsFilterGeneralProducts(status: PaymentListTabsEnum): Observable<any[]> {
    return this.http.get<ResponseModel<any[]>>(`${this.apiEndpoint}/filter/general-products/${status}`)
      .pipe(map((response: ResponseModel<any[]>) => response.data));
  }

  public getListTotals(status: PaymentListTabsEnum, filters: any = {}): Observable<PaymentsListTotalsModel> {
    const params = {};
    for (const [key, value] of Object.entries(filters)) {
      params[key] = Array.isArray(value) ? value.join(',') : value.toString();
    }

    return this.http
      .get<ResponseModel<PaymentsListTotalsModel>>(`${this.apiEndpoint}/totals/${status}`, { params })
      .pipe(map((data: ResponseModel<PaymentsListTotalsModel>) => data.data));
  }

  public getPaymentCounters(): Observable<PaymentCountersModel> {
    return this.http.get<ResponseModel<PaymentCountersModel>>(`${this.apiEndpoint}/count/by-status`)
      .pipe(map((data: ResponseModel<PaymentCountersModel>) => data.data));
  }

  public getCounterPaymentsList(paymentId: number): Observable<PaymentModel[]> {
    return this.http.get<ResponseModel<PaymentModel[]>>(this.apiV2Url(`/${paymentId}/counter-payments`))
      .pipe(map((data: ResponseModel<PaymentModel[]>) => data.data));
  }

  public changePaymentsStatus(
    ids: number[],
    action: ChangeStatusOperationsEnum,
    proceed = false
  ): Observable<any> {
    const params = new HttpParams()
      .set('proceed', proceed.toString());
    const body = { ids, action };

    return this.http.post<ResponseModel<any>>(
      `${this.apiEndpoint}/batch`,
      body,
      { params }
    );
  }

  public updateLinkedDocumentAmount(paymentId: number, proxyId: number, field: FormInputChangedModel): Observable<PaymentModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<PaymentModel>>(`${this.apiEndpoint}/${paymentId}/amount/${proxyId}`, field)
      .pipe(
        tap((response: ResponseModel<PaymentModel>) => {
          this.store.dispatch(LoadPayment({ payment: response.data }));
          this.store.dispatch(UpdatePaymentUpdatedAt({ updatedAt: new Date() }));
        }),
        map((data: ResponseModel<PaymentModel>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  // public linkPayment(paymentId: number, documentId: number, documentType: DocumentTypesEnum): Observable<PaymentModel> {
  //   this.store.dispatch(IncrementLoadingRequestsCount());
  //
  //   return this.http.patch<ResponseModel<PaymentModel>>(`${this.apiEndpoint}/${paymentId}/link`, {documentId, documentType})
  //   .pipe(
  //     tap((response: ResponseModel<PaymentModel>) => {
  //       this.store.dispatch(LoadPayment({ payment: response.data }));
  //       this.store.dispatch(UpdatePaymentUpdatedAt({ updatedAt: new Date() }));
  //     }),
  //     map((data: ResponseModel<PaymentModel>) => data.data),
  //     finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
  //   );
  // }

  public assignDocumentToPayment(paymentId: number, document): Observable<PaymentModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<PaymentModel>>(`${this.apiEndpoint}/${paymentId}/assign`, document)
      .pipe(
        tap((response: ResponseModel<PaymentModel>) => {
          this.store.dispatch(LoadPayment({ payment: response.data }));
          this.store.dispatch(UpdatePaymentUpdatedAt({ updatedAt: new Date() }));
        }),
        map((data: ResponseModel<PaymentModel>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  public assignMultipleDocumentsToPayment(
    paymentId: number,
    documents: {
      documentType: DocumentTypesUppercaseEnum;
      documentId: number;
      amount?: number;
    }[],
    multipleLinkEnabled = false,
    multiplePartnerEnabled = false,
    wid: number = this.wid
  ): Observable<PaymentModel> {
    const body = {
      documents,
      multipleLinkEnabled,
      multiplePartnerEnabled
    };
    const params = {
      wid: (wid).toString(),
    };
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<PaymentModel>>(`${this.apiEndpoint}/${paymentId}/assign/multiple`, body, { params })
      .pipe(
        tap((response: ResponseModel<PaymentModel>) => {
          this.store.dispatch(LoadPayment({ payment: response.data }));
          this.store.dispatch(UpdatePaymentUpdatedAt({ updatedAt: new Date() }));
        }),
        map((data: ResponseModel<PaymentModel>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  public unlinkPayment(paymentId: number, proxyId: number): Observable<PaymentModel> {
    let params = new HttpParams();

    if (proxyId) {
      params = params.set('proxyId', proxyId.toString());
    }

    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<PaymentModel>>('patch', `${this.apiEndpoint}/${paymentId}/unlink`, { params })
    .pipe(
      tap((response: ResponseModel<PaymentModel>) => {
        this.store.dispatch(LoadPayment({ payment: response.data }));
        this.store.dispatch(UpdatePaymentUpdatedAt({ updatedAt: new Date() }));
      }),
      map((response: ResponseModel<PaymentModel>) => response.data),
      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
      catchError(error => {
        this.handlePopupErrors(error, paymentId, {proxyId});
        return throwError(error);
      })
    );
  }

  public unlinkPaymentV2(paymentId: number, proxyId: number): Observable<PaymentModel> {
    let params = new HttpParams();

    if (proxyId) {
      params = params.set('proxyId', proxyId.toString());
    }

    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<PaymentModel>>('delete', this.apiV2Url(`/${paymentId}/unlink`), { params })
      .pipe(
        tap((response: ResponseModel<PaymentModel>) => {
          this.store.dispatch(LoadPayment({ payment: response.data }));
          this.store.dispatch(UpdatePaymentUpdatedAt({ updatedAt: new Date() }));
        }),
        map((response: ResponseModel<PaymentModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, paymentId, {proxyId});
          return throwError(error);
        })
      );
  }

  submitPaymentCreation(id: number | string): Observable<PaymentModel> {
    return this.http.request<ResponseModel<PaymentModel>>('patch', `${this.apiEndpoint}/${id}/create`)
      .pipe(
        tap((response: ResponseModel<PaymentModel>) => {
          this.store.dispatch(LoadPayment({ payment: response.data }));
        }),
        map((response: ResponseModel<PaymentModel>) => response.data)
      );
  }

  bookPayment(id: number | string): Observable<PaymentModel> {
    return this.http.request<ResponseModel<PaymentModel>>('patch', `${this.apiEndpoint}/${id}/book`)
      .pipe(
        tap((response: ResponseModel<PaymentModel>) => {
          this.store.dispatch(LoadPayment({ payment: response.data }));
          this.store.dispatch(UpdatePaymentUpdatedAt({ updatedAt: new Date() }));
          this.store.dispatch(UpdatePaymentState({ currentState: UIStatesEnum.VIEW }));
        }),
        map((response: ResponseModel<PaymentModel>) => response.data)
      );
  }

  deletePayment(id: number | string): Observable<ResponseModel<null | PaymentModel>> {
    return this.http.request<ResponseModel<null | PaymentModel>>('delete', `${this.apiEndpoint}/${id}`);
  }

  clonePayment(id: number | string): Observable<PaymentModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<PaymentModel>>('post', `${this.apiEndpoint}/${id}/clone`)
      .pipe(
        tap((response: ResponseModel<PaymentModel>) => {
          this.store.dispatch(LoadPayment({ payment: response.data }));
          this.store.dispatch(UpdatePaymentUpdatedAt({ updatedAt: new Date() }));
          // this.store.dispatch(UpdatePaymentState({ currentState: UIStatesEnum.CREATE }));
        }),
        map((response: ResponseModel<PaymentModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  addFee(id: number | string): Observable<PaymentModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());
    const feeBody = {
      feeAmount: null,
      feeName: null
    };
    return this.http.post<ResponseModel<PaymentModel>>(`${this.apiEndpoint}/${id}/fee`, feeBody)
      .pipe(
        tap((response: ResponseModel<PaymentModel>) => {
          this.store.dispatch(LoadPayment({ payment: response.data }));
          this.store.dispatch(UpdatePaymentUpdatedAt({ updatedAt: new Date() }));
        }),
        map((response: ResponseModel<PaymentModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, id);
          return throwError(error);
        })
      );
  }

  updateFee(paymentId: string | number, fee: FeeModel): Observable<PaymentModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.put<ResponseModel<PaymentModel>>(`${this.apiEndpoint}/${paymentId}/fee/${fee.feePaymentId}`, fee)
      .pipe(
        tap((response: ResponseModel<PaymentModel>) => {
          this.store.dispatch(LoadPayment({ payment: response.data }));
          this.store.dispatch(UpdatePaymentUpdatedAt({ updatedAt: new Date() }));
        }),
        map((response: ResponseModel<PaymentModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, paymentId);
          return throwError(error);
        })
      );
  }

  deleteFee(paymentId: string | number, feeId: number): Observable<PaymentModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<null>>('delete', `${this.apiEndpoint}/${paymentId}/fee/${feeId}`)
      .pipe(
        tap((response: ResponseModel<PaymentModel>) => {
          this.store.dispatch(LoadPayment({ payment: response.data }));
          this.store.dispatch(UpdatePaymentUpdatedAt({ updatedAt: new Date() }));
        }),
        map((response: ResponseModel<PaymentModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, paymentId);
          return throwError(error);
        })
      );
  }

  addPosition(id: number | string): Observable<PaymentModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<PaymentModel>>('post', `${this.apiEndpoint}/${id}/positions`)
      .pipe(
        tap((response: ResponseModel<PaymentModel>) => {
          this.store.dispatch(LoadPayment({ payment: response.data }));
          this.store.dispatch(UpdatePaymentUpdatedAt({ updatedAt: new Date() }));
          // this.store.dispatch(UpdatePaymentState({ currentState: UIStatesEnum.CREATE }));
        }),
        map((response: ResponseModel<PaymentModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  updatePosition(paymentId: number, positionId: number, field: FormInputChangedModel): Observable<PaymentModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<PaymentModel>>(`${this.apiEndpoint}/${paymentId}/positions/${positionId}`, field)
      .pipe(
        tap((response: ResponseModel<PaymentModel>) => {
          this.store.dispatch(LoadPayment({ payment: response.data }));
          this.store.dispatch(UpdatePaymentUpdatedAt({ updatedAt: new Date() }));
          // this.store.dispatch(UpdatePaymentState({ currentState: UIStatesEnum.CREATE }));
        }),
        map((response: ResponseModel<PaymentModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  deletePosition(paymentId: number, positionId: number): Observable<PaymentModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.delete<ResponseModel<PaymentModel>>(`${this.apiEndpoint}/${paymentId}/positions/${positionId}`)
      .pipe(
        tap((response: ResponseModel<PaymentModel>) => {
          this.store.dispatch(LoadPayment({ payment: response.data }));
          this.store.dispatch(UpdatePaymentUpdatedAt({ updatedAt: new Date() }));
          // this.store.dispatch(UpdatePaymentState({ currentState: UIStatesEnum.CREATE }));
        }),
        map((response: ResponseModel<PaymentModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  deletePositions(paymentId: number, positionIds: number[]): Observable<PaymentModel> {
    const params = {
      pos: positionIds as any
    }
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<PaymentModel>>('delete', `${this.apiEndpoint}/${paymentId}/positions`, { params })
      .pipe(
        tap((response: ResponseModel<PaymentModel>) => {
          this.store.dispatch(LoadPayment({ payment: response.data }));
          this.store.dispatch(UpdatePaymentUpdatedAt({ updatedAt: new Date() }));
          // this.store.dispatch(UpdatePaymentState({ currentState: UIStatesEnum.CREATE }));
        }),
        map((response: ResponseModel<PaymentModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  paymentRecalculate(id: number | string, field: string, value: string | number): Observable<PaymentModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());
    const body = {field, value};
    return this.http.request<ResponseModel<PaymentModel>>('patch', `${this.apiEndpoint}/${id}/recalculate`, {body})
      .pipe(
        tap((response: ResponseModel<PaymentModel>) => {
          this.store.dispatch(LoadPayment({ payment: response.data }));
          this.store.dispatch(UpdatePaymentUpdatedAt({ updatedAt: new Date() }));
        }),
        map((response: ResponseModel<PaymentModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, id);
          return throwError(error);
        })
      );
  }

  paymentFilterPayerDetails(): Observable<string[]> {
    return this.http.get<ResponseModel<string[]>>(`${this.apiEndpoint}/filter/payerDetails`)
      .pipe(
        map((response: ResponseModel<string[]>) => {
          return response.data
            .filter(itm => !!itm)
            .sort((a, b) => a.localeCompare(b));
        })
      );
  }

  paymentFilterRecipientDetails(): Observable<string[]> {
    return this.http.get<ResponseModel<string[]>>(`${this.apiEndpoint}/filter/recipientDetails`)
      .pipe(
        map((response: ResponseModel<string[]>) => {
          return response.data
            .filter(itm => !!itm)
            .sort((a, b) => a.localeCompare(b));
        })
      );
  }

  paymentFilterLinkedDocuments(filteredDocumentType?): Observable<any[]> {
    return this.http.get<ResponseModel<any[]>>(`${this.apiEndpoint}/filter/linkedDocuments`)
      .pipe(
        map((response: ResponseModel<any[]>) => {
          return response.data
            .filter(itm => filteredDocumentType ? itm.type === filteredDocumentType : true);
        })
      );
  }

  public getCurrencies(): Observable<CurrencyModel[]> {
    return this.http.get<ResponseModel<CurrencyModel[]>>(`accounting/v1/utils/currencies`)
      .pipe(
        tap((response: ResponseModel<CurrencyModel[]>) => {
          this.store.dispatch(LoadCurrencies({ currencies: response.data }));
        }),
        map((response: ResponseModel<CurrencyModel[]>) => response.data)
      );
  }

  public editPayment(paymentId?: number, force = false, proceed = false): Observable<EditModel> {
    const params = new HttpParams()
      .set('force', force.toString())
      .set('proceed', proceed.toString());

    return this.http.get<EditModel>(`accounting/v1/utils/edit-flag/PMNT/${paymentId}/check-and-set`, {params})
      .pipe(
        tap(() => {
          this.store.dispatch(UpdatePaymentState({ currentState: UIStatesEnum.EDIT }));
        }),
        catchError(error => {
          this.handlePopupErrors(error, paymentId);
          return throwError(error);
        })
      );
  }

  public commitPayment(paymentId: number, forced = false): Observable<EditModel> {
    const params = new HttpParams().set('forced', forced.toString());

    return this.http.get<EditModel>(`accounting/v1/utils/edit-flag/PMNT/${paymentId}/commit`, {params})
      .pipe(
        tap(response => {
          this.store.dispatch(UpdatePaymentState({ currentState: UIStatesEnum.VIEW }));
        }),
        catchError(error => {
          this.handlePopupErrors(error, paymentId);
          return throwError(error);
        })
      );
  }

  public autoLinkPayments(): Observable<number> {
    return this.http.request<ResponseModel<number>>('patch', `${this.apiEndpoint}/auto-link`)
      .pipe(
        map((response: ResponseModel<number>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getPaymentsListExport(status: string): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: `${this.apiEndpoint}/download?status=${status}`,
      type: 'zip',
    };
    return of(fileParams);
  }

  // public getPaymentsListExportAll(title: string): Observable<FileUploadParams> {
  //   const fileParams: FileUploadParams = {
  //     url: `${this.apiEndpoint}/download/all`,
  //     type: 'zip',
  //     title,
  //   };
  //   return of(fileParams);
  // }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  private handlePopupErrors(error: HttpErrorResponse, paymentId?: string | number, opts?: {proxyId?: number}): void {
    if (error.error.errors && error.error.errors.length) {
      error.error.errors.forEach(errorText => {
        switch (errorText) {
          case 'anotherUserEditError': {
            const dialog = this.dialog.open(WarningModalComponent, {
              data: getAnotherUserEditErrorModalData(
                {
                  document: error.error.data.entityName,
                  userName: error.error.data.userName,
                },
                this.translateService
              )
            });

            dialog.afterClosed().subscribe(res => {
              if (res === CommonModalsActionsEnum.CONFIRM) {
                this.editPayment(+paymentId, true).subscribe();
                this.getPaymentById(paymentId).subscribe();
              }
            });
          }
            break;
          case 'The document has been sent to the accountant, if you edit it, you will need to re-send the document to the accountant. Proceed?':
            {
              const dialog = this.dialog.open(WarningModalComponent, {
                data: {
                  title: 'COMMON.DOC_SENT_TO_ACCOUNTANT',
                  message: 'COMMON.DOC_SENT_TO_ACCOUNTANT_MSG',
                  confirmBtnText: 'BUTTON.EDIT',
                  confirmBtnIcon: 'edit'
                }
              });

              dialog.afterClosed().subscribe(res => {
                if (res === CommonModalsActionsEnum.CONFIRM) {
                  this.editPayment(+paymentId, false, true).subscribe();
                  this.getPaymentById(paymentId).subscribe();
                }
              });
            }
            break;
          case 'Überzahlung per Beleg.':
            {
              const dialog = this.dialog.open(WarningModalComponent, {
                data: {
                  title: 'OVERPAYMENT.TITLE',
                  message: 'OVERPAYMENT.MESSAGE_2',
                  confirmBtnText: 'BUTTON.CONTINUE',
                  confirmBtnIcon: 'checkbox-on'
                }
              });

              dialog.afterClosed().subscribe((response: CommonModalsActionsEnum) => {
                if (response === CommonModalsActionsEnum.CONFIRM) {
                  this.commitPayment(+paymentId, true)
                    .pipe(switchMap(() => this.getPaymentById(paymentId)))
                    .subscribe();
                }
              });
            }
            break;
          case 'notEditModeError':
            const documentName = this.translateService.instant('DOC_SUMMARY.PAYMENT');
            this.showMsg('warning', this.translateService.instant('COMMON.DOC_UPDATED_BY_USER', { document: documentName }));
            this.store.dispatch(UpdatePaymentState({currentState: UIStatesEnum.VIEW}));
            this.getPaymentById(paymentId).subscribe();
            break;
          default:
            this.showMsg('error', errorText);
            break
        }
      });
    }
  }

}
