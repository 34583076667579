import { Component, Input } from '@angular/core';
// import { animate, group, query, sequence, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'rnpl-copy',
  templateUrl: './copy.component.html',
  styleUrls: ['./copy.component.scss'],
  // animations: [
  //   trigger('copyDataAnimation', [
  //     transition('* => visible', [
  //
  //       group([
  //         query('visible, .checkmark', [
  //           style({
  //             opacity: 0,
  //             transform: 'scale(.3) translateY(-50%)',
  //           }),
  //           sequence( [
  //             animate(
  //               '250ms',
  //               style({ opacity: 1, transform: 'scale(1) translateY(-50%)'})
  //             ),
  //             animate(
  //               '300ms',
  //               style({ opacity: 1}),
  //             ),
  //             animate(
  //               '250ms',
  //               style({ opacity: 0, transform: 'scale(.3) translateY(-50%)'}),
  //             ),
  //           ]),
  //         ]),
  //
  //         query('visible, .icon-copy', [
  //           style({
  //             transform: 'scale(1)',
  //           }),
  //           sequence([
  //             animate(
  //               '250ms',
  //               style({
  //                 transform: 'scale(.3)'
  //               })
  //             ),
  //             animate(
  //               '300ms',
  //               style({
  //                 transform: 'scale(.3)'
  //               })
  //             ),
  //             animate(
  //               '250ms',
  //               style({
  //                 transform: 'scale(1)'
  //               })
  //             ),
  //           ]),
  //         ]),
  //       ])
  //     ]),
  //   ]),
  // ]
})
export class CopyComponent {

  public isCopiedData: boolean = false;

  @Input() textUppercase: boolean = true;
  @Input() textToCopy: string = '';
  @Input() showSuccessMsg: boolean = false;
  @Input() copyTitle: string = 'FORM.COPY';

  public isCopiedHandler(): void {
    this.isCopiedData = true;
    setTimeout(() => this.isCopiedData = false, 1000);
}

}
