import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { get } from 'lodash';

import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { SalesOrderApiService } from 'projects/workspace/src/app/sales-order/services/sales-order-api.service';
import { selectTradeOffer, selectTradeOfferState } from 'projects/workspace/src/app/trade-offer/store/selectors';
import { TradeOfferModel, TradeOfferPropertiesModel } from 'projects/workspace/src/app/trade-offer/models';
import { CustomerTypeEnum, DeliveryTypesEnum, UIStatesEnum } from '../../../../models';
import { UpdatePropertiesBlockValid } from 'projects/workspace/src/app/trade-offer/store/actions/trade-offer.actions';
import { FormInputChangedModel } from 'projects/workspace/src/app/shared/models';
import { TradeOfferApiService } from 'projects/workspace/src/app/trade-offer/services/trade-offer-api.service';
import { ModalNameEnum } from '../../../../models/modal-name.enum';
import { CommonModalsActionsEnum, ConfirmModalComponent } from '../../modals-common';
import { PartnersFieldsChangedModel } from '../../../ui-components/select-partner/update-partner-fields.model';
import { UPDATE_PARTNER_SALES_PRICE_LIST_MODAL_CONFIG } from 'projects/workspace/src/app/shared/constants';
import { AddressTypeEnum } from 'projects/workspace/src/app/sales-order/enums/address-type.enum';
import { CountryModel } from '../../../rnpl-common';
import { selectCountries } from 'projects/workspace/src/app/store/selectors/shared.selectors';
import { OfferFormService } from 'projects/workspace/src/app/trade-offer/services/offer-form.service';
import { convertAddressToStringHelper } from '../../../rnpl-common/helpers';

@Component({
  selector: 'rnpl-offer-select-partner-modal',
  templateUrl: './offer-select-partner-modal.component.html',
})
export class OfferSelectPartnerModalComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public billingInfoForm: FormGroup = new FormGroup({});
  public isReadonly: boolean = true;
  public tradeOffer: TradeOfferModel;
  public modalNameEnum: typeof ModalNameEnum = ModalNameEnum;
  public addressTypeEnum: typeof AddressTypeEnum = AddressTypeEnum;
  public addressTypeForGeneralPartner = [
    {value: AddressTypeEnum.USER_DEFINED, label: 'COMMON.NEW_ADDRESS'},
    {value: AddressTypeEnum.SIMPLIFIED, label: 'COMMON.USER_DEFINED_ADDRESS'},
  ];
  public countries: CountryModel[];

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public dialogRef: MatDialogRef<OfferSelectPartnerModalComponent>,
    private store: Store<AppState>,
    private salesOrderApiService: SalesOrderApiService,
    private readonly fb: FormBuilder,
    private readonly dialog: MatDialog,
    private readonly cdr: ChangeDetectorRef,
    private readonly tradeOfferApiService: TradeOfferApiService,
    public readonly offerFormService: OfferFormService,
  ) { }

  ngOnInit() {
    this.isLoading$.next(true);
    this.selectCountries();
    this.trackTradeOfferChanges();
    this.trackTradeOfferStateChanges();
  }

  private selectCountries() {
    this.store.select(selectCountries)
      .pipe(takeUntil(this.destroy$))
      .subscribe((countriesFromStore: CountryModel[]) => {
        this.countries = countriesFromStore;
      });
  }

  private trackTradeOfferChanges(): void {
    this.store.select(selectTradeOffer)
      .pipe(takeUntil(this.destroy$))
      .subscribe((tradeOffer: TradeOfferModel) => {
        this.tradeOffer = tradeOffer;
        this.initForm(this.tradeOffer.properties);
        this.offerFormService.initBillingInfoForm(tradeOffer);
        this.billingInfoForm = this.offerFormService.billingInfoForm;
        this.setFormsState();
      });
  }

  private trackTradeOfferStateChanges(): void {
    this.store.select(selectTradeOfferState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: UIStatesEnum) => {
        this.isReadonly = state === UIStatesEnum.VIEW;
        this.setFormsState();
      });
  }

  private setFormsState(): void {
    const opts = {onlySelf: true, emitEvent: false};
    if (this.isReadonly) {
      this.form.disable(opts);
      this.billingInfoForm.disable(opts);
      // this.countryControl.disable(opts);
    } else {
      this.form.enable(opts);
      this.billingInfoForm.enable(opts);
      // this.countryControl.enable(opts);
      this.cdr.detectChanges();
    }

    if (!get(this.tradeOffer, 'properties.company.id')) {
      this.form.get('companyContactId').disable(opts);
      this.form.get('email').disable(opts);
      this.form.get('phone').disable(opts);
    }

    if (this.tradeOffer.billingInformation.sameAsDeliveryAddress) {
      this.billingInfoForm.disable(opts);
    }

    if (this.tradeOffer.deliveryInformation.deliveryType === DeliveryTypesEnum.PICK_UP) {
      this.sameAsDeliveryAddressControl.disable(opts);
    }

    if (get(this.tradeOffer, 'properties.company.generalType') === 'inland' && this.billingAddressFormGroup) {
      this.billingAddressFormGroup.get('country_iso3').disable(opts);
    }

    // if (get(this.tradeOffer, 'properties.company.generalType') === 'inland') {
    //   this.countryControl.disable(opts);
    // }
    // if (this.tradeOffer.billingInformation.sameAsDeliveryAddress) {
    //   this.countryControl.disable(opts);
    // }
  }

  public initForm(properties: TradeOfferPropertiesModel = {} as TradeOfferPropertiesModel): void {
    this.form = this.fb.group({
      partnerType: [get(properties, 'partnerType'), [Validators.required]],
      generalPartnerName: [get(properties, 'generalPartnerName'), [Validators.required]],
      generalPartnerVat: [get(properties, 'generalPartnerVat')],
      companyId: [get(properties, 'company.id'), [Validators.required]],
      companyContactId: [get(properties, 'companyContact.id')],
      email: [get(properties, 'email')],
      phone: [get(properties, 'phone')],
    });

    if (this.partnerType.value === CustomerTypeEnum.CORPORATE_PARTNER) {
      this.companyContactId.setValidators(Validators.required);
    }
    this.isLoading$.next(false);
    this.cdr.detectChanges();
    this.store.dispatch(UpdatePropertiesBlockValid({ propertiesBlockValid: this.form.valid }));
  }

  public updateProperties(field: FormInputChangedModel): void {
    field.fieldName = 'properties.' + field.fieldName;
    this.tradeOfferApiService.updateTradeOffer(this.tradeOffer.id, field)
      .subscribe(); // updated via store
  }

  public updateAddressField(field: FormInputChangedModel): void {
    field.fieldName = 'billingInformation.billingAddress.' + field.fieldName;
    this.tradeOfferApiService.updateTradeOffer(this.tradeOffer.id, field)
      .subscribe(); // updated via store
  }

  public updateTradeOffer(fieldValue, fieldName: string): void {
    this.tradeOfferApiService.updateTradeOffer(this.tradeOffer.id, {fieldValue, fieldName})
      .subscribe(); // updated via store
  }

  public updatePartnerField(partnerData: PartnersFieldsChangedModel): void {
    if (partnerData.salesPriceList && partnerData.salesPriceList !== this.tradeOffer.salesPriceList) {
      const dialog = this.dialog.open(ConfirmModalComponent, UPDATE_PARTNER_SALES_PRICE_LIST_MODAL_CONFIG);

      dialog.afterClosed().subscribe((res: CommonModalsActionsEnum) => {
        if (res === CommonModalsActionsEnum.CONFIRM) {
          this.updatePartnerFieldRequest(partnerData, true, true);
        } else if (res === CommonModalsActionsEnum.REJECT) {
          this.updatePartnerFieldRequest(partnerData, false, false);
        } else {
          this.initForm(this.tradeOffer.properties);
        }
      });
      return;
    }

    this.updatePartnerFieldRequest(partnerData);
  }

  public updatePartnerFieldRequest(partnerData: PartnersFieldsChangedModel, updatePositions = false, updateDocument = false): void {
    this.tradeOfferApiService.updateTradeOffer(
      this.tradeOffer.id,
      {
        fieldValue: {
          type: partnerData.partnerType,
          id: partnerData.partnerId,
          updatePositions,
          updateDocument
        },
        fieldName: 'properties.' + partnerData.partnerIdFieldName
      })
      .subscribe(() => {
        this.setFormsState();
      }); // updated via store
  }

  public editAddress(): void {
    this.addressTemplateControl.setValue(AddressTypeEnum.USER_DEFINED);
    this.updateTradeOffer(AddressTypeEnum.USER_DEFINED, 'billingInformation.addressTemplate');
  }

  get addressAsText(): string {
    if (!this.billingInfoForm) { return ''; }

    const addressVal = this.billingAddressFormGroup.value;
    return convertAddressToStringHelper(addressVal, this.countries);
  }

  get isGeneralPartner(): boolean {
    return get(this.tradeOffer, 'properties.partnerType') === CustomerTypeEnum.GENERAL;
  }

  get email(): FormControl { return this.form.get('email') as FormControl; }
  get phone(): FormControl { return this.form.get('phone') as FormControl; }
  get companyId(): FormControl { return this.form.get('companyId') as FormControl; }
  get partnerType(): FormControl { return this.form.get('partnerType') as FormControl; }
  get generalPartnerName(): FormControl { return this.form.get('generalPartnerName') as FormControl; }
  get generalPartnerVat(): FormControl { return this.form.get('generalPartnerVat') as FormControl; }
  get companyContactId(): FormControl { return this.form.get('companyContactId') as FormControl; }
  // get countryControl(): FormControl { return this.offerFormsService.billingInfoForm.get('billingAddress').get('country_iso3') as FormControl; }
  get billingAddressFormGroup(): FormGroup { return this.billingInfoForm.get('billingAddress') as FormGroup; }
  get addressTemplateControl(): FormControl { return this.billingInfoForm.get('addressTemplate') as FormControl; }
  get sameAsDeliveryAddressControl(): FormControl { return this.billingInfoForm.get('sameAsDeliveryAddress') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
