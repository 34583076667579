import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { get } from 'lodash';

import { getHomePageMenuItems } from '../../../home-page/home-page-items';
import { SubscriptionPlanEnum } from 'common/src/modules/subscription-activation/subscription-activation.emum';
import { selectCompanyProfile } from '../../../administration/store/selectors';
import { CompanyProfile } from '../../../administration/models/company-profile.model';
import { HrmService } from '../../../hrm/hrm.service';
import { AuthService } from 'common/src/auth/auth.service';
import { UserPermissionsService } from 'common/src/services/user-permissions.service';
import { SystemPreferencesPermissionsService } from 'common/src/services/system-preferences-permissions.service';
import { AppState } from '../../../store/state/app.state';
import { selectAccountingSettings } from '../../../store/selectors/shared.selectors';
import { AccountingSettingsModel } from '../../../accounting/accounting-settings-module/models';
import { ActionButtonsService } from 'common/src/services/action-buttons.service';
import { AuthUserModel } from 'common/src/auth/auth-user.model';
import { LaunchpadApiService } from 'common/src/modules/start-screen-page/launchpad-api.service';
import { AuthUserPermissionsEnum } from 'common/src/auth/auth-user-permissions.enum';
import { SandboxHintService } from 'common/src/modules/quick-onboarding/sandbox-hint.service';
import { UpdatePaymentCardModalComponent } from 'common/src/modules/modals/modals-subscription-activation';
import { ExitSandboxModalComponent } from 'common/src/modules/quick-onboarding/components/exit-sandbox-modal/exit-sandbox-modal.component';
import { CompanyProfileModeEnum } from '../../../administration/enums';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'rnpl-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss'],
})
export class SideNavigationComponent {

  public companyProfileMode = CompanyProfileModeEnum;
  public onboardingState: string = 'open';
  public authUserPermissions = AuthUserPermissionsEnum;
  public menuItems = [];
  public appsCounters: {[key: string]: number} = {} as {[key: string]: number};
  public companyProfile: CompanyProfile;
  public accountingSettings: AccountingSettingsModel;

  @Input() opened: boolean;
  @Input() loggedUser: AuthUserModel;

  @Output() toggleThemeClicked: EventEmitter<null> = new EventEmitter<null>();
  @Output() changeModeClicked: EventEmitter<null> = new EventEmitter<null>();
  @Output() profileMenuStateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  public showSandboxHint$: Observable<boolean> = this.sandboxHintService.showSandboxHint$;
  public showOverduePaymentHint$: Observable<boolean> = this.sandboxHintService.showOverduePaymentHint$;
  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    public hrmService: HrmService,
    public authService: AuthService,
    public sandboxHintService: SandboxHintService,
    public launchpadApiService: LaunchpadApiService,
    public userPermissionsService: UserPermissionsService,
    private systemPreferencesPermissionsService: SystemPreferencesPermissionsService,
    private actionButtonService: ActionButtonsService,
    private dialog: MatDialog,
    // private router: Router,
    public store: Store<AppState>,
  ) {
    this.store.select(selectAccountingSettings)
      .pipe(takeUntil(this.destroy$))
      .subscribe((accountingSettings: AccountingSettingsModel) => this.accountingSettings = accountingSettings);

    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((companyProfile: CompanyProfile) => {
        this.companyProfile = companyProfile;
        this.getMenuItems();
      });

    this.launchpadApiService.getAppsCounters()
      .pipe(takeUntil(this.destroy$))
      .subscribe(counters => {
        this.appsCounters = counters;
      });

    this.hrmService.getUserProfileAndBlueprintsV2(this.authService.getUser().id, false, true)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.getMenuItems()
      });
  }

  private getMenuItems(): void {
    this.menuItems = getHomePageMenuItems(
      this.userPermissionsService.canViewAccounting(),
      this.userPermissionsService.canViewAdministration(),
      this.userPermissionsService.canViewAnalytics(),
      this.userPermissionsService.canViewEcommerce(),
      this.userPermissionsService.canViewPartners(),
      this.userPermissionsService.canViewProducts(),
      this.userPermissionsService.canViewRetail(),
      this.userPermissionsService.canViewServices(),
      this.userPermissionsService.canViewTeam(),
      this.userPermissionsService.canViewWarehouse(),
      this.userPermissionsService.canViewWholesale(),
      this.systemPreferencesPermissionsService.wholesaleEnabled(),
      this.systemPreferencesPermissionsService.ecommerceEnabled(),
      this.systemPreferencesPermissionsService.servicesEnabled(),
      this.systemPreferencesPermissionsService.goodsProductTypeEnabled(),
      this.systemPreferencesPermissionsService.servicesProductTypeEnabled(),
      this.systemPreferencesPermissionsService.digitalProductTypeEnabled(),
      this.systemPreferencesPermissionsService.corporatePartnersEnabled(),
      this.systemPreferencesPermissionsService.privatePartnersEnabled(),
      this.userPermissionsService.canManageWarehouse(),
      this.userPermissionsService.canManageProducts(),
      this.userPermissionsService.canManagePartners(),
      this.userPermissionsService.canManageAccounting(),
      this.userPermissionsService.canManageEcommerce(),
      this.userPermissionsService.canManageWholesale(),
      this.userPermissionsService.canManageServices(),
      this.isFreePlan || this.isAccountingPlan,
      get(this.accountingSettings, 'reportingSettings.currentReportingType') === 'SIMPLIFIED',
      this.companyProfile.subscriptionManagement.master,
      true
    )
      .filter(i => !i.hasOwnProperty('show') || i.show)
      .map(item => {
      const existedItem = this.menuItems.length && this.menuItems.find(i => i.title === item.title);
      const expanded = existedItem && existedItem.hasOwnProperty('expanded')
        ? existedItem.expanded
        : item.expanded || false;
      const moreFeaturesExpanded = existedItem && existedItem.hasOwnProperty('moreFeaturesExpanded')
        ? existedItem.moreFeaturesExpanded
        : item.moreFeaturesExpanded || false;
      return {
        ...item,
        children: item.children.filter(i => !i.hasOwnProperty('show') || i.show),
        expanded,
        moreFeaturesExpanded
      }
    });
  }

  public actionHandler(action: string, event: Event) {
    event.stopPropagation();
    this.actionButtonService.callAction(action);
  }

  public toggleTheme(): void {
    this.toggleThemeClicked.emit();
  }

  public profileMenuToggle(isOpen): void {
    this.profileMenuStateChanged.emit(isOpen);
  }

  public changeMenuMode(): void {
    this.changeModeClicked.emit();
  }

  public showSandboxHint(): void {
    this.sandboxHintService.setShowHint = true;
  }

  public showOverduePaymentHint(): void {
    this.sandboxHintService.setOverduePaymentShowHint = true;
  }

  // public toggleOnboardingMenu(): void {
  //   this.quickOnboardingToggleService.toggle();
  // }

  public updateOverduePaymentCard(e): void {
    e.stopPropagation();
    this.sandboxHintService.setOverduePaymentShowHint = false;
    this.dialog.open(UpdatePaymentCardModalComponent, {
      maxWidth: '480px',
      panelClass: 'mat-dialog-container-min-width-auto',
      disableClose: true
    });
  }

  public exitSandbox(e): void {
    e.stopPropagation();
    this.sandboxHintService.setShowHint = false;
    this.dialog.open(ExitSandboxModalComponent, {
      data: {
        companyProfile: this.companyProfile
      },
      disableClose: true
    });
  }

  public toggleSandboxHint(e, value: boolean): void {
    e.stopPropagation();
    this.sandboxHintService.setShowHint = value;
  }

  public toggleOverduePaymentHint(e, value: boolean): void {
    e.stopPropagation();
    this.sandboxHintService.setOverduePaymentShowHint = value;
  }

  // public toggleMoreFeatures(event: any, className: string) {
  //   const hasClass = event.target.classList.contains(className);
  //
  //   if (hasClass) {
  //     this.renderer.removeClass(event.target, className);
  //   } else {
  //     this.renderer.addClass(event.target, className);
  //   }
  // }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  get isFreePlan(): boolean {
    return this.companyProfile && this.companyProfile.subscriptionManagement.subscriptionPlan === SubscriptionPlanEnum.FREE;
  }

  get isAccountingPlan(): boolean {
    return this.companyProfile &&
      (this.companyProfile.subscriptionManagement.subscriptionPlan === SubscriptionPlanEnum.ACCOUNTING_MONTHLY ||
        this.companyProfile.subscriptionManagement.subscriptionPlan === SubscriptionPlanEnum.ACCOUNTING_ANNUALLY);
  }

}
