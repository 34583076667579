import { TableActivateTypes } from '../modules/ui-components/table/custom-table.enums';
import { HighlightTypeEnum } from '../modules/ui-components/table-summary-bar/table-summary-bar.model';

export interface ColumnType {
  type?: ColumnTypeEnum;
  disabled?: boolean;
}

export interface TableColumnModel extends ColumnType {
  cellTemplate?: string;
  headerTemplate?: string;
  cellClass?: string;
  controlColorClass?: string;
  controlGroupClass?: string;
  name?: string;
  width?: number;
  maxWidth?: number;
  sortable?: boolean;
  resizeable?: boolean;
  headerClass?: string;
  prop?: string;
  link?: string;
  withoutLink?: boolean;
  clicktype?: TableActivateTypes;
  icon?: string;
  iconColorClass?: string;
  showWithStatuses?: string[];
  prefixText?: string;
  showRowPostfix?: boolean;
  showRowIcon?: boolean;
  showRowCountry?: boolean;
  additionalText?: string;
  additionalIcon?: string;
  additionalTextClass?: string;
  additionalIconClass?: string;
  unitTypeShortcut?: boolean;
  unitTypeDefault?: string;
  currencyConversion?: boolean;
  currencyNegative?: boolean;
  currencySourceFormat?: boolean;
  dueWithinDate?: number;
  capitalizeFirstLetter?: boolean; // capitalize first letter of value
  underscoreSplitting?: boolean; // split value by underscore
  statusClass?: string;
  dateType?: DateColumnType;
  showWithLinkedSo?: boolean;
  showCondition?: boolean;
  sourceTextFormat?: boolean; // pass true if you want show all formatted text without truncating
  highlightTypes?: HighlightTypeEnum[];
  customHighlight?: boolean;
  required?: boolean;
  clearValidator?: boolean;
  frozenRight?: boolean;
  frozenLeft?: boolean;
  rowListPropertyName?: string;
  cellDisabled?: boolean; // disable cell when both (row.cellDisabled = true) and (column.cellDisabled = true)
  placeholder?: string;
  hintsId?: string;
  postFixText?: string;
  customizable?: boolean; // has user opportunity to show/hide this column via settings
  hiddenByDefault?: boolean; // hide column from user by default
  preventTranslate?: boolean; // has user opportunity to show/hide this column via settings
}

export interface TableColumnModelExtended extends TableColumnModel {
  [key: string]: any;
}

export enum ColumnTypeEnum {
  QUANTITY = 'quantity-table',
  STATUS_LINE_OPEN_AMOUNT = 'openAmount',
  STATUS_LINE_CURRENCY_REAL_VAL_ONLY = 'currency-real-val-only',
  MASK = 'mask',
  MASK_NUMBERS = 'maskNumbers',
  NUMBER = 'number',
  STEPPER = 'stepper',
  TEXT = 'text',
  PCS = 'PCS',
  DAYS = 'days',
  DATE = 'date',
  AVAILABILITY = 'availability',
  INT_OR_FLOAT_ONLY = 'integerOrFloat',
  INT_OR_FLOAT_ONLY_UNIT_TYPE = 'integerOrFloatUnitType',
  CURRENCY = 'currency',
}

export enum DateColumnType {
  WITHOUT_TIME = 'without_time',
  WITHOUT_TIME_DEFAULT_COLOR = 'without_time_default_color',
  WITH_TIME = 'with_time',
}
