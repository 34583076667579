import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TradeDocumentsNumbersSettingsModel, TradeSettingsModel } from './models/trade-settings.model';
import { DocumentNumberSettingsModel, ResponseModel } from '../shared/models';
import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { TradeDocumentSettingsModel } from './models';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { DisplayToaster } from '../shared/decorators/toaster';

@Injectable({
  providedIn: 'root'
})
export class TradeSettingsService {

  constructor(
    private readonly http: HttpClient,
    private readonly toasterService: ToasterService,
  ) {
  }

  public getTradeSettings(): Observable<TradeDocumentsNumbersSettingsModel> {
    return this.http.get<ResponseModel<TradeDocumentsNumbersSettingsModel>>('/settings')
      .pipe(
        map((data: ResponseModel<TradeDocumentsNumbersSettingsModel>) => data.data)
      );
  }

  @DisplayToaster({showErrorMessage: true})
  public updateTradeSettings(documentIdSettings: TradeDocumentsNumbersSettingsModel): Observable<TradeDocumentsNumbersSettingsModel> {
    return this.http.patch<ResponseModel<TradeDocumentsNumbersSettingsModel>>('/settings', documentIdSettings)
      .pipe(
        map((data: ResponseModel<TradeDocumentsNumbersSettingsModel>) => data.data)
      );
  }

  public getSettingsByDocument(docType: 'so'|'ofr'|'dn'|'sbc'): Observable<TradeDocumentSettingsModel> {
    return this.http.get<ResponseModel<TradeDocumentSettingsModel>>(`/settings/document/${docType}`)
      .pipe(
        map((data: ResponseModel<TradeDocumentSettingsModel>) => data.data)
      );
  }

  @DisplayToaster({showErrorMessage: true})
  public updateSettingsByDocument(docType: 'so'|'ofr'|'dn'|'sbc', documentIdSettings: TradeDocumentSettingsModel): Observable<TradeDocumentSettingsModel> {
    return this.http.patch<ResponseModel<TradeDocumentSettingsModel>>(`/settings/document/${docType}`, documentIdSettings)
      .pipe(
        map((data: ResponseModel<TradeDocumentSettingsModel>) => data.data)
      );
  }

  @DisplayToaster({showErrorMessage: true})
  public resetSettingsByDocument(docType: 'so'|'ofr'|'dn'|'sbc'): Observable<TradeDocumentSettingsModel> {
    return this.http.request<ResponseModel<TradeDocumentSettingsModel>>('post', `/settings/document/${docType}/reset`)
      .pipe(
        map((data: ResponseModel<TradeDocumentSettingsModel>) => data.data)
      );
  }

  public resetDocumentNumberSettings(documentType: DocumentTypesUppercaseEnum): Observable<any> {
    return this.http.request<ResponseModel<any>>('post', `/settings/document-id/${documentType}/reset-default`)
      .pipe(
        map((data: ResponseModel<any>) => data.data)
      );
  }

  public getSettingsMail(): Observable<TradeSettingsModel> {
    return this.http.get<ResponseModel<TradeSettingsModel>>('/trade/settings')
      .pipe(
        map((data: ResponseModel<TradeSettingsModel>) => data.data)
      );
  }

  public getNextRunpleIdDocumentNumberSettings(
    documentType: DocumentTypesUppercaseEnum,
    runpleIdSettings: DocumentNumberSettingsModel
  ): Observable<string> {

    return this.http.post<ResponseModel<string>>(`/settings/document-id/${documentType}/next-runple-id`, {...runpleIdSettings})
      .pipe(map((response: ResponseModel<string>) => response.data));
  }

}
