import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

import { PositionsActionsType } from '../../../ui-components/table/custom-table.enums';
import { ActionsReorderingPositionModel, PositionsIndexModel } from '../../position-card-models.model';

@Component({
  selector: 'rnpl-action-list',
  templateUrl: './action-list.component.html',
  styleUrls: ['./action-list.component.scss']
})
export class ActionListComponent implements OnInit {

  public positionsActionsType: typeof PositionsActionsType = PositionsActionsType;
  public positionsIndexes: PositionsIndexModel[] = [];
  public rows: any[] = [];

  @Input('positionIndex') public rowIndex: number;
  @Input('positions') public set rowsSetter(rows: any[]) {
    this.rows = rows;
    this.positionsIndexes = this.getPositionsIndex();
  };
  @Input('currentPosition') public row: any;
  @Input() public hideRemoveBtn: boolean;
  @Input() public skipInfoBtn: boolean;
  @Input() public displayEditBtn: boolean;
  @Input() public fromModal: boolean;
  @Input() public isContextMenu: boolean;
  @Input() public isReadOnly: boolean;
  @Input() public showShipInfo: boolean;
  @Input() public showBillInfo: boolean;
  @Input() public isResale: boolean;
  @Input() public contextMenu: ActionListComponent;
  @Input('skipPositionActions') public skipPositionActions: boolean = false;

  @Output() public positionsActionList: EventEmitter<ActionsReorderingPositionModel> = new EventEmitter();

  @ViewChild(MatMenuTrigger, {static: false}) menuTrigger: MatMenuTrigger;

  ngOnInit() {
    this.positionsIndexes = this.getPositionsIndex();
  }


  public getRowOrderNumber(index: number): string | number {
    return index + 1 < 10 ? '0' + (index + 1) : index + 1;
  }

  public positionsActionHandler(actionType, row, rowIndex, moveTo?: number, rows?: any, returnData?: any): void {
    this.positionsActionList.emit({
      rowIndex: rowIndex,
      actionType: actionType,
      row: row,
      moveTo: moveTo,
      top: 0,
      left: 0,
      rows: rows,
      returnData: returnData
    });
  }

  public getPositionsIndex(): Array<PositionsIndexModel> {
    if (!!this.rows.length) {
      return this.rows.map((item, index) => {
        return {
          index,
          title: this.getRowOrderNumber(index)
        };
      });
    }
  }

  // public menuTriggerHandler(event): void {
  //   event.stopPropagation();
  //
  //   if (this.contextMenu) {
  //     this.contextMenu.menuTrigger.closeMenu();
  //   }
  //
  //   this.menuTrigger.openMenu();
  // }

}
