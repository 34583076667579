import { CdkDrag, CdkDragMove, CdkDropList, CdkDropListGroup, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ViewportRuler } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { get, findIndex, remove, isEmpty } from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';

import {
  AppActionsListModel,
  AppListModel,
  AppListTypeEnum,
  AppNameEnum,
  LaunchpadNavEnum,
  LaunchPadNavModel,
  StartScreenModel
} from '../../launchpad.model';
import { NavItem } from '../../../ui-components/head-navigation-process-based/nav-items.config';
import { AuthUserModel } from '../../../../auth/auth-user.model';
import { BACKGROUND_CONTENT, TimeOfDay } from '../../../modals/modals-sign-in/sign-in.config';
import { AppDataByModuleName } from '../../app-data.config';
import { UserPermissionsService } from '../../../../services/user-permissions.service';
import { SystemPreferencesPermissionsService } from '../../../../services/system-preferences-permissions.service';
import { ActionButtonsService } from '../../../../services/action-buttons.service';
import { LaunchpadApiService } from '../../launchpad-api.service';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { selectCompanyProfile, selectOnboarding } from 'projects/workspace/src/app/administration/store/selectors';
import { getLaunchpadNav, getListItemsByActiveTab, RemoveActionConfig, StartScreenContextMenuActions } from '../../launchpad.config';
import { getHomePageMenuItems } from 'projects/workspace/src/app/home-page/home-page-items';
import { LaunchPadAddShortcutsModalComponent } from '../../../modals/launch-pad-add-shortcuts-modal/launch-pad-add-shortcuts-modal.component';
import {
  loadingRequestsCount,
  selectAccountingSettings,
  selectUserStartScreenItems
} from 'projects/workspace/src/app/store/selectors/shared.selectors';
import { filerListByUniqElements } from '../../start-screen.helper';
import { ProductsOptions } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { HrmService } from 'projects/workspace/src/app/hrm/hrm.service';
import { AuthService } from '../../../../auth/auth.service';
import { IncomingInvoiceModel } from 'projects/workspace/src/app/incoming-invoice/models/incoming-invoice.model';
import { IncomingInvoiceApiService } from 'projects/workspace/src/app/incoming-invoice/services/invoice-api.service';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { SubscriptionPlanEnum } from '../../../subscription-activation/subscription-activation.emum';
import { OnboardingModel } from 'projects/workspace/src/app/administration/models/onboarding.model';
import { AccountingSettingsModel } from '../../../../../../projects/workspace/src/app/accounting/accounting-settings-module/models';

@Component({
  selector: 'rnpl-start-screen-page',
  templateUrl: './start-screen-page.component.html',
  styleUrls: ['./start-screen-page.component.scss']
})
export class StartScreenPageComponent implements OnInit, OnDestroy, AfterViewInit {
  public dragEnded: boolean = true;
  public isPushedElement: boolean = false;
  public isOverIcon: boolean = true;
  public isAddedScreen: boolean = false;
  public isLeftHandler: boolean = false;
  public isEditMode: boolean = false;
  public isMovedElement: boolean = false;
  public draggableElement = null;
  public draggableFolder = null;
  public draggableItem = null;
  public draggableNavItemIndex = null;
  public countdownTimer;
  public timerDelay = 6;
  public timerDelayForEditMode = 100;
  public activeScreenCount = 0;
  public draggedIndex: number;
  public relativeValue: number = 1.694;

  public menuItems = [];
  public versions: Array<any> = [];
  public isScrolled: boolean;
  public isOpenModal: boolean;

  public isOpenFolder: boolean;
  public removeFromStartScreenRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public updateStartScreenListRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public index: number;
  public screenIndex: number;
  public folderData: AppListModel;
  public appListTypeEnum: typeof AppListTypeEnum = AppListTypeEnum;

  public activeTab: LaunchpadNavEnum = LaunchpadNavEnum.ALL_FUNCTIONS;
  public launchPadNav: LaunchPadNavModel[] = [];
  public launchpadNavEnum: typeof LaunchpadNavEnum = LaunchpadNavEnum;
  public listItemsByActiveTab: NavItem[];
  public user: AuthUserModel = null;
  public backGroundContent = BACKGROUND_CONTENT;

  public startScreenList: StartScreenModel[] = [];

  public timeOfDay = TimeOfDay();

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public isLoading$: Observable<number> = this.store.select(loadingRequestsCount);

  public target: CdkDropList;
  public targetIndex: number;
  public source: CdkDropList;
  public sourceIndex: number;
  public activeContainer;
  public isOutFolder: boolean = true;
  public isOutElement: boolean = true;

  public isMobile: boolean = false;

  public appDataByModuleName: {[key: string]: any} = {} as {[key: string]: any};
  public startScreenContextMenuActions: AppActionsListModel = StartScreenContextMenuActions;
  public contextMenuLisFromFolder: AppActionsListModel = {
    actions: [
      {
        action: 'setEditMode',
        title: 'BUTTON.EDIT',
        icon: 'edit'
      },
    ]
  };

  public maxPageListLength = 28;
  public appsCounters: {[key: string]: number} = {} as {[key: string]: number};
  public productOptions: ProductsOptions;
  public maxPageListLengthWithOnboarding = 20;
  public companyProfile: CompanyProfile;
  public onboarding: OnboardingModel;
  public accountingSettings: AccountingSettingsModel;
  public isDisplayOnboarding: boolean;

  @ViewChild(CdkDropListGroup, {static: false}) listGroup: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList, {static: false}) placeholder: CdkDropList;
  @ViewChild('pageContainer', { read: ElementRef, static: false }) public pageContainer: ElementRef<any>;

  @Input() public fromModal: boolean;

  constructor(
    public hrmService: HrmService,
    public authService: AuthService,
    public userPermissionsService: UserPermissionsService,
    private systemPreferencesPermissionsService: SystemPreferencesPermissionsService,
    private actionButtonService: ActionButtonsService,
    private router: Router,
    private viewportRuler: ViewportRuler,
    private launchpadApiService: LaunchpadApiService,
    private cd: ChangeDetectorRef,
    private readonly dialog: MatDialog,
    public store: Store<AppState>,
    private incomingInvoiceApiService: IncomingInvoiceApiService,
    private deviceService: DeviceDetectorService
  ) {
    this.target = null;
    this.source = null;

    this.isMobile = this.deviceService.isMobile();

    this.store.select(selectAccountingSettings)
      .pipe(takeUntil(this.destroy$))
      .subscribe((accountingSettings: AccountingSettingsModel) => this.accountingSettings = accountingSettings);

    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((companyProfile: CompanyProfile) => {
        this.companyProfile = companyProfile;
        // todo: change productOptions to userProductOptions after Runple Subscription 2.0 implementation
        this.productOptions = companyProfile.productOptions;
        this.getAppListConfig();
        this.getMenuItems();
        this.getListItems();
      });
  }

  ngAfterViewInit() {
    const phElement = get(this.placeholder, 'element.nativeElement');
    if (phElement) {
      phElement.style.display = 'none';
      phElement.parentElement.removeChild(phElement);
    }

    this.cd.detectChanges();
  }

  ngOnInit() {
    this.trackStartScreenItemsListChanges();
    this.getAppsCounters();

    this.hrmService.getUserProfileAndBlueprintsV2(this.authService.getUser().id, false, true)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.getAppListConfig();
        this.getMenuItems();
        this.getListItems();
      });

    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((companyProfile: CompanyProfile) => {
        this.companyProfile = companyProfile;
        this.displayOnboarding();
        this.launchPadNav = getLaunchpadNav(this.isAccountingPlan);

        if (this.isDisplayOnboarding) {
          this.maxPageListLength = this.maxPageListLengthWithOnboarding;
        }
      });

    this.store.select(selectOnboarding)
      .pipe(takeUntil(this.destroy$))
      .subscribe((onboarding: OnboardingModel) => {
        this.onboarding = onboarding;
        this.displayOnboarding();

        if (this.isDisplayOnboarding) {
          this.maxPageListLength = this.maxPageListLengthWithOnboarding;
        }
      });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'ArrowRight') {
      event.preventDefault();
      this.scrollRight(1);
    }

    if (event.key === 'ArrowLeft') {
      event.preventDefault();
      this.scrollLeft(1);
    }
  }

  public trackStartScreenItemsListChanges(): void {
    this.store.select(selectUserStartScreenItems)
      .pipe(takeUntil(this.destroy$))
      .subscribe((listItems) => {
        this.startScreenList = filerListByUniqElements(JSON.parse(JSON.stringify(listItems)));
      });
  }

  public getAppsCounters(): void {
    this.launchpadApiService.getAppsCounters()
      .pipe(takeUntil(this.destroy$))
      .subscribe(counters => {
        this.appsCounters = counters;
      });
  }

  private getListItems(): void {
    this.listItemsByActiveTab = getListItemsByActiveTab(
      this.activeTab,
      this.userPermissionsService.canViewAccounting(),
      this.userPermissionsService.canViewAdministration(),
      this.userPermissionsService.canEditAdministration(),
      this.userPermissionsService.canViewAnalytics(),
      this.userPermissionsService.canViewEcommerce(),
      this.userPermissionsService.canViewPartners(),
      this.userPermissionsService.canViewProducts(),
      this.userPermissionsService.canViewRetail(),
      this.userPermissionsService.canViewServices(),
      this.userPermissionsService.canViewTeam(),
      this.userPermissionsService.canViewWarehouse(),
      this.userPermissionsService.canViewWholesale(),
      this.systemPreferencesPermissionsService.wholesaleEnabled(),
      this.systemPreferencesPermissionsService.ecommerceEnabled(),
      this.systemPreferencesPermissionsService.servicesEnabled(),
      this.systemPreferencesPermissionsService.goodsProductTypeEnabled(),
      this.systemPreferencesPermissionsService.servicesProductTypeEnabled(),
      this.systemPreferencesPermissionsService.digitalProductTypeEnabled(),
      this.systemPreferencesPermissionsService.corporatePartnersEnabled(),
      this.systemPreferencesPermissionsService.privatePartnersEnabled(),
      this.userPermissionsService.canManageWarehouse(),
      this.userPermissionsService.canManageProducts(),
      this.userPermissionsService.canManagePartners(),
      this.userPermissionsService.canManageAccounting(),
      this.isFreePlan || this.isAccountingPlan,
      get(this.accountingSettings, 'reportingSettings.currentReportingType'),
    );
  }

  private getMenuItems(): void {
    this.menuItems = getHomePageMenuItems(
      this.userPermissionsService.canViewAccounting(),
      this.userPermissionsService.canViewAdministration(),
      this.userPermissionsService.canViewAnalytics(),
      this.userPermissionsService.canViewEcommerce(),
      this.userPermissionsService.canViewPartners(),
      this.userPermissionsService.canViewProducts(),
      this.userPermissionsService.canViewRetail(),
      this.userPermissionsService.canViewServices(),
      this.userPermissionsService.canViewTeam(),
      this.userPermissionsService.canViewWarehouse(),
      this.userPermissionsService.canViewWholesale(),
      this.systemPreferencesPermissionsService.wholesaleEnabled(),
      this.systemPreferencesPermissionsService.ecommerceEnabled(),
      this.systemPreferencesPermissionsService.servicesEnabled(),
      this.systemPreferencesPermissionsService.goodsProductTypeEnabled(),
      this.systemPreferencesPermissionsService.servicesProductTypeEnabled(),
      this.systemPreferencesPermissionsService.digitalProductTypeEnabled(),
      this.systemPreferencesPermissionsService.corporatePartnersEnabled(),
      this.systemPreferencesPermissionsService.privatePartnersEnabled(),
      this.userPermissionsService.canManageWarehouse(),
      this.userPermissionsService.canManageProducts(),
      this.userPermissionsService.canManagePartners(),
      this.userPermissionsService.canManageAccounting(),
      this.userPermissionsService.canManageEcommerce(),
      this.userPermissionsService.canManageWholesale(),
      this.userPermissionsService.canManageServices(),
      this.isFreePlan || this.isAccountingPlan,
      get(this.accountingSettings, 'reportingSettings.currentReportingType') === 'SIMPLIFIED',
      this.companyProfile.subscriptionManagement.master,
      false
    );
  }

  private getAppListConfig(): void {
    const wholesaleEnabled = this.systemPreferencesPermissionsService.wholesaleEnabled();
    const servicesEnabled = this.systemPreferencesPermissionsService.servicesEnabled();
    const ecommerceEnabled = this.systemPreferencesPermissionsService.ecommerceEnabled();
    const canViewWholesale = this.userPermissionsService.canViewWholesale();
    const canViewServices = this.userPermissionsService.canViewServices();
    const canViewEcommerce = this.userPermissionsService.canViewEcommerce();

    this.appDataByModuleName = AppDataByModuleName(
      this.userPermissionsService.canManageProducts(),
      this.userPermissionsService.canManagePartners(),
      this.userPermissionsService.canManageWarehouse(),
      this.userPermissionsService.canManageAccounting(),
      this.userPermissionsService.canManageWholesale(),
      this.userPermissionsService.canManageServices(),
      this.productOptions.goodsEnabled,
      this.productOptions.servicesEnabled,
      this.productOptions.digitalEnabled,
      this.systemPreferencesPermissionsService.corporatePartnersEnabled(),
      this.systemPreferencesPermissionsService.privatePartnersEnabled(),
      (canViewWholesale || canViewServices) && (wholesaleEnabled || servicesEnabled || this.isFreePlan || this.isAccountingPlan),
      (canViewWholesale || canViewEcommerce || canViewServices),
    );
  }

  public actionHandler(action: string, event: Event) {
    event.stopPropagation();
    this.actionButtonService.callAction(action);
  }

  public showItem(item): boolean {
    if (item.hasOwnProperty('show')) {
      return item.show;
    }
    return true;
  }

  public hasControlCenter(): boolean {
    return this.userPermissionsService.canViewAccounting() ||
      this.userPermissionsService.canViewWarehouse() ||
      this.userPermissionsService.canViewWholesale();
  }

  public openFolder(folder, event, index, screenIndex): void {
    event.stopPropagation();
    event.preventDefault();

    if (!this.isOpenFolder) {
      this.folderData = folder;
      this.isOpenFolder = true;
      this.index = index;
      this.screenIndex = screenIndex;
      this.cd.detectChanges();
    }
  }

  public closeFolder(): void {
    this.isOpenFolder = false;
  }

  public setActiveTab(activeTab: LaunchpadNavEnum): void {
    this.activeTab = activeTab;
    this.getListItems();
  }

  private getStartScreenAps(): void {
    this.launchpadApiService.getStartScreenApps()
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  dropListEnterPredicate = (drag: CdkDrag, drop: CdkDropList): boolean => {
    if (drop == this.placeholder) {
      return true;
    }

    if (drop != this.activeContainer) {
      return false;
    }

    const phElement = this.placeholder.element.nativeElement;
    const sourceElement = drag.dropContainer.element.nativeElement;
    const dropElement = drop.element.nativeElement;

    const dragIndex = __indexOf(dropElement.parentElement.children, (this.source ? phElement : sourceElement));
    const dropIndex = __indexOf(dropElement.parentElement.children, dropElement);

    if (!this.source) {
      this.sourceIndex = dragIndex;
      this.source = drag.dropContainer;

      phElement.style.width = sourceElement.clientWidth + 'px';
      phElement.style.height = sourceElement.clientHeight + 'px';

      sourceElement.parentElement.removeChild(sourceElement);
    }

    this.targetIndex = dropIndex;
    this.target = drop;

    phElement.style.display = null;
    dropElement.parentElement.insertBefore(
      phElement,
      (dropIndex > dragIndex ? dropElement.nextSibling : dropElement)
    );

    this.placeholder.enter(drag, drag.element.nativeElement.offsetLeft, drag.element.nativeElement.offsetTop);
    return false;
  }

  dropListDropped(): void {
    if (this.isOutFolder && this.isOutElement) {
      this.isMovedElement = false;

      if (!this.target) {
        return;
      }

      const phElement = this.placeholder.element.nativeElement;
      const parent = phElement.parentElement;

      phElement.style.display = 'none';

      parent.removeChild(phElement);
      parent.appendChild(phElement);
      parent.insertBefore(this.source.element.nativeElement, parent.children[this.targetIndex]);

      this.target = null;
      this.source = null;

      if (this.sourceIndex !== this.targetIndex) {
        moveItemInArray(this.startScreenList[this.activeScreenCount].items, this.sourceIndex, this.targetIndex);
        this.updateStartScreenList(this.startScreenList);
      }
    }
  }

  dragMoved(e: CdkDragMove): void {
    // if (!this.isEditMode) {
    //   const point = this.getPointerPositionOnPage(e.event);
    //   this.listGroup._items.forEach(dropList => {
    //     if (__isInsideDropListClientRect(dropList, point.x, point.y)) {
    //       this.activeContainer = dropList;
    //       return;
    //     }
    //   });
    // }

    setTimeout(() => {
      if (!this.isOverIcon) {
        const point = this.getPointerPositionOnPage(e.event);
        this.listGroup._items.forEach(dropList => {
          if (__isInsideDropListClientRect(dropList, point.x, point.y)) {
            this.activeContainer = dropList;
            return;
          }
        });
      }
    }, 500);

  }

  /** Determines the point of the page that was touched by the user. */
  getPointerPositionOnPage(event: MouseEvent | TouchEvent): {[key: string]: number} {
    // `touches` will be empty for start/end events so we have to fall back to `changedTouches`.
    const point = __isTouchEvent(event) ? (event.touches[0] || event.changedTouches[0]) : event;

    const scrollPosition = this.viewportRuler.getViewportScrollPosition();

    return {
      x: point.pageX - scrollPosition.left,
      y: point.pageY - scrollPosition.top
    };
  }

  public elementOverHandler(navItem: AppListModel, index: number, isOver: boolean, screenIndex: number): void {

    if (!this.draggableElement || !this.draggableItem) { return; }
    this.isOutElement = false;
    let timer: number = this.timerDelay;
    const prepareNavItem = JSON.parse(JSON.stringify(navItem));
    if (isOver) {
      this.isOverIcon = true;
      const draggableItem = this.draggableItem;
      this.folderData = prepareNavItem;
      this.index = index;
      this.screenIndex = screenIndex;
      // if (!this.updateStartScreenListRequest$.value) {
        const countdownTimer = setInterval(() => {
          timer -= 1;
          if (this.isOutElement) {
            clearInterval(countdownTimer);
          }
          if (timer <= 0) {
            prepareNavItem.type = AppListTypeEnum.FOLDER;
            prepareNavItem.name = null;

            if (findIndex(prepareNavItem.items, draggableItem) === -1) {
              prepareNavItem.items.push(draggableItem);
            }

            prepareNavItem.items = this.getUniqueListBy(prepareNavItem.items, 'name');
            this.isPushedElement = true;
            this.draggableElement = null;
            this.draggableItem = null;
            this.isOpenFolder = true;
            this.startScreenList[screenIndex].items.splice(index, 1, prepareNavItem);
            remove(this.startScreenList[screenIndex].items, draggableItem);
            this.updateStartScreenListRequest$.next(true);
            clearInterval(countdownTimer);
            this.updateStartScreenList(this.startScreenList);
          }
        }, 100);
      // }
    } else {
      if (!!prepareNavItem.items.length && prepareNavItem.items.length > 1 && this.isPushedElement) {
        prepareNavItem.items.pop();
      }

      this.isOpenFolder = false;
      this.isOverIcon = false;
      prepareNavItem.type = prepareNavItem.items.length > 1 ? AppListTypeEnum.FOLDER : AppListTypeEnum.SINGLE;

      this.isPushedElement = false;
    }

    this.cd.detectChanges();
  }

  public folderOverHandler(navItem: AppListModel, index: number, screenIndex: number): void {
    if (
      !this.draggableElement
      || !this.draggableItem
      || navItem.items.some(itm => itm.name === this.draggableElement.name)
      || this.isOverIcon
    ) {
      return;
    }

    this.isOutFolder = false;
    let timer: number = this.timerDelay;
    const prepareNavItem = navItem;
    const draggableItem = this.draggableItem;

    // if (!this.updateStartScreenListRequest$.value) {
      const countdownTimer = setInterval(() => {
        timer -= 1;

        if (this.isOutFolder) {
          clearInterval(countdownTimer);
        }

        if (timer <= 0) {
          clearInterval(countdownTimer);
          this.isOpenFolder = true;
          if (findIndex(prepareNavItem.items, draggableItem) === -1) {
            prepareNavItem.items.push(draggableItem);
          }
          prepareNavItem.items = this.getUniqueListBy(prepareNavItem.items, 'name');
          this.isPushedElement = true;
          this.folderData = prepareNavItem;
          this.index = index;
          this.screenIndex = screenIndex;
          this.draggedIndex = null;
          this.draggableElement = null;
          this.draggableItem = null;
          this.startScreenList[screenIndex].items.splice(index, 1, prepareNavItem);
          remove(this.startScreenList[screenIndex].items, draggableItem);
          this.updateStartScreenList(this.startScreenList);
        }
      }, 100);
    // }
  }

  public clearIntervalHandler(item): void {
    this.isOverIcon = false;
    this.isOpenFolder = false;
    clearInterval(this.countdownTimer);
    this.elementOutHandler(item);
  }

  public dragStartedHandler(app: AppListModel, item: AppListModel, draggedIndex?: number): void {
    this.draggedIndex = draggedIndex;
    this.dragEnded = false;
    this.draggableElement = app;
    this.draggableItem = item;
    this.draggableNavItemIndex = null;
    this.isLeftHandler = false;
    this.isAddedScreen = false;
  }

  public dragEndedHandler(navItemIndex: number, screenIndex: number): void {
    this.draggableElement = null;
    this.draggableItem = null;
    this.draggableNavItemIndex = navItemIndex;
  }

  public addScreen(): void {
    if (this.isAddedScreen) {
      return;
    }
    const screen = {
      screenCount: this.startScreenList[this.startScreenList.length - 1].screenCount + 1,
      items: [],
      isActive: true,
      name: ''
    };
    this.startScreenList.push(screen);
    const draggableElement = this.draggableFolder || this.draggableItem;
    const previousIndex = this.startScreenList[this.activeScreenCount].items.findIndex(item => item === draggableElement);

    transferArrayItem(
      this.startScreenList[this.activeScreenCount].items,
      this.startScreenList[this.activeScreenCount + 1].items,
      previousIndex,
      0
    );

    setTimeout(() => {
      this.scrollRight(1);
    });

    this.isAddedScreen = true;
  }

  public updateStartScreenList(screenList): void {
    // this.updateStartScreenListRequest$.next(true);
    this.launchpadApiService.updateStartScreenApps(filerListByUniqElements(screenList))
      .pipe(
        // finalize(() => {this.updateStartScreenListRequest$.next(false)}),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
      }, error => {
        if (error.error.message.includes('Duplicate item')) {
          this.closeFolder();
        }
        this.getStartScreenAps();
      });
  }

  public scrollToScreen(screenCount: number): void {
    if (screenCount === this.activeScreenCount) { return; }
    if (screenCount > this.activeScreenCount) {
      this.scrollRight(screenCount - this.activeScreenCount);
    } else {
      this.scrollLeft(this.activeScreenCount - screenCount);
    }

    this.activeScreenCount = screenCount;
  }

  public scrollLeft(step: number): void {
    const pageScreenWidth = this.pageContainer.nativeElement.clientWidth;
    this.pageContainer.nativeElement.scrollTo(
      { left: (this.pageContainer.nativeElement.scrollLeft - (pageScreenWidth * step)), behavior: 'smooth' }
    );
  }

  public scrollRight(step: number): void {
    const pageScreenWidth = this.pageContainer.nativeElement.clientWidth;
    this.pageContainer.nativeElement.scrollTo(
      { left: (this.pageContainer.nativeElement.scrollLeft + (pageScreenWidth * step)), behavior: 'smooth' }
    );
  }

  public scrollScreenHandler(target: HTMLElement): void {
    this.activeScreenCount = Math.round(target.scrollLeft / target.clientWidth);
  }

  public changeScreenHandlerRight(): void {
    if (
      (!!this.draggableFolder || !!this.draggableItem)
      && !this.isLeftHandler
      && this.startScreenList[this.activeScreenCount].items.length > 1
    ) {
      const draggableElement = this.draggableFolder || this.draggableItem;
      if (!!this.startScreenList[this.activeScreenCount + 1]) {
        const previousIndex = this.startScreenList[this.activeScreenCount].items.findIndex(item => item === draggableElement);
        transferArrayItem(
          this.startScreenList[this.activeScreenCount].items,
          this.startScreenList[this.activeScreenCount + 1].items,
          previousIndex,
          this.startScreenList[this.activeScreenCount + 1].items.length
        );
        this.scrollRight(1);
      } else {
        this.addScreen();
      }

      this.updateStartScreenList(this.startScreenList);
    }

    this.draggableFolder = null;
    this.draggableItem = null;
    this.draggableElement = null;
  }

  public changeScreenHandlerLeft(): void {
    if ((!!this.draggableFolder || !!this.draggableItem) && this.activeScreenCount !== 0) {
      const draggableElement = this.draggableFolder || this.draggableItem;
      this.isLeftHandler = true;
      this.scrollLeft(1);
      const previousIndex = this.startScreenList[this.activeScreenCount].items.findIndex(item => item === draggableElement);
      if (this.startScreenList[this.activeScreenCount - 1].items.length === this.maxPageListLength) {
        transferArrayItem(
          this.startScreenList[this.activeScreenCount - 1].items,
          this.startScreenList[this.activeScreenCount].items,
          this.startScreenList[this.activeScreenCount - 1].items.length,
          this.startScreenList[this.activeScreenCount].items.length,
        );

        transferArrayItem(
          this.startScreenList[this.activeScreenCount].items,
          this.startScreenList[this.activeScreenCount - 1].items,
          previousIndex,
          this.startScreenList[this.activeScreenCount - 1].items.length
        );

      } else {
        transferArrayItem(
          this.startScreenList[this.activeScreenCount].items,
          this.startScreenList[this.activeScreenCount - 1].items,
          previousIndex,
          this.startScreenList[this.activeScreenCount - 1].items.length
        );
      }

      this.updateStartScreenList(this.startScreenList);
    } else {
      this.isLeftHandler = false;
    }
    this.draggableFolder = null;
    this.draggableItem = null;
    this.draggableElement = null;
  }

  public actionListHandler(actionName: string): void {
    if (this.actionButtonService[actionName]) {
      this.actionButtonService[actionName]();
    }
  }

  public contextMenuActionsHandler(action: string, appName?: AppNameEnum): void {
    if (this[action]) {
      if (action === 'removeFromStartScreen') {
        this[action](appName);
      } else {
        this[action]();
      }
    } else {
      this.actionButtonService.callAction(action);
    }
  }

  public setEditModeByMouseDown(event: MouseEvent): void {
    if (event.button !== 0) { return; }
    let timer: number = this.timerDelayForEditMode;

    this.countdownTimer = setInterval(() => {
      if (!this.isMovedElement && !this.isEditMode) {
        timer -= 1;
        if (timer <= 0) {
          clearInterval(this.countdownTimer);
          this.isEditMode = !this.isMovedElement;
        }
      } else {
        clearInterval(this.countdownTimer);
      }
    }, 10);
  }

  public setEditMode(): void {
    this.isEditMode = true;
  }

  public openHandbook(): void {
    window.open('https://docs.runple.com/', '_blank');
  }

  public removeFromStartScreen(appName): void {
    if (!this.removeFromStartScreenRequest$.value) {
      this.removeFromStartScreenRequest$.next(true);
      this.launchpadApiService.removeAppFromStartScreen(appName)
        .pipe(
          finalize(() => this.removeFromStartScreenRequest$.next(false)),
          takeUntil(this.destroy$)
        )
        .subscribe();
    }
  }

  public addShortcutsModal(): void {
    this.isOpenModal = true;
    const dialog = this.dialog.open(LaunchPadAddShortcutsModalComponent, {
      data: {
        screenCount: this.activeScreenCount,
      },
      width: '342px',
      minWidth: '342px',
      maxWidth: '342px',
      panelClass: ['add-shortcuts-modal', 'sm-modal'],
      hasBackdrop: false,
      disableClose: false,
    });

   dialog.afterClosed().subscribe(() => this.isOpenModal = false);
  }

  public openContextMenu(event: MouseEvent, menuTrigger: MatMenuTrigger): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
      menuTrigger.openMenu();
    }
  }


  private getUniqueListBy(arr: Array<any>, key: string): Array<any> {
    return [...new Map(arr.map(item => [item[key], item])).values()];
  }

  public folderOutHandler(item): void {
    if (!this.draggableItem) {
      this.isOutFolder = true;
    } else {
      this.isOutFolder = this.draggableItem !== item;
    }
  }

  public elementOutHandler(item): void {
    if (!this.draggableItem) {
      this.isOutElement = true;
    } else {
      this.isOutElement = this.isOutElement !== item;
    }
  }

  public getAppActionList(itemName: AppNameEnum, hideRemoveBtn?: boolean): AppActionsListModel {
    if (!this.appDataByModuleName[itemName]) { return; }

    return {
      appName: itemName,
      actions: [
        ...this.appDataByModuleName[itemName].actionList,
        !hideRemoveBtn ?  RemoveActionConfig : null
      ]
    };
  }

  public getRelativeSize(parentElementWidth: number, ratio: number, minSize: number = 14): number {
    const calculatedSize = parentElementWidth * ratio;
    return calculatedSize > minSize ? calculatedSize : minSize;
  }

  public checkItemsListByTabName(tabName: LaunchpadNavEnum): boolean {
    if (tabName === LaunchpadNavEnum.START_SCREEN || tabName === LaunchpadNavEnum.ALL_FUNCTIONS) {
      return true;
    }

    return getListItemsByActiveTab(
      tabName,
      this.userPermissionsService.canViewAccounting(),
      this.userPermissionsService.canViewAdministration(),
      this.userPermissionsService.canEditAdministration(),
      this.userPermissionsService.canViewAnalytics(),
      this.userPermissionsService.canViewEcommerce(),
      this.userPermissionsService.canViewPartners(),
      this.userPermissionsService.canViewProducts(),
      this.userPermissionsService.canViewRetail(),
      this.userPermissionsService.canViewServices(),
      this.userPermissionsService.canViewTeam(),
      this.userPermissionsService.canViewWarehouse(),
      this.userPermissionsService.canViewWholesale(),
      this.systemPreferencesPermissionsService.wholesaleEnabled(),
      this.systemPreferencesPermissionsService.ecommerceEnabled(),
      this.systemPreferencesPermissionsService.servicesEnabled(),
      this.systemPreferencesPermissionsService.goodsProductTypeEnabled(),
      this.systemPreferencesPermissionsService.servicesProductTypeEnabled(),
      this.systemPreferencesPermissionsService.digitalProductTypeEnabled(),
      this.systemPreferencesPermissionsService.corporatePartnersEnabled(),
      this.systemPreferencesPermissionsService.privatePartnersEnabled(),
      this.userPermissionsService.canManageWarehouse(),
      this.userPermissionsService.canManageProducts(),
      this.userPermissionsService.canManagePartners(),
      this.userPermissionsService.canManageAccounting(),
      this.isFreePlan || this.isAccountingPlan,
      get(this.accountingSettings, 'reportingSettings.currentReportingType') === 'SIMPLIFIED',
    ).some(app => app.show);
  }

  public trackByFn(item) {
    return item;
  }

  public createNewIncomingInvoiceMobile() {
    this.incomingInvoiceApiService.createIncomingInvoiceBlank()
      .subscribe((data: IncomingInvoiceModel) => {
        this.router.navigate([`/accounting/invoice-uploader/${data.id}`]);
      });
  }

  get progress(): number {
    if (!this.onboarding || !this.onboarding.progress || isEmpty(this.onboarding)) { return 0; }
    return (this.onboarding.progress.completed * 100) / this.onboarding.progress.total;
  }

  public displayOnboarding(): void {
    this.isDisplayOnboarding = this.companyProfile
      && this.companyProfile.entityState === 'EXISTS'
      && (!this.companyProfile.onboardingCompleted && (this.progress !== 100));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  get isFreePlan(): boolean {
    return this.companyProfile && this.companyProfile.subscriptionManagement.subscriptionPlan === SubscriptionPlanEnum.FREE;
  }

  get isAccountingPlan(): boolean {
    return this.companyProfile &&
      (this.companyProfile.subscriptionManagement.subscriptionPlan === SubscriptionPlanEnum.ACCOUNTING_MONTHLY ||
      this.companyProfile.subscriptionManagement.subscriptionPlan === SubscriptionPlanEnum.ACCOUNTING_ANNUALLY);
  }

}

function __indexOf(collection, node): number {
  return Array.prototype.indexOf.call(collection, node);
}

/** Determines whether an event is a touch event. */
function __isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
  return event.type.startsWith('touch');
}

function __isInsideDropListClientRect(dropList: CdkDropList, x: number, y: number): boolean {
  const {top, bottom, left, right} = dropList.element.nativeElement.getBoundingClientRect();
  return y >= top && y <= bottom && x >= left && x <= right;
}

