import { createSelector } from '@ngrx/store';

import { EcoOrderBlocksValidationsState, EcoOrderFeatureKey, EcoOrderState, EcoOrderStore } from '../reducers';
import { AppState } from '../../../../../store/state/app.state';


export const selectFeature = ((state: AppState) => state[EcoOrderFeatureKey]);

export const selectEcoOrderEntity = createSelector(
  selectFeature,
  (state: EcoOrderStore) => state.entity
);

export const selectEcoOrderList = createSelector(
  selectFeature,
  (state: EcoOrderStore) => state.list
);

export const selectEcoOrderBlocksValidations = createSelector(
  selectFeature,
  (state: EcoOrderStore) => state.blocksValidations
);

export const selectEcoOrder = createSelector(
  selectEcoOrderEntity,
  (state: EcoOrderState) => state.ecoOrder
);

export const selectEcoOrderPositions = createSelector(
  selectEcoOrderEntity,
  (state: EcoOrderState) => state.positions
);

export const selectSalesOrderPositionsCount = createSelector(
  selectEcoOrderEntity,
  (state: EcoOrderState) => state.positionsCount
);

export const selectPartners = createSelector(
  selectEcoOrderEntity,
  (state: EcoOrderState) => state.partners
);

export const selectEcoOrderCurrentState = createSelector(
  selectEcoOrderEntity,
  (state: EcoOrderState) => state.currentState
);

export const loadingRequestsCount = createSelector(
  selectEcoOrderEntity,
  (state: EcoOrderState) => state.loadingRequestsCount
);

export const salesOrderUpdatedAt = createSelector(
  selectEcoOrderEntity,
  (state: EcoOrderState) => state.updatedAt
);

export const isShouldRefresh = createSelector(
  selectEcoOrderEntity,
  (state: EcoOrderState) => state.isShouldRefresh
);

export const selectEcoOrderPropertiesBlockValid = createSelector(
  selectEcoOrderBlocksValidations,
  (state: EcoOrderBlocksValidationsState) => state.propertiesBlockValid
);

export const selectEcoOrderDeliveryInfoBlockValid = createSelector(
  selectEcoOrderBlocksValidations,
  (state: EcoOrderBlocksValidationsState) => state.deliveryInfoBlockValid
);

export const selectEcoOrderBillingInfoBlockValid = createSelector(
  selectEcoOrderBlocksValidations,
  (state: EcoOrderBlocksValidationsState) => state.billingInfoBlockValid
);

export const selectEcoOrderPositionsBlockValid = createSelector(
  selectEcoOrderBlocksValidations,
  (state: EcoOrderBlocksValidationsState) => state.positionsBlockValid
);
