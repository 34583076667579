import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ReplaySubject } from 'rxjs';
import { get } from 'lodash';

import { SubscriptionApiService } from 'projects/workspace/src/app/subscription/services/subscription-api.service';
import {
  selectSubscription,
  selectSubscriptionCurrentState,
  selectCountries
} from 'projects/workspace/src/app/subscription/store/selectors';
import { SubscriptionModel } from 'projects/workspace/src/app/subscription/models/subscription.model';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { PaymentMethodsEnum, TransferMethodsEnum, UIStatesEnum } from '../../../../models';
import { CountryModel } from '../../../rnpl-common';
import { convertAddressToStringHelper } from '../../../rnpl-common/helpers';
import { FormInputChangedModel } from 'projects/workspace/src/app/shared/models';
import { UpdateBillingInfoBlockValid } from 'projects/workspace/src/app/subscription/store/actions/subscription.actions';
import { SubscriptionFormsService } from 'projects/workspace/src/app/subscription/services/subscription-forms.service';
import { DocumentTypesUppercaseEnum } from '../../modals-common/link-document-modal/enums/ducument-types.enum';
import { AddressTypeEnum } from 'projects/workspace/src/app/sales-order/enums/address-type.enum';
import { ModalNameEnum } from '../../../../models/modal-name.enum';
import { PartnersTypeEnum } from 'projects/workspace/src/app/partners/corporate/enums';
import { CommonModalsActionsEnum, ConfirmModalComponent } from '../../modals-common';
import { UPDATE_SMALL_BUSINESS_MODAL_CONFIG } from 'projects/workspace/src/app/shared/constants';
import { selectAccountingSettings } from 'projects/workspace/src/app/store/selectors/shared.selectors';
import { AccountingSettingsModel } from 'projects/workspace/src/app/accounting/accounting-settings-module/models';

@Component({
  selector: 'rnpl-subscription-add-billing-info-modal',
  templateUrl: './subscription-add-billing-info-modal.component.html',
})
export class SubscriptionAddBillingInfoModalComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public accountingSettings: AccountingSettingsModel;
  public subscription: SubscriptionModel;
  public readonly paymentMethods = PaymentMethodsEnum;
  public readonly transferMethods = TransferMethodsEnum;
  public isReadonly: boolean = true;
  public addressTypeEnum: typeof AddressTypeEnum = AddressTypeEnum;
  public addressTypeList = [];
  public countries: CountryModel[];
  public modalNameEnum: typeof ModalNameEnum = ModalNameEnum;

  private convertAddressToString = convertAddressToStringHelper;

  // public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private readonly dialog: MatDialog,
    private readonly router: Router,
    public dialogRef: MatDialogRef<SubscriptionAddBillingInfoModalComponent>,
    private subscriptionApiService: SubscriptionApiService,
    private subscriptionFormService: SubscriptionFormsService,
    private readonly store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.store.select(selectAccountingSettings)
      .pipe(takeUntil(this.destroy$))
      .subscribe((accountingSettings: AccountingSettingsModel) => this.accountingSettings = accountingSettings);

    // this.isLoading$.next(true);
    this.selectCountries();

    this.store.select(selectSubscription)
      .pipe(takeUntil(this.destroy$))
      .subscribe((subscription: SubscriptionModel) => {
        this.subscription = subscription;
        this.addressTypeList = [
          {value: AddressTypeEnum.BILLING, label: 'FORM.BILLING_ADDRESS', enabled: this.isCustomerSelected},
          {value: AddressTypeEnum.DELIVERY, label: 'FORM.DELIVERY_ADDRESS', enabled: this.isCustomerSelected},
          {value: AddressTypeEnum.USER_DEFINED, label: 'COMMON.NEW_ADDRESS', enabled: true},
          {value: AddressTypeEnum.SIMPLIFIED, label: 'COMMON.USER_DEFINED_ADDRESS', enabled: true},
        ].filter(i => i.enabled);

        this.subscriptionFormService.initForm(this.subscription);
        this.form = this.subscriptionFormService.form;

        this.store.dispatch(UpdateBillingInfoBlockValid({ billingInfoBlockValid: this.form.valid }));
        this.setFormsState();

      });

    this.store.select(selectSubscriptionCurrentState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: UIStatesEnum) => {
        this.isReadonly = response === UIStatesEnum.VIEW;
        this.setFormsState();
      });
  }

  private setFormsState(): void {
    const opts = { onlySelf: true, emitEvent: false };
    if (this.isReadonly) {
      this.form.disable(opts);
    } else {
      this.form.enable(opts);

      if (this.hasCreatedLinkedOIN || this.subscription.properties.smallBusiness) {
        this.vatDisabled.disable(opts);
      }

      if (get(this.subscription, 'properties.partnerType') === PartnersTypeEnum.CORPORATE) {
        this.nameControl.disable(opts);
      }

      if (!get(this.subscription, 'properties.company.id')) {
        this.billingAddressFormGroup.disable(opts);
        this.addressTemplateControl.disable(opts);
      }
    }
  }

  public updateSubscription(fieldValue, fieldName: string, fromProperties: boolean = false): void {
    fieldName = (fromProperties ? 'properties.' : 'billingInformation.') + fieldName;
    this.subscriptionApiService.updateSubscription(this.subscription.id, {fieldValue, fieldName})
      .subscribe(() => {
        // updated via store
      });
  }

  public updateSmallBusiness(fieldValue, fieldName: string): void {
    if (this.accountingSettings.smallBusiness.forward === fieldValue) {
      this.updateSubscription(fieldValue, fieldName, true);
      return;
    }

    const dialog = this.dialog.open(ConfirmModalComponent, UPDATE_SMALL_BUSINESS_MODAL_CONFIG);

    dialog.afterClosed().subscribe((res: CommonModalsActionsEnum) => {
      if (res === CommonModalsActionsEnum.CONFIRM) {
        this.updateSubscription(fieldValue, fieldName, true);
      } else if (res === CommonModalsActionsEnum.REJECT) {
        this.router.navigate(['/accounting/settings/general-settings']);
      } else {
        this.smallBusiness.setValue(this.subscription.properties.smallBusiness);
      }
    });
  }

  private selectCountries() {
    this.store.select(selectCountries)
      .pipe(takeUntil(this.destroy$))
      .subscribe((countriesFromStore: CountryModel[]) => {
        this.countries = countriesFromStore;
      });
  }

  // public updateBillingInfoAddressByCustomer(): void {
  //   this.subscriptionApiService.updateBillingInfoAddressByCustomer(this.subscription.id).subscribe();
  // }

  public updateAddressField(field: FormInputChangedModel): void {
    const fieldName = 'billingAddress.' + field.fieldName;
    this.updateSubscription(field.fieldValue, fieldName);
  }

  public editAddress(): void {
    this.addressTemplateControl.setValue(AddressTypeEnum.USER_DEFINED);
    this.updateSubscription(AddressTypeEnum.USER_DEFINED, 'addressTemplate');
  }

  get paymentMethodControl(): FormControl { return this.form.get('paymentMethod') as FormControl; }
  get transferMethodControl(): FormControl { return this.form.get('transferMethod') as FormControl; }
  get dueWithinDaysControl(): FormControl { return this.form.get('dueWithinDays') as FormControl; }
  get billingAddressFormGroup(): FormGroup { return this.form.get('billingAddress') as FormGroup; }
  // get billingForControl(): FormControl { return this.form.get('billingFor') as FormControl; }
  // get billingPeriodControl(): FormControl { return this.form.get('billingPeriod') as FormControl; }
  get bankAccountIdControl(): FormControl { return this.form.get('bankAccountId') as FormControl; }
  get ibanControl(): FormControl { return this.form.get('iban') as FormControl; }
  get bicControl(): FormControl { return this.form.get('bic') as FormControl; }
  get vatDisabled(): FormControl { return this.form.get('vatDisabled') as FormControl; }
  get addressTemplateControl(): FormControl { return this.form.get('addressTemplate') as FormControl; }
  get nameControl(): FormControl { return this.form.get('name') as FormControl; }
  get smallBusiness(): FormControl { return this.form.get('smallBusiness') as FormControl; }

  get billingAddressAsText(): string {
    if (!this.form) { return ''; }

    const addressVal = this.billingAddressFormGroup.value;
    return this.convertAddressToString(addressVal, this.countries);
  }

  get hasCreatedLinkedOIN(): boolean {
    if (!get(this, 'subscription.linkedDocuments')) { return false; }
    return get(this, 'subscription.linkedDocuments')
      .some(doc => doc.type === DocumentTypesUppercaseEnum.OUTGOING_INVOICE && doc.documentId);
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public get isCustomerSelected(): boolean {
    return !!get(this, 'subscription.properties.company.runpleId');
  }

}
