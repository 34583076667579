import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { get } from 'lodash';

import { InitialSetupBaseComponent } from '../initial-setup-base/initial-setup-base.component';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { AdministrationsApiService } from 'projects/workspace/src/app/administration/services/administrations-api.service';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { AuthService } from '../../../../auth/auth.service';
import { SubscriptionPlanEnum } from '../../../subscription-activation/subscription-activation.emum';

@Component({
  selector: 'rnpl-app-features',
  templateUrl: './app-features.component.html',
  styleUrls: ['./app-features.component.scss'],
})
export class AppFeaturesComponent extends InitialSetupBaseComponent implements OnInit {

  public form: FormGroup = this.fb.group({});

  constructor(
    public fb: FormBuilder,
    public readonly store: Store<AppState>,
    public router: Router,
    public readonly administrationsApiService: AdministrationsApiService,
    public readonly authService: AuthService,
  ) {
    super(store, router, administrationsApiService);
  }

  ngOnInit() {
    this.initForm();
  }

  public initForm(): void {
    this.form = this.fb.group({
      ecommerceEnabled:  [false, {validators: []}],
      servicesEnabled:  [false, {validators: []}],
      wholesalesEnabled:  [false, {validators: []}],
    });
  }

  public updateStep$(): Observable<boolean | CompanyProfile> {
    if (!this.getStepValidation) {
      return of(false);
    }
    const formValue = this.form.getRawValue();
    const company: CompanyProfile = {
      ...this.companyProfile,
      subscriptionManagement: {
        ...this.companyProfile.subscriptionManagement,
        userChannelOptions: {
          ...this.companyProfile.subscriptionManagement.userChannelOptions,
          ...formValue
        }
      }
    };

    return this.updateCompanyInfo$(company);
  }

  public getStepValidation(): boolean {
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    return (this.wholesalesEnabled.value || this.servicesEnabled.value || this.ecommerceEnabled.value);
  }

  get isFreePlan(): boolean {
    return get(this.companyProfile, 'subscriptionManagement.subscriptionPlan') === SubscriptionPlanEnum.FREE;
  }

  get isAccountingPlan(): boolean {
    return get(this.companyProfile, 'subscriptionManagement.subscriptionPlan') === SubscriptionPlanEnum.ACCOUNTING_MONTHLY ||
      get(this.companyProfile, 'subscriptionManagement.subscriptionPlan') === SubscriptionPlanEnum.ACCOUNTING_ANNUALLY;
  }

  get wholesalesEnabled() { return this.form.get('wholesalesEnabled') as FormControl; }
  get servicesEnabled() { return this.form.get('servicesEnabled') as FormControl; }
  get ecommerceEnabled() { return this.form.get('ecommerceEnabled') as FormControl; }

}
