import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';

import { CostCenterControlComponent } from './cost-center-control.component';
import { CostCenterApiService } from '../../../cost-center/services/cost-center-api.service';

@NgModule({
  declarations: [
    CostCenterControlComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    TranslateModule,
  ],
  exports: [
    CostCenterControlComponent,
  ],
  providers: [
    CostCenterApiService
  ],
  entryComponents: [
    CostCenterControlComponent
  ],
})
export class CostCenterControlModule { }
