import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { ColumnTypeEnum, TableColumnModelExtended } from 'common/src/models/table-column.model';
import { selectCompanyTimezoneOffset } from 'projects/workspace/src/app/administration/store/selectors';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';

@Component({
  selector: 'rnpl-status-line',
  templateUrl: 'status-line.component.html',
  styleUrls: ['status-line.component.scss']
})
export class StatusLineComponent implements OnChanges {

  @Input() public value: number = 0;
  @Input() public maxValue: number = 0;
  @Input() public dueWithinDays: number;

  @Input() public inverse: boolean = false;
  @Input() public forTable: boolean;
  @Input() public isEndless: boolean;
  @Input() public withLabel: boolean = false;
  @Input() public inPercent: boolean = false;
  @Input() public valueOnly: boolean;
  @Input() public hideProgress: boolean = false;
  @Input() public unitTypeShortcut: boolean;
  @Input() public reverseDirection: boolean = false;
  @Input() public currencyConversion: boolean;
  @Input() public statusLineOverdueHighlight: boolean = false;

  @Input() public icon: string = '';
  @Input() public type: string = 'primary';
  @Input() public label: string = '';
  @Input() public postfix?: string;
  @Input() public currency: string;
  @Input() public colorState: string;
  @Input() public rightLabel: string;
  @Input() public createdDate: string;
  @Input() public customClass: string;
  @Input() public expectedDate: string;

  @Input() public column: TableColumnModelExtended;

  public progress: number;

  public color: string;

  public status: string | number;

  public date = new Date();
  public showSuffixDay: boolean;
  public showProgressLine: boolean = true;

  public columnTypes: typeof ColumnTypeEnum = ColumnTypeEnum;

  readonly companyProfileTimezoneOffset$: Observable<string> = this.store.select(selectCompanyTimezoneOffset);

  constructor(
    private readonly store: Store<AppState>,
    private readonly translateService: TranslateService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isEndless) {
      // if status line value is endless (dont have end point) - progress line is 50% always
      this.progress = 50;
      return;
    }

    if (changes.hasOwnProperty('value') ||  changes.hasOwnProperty('maxValue') ) {
      this.initProgress();
    }

    if (this.type === 'days') {
      this.initProgressDays();
    }

    if (this.type === 'date') {
      this.initProgressDate();
    }
  }

  initProgress(): void {
    if (!this.value || !this.maxValue) { this.progress = 0; }
    if (this.type === 'paymentsEuroDifference' && (this.value > this.maxValue || !this.maxValue)) {
      this.progress = 0;
      return;
    }

    if (this.reverseDirection) {
      if (this.maxValue >= this.value) {
        this.progress = (100 / this.maxValue) * (this.maxValue - this.value);
      } else {
        this.progress = 0;
      }

      if (this.progress > 100) { this.progress = 100; }
      return;
    }
    this.progress = (100 / this.maxValue) * this.value;
    if (this.progress > 100) { this.progress = 100; }
  }

  initProgressDate(): void {
    if (this.createdDate && this.expectedDate) {
      const estimatedDate: Date = new Date(this.createdDate);

      let todayDate: any = this.date;
      let createdDate: any = new Date(this.createdDate);
      let expectedDate: any = this.expectedDate ? new Date(this.expectedDate) : estimatedDate;

      const oneDay = 3600 * 24 * 1000;  // milliseconds per day

      expectedDate = Math.floor(expectedDate.getTime() / oneDay);
      todayDate = Math.floor(todayDate.getTime() / oneDay);
      createdDate = Math.floor(createdDate.getTime() / oneDay);

      const passedDays = (expectedDate - todayDate);
      let totalDays = (expectedDate - createdDate);
      totalDays = (totalDays <= 0) ? 1 : totalDays;
      const progress = (100 / totalDays) * passedDays;

      this.value = passedDays;
      this.maxValue = totalDays;
      this.progress = progress;
      this.status = (passedDays === 0 ) ? 'Today' : this.expectedDate;
    } else {
      this.progress = 0;
      this.status = '-';
      this.showSuffixDay = true;
    }
  }

  initProgressDays(): void {
    if (this.createdDate && this.expectedDate || this.dueWithinDays) {
      const estimatedDate: Date = new Date(this.createdDate);
      estimatedDate.setDate(estimatedDate.getDate() + this.dueWithinDays);

      let todayDate: any = this.date;
      let createdDate: any = new Date(this.createdDate);
      let expectedDate: any = this.expectedDate ? new Date(this.expectedDate) : estimatedDate;

      const oneDay = 3600 * 24 * 1000;  // milliseconds per day

      expectedDate = Math.floor(expectedDate.getTime() / oneDay);
      todayDate = Math.floor(todayDate.getTime() / oneDay);
      createdDate = Math.floor(createdDate.getTime() / oneDay);

      const passedDays = (expectedDate - todayDate);
      let totalDays = (expectedDate - createdDate);
      totalDays = (totalDays <= 0) ? 1 : totalDays;
      const progress = (100 / totalDays) * passedDays;

      this.value = passedDays;
      this.maxValue = totalDays;
      this.progress = progress;
      this.status = (passedDays === 0 )
        ? this.translateService.instant('FORM.TODAY')
        : this.expectedDate;

      if (this.type === 'days') {
        if (passedDays === 0) {
          this.status = this.translateService.instant('FORM.TODAY');
          this.showSuffixDay = false;
        } else if (passedDays > 0) {
          this.status = passedDays;
          this.showSuffixDay = true;
        } else {
          this.status = passedDays * -1;
          this.showSuffixDay = true;
        }
      }
    } else {
      this.progress = 0;
      this.status = '-';
      this.showSuffixDay = true;
    }
  }

  public getColor(progress): string {
    let color;
    switch (this.type) {
      case 'quantity' :
      case 'quantity-max-val-only' :
        if (this.inPercent) {
          if (progress <= 50) {
            return 'red';
          } else if (progress <= 90) {
            return 'yellow';
          } else {
            return 'green';
          }
        }
        if (progress === 100) {
          color = 'green';
        } else {
          color = 'yellow';
        }
        break;
      case 'days' :
      case 'date' :
        if (progress <= 0) {
          color = 'grey';
        } else if (progress <= 24) {
          color = 'red';
        } else if (progress <= 54) {
          color = 'yellow';
        } else {
          color = 'green';
        }
        break;
      default:
        if (progress <= 50) {
          color = this.inverse ? 'red' : 'green';
        } else if (progress <= 90) {
          color = 'yellow';
        } else {
          color = this.inverse ? 'green' : 'red';
        }
    }
    return color;
  }

}
