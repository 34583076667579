import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { ReportingPeriodsApiService } from 'projects/workspace/src/app/reporting-periods/services/reporting-periods-api.service';
import { ExternalAccountConfigModel } from 'projects/workspace/src/app/reporting-periods/models';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'rnpl-reporting-periods-setup-external-modal',
  templateUrl: './reporting-periods-setup-external-modal.component.html'
})
export class ReportingPeriodsSetupExternalModalComponent implements OnDestroy {

  public config: ExternalAccountConfigModel;
  public form: FormGroup;
  public numberLengthList: { value: number, label: string }[] = [
    { value: 3, label: this.translateService.instant('DOCUMENTS_NUMBERS.N_SYMBOLS', {n: 3}) },
    { value: 4, label: this.translateService.instant('DOCUMENTS_NUMBERS.N_SYMBOLS', {n: 4}) },
    { value: 5, label: this.translateService.instant('DOCUMENTS_NUMBERS.N_SYMBOLS', {n: 5}) },
    { value: 6, label: this.translateService.instant('DOCUMENTS_NUMBERS.N_SYMBOLS', {n: 6}) },
    { value: 7, label: this.translateService.instant('DOCUMENTS_NUMBERS.N_SYMBOLS', {n: 7}) },
    { value: 8, label: this.translateService.instant('DOCUMENTS_NUMBERS.N_SYMBOLS', {n: 8}) },
    { value: 9, label: this.translateService.instant('DOCUMENTS_NUMBERS.N_SYMBOLS', {n: 9}) },
    { value: 10, label: this.translateService.instant('DOCUMENTS_NUMBERS.N_SYMBOLS', {n: 10}) },
  ];
  readonly confirmRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private fb: FormBuilder,
    private translateService: TranslateService,
    private reportingPeriodsApiService: ReportingPeriodsApiService,
    public dialogRef: MatDialogRef<ReportingPeriodsSetupExternalModalComponent>,
  ) {
    this.initForm();

    this.reportingPeriodsApiService.getReportingPeriodsPartnerAccountConfig()
      .pipe(takeUntil(this.destroy$))
      .subscribe((config: ExternalAccountConfigModel) => {
        this.config = config;
        this.form.patchValue(config);
      });
  }

  public initForm(): void {
    this.form = this.fb.group({
      enabled: [false],
      creditAccountConfig: this.fb.group({
        prefix: [null, [Validators.required]],
        length: [],
        continueExisting: [],
        nextValue: [null, [Validators.required]],
        // andIncrement: [],
      }),
      debitAccountConfig: this.fb.group({
        prefix: [null, [Validators.required]],
        length: [],
        continueExisting: [],
        nextValue: [null, [Validators.required]],
        // andIncrement: [],
      })
    });

    this.creditContinueExistingControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: boolean) => {
        value
          ? this.creditNextValueControl.setValidators(Validators.required)
          : this.creditNextValueControl.clearValidators();

        this.creditNextValueControl.updateValueAndValidity();
      });

    this.debitContinueExistingControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: boolean) => {
        value
          ? this.debitNextValueControl.setValidators(Validators.required)
          : this.debitNextValueControl.clearValidators();

        this.debitNextValueControl.updateValueAndValidity();
      });
  }

  public submit(): void {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.invalid) { return; }
    if (this.confirmRequest$.getValue()) { return; }

    this.confirmRequest$.next(true);

    this.reportingPeriodsApiService.updateReportingPeriodsPartnerAccountConfig(this.form.getRawValue())
      .pipe(
        finalize(() => this.confirmRequest$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.dialogRef.close());
  }

  public getCreditNextNumber(): string {
    let filler = '';
    const nextNumber = this.creditContinueExistingControl.value
      ? this.creditNextValueControl.value
      : this.config.creditAccountConfig.nextValue;
    const maxLength = this.creditContinueExistingControl.value
      ? this.creditLengthControl.value - this.creditNextValueControl.value.toString().length
      : this.creditLengthControl.value - this.config.creditAccountConfig.nextValue.toString().length;
    for (let index = 1; index <= (maxLength); index++) {
      filler = filler + '0';
    }

    return `${this.creditPrefixControl.value}${filler}${nextNumber}`;
  }

  public getDebitNextNumber(): string {
    let filler = '';
    const nextNumber = this.debitContinueExistingControl.value
      ? this.debitNextValueControl.value
      : this.config.debitAccountConfig.nextValue;
    const maxLength = this.debitContinueExistingControl.value
      ? this.debitLengthControl.value - this.debitNextValueControl.value.toString().length
      : this.debitLengthControl.value - this.config.debitAccountConfig.nextValue.toString().length;
    for (let index = 1; index <= maxLength; index++) {
      filler = filler + '0';
    }

    return `${this.debitPrefixControl.value}${filler}${nextNumber}`;
  }

  get enabledControl(): FormControl { return this.form.get('enabled') as FormControl; }
  get creditPrefixControl(): FormControl { return this.form.get('creditAccountConfig').get('prefix') as FormControl; }
  get creditLengthControl(): FormControl { return this.form.get('creditAccountConfig').get('length') as FormControl; }
  get creditContinueExistingControl(): FormControl { return this.form.get('creditAccountConfig').get('continueExisting') as FormControl; }
  get creditNextValueControl(): FormControl { return this.form.get('creditAccountConfig').get('nextValue') as FormControl; }
  // get creditAndIncrementControl(): FormControl { return this.form.get('creditAccountConfig').get('andIncrement') as FormControl; }
  get debitPrefixControl(): FormControl { return this.form.get('debitAccountConfig').get('prefix') as FormControl; }
  get debitLengthControl(): FormControl { return this.form.get('debitAccountConfig').get('length') as FormControl; }
  get debitContinueExistingControl(): FormControl { return this.form.get('debitAccountConfig').get('continueExisting') as FormControl; }
  get debitNextValueControl(): FormControl { return this.form.get('debitAccountConfig').get('nextValue') as FormControl; }
  // get debitAndIncrementControl(): FormControl { return this.form.get('debitAccountConfig').get('andIncrement') as FormControl; }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
