import { Pipe, PipeTransform } from '@angular/core';
import { DocumentTypesUppercaseEnum } from '../../modals/modals-common/link-document-modal/enums/ducument-types.enum';

@Pipe({name: 'statusTranslatePipe'})
export class StatusTranslatePipe implements PipeTransform {
  transform(value: string, documentType?: DocumentTypesUppercaseEnum, ...args: any[]): string {
    if (!value) { return value; }

    let translateKey: string;

    switch (value.toLowerCase()) {
      case 'approved':
        translateKey = 'FORM.APPROVED';
        break;
      case 'draft':
        translateKey = 'TAB.DRAFT';
        break;
      case 'deleted':
        translateKey = 'TAB.DELETED';
        break;
      case 'completed':
        translateKey = 'TAB.COMPLETED';
        break;
      case 'canceled':
        translateKey = 'TAB.CANCELED';
        if (documentType === DocumentTypesUppercaseEnum.ST) {
          translateKey = 'STOCKTAKING.CANCELED';
        }
        break;
      case 'open':
        translateKey = 'TAB.OPEN';
        break;
      case 'paid':
        translateKey = 'TAB.PAID';
        break;
      case 'sent':
        translateKey = 'TAB.SENT';
        break;
      case 'accepted':
        translateKey = 'TAB.ACCEPTED';
        break;
      case 'declined':
        translateKey = 'TAB.DECLINED';
        break;
      case 'active':
        translateKey = 'TAB.ACTIVE';
        break;
      case 'inactive':
        translateKey = 'TAB.INACTIVE';
        break;
      case 'pending':
      case 'unreported':
      case 'unreported_documents':
        translateKey = 'STATUS.PENDING';
        break;
      case 'incoming':
        translateKey = 'TABLE_SUMMARY_BAR.INCOMING';
        break;
      case 'outgoing':
        translateKey = 'TABLE_SUMMARY_BAR.OUTGOING';
        break;
      case 'booked':
        translateKey = 'TAB.BOOKED';
        break;
      case 'blocked':
        translateKey = 'TAB.BLOCKED';
        break;
      case 'editing':
        translateKey = 'STATUS.EDITING';
        break;
      case 'overpaid':
        translateKey = 'STATUS.OVERPAID';
        break;
      case 'delayed':
        translateKey = 'STATUS.DELAYED';
        break;
      case 'equalized':
        translateKey = 'STATUS.EQUALIZED';
        if (documentType === DocumentTypesUppercaseEnum.OCN) {
          translateKey = 'STATUS.BALANCED';
        }
        if (documentType === DocumentTypesUppercaseEnum.OIN) {
          translateKey = 'STATUS.CREDITED';
        }
        break;
      case 'ended':
        translateKey = 'STATUS.ENDED';
        break;
      case 'corrupted':
        translateKey = 'TAB.CORRUPTED';
        break;
      case 'packing':
        translateKey = 'TAB.PACKING';
        break;
      case 'packed_up':
        translateKey = 'TAB.PACKED_UP';
        break;
      case 'packed':
        translateKey = 'BUTTON.PACKED';
        break;
      case 'shipped':
        translateKey = 'TAB.SHIPPED';
        break;
      case 'delivered':
        translateKey = 'STATUS.DELIVERED';
        break;
      case 'paused':
        translateKey = 'TAB.PAUSED';
        break;
      case 'ready':
        translateKey = 'STATUS.READY';
        break;
      case 'reported':
        translateKey = 'REPORTING_PERIODS.EXPORTED';
        break;
      case 'partially':
        translateKey = 'STATUS.PARTIALLY';
        break;
      case 'partially_reported':
        translateKey = 'STATUS.PARTIALLY_REPORTED';
        break;
      case 'closed':
        translateKey = 'REPORTING_PERIODS.CLOSED';
        break;
      case 'checked':
        translateKey = 'STATUS.CHECKED';
        break;
      case 'overdue':
        translateKey = 'STATUS.OVERDUE';
        break;
      case 'partially_delivered':
        translateKey = 'STATUS.PARTIALLY_PROCESSED';
        break;
      case 'partially_billed':
        translateKey = 'STATUS.PARTIAL_BILLED';
        break;
      case 'billed':
        translateKey = 'STATUS.BILLED';
        break;
      case 'received':
        translateKey = 'TAB.RECEIVED';
        break;
      case 'processing':
        translateKey = 'STATUS.PROCESSING';
        if (documentType === DocumentTypesUppercaseEnum.EMAIL_IMPORT) {
          translateKey = 'EMAIL_IMPORT.PROCESSING';
        }
        break;
      case 'partially_paid':
        translateKey = 'STATUS.PARTIALLY_PAID';
        break;
      case 'partially_warehoused':
        translateKey = 'STATUS.PARTIALLY_WAREHOUSED';
        break;
      case 'partially_shipped':
        translateKey = 'STATUS.PARTIAL_PROCESSING';
        break;
      case 'warehoused':
        translateKey = 'STATUS.WAREHOUSED';
        break;
      case 'partially_prepared':
        translateKey = 'STATUS.PARTIALLY_SHIPPED';
        break;
      case 'not_required':
        translateKey = 'STATUS.NOT_REQUIRED';
        break;
      case 'primary':
        translateKey = 'STATUS.PRIMARY';
        break;
      case 'regular':
        translateKey = 'STATUS.REGULAR';
        break;
      case 'minor':
        translateKey = 'STATUS.MINOR';
        break;
      case 'passed':
        translateKey = 'VAT.PASSED';
        break;
      case 'failed':
        translateKey = 'VAT.FAILED';
        break;
      case 'closed_periods':
        translateKey = 'REPORTING_PERIODS.CLOSED_PERIODS';
        break;
      case 'reports':
        translateKey = 'REPORTING_PERIODS.OPEN_PERIODS';
        break;
      case 'log':
        translateKey = 'TAB.LOG';
        break;
      case 'shortage':
        translateKey = 'STATUS.SHORTAGE';
        break;
      case 'allocated':
        translateKey = 'STATUS.ALLOCATED';
        break;
      case 'template':
        translateKey = 'DOCUMENTS_TEMPLATES.TEMPLATES';
        break;
      case 'debit':
        translateKey = 'STATUS.DEBIT';
        break;
      case 'credit':
        translateKey = 'STATUS.CREDIT';
        break;
      case 'inventory':
        translateKey = 'STATUS.INVENTORY';
        break;
      case 'fixed':
        translateKey = 'STATUS.FIXED';
        break;
      case 'generated':
        translateKey = 'COLUMN.CREATED';
        break;
      case 'review':
        translateKey = 'STATUS.REVIEW';
        break;
      case 'difference':
        translateKey = 'STATUS.DIFFERENCE';
        break;
      case 'match':
        translateKey = 'STATUS.MATCH';
        break;
      case 'unsupported':
        translateKey = 'EMAIL_IMPORT.UNSUPPORTED';
        break;
      case 'succeed':
        translateKey = 'EMAIL_IMPORT.IMPORTED';
        break;
    }

    return translateKey || value;
  }
}
