import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

import { ProductTypes } from '../../products/product-types';
import { DOCUMENT_COLOR_ICON, DOCUMENT_ICON, DOCUMENT_NAME, STATUS_CLASSES } from '../position-card.constant';
import { ActionButtonsService } from '../../../services/action-buttons.service';
import { PositionTypeEnum } from '../../document-viewer/document-viewer.model';
import { TableActivateTypes } from '../../ui-components/table/custom-table.enums';

@Component({
  selector: 'rnpl-position-main-info',
  templateUrl: './position-main-info.component.html',
  styleUrls: ['./position-main-info.component.scss']
})
export class PositionMainInfoComponent implements AfterViewChecked {

  public showDescription: boolean = false;
  public showProduct: boolean = false;
  public readonly tableActivateTypes = TableActivateTypes;

  public productTypes = ProductTypes;

  private readonly descriptionsMinVisibleHeight = 88;

  readonly statusClasses: { [key: string]: string } = STATUS_CLASSES;
  readonly documentName: { [key: string]: string } = DOCUMENT_NAME;
  readonly documentIcon: { [key: string]: string } = DOCUMENT_ICON;
  readonly documentColorIcon: { [key: string]: string } = DOCUMENT_COLOR_ICON;

  @Input() public position;
  @Input() public showRemoveBtn: boolean;
  @Input() public isDocumentType: boolean;
  @Input() public showManufacturerCode: boolean;
  @Input() public isCreatePositionType: boolean;
  @Input() public productDescription: string;
  @Input() public productName: string;
  @Input() public modalType: boolean;

  @Output() rowAction: EventEmitter<{position: any, actionType: TableActivateTypes}> = new EventEmitter();

  public getIconByProductType(productType: ProductTypes | string): string {
    switch (productType) {
      case ProductTypes.GOODS:
        return 'box';
      case ProductTypes.SERVICES:
        return 'checklist';
        case ProductTypes.OVERDUE_FEE:
        return 'overdue';
      case ProductTypes.DIGITAL:
        return 'digital-product';
      case ProductTypes.ARBITRARY:
        return 'user-defined';
      case ProductTypes.CORRECTION:
        return 'summary';
      case ProductTypes.UNASSIGNED:
        return 'question-mark-circle';
      case ProductTypes.INVOICE:
      case ProductTypes.PREPAYMENT:
        return 'outgoing-invoice';
      default:
        return 'box';
    }
  }

  public moreDescriptionToggle(): void {
    this.showDescription = !this.showDescription;
  }
  public displayProductsList(): void {
    if (!this.isDocumentType) {
      this.showProduct = !this.showProduct;
    }
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    public actionButtonsService: ActionButtonsService
  ) {
  }

  displayToggleHandler(el): boolean {
    if (!el) { return false; }
    return el.scrollHeight > el.offsetHeight || el.clientHeight > this.descriptionsMinVisibleHeight;
  }

  public onRowAction(event: {position: any, actionType: TableActivateTypes}): void {
    this.rowAction.emit(event);
  }

  get hideProductLink(): boolean {
    return this.position.positionType === PositionTypeEnum.DELIVERY_STANDARD ||
      this.position.positionType === PositionTypeEnum.DELIVERY_EXPRESS ||
      this.position.positionType === PositionTypeEnum.INSURANCE ||
      this.position.positionType === PositionTypeEnum.PREPAYMENT ||
      this.position.positionType === PositionTypeEnum.INVOICE ||
      this.position.logistics ||
      this.position.general;
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
}
