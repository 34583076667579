import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { CommonModalsActionsEnum } from '../../modals-common';
import { DocumentTypesUppercaseEnum } from '../../modals-common/link-document-modal/enums/ducument-types.enum';
import { PaymentListTabsEnum } from 'projects/workspace/src/app/payment/enums';


@Component({
  selector: 'rnpl-unlink-document.modal',
  templateUrl: './unlink-document-modal.component.html'
})
export class UnlinkDocumentModalComponent {

  public inBlackList: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<UnlinkDocumentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      documentType: DocumentTypesUppercaseEnum;
      status: PaymentListTabsEnum;
      transactionId: string;
    }
  ) {

  }

  public confirmAction(): void {
    this.dialogRef.close({ action: CommonModalsActionsEnum.CONFIRM, inBlackList: this.inBlackList });
  }

  public get allowedBlackList(): boolean {
    return this.data && (this.data.status === PaymentListTabsEnum.PENDING || this.data.transactionId)
      && (
        this.data.documentType === DocumentTypesUppercaseEnum.OIN
        || this.data.documentType === DocumentTypesUppercaseEnum.IIN
        || this.data.documentType === DocumentTypesUppercaseEnum.OCN
        || this.data.documentType === DocumentTypesUppercaseEnum.ICN
        || this.data.documentType === DocumentTypesUppercaseEnum.CRN
      );
  }

}

