import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { get, isEqual } from 'lodash';

import {
  OcrDataModel,
  OcrDocumentFieldTypeEnum,
  OcrExpenseDocumentModel,
  OcrFieldCorrectionModel,
  OcrLineItemExpenseField,
  OcrListItemGroupModel,
  OcrPositionCorrectionModel,
  OcrPositionFieldTypeEnum,
  OcrSummaryField,
  OcrSummaryFieldMapModel
} from './models';
import { environment } from '../../../../environments/environment';
import { AuthService } from 'common/src/auth/auth.service';

@Component({
  selector: 'rnpl-ocr-preview',
  templateUrl: './ocr-preview.component.html',
  styleUrls: ['./ocr-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OcrPreviewComponent {

  public currentPage: number = 1;
  public currentZoom: number = 1;
  public previewData: {expenseDocuments: OcrExpenseDocumentModel, imageUrl: string}[] = [];
  public sourceOcrData: OcrDataModel = null;
  public activeItem: OcrSummaryField|OcrLineItemExpenseField;

  @Input() isReadonly: boolean = true;
  @Input() formMobile: boolean = true;
  @Input() recognized: boolean = false;
  @Input() set ocrData(ocrData: OcrDataModel) {
    this.sourceOcrData = ocrData;
    this.preparePreviewData(ocrData);
  }

  @Output() clickSummaryField: EventEmitter<OcrFieldCorrectionModel> = new EventEmitter<OcrFieldCorrectionModel>();
  @Output() clickPositionField: EventEmitter<OcrPositionCorrectionModel> = new EventEmitter<OcrPositionCorrectionModel>();
  @Output() clickDeleteFile: EventEmitter<null> = new EventEmitter<null>();
  @Output() clickParseDocument: EventEmitter<null> = new EventEmitter<null>();
  @Output() clickChangeView: EventEmitter<null> = new EventEmitter<null>();
  @Output() clickDownloadFile: EventEmitter<null> = new EventEmitter<null>();

  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  public deleteFile(): void {
    this.clickDeleteFile.emit();
  }

  public parseDocument(): void {
    this.clickParseDocument.emit();
  }

  public changeView(): void {
    this.clickChangeView.emit();
  }

  public downloadFile(): void {
    this.clickDownloadFile.emit();
  }

  private preparePreviewData(ocrData: OcrDataModel): void {
    const previewDataAlreadyExists = !!this.previewData.length;
    if (!ocrData || !ocrData.expenseDocuments) { return; }

    ocrData.expenseDocuments.forEach((expenseDocuments, index) => {
      const filteredSummaryFields: OcrSummaryField[] = [];

      if (ocrData.matchedFieldsMap && ocrData.matchedFieldsMap.summaryFieldsMap) {
        // first of all add matched fields
        ocrData.matchedFieldsMap.summaryFieldsMap
          .filter(mappedFiled => !!mappedFiled) // todo: remove
          .filter(mappedFiled => mappedFiled.pageIndex === index)
          .forEach(mappedFiled => {
            const foundField = expenseDocuments.summaryFields.find((f, summaryFiledIndex) => {
              return mappedFiled.fieldIndex === summaryFiledIndex;
            });
            filteredSummaryFields.push(foundField);
          });
      }

      expenseDocuments.summaryFields.forEach((f: OcrSummaryField) => {
        if (!f.valueDetection || !f.valueDetection.geometry) {
          return;
        }
        const sameCoords: boolean =  filteredSummaryFields.some((filteredField: OcrSummaryField) => {
          return isEqual(get(filteredField, 'valueDetection.geometry.boundingBox'), get(f, 'valueDetection.geometry.boundingBox'));
        });

        if (!sameCoords) {
          const crossCoords: boolean =  filteredSummaryFields.some((filteredField: OcrSummaryField) => {
            const filteredCoords = get(filteredField, 'valueDetection.geometry.polygon');
            const sourceCoords = get(f, 'valueDetection.geometry.polygon');

            if (!filteredCoords || !sourceCoords) { return; }

            const fX1: number = +filteredCoords[0].x.toFixed(3);
            const fX2: number = +filteredCoords[1].x.toFixed(3);
            const fX3: number = +filteredCoords[2].x.toFixed(3);
            const fX4: number = +filteredCoords[3].x.toFixed(3);

            const sX1: number = +sourceCoords[0].x.toFixed(3);
            const sX2: number = +sourceCoords[1].x.toFixed(3);
            const sX3: number = +sourceCoords[2].x.toFixed(3);
            const sX4: number = +sourceCoords[3].x.toFixed(3);

            const fY1: number = +filteredCoords[0].y.toFixed(3);
            const fY2: number = +filteredCoords[1].y.toFixed(3);
            const fY3: number = +filteredCoords[2].y.toFixed(3);
            const fY4: number = +filteredCoords[3].y.toFixed(3);

            const sY1: number = +sourceCoords[0].y.toFixed(3);
            const sY2: number = +sourceCoords[1].y.toFixed(3);
            const sY3: number = +sourceCoords[2].y.toFixed(3);
            const sY4: number = +sourceCoords[3].y.toFixed(3);

            const isInside: boolean =
              (fX1 <= sX1 && fY1 <= sY1) &&
              (fX2 >= sX2 && fY2 <= sY2) &&
              (fX3 >= sX3 && fY3 >= sY3) &&
              (fX4 <= sX4 && fY4 >= sY4);

            if (isInside) {
              const foundIndex = filteredSummaryFields.findIndex(itm => isEqual(itm, filteredField));
              filteredSummaryFields[foundIndex] = f;
              return true;
            }

            const isAround: boolean =
              (sX1 <= fX1 && sY1 <= fY1) &&
              (sX2 >= fX2 && sY2 <= fY2) &&
              (sX3 >= fX3 && sY3 >= fY3) &&
              (sX4 <= fX4 && sY4 >= fY4);

            return isAround;
          });
          if (crossCoords) {  return; }
          filteredSummaryFields.push(f);
        }
      });

      const previewItem = {
        expenseDocuments: {
          ...expenseDocuments,
          summaryFields: filteredSummaryFields
        },
        imageUrl: this.getFileUrl(ocrData.images[index])
      };

      if (!previewDataAlreadyExists) {
        this.previewData.push(previewItem);
      } else {
         // update only part of data (prevent blinking image on correction)
        this.previewData[index].expenseDocuments = {
          ...expenseDocuments,
          summaryFields: filteredSummaryFields
        };
      }
    });
  }

  public getFileUrl(imageUrl: { title: string; key: string }) {
    const basicPath = environment.javaApiUrl + environment.javaApiVersion;
    const apiPath = '/utils/download';
    const params = {
      name: encodeURIComponent(imageUrl.title),
      resource: imageUrl.key,
      uid: this.authService.getToken()
    };
    return `${basicPath}${apiPath}?${new URLSearchParams(params).toString()}`;
  }

  private getSummaryCorrectionField(summaryField: OcrSummaryField, mappedFieldType?: OcrDocumentFieldTypeEnum): OcrFieldCorrectionModel {
    return {
      value: summaryField.valueDetection.text,
      mappedFieldType: mappedFieldType || null,
      summaryField: {
        type:  summaryField.type.text,
        labelDetection: {
          text: get(summaryField, 'labelDetection.text'),
          ...get(summaryField, 'valueDetection.geometry.boundingBox')
        },
        valueDetection: {
          text: summaryField.valueDetection.text,
          ...summaryField.valueDetection.geometry.boundingBox
        }
      }
    };
  }

  private getPositionCorrectionField(
    positionField: OcrLineItemExpenseField,
    lineItemGroupIndex: number,
    expenseIndex: number,
    lineItemExpenseFieldIndex: number,
    lineItemIndex: number,
    mappedFieldType?: OcrPositionFieldTypeEnum
  ): OcrPositionCorrectionModel {
    return {
      mappedPositionType: mappedFieldType || null,
      expenseIndex,
      lineItemGroupIndex,
      summaryField: {
        type:  positionField.type.text,
        columnIndex: lineItemExpenseFieldIndex,
        rowIndex: lineItemIndex,
        labelDetection: {
          text: get(positionField, 'labelDetection.text'),
          ...get(positionField, 'valueDetection.geometry.boundingBox')
        },
        valueDetection: {
          text: positionField.valueDetection.text,
          ...positionField.valueDetection.geometry.boundingBox
        }
      }
    };
  }

  public onClickRecognizedSummaryField(summaryField: OcrSummaryField): void {
    this.activeItem = summaryField;
    this.clickSummaryField.emit(
      this.getSummaryCorrectionField(summaryField)
    );
  }

  public onClickMappedSummaryField(summaryField: OcrSummaryField, pageIndex: number): void {
    this.activeItem = summaryField;
    const mappedFiled = this.getMappedSummaryField(summaryField, pageIndex);

    this.clickSummaryField.emit({
      ...this.getSummaryCorrectionField(summaryField, mappedFiled.fieldType),
      error: mappedFiled.error
    });
  }

  public getMappedSummaryField(summaryField: OcrSummaryField, pageIndex: number): OcrSummaryFieldMapModel {
    const foundSummaryFieldIndex = this.sourceOcrData.expenseDocuments[pageIndex].summaryFields
      .findIndex(f => isEqual(f, summaryField));
    return  this.sourceOcrData.matchedFieldsMap.summaryFieldsMap
      .filter(f => !!f) // todo: remove
      .find(f => (f.pageIndex === pageIndex) && f.fieldIndex === foundSummaryFieldIndex);
  }

  public onClickRecognizedPositionField(
    positionField: OcrLineItemExpenseField,
    lineItemGroupIndex: number,
    expenseIndex: number,
    lineItemExpenseFieldIndex: number,
    lineItemIndex: number,
  ): void {
    this.activeItem = positionField;

    this.clickPositionField.emit(
      this.getPositionCorrectionField(
        positionField,
        lineItemGroupIndex,
        expenseIndex,
        lineItemExpenseFieldIndex,
        lineItemIndex
      )
    );
  }

  public onClickMappedPositionField(
    positionField: OcrLineItemExpenseField,
    lineItemGroupIndex: number,
    expenseIndex: number,
    lineItemIndex: number,
    lineItemExpenseFieldIndex: number,
  ): void {
    this.activeItem = positionField;
    const mappedFiled = this.getMappedPositionField(lineItemGroupIndex, expenseIndex, lineItemIndex, lineItemExpenseFieldIndex);

    this.clickPositionField.emit({
      ...this.getPositionCorrectionField(
        positionField,
        lineItemGroupIndex,
        expenseIndex,
        lineItemExpenseFieldIndex,
        lineItemIndex,
        mappedFiled.fieldType,
      ),
      error: mappedFiled.error
    });
  }

  public getMappedPositionField(
    lineItemGroupIndex: number,
    expenseIndex: number,
    lineItemIndex: number,
    lineItemExpenseFieldIndex: number,
  ): OcrListItemGroupModel {
    return this.sourceOcrData.matchedFieldsMap.listItemGroupsMap
      .filter(f => !!f) // todo: remove
      .find(f => (
        (f.pageIndex === (expenseIndex - 1)) &&
        (f.listItemGroupIndex === (lineItemGroupIndex - 1)) &&
        (f.lineItemIndex === lineItemIndex) &&
        (f.lineItemExpenseFieldIndex === lineItemExpenseFieldIndex)
      ));
  }

  public isSummaryFieldMapped(pageIndex: number, summaryField: OcrSummaryField): boolean {
    if (!this.sourceOcrData || !this.sourceOcrData.matchedFieldsMap || !this.sourceOcrData.matchedFieldsMap.summaryFieldsMap) {
      return false;
    }

    const foundSummaryFieldIndex = this.sourceOcrData.expenseDocuments[pageIndex].summaryFields
      .findIndex(f => isEqual(f, summaryField));

    return this.sourceOcrData.matchedFieldsMap.summaryFieldsMap
      .filter(f => !!f) // todo: remove
      .some((f: OcrSummaryFieldMapModel) => (f.pageIndex === pageIndex) && (f.fieldIndex === foundSummaryFieldIndex));
  }

  public isListItemMapped(
    pageIndex: number,
    listItemGroupIndex: number,
    lineItemIndex: number,
    lineItemExpenseFieldIndex: number,
  ): boolean {
    if (!this.sourceOcrData || !this.sourceOcrData.matchedFieldsMap || !this.sourceOcrData.matchedFieldsMap.listItemGroupsMap) {
      return false;
    }

    return this.sourceOcrData.matchedFieldsMap.listItemGroupsMap
      .filter(f => !!f) // todo: remove
      .some((f: OcrListItemGroupModel) => (
        (f.pageIndex === pageIndex) &&
        (f.listItemGroupIndex === listItemGroupIndex) &&
        (f.lineItemIndex === lineItemIndex) &&
        (f.lineItemExpenseFieldIndex === lineItemExpenseFieldIndex)
      ));
  }

  public isActive(item: OcrSummaryField|OcrLineItemExpenseField): boolean {
    if (!this.activeItem) { return false; }

    return isEqual(item, this.activeItem);
  }

  public resetActive(): void {
    this.activeItem = null;
    this.cdr.detectChanges();
  }

  public nextPage(): void {
    const pagesCount = this.previewData.length || this.sourceOcrData.images.length;

    if (this.currentPage < pagesCount) {
      this.currentPage += 1;
    }
  }

  public prevPage(): void {
    if (this.currentPage !== 1) {
      this.currentPage -= 1;
    }
  }

  public zoomOut(): void {
    if (this.currentZoom > 1) {
      this.currentZoom -= 0.25;
    }
  }

  public zoomIn(): void {
    if (this.currentZoom < 2) {
      this.currentZoom += 0.25;
    }
  }

}
