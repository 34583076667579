import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { LANGUAGES_LIST } from '../../constants';

@Component({
  selector: 'rnpl-language-control',
  templateUrl: './language-control.component.html',
})
export class LanguageControlComponent {

  @Input() control: FormControl;
  @Input() label: string = 'TEAM.LANG';

  public languagesList = LANGUAGES_LIST;

  @Output() fieldChanged: EventEmitter<any> = new EventEmitter<any>();

  public updateField(fieldValue: any): void {
    this.fieldChanged.emit(fieldValue);
  }


}
