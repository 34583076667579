import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { InitialSetupBaseComponent } from '../initial-setup-base/initial-setup-base.component';
import { CompanyProfile, LegalType } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { AdministrationsApiService } from 'projects/workspace/src/app/administration/services/administrations-api.service';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { selectLegalTypes } from 'projects/workspace/src/app/administration/store/selectors';
import { AuthService } from '../../../../auth/auth.service';

@Component({
  selector: 'rnpl-company-type',
  templateUrl: './company-type.component.html',
  styleUrls: ['./company-type.component.scss'],
})
export class CompanyTypeComponent extends InitialSetupBaseComponent implements OnInit {

  public legalTypesList: LegalType[] = [];

  @Output() stepFinished: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public readonly store: Store<AppState>,
    public router: Router,
    public readonly administrationsApiService: AdministrationsApiService,
    public readonly authService: AuthService,
  ) {
    super(store, router, administrationsApiService);
  }

  ngOnInit() {
    this.administrationsApiService.getLegalTypes(this.companyProfile.legalAddress.country_iso3)
      .pipe(takeUntil(this.destroy$))
      .subscribe((legalTypes: LegalType[]) => {
        this.legalTypesList = legalTypes;
      });

    this.store.select(selectLegalTypes)
      .pipe(takeUntil(this.destroy$))
      .subscribe((legalTypes: LegalType[]) => {
        this.legalTypesList = legalTypes;
      });
  }

  public asCompany(): void {
    const companyProfile: CompanyProfile = {
      ...this.companyProfile,
      legalType: this.legalTypesList.find(legal => legal.type === 'GmbH'),
    };
    this.updateStep$(companyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.stepFinished.emit();
      });
  }

  public asSolo(): void {
    if (this.companyProfile.legalType.type === 'Einzelunternehmen') {
      this.stepFinished.emit();
      return;
    }

    const loggedUser = this.authService.getUser();
    const companyProfile: CompanyProfile = {
      ...this.companyProfile,
      legalType: this.legalTypesList.find(legal => legal.type === 'Einzelunternehmen'),
      noVat: true,
      hasCommercialRegistration: false,
      firstName: loggedUser.firstName,
      lastName: loggedUser.lastName,

    };
    this.updateStep$(companyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.stepFinished.emit();
      });
  }

  public updateStep$(company: CompanyProfile): Observable<boolean | CompanyProfile> {
    return this.updateCompanyInfo$(company);
  }

}
