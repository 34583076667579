import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { get } from 'lodash';

import { selectCountries, selectTradeOffer, selectTradeOfferState } from 'projects/workspace/src/app/trade-offer/store/selectors';
import { TradeOfferModel } from 'projects/workspace/src/app/trade-offer/models';
import { CustomerTypeEnum, DeliveryTypesEnum, PaymentMethodsEnum, PaymentTermsEnum, UIStatesEnum } from '../../../../models';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { CountryModel } from '../../../rnpl-common';
import { convertAddressToStringHelper } from '../../../rnpl-common/helpers';
import { TradeOfferApiService } from 'projects/workspace/src/app/trade-offer/services/trade-offer-api.service';
import { FormInputChangedModel } from 'projects/workspace/src/app/shared/models';
import { OfferFormService } from 'projects/workspace/src/app/trade-offer/services/offer-form.service';
import { AddressTypeEnum } from 'projects/workspace/src/app/sales-order/enums/address-type.enum';
import { ModalNameEnum } from '../../../../models/modal-name.enum';
import { PartnersTypeEnum } from 'projects/workspace/src/app/partners/corporate/enums';
import { CommonModalsActionsEnum, ConfirmModalComponent } from '../../modals-common';
import { UPDATE_SMALL_BUSINESS_MODAL_CONFIG } from 'projects/workspace/src/app/shared/constants';
import { selectAccountingSettings } from 'projects/workspace/src/app/store/selectors/shared.selectors';
import { AccountingSettingsModel } from 'projects/workspace/src/app/accounting/accounting-settings-module/models';

@Component({
  selector: 'rnpl-offer-billing-info-modal',
  templateUrl: './offer-billing-info-modal.component.html',
})
export class OfferBillingInfoModalComponent implements OnInit, OnDestroy {

  public accountingSettings: AccountingSettingsModel;
  public tradeOffer: TradeOfferModel;
  public countries: CountryModel[];
  public isReadonly: boolean = true;
  public addressTypeEnum: typeof AddressTypeEnum = AddressTypeEnum;
  public addressTypeList = [];
  public addressTypeForGeneralPartner = [
    {value: AddressTypeEnum.USER_DEFINED, label: 'COMMON.NEW_ADDRESS'},
    {value: AddressTypeEnum.SIMPLIFIED, label: 'COMMON.USER_DEFINED_ADDRESS'},
  ];
  public readonly paymentTerms = PaymentTermsEnum;
  public readonly paymentMethods = PaymentMethodsEnum;
  public readonly deliveryTypes = DeliveryTypesEnum;
  public modalNameEnum: typeof ModalNameEnum = ModalNameEnum;

  private convertAddressToString = convertAddressToStringHelper;

  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private readonly store: Store<AppState>,
    public dialogRef: MatDialogRef<OfferBillingInfoModalComponent>,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private cdr: ChangeDetectorRef,
    private tradeOfferApiService: TradeOfferApiService,
    private offerFormService: OfferFormService,
    @Inject(MAT_DIALOG_DATA) public data: {
      form: FormGroup
    }
  ) { }

  ngOnInit() {
    this.store.select(selectAccountingSettings)
      .pipe(takeUntil(this.destroy$))
      .subscribe((accountingSettings: AccountingSettingsModel) => this.accountingSettings = accountingSettings);

    this.isLoading$.next(true);
    this.selectCountries();

    this.store.select(selectTradeOffer)
      .pipe(takeUntil(this.destroy$))
      .subscribe((tradeOffer: TradeOfferModel) => {
        this.tradeOffer = tradeOffer;
        this.addressTypeList = [
          {value: AddressTypeEnum.BILLING, label: 'FORM.BILLING_ADDRESS', enabled: this.isCustomerSelected},
          {value: AddressTypeEnum.DELIVERY, label: 'FORM.DELIVERY_ADDRESS', enabled: this.isCustomerSelected},
          {value: AddressTypeEnum.USER_DEFINED, label: 'COMMON.NEW_ADDRESS', enabled: true},
          {value: AddressTypeEnum.SIMPLIFIED, label: 'COMMON.USER_DEFINED_ADDRESS', enabled: true},
        ].filter(i => i.enabled);

        this.updateFormValid();
        this.setFormsState();

        this.isLoading$.next(false);
        this.offerFormService.initBillingInfoForm(tradeOffer);
        this.data.form = this.offerFormService.billingInfoForm;
      });

    this.store.select(selectTradeOfferState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: UIStatesEnum) => {
        this.isReadonly = response === UIStatesEnum.VIEW;
        this.setFormsState();
      });
  }

  private updateFormValid(): void {
    if (get(this.tradeOffer, 'billingInformation.sconto.enabled')) {
      this.scontoRateControl.setValidators(Validators.required);
      this.scontoPeriodControl.setValidators(Validators.required);
    } else {
      this.scontoRateControl.clearValidators();
      this.scontoPeriodControl.clearValidators();
    }
    this.scontoRateControl.updateValueAndValidity();
    this.scontoPeriodControl.updateValueAndValidity();
  }

  private selectCountries() {
    this.store.select(selectCountries)
      .pipe(takeUntil(this.destroy$))
      .subscribe((countriesFromStore: CountryModel[]) => {
        this.countries = countriesFromStore;
      });
  }

  private setFormsState(): void {
    const opts = {onlySelf: true, emitEvent: false};
    if (this.isReadonly) {
      this.data.form.disable(opts);
    } else {
      this.data.form.enable(opts);
      this.cdr.detectChanges();
    }

    if (this.tradeOffer.billingInformation.sameAsDeliveryAddress || !get(this.tradeOffer, 'properties.company.id')) {
      this.billingAddressFormGroup.disable(opts);
      this.nameControl.disable(opts);
    }

    if (!get(this.tradeOffer, 'properties.company.id')) {
      this.addressTemplateControl.disable(opts);
    }

    if (
      this.isGeneralPartner || (get(this.tradeOffer, 'properties.partnerType') === PartnersTypeEnum.CORPORATE)) {
      this.nameControl.disable(opts);
    }

    if (this.tradeOffer.deliveryInformation.deliveryType === this.deliveryTypes.PICK_UP) {
      this.data.form.get('sameAsDeliveryAddress').disable(opts);
    }

    if (get(this.tradeOffer, 'properties.company.generalType') === 'inland') {
      this.billingAddressFormGroup.get('country_iso3').disable(opts);
    }

    if (this.tradeOffer.properties.smallBusiness) {
      this.vatDisabled.disable(opts);
    }
  }

  public updateTradeOffer(fieldValue, fieldName: string): void {
    this.tradeOfferApiService.updateTradeOffer(this.tradeOffer.id, {fieldValue, fieldName})
      .subscribe(); // updated via store
  }

  public scontoFieldUpdated(field: FormInputChangedModel): void {
    const fieldName = 'billingInformation.sconto.' + field.fieldName;
    this.updateTradeOffer(field.fieldValue, fieldName);
  }

  // public updateBillingInfoAddressByCustomer(): void {
  //   this.tradeOfferApiService.updateBillingInfoAddressByCustomer(this.tradeOffer.id).subscribe();
  // }

  public updateAddressField(field: FormInputChangedModel): void {
    const fieldName = 'billingInformation.billingAddress.' + field.fieldName;
    this.updateTradeOffer(field.fieldValue, fieldName);
  }

  public updateSmallBusiness(fieldValue, fieldName: string): void {
    if (this.accountingSettings.smallBusiness.forward === fieldValue) {
      this.updateTradeOffer(fieldValue, fieldName);
      return;
    }

    const dialog = this.dialog.open(ConfirmModalComponent, UPDATE_SMALL_BUSINESS_MODAL_CONFIG);

    dialog.afterClosed().subscribe((res: CommonModalsActionsEnum) => {
      if (res === CommonModalsActionsEnum.CONFIRM) {
        this.updateTradeOffer(fieldValue, fieldName);
      } else if (res === CommonModalsActionsEnum.REJECT) {
        this.router.navigate(['/accounting/settings/general-settings']);
      } else {
        this.offerFormService.initBillingInfoForm(this.tradeOffer);
        this.data.form = this.offerFormService.billingInfoForm;
      }
    });
  }

  public editAddress(): void {
    this.addressTemplateControl.setValue(AddressTypeEnum.USER_DEFINED);
    this.updateTradeOffer(AddressTypeEnum.USER_DEFINED, 'billingInformation.addressTemplate')
  }

  get billingAddressFormGroup(): FormGroup { return this.data.form.get('billingAddress') as FormGroup; }
  get scontoFormGroup(): FormGroup { return this.data.form.get('sconto') as FormGroup; }
  get scontoRateControl(): FormControl { return this.data.form.get('sconto').get('rate') as FormControl; }
  get scontoPeriodControl(): FormControl { return this.data.form.get('sconto').get('period') as FormControl; }
  get vatDisabled(): FormControl { return this.data.form.get('vatDisabled') as FormControl; }
  get smallBusiness(): FormControl { return this.data.form.get('smallBusiness') as FormControl; }
  get addressTemplateControl(): FormControl { return this.data.form.get('addressTemplate') as FormControl; }
  get nameControl(): FormControl { return this.data.form.get('name') as FormControl; }

  get paymentTermsValue(): string {
    return this.data.form.get('paymentTerms').value;
  }

  get dueWithinDays(): FormControl {
    return this.data.form.get('paymentDueWithin') as FormControl;
  }

  get billingAddressAsText(): string {
    if (!this.data.form) { return ''; }

    const addressVal = this.billingAddressFormGroup.value;
    return this.convertAddressToString(addressVal, this.countries);
  }

  public get isCustomerSelected(): boolean {
    return !!get(this, 'tradeOffer.properties.company.runpleId');
  }

  public get isGeneralPartner(): boolean {
    return get(this, 'tradeOffer.properties.partnerType') === CustomerTypeEnum.GENERAL
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
