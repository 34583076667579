import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DatePipe } from '@angular/common';
import { BehaviorSubject, ReplaySubject, Subscription } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { get, isEqual } from 'lodash';

import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { CountryModel } from '../../../rnpl-common';
import { selectCountries } from 'projects/workspace/src/app/store/selectors/shared.selectors';
import {
  selectDeliveryNote,
  selectDeliveryNoteDeliveryInformation, selectDeliveryNoteState
} from 'projects/workspace/src/app/delivery-note/store/selectors';
import { DeliveryInformation, DeliveryNote } from 'projects/workspace/src/app/delivery-note/models/delivery-note.model';
import { AddressModel, CustomerTypeEnum, DeliveryTypesEnum, UIStatesEnum } from '../../../../models';
// import { UpdateDeliveryInfoBlockValid } from 'projects/workspace/src/app/delivery-note/store/actions/delivery-note.actions';
import { DeliveryNoteListTabsEnum } from 'projects/workspace/src/app/delivery-note/enums';
import { DeliveryNoteFormService } from 'projects/workspace/src/app/delivery-note/services/delivery-note-form.service';
import { convertAddressToStringHelper } from '../../../rnpl-common/helpers';
import { CrmService } from 'projects/workspace/src/app/crm/crm.service';
import { StockAddressesService } from 'projects/workspace/src/app/warehouse/services';
import { DeliveryNoteApiService } from 'projects/workspace/src/app/delivery-note/services/delivery-note-api.service';
import { AddressTypeEnum } from 'projects/workspace/src/app/sales-order/enums/address-type.enum';

@Component({
  selector: 'rnpl-delivery-info-modal',
  templateUrl: './delivery-info-modal.component.html',
})
export class DeliveryInfoModalComponent implements OnInit, OnDestroy {
  public countries: CountryModel[];
  public currentDeliveryNote: DeliveryNote;
  public selectedDeliveryType: DeliveryTypesEnum = DeliveryTypesEnum.DELIVERY;
  public isReadonly: boolean = true;
  public formsSubscriptions: Subscription[] = [];
  public readonly deliveryTypes = DeliveryTypesEnum;
  public readonly addressTypeEnum = AddressTypeEnum;
  private convertAddressToString = convertAddressToStringHelper;
  public addressTypeList = [];
  public addressTypeForGeneralPartner = [
    {value: AddressTypeEnum.USER_DEFINED, label: 'COMMON.NEW_ADDRESS'},
    {value: AddressTypeEnum.SIMPLIFIED, label: 'COMMON.USER_DEFINED_ADDRESS'},
  ];
  public addressTypeListPickUp = [
    {value: AddressTypeEnum.WAREHOUSE, label: 'FORM.WAREHOUSE_ADDRESS'},
    {value: AddressTypeEnum.USER_DEFINED, label: 'COMMON.NEW_ADDRESS'},
    {value: AddressTypeEnum.SIMPLIFIED, label: 'COMMON.USER_DEFINED_ADDRESS'},
  ];

  public readonly deliveryInformation$: BehaviorSubject<DeliveryInformation> = new BehaviorSubject<DeliveryInformation>(null);
  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<DeliveryInfoModalComponent>,
    public deliveryNoteFormService: DeliveryNoteFormService,
    private datePipe: DatePipe,
    private crmService: CrmService,
    private stockAddressesService: StockAddressesService,
    private deliveryNoteApiService: DeliveryNoteApiService,

    @Inject(MAT_DIALOG_DATA) public data: {
      form: FormGroup,
      pickUpAddressForm: FormGroup,
      deliveryToAddressForm: FormGroup
    }
  ) { }

  ngOnInit() {
    this.selectCountries();

    this.selectAndHandleDeliveryInformation();
    this.trackDeliveryNoteChanges();
    this.selectAndHandleState();
  }

  private updateDeliveryInfo(deliveryNote): void {
    if (!!this.currentDeliveryNote) {
      this.deliveryNoteApiService.updateDeliveryNote(this.currentDeliveryNote.id, deliveryNote).subscribe();
    }
  }

  private selectCountries(): void {
    this.store.select(selectCountries)
      .pipe(takeUntil(this.destroy$))
      .subscribe((countriesFromStore: CountryModel[]) => {
        this.countries = countriesFromStore;
        this.cdr.detectChanges();
      });
  }

  private selectAndHandleState() {
    this.store.select(selectDeliveryNoteState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: UIStatesEnum) => {
        this.isReadonly = state === UIStatesEnum.VIEW || state === UIStatesEnum.PACKING;
        this.setFormsState();
      });
  }

  private selectAndHandleDeliveryInformation() {
    this.store.select(selectDeliveryNoteDeliveryInformation)
      .pipe(distinctUntilChanged(isEqual), takeUntil(this.destroy$))
      .subscribe((deliveryInformation: DeliveryInformation) => {
        this.formsSubscriptions.forEach((sub) => sub.unsubscribe());
        this.deliveryInformation = deliveryInformation;
        if (!this.deliveryInformation) {
          this.deliveryNoteFormService.initForm({} as DeliveryInformation, DeliveryTypesEnum.DELIVERY);
          this.deliveryNoteFormService.initPickUpForm();

          this.data.form = this.deliveryNoteFormService.form;
          this.data.pickUpAddressForm = this.deliveryNoteFormService.pickUpAddressForm;
        } else {
          const pickUpAddress = get(this.deliveryInformation, 'pickUpAddress');
          this.selectedDeliveryType = get(this.deliveryInformation, 'deliveryType', DeliveryTypesEnum.DELIVERY) as DeliveryTypesEnum;

          this.deliveryNoteFormService.initForm(this.deliveryInformation, this.selectedDeliveryType);
          this.deliveryNoteFormService.initPickUpForm(pickUpAddress);

          this.data.form = this.deliveryNoteFormService.form;
          this.data.pickUpAddressForm = this.deliveryNoteFormService.pickUpAddressForm;

          const estimatedDeliveryDate = get(this.deliveryInformation, 'estimatedDeliveryDate');

          if (estimatedDeliveryDate) {
            const stringYYYYMMDD = this.datePipe.transform(estimatedDeliveryDate, 'yyyy-MM-dd');
            this.estimatedDeliveryDate.setValue(stringYYYYMMDD, {emitEvent: false});
          }
        }

        this.trackFormChanges();
        // this.updateDeliveryInfoBlockValid();
        this.setFormsState();
      });
  }

  private trackFormChanges(): void {
    let updatedDeliveryNote: DeliveryNote = this.currentDeliveryNote;

    const subscription = this.data.form.valueChanges
      .pipe(
        distinctUntilChanged(isEqual),
        takeUntil(this.destroy$)
      )
      .subscribe((data: DeliveryInformation) => {
        updatedDeliveryNote = {
          ...this.currentDeliveryNote,
          deliveryInformation: {
            ...this.currentDeliveryNote.deliveryInformation,
            ...data,
            deliveryService: this.deliveryServiceControl.value,
          }
        };

        this.updateDeliveryInfo(updatedDeliveryNote);
      });

    const deliveryToAddressFormSubscription = this.data.deliveryToAddressForm.valueChanges
      .pipe(
        distinctUntilChanged(isEqual),
        takeUntil(this.destroy$)
      )
      .subscribe((value: AddressModel) => {
        updatedDeliveryNote = {
          ...this.currentDeliveryNote,
          deliveryInformation: {
            ...this.currentDeliveryNote.deliveryInformation,
            deliveryToAddress: value
          }
        };

        this.updateDeliveryInfo(updatedDeliveryNote);
      });

    const pickUpAddressFormSubscription = this.data.pickUpAddressForm.valueChanges
      .pipe(
        distinctUntilChanged(isEqual),
        takeUntil(this.destroy$))
      .subscribe((value: AddressModel) => {
        updatedDeliveryNote = {
          ...this.currentDeliveryNote,
          deliveryInformation: {
            ...this.currentDeliveryNote.deliveryInformation,
            pickUpAddress: value
          }
        };

        this.updateDeliveryInfo(updatedDeliveryNote);
      });

    this.formsSubscriptions.push(subscription);
    this.formsSubscriptions.push(deliveryToAddressFormSubscription);
    this.formsSubscriptions.push(pickUpAddressFormSubscription);
  }

  private trackDeliveryNoteChanges(): void {
    this.store.select(selectDeliveryNote)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: DeliveryNote) => {
        this.currentDeliveryNote = response;
        // this.updateDeliveryInfoBlockValid();
        this.setFormsState();
        this.cdr.detectChanges();
        const isCustomerSelected: boolean = !!get(response, 'customer.company.id');

        this.addressTypeList = [
          {value: AddressTypeEnum.BILLING, label: 'FORM.BILLING_ADDRESS', enabled: isCustomerSelected},
          {value: AddressTypeEnum.DELIVERY, label: 'FORM.DELIVERY_ADDRESS', enabled: isCustomerSelected},
          {value: AddressTypeEnum.USER_DEFINED, label: 'COMMON.NEW_ADDRESS', enabled: true},
          {value: AddressTypeEnum.SIMPLIFIED, label: 'COMMON.USER_DEFINED_ADDRESS', enabled: true},
        ].filter(i => i.enabled);
      });
  }

  // private updateDeliveryInfoBlockValid(): void {
  //   let isFormsValid: boolean;
  //   if (this.hasLinkedEco) {
  //     this.data.form.get('deliveryService').setValidators(Validators.required);
  //     this.data.form.get('trackingCode').setValidators(Validators.required);
  //   } else {
  //     this.data.form.get('trackingCode').clearValidators();
  //     this.data.form.get('deliveryService').clearValidators();
  //   }
  //   this.data.form.get('deliveryService').updateValueAndValidity({emitEvent: false});
  //   this.data.form.get('trackingCode').updateValueAndValidity({emitEvent: false});
  //
  //   if (this.selectedDeliveryType === DeliveryTypesEnum.DELIVERY) {
  //     isFormsValid = this.data.form.valid && this.data.deliveryToAddressForm.valid;
  //   } else {
  //     isFormsValid = this.data.form.valid && this.data.pickUpAddressForm.valid;
  //   }
  //
  //   this.store.dispatch(UpdateDeliveryInfoBlockValid({ deliveryInfoBlockValid: isFormsValid }));
  // }

  private setFormsState(): void {
    const opts = { onlySelf: true, emitEvent: false };
    if (this.isReadonly) {
      this.data.form.disable(opts);
      this.data.pickUpAddressForm.disable(opts);
      this.data.deliveryToAddressForm.disable(opts);
    } else {
      this.data.form.enable(opts);
      this.data.pickUpAddressForm.enable(opts);
      this.data.deliveryToAddressForm.enable(opts);
      if (get(this, 'currentDeliveryNote.isLinkedSo') || this.currentDeliveryNote.era || this.currentDeliveryNote.eco) {
        this.disableLinkedDeliveryNoteFields();
      }
      if (!get(this.currentDeliveryNote, 'customer.company.id')) {
        this.data.pickUpAddressForm.disable(opts);
        this.data.deliveryToAddressForm.disable(opts);
        this.addressTemplateControl.disable(opts);
        this.nameControl.disable(opts);
      }
      if (this.isGeneralPartner) {
        this.nameControl.disable(opts);
      }
      this.cdr.detectChanges();
    }
  }

  private disableLinkedDeliveryNoteFields(): void {
    const opts = {onlySelf: true, emitEvent: false};
    const fieldsToDisable = [
      'deliveryType',
    ];

    if (
      (this.currentDeliveryNote.status === DeliveryNoteListTabsEnum.OPEN || this.currentDeliveryNote.status === DeliveryNoteListTabsEnum.DRAFT)
      && (this.currentDeliveryNote.isLinkedSo || this.currentDeliveryNote.era || this.currentDeliveryNote.eco)
    ) {
      fieldsToDisable.push('partialShipment');
      fieldsToDisable.push('isExpressDelivery');
    }

    fieldsToDisable.forEach(field => {
      (this.data.form.get(field)) ? this.data.form.get(field).disable(opts) : console.warn(`Can't find field ${field}`);
    });
  }

  public changeDeliveryType(deliveryType: DeliveryTypesEnum): void {
    this.selectedDeliveryType = deliveryType;
  }

  public get isPickUpTypeSelected(): boolean {
    return this.selectedDeliveryType === this.deliveryTypes.PICK_UP;
  }

  public get deliveryAddressAsText(): string {
    if (!this.data.form) {
      return '';
    }

    const billingAddressVal = this.data.deliveryToAddressForm.value;
    return this.convertAddressToString(billingAddressVal, this.countries);
  }

  public get pickUpAddressAsText(): string {
    if (!this.data.form) {
      return '';
    }

    const pickUpAddressVal = this.data.pickUpAddressForm.value;
    return this.convertAddressToString(pickUpAddressVal, this.countries);
  }

  // public fillCustomerDeliveryAddress(): void {
  //   this.crmService.getPartnerWarehouseAddress(this.currentDeliveryNote.partner.id)
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe((address: AddressModel) => {
  //       this.data.deliveryToAddressForm.patchValue(address);
  //     });
  // }

  public fillOwnWarehouseDeliveryAddress(): void {
    this.stockAddressesService.getBasicWarehouseAddress()
      .pipe(takeUntil(this.destroy$))
      .subscribe((address: AddressModel) => {
        this.data.pickUpAddressForm.patchValue(address);
      });
  }

  public editAddress(): void {
    this.addressTemplateControl.patchValue(AddressTypeEnum.USER_DEFINED);
  }

  get deliveryInformation(): DeliveryInformation {
    return this.deliveryInformation$.getValue();
  }

  set deliveryInformation(deliveryInformationData: DeliveryInformation) {
    this.deliveryInformation$.next(deliveryInformationData);
  }

  get estimatedDeliveryDate(): FormControl {
    return this.data.form.get('estimatedDeliveryDate') as FormControl;
  }

  get hasLinkedEco(): boolean {
    return !!get(this, 'currentDeliveryNote.eco.id');
  }

  get isExpressDelivery(): FormControl {
    return this.data.form.get('isExpressDelivery') as FormControl;
  }

  get deliveryServiceControl(): FormControl {
    return this.data.form.get('deliveryService') as FormControl;
  }

  get nameControl(): FormControl {
    return this.data.form.get('name') as FormControl;
  }

  get addressTemplateControl(): FormControl {
    return this.data.form.get('addressTemplate') as FormControl;
  }

  get isGeneralPartner(): boolean {
    return get(this.currentDeliveryNote, 'customer.partnerType') === CustomerTypeEnum.GENERAL;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
