import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { DOCUMENT } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { ContextMenuItemModel } from '../../../models/context-menu.model';
import { RowClick } from './custom-table.interface';
import { PositionsActionsType, TableActivateTypes } from './custom-table.enums';
import { PaginationModel } from '../../../models';
import { ColumnTable } from './models/column-model';
import { TableColumnModelExtended } from 'common/src/models/table-column.model';
import { TableSummaryBarItemModel } from '../table-summary-bar/table-summary-bar.model';
import { ActionsReorderingPositionModel, PositionsIndexModel } from '../../position-card/position-card-models.model';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { FILE_ICON } from 'projects/workspace/src/app/shared/constants';

@Component({
  selector: 'rnpl-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
})
export class CustomTableComponent implements OnInit, OnDestroy {
  public companyProfile: CompanyProfile;
  public columns: TableColumnModelExtended = [];
  public tableActivateTypes: typeof TableActivateTypes = TableActivateTypes;
  public positionsActionsType: typeof PositionsActionsType = PositionsActionsType;
  public fileIcon = FILE_ICON;

  public contextmenuX: any;
  public contextmenuY: any;
  public contextmenu = false;
  public selectedRow: Object;

  private defaultMenuWidth = 185;
  private pageScrollbarWidth = 16;
  private menuItemHeight = 40;
  private totalIndentation = 66;

  private lastClickedRow: Object = null;
  public expanded: boolean;
  // public _sort: { prop: string, dir: string } = {prop: null, dir: null};

  public positionsIndexes$: BehaviorSubject<Array<PositionsIndexModel>> = new BehaviorSubject<Array<PositionsIndexModel>>([]);

  @ViewChild('table', { static: false }) table: DatatableComponent;
  @ViewChild('headerAddButton', { static: true }) headerAddButton: TemplateRef<any>;
  @ViewChild('headerTextWithRemoveButton', { static: true }) headerTextWithRemoveButton: TemplateRef<any>;
  @ViewChild('headerSelections', { static: true }) headerSelections: TemplateRef<any>;
  @ViewChild('headerPrimary', { static: true }) headerPrimary: TemplateRef<any>;
  @ViewChild('headerSelectionsToggle', { static: true }) headerSelectionsToggle: TemplateRef<any>;
  @ViewChild('headerWithHints', { static: true }) headerWithHints: TemplateRef<any>;
  @ViewChild('primary', { static: true }) primary: TemplateRef<any>;
  @ViewChild('withCellCustomClass', { static: true }) withCellCustomClass: TemplateRef<any>;
  @ViewChild('numberRows', { static: true }) numberRows: TemplateRef<any>;
  @ViewChild('placeholder', { static: true }) placeholder: TemplateRef<any>;
  @ViewChild('withAvatar', { static: true }) withAvatar: TemplateRef<any>;
  @ViewChild('withCountry', { static: true }) withCountry: TemplateRef<any>;
  // @ViewChild('withCountryISO3', { static: true }) withCountryISO3: TemplateRef<any>;
  @ViewChild('withStatusLabel', { static: true }) withStatusLabel: TemplateRef<any>;
  @ViewChild('withArrowRight', { static: true }) withArrowRight: TemplateRef<any>;
  @ViewChild('withActions', { static: true }) withActions: TemplateRef<any>;
  @ViewChild('withSelection', { static: true }) withSelection: TemplateRef<any>;
  @ViewChild('withSelectionToggle', { static: true }) withSelectionToggle: TemplateRef<any>;
  @ViewChild('withSelectionRadioButton', { static: true }) withSelectionRadioButton: TemplateRef<any>;
  @ViewChild('withAvatarArrowRight', { static: true }) withAvatarArrowRight: TemplateRef<any>;
  @ViewChild('withArrowRightEdit', { static: true }) withArrowRightEdit: TemplateRef<any>;
  @ViewChild('withStatusLine', { static: true }) withStatusLine: TemplateRef<any>;
  @ViewChild('withDate', { static: true }) withDate: TemplateRef<any>;
  @ViewChild('withClickableIcon', { static: true }) withClickableIcon: TemplateRef<any>;
  @ViewChild('withClickableRowIcon', { static: true }) withClickableRowIcon: TemplateRef<any>;
  @ViewChild('withInput', { static: true }) withInput: TemplateRef<any>;
  @ViewChild('withTextarea', { static: true }) withTextarea: TemplateRef<any>;
  @ViewChild('withCurrency', { static: true }) withCurrency: TemplateRef<any>;
  @ViewChild('withCurrencyPrimary', { static: true }) withCurrencyPrimary: TemplateRef<any>;
  @ViewChild('withDropdown', { static: true }) withDropdown: TemplateRef<any>;
  @ViewChild('withToggle', { static: true }) withToggle: TemplateRef<any>;
  @ViewChild('withArrowLink', { static: true }) withArrowLink: TemplateRef<any>;
  @ViewChild('withArrowLinkNew', { static: true }) withArrowLinkNew: TemplateRef<any>;
  @ViewChild('withCorporatePartner', { static: true }) withCorporatePartner: TemplateRef<any>;
  @ViewChild('withBank', { static: true }) withBank: TemplateRef<any>;
  @ViewChild('withBankAccount', { static: true }) withBankAccount: TemplateRef<any>;
  @ViewChild('withProfit', { static: true }) withProfit: TemplateRef<any>;
  @ViewChild('withLinkPrimary', { static: true }) withLinkPrimary: TemplateRef<any>;
  @ViewChild('withLinksList', { static: true }) withLinksList: TemplateRef<any>;
  @ViewChild('withLoadingIndicator', { static: true }) withLoadingIndicator: TemplateRef<any>;
  @ViewChild('withIconPrimary', { static: true }) withIconPrimary: TemplateRef<any>;
  @ViewChild('withUnitType', { static: true }) withUnitType: TemplateRef<any>;
  @ViewChild('withPermissionEdit', { static: true }) withPermissionEdit: TemplateRef<any>;
  @ViewChild('withPermissionView', { static: true }) withPermissionView: TemplateRef<any>;
  @ViewChild('withModuleName', { static: true }) withModuleName: TemplateRef<any>;
  @ViewChild('withHighlightTypes', { static: true }) withHighlightTypes: TemplateRef<any>;
  @ViewChild('withPositionsActionsList', { static: true }) withPositionsActionsList: TemplateRef<any>;
  @ViewChild('withProduct', { static: true }) withProduct: TemplateRef<any>;
  @ViewChild('primaryWithNumber', { static: true }) primaryWithNumber: TemplateRef<any>;
  @ViewChild('withAttachment', { static: true }) withAttachment: TemplateRef<any>;

  @Input() rows: any[];
  @Input() set cols(cols: TableColumnModelExtended[]) {
    this.updateCols(cols);
  }

  @Input() hasBorder: boolean = false;
  @Input() bgWhite: boolean = false;

  // @Input() set sorting(sort: FilterModel|FilterModelNew) {
  //   if (sort.hasOwnProperty('nameColumn')) {
  //     this._sort['prop'] = sort['nameColumn'];
  //   }
  //
  //   if (sort.hasOwnProperty('sortBy')) {
  //     this._sort['prop'] = sort['sortBy'];
  //   }
  //
  //   if (sort.hasOwnProperty('value')) {
  //     this._sort['dir'] = sort['value'].toLowerCase();
  //   }
  //
  //   if (sort.hasOwnProperty('direction')) {
  //     this._sort['dir'] = sort['direction'].toLowerCase();
  //   }
  // }

  @Input() cellBinding: boolean = false; // if true - update passed (source) rows
  @Input('emptyStatePlaceholder') set emptyStatePlaceholderSetter(text: string) {
    this.message = {
      emptyMessage: `
      <div class="inner-table-empty-state">
        <div class="inner-table-empty-state-icon">
          <i class="icon icon-lock"></i>
        </div>
        <p class="inner-table-empty-state-descr">
          ${text}
        </p>
      </div>
    `
    };
  }

  @Input() tableIndex: number;
  @Input() innerViewTemplate?: TemplateRef<any>;

  @Input() scrollbarV: boolean = false;
  @Input() scrollbarH: boolean = true;
  @Input() externalSorting: boolean = false;
  @Input() numberRow: boolean = true; // column with number rows
  @Input() icChecked: boolean = true; // Column with checkbox select
  @Input() showContextMenu: boolean = false; // show context menu
  @Input() virtualization: boolean = false;
  @Input() selectedType: string = 'checkbox';

  @Input('selectedRows') set selectedRowsSetter(rows: any[]) {
    this.selected = rows;
    this.selectedRows.emit(rows);
  }
  @Input() contextmenuItems: Array<ContextMenuItemModel>;
  @Input() pagination: PaginationModel = { page: 0, per_page: 100, count: 100 }; // pagination
  @Input() tableSummaryBarItems: Array<TableSummaryBarItemModel>;

  @Output() public selectedRows: EventEmitter<any> = new EventEmitter();
  @Output() public singleRowSelected: EventEmitter<any> = new EventEmitter();
  @Output() public allRowsSelected: EventEmitter<any> = new EventEmitter();
  @Output() public clickArrow: EventEmitter<any> = new EventEmitter();
  @Output() public clickText: EventEmitter<any> = new EventEmitter();
  @Output() public clickRow: EventEmitter<RowClick> = new EventEmitter();
  @Output() public clickAddOrRemove: EventEmitter<any> = new EventEmitter();
  @Output() public clickBtnEmit: EventEmitter<any> = new EventEmitter();
  @Output() public clickAddColumn: EventEmitter<any> = new EventEmitter();
  @Output() public clickRemoveColumn: EventEmitter<any> = new EventEmitter();
  @Output() public clickenableOrDisable: EventEmitter<any> = new EventEmitter();
  @Output() public editCell: EventEmitter<any> = new EventEmitter();
  @Output() public sort: EventEmitter<any> = new EventEmitter();
  @Output() public closingInnerTable: EventEmitter<any> = new EventEmitter();
  @Output() public positionsActionList: EventEmitter<ActionsReorderingPositionModel> = new EventEmitter();

  // for context menu
  @Output() public view: EventEmitter<any> = new EventEmitter();
  @Output() public deleteItem: EventEmitter<any> = new EventEmitter();
  @Output() public moveToArchive: EventEmitter<any> = new EventEmitter();
  @Output() public sendEmail: EventEmitter<any> = new EventEmitter();
  @Output() public addToBookmarks: EventEmitter<any> = new EventEmitter();
  @Output() public page: EventEmitter<any> = new EventEmitter();

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public selected: TableColumnModelExtended[] = [];
  public message = {
    emptyMessage: `
      <div class="inner-table-empty-state">
        <div class="inner-table-empty-state-icon">
          <i class="icon icon-lock"></i>
        </div>
        <p class="inner-table-empty-state-descr">
            ${this.translateService.instant('COMMON.POSITION_NOT_SPECIFIED')}
        </p>
      </div>
    `
  };

  @HostListener('document:click', ['$event'])
  // hides context-menu when clicking outside
  clickedOutside($event: MouseEvent) {
    if ((<HTMLInputElement>$event.target).classList.contains('contextmenu')) {
      this.contextmenu = false;
    }
  }

  @HostListener('window:scroll')
  // hides context-menu when scrolling window
  public scrollWindow() {
    this.contextmenu = false;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private store: Store<AppState>,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((companyProfile: CompanyProfile) => {
        this.companyProfile = companyProfile;
      });
  }

  ngOnInit(): void {
    this.expanded = false;
    this.positionsIndexes$.next(this.getPositionsIndex());
  }

  public updateCols(columns: TableColumnModelExtended[]): void {
    if (!columns) { return; }

    this.positionsIndexes$.next(this.getPositionsIndex());

    this.columns = columns.map((col: TableColumnModelExtended) => ({
      ...col,
      cellTemplate: this[col.cellTemplate],
      headerTemplate: this[col.headerTemplate],
    }));
  }

  // Inline editing handler
  public updateValueHandler(data, row?) {
    const { value, cell, rowIndex } = data;

    if (this.cellBinding) {
      if (row) {
        row[cell] = value; // right way to update row data
      } else {
        this.rows[rowIndex][cell] = value; // mutating this array causes problems
        this.rows = [...this.rows];
      }
    }

    this.editCell.emit({ ...data, tableIndex: this.tableIndex, row: row });
  }

  public onSelect({ selected }) {
    if (selected) {
      this.selected.splice(0, this.selected.length);
      this.selected.push(...selected);
      this.selectedRows.emit(this.selected);
    }
  }

  public singleRowSelectedChanged(row): void {
    this.singleRowSelected.emit({
      row,
      isActive: this.selected.includes(row)
    });
  }

  public allRowsSelectedChanged(allSelected: boolean): void {
    this.allRowsSelected.emit(allSelected);
  }

  public onSort(event): void {
    this.table.offset = this.pagination.page - 1;
    this.sort.emit(event);
  }

  public clickArrowHandler(row): void {
    this.clickArrow.emit(row);
  }

  public columnActionHandler(actionType, row, rowIndex, moveTo?: number, returnData?: any): void {
    this.positionsActionList.emit({
      rowIndex,
      actionType,
      row,
      moveTo,
      returnData
    });
  }

  public onActivate(event): void {
    if (event.type === 'click') {
      const clickedColumn: any = event.column;
      if (
        clickedColumn && clickedColumn.clicktype
        && event.row && event.row.hideRowActions && event.row.hideRowActions[clickedColumn.clicktype]
      ) {
        return;
      }

      if (clickedColumn && clickedColumn.clicktype) {
        switch (clickedColumn.clicktype) {
          case TableActivateTypes.RowDetail:
            this.toggleExpandRow(event);
            break;
          case TableActivateTypes.Sidebar:
            this.clickArrowTextReceiver(event.row);
            this.outputClickEvent(event);
            break;
          default:
            this.outputClickEvent(event);
        }
      }
    }
  }

  public outputClickEvent(event): void {
    if (event.event && event.event.view.getSelection().type === 'Range') {
      return;
    }
    this.clickRow.emit({
      ...event,
      type: event.column.clicktype,
      tableIndex: this.tableIndex,
    });
  }

  public addOrRemoveClick(type: 'add' | 'remove', rowIndex: string): void { // todo: refactor
    this.clickAddOrRemove.emit({ type, rowIndex });
  }

  public clickBtn(actionName, row): void {
    this.clickBtnEmit.emit({ actionName, row });
  }

  public addColumnClick(column: ColumnTable): void { // todo: refactor
    this.clickAddColumn.emit(column);
  }

  public removeColumnClick(column: ColumnTable): void { // todo: refactor
    this.clickRemoveColumn.emit(column);
  }

  public enableOrDisableClick(status: boolean, rowIndex: string) { // todo: refactor
    this.clickenableOrDisable.emit({ status, rowIndex });
  }

  public toggleExpandRow(event) {
    const row = event.row;
    const column = event.column;
    if (row.hideRowActions && column.clicktype && row.hideRowActions[column.clicktype]) {
      return;
    }
    this.table.rowDetail.collapseAllRows();

    this.activatedRoute.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.removeExpandedAttr());

    if (this.lastClickedRow === null || this.lastClickedRow !== row) {
      this.expanded = true;
      this.table.rowDetail.toggleExpandRow(row);
      // add attr expanded for datatable-row-wrapper, which use for style
      event.rowElement.parentElement.setAttribute('expanded', 'true');
      this.outputClickEvent(event);
    }

    if (this.lastClickedRow === row) {
      this.closingInnerTable.emit();
    }

    this.lastClickedRow = this.lastClickedRow === row ? null : row;
  }

  private removeExpandedAttr() {
    this.table.element.querySelectorAll('datatable-row-wrapper')
      .forEach(el => el.removeAttribute('expanded'));
  }

  public collapseAll(): void { // do not remove it as unused. Method is for to collapse rows from outer components
    this.expanded = false;
    this.table.rowDetail.collapseAllRows();
    this.removeExpandedAttr();
  }

  // do not remove it as unused
  public recalculate(resetPositions = true): void {
    if (resetPositions) {
      this.table.offset = 1;
      this.table.bodyComponent.offsetX = 1;
      this.table.bodyComponent.offsetY = 1;
      this.table.headerComponent.offsetX = 1;
      this.table.columnTemplates.setDirty();
    }
    this.table.recalculate();
  }

  // for context menu
  public onTableContextMenu(event) {
    const menuWidth = this.defaultMenuWidth;
    const amountItems = this.contextmenuItems.length;
    const itemHeight = this.menuItemHeight;
    const totalIndentation = this.totalIndentation;
    const menuHeight = amountItems * itemHeight + totalIndentation;
    const pageScrollbarWidth = this.pageScrollbarWidth;
    const { pageX, pageY } = event.event;
    const { innerWidth, innerHeight } = event.event.view;

    const isMaxPageX: boolean = pageX + menuWidth > innerWidth - pageScrollbarWidth;
    const isMaxPageY: boolean = pageY + menuHeight > innerHeight - pageScrollbarWidth;
    const positionX = isMaxPageX ? pageX - menuWidth : pageX;
    const positionY = isMaxPageY ? pageY - menuHeight : pageY;

    if (event.type === 'body') {
      this.contextmenuX = positionX;
      this.contextmenuY = positionY;
      this.selectedRow = event.content;
      this.contextmenu = true;
      event.event.preventDefault();
      event.event.stopPropagation();
    } else {
      this.contextmenu = false;
    }
  }

  // output methods the context menu items
  public viewHandler(): void { // todo: refactor
    this.view.emit(this.selectedRow);
  }

  public deleteHandler(): void { // todo: refactor
    this.deleteItem.emit(this.selectedRow);
  }

  public moveToArchiveHandler(): void { // todo: refactor
    this.moveToArchive.emit(this.selectedRow);
  }

  public sendEmailHandler(): void { // todo: refactor
    this.sendEmail.emit(this.selectedRow);
  }

  public addToBookmarksHandler(): void { // todo: refactor
    this.addToBookmarks.emit(this.selectedRow);
  }

  public setPage(pageInfo: { offset: number }): void {
    this.page.emit(pageInfo.offset);
  }

  public clickArrowTextReceiver(e) {
    this.clickText.emit(e);
  }

  public getRowOrderNumber(index: number): string | number {
    if (this.pagination.page) {
      const number = index + (this.pagination.page - 1) * this.pagination.per_page + 1;
      const isZeroRequired = this.pagination.page === 1 && index < 9;
      return isZeroRequired ? '0' + number : number;
    } else {
      return index + 1 < 10 ? '0' + (index + 1) : index + 1;
    }
  }

  public getPositionsIndex(): Array<PositionsIndexModel> {
    if (!!this.rows.length) {
      return this.rows.map((item, index) => {
        return {
          index,
          title: this.getRowOrderNumber(index)
        };
      });
    }
  }

  public errorsMouseEnter(errors: string[]|string, iconId: string, containerId: string): void {
    if (!errors || !errors.length) { return; }

    const iconEl = this.document.getElementById(iconId);
    const iconRect = iconEl.getBoundingClientRect();
    const containerEl = this.document.getElementById(containerId);

    containerEl.style.left = iconRect.left + 'px';
    containerEl.style.top = iconRect.top + 'px';
    containerEl.style.bottom = null;

    const containerRect = containerEl.getBoundingClientRect();

    if (containerRect.top + containerRect.height > this.document.body.getBoundingClientRect().height) {
      containerEl.style.top = null;
      containerEl.style.bottom = '50px';
      if (containerRect.left < iconRect.left + iconRect.width) {
        containerEl.style.left = iconRect.left + 20 + 'px';
      }
    }

    this.document.getElementsByTagName('body')[0].appendChild(containerEl);

    containerEl.classList.remove('hidden');
  }

  public errorsMouseLeave(errors: string[], iconId: string, containerId: string): void {
    if (!errors || !errors.length) { return; }

    const containerEl = this.document.getElementById(containerId);
    const iconEl = this.document.getElementById(iconId);
    containerEl.classList.add('hidden');
    iconEl.parentElement.appendChild(containerEl);
  }

  public errorsActionMouseEnter(errors: string[]|string, iconId: string, containerId: string): void {
    if (!errors || !errors.length) { return; }

    const iconEl = this.document.getElementById(iconId);
    const iconRect = iconEl.getBoundingClientRect();
    const containerEl = this.document.getElementById(containerId);
    containerEl.style.left = (iconRect.left - containerEl.getBoundingClientRect().width - 20) + 'px';
    containerEl.style.top = (iconRect.top - 52) + 'px';

    this.document.getElementsByTagName('body')[0].appendChild(containerEl);

    containerEl.classList.remove('hidden');
  }

  public errorsActionMouseLeave(errors: string[], iconId: string, containerId: string): void {
    if (!errors || !errors.length) { return; }

    const containerEl = this.document.getElementById(containerId);
    containerEl.classList.add('hidden');
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
