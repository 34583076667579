import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { get, isEmpty } from 'lodash';
import { Store } from '@ngrx/store';
import { catchError, filter, map, takeUntil } from 'rxjs/operators';

import { selectCompanyProfile, selectLegalTypes, selectValidations } from 'projects/workspace/src/app/administration/store/selectors';
import { CompanyProfile, LegalType } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { AdministrationsApiService } from 'projects/workspace/src/app/administration/services/administrations-api.service';
import { ValidationService } from 'projects/workspace/src/app/shared/services/validation.service';
import { ResponseModel, Validation } from 'projects/workspace/src/app/shared/models/response';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { CountryModel } from '../../../rnpl-common';
import { SystemSettingsService } from '../../../system-settings';
import { InitialSetupBaseComponent } from '../initial-setup-base/initial-setup-base.component';

@Component({
  selector: 'rnpl-company-details',
  templateUrl: './company-details.component.html'
})
export class CompanyDetailsComponent extends InitialSetupBaseComponent implements OnInit, OnDestroy {

  public legalTypeItems$: Observable<LegalType[]> = this.store.select(selectLegalTypes)
    .pipe(map((items: LegalType[]) => items.filter(itm => itm.type !== 'Einzelunternehmen')));
  public validations$: Observable<Validation[]> = this.store.select(selectValidations);

  public haveVatNumber: boolean;
  public countries: CountryModel[];
  public form: FormGroup = new FormGroup({});

  constructor(
    public readonly store: Store<AppState>,
    private readonly fb: FormBuilder,
    public router: Router,
    private readonly cdr: ChangeDetectorRef,
    public validationService: ValidationService,
    private readonly systemSettingsService: SystemSettingsService,
    public readonly administrationsApiService: AdministrationsApiService,
  ) {
    super(store, router, administrationsApiService);
  }

  ngOnInit() {
    this.getAvailableCountries();
    this.initForm();

    this.store.select(selectCompanyProfile)
      .pipe(
        filter(cp => !!cp && !isEmpty(cp)),
        takeUntil(this.destroy$)
      )
      .subscribe((companyProfile: CompanyProfile) => {
        this.haveVatNumber = !companyProfile.noVat;
        this.form.patchValue(companyProfile, {emitEvent: false});
        this.updateNoVatState(this.noVat.value);
        this.administrationsApiService.getLegalTypes(companyProfile.legalAddress.country_iso3).subscribe();
        this.updateCountryState();
        this.updateValidators();
      });

    // this.bindLegalTypeChanges();

    this.validations$
      .pipe(takeUntil(this.destroy$))
      .subscribe(validations => {
        this.validationService.renderServerErrors(this.form, validations);
      });
  }

  private getAvailableCountries(): void {
    this.systemSettingsService.getAvailableCountries(1)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.countries = response.data;
      });
  }

  public initForm(companyProfile: CompanyProfile = {} as any): void {
    this.form = this.fb.group({
      companyName: [get(companyProfile, 'companyName'), {validators: [Validators.required], updateOn: 'blur'}],
      companyRegistrationCountry: [{value: get(companyProfile, 'legalAddress.country_iso3'), disabled: true}, {validators: [Validators.required]}],
      legalType: [get(companyProfile, 'legalType'), {validators: [Validators.required]}],
      registrationNumber: [get(companyProfile, 'registrationNumber'), {updateOn: 'blur'}],
      vatNumber: [get(companyProfile, 'vatNumber'), {validators:  [Validators.required, Validators.pattern(/^ATU[0-9]{8}$/)], updateOn: 'blur'}],
      noVat: [get(companyProfile, 'noVat'), {validators: []}], // [ ]
      hasCommercialRegistration: [get(companyProfile, 'hasCommercialRegistration'), {validators: []}], // [ ]
      firstName: [get(companyProfile, 'firstName'), {validators: [Validators.required], updateOn: 'blur'}],
      lastName: [get(companyProfile, 'lastName'), {validators: [Validators.required], updateOn: 'blur'}],
    });

    this.noVat.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.updateNoVatState(value);
        this.vatNumber.reset('', {emitEvent: false});
      });

    this.hasCommercialRegistration.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        if (value) {
          this.companyName.setValidators(Validators.required);
        } else {
          this.companyName.clearValidators();
        }
      });
  }

  public updateValidators(): void {
    if (this.isCompany) {
      this.registrationNumber.setValidators(Validators.required);
    } else {
      this.registrationNumber.clearValidators();
    }

    this.registrationNumber.updateValueAndValidity();
  }

  public haveVatChanged(): void {
    this.noVat.patchValue(!this.noVat.value);
  }

  public updateNoVatState(noVat: boolean): void {
    noVat
      ? this.vatNumber.disable({emitEvent: false})
      : this.vatNumber.enable({emitEvent: false});
  }

  // public bindLegalTypeChanges(): void {
  //   this.legalType.valueChanges
  //     .pipe(
  //       startWith(get(this.companyProfile, 'legalType')),
  //       takeUntil(this.destroy$)
  //     )
  //     .subscribe((legalType: LegalType) => {
  //       this.setLegalTypeValidations(legalType);
  //     });
  // }

  // public setLegalTypeValidations(legalType: LegalType): void {
  //   if (legalType) {
  //     // if (legalType.type === 'Einzelunternehmen' || legalType.type === 'GesbR') {
  //     //   this.registrationNumber.clearValidators();
  //     //   this.vatNumber.clearValidators();
  //     // } else {
  //     //   this.registrationNumber.setValidators(Validators.required);
  //     //   this.vatNumber.setValidators([Validators.required, Validators.pattern(/^ATU[0-9]{8}$/)]);
  //     // }
  //
  //     if (legalType.type === 'Einzelunternehmen') {
  //       this.firstName.setValidators(Validators.required);
  //       this.lastName.setValidators(Validators.required);
  //       this.companyName.clearValidators();
  //     } else {
  //       this.firstName.clearValidators();
  //       this.lastName.clearValidators();
  //       this.companyName.setValidators(Validators.required);
  //     }
  //
  //     if (legalType.type === 'Verein') {
  //       this.registrationNumber.setValidators(Validators.required);
  //       this.vatNumber.clearValidators();
  //     }
  //
  //     if (legalType.type === 'OG' || legalType.type === 'KG') {
  //       this.vatNumber.clearValidators();
  //     }
  //   }
  //
  //   this.firstName.updateValueAndValidity();
  //   this.lastName.updateValueAndValidity();
  //   this.companyName.updateValueAndValidity();
  //   this.registrationNumber.updateValueAndValidity();
  //   this.vatNumber.updateValueAndValidity();
  // }

  public updateCountryState(): void {
    const companyCountryISO3 = get(this.companyProfile, 'legalAddress.country_iso3');
    if (companyCountryISO3) {
      this.companyRegistrationCountry.setValue(companyCountryISO3, {emitEvent: false});
      this.companyRegistrationCountry.disable({emitEvent: false});
    } else {
      this.companyRegistrationCountry.enable({emitEvent: false});
    }
    this.cdr.detectChanges();
  }

  public getStepValidation(): boolean {
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    return this.form.valid;
  }

  public updateStep$(): Observable<boolean | CompanyProfile> {
    if (!this.getStepValidation) {
      return of(false);
    }
    const formValue = this.form.getRawValue();
    const company = {
      ...this.companyProfile,
      ...formValue,
      legalAddress: {
        ...formValue.legalAddress,
        country_iso3: (this.companyRegistrationCountry.value)
          ? this.companyRegistrationCountry.value
          : formValue.legalAddress.country_iso3
      }
    };

    return this.administrationsApiService.updateCompanyProfile(company)
      .pipe(
        catchError(error => {
          if (error.error.messages && error.error.messages.filter(err => err.name === 'vatNumber')) {
            this.form.get('vatNumber').setErrors({[error.error.messages[0].message]: true});
            this.form.updateValueAndValidity();
          }
          return throwError(error);
        }),
        map((data: ResponseModel<CompanyProfile>) => data.data)
      );
  }

  get isCompany(): boolean {
    return this.companyProfile && this.companyProfile.legalType.type !== 'Einzelunternehmen';
  }

  get companyName() { return this.form.get('companyName') as FormControl; }
  get firstName() { return this.form.get('firstName') as FormControl; }
  get lastName() { return this.form.get('lastName') as FormControl; }
  get companyRegistrationCountry() { return this.form.get('companyRegistrationCountry') as FormControl; }
  get legalType() { return this.form.get('legalType') as FormControl; }
  get registrationNumber() { return this.form.get('registrationNumber') as FormControl; }
  get vatNumber() { return this.form.get('vatNumber') as FormControl; }
  get noVat() { return this.form.get('noVat') as FormControl; }
  get hasCommercialRegistration() { return this.form.get('hasCommercialRegistration') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
