import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ContextMenuModule } from 'ngx-contextmenu';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { RnplCommonModule } from '../rnpl-common';
import { UIComponentsModule } from '../ui-components/ui-components.module';
import {
  FileViewerComponent,
  DocumentViewerComponent,
  PositionsTableComponent,
  EmptyPositionsComponent,
  DocumentViewerFooterComponent,
  DocumentViewerHeadComponent,
  DocumentViewerPositionsSummaryComponent,
  DocumentViewerHeadAddressLocationComponent
} from './components';
import { PositionCardModule } from '../position-card/position-card.module';
import { PositionsContextMenuModule } from 'projects/workspace/src/app/shared/components/positions-context-menu/positions-context-menu.module';
import { LogoModule } from '../ui-components/logo/logo.module';
import { SortingVatDetailPipeModule } from 'projects/workspace/src/app/shared/pipes/sorting-vat-detail-pipe/sorting-vat-detail-pipe.module';
import { NumberFormatPipeModule } from 'projects/workspace/src/app/shared/pipes/number-format-pipe/number-format-pipe.module';
import { TranslateInPipeModule } from 'projects/workspace/src/app/shared/pipes/translate-in.pipe.ts/translate-in-pipe.module';


@NgModule({
  declarations: [
    DocumentViewerComponent,
    PositionsTableComponent,
    EmptyPositionsComponent,
    DocumentViewerFooterComponent,
    DocumentViewerHeadComponent,
    DocumentViewerPositionsSummaryComponent,
    FileViewerComponent,
    DocumentViewerHeadAddressLocationComponent
  ],
  exports: [
    DocumentViewerComponent,
    PositionsTableComponent,
    EmptyPositionsComponent,
    FileViewerComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    RnplCommonModule,
    UIComponentsModule,
    PositionCardModule,
    ContextMenuModule,
    PositionsContextMenuModule,
    PerfectScrollbarModule,
    LogoModule,
    SortingVatDetailPipeModule,
    NumberFormatPipeModule,
    TranslateInPipeModule
  ]
})
export class DocumentViewerModule { }
