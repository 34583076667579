export enum DocumentTypesEnum {
  OIN = 'oin',
  IIN = 'iin',
  OCN = 'ocn',
  ICN = 'icn',
  ERA = 'era',
  PO = 'po',
  SO = 'so',
  CRN = 'CRN',
}

export enum DocumentTypesUppercaseEnum {
  OIN = 'OIN',
  IIN = 'IIN',
  OCN = 'OCN',
  ICN = 'ICN',
  PO = 'PO',
  SO = 'SO',
  CRN = 'CRN',
  ERA = 'ERA',
  RMA = 'RMA',
  OUTGOING_PAYMENT = 'OPB',
  INCOMING_PAYMENT = 'IPB',
  OPB = 'OPB',
  IPB = 'IPB',
  CP = 'CP',
  IPX = 'IPX',
  DN = 'DN',
  OFR = 'OFR',
  PARTNER = 'PARTNER',
  BA = 'BA',
  PMNT = 'PMNT',
  TTR = 'TTR',
  ECO = 'ECO',
  ECO_ORDER = 'eco_order',
  SBC = 'SBC',
  OUTGOING_INVOICE = 'outgoing_invoice',
  INCOMING_INVOICE = 'incoming_invoice',
  DELIVERY_NOTE = 'delivery_note',
  PRICE_SALES = 'price_sales',
  PRICE_SALES_ECO = 'price_sales_eco',
  PRICE_PURCHASE = 'price_purchase',
  OPEN_ITEMS = 'open_items',
  REPORTING_DOCUMENT = 'reporting_document',
  TRANSACTIONS = 'transactions',
  INCOMING_DELIVERY = 'incoming_delivery',
  IN = 'IN',
  STOCK_GENERAL = 'stock_general',
  STOCK_RETURN = 'stock_return',
  BIN_LOCATION = 'bin_location',
  PRODUCT = 'product',
  PRODUCT_RELATED = 'product_related',
  ECO_PRODUCT = 'eco_product',
  USER = 'user',
  PARTNER_PRIVATE = 'partner_private',
  PARTNER_CORPORATE = 'partner_corporate',
  ECO_PARTNER_PRIVATE = 'eco_partner_private',
  ECO_PARTNER_CORPORATE = 'eco_partner_corporate',
  OFFER = 'offer',
  TSO = 'TSO',
  SP = 'SP', // scheduled payment
  ST = 'ST', // stocktaking
  ST_PRODUCTS = 'st_products', // stocktaking products
  ST_TRANSACTIONS = 'st_transactions', // stocktaking products
  GL_TRANSACTIONS = 'gl_transactions',
  GL_TRANSACTIONS_PROFILE = 'gl_transactions_profile',
  PURCHASE_ORDER = 'purchase_order',
  CREDIT_NOTE = 'credit_note',
  SALES_ORDER = 'sales_order',
  FIXED_ASSETS = 'fixed_assets',
  GOODS_REGISTER = 'goods_register',
  CASHFLOW = 'cashflow',
  REPORTS = 'reports',
  COST_CENTER = 'cost_center',
}
