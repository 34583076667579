import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';

import { DynamicFormsModule, RnplCommonModule, UIComponentsModule } from 'common/src/modules';
import { CreateProductFormHardcodeComponent } from './create-product-form-hardcode.component';
import { CreateProductFormNameComponent } from './create-product-form-name.component';
import { ProductFormHardcodeModule } from '../product-form-hardcore.module';
import { CreateProductFormBarcodeComponent } from './create-product-form-barcode.component';
import { CreateProductFormPreferencesComponent } from './create-product-form-preferences.component';
import { CreateProductFormPriceComponent } from './create-product-form-price.component';
import { NgxCurrencyModule } from 'ngx-currency-custom';
import { CreateProductFormAttributesComponent } from './create-product-form-attributes.component';

const COMPONENTS = [
  CreateProductFormHardcodeComponent,
  CreateProductFormNameComponent,
  CreateProductFormBarcodeComponent,
  CreateProductFormPreferencesComponent,
  CreateProductFormPriceComponent,
  CreateProductFormAttributesComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    UIComponentsModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    DynamicFormsModule,
    RnplCommonModule,
    TranslateModule,
    ProductFormHardcodeModule,
    NgxCurrencyModule
  ],
  exports: [
    ...COMPONENTS
  ],
  providers: []
})
export class CreateProductFormHardcodeModule {

}
