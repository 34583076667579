import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import { AuthGuard } from 'common/src/auth/auth.guard';
import { SignInPageComponent, AuthKeyPageComponent } from 'common/src/pages';
import { IsNotAuthGuard } from 'common/src/auth/is-not-auth.guard';
import {
  FreePlanLimitationGuard,
  PermissionAccountingGuard,
  PermissionAdministrationGuard,
  PermissionEcommerceGuard,
  PermissionPartnersGuard,
  PermissionProductsGuard,
  PermissionServicesGuard,
  PermissionTeamGuard,
  PermissionWarehouseGuard,
  SubscriptionActivatedGuard
} from 'common/src/guards';
import {ChangePasswordPageComponent} from 'common/src/pages/change-password-page/change-password-page.component';
import { InitialSetupGuard } from 'common/src/modules/initial-setup/initial-setup.guard';
import { InitialSetupPageComponent } from 'common/src/modules/initial-setup/components';
import { InitialSetupCompletedGuard } from 'common/src/modules/initial-setup/initial-setup-completed.guard';
import { AccountingSettingsGuard } from './accounting/guards/accounting-settings.guard';
import { GeneralSettingsGuard } from './accounting/guards/general-settings.guard';
import { ShowControlCenterGuard } from './control-center/show-control-center.guard';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload',
  scrollOffset: [1000, 96],
};


const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard, InitialSetupGuard, GeneralSettingsGuard],
    pathMatch: 'full',
    redirectTo: 'start'
  },
  {
    path: 'sign-in',
    canActivate: [IsNotAuthGuard],
    component: SignInPageComponent,
    data: { animation: 'signInRouteAnimation' }
  },
  {
    path: 'user/reset-pass',
    canActivate: [IsNotAuthGuard],
    component: ChangePasswordPageComponent
  },
  {
    path: 'auth-key',
    component: AuthKeyPageComponent
  },
  {
    path: 'user/new-email/confirm',
    loadChildren: () => import('./public/new-email-confirm/new-email-confirm.module').then(m => m.NewEmailConfirmModule),
    data: {
      hideHeader: true
    }
  },
  {
    path: 'no-access',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () => import('./no-access/no-access.module').then(m => m.NoAccessModule),
    data: {
      title: 'No access'
    }
  },
  {
    path: 'initial-setup',
    // canActivate: [InitialSetupGuard],
    canActivate: [AuthGuard, InitialSetupCompletedGuard],
    component: InitialSetupPageComponent,
    data: {
      isInitialSetup: true
    }
  },
  {
    path: 'products',
    canLoad: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard, PermissionProductsGuard],
    canActivate: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard, PermissionProductsGuard],
    loadChildren: () => import('./products-two-level/products-two-level.module').then(m => m.ProductsTwoLevelModule),
    data: {
      title: 'Products'
    }
  },
  {
    path: 'partners-new',
    canLoad: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard, PermissionPartnersGuard],
    canActivate: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard, PermissionPartnersGuard],
    loadChildren: () => import('./partners/partners.module').then(m => m.PartnersModule),
    data: {
      title: 'Partners'
    }
  },
  {
    path: 'entities-management',
    canLoad: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard],
    canActivate: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard],
    loadChildren: () => import('common/src/modules/entities-management/entities-management.module').then(m => m.EntitiesManagementModule),
    data: {
      title: 'Entities management'
    }
  },
  {
    path: 'administration',
    canLoad: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard, PermissionAdministrationGuard],
    canActivate: [AuthGuard, InitialSetupGuard, AccountingSettingsGuard, SubscriptionActivatedGuard, PermissionAdministrationGuard],
    loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule),
    data: {
      title: 'Administration'
    }
  },
  {
    path: 'team',
    canLoad: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard, PermissionTeamGuard],
    canActivate: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard, PermissionTeamGuard],
    loadChildren: () => import('./hrm/hrm.module').then(m => m.HrmModule),
    data: {
      title: 'Team'
    }
  },
  {
    path: 'warehouse',
    canLoad: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard, PermissionWarehouseGuard],
    canActivate: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard, PermissionWarehouseGuard],
    loadChildren: () => import('./warehouse/warehouse.module').then(m => m.WarehouseModule),
    data: {
      title: 'Warehouse'
    }
  },
  {
    path: 'company-registration',
    canLoad: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard],
    canActivate: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard],
    loadChildren: () => import('./company-sign-up/company-sign-up.module').then(m => m.CompanySignUpModule),
    data: {
      title: 'Company registration'
    }
  },
  {
    path: 'trade',
    canLoad: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard],
    canActivate: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard, AccountingSettingsGuard, GeneralSettingsGuard],
    loadChildren: () => import('./trade/trade.module').then(m => m.TradeModule),
    data: {
      title: 'Trade'
    }
  },
  {
    path: 'trade-offer',
    loadChildren: () => import('./trade-offer/trade-offer-confirmation/trade-offer-confirmation.module')
      .then(m => m.TradeOfferConfirmationModule),
    data: {
      hideHeader: true
    }
  },
  {
    path: 'accounting',
    canLoad: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard, PermissionAccountingGuard],
    canActivate: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard, PermissionAccountingGuard, AccountingSettingsGuard, GeneralSettingsGuard],
    loadChildren: () => import('./accounting/accounting.module').then(m => m.AccountingModule),
  },
  {
    path: 'services',
    canLoad: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard, PermissionServicesGuard],
    canActivate: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard, PermissionServicesGuard, AccountingSettingsGuard],
    loadChildren: () => import('./services/services.module').then(m => m.ServicesModule),
  },
  {
    path: 'e-commerce',
    canLoad: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard, PermissionEcommerceGuard],
    canActivate: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard, PermissionEcommerceGuard, AccountingSettingsGuard],
    loadChildren: () => import('./e-commerce/e-commerce.module').then(m => m.ECommerceModule),
    data: {
      title: 'E-commerce',
      darkBg: true,
      hasBottomNavigation: true,
    }
  },
  {
    path: 'start',
    canLoad: [AuthGuard, InitialSetupGuard],
    canActivate: [AuthGuard, InitialSetupGuard, AccountingSettingsGuard],
    loadChildren: () => import('./home-page/home-page.module').then(m => m.HomePageModule),
    data: {
      title: 'Start',
      hideHeader: true,
      animation: 'StartScreenRouteAnimation'
    }
  },
  {
    path: 'analytics',
    canLoad: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard],
    canActivate: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard],
    loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule),
  },
  {
    path: 'control-center',
    canLoad: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard, ShowControlCenterGuard],
    canActivate: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard, ShowControlCenterGuard, AccountingSettingsGuard],
    loadChildren: () => import('./control-center/control-center.module').then(m => m.ControlCenterModule),
  },
  {
    path: 'data-import',
    canLoad: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard],
    canActivate: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard],
    loadChildren: () => import('./data-import/data-import.module').then(m => m.DataImportModule),
  },
  {
    path: 'pdf-designer',
    canLoad: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard],
    canActivate: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard, FreePlanLimitationGuard],
    loadChildren: () => import('./pdf-settings/pdf-settings.module').then(m => m.PdfSettingsModule),
  },
  // {
  //   path: 'retail',
  //   canLoad: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard],
  //   canActivate: [AuthGuard, InitialSetupGuard, SubscriptionActivatedGuard, AccountingSettingsGuard],
  //   loadChildren: () => import('./retail/retail.module').then(m => m.RetailModule),
  //   data: {
  //     title: 'Retail',
  //     hideHeader: true,
  //     darkBg: true,
  //     hasBottomNavigation: true,
  //   }
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
