import { Component, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

import { CURRENT_VERSION, CURRENT_VERSION_NAME } from 'projects/workspace/src/app/version.constant';
import { AuthUserModel } from 'common/src/auth/auth-user.model';
import { AuthService } from 'common/src/auth/auth.service';
import { UserPermissionsService } from 'common/src/services/user-permissions.service';

@Component({
  selector: 'rnpl-side-navigation-profile-menu',
  templateUrl: './side-navigation-profile-menu.component.html',
  styleUrls: ['./side-navigation-profile-menu.component.scss']
})
export class SideNavigationProfileMenuComponent implements OnInit, OnDestroy {
  private documentElement: any;
  public isFullScreen: boolean;
  public isMobile: boolean;
  public user: AuthUserModel = null;
  public headLogo: string = 'assets/img/runple_logo.png';
  public currentVersion: string = CURRENT_VERSION;
  public currentVersionName: string = CURRENT_VERSION_NAME;

  @Input() public openedSideMenu: boolean = false;
  @Output() public toggleThemeClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() public isProfileMenuOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    public authService: AuthService,
    public userPermissionsService: UserPermissionsService,
    private deviceService: DeviceDetectorService,
    @Inject(DOCUMENT) private document: any
  ) {
    this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit() {
    this.authService.loggedUser$
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => this.user = user);

    this.chkScreenMode();
    this.documentElement = this.document.documentElement;
  }

  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])
  fullscreenModes(event){
    this.chkScreenMode();
  }

  public chkScreenMode(): void {
    // this.isFullScreen = !!document.fullscreenElement || !!document.webkitIsFullScreen;
    this.isFullScreen = !!this.document.fullscreenElement;
  }

  public openFullscreen(): void {
    if (this.documentElement.requestFullscreen) {
      this.documentElement.requestFullscreen();
    } else if (this.documentElement.mozRequestFullScreen) {
      /* Firefox */
      this.documentElement.mozRequestFullScreen();
    } else if (this.documentElement.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.documentElement.webkitRequestFullscreen();
    } else if (this.documentElement.msRequestFullscreen) {
      /* IE/Edge */
      this.documentElement.msRequestFullscreen();
    }
  }

  public closeFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }

  public userSignOut(): void {
    this.authService.signOut();
  }

  public canViewAdministration(): boolean {
    return this.userPermissionsService.canViewAdministration();
  }

  public openHandbook(): void {
    window.open('https://docs.runple.com/', '_blank');
  }

  public toggleTheme(): void {
    this.toggleThemeClicked.emit();
  }

  public menuToggle(isOpen: boolean): void {
    this.isProfileMenuOpen.emit(isOpen);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
