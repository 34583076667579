import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { ProductTypes } from 'common/src/modules/products/product-types';
import { BaseFormComponent } from 'projects/workspace/src/app/crm/partner-forms/components/base-form.component';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { VAT_LIST } from 'projects/workspace/src/app/shared/constants';
import { TradePricesApiService } from 'projects/workspace/src/app/trade-prices/services/trade-prices-api.service';
import { MetricConversionPipe } from '../../../rnpl-common';


@Component({
  selector: 'rnpl-create-product-form-price',
  templateUrl: './create-product-form-price.component.html'
})
export class CreateProductFormPriceComponent extends BaseFormComponent implements OnInit, OnChanges, OnDestroy {

  public readonly vatList = VAT_LIST;
  public localCurrency: string = '€';
  public companyProfile: CompanyProfile;

  @Input() productType: ProductTypes;

  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private fb: FormBuilder,
    private tradePricesApiService: TradePricesApiService,
    private metricPipe: MetricConversionPipe,
    private readonly store: Store<AppState>,
  ) {
    super();

    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((companyProfile: CompanyProfile) => this.companyProfile = companyProfile);
  }

  ngOnInit(): void {
    this.initForm();
    this.updateFormEditingStatus();
  }

  public initForm(): void {
    this.form = this.fb.group({
      netPrice: [],
      vat: [20],
      grossPrice: [],
    });
  }

  public getFormData(): any {
    return {
      netPrice: this.toInteger(this.netPriceControl.value),
      vat: this.vatControl.value,
      grossPrice: this.toInteger(this.grossPriceControl.value)
    };
  }

  private toInteger(value: number | string): number {
    if (value === 0) { return value; }
    if (!value) { return null; }
    return +this.metricPipe.transform(+value, 'TO-INTEGER').toFixed(0);
  }

  private fillControlValueWithConversion(value: number | string): number {
    if (value === 0) { return value; }
    if (!value) { return null; }
    return this.metricPipe.transform(+value, 'TO-FRACTIONAL') || null;
  }

  public updateField(fieldName: string): void {
    const formData = this.getFormData();
    this.tradePricesApiService.calcPrice(fieldName, formData)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.vatControl.patchValue(res.vat);
        this.netPriceControl.patchValue(this.fillControlValueWithConversion(res.netPrice));
        this.grossPriceControl.patchValue(this.fillControlValueWithConversion(res.grossPrice));
      });
  }

  get netPriceControl(): FormControl { return this.form.get('netPrice') as FormControl; }
  get vatControl(): FormControl { return this.form.get('vat') as FormControl; }
  get grossPriceControl(): FormControl { return this.form.get('grossPrice') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
