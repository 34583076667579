import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, interval, Observable, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { catchError, filter, finalize, startWith, switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

import { SetupStepsModel } from '../../setup-steps.model';
import { InitialSetupStepIdEnum } from '../../initial-setup.enum';
import { getSteps } from '../../initial-setup.config';
import { InitialSetupBaseComponent } from '../initial-setup-base/initial-setup-base.component';
import { AdministrationsApiService } from 'projects/workspace/src/app/administration/services/administrations-api.service';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { loadingRequestsCount, selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { BACKGROUND_CONTENT, TimeOfDay } from '../../../modals/modals-sign-in/sign-in.config';
import { ToasterService } from '../../../ui-components/toaster';
import { AccountingSettingsModel } from 'projects/workspace/src/app/accounting/accounting-settings-module/models';
import {
  AccountingSettingsService
} from 'projects/workspace/src/app/accounting/accounting-settings-module/services/accounting-settings.service';
import { SubscriptionPlanEnum } from '../../../subscription-activation/subscription-activation.emum';
import { isEmpty } from 'lodash';
import { HrmService } from '../../../../../../projects/workspace/src/app/hrm/hrm.service';
import { AuthService } from '../../../../auth/auth.service';

@Component({
  selector: 'rnpl-initial-setup',
  templateUrl: './initial-setup.component.html',
  styleUrls: ['./initial-setup.component.scss']
})
export class InitialSetupComponent extends InitialSetupBaseComponent implements OnInit {

  public backGroundContent = BACKGROUND_CONTENT;
  public timeOfDay = TimeOfDay();
  public initialSetupStepIdEnum = InitialSetupStepIdEnum;
  public initialSetupSteps: SetupStepsModel[] = [];
  public setupErrors: HttpErrorResponse = null;
  public accountingSettings: AccountingSettingsModel = null;
  public accountingMethod: 'smallBusiness'|'cashAccounting'|'doubleEntry' = null;

  public currentStep$: BehaviorSubject<InitialSetupStepIdEnum> = new BehaviorSubject<InitialSetupStepIdEnum>(InitialSetupStepIdEnum.HELLO);
  readonly loadingRequestsCount$: Observable<number> = this.store.select(loadingRequestsCount);
  readonly submitRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  readonly updateRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  @ViewChild('companyDetails', {static: false}) stepCompanyDetails;
  @ViewChild('stepLegalInfo', {static: false}) stepLegalInfo;
  @ViewChild('stepContactInfo', {static: false}) stepContactInfo;
  @ViewChild('stepAccountingMethod', {static: false}) stepAccountingMethod;
  @ViewChild('stepAppFeatures', {static: false}) stepAppFeatures;
  @ViewChild('scrollbar', {static: false}) scrollbar: PerfectScrollbarComponent;

  constructor(
    public administrationsApiService: AdministrationsApiService,
    public router: Router,
    public toasterService: ToasterService,
    public readonly store: Store<AppState>,
    public readonly accountingSettingsService: AccountingSettingsService,
    public readonly hrmService: HrmService,
    public readonly authService: AuthService,
  ) {
    super(store, router, administrationsApiService);

    this.store.select(selectCompanyProfile)
      .pipe(
        filter(cp => !!cp && !isEmpty(cp)),
        takeUntil(this.destroy$)
      )
      .subscribe((companyProfile: CompanyProfile) => {
        this.companyProfile = companyProfile;
        this.initialSetupSteps = getSteps(companyProfile, this.currentStepInfo && this.currentStepInfo.step);
      });

    this.getAccountingSettings();
  }

  ngOnInit() {
    this.administrationsApiService.getCompanyProfile()
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  private getAccountingSettings (): void {
    this.accountingSettingsService.getAccountingSettings()
      .pipe(takeUntil(this.destroy$))
      .subscribe((settings: AccountingSettingsModel) => {
        this.accountingSettings = settings;
        // this.forwardSmallBusiness = settings.smallBusiness.forward;
        // this.forwardReportingType = settings.reportingSettings.forwardReportingType;
        // this.updateNoVatState(this.forwardSmallBusiness || this.noVat.value);
      });
  }

  public startInitialSetup(): void {
    this.currentStep$.next(InitialSetupStepIdEnum.COMPANY_TYPE);
  }

  public backToSetup(): void {
    const lastStep: InitialSetupStepIdEnum = this.initialSetupSteps[this.initialSetupSteps.length - 1].id;
    this.currentStep$.next(lastStep);
    this.initialSetupSteps = getSteps(this.companyProfile, this.currentStepInfo.step);
  }

  public changeStep(direction?: 'prev' | 'next'): void {
    if (this.updateRequest$.getValue()) { return; }

    let nextStepIndex: number;

    this.initialSetupSteps.map((step, index) => {
      if (this.currentStep === step.id && !!direction) {

        if (direction) {
          switch (direction) {

            case 'next':
              if (index !== this.initialSetupSteps.length - 1) {
                nextStepIndex = index + 1;
              } else {
                nextStepIndex = index;
              }
              break;

            case 'prev':
              if (index > 0) {
                nextStepIndex = index - 1;
              } else {
                nextStepIndex = 0;
              }
              break;
          }
        }
      }
    });

    if (direction === 'next') {
      if (!this.validateSteps()) {
        return;
      }
      this.updateRequest$.next(true);
      this.updateStep$()
        .pipe(finalize(() => this.updateRequest$.next(false)))
        .subscribe(() => {
          if (this.validateSteps()) { // additional check after response
            this.initialSetupSteps[nextStepIndex - 1].status = 'complete';
            this.initialSetupSteps = [...this.initialSetupSteps];
            this.currentStep$.next(this.initialSetupSteps[nextStepIndex].id);
          }
        });
    }

    if (direction === 'prev') {
      this.currentStep$.next(this.initialSetupSteps[nextStepIndex].id);
    }
  }

  public goToStep(clickedStep: SetupStepsModel, prevStep: SetupStepsModel): void {
    const currentStep: SetupStepsModel = this.initialSetupSteps.find(step => step.id === this.currentStep);

    // change step is not allowed
    if (clickedStep.id === currentStep.id) { return; }
    if (this.updateRequest$.getValue()) { return; }

    // go back
    if (clickedStep.step < currentStep.step) {
      this.currentStep$.next(clickedStep.id);
      return;
    }

    // go forward
    if (clickedStep.status === 'complete' || prevStep.status === 'complete') {
      if (!this.validateSteps()) {
        return;
      }
      this.updateRequest$.next(true);
      this.updateStep$()
        .pipe(finalize(() => this.updateRequest$.next(false)))
        .subscribe(() => {
          if (this.validateSteps()) { // additional check after response
            this.currentStep$.next(clickedStep.id);
          }
        });
    }
  }

  public companyTypeStepFinished(): void {
    this.currentStep$.next(InitialSetupStepIdEnum.COMPANY_DETAILS);
  }

  public validateSteps(): boolean {
    let valid = false;

    switch (this.currentStep) {
      case InitialSetupStepIdEnum.COMPANY_DETAILS:
        valid = this.stepCompanyDetails.getStepValidation();
        break;
      case InitialSetupStepIdEnum.LEGAL_INFO:
        valid = this.stepLegalInfo.getStepValidation();
        break;
      case InitialSetupStepIdEnum.CONTACT_INFO:
        valid = this.stepContactInfo.getStepValidation();
        break;
      case InitialSetupStepIdEnum.ACCOUNTING_METHOD:
        valid = this.stepAccountingMethod.getStepValidation();
        break;
      case InitialSetupStepIdEnum.APP_FEATURES:
        valid = this.stepAppFeatures.getStepValidation();
        break;
    }

    if (!valid) {
      this.toasterService.notify({type: 'error', message: 'INITIAL_SETUP.PROVIDE_REQUIRED_INFO'});
    }

    return valid;
  }

  public updateStep$(): Observable<boolean | CompanyProfile> {
    let request$;

    switch (this.currentStep) {
      case InitialSetupStepIdEnum.COMPANY_DETAILS:
        request$ = this.stepCompanyDetails.updateStep$();
        break;

      case InitialSetupStepIdEnum.LEGAL_INFO:
        request$ = this.stepLegalInfo.updateStep$();
        break;
      case InitialSetupStepIdEnum.CONTACT_INFO:
        request$ = this.stepContactInfo.updateStep$();
        break;
      case InitialSetupStepIdEnum.ACCOUNTING_METHOD:
        request$ = this.stepAccountingMethod.updateStep$();
        break;
      case InitialSetupStepIdEnum.APP_FEATURES:
        request$ = this.stepAppFeatures.updateStep$();
        break;
    }

    return request$;
  }

  public updateAccountingSettings$(): Observable<any> {
    const subscriptionPlan = this.companyProfile.subscriptionManagement.subscriptionPlanActive;
    let reportingType: 'STANDARD'|'SIMPLIFIED';
    let smallBusiness: boolean;

    if (subscriptionPlan === SubscriptionPlanEnum.TEAM_MONTHLY || subscriptionPlan === SubscriptionPlanEnum.ACCOUNTING_MONTHLY) {
      if (!this.companyProfile.legalType.simplifiedReportingAllowed) {
        reportingType = 'STANDARD';
        smallBusiness = false;
      }

      if (this.companyProfile.legalType.simplifiedReportingAllowed) {
        if (this.accountingMethod === 'smallBusiness') {
          reportingType = 'SIMPLIFIED';
          smallBusiness = true;
        }
        if (this.accountingMethod === 'cashAccounting') {
          reportingType = 'SIMPLIFIED';
          smallBusiness = false;
        }
        if (this.accountingMethod === 'doubleEntry') {
          reportingType = 'STANDARD';
          smallBusiness = false;
        }
      }
    }

    const settings: AccountingSettingsModel = {
      ...this.accountingSettings,
      reportingSettings: {
        ...this.accountingSettings.reportingSettings,
        currentReportingType: reportingType,
        forwardReportingType: reportingType,
      },
      smallBusiness: {
        ...this.accountingSettings.smallBusiness,
        current: smallBusiness,
        forward: smallBusiness,
      }
    };

    return this.accountingSettingsService.updateAccountingSettings(settings);
  }

  public onStartClick(): void {
    if (this.submitRequest$.getValue()) { return; }
    this.submitRequest$.next(true);
    this.setupErrors = null;

    if (this.currentStep === InitialSetupStepIdEnum.CONTACT_INFO) {
      this.stepContactInfo.updateStep$()
        .pipe(takeUntil(this.destroy$))
        .subscribe(res => {
          if (res) {
            this.submitInitialSetup();
          } else {
            this.submitRequest$.next(false);
          }
        });
    } else if (this.currentStep === InitialSetupStepIdEnum.APP_FEATURES) {
      this.stepAppFeatures.updateStep$()
        .pipe(takeUntil(this.destroy$))
        .subscribe(res => {
          if (res) {
            this.submitInitialSetup();
          } else {
            this.submitRequest$.next(false);
          }
        });
    } else if (this.currentStep === InitialSetupStepIdEnum.ACCOUNTING_METHOD) {
      if (this.stepAccountingMethod.getStepValidation()) {
        this.submitInitialSetup();
      } else {
        this.submitRequest$.next(false);
      }
    } else {
      this.submitInitialSetup();
    }
  }

  public submitInitialSetup(): void {
    this.administrationsApiService.updateCompanyProfile(this.companyProfile)
      .pipe(
        tap(() => this.currentStep$.next(InitialSetupStepIdEnum.FINISHING)),
        switchMap(() => this.updateAccountingSettings$()),
        switchMap(() => this.hrmService.getUserProfileAndBlueprintsV2(this.authService.getUser().id, false, true)),
        switchMap(() => this.administrationsApiService.finishCompanyProfileSetup(
          this.companyProfile.workspaceId,
          false
        )),
        catchError((error) => {
          this.setupErrors = error;
          this.currentStep$.next(InitialSetupStepIdEnum.ERROR);
          this.submitRequest$.next(false);
          return throwError(error);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        interval(2000)
          .pipe(
            startWith(0),
            takeWhile(() => !this.companyProfile.tracking.completedAt),
            takeUntil(this.destroy$),
          )
          .subscribe(() => {
            this.administrationsApiService.getCompanyProfile()
              .pipe(
                catchError((error) => {
                  this.submitRequest$.next(false);
                  return throwError(error);
                }),
                tap((companyProfile: CompanyProfile) => {
                  if (companyProfile.tracking.completedAt && companyProfile.entityState === 'EXISTS') {
                    this.submitRequest$.next(false);
                  }
                })
              )
              .subscribe();
          });
      });
  }

  get currentStep(): InitialSetupStepIdEnum {
    return this.currentStep$.getValue();
  }

  get currentStepInfo(): SetupStepsModel {
    if (!this.initialSetupSteps && !this.initialSetupSteps.length) { return null; }
    return this.initialSetupSteps.find(s => s.id === this.currentStep);
  }

  get isRunbill(): boolean {
    return this.companyProfile
      && this.companyProfile.subscriptionManagement.subscriptionPlanActive === SubscriptionPlanEnum.ACCOUNTING_MONTHLY;
  }

  public get showSetupFlow(): boolean {
    return this.currentStep === InitialSetupStepIdEnum.COMPANY_DETAILS
      || this.currentStep === InitialSetupStepIdEnum.LEGAL_INFO
      || this.currentStep === InitialSetupStepIdEnum.CONTACT_INFO
      || this.currentStep === InitialSetupStepIdEnum.ACCOUNTING_METHOD
      || this.currentStep === InitialSetupStepIdEnum.APP_FEATURES;
  }

}
