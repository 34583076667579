import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { get } from 'lodash';

import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';

@Injectable({ providedIn: 'root' })
export class SystemPreferencesPermissionsService {

  private companyProfile: CompanyProfile;

  constructor(private readonly store: Store<AppState>) {
    this.store.select(selectCompanyProfile).subscribe(companyProfile => {
      this.companyProfile = companyProfile;
    });
  }

  public wholesaleEnabled(): boolean {
    return get(this.companyProfile, 'subscriptionManagement.channelOptions.wholesalesEnabled');
  }

  public ecommerceEnabled(): boolean {
    return get(this.companyProfile, 'subscriptionManagement.channelOptions.ecommerceEnabled');
  }

  public servicesEnabled(): boolean {
    return get(this.companyProfile, 'subscriptionManagement.channelOptions.servicesEnabled');
  }

  public corporatePartnersEnabled(): boolean {
    return get(this.companyProfile, 'partnerOptions.corporateEnabled');
  }

  public privatePartnersEnabled(): boolean {
    return get(this.companyProfile, 'partnerOptions.privateEnabled');
  }

  public goodsProductTypeEnabled(): boolean {
    return get(this.companyProfile, 'productOptions.goodsEnabled');
  }

  public servicesProductTypeEnabled(): boolean {
    return get(this.companyProfile, 'productOptions.servicesEnabled');
  }

  public digitalProductTypeEnabled(): boolean {
    return get(this.companyProfile, 'productOptions.digitalEnabled');
  }

}
