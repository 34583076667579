import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ReplaySubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import {
  SubscriptionActivationComponent
} from '../../../subscription-activation/subscription-activation/subscription-activation.component';
import { SubscriptionActivationTabEnum } from '../../../subscription-activation/subscription-activation.emum';
import { BACKGROUND_CONTENT, TimeOfDay } from '../../modals-sign-in/sign-in.config';
import { DeleteAccountModalComponent } from '../delete-account-modal/delete-account-modal.component';

@Component({
  selector: 'rnpl-activate-subscription-modal',
  templateUrl: './activate-subscription-modal.component.html',
  styleUrls: ['./activate-subscription-modal.component.scss']
})
export class ActivateSubscriptionModalComponent implements OnDestroy {
  public modalConfig = {
    ['workspaceLocked']: {
      title: 'SUBSCRIPTION_ACTIVATION.WORKSPACE_LOCKED_TITLE',
      msg: 'SUBSCRIPTION_ACTIVATION.WORKSPACE_LOCKED_MSG',
      img: 'locked.svg'
    },
    // ['paymentOverdue']: {
    //   title: 'SUBSCRIPTION_ACTIVATION.PAYMENT_OVERDUE_TITLE',
    //   msg: 'SUBSCRIPTION_ACTIVATION.PAYMENT_OVERDUE_MSG',
    //   img: 'overdue.svg'
    // },
    ['expiredTrial']: {
      title: 'SUBSCRIPTION_ACTIVATION.TRIAL_EXPIRED_TITLE',
      msg: 'SUBSCRIPTION_ACTIVATION.TRIAL_EXPIRED_MSG',
      img: 'trial_calendar.svg'
    },
  };

  public backGroundContent = BACKGROUND_CONTENT;
  public timeOfDay = TimeOfDay();

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    public dialogRef: MatDialogRef<ActivateSubscriptionModalComponent>,
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: {
      modalType: 'workspaceLocked'|'expiredTrial',
      isAdmin: boolean,
      isAccountant: boolean,
    }
  ) {

  }

  public activateSubscription(activeTab: SubscriptionActivationTabEnum = SubscriptionActivationTabEnum.SUBSCRIPTION_OPTIONS): void {
    const dialog = this.dialog.open(SubscriptionActivationComponent, {
      id: 'subscriptionActivationModal',
      panelClass: 'launchpad-modal',
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      hasBackdrop: false,
      disableClose: true,
      data: {
        activeTab,
      }
    });

    dialog.afterOpened().subscribe(() => this.dialogRef.close());
  }

  public deleteAccount(): void {
    this.dialog.open(DeleteAccountModalComponent, { disableClose: true });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
