import { SetupStepsModel } from './setup-steps.model';
import { InitialSetupStepIdEnum } from './initial-setup.enum';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { SubscriptionPlanEnum } from '../subscription-activation/subscription-activation.emum';

export function getSteps(companyProfile: CompanyProfile, currentStep: number): SetupStepsModel[] {
  const subscriptionPlan = companyProfile.subscriptionManagement.subscriptionPlanActive;

  const steps: SetupStepsModel[] = [
    {
      id: InitialSetupStepIdEnum.COMPANY_TYPE,
      step: 0,
      status: 'active',
      title: null,
      pageTitle: 'Do you run your business as sole entrepreneur \n or as a company?',
      pageDescription: '',
    },
    {
      id: InitialSetupStepIdEnum.COMPANY_DETAILS,
      step: 1,
      status: 'active',
      title: companyProfile.legalType.individualBusiness
        ? 'Provide business name'
        : 'Provide your company details',
      pageTitle: companyProfile.legalType.individualBusiness
        ? 'What is your business name?'
        : 'Please provide your company details',
      pageDescription: '',
    },
    {
      id: InitialSetupStepIdEnum.LEGAL_INFO,
      step: 2,
      status: 'active',
      title: 'Enter legal address',
      pageTitle: 'What is your legal address?',
      pageDescription: '',
    },
    {
      id: InitialSetupStepIdEnum.CONTACT_INFO,
      step: 3,
      status: 'active',
      title: 'Specify contact information',
      pageTitle: 'How can your customers contact you?',
      pageDescription: '',
    },
  ];

  if (companyProfile.legalType.individualBusiness) {
    steps.push({
      id: InitialSetupStepIdEnum.ACCOUNTING_METHOD,
      step: steps.length,
      status: 'active',
      title: 'Choose accounting method',
      pageTitle: 'What is your annual turnover?',
      pageDescription: 'We use this information to choose the right accounting method for you.',
    });

    steps.push({
      id: InitialSetupStepIdEnum.APP_FEATURES,
      step: steps.length,
      status: 'active',
      title: 'Select features',
      pageTitle: 'What features do you need?',
      pageDescription: 'Activated options define the set of available features. \n' +
        'You can manage it in administration later.',
    });
  }

  if (!companyProfile.legalType.individualBusiness && subscriptionPlan === SubscriptionPlanEnum.TEAM_MONTHLY) {
    steps.push({
      id: InitialSetupStepIdEnum.ACCOUNTING_METHOD,
      step: steps.length,
      status: 'active',
      title: 'Choose accounting method',
      pageTitle: 'What is your annual turnover?',
      pageDescription: 'We use this information to choose the right accounting method for you.',
    });
  }

  return steps
    .map(s => ({
      ...s,
      status: s.step < currentStep
        ? 'complete'
        : 'active'
    }));
}
