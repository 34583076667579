import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';

import { SideNavigationQuickActionsComponent } from './side-navigation-quick-actions.component';

@NgModule({
  declarations: [
    SideNavigationQuickActionsComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatMenuModule,
    // RnplCommonModule,
  ],
  exports: [SideNavigationQuickActionsComponent],
  entryComponents: [SideNavigationQuickActionsComponent]
})
export class SideNavigationQuickActionsModule { }
