import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';

import { DocumentTypesUppercaseEnum } from '../link-document-modal/enums/ducument-types.enum';
import { DocumentTextTemplatesApiService } from 'projects/workspace/src/app/shared/services';
import { DocumentTextTemplateModel } from 'projects/workspace/src/app/shared/models';
import { DocumentTextLocationEnum } from 'projects/workspace/src/app/shared/enums';
import { CommonModalsActionsEnum } from '../common-modals-actions.enum';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import { ToasterService } from '../../../ui-components/toaster';

@Component({
  selector: 'rnpl-save-pdf-text-template-modal',
  templateUrl: './save-pdf-text-template-modal.component.html',
})
export class SavePdfTextTemplateModalComponent {

  public nameControl: FormControl = new FormControl('', [Validators.required]);

  // readonly destroy$: Subject<void> = new Subject();
  readonly submitRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    private dialog: MatDialog,
    private toasterService: ToasterService,
    private documentTextTemplatesApiService: DocumentTextTemplatesApiService,
    public dialogRef: MatDialogRef<SavePdfTextTemplateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      templates: DocumentTextTemplateModel[];
      mode: DocumentTextLocationEnum;
      documentType: DocumentTypesUppercaseEnum;
      text: string;
      selectedTemplate?: DocumentTextTemplateModel;
      subject?: string;
    }
  ) {
    if (this.data.selectedTemplate) {
      this.nameControl.patchValue(this.data.selectedTemplate.title);
    }
  }

  public submit(confirmValidation = false): void {
    this.nameControl.markAsTouched();
    this.nameControl.updateValueAndValidity();
    if (this.nameControl.invalid) { return; }

    if (!confirmValidation && this.data.templates && this.data.templates.length) {
      if (this.data.templates.some(tpl => tpl.title.toLowerCase() === this.nameControl.value.trim().toLowerCase())) {
        this.dialog.open(InfoModalComponent,
          {
            data: {
              title: 'DOCUMENTS_TEMPLATES.SAME_NAME_ERROR_TITLE',
              message: 'DOCUMENTS_TEMPLATES.SAME_NAME_ERROR_MSG',
              confirmBtnText: 'BUTTON.APPLY'
            },
            disableClose: true
          }
        )
          .afterClosed().subscribe((res: CommonModalsActionsEnum) => {
            if (res === CommonModalsActionsEnum.CONFIRM) {
              this.submit(true);
            }
          });
        return;
      }
    }

    if (this.nameControl.value && !this.nameControl.value.trim()) {
      this.toasterService.notify({type: 'error', message: 'DOCUMENTS_TEMPLATES.ENTER_TEMPLATE_NAME'});
      return;
    }

    this.submitRequest$.next(true);

    this.documentTextTemplatesApiService.saveTextTemplate(
      this.data.documentType,
      this.data.mode,
      this.data.text,
      this.nameControl.value,
      this.data.subject
    )
      .pipe(finalize(() => this.submitRequest$.next(false)))
      .subscribe((res) => this.dialogRef.close(res));
  }

  // ngOnDestroy(): void {
  //   this.destroy$.next(null);
  //   this.destroy$.complete();
  // }

}

