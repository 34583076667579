import { Component, HostBinding, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'rnpl-ai-loader',
  templateUrl: './ai-loader.component.html',
  styleUrls: ['ai-loader.component.scss']
})
export class AiLoaderComponent {

  @Input() size: number = 160;

  @HostBinding('style')
  get height(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(`height: ${this.size - 8}px;`);
  }

  constructor(private sanitizer: DomSanitizer) {}

}
