import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

import { AuthUserModel } from 'common/src/auth/auth-user.model';
import { AuthService } from 'common/src/auth/auth.service';
import { selectUserQuickActions } from '../../../store/selectors/shared.selectors';
import { quickActionsConfig } from 'common/src/modules/modals/modals-control-center/shortcuts-modal/user-shortcuts.helper';
import { QuickActionsModalComponent } from 'common/src/modules/modals/quick-actions-modal/quick-actions-modal.component';
import { AppState } from '../../../store/state/app.state';
import { ActionButtonsService } from 'common/src/services/action-buttons.service';

@Component({
  selector: 'rnpl-side-navigation-quick-actions',
  templateUrl: './side-navigation-quick-actions.component.html',
  styleUrls: ['./side-navigation-quick-actions.component.scss']
})
export class SideNavigationQuickActionsComponent implements OnInit, OnDestroy {
  public user: AuthUserModel = null;
  public activatedQuickActions = []; // todo model

  @Input() public openedSideMenu: boolean = false;
  @Output() public isProfileMenuOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private readonly router: Router,
    public authService: AuthService,
    private readonly dialog: MatDialog,
    private readonly store: Store<AppState>,
    private readonly actionButtonService: ActionButtonsService,
  ) {
    this.store.select(selectUserQuickActions)
      .pipe(takeUntil(this.destroy$))
      .subscribe(shortcuts => {
        this.activatedQuickActions = shortcuts.activated.map(itm => quickActionsConfig[itm]);
      });
  }

  ngOnInit() {
    this.authService.loggedUser$
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => this.user = user);
  }

  public openShortcutsSettingsModal(): void {
    this.dialog.open(QuickActionsModalComponent, {
      minWidth: '700px',
      maxWidth: '700px',
      disableClose: true
    });
  }

  public actionHandler(action: string, routerLink: string = '', event: Event): void {
    event.stopPropagation();
    if (!!routerLink) {
      this.navigateToShortcutLink(routerLink);
      return;
    }

    this.actionButtonService.callAction(action);
  }

  public navigateToShortcutLink(routerLink: string): void {
    this.router.navigate([routerLink]);
  }

  public menuToggle(isOpen: boolean): void {
    this.isProfileMenuOpen.emit(isOpen);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
