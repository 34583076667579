import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ReplaySubject } from 'rxjs';

import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { UserProfileModel } from 'projects/workspace/src/app/hrm/models';
import { selectLoggedUser } from 'projects/workspace/src/app/hrm/store/selectors';
import { OverdueNoticeTypeEnum } from 'projects/workspace/src/app/accounting/accounting-settings-module/enums';
import { OverdueNoticeItemModel } from 'projects/workspace/src/app/accounting/accounting-settings-module/models';
import {
  AccountingSettingsService
} from 'projects/workspace/src/app/accounting/accounting-settings-module/services/accounting-settings.service';

@Component({
  selector: 'rnpl-accounting-settings-send-email-modal',
  templateUrl: './accounting-settings-send-email-modal.component.html'
})
export class AccountingSettingsSendEmailModalComponent implements OnDestroy {

  public emails: string = null;
  public emailEmptyValidation: boolean = false;

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    public store: Store<AppState>,
    public dialogRef: MatDialogRef<AccountingSettingsSendEmailModalComponent>,
    public accountingSettingsService: AccountingSettingsService,
    @Inject(MAT_DIALOG_DATA) public data: {
      overdueNoticeType: OverdueNoticeTypeEnum,
      overdueNotice: OverdueNoticeItemModel
    }
  ) {
    this.store.select(selectLoggedUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: UserProfileModel) => this.emails = user.profile.email);
  }

  public sendEmail(): void {
    if (!this.emails) {
      this.emailEmptyValidation = true;
      return;
    }

    const emails = this.emails.split(',');

    this.accountingSettingsService.sendOverdueNoticeTestEmail(emails, this.data.overdueNotice, this.data.overdueNoticeType)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.dialogRef.close());
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}

