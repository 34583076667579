import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { finalize, takeUntil } from 'rxjs/operators';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { IncomingInvoiceApiService } from 'projects/workspace/src/app/incoming-invoice/services/invoice-api.service';
import {
  FileValidationDocumentsModel,
  IinValidateFilesResponseModel
} from 'projects/workspace/src/app/incoming-invoice/models';
import { CommonModalsActionsEnum, WarningModalComponent } from '../../modals-common';
import { FILE_ICON } from 'projects/workspace/src/app/shared/constants';

@Component({
  selector: 'rnpl-create-iin-modal',
  templateUrl: './create-iin-modal.component.html',
  styleUrls: ['./create-iin-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateIinModalComponent implements OnDestroy {

  public selectedFilesList: File[] = [];
  public filesValidations: IinValidateFilesResponseModel[] = [];

  public fileIcon = FILE_ICON;

  public maxFileSize: number = 10000000;
  public submitClicked: boolean = false;
  public matchDocument: boolean = true;
  public createPositions: boolean = true;
  public separatePositions: boolean = false;

  @ViewChild('UploadFileInput', {static: true}) public uploadFileInputEl: ElementRef;

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  readonly uploadingProcessing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly submitRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private incomingInvoiceApiService: IncomingInvoiceApiService,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<CreateIinModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      // todo
    }
  ) {
  }

  public uploadFile(filesList: FileList): void {
    if (!filesList || !filesList.length) { return; }

    for (let i = 0; i < filesList.length; i++) {
      this.selectedFilesList = [...this.selectedFilesList, filesList[i]];
    }

    this.selectedFilesList = this.selectedFilesList
      .filter(function (file: File, index: number, array: File[]) {
        const { size, name } = file;
        return array.findIndex(itm => (itm.size === size) && (itm.name === name)) === index;
      })
      .filter((file: File) => {
        return file.type === 'application/pdf'
          || file.type === 'image/png'
          || file.type === 'image/jpg'
          || file.type === 'image/jpeg';
      });

    this.uploadFileInputEl.nativeElement.value = null;
    this.validateAlreadyAttachedFiles();
  }

  public validateAlreadyAttachedFiles(): void {
    this.incomingInvoiceApiService.validateInvoicesFiles(this.selectedFilesList)
      .pipe(takeUntil(this.destroy$))
      .subscribe((filesValidations: IinValidateFilesResponseModel[]) => {
        this.filesValidations = filesValidations;
        this.cdr.detectChanges();
      });
  }

  public getFileValidationDocuments(fileName: string): FileValidationDocumentsModel[] {
    if (!this.filesValidations || !this.filesValidations.length) { return null; }

    const documents: FileValidationDocumentsModel[] = this.filesValidations.find(f => f.fileName === fileName).documents;
    return documents && documents.length
      ? documents
      : null;
  }

  public openIIN(document: FileValidationDocumentsModel): void {
    const url = this.router.serializeUrl(this.router.createUrlTree([`/accounting/incoming-invoice/${document.documentId}`]));
    window.open(url, '_blank');
  }

  public alreadyAppliedWarning(): void {
    this.submitRequest$.next(false);
    const dialog = this.dialog.open(WarningModalComponent, {
      data: {
        title: 'OCR.UPLOAD_INVOICES',
        message: 'OCR.UPLOAD_INVOICES_MSG',
        confirmBtnText: 'BUTTON.CONTINUE',
        confirmBtnIcon: 'arrow-right'
      }
    });

    dialog.afterClosed().subscribe(res => {
      if (res === CommonModalsActionsEnum.CONFIRM) {
        this.submit(true);
      }
    });
  }

  public submit(confirmed = false): void {
    this.submitClicked = true;

    if (!confirmed && this.filesValidations && this.filesValidations.some(f => f.documents && !!f.documents.length)) {
      this.alreadyAppliedWarning();
      return;
    }

    if (this.submitRequest$.getValue() || !this.validFilesCount) {
      return;
    }

    this.submitRequest$.next(true);

    this.toasterService.notifyRequestMessage({key: 'uploadingFilesProcessing', message: 'OCR.UPLOADING_INVOICES'});

    this.incomingInvoiceApiService.uploadMultipleInvoices(
      this.getValidFiles(),
      this.matchDocument,
      this.createPositions,
      this.separatePositions,
      confirmed
    )
      .pipe(
        finalize(() => {
          this.submitRequest$.next(false);
          this.toasterService.hideRequestMessage('uploadingFilesProcessing');
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((response: {name: string; success: boolean}[]) => {
        const fullQuantity = response.length;
        const successQuantity = response.filter(itm => itm.success).length;
        const message = fullQuantity === successQuantity
          ? this.translateService.instant('OCR.MULTIPLE_UPLOAD_FULL_SUCCESS', {fullQuantity})
          // todo: add EN translate for MULTIPLE_UPLOAD_SUCCESS
          : this.translateService.instant('OCR.MULTIPLE_UPLOAD_SUCCESS', {fullQuantity, successQuantity});
        this.toasterService.notify({type: 'success', message});
        // todo
        // this.close(true);
        this.dialogRef.close();
        // this.router.navigate([`/accounting/incoming-invoice/${data.id}`]);
      });
  }

  public getValidFiles(): File[] {
    return this.selectedFilesList.filter((file: File) => file.size < this.maxFileSize);
  }

  public removeFile(fileIndex: number): void {
    this.selectedFilesList.splice(fileIndex, 1);
    this.submitClicked = false;
  }

  public isFilePdf(file: File): boolean {
    return file.type === 'application/pdf';
  }

  public ifFileImg(file: File): boolean {
    return file.type === 'image/png'
      || file.type === 'image/jpg'
      || file.type === 'image/jpeg';
  }

  get validFilesCount(): number {
    return this.getValidFiles().length;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
