import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { SafePipeModule } from 'safe-pipe';

import { LanguageControlComponent } from './language-control.component';
import { RnplCommonModule, UIComponentsModule } from 'common/src/modules';

@NgModule({
  declarations: [
    LanguageControlComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    // RnplCommonModule,
    // UIComponentsModule,
    TranslateModule,
  ],
  exports: [
    LanguageControlComponent,
  ],
  entryComponents: [LanguageControlComponent],
})
export class LanguageControlModule { }
