import { ProductModel } from '../../products';
import { ProductTypes } from '../../products/product-types';
import { TranslateService } from '@ngx-translate/core';

export const CustomSearchFn = (term: string, item: any): boolean => {
  term = term.toLowerCase();

  const splitTerm = term.split(' ').filter(t => t);

  const isWordThere = [];

  splitTerm.forEach(arrTerm => {
    const search = item['searchLabel'].toLowerCase();
    isWordThere.push(search.indexOf(arrTerm) !== -1);
  });

  const allWords = (thisWord) => thisWord;

  return isWordThere.every(allWords);
};


export function getProductSearchValue(product: ProductModel, translateService: TranslateService): string {
  const productTypes = {
    [ProductTypes.GOODS]: translateService.instant('POSITIONS.PRODUCTS'),
    [ProductTypes.SERVICES]: translateService.instant('POSITIONS.SERVICES'),
    [ProductTypes.DIGITAL]: translateService.instant('POSITIONS.DIGITAL_PRODUCTS'),
  };
  const { name, type, net_price, available, runple_id, description, manufacturer_code, barcode } = product;
  return `${name} ${productTypes[type]} ${net_price || 'n/a'} ${available} ${runple_id} ${description} ${manufacturer_code} ${barcode}`;
}

export function getProductVariationSearchValue(product: ProductModel, translateService: TranslateService): string {
  const productTypes = {
    [ProductTypes.GOODS]: translateService.instant('POSITIONS.PRODUCTS'),
    [ProductTypes.SERVICES]: translateService.instant('POSITIONS.SERVICES'),
    [ProductTypes.DIGITAL]: translateService.instant('POSITIONS.DIGITAL_PRODUCTS'),
  };
  const { name, productName, type, net_price, unitPriceNet, available, runple_id, runpleId, description, productDescription } = product;
  return `${name || productName} ${productTypes[type]} ${net_price || unitPriceNet || 'n/a'} ${available} ${runple_id || runpleId} ${description || productDescription}`;
}

export function getProductSearchValueCamel(product: ProductModel, translateService: TranslateService): string {
  const productTypes = {
    [ProductTypes.GOODS]: translateService.instant('POSITIONS.PRODUCTS'),
    [ProductTypes.SERVICES]: translateService.instant('POSITIONS.SERVICES'),
    [ProductTypes.DIGITAL]: translateService.instant('POSITIONS.DIGITAL_PRODUCTS'),
  };
  const { name, type, netPrice, available, runpleId, description, manufacturerCode, barcode } = product;
  return `${name} ${productTypes[type]} ${netPrice || 'n/a'} ${available} ${runpleId} ${description} ${manufacturerCode} ${barcode}`;
}
