import { Component, Input, OnDestroy } from '@angular/core';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { ReplaySubject } from 'rxjs';

import { BottomNavigationModel } from './bottom-navigation.model';
import { AuthService } from '../../../auth/auth.service';
import { AuthUserModel } from '../../../auth/auth-user.model';

@Component({
  selector: 'rnpl-bottom-navigation',
  templateUrl: './bottom-navigation.component.html',
  styleUrls: ['./bottom-navigation.component.scss'],
})
export class BottomNavigationComponent implements OnDestroy {

  public loggedUser: AuthUserModel;

  @Input() public navListItems: BottomNavigationModel[];

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    public authService: AuthService,
  ) {
    this.authService.loggedUser$
      .pipe(
        distinctUntilChanged(isEqual),
        takeUntil(this.destroy$)
      )
      .subscribe((user: AuthUserModel) => this.loggedUser = user);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
