import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { InitialSetupBaseComponent } from '../initial-setup-base/initial-setup-base.component';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { AdministrationsApiService } from 'projects/workspace/src/app/administration/services/administrations-api.service';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';

@Component({
  selector: 'rnpl-accounting-method',
  templateUrl: './accounting-method.component.html',
  styleUrls: ['./accounting-method.component.scss'],
})
export class AccountingMethodComponent extends InitialSetupBaseComponent implements OnInit {

  public submitted: boolean = false;

  @Input() accountingMethod: 'smallBusiness'|'cashAccounting'|'doubleEntry' = null;
  @Output() accountingMethodChanged: EventEmitter<'smallBusiness'|'cashAccounting'|'doubleEntry'>
    = new EventEmitter<'smallBusiness'|'cashAccounting'|'doubleEntry'>();

  constructor(
    public readonly store: Store<AppState>,
    public router: Router,
    public readonly administrationsApiService: AdministrationsApiService,
  ) {
    super(store, router, administrationsApiService);
  }

  ngOnInit() {
  }

  public getStepValidation(): boolean {
    this.submitted = true;
    return !!this.accountingMethod;
  }

  public methodChanged(): void {
    this.accountingMethodChanged.emit(this.accountingMethod);
  }

  public updateStep$(company: CompanyProfile): Observable<boolean | CompanyProfile> {

    return this.updateCompanyInfo$(company);
  }

}
