import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { tap, map, finalize, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { PaginationModel, UIStatesEnum } from 'common/src/models';
import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { FileUploadParams } from 'common/src/models/file-upload-params.model';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { AppState } from '../../store/state/app.state';
import { environment } from 'projects/workspace/src/environments/environment';
import { BankAccountsListTabsEnum } from '../enums';
import { FilterModelNew } from '../../outgoing-invoice/models/filter-model-new';
import { FormInputChangedModel, ResponseList, ResponseModel } from '../../shared/models';
import { BankAccountModel, BankAccountPaymentsListTotalsModel, BankModel } from '../models';
import {
  DecrementLoadingRequestsCount,
  IncrementLoadingRequestsCount,
  LoadBankAccount,
  LoadBankAccountsList, UpdateBankAccountUIState,
  UpdateBankAccountUpdatedAt,
  UpdateShouldRefreshEntity
} from '../store/actions/bank-accounts.actions';
import { CurrencyModel } from '../../payment/models/currency.model';
import { PaymentModel } from '../../payment/models/payment.model';
import { EditModel } from '../../outgoing-invoice/models/edit.model';
import { selectCompanyProfile } from '../../administration/store/selectors';
import { CompanyProfile } from '../../administration/models/company-profile.model';
import { AdministrationsApiService } from '../../administration/services/administrations-api.service';
import { CommonModalsActionsEnum, WarningModalComponent } from 'common/src/modules/modals/modals-common';
import { getAnotherUserEditErrorModalData } from 'common/src/modules/modals/modals-common/common-modal.config';

@Injectable({
  providedIn: 'root',
})
export class BankAccountsApiService {

  public companyProfile: CompanyProfile;
  private readonly apiEndpoint: string = `${environment.javaApiVersion}/bank/accounts`;

  constructor(
    private readonly http: HttpClient,
    private readonly dialog: MatDialog,
    private readonly toasterService: ToasterService,
    private readonly translateService: TranslateService,
    private administrationsApiService: AdministrationsApiService,
    private readonly store: Store<AppState>
  ) {
    this.store.select(selectCompanyProfile)
      .subscribe((companyProfile: CompanyProfile) => {
        this.companyProfile = companyProfile;
      });
  }

  // public getBankAccountsListFilters(): Observable<string> {
  //   return this.http.get<ResponseModel<string>>(`${this.apiEndpoint}/filters`)
  //     .pipe(map((response: ResponseModel<any>) => response.data));
  // }

  public getCurrencies(): Observable<CurrencyModel[]> {
    return this.http.get<ResponseModel<CurrencyModel[]>>(`${environment.javaApiVersion}/utils/currencies`)
      .pipe(
        map((response: ResponseModel<CurrencyModel[]>) => response.data)
      );
  }

  public getBanksByCountry(codeIso3: string): Observable<BankModel[]> {
    const params = {
      code: codeIso3
    };
    return this.http.get<ResponseModel<BankModel[]>>(`${environment.javaApiVersion}/utils/banks`, { params })
      .pipe(map((response: ResponseModel<BankModel[]>) => response.data));
  }

  public getBankAccountsListFiltersIban(status: BankAccountsListTabsEnum): Observable<string[]> {
    const params = { status };
    return this.http.get<ResponseModel<string[]>>(`${this.apiEndpoint}/filters/ibans`, { params })
      .pipe(map((response: ResponseModel<string[]>) => response.data));
  }

  public getBankAccountsListFiltersAccountsName(status: BankAccountsListTabsEnum): Observable<any> {
    const params = { status };
    return this.http.get<ResponseModel<any>>(`${this.apiEndpoint}/filters/account-name`, { params })
      .pipe(map((response: ResponseModel<any>) => response.data));
  }


  public getBankAccountsListFiltersBanks(status: BankAccountsListTabsEnum): Observable<any> {
    const params = { status };
    return this.http.get<ResponseModel<any>>(`${this.apiEndpoint}/filters/banks`, { params })
      .pipe(map((response: ResponseModel<any>) => response.data));
  }

  public getBankAccountsListFiltersCurrencies(status: BankAccountsListTabsEnum): Observable<any> {
    const params = { status };
    return this.http.get<ResponseModel<any>>(`${this.apiEndpoint}/filters/currencies`, { params })
      .pipe(map((response: ResponseModel<any>) => response.data));
  }

  public getBankAccountsListFiltersCountries(status: BankAccountsListTabsEnum): Observable<any> {
    const params = { status };
    return this.http.get<ResponseModel<any>>(`${this.apiEndpoint}/filters/bank/countries`, { params })
      .pipe(map((response: ResponseModel<any>) => response.data));
  }

  public createBankAccount(bankAccount: Partial<BankAccountModel>): Observable<BankAccountModel> {
    return  this.http.post<ResponseModel<BankAccountModel>>(`${this.apiEndpoint}`, bankAccount)
      .pipe(
        tap(() => {
          if (!this.companyProfile.onboardingCompleted) { // todo: check usage
            this.administrationsApiService.getOnboardingProcess().subscribe();
          }
        }),
        map((response: ResponseModel<BankAccountModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public updateInitialSetupBankAccount(bankAccount: Partial<BankAccountModel>): Observable<BankAccountModel> {
    return  this.http.post<ResponseModel<BankAccountModel>>(`${this.apiEndpoint}/initial-setup`, bankAccount)
      .pipe(
        tap(() => {
          if (!this.companyProfile.onboardingCompleted) { // todo: check usage
            this.administrationsApiService.getOnboardingProcess().subscribe();
          }
        }),
        map((response: ResponseModel<BankAccountModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getBankAccountById(id: number): Observable<BankAccountModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return  this.http.get<ResponseModel<BankAccountModel>>(`${this.apiEndpoint}/${id}`)
      .pipe(
        tap((response: ResponseModel<BankAccountModel>) => {
          this.store.dispatch(LoadBankAccount({ bankAccount: response.data }));
          this.store.dispatch(UpdateBankAccountUpdatedAt({ updatedAt: new Date() }));
          this.store.dispatch(UpdateShouldRefreshEntity({ isShouldRefresh: false }));
        }),
        map((response: ResponseModel<BankAccountModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  public getBankAccountPrimary(): Observable<BankAccountModel> {
    return  this.http.get<ResponseModel<BankAccountModel>>(`${this.apiEndpoint}/primary`)
      .pipe(map((response: ResponseModel<BankAccountModel>) => response.data));
  }

  public updateBankAccountField(bankAccountId: number, field: FormInputChangedModel): Observable<BankAccountModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    const body = { ...field };

    return this.http.patch<ResponseModel<BankAccountModel>>(`${this.apiEndpoint}/${bankAccountId}`, body)
      .pipe(
        tap((response: ResponseModel<BankAccountModel>) => {
          this.store.dispatch(LoadBankAccount({ bankAccount: response.data }));
          this.store.dispatch(UpdateBankAccountUpdatedAt({ updatedAt: new Date() }));
          this.store.dispatch(UpdateShouldRefreshEntity({ isShouldRefresh: false }));
        }),
        map((response: ResponseModel<BankAccountModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, bankAccountId);
          return throwError(error);
        })
      );
  }

  public getBankAccountsList(
    status: BankAccountsListTabsEnum,
    pagination: PaginationModel,
    sort: FilterModelNew,
    filters: any = {}
  ): Observable<ResponseList<BankAccountModel>> {
    let params = new HttpParams()
      .set('status', status)
      .set('page', pagination.page)
      .set('sortBy', sort.sortBy)
      .set('direction', sort.direction)
      .set('length', pagination.per_page);

    for (const [key, value] of Object.entries(filters)) {
      Array.isArray(value)
        ? value.forEach(itm => params = params.append(key, itm))
        : params = params.append(key, value.toString());
    }

    return this.http.get<ResponseList<BankAccountModel>>(`${this.apiEndpoint}/list`, { params })
      .pipe(tap((data: ResponseList<BankAccountModel>) => {
        this.store.dispatch(LoadBankAccountsList({
          bankAccountsListData: {
            [data.pagination.page]: {
              pagination: data.pagination,
              sort,
              data: data.data
            }
          },
          status, page: data.pagination.page
        }));
      }));
  }

  public getActiveBankAccounts(): Observable<BankAccountModel[]> {
    const params = {
      status: BankAccountsListTabsEnum.ACTIVE,
    };

    return this.http.get<ResponseList<BankAccountModel>>(`${this.apiEndpoint}/list`, { params })
      .pipe(map((response: ResponseList<BankAccountModel>) => response.data));
  }

  public getBankAccountsCountersByStatus(status: BankAccountsListTabsEnum): Observable<number> {
    return this.http.get<ResponseModel<number>>(`${this.apiEndpoint}/count/${status}`)
      .pipe(map((data: ResponseModel<number>) => data.data));
  }

  public changeBankAccountsStatusBatch(ids: number[], status: BankAccountsListTabsEnum): Observable<any> {
    const body = { ids };
    return this.http.patch(`${this.apiEndpoint}/multiple/status/${status}`, body);
  }

  public checkBankAccountWarnings(
    bankAccountId: number,
    skipBAChangedWarning = false,
    skipExistsAnotherPrimaryBA = false
  ): Observable<any> {
    const params = {
      skipBAChangedWarning: skipBAChangedWarning.toString(),
      skipExistsAnotherPrimaryBA: skipExistsAnotherPrimaryBA.toString(),
    }
    return this.http.get(`${this.apiEndpoint}/${bankAccountId}/check-warnings`, {params});
  }

  public starBankAccountEditing(bankAccountId: number, force = false): Observable<EditModel> {
    const params = new HttpParams()
      .set('force', force.toString());

    return this.http.get<EditModel>(
      `${environment.javaApiVersion}/utils/edit-flag/${DocumentTypesUppercaseEnum.BA}/${bankAccountId}/check-and-set`,
      {params}
    )
      .pipe(
        tap(() => this.store.dispatch(UpdateBankAccountUIState({ currentState: UIStatesEnum.EDIT }))),
        catchError(error => {
          this.handlePopupErrors(error, bankAccountId);
          return throwError(error);
        })
      );
  }

  public finishBankAccountEditing(bankAccountId: number): Observable<EditModel> {
    return this.http.get<EditModel>(
      `${environment.javaApiVersion}/utils/edit-flag/${DocumentTypesUppercaseEnum.BA}/${bankAccountId}/commit`
    )
      .pipe(
        tap(() => this.store.dispatch(UpdateBankAccountUIState({ currentState: UIStatesEnum.VIEW }))),
        catchError(error => {
          this.handlePopupErrors(error, bankAccountId);
          return throwError(error);
        })
      );
  }

  public changeBankAccountStatus(bankAccountId: number, status: BankAccountsListTabsEnum): Observable<BankAccountModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<BankAccountModel>>('patch', `${this.apiEndpoint}/${bankAccountId}/status/${status}`)
      .pipe(
        tap((response: ResponseModel<BankAccountModel>) => {
          this.store.dispatch(LoadBankAccount({ bankAccount: response.data }));
          this.store.dispatch(UpdateBankAccountUpdatedAt({ updatedAt: new Date() }));
        }),
        map((response: ResponseModel<BankAccountModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public deleteBankAccountPermanently(bankAccountId: number): Observable<any> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.delete(`${this.apiEndpoint}/${bankAccountId}`)
      .pipe(
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getBankAccountsListExport(status: BankAccountsListTabsEnum): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: `${this.apiEndpoint}/download?status=${status}`,
      type: 'zip',
    };
    return of(fileParams);
  }

  // BANK ACCOUNT PAYMENTS //
  public getBankAccountPaymentsListExport(bankAccountId: number): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: `${this.apiEndpoint}/${bankAccountId}/linked-payments/download`,
      type: 'zip',
    };
    return of(fileParams);
  }

  public getBankAccountPaymentsList(
    bankAccountId: number,
    pagination: PaginationModel,
    sort: FilterModelNew,
    filters: any = {}
  ): Observable<ResponseList<PaymentModel>> {

    const params = {
      page: pagination.page,
      sortBy: sort.sortBy,
      direction: sort.direction,
      length: pagination.per_page,
    };

    for (const [key, value] of Object.entries(filters)) {
      params[key] = Array.isArray(value) ? value.join(',') : value.toString();
    }

    return this.http.get<ResponseList<PaymentModel>>(`${this.apiEndpoint}/${bankAccountId}/linked-payments`, { params });
  }

  public getBankAccountPaymentsListTotals(bankAccountId: number, filters: any = {}): Observable<BankAccountPaymentsListTotalsModel> {
    const params = {};
    for (const [key, value] of Object.entries(filters)) {
      params[key] = Array.isArray(value) ? value.join(',') : value.toString();
    }

    return this.http.get<ResponseModel<BankAccountPaymentsListTotalsModel>>(
      `${this.apiEndpoint}/${bankAccountId}/linked-payments/totals`,
      { params }
    )
      .pipe(map((response: ResponseModel<BankAccountPaymentsListTotalsModel>) => response.data));
  }

  paymentFilterPayers(bankAccountId: number): Observable<string[]> {
    const params = {
      bankAccountId: bankAccountId as any
    };
    return this.http.get<ResponseModel<string[]>>(`${this.apiEndpoint}/filters/payments/payers`, {params})
      .pipe(
        map((response: ResponseModel<string[]>) => {
          return response.data
            .filter(itm => !!itm)
            .sort((a, b) => a.localeCompare(b));
        })
      );
  }

  paymentsFilterRecipients(bankAccountId: number): Observable<string[]> {
    const params = {
      bankAccountId: bankAccountId as any
    };
    return this.http.get<ResponseModel<string[]>>(`${this.apiEndpoint}/filters/payments/recipients`, {params})
      .pipe(
        map((response: ResponseModel<string[]>) => {
          return response.data
            .filter(itm => !!itm)
            .sort((a, b) => a.localeCompare(b));
        })
      );
  }

  paymentsFilterLinkedDocuments(bankAccountId: number, filteredDocumentType?): Observable<any[]> {
    const params = {
      bankAccountId: bankAccountId as any
    };
    return this.http.get<ResponseModel<any[]>>(`${this.apiEndpoint}/filters/payments/linkedDocuments`, {params})
      .pipe(
        map((response: ResponseModel<any[]>) => {
          return response.data
            .filter(itm => itm)
            .filter(itm => filteredDocumentType ? itm.type === filteredDocumentType : true);
        })
      );
  }

  paymentsFilterRunpleIds(bankAccountId: number): Observable<any[]> {
    const params = {
      bankAccountId: bankAccountId as any
    };
    return this.http.get<ResponseModel<any[]>>(`${this.apiEndpoint}/filters/payments/rids`, { params })
      .pipe(map((response: ResponseModel<any[]>) => response.data));
  }
  // @END BANK ACCOUNT PAYMENTS //

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  private handlePopupErrors(error: HttpErrorResponse, bankAccountId?: number): void {
    if (!error || !error.error) { return; }
    if (error.error.errors && error.error.errors.length) {
      error.error.errors.forEach(errorText => {
        switch (errorText) {
          case 'anotherUserEditError':
            {
              const dialog = this.dialog.open(WarningModalComponent, {
                data: getAnotherUserEditErrorModalData(
                  {
                    document: error.error.data.entityName,
                    userName: error.error.data.userName,
                  },
                  this.translateService
                )
              });

              dialog.afterClosed().subscribe(res => {
                if (res === CommonModalsActionsEnum.CONFIRM) {
                  this.starBankAccountEditing(+bankAccountId, true).subscribe();
                  this.getBankAccountById(bankAccountId).subscribe();
                }
              });
            }
            break;
          case 'notEditModeError':
            const documentName = this.translateService.instant('BANK_ACCOUNTS.BANK_ACCOUNT');
            this.showMsg('warning', this.translateService.instant('COMMON.DOC_UPDATED_BY_USER', { document: documentName}));
            this.store.dispatch(UpdateBankAccountUIState({currentState: UIStatesEnum.VIEW}));
            this.getBankAccountById(bankAccountId).subscribe();
            break;
          default:
            this.showMsg('error', errorText);
            break
        }
      });
    }
  }


}
