import { SetupStepsModel } from './setup-steps.model';
import { InitialSetupStepIdEnum } from './initial-setup.enum';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { SubscriptionPlanEnum } from '../subscription-activation/subscription-activation.emum';

export function getSteps(companyProfile: CompanyProfile, currentStep: number): SetupStepsModel[] {
  currentStep = currentStep || 0;
  const subscriptionPlan = companyProfile.subscriptionManagement.subscriptionPlanActive;

  const steps: SetupStepsModel[] = [
    {
      id: InitialSetupStepIdEnum.COMPANY_TYPE,
      step: 0,
      status: 'active',
      title: null,
      pageTitle: 'INITIAL_SETUP.HOW_DO_YOU_RUN',
      pageDescription: '',
    },
    {
      id: InitialSetupStepIdEnum.COMPANY_DETAILS,
      step: 1,
      status: 'active',
      title: companyProfile.legalType.individualBusiness
        ? 'INITIAL_SETUP.PROVIDE_NAME'
        : 'INITIAL_SETUP.PROVIDE_DETAILS',
      pageTitle: companyProfile.legalType.individualBusiness
        ? 'INITIAL_SETUP.WHATS_NAME'
        : 'INITIAL_SETUP.PLEASE_PROVIDE_DETAILS',
      pageDescription: '',
    },
    {
      id: InitialSetupStepIdEnum.LEGAL_INFO,
      step: 2,
      status: 'active',
      title: 'INITIAL_SETUP.ENTER_LEGAL_ADDRESS',
      pageTitle: 'INITIAL_SETUP.WHATS_LEGAL_ADDRESS',
      pageDescription: '',
    },
    {
      id: InitialSetupStepIdEnum.CONTACT_INFO,
      step: 3,
      status: 'active',
      title: 'INITIAL_SETUP.SPECIFY_CONTACT_INFO',
      pageTitle: 'INITIAL_SETUP.HOW_CONTACT_YOU',
      pageDescription: '',
    },
  ];

  const featuresStep: SetupStepsModel = {
    id: InitialSetupStepIdEnum.APP_FEATURES,
    step: steps.length,
    status: 'active',
    title: 'INITIAL_SETUP.SELECT_FEATURES',
    pageTitle: 'INITIAL_SETUP.WHAT_FEATURES',
    pageDescription: 'INITIAL_SETUP.SET_OF_FEATURES',
  };

  if (subscriptionPlan === SubscriptionPlanEnum.TEAM_MONTHLY) {
    steps.push(featuresStep);
  }

  const accountingMethodStep: SetupStepsModel = {
    id: InitialSetupStepIdEnum.ACCOUNTING_METHOD,
    step: steps.length,
    status: 'active',
    title: 'INITIAL_SETUP.CHOOSE_ACCOUNTING',
    pageTitle: 'INITIAL_SETUP.ANNUAL_TURNOVER',
    pageDescription: 'INITIAL_SETUP.RIGHT_ACCOUNTING',
  };

  if (
    (subscriptionPlan === SubscriptionPlanEnum.ACCOUNTING_MONTHLY || subscriptionPlan === SubscriptionPlanEnum.TEAM_MONTHLY)
    && companyProfile.legalType.simplifiedReportingAllowed
  ) {
      steps.push(accountingMethodStep);
  }

  return steps
    .map(s => ({
      ...s,
      status: s.step < currentStep
        ? 'complete'
        : 'active'
    }));
}
