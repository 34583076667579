import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { get } from 'lodash';

import { MetricConversionPipe } from 'common/src/modules/rnpl-common';
import { AppState } from '../../../../store/state/app.state';
import { AddressFormFactory } from '../../../../shared/forms/address-form.factory';
import { DeliveryTypesEnum, ScontoRateTypeEnum } from 'common/src/models';
import { EcoOrderDeliveryInfoModel, EcoOrderModel } from '../models';
import { UpdateBillingInfoBlockValid, UpdateDeliveryInfoBlockValid } from '../store/actions/eco-order.actions';
import { AddressTypeEnum } from '../../../../sales-order/enums/address-type.enum';

@Injectable({
  providedIn: 'root'
})
export class EcoFormService {
  public billingInfoForm: FormGroup;
  public deliveryInfoForm: FormGroup;
  public pickUpAddressForm: FormGroup;
  public deliveryToAddressForm: FormGroup;
  public ecoOrder: EcoOrderModel;

  constructor(
    private readonly fb: FormBuilder,
    private metricPipe: MetricConversionPipe,
    private datePipe: DatePipe,
    private readonly store: Store<AppState>,
  ) { }

  public initBillingInfoForm(ecoOrder: EcoOrderModel = {} as EcoOrderModel): void {
    this.ecoOrder = ecoOrder;
    const billingInfo = ecoOrder.billingInformation;

    this.billingInfoForm = this.fb.group({
      paymentMethod: [get(billingInfo, 'paymentMethod')],
      paymentTerms: [get(billingInfo, 'paymentTerms')],
      name: [get(billingInfo, 'name')],
      paymentDueWithin: [get(billingInfo, 'paymentDueWithin')],
      addressTemplate: [get(billingInfo, 'addressTemplate')],
      sameAsDeliveryAddress: [get(billingInfo, 'sameAsDeliveryAddress')],
      billingAddress: AddressFormFactory.getForm(get(billingInfo, 'billingAddress'))
    });

    this.store.dispatch(UpdateBillingInfoBlockValid({ billingInfoBlockValid: this.billingInfoForm.valid }));
  }

  public initDeliveryInfoForm(deliveryInformation: EcoOrderDeliveryInfoModel = {} as EcoOrderDeliveryInfoModel): void {
    const deliveryDate = get(deliveryInformation, 'estimatedDeliveryDate');

    this.deliveryInfoForm = this.fb.group({
      deliveryType: [get(deliveryInformation, 'deliveryType'), [Validators.required]],
      isExpressDelivery: [get(deliveryInformation, 'isExpressDelivery')],
      isInsuredDelivery: [get(deliveryInformation, 'isInsuredDelivery')],
      isDeliveryCosts: [get(deliveryInformation, 'isDeliveryCosts')],
      estimatedDeliveryDate: [deliveryDate ? this.datePipe.transform(deliveryDate, 'yyyy-MM-dd') : null],
      partialShipment: [get(deliveryInformation, 'partialShipment', false)],
      //deliveryCosts
      deliveryCosts: [get(deliveryInformation, 'deliveryCosts')],
      deliveryCostsGross: [get(deliveryInformation, 'deliveryCostsGross')],
      deliveryCostsVat: [get(deliveryInformation, 'deliveryCostsVat')],
      deliveryCostsPayer: [get(deliveryInformation, 'deliveryCostsPayer')],
      //deliveryInsurance
      deliveryInsuranceCosts: [get(deliveryInformation, 'deliveryInsuranceCosts')],
      deliveryInsuranceCostsGross: [get(deliveryInformation, 'deliveryInsuranceCostsGross')],
      deliveryInsuranceVat: [get(deliveryInformation, 'deliveryInsuranceVat')],
      deliveryInsurancePayer: [get(deliveryInformation, 'deliveryInsurancePayer')],
      addressTemplate: [get(deliveryInformation, 'addressTemplate')],
      name: [get(deliveryInformation, 'name'), [Validators.required]],
    });

    this.pickUpAddressForm = AddressFormFactory.getForm(get(deliveryInformation, 'pickUpAddress'));
    this.deliveryToAddressForm = AddressFormFactory.getForm(get(deliveryInformation, 'deliveryToAddress'));

    this.updateDeliveryInfoBlockValid();
  }

  private updateDeliveryInfoBlockValid(): void {
    const deliveryCostControls: FormControl[] = [this.deliveryCostsVat, this.deliveryCosts, this.deliveryCostsGross, this.deliveryCostsPayer];
    // const insuranceCostControls: FormControl[] = [this.deliveryInsuranceVat, this.deliveryInsuranceCosts, this.deliveryInsuranceCostsGross, this.deliveryInsurancePayer];

    let isFormsValid: boolean;

    if (this.deliveryInfoForm.get('isDeliveryCosts').value) {
      deliveryCostControls.forEach(control => control.setValidators(Validators.required));
    } else {
      deliveryCostControls.forEach(control => control.clearValidators());
    }

    // if (this.deliveryInfoForm.get('isInsuredDelivery').value) {
    //   insuranceCostControls.forEach(control => control.setValidators(Validators.required));
    // } else {
    //   insuranceCostControls.forEach(control => control.clearValidators());
    // }

    // [...deliveryCostControls, ...insuranceCostControls].forEach(control => control.updateValueAndValidity());
    [...deliveryCostControls].forEach(control => control.updateValueAndValidity());

    if (this.deliveryTypeControl.value === DeliveryTypesEnum.DELIVERY) {
      if (this.deliveryInfoForm.get('addressTemplate').value === AddressTypeEnum.SIMPLIFIED) {
        AddressFormFactory.setStateForSimplifiedFAddressTemplate(this.deliveryToAddressForm);
      } else {
        this.deliveryToAddressForm.get('address_line').disable({emitEvent: false});
        this.deliveryToAddressForm.get('address_line').updateValueAndValidity({emitEvent: false});
      }
      isFormsValid = this.deliveryInfoForm.valid && this.deliveryToAddressForm.valid;
    } else {
      if (this.deliveryInfoForm.get('addressTemplate').value === AddressTypeEnum.SIMPLIFIED) {
        AddressFormFactory.setStateForSimplifiedFAddressTemplate(this.pickUpAddressForm);
      } else {
        this.pickUpAddressForm.get('address_line').disable({emitEvent: false});
        this.pickUpAddressForm.get('address_line').updateValueAndValidity({emitEvent: false});
      }
      isFormsValid = this.deliveryInfoForm.valid && this.pickUpAddressForm.valid;
    }

    this.store.dispatch(UpdateDeliveryInfoBlockValid({ deliveryInfoBlockValid: isFormsValid }));
  }

  // private fillRateField(value: number|string): number|string {
  //   if (get(this, 'currentSalesOrder.billingInformation.sconto.type') === ScontoRateTypeEnum.ABSOLUTE) {
  //     if (value === 0) { return value; }
  //     if (!value) { return null; }
  //     return this.metricPipe.transform(+value, 'TO-FRACTIONAL') || null;
  //   }
  //   return value;
  // }
  //
  // private fillControlValueWithConversion(value: number | string): number {
  //   if (value === 0) { return value; }
  //   if (!value) { return null; }
  //   return this.metricPipe.transform(+value, 'TO-FRACTIONAL') || null;
  // }


  get bankAccountIdControl(): FormControl { return this.billingInfoForm.get('bankAccount').get('id') as FormControl; }
  get scontoRateControl(): FormGroup { return this.billingInfoForm.get('sconto').get('rate') as FormGroup; }
  get scontoPeriodControl(): FormGroup { return this.billingInfoForm.get('sconto').get('period') as FormGroup; }

  get deliveryCostsVat(): FormControl { return this.deliveryInfoForm.get('deliveryCostsVat') as FormControl; }
  get deliveryCosts(): FormControl { return this.deliveryInfoForm.get('deliveryCosts') as FormControl; }
  get deliveryCostsGross(): FormControl { return this.deliveryInfoForm.get('deliveryCostsGross') as FormControl; }
  get deliveryCostsPayer(): FormControl { return this.deliveryInfoForm.get('deliveryCostsPayer') as FormControl; }
  get deliveryInsuranceVat(): FormControl { return this.deliveryInfoForm.get('deliveryInsuranceVat') as FormControl; }
  get deliveryInsuranceCosts(): FormControl { return this.deliveryInfoForm.get('deliveryInsuranceCosts') as FormControl; }
  get deliveryInsuranceCostsGross(): FormControl { return this.deliveryInfoForm.get('deliveryInsuranceCostsGross') as FormControl; }
  get deliveryInsurancePayer(): FormControl { return this.deliveryInfoForm.get('deliveryInsurancePayer') as FormControl; }
  get deliveryTypeControl(): FormControl { return this.deliveryInfoForm.get('deliveryType') as FormControl; }
}
