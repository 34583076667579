import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'rnpl-parse-iin-modal',
  templateUrl: './parse-iin-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParseIinModalComponent {

  public separatePositions = false;
  public createPositions = true;

  constructor(
    public dialogRef: MatDialogRef<ParseIinModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      // todo
    }
  ) {
  }

  public confirmAction(): void {
    this.dialogRef.close({
      separatePositions: this.separatePositions,
      createPositions: this.createPositions
    });
  }

}
