import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { COLUMNS } from './add-product-modal.config';
import { StockAreaEnum } from 'projects/workspace/src/app/warehouse/modules/transactions/enums';
import { DeliveryNoteApiService } from 'projects/workspace/src/app/delivery-note/services/delivery-note-api.service';
import { TableColumnModelExtended } from '../../../../models/table-column.model';
import { CustomSearchFn } from '../../../rnpl-common/helpers';
import { EmptyStateTypeEnum } from '../../../ui-components/empty-state/empty-state.model';
import { AvailableProduct } from '../../modals-warehouse/pack-up-modal/models/available-product.model';
import { ProductTypes } from '../../../products/product-types';
import { ProductUnitModel } from 'projects/workspace/src/app/shared/models';
import { ProductUnitApiService } from 'projects/workspace/src/app/shared/services';

@Component({
  selector: 'rnpl-add-product-modal',
  templateUrl: './add-product-modal.component.html',
})
export class AddProductModalComponent implements OnInit, AfterViewInit, OnDestroy {
  public columns: TableColumnModelExtended[] = COLUMNS;
  // public stockAreaList = STOCK_AREA_LIST;
  public quantity: number;
  public singleProductQuantity: number = 1;
  public specifyUnits: boolean = false;
  public allowFractionalValues: boolean = true;
  public form: FormGroup;
  public emptyStateTypeEnum = EmptyStateTypeEnum;

  public customSearchFn = CustomSearchFn;

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);
  readonly productsList$: BehaviorSubject<AvailableProduct[]> = new BehaviorSubject<any>([]);
  readonly productsUnitsList$: BehaviorSubject<any> = new BehaviorSubject<any>([]); // todo model
  readonly productsLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly btnToClearLoadingStatus$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private productUnitApiService: ProductUnitApiService,
    private deliveryNoteApiService: DeliveryNoteApiService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddProductModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      dnId: number,
      dnStatus: string,
    }
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    this.deliveryNoteApiService.getAvailableProductsForUnlinkedDeliveryNote(this.data.dnStatus)
      .pipe(
        finalize(() => this.productsLoading$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe((products) => this.productsList$.next(this.prepareProductsList(products)));
  }

  ngAfterViewInit(): void {
    window.dispatchEvent(new Event('resize'));
  }

  public getAvailableProductUnits(): void {
    if (!this.productIdControl.value || !this.stockAreaControl.value) { return; }

    this.deliveryNoteApiService.getAvailableProductUnitsForUnlinkedDeliveryNote(
      this.stockAreaControl.value,
      this.productIdControl.value
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.productsUnitsList$.next(
          res.map(itm => ({
            ...itm,
            quantity: null
          }))
        );
      }, () => {
        this.productsUnitsList$.next([]);
      });
  }

  public addAllAvailableUnitsChanged(checked: boolean): void {
    if (checked) {
      const units = this.productsUnitsList$.getValue().map(itm => ({
        ...itm,
        quantity: itm.available
      }));

      this.productsUnitsList$.next(units);
    }
  }

  public actionsEvent(e: string): void {
    this[e]();
  }

  private initForm(): void {
    this.form = this.fb.group({
      productId: [],
      stockArea: [StockAreaEnum.GENERAL_STOCK],
    });

    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.selectedProduct && !this.selectedProduct.available) {
          this.specifyUnits = false;
        }
        this.getAvailableProductUnits();
      });
  }

  public productSelected(): void {
    const selectedProduct = this.productsList$.getValue().find(p => p.id === this.productIdControl.value);

    this.productUnitApiService.getUnits$(selectedProduct.type as ProductTypes)
      .pipe(takeUntil(this.destroy$))
      .subscribe((unitsList: ProductUnitModel[]) => {
        const allowFractionalValues = unitsList.find(u => u.name === selectedProduct.unitType).allowFractionalValues;
        this.allowFractionalValues = allowFractionalValues && selectedProduct.type !== ProductTypes.GOODS;
      });
  }

  public prepareProductsList(products): AvailableProduct[] {
    return products.map(item => {
      return {
        ...item,
        searchLabel: `${item.name} ${item.type} ${item.netPrice || 'n/a'} ${item.available} ${item.description} ${item.runpleId}`
      };
    });
  }

  public onEditTableCell(e: { value: number, cell: string }): void {
    if (e.cell === 'quantity') {
      this.singleProductQuantity = this.productsUnitsList$.getValue()
        .filter(itm => itm.quantity)
        .reduce((acc, itm) => acc + itm.quantity, 0);
    }
  }

  public increaseQuantity(): void {
    this.singleProductQuantity = (this.singleProductQuantity || 0) + 1;
  }

  public decreaseQuantity(): void {
    if (!this.singleProductQuantity) { return; }
    this.singleProductQuantity = this.singleProductQuantity - 1;
  }

  public submit(): void {
    this.deliveryNoteApiService.addProductUnitsToUnlinkedDeliveryNote(
      this.data.dnId,
      this.stockAreaControl.value,
      this.productIdControl.value,
      this.specifyUnits,
      this.singleProductQuantity,
      this.productsUnitsList$.getValue().filter(itm => itm.quantity)
    ).subscribe(() => this.dialogRef.close());
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  get selectedProduct(): AvailableProduct {
    if (!this.productIdControl.value || !this.productsList$.getValue()) { return null; }
    return this.productsList$.getValue().find(p => p.id === this.productIdControl.value);
  }

  get productIdControl(): FormControl { return this.form.get('productId') as FormControl; }
  get stockAreaControl(): FormControl { return this.form.get('stockArea') as FormControl; }

}
