import { DeliveryServiceEnum } from '../enums';

export function TrackShipmentHelper(shipment: DeliveryServiceEnum, trackingCode: string): void {
  window.open(getTrackShipmentLink(shipment, trackingCode), '_blank');
}

export function getTrackShipmentLink(shipment: DeliveryServiceEnum, trackingCode: string): string {
  switch (shipment) {
    case DeliveryServiceEnum.AUSTRIAN_POST:
      return `https://www.post.at/sv/sendungsdetails?snr=${trackingCode}`;
    case DeliveryServiceEnum.UPS:
      return `https://www.ups.com/track?loc=de_AT&Requester=SBN&tracknum=${trackingCode}`;
    case DeliveryServiceEnum.DHL:
      return `https://www.dhl.com/at-de/home/sendungsverfolgung.html?tracking-id=${trackingCode}`;
    case DeliveryServiceEnum.DPD:
      return `https://www.mydpd.at/?f=parcel.load&p=${trackingCode}`;
    case DeliveryServiceEnum.GLS:
      return `https://gls-group.eu/track/${trackingCode}`;
  }
}
