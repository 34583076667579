import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import {
  DocumentInfoTypeEnum,
  DocumentViewerModel,
  FooterDocumentInfoModel,
  InfoBlockTypeEnum,
  PositionsColumnModel
} from '../../document-viewer.model';
import {
  selectCompanyProfile,
  selectCompanyTimezoneOffset,
  selectLegalCountryTranslate
} from 'projects/workspace/src/app/administration/store/selectors';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { STATUS_CLASSES } from 'projects/workspace/src/app/sales-order/sales-order.constants';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { HelperActionModel } from '../../../ui-components/document-steps/document-step.model';
import { selectPdfSettings } from 'projects/workspace/src/app/store/selectors/shared.selectors';
import { PdfSettingsModel } from 'projects/workspace/src/app/pdf-settings/models';
import { PdfSettingsAlignEnum } from 'projects/workspace/src/app/pdf-settings/enums';
import { DocumentTextLocationEnum } from 'projects/workspace/src/app/shared/enums';
import { DocumentTypesUppercaseEnum } from '../../../modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { AddressTypeEnum } from 'projects/workspace/src/app/sales-order/enums/address-type.enum';

@Component({
  selector: 'rnpl-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss']
})
export class DocumentViewerComponent implements OnInit, OnDestroy {

  public companyLogo: string = '';
  private countryTitle: string = '';
  public pdfSettings: PdfSettingsModel;

  public hoverSconto: boolean;
  public documentCenterPosition: number;

  public footerInfo: FooterDocumentInfoModel;
  public DocumentInfoTypeEnum = DocumentInfoTypeEnum;
  public addressTypeEnum = AddressTypeEnum;
  public infoBlockTypeEnum = InfoBlockTypeEnum;
  public alignEnum = PdfSettingsAlignEnum;
  public documentTextLocationEnum = DocumentTextLocationEnum;
  public isScrolled: boolean;
  public company: CompanyProfile;
  public appendixColumn: PositionsColumnModel[] = [
    {
      columnName: 'COLUMN.NAME',
      prop: 'description',
    },
    {
      columnName: 'COLUMN.SERIAL_NUMBER',
      prop: 'serialNumbers',
      type: DocumentInfoTypeEnum.SERIAL_NUM_LIST,
      width: 200
    },
  ];

  readonly statusClasses: { [key: string]: string } = STATUS_CLASSES;

  readonly companyProfileTimezoneOffset$: Observable<string> = this.store.select(selectCompanyTimezoneOffset);

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  @ViewChild('documentPreviewElement', {static: true}) documentPreviewElement: ElementRef;

  @Input() public documentPreviewData: DocumentViewerModel;
  @Input() public actionList: HelperActionModel[];
  @Input() public isCustomCompanyLogo: boolean;
  @Input() public customCompanyLogoUrl: string;
  @Input() public hideViewer: boolean;
  @Input() public documentName: string;
  @Input() public documentPeriod: Date;
  @Input() public positionsTitle: string = 'COLUMN.POSITIONS';
  @Input() public documentId: string;
  @Input() public statusLabel: string;
  @Input() public isLoading: boolean;
  @Input() public hidePositionTotal: boolean;
  @Input() public documentType: string = '';
  @Input() public documentTypeEnum: DocumentTypesUppercaseEnum;
  @Input() public displayAppendix: boolean;
  @Input() public appendixProducts;
  @Input() public isReadOnly: boolean = true;
  @Input() public customerBlockIsValid: boolean = true;
  @Input() public billingBlockValid: boolean = true;
  @Input() public positionsValid: boolean = true;
  @Input() public hasPositions: boolean = false;
  @Input() public hidePropertiesAction: boolean = false;
  @Input() public allowedAddPdfText: boolean = false;

  @Output() selectCustomerEmit: EventEmitter<any> = new EventEmitter();
  @Output() addRemarkEmit: EventEmitter<any> = new EventEmitter();
  @Output() addPdfTextEmit: EventEmitter<any> = new EventEmitter();
  @Output() displayDiscountModalEmit: EventEmitter<any> = new EventEmitter();
  @Output() actionInfoBlockEmit: EventEmitter<{action: string, centerPosition: number, params?: any}> = new EventEmitter();

  constructor(
    private readonly store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.getDocumentCenter();
    this.getCountryTitle();
    this.getCompanyProfileFromStore();
    this.getPdfSettingsFromStore();
  }

  private getPdfSettingsFromStore(): void {
    this.store.select(selectPdfSettings)
      .pipe(takeUntil(this.destroy$))
      .subscribe((pdfSettings: PdfSettingsModel) => {
        this.pdfSettings = pdfSettings;
      });
  }

  private getCompanyProfileFromStore(): void {
    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe(company => {
        if (!!company) {
          this.company = company;
          this.companyLogo = !!company.printLogo ? company.printLogo.urlOriginal : null;
          this.setFooterInfo();
        }
      });
  }

  private setFooterInfo(): void {
    if (!this.company) { return; }

    const postalCodeCityCountry = [
      this.company.legalAddress.zip_code || '',
      this.company.legalAddress.city || '',
      this.countryTitle || ''
    ].filter(i => !!i)
      .join(', ');

    this.footerInfo = {
      legalAddress: {
        companyName: this.company.companyName,
        companyNameForDeliveryAddress: this.company.legalType.type === 'Einzelunternehmen'
          ? this.company.companyName || this.company.companyNameWithType
          : this.company.companyName,
        companyNameWithType: this.company.companyNameWithType,
        street: this.company.legalAddress.street,
        streetLine: [
          this.company.legalAddress.house_number,
          this.company.legalAddress.block,
          this.company.legalAddress.floor,
          this.company.legalAddress.apartments
        ],
        additionalInfo: this.company.legalAddress.additional_information,
        postalCodeCityCountry,
      },
      companyContact: {
        email: this.company.email,
        phone: this.company.phone,
        webSite: this.company.website,
      },
      companyInfo: [
        {
          // title: 'Firmenbuchnummer', // todo: add translate
          value: this.company.registrationNumber,
          hide: !this.company.registrationNumber
        },
        {
          // title: 'Firmenbuchgericht', // todo: add translate
          value: this.company.commercialCourt,
          hide: !this.company.commercialCourt
        },
        {
          // title: 'COLUMN.VAN_NUMBER',
          value: this.company.vatNumber,
          hide: !this.company.vatNumber
        }
      ]
    };
  }

  private getCountryTitle(): void {
    this.store.select(selectLegalCountryTranslate)
      .pipe(takeUntil(this.destroy$))
      .subscribe((countryTitle) => {
        if (!!countryTitle) {
          this.countryTitle = countryTitle;
          this.setFooterInfo();
        }
      });
  }

  public showGradient(e): void {
    this.isScrolled = e;
  }

  public selectCustomerAction(): void {
    this.getDocumentCenter();
    this.selectCustomerEmit.emit(this.documentCenterPosition);
  }

  public addRemarkAction():void {
    if (this.isReadOnly) { return; }
    this.getDocumentCenter();
    this.addRemarkEmit.emit(this.documentCenterPosition);
  }

  public displayDiscountModal(): void {
    // if (this.isReadOnly) { return; }
    this.getDocumentCenter();
    this.displayDiscountModalEmit.emit(this.documentCenterPosition);
  }

  public actionInfoBlockHandler(action: string, params?: any): void {
    if (!action) { return; }
    this.getDocumentCenter();
    this.actionInfoBlockEmit.next({action, centerPosition: this.documentCenterPosition, params});
  }

  public getDocumentCenter(): void {
    const documentBoundingClientRect = this.documentPreviewElement.nativeElement.getBoundingClientRect();
    this.documentCenterPosition = documentBoundingClientRect.left + (documentBoundingClientRect.width / 2);
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
