import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { NgSelectComponent } from '@ng-select/ng-select';
import { finalize, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, forkJoin, of, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { get } from 'lodash';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ProductModel, ProductsService } from '../../../products';
import { ProductTypes } from '../../../products/product-types';
import { InvoiceApiService } from 'projects/workspace/src/app/outgoing-invoice/services/invoice-api.service';
import {
  OutgoingInvoiceModel,
  OutgoingInvoicePositionModel,
  PositionAccountingSettings
} from 'projects/workspace/src/app/outgoing-invoice/models';
import { CompositeProductModalComponent } from '../../modals-products';
import { TimeTrackingApiService } from 'projects/workspace/src/app/time-tracking/services/time-tracking-api.service';
import { TimeTrackingRecordModel } from 'projects/workspace/src/app/time-tracking/models';
import { TimeTrackingListTabsEnum } from 'projects/workspace/src/app/time-tracking/enums';
import { CustomSearchFn, getProductSearchValue, getProductSearchValueCamel } from 'common/src/modules/rnpl-common/helpers';
import { PRODUCTS_TYPE } from '../../../rnpl-common/constants/products-type';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { PauseModalsHintsComponent } from '../../modals-subscription/pause-modals-hints/pause-modals-hints.component';
import { VAT_LIST } from 'projects/workspace/src/app/shared/constants/vat-list';
import { DocumentTypesUppercaseEnum } from '../../modals-common/link-document-modal/enums/ducument-types.enum';
import { RecalculatePositionApiService } from '../../../../services/recalculate-position-api.service';
// import { CORRECTION_POSITION_TYPES_LIST } from 'projects/workspace/src/app/accounting/accounting.constants';
// import { CommonModalsActionsEnum, WarningModalComponent } from '../../modals-common';
// import { CHANGE_CORRECTION_CALCULATION_MODAL_DATA } from '../../modals.contsans';
import { CORRECTION_POSITION_TYPES_LIST } from 'projects/workspace/src/app/accounting/accounting.constants';
import { CommonModalsActionsEnum, WarningModalComponent } from '../../modals-common';
import { CHANGE_CORRECTION_CALCULATION_MODAL_DATA } from '../../modals.contsans';
import {
  PositionAccountingSettingsComponent
} from 'projects/workspace/src/app/shared/components/position-accounting-settings/position-accounting-settings.component';
import { OinTypeEnum, OutgoingInvoiceListTabsEnum } from 'projects/workspace/src/app/outgoing-invoice/enums';
import { ProductUnitModel } from 'projects/workspace/src/app/shared/models';
import { ProductUnitApiService } from 'projects/workspace/src/app/shared/services';

@Component({
  selector: 'rnpl-outgoing-invoice-position-modal',
  templateUrl: './outgoing-invoice-position-modal.component.html',
  styleUrls: ['./outgoing-invoice-position-modal.component.scss']
})
export class OutgoingInvoicePositionModalComponent extends BaseModalComponent implements OnInit {

  public productType = PRODUCTS_TYPE;
  // public correctionPositionTypesList = CORRECTION_POSITION_TYPES_LIST;
  public unitsList: string[] = [];
  public unitsListSource: ProductUnitModel[] = [];
  public companyProfile: CompanyProfile;

  public productTypes = ProductTypes;
  public selectedRecordInvalid: boolean = false;
  public selectedProductInvalid: boolean = false;
  public productNameInvalid: boolean = false;
  public previousUnitType: string;

  public productsList: ProductModel[] = [];
  public productsListAllTypes: ProductModel[] = [];
  public timeTrackingRecords: TimeTrackingRecordModel[] = [];

  public productTypeRadio: 'common'|'general' = 'common';
  public isTimeTrackingProducts: boolean = false;
  public addProductsNotFromSalesOrder: boolean = false;
  public accountingSettings: PositionAccountingSettings;
  public selectedProduct: ProductModel = null;
  public selectedRecord: TimeTrackingRecordModel = null;
  public positionName: string = null;
  public selectedProductType: ProductTypes = ProductTypes.GOODS;
  public positionFromTimeTracking: OutgoingInvoicePositionModel;

  public customSearchFn = CustomSearchFn;

  readonly generalProductsList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  readonly addPositionRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public form: FormGroup;
  public readonly vatList = VAT_LIST;

  @ViewChild('select', {static: false}) public dropdown: NgSelectComponent;
  @ViewChild('hint', { static: false }) hint: PauseModalsHintsComponent;
  @ViewChild('positionAccountingSettings', { static: false }) positionAccountingSettingsRef: PositionAccountingSettingsComponent;

  constructor(
    public store: Store<AppState>,
    public toasterService: ToasterService,
    public translateService: TranslateService,
    private productsService: ProductsService,
    private invoiceApiService: InvoiceApiService,
    private timeTrackingApiService: TimeTrackingApiService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<OutgoingInvoicePositionModalComponent>,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private recalculatePositionApiService: RecalculatePositionApiService,
    private productUnitApiService: ProductUnitApiService,
    @Inject(MAT_DIALOG_DATA) public data: {
      invoice: OutgoingInvoiceModel,
      isGeneralPartner: boolean,
      hasLinkedSO: boolean,
      hasLinkedNotTechnicalSO: boolean,
      hasLinkedOFR: boolean,
      hasLinkedSBC: boolean,
      hasLinkedECO: boolean,
      invoiceMayContainProductsNotFromOrder: boolean,
      vatBlocked: boolean,
      hasLinkedDocument: boolean;
    }
  ) {
    super(toasterService);

    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((companyProfile: CompanyProfile) => {
        this.companyProfile = companyProfile;
      });
  }

  ngOnInit() {
    this.initForm(this.selectedProduct);
    this.productTypeControl.setValue(ProductTypes.GOODS);
    this.getProducts();
    this.getGeneralProductsListDropdown();

    if (
      !this.data.hasLinkedSO &&
      !this.data.hasLinkedNotTechnicalSO &&
      !this.data.hasLinkedOFR &&
      !this.data.hasLinkedSBC &&
      !this.data.hasLinkedECO
    ) {
      this.addProductsNotFromSalesOrder = true;
    }

    if (this.data.invoice.customerId && !this.data.hasLinkedSO) {
      const filters: any = {
        billable: true,
        loadImages: true,
        approved: true,
        hasDocuments: false,
        partnerIds: [this.data.invoice.customerId]
      };

      this.timeTrackingApiService.getRecordsListDetails(TimeTrackingListTabsEnum.OPEN, filters)
        .pipe(
          takeUntil(this._destroy)
        )
        .subscribe(records => {
          this.timeTrackingRecords = records;
        });
    }
  }

  private initForm(selectProduct: ProductModel = {} as ProductModel, fromDocument: boolean = false): void {
    this.form = this.fb.group({
      grossTotalPrice: [
        fromDocument ? this.setCurrencyValue(get(selectProduct, 'grossTotalPrice')) :
          this.setCurrencyValue(get(selectProduct, 'prices.price.grossPrice')),
        Validators.required]
      ,
      grossUnitPrice: [
        fromDocument ? this.setCurrencyValue(get(selectProduct, 'grossUnitPrice')) :
          this.setCurrencyValue(get(selectProduct, 'prices.price.grossPrice')),
        Validators.required
      ],
      netTotalPrice: [
        fromDocument ? this.setCurrencyValue(get(selectProduct, 'netTotalPrice')) :
          this.setCurrencyValue(get(selectProduct, 'prices.price.netPrice')),
        Validators.required
      ],
      productDescription: [get(selectProduct, 'description')],
      providedServices: [ get(selectProduct, 'description')],
      description: [fromDocument ? get(selectProduct, 'name') : get(selectProduct, 'description')],
      netUnitPrice: [
        fromDocument ? this.setCurrencyValue(get(selectProduct, 'netUnitPrice')) :
          this.setCurrencyValue(get(selectProduct, 'prices.price.netPrice')),
        Validators.required
      ],
      vat: [
        fromDocument
          ? get(selectProduct, 'vat')
          : get(selectProduct, 'prices.price.vat') || get(selectProduct, 'prices.price.vat') === 0
            ? get(selectProduct, 'prices.price.vat')
            : get(selectProduct, 'accountingSettings.vatRateByDefault')
      ],
      discount: [
        fromDocument
          ? get(selectProduct, 'discount')
          : get(selectProduct, 'prices.price.discount'),
      ],
      discountAmountNet: [
        fromDocument
          ? get(selectProduct, 'discountAmountNet')
          : this.setCurrencyValue(get(selectProduct, 'prices.price.discountNet'))
      ],
      discountAmountGross: [
        fromDocument
          ? get(selectProduct, 'discountAmountGross')
          : this.setCurrencyValue(get(selectProduct, 'prices.price.discountGross'))
      ],
      addDiscount: [fromDocument
        ? !!get(selectProduct, 'discount')
        : !!get(selectProduct, 'prices.price.discount')],
      quantity: [fromDocument ? get(selectProduct, 'available') : 1],
      delivered: [],
      providedFrom: [get(selectProduct, 'providedFrom')],
      providedTo: [get(selectProduct, 'providedTo')],
      remark: [],
      addRemark: [false],
      productType: [],
      arbitraryPositionProductType: [ProductTypes.GOODS],
      unitType: [get(selectProduct, 'unitType', 'pcs')],
      productDescriptionUpdated: [false],
      // correctionType: this.isCorrectionProductType ? this.correctionType.value : null,
      // specifyAmountsSeparately: [],
      vatTotal: [],
    });
  }

  public getPositionFromRecordProto(selectedRecord: TimeTrackingRecordModel): void {
    this.invoiceApiService.getOutgoingInvoicePositionFromRecordProto(this.data.invoice.invoiceId, this.selectedRecord.id)
      .pipe(
        finalize(() => this.addPositionRequest$.next(false)),
        takeUntil(this._destroy))
      .subscribe((response: OutgoingInvoicePositionModel) => {

        this.positionFromTimeTracking = response;

        this.selectedProductType = ProductTypes.SERVICES;
        this.selectedProduct = {
          ...response,
          productImgUrl: selectedRecord.product.imageUrl
        } as any;
        this.form.patchValue({
          ...response,
          description: response.providedServices,
        });
        this.productTypeControl.setValue(ProductTypes.SERVICES);
      });
  }

  selectPositionChangesTimeTracking(selectedRecord: TimeTrackingRecordModel) {
    if (this.data.vatBlocked) {
      this.vat.disable({onlySelf: true, emitEvent: false});
    }
    this.getPositionFromRecordProto(selectedRecord);
  }

  selectPositionChanges(selectedProduct: ProductModel): void {
    this.selectedProductType = this.isTimeTrackingProducts
      ? ProductTypes.SERVICES
      : (selectedProduct.type || selectedProduct.productType);
    if (this.productTypeRadio === 'general') {
      this.selectedProductType = ProductTypes.GENERAL;
    }
    this.selectedProduct = selectedProduct;
    this.accountingSettings = null;
    if (this.positionAccountingSettingsRef) {
      this.positionAccountingSettingsRef.resetForm();
    }

    this.vat.enable({onlySelf: true, emitEvent: false});
    this.netTotalPrice.enable({onlySelf: true, emitEvent: false});
    this.netUnitPrice.enable({onlySelf: true, emitEvent: false});
    this.grossTotalPrice.enable({onlySelf: true, emitEvent: false});
    this.grossUnitPrice.enable({onlySelf: true, emitEvent: false});

    if ((this.data.hasLinkedSO || this.data.hasLinkedOFR) && !this.addProductsNotFromSalesOrder) {
      this.initForm(this.selectedProduct, true);
      this.positionName = this.selectedProduct.name;
      this.accountingSettings = { ...this.selectedProduct.accountingSettings };
      this.accountingSettings.productTypeId = get(this.selectedProduct, 'accountingSettings.glProductTypeId');

      if (this.isPrepaymentProductType) {
        this.vat.setValue(selectedProduct.vatRate);

        if (this.isPrepaymentInvoice || this.isRegularInvoice) {
          this.vat.disable({onlySelf: true, emitEvent: false});
        }

        if (this.isRegularInvoice) {
          this.netTotalPrice.setValue(selectedProduct.amountNet);
          this.netUnitPrice.setValue(selectedProduct.amountNet);
          this.grossTotalPrice.setValue(selectedProduct.amountGross);
          this.grossUnitPrice.setValue(selectedProduct.amountGross);
          this.netTotalPrice.disable({onlySelf: true, emitEvent: false});
          this.netUnitPrice.disable({onlySelf: true, emitEvent: false});
          this.grossTotalPrice.disable({onlySelf: true, emitEvent: false});
          this.grossUnitPrice.disable({onlySelf: true, emitEvent: false});
        }
      }

      if (this.data.vatBlocked) {
        this.vat.setValue(0);
        this.vat.disable({onlySelf: true, emitEvent: false});
        this.vatTotal.disable({onlySelf: true, emitEvent: false});
        if (!this.isPrepaymentProductType) {
          this.grossTotalPrice.setValue(this.setCurrencyValue(get(this.selectedProduct, 'netTotalPrice')));
          this.grossUnitPrice.setValue(this.setCurrencyValue(get(this.selectedProduct, 'netTotalPrice')));
        }
      }

      // init position predefined data
      this.updatePosition(null, 'resolveRevenueGL');

    } else {
      this.getProductById(+selectedProduct.id);
    }

    this.getProductUnits();
    this.productTypeControl.setValue(selectedProduct['arbitraryProductType'] || selectedProduct.type);
  }

  public getProductUnits(productType?: ProductTypes): void {
    productType = productType || this.selectedProduct.type || this.selectedProduct.productType || this.selectedProduct.entityForm;
    this.productUnitApiService.getUnits$(productType)
      .pipe(takeUntil(this.destroy$))
      .subscribe((unitsList: ProductUnitModel[]) => {
        this.unitsListSource = unitsList;
        this.unitsList = unitsList.map(u => u.name);
      });
  }

  public resetUnitTypeForArbitrary(productType: ProductTypes): void {
    this.unitType.patchValue('pcs');
    this.getProductUnits(productType);
  }

  public getProductById(productId) {
    this.productsService.getProduct(productId, this.data.invoice.salesPriceList)
      .pipe(takeUntil(this._destroy))
      .subscribe(product => {
        this.initForm(product);
        this.accountingSettings = { ...product.accountingSettings };
        this.accountingSettings.productTypeId = product.accountingSettings.glProductTypeId;
        this.productTypeControl.setValue(product.productType);

        if (this.data.vatBlocked) {
          this.vat.setValue(0);
          this.vat.disable({onlySelf: true, emitEvent: false});
          this.vatTotal.disable({onlySelf: true, emitEvent: false});
          this.grossTotalPrice.setValue(this.setCurrencyValue(get(product, 'prices.price.netPrice')));
          this.grossUnitPrice.setValue(this.setCurrencyValue(get(product, 'prices.price.netPrice')));
        }

        // init position predefined data
        this.updatePosition(null, 'resolveRevenueGL');
      });
  }

  private getProducts(): void {
    // this.productsService.getAvailableProducts(this.data.invoice.invoiceId, 'outgoing-invoices')
    //   .pipe(takeUntil(this._destroy))
    //   .subscribe((products: ProductModel[]) => {
    //     this.productsList = this.prepareProductsList(products);
    //     this.filterProducts();
    //     this.showDropdownSpin$.next(false);
    //   });

    const activeProductsRequest$ = this.productsService
      .getActiveProducts(undefined, undefined, this.data.invoice.salesPriceList)
      .pipe(tap((products: ProductModel[]) => this.productsListAllTypes = this.prepareProductsList(products)));

    const availableProductsRequest$ = this.isPrepaymentInvoice
      ? of(true)
      : this.invoiceApiService
        .getAvailablePositionsFromSO(this.data.invoice.invoiceId, this.companyProfile.workspaceId)
        .pipe(tap((products: ProductModel[]) => this.productsList = [...this.productsList, ...this.prepareProductsList(products)]));

    const availablePrepaymentPositionsRequest$ = this.linkedSoId
      ? this.invoiceApiService
        .getAvailablePrepaymentPositions(this.linkedSoId, this.companyProfile.workspaceId, this.data.invoice.type)
        .pipe(tap((positions: any[]) => {
          positions.map(pos => {
            this.productsList.push({
              ...pos,
              searchLabel: pos.name
            });
          });
          this.productsList = [...this.productsList];
        }))
        : of(true);

      forkJoin([
        activeProductsRequest$,
        availableProductsRequest$,
        availablePrepaymentPositionsRequest$
      ])
        .pipe(takeUntil(this._destroy))
        .subscribe(() => this.showDropdownSpin$.next(false));
  }

  private getGeneralProductsListDropdown(): void {
    this.productsService.getGeneralProductsListDropdown(false)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        const filteredGeneralProducts = response
          .filter(glProduct => glProduct.entityForm === 'services')
          .filter(glProduct => glProduct.isVisibleForList);
        this.generalProductsList$.next(filteredGeneralProducts);
      });
  }

  public prepareProductsList(products): ProductModel[] {
    return products.map(item => ({
      ...item,
      searchLabel: getProductSearchValue(item, this.translateService),
      searchLabelCamel: getProductSearchValueCamel(item, this.translateService)
    }));
  }

  public createProduct(productType: ProductTypes): void {
    const dialog = this.dialog.open(CompositeProductModalComponent, {
      data: {
        family: null,
        productType: productType
      },
      disableClose: true,
    });

    dialog.afterClosed().subscribe((product: ProductModel) => {
      if (product) {
        this.productsList.push(product);
        this.productsListAllTypes.push(product);
        this.selectedProduct = product;
        this.selectedProductType = product.productType;
        this.selectPositionChanges(product);
      }
    });
  }

  public addPosition(): void {
    if (this.addPositionRequest$.getValue()) { return; }
    this.addPositionRequest$.next(true);

    if (this.isCreditProductType) {
      this.addCreditPosition();
      return;
    }

    if (this.isTimeTrackingProducts && !this.selectedRecord) {
      this.selectedRecordInvalid = true;
      this.addPositionRequest$.next(false);
      return;
    }
    this.addProductPosition();
  }

  public addProductPosition(): void {
    let description: string = null;

    if (this.isArbitraryProductType || this.isCorrectionProductType) {
      if (!this.positionName) {
        this.productNameInvalid = true;
        this.addPositionRequest$.next(false);
        return;
      }
    } else {
      if (!this.selectedProduct) {
        this.selectedProductInvalid = true;
        this.addPositionRequest$.next(false);
        return;
      }
    }

    const selectedNotArbitrary = this.selectedProduct &&
      !this.isArbitraryProductType &&
      !this.isCorrectionProductType;

    if (get(this.selectedProduct, 'logistics')) {
      description = this.selectedProduct.description;
    }

    let position: OutgoingInvoicePositionModel = {
      ...this.form.getRawValue(),
      positionId: this.selectedProduct.id,
      productTypeForGrouping: this.selectedProductType,
      positionType: this.isPrepaymentProductType
        ? 'PREPAYMENT'
        : null,
      description: selectedNotArbitrary
        ? this.selectedProduct.name
        : this.positionName,
      manufacturerCode: get(this.selectedProduct, 'manufacturer_code') || get(this.selectedProduct, 'manufacturerCode'),
      productId: selectedNotArbitrary ? +this.selectedProduct.id : null,
      generalProductId: selectedNotArbitrary ? this.selectedProduct.generalProductId : null,
      runpleId: selectedNotArbitrary
        ? (this.selectedProduct.runpleId || this.selectedProduct.runple_id)
        : null,
      type: this.selectedProductType,
      sourcePositionId: this.selectedProduct['orderPositionId'],
      arbitraryProductType: this.selectedProduct['arbitraryProductType'],
      sourceInvoiceId: this.selectedProduct['sourceInvoiceId'],
      notFromBaseDocument: this.addProductsNotFromSalesOrder,
      logistics: (this.selectedProduct && !this.isArbitraryProductType)
        ? !!this.selectedProduct.logistics
        : false,
      productDescription: (!this.isServiceProductType() && !this.isGeneralServiceType && !this.isArbitraryProductType)
        ? this.productDescription.value
        : description,
      providedServices: (this.isServiceProductType() || this.isGeneralServiceType || this.isArbitraryProductType)
        ? this.providedServices.value
        : null,
      documentId: this.data.invoice.invoiceId,
      // correctionType: this.correctionType.value,
      accountingSettings: {
        ...this.accountingSettings,
        ...(this.positionAccountingSettingsRef && this.positionAccountingSettingsRef.getFormValue()),
      }
    };

    if (this.isArbitraryProductType) {
      position.description = this.positionName;
    }

    if (this.isTimeTrackingProducts) {
      position = {
        ...this.positionFromTimeTracking,
        ...this.form.getRawValue(),
        recordId: this.selectedRecord.id,
      };
    }

    this.invoiceApiService.createPositionByPredefinedForm(this.data.invoice.invoiceId, position)
      .pipe(finalize(() => this.addPositionRequest$.next(false)))
      .subscribe((response: OutgoingInvoicePositionModel) => this.dialogRef.close(response));
  }

  public addCreditPosition(): void {
    this.invoiceApiService.addCreditPosition(
      this.data.invoice.invoiceId,
      {
        description: this.productDescription.value,
        amountNet: this.netTotalPrice.value,
      }
    )
      .pipe(finalize(() => this.addPositionRequest$.next(false)))
      .subscribe((response: OutgoingInvoicePositionModel) => this.dialogRef.close(response));
  }

  public displayUserDefinedPosition(): void {
    this.selectedProductType = ProductTypes.ARBITRARY;
    this.selectedProduct = { name: this.translateService.instant('POSITIONS.USER_DEFINED_POSITION') };
    this.positionName = null;
    this.initForm();
    this.productTypeControl.setValue(ProductTypes.GOODS);
    this.resetUnitTypeForArbitrary(this.arbitraryPositionProductTypeControl.value);
    if (this.data.vatBlocked) {
      this.vat.setValue(0);
      this.vat.disable({onlySelf: true, emitEvent: false});
    } else {
      this.vat.setValue(20);
    }
  }

  // public displayCorrectionPosition(): void {
  //   this.selectedProductType = ProductTypes.CORRECTION;
  //   this.selectedProduct = { name: this.translateService.instant('FORM.CORRECTIONS') };
  //   this.positionName = null;
  //   this.initForm();
  //   this.correctionType.setValue('NEGATIVE');
  //   this.netTotalPrice.setValue(0);
  //   this.vatTotal.setValue(0);
  //   this.grossTotalPrice.setValue(0);
  //   this.productTypeControl.setValue(ProductTypes.CORRECTION);
  //   this.specifyAmountsSeparately.setValue(false);
  //   this.correctionType.disable({ onlySelf: true, emitEvent: false })
  //   if (this.data.vatBlocked) {
  //     this.vatTotal.disable({onlySelf: true, emitEvent: false});
  //     this.vat.setValue(0);
  //   }
  //   // init position predefined data
  //   this.updatePosition(null, 'resolveRevenueGL');
  // }

  public setCreditPosition(): void {
    this.selectedProductType = ProductTypes.CREDIT;
    this.selectedProduct = { name: this.translateService.instant('Credit') };
    this.productTypeControl.setValue(ProductTypes.CREDIT);
  }

  public updatePosition(value, fieldName): void {
    if (this.isCreditProductType) { return; }

    let description: string = null;
    this.addPositionRequest$.next(true);

    switch (fieldName) {
      case 'delivered':
      case 'providedFrom':
      case 'providedTo':
        value = this.datePipe.transform(value, 'yyyy-MM-dd');
        break;
    }

    if (get(this.selectedProduct, 'logistics')) {
      description = this.selectedProduct.description;
    }

    // if (fieldName === 'specifyAmountsSeparately' && !value && !force) {
    //   if (
    //     (this.netTotalPrice.value && (this.netTotalPrice.value !== '0.00')) ||
    //     (this.grossTotalPrice.value && (this.grossTotalPrice.value !== '0.00')) ||
    //     (this.vatTotal.value && (this.vatTotal.value !== 0))
    //   ) {
    //     const dialog = this.dialog.open(WarningModalComponent, {
    //       data: CHANGE_CORRECTION_CALCULATION_MODAL_DATA,
    //       disableClose: true,
    //     });
    //
    //     dialog.afterClosed().subscribe(res => {
    //       if (res === CommonModalsActionsEnum.CONFIRM) {
    //         this.updatePosition(value, fieldName, true);
    //       } else {
    //         this.specifyAmountsSeparately.setValue(true);
    //       }
    //     });
    //
    //     return;
    //   }
    // }

    this.recalculatePositionApiService.recalculatePosition(DocumentTypesUppercaseEnum.OIN, {
      protoPosition: {
        ...this.form.getRawValue(),
        salesPriceListName: this.data.invoice.salesPriceList,
        productId: this.selectedProduct.id,
        sourceInvoiceId: this.selectedProduct['sourceInvoiceId'],
        productTypeForGrouping: this.selectedProductType,
        productType: this.productTypeControl.value,
        positionType: this.isPrepaymentProductType
          ? 'PREPAYMENT'
          : null,
        productDescription: (!this.isServiceProductType() && !this.isGeneralServiceType && !this.isArbitraryProductType)
          ? this.productDescription.value
          : description,
        providedServices: (this.isServiceProductType() || this.isGeneralServiceType || this.isArbitraryProductType)
          ? this.providedServices.value
          : null,
        description: this.isCorrectionProductType || this.isPrepaymentProductType || this.isArbitraryProductType
          ? this.form.get('description').value
          : null,
        notFromBaseDocument: this.addProductsNotFromSalesOrder,
        logistics: this.selectedProduct && !this.isArbitraryProductType
          ? !!this.selectedProduct.logistics
          : false,
        timeTracking: this.isTimeTrackingProducts,
        // correctionType: this.isCorrectionProductType ? this.correctionType.value : null,
        quantity: this.isPrepaymentProductType
          ? 1
          : this.quantity.value,
        accountingSettings: {
          ...this.accountingSettings,
          ...(this.positionAccountingSettingsRef && this.positionAccountingSettingsRef.getFormValue()),
          productTypeId: get(this.accountingSettings, 'productTypeId') || get(this.accountingSettings, 'glProductTypeId')
        },
      },
      fieldUpdateRequest: {
        fieldName: fieldName,
        fieldValue: value
      },
      type: this.selectedProductType,
      documentId: this.data.invoice.invoiceId,
    })
      .pipe(finalize(() => this.addPositionRequest$.next(false)))
      .subscribe(product => {
        this.form.patchValue(product);
        this.previousUnitType = product.unitType;
        if (product.accountingSettings) {
          this.accountingSettings = product.accountingSettings;
        }
      }, () => {
        if (fieldName === 'unitType') {
          // set previous value of unit type in case of error
          this.unitType.patchValue(this.previousUnitType);
          // redraw quantity control view
          setTimeout(() => this.quantity.patchValue(this.quantity.value), 10);
        }
      });
  }

  public setCurrencyValue(value): number {
    if (!value) { return value; }
    return +(value / 1e2).toFixed(2);
  }

  public updateDescription(description: string): void {
    this.selectedProduct = {
      ...this.selectedProduct,
      displayDescription: description
    };
  }

  public updateDescriptionByProductType(): void {
    if (
      this.isServiceProductType()
      || this.isArbitraryProductType
      || this.isGeneralServiceType
    ) {
      this.updatePosition(this.providedServices.value, 'providedServices');
    } else {
      this.updatePosition(this.productDescription.value, 'productDescription');
    }
  }

  public isServiceProductType(): boolean {
    return this.selectedProductType === ProductTypes.SERVICES;
  }
  get isGoodsProductType(): boolean {
    return this.selectedProductType === ProductTypes.GOODS;
  }
  get isDigitalProductType(): boolean {
    return this.selectedProductType === ProductTypes.DIGITAL;
  }
  get isCorrectionProductType(): boolean {
    return this.selectedProductType === ProductTypes.CORRECTION;
  }
  get isCreditProductType(): boolean {
    return this.selectedProductType === ProductTypes.CREDIT;
  }
  get isArbitraryProductType(): boolean {
    return this.selectedProductType === ProductTypes.ARBITRARY;
  }
  get isTemplate(): boolean {
    return this.data && this.data.invoice.status === OutgoingInvoiceListTabsEnum.TEMPLATE;
  }
  get isGeneralProductType(): boolean {
    return this.selectedProductType === ProductTypes.GENERAL;
  }
  get isPrepaymentProductType(): boolean {
    return this.selectedProductType === ProductTypes.PREPAYMENT
      || this.selectedProductType === ProductTypes.PREPAYMENT.toUpperCase();
  }
  get isGeneralServiceType(): boolean {
    if (!this.selectedProduct) { return false; }
    return this.selectedProduct.entityForm === ProductTypes.SERVICES;
  }
  get isGeneralGoodsType(): boolean {
    if (!this.selectedProduct) { return false; }
    return this.selectedProduct.entityForm === ProductTypes.GOODS;
  }
  get isGeneralDigitalType(): boolean {
    if (!this.selectedProduct) { return false; }
    return this.selectedProduct.entityForm === ProductTypes.DIGITAL;
  }

  get allowFractionalValues(): boolean {
    if (!this.unitType.value || !this.unitsListSource.length) { return true; }
    return this.unitsListSource
      .find((itm: ProductUnitModel) => itm.name === this.unitType.value)
      .allowFractionalValues;
  }

  get linkedSoId(): number {
    const linkedSoList = get(this, 'data.invoice.linkedDocuments', [])
      .filter(doc => doc.printableType === DocumentTypesUppercaseEnum.SO || doc.printableType === DocumentTypesUppercaseEnum.OFR);
    return linkedSoList && linkedSoList.length
      ? linkedSoList[0].documentId
      : null;
  }

  get isPrepaymentInvoice(): boolean {
    return get(this.data, 'invoice.type') === OinTypeEnum.PREPAYMENT;
  }

  get isRegularInvoice(): boolean {
    return get(this.data, 'invoice.type') === OinTypeEnum.REGULAR;
  }

  get grossTotalPrice(): FormControl { return this.form.get('grossTotalPrice') as FormControl; }
  get grossUnitPrice(): FormControl { return this.form.get('grossUnitPrice') as FormControl; }
  get specifyRemark(): FormControl { return this.form.get('addRemark') as FormControl; }
  get netTotalPrice(): FormControl { return this.form.get('netTotalPrice') as FormControl; }
  get providedFrom(): FormControl { return this.form.get('providedFrom') as FormControl; }
  get netUnitPrice(): FormControl { return this.form.get('netUnitPrice') as FormControl; }
  get productDescription(): FormControl { return this.form.get('productDescription') as FormControl; }
  get providedServices(): FormControl { return this.form.get('providedServices') as FormControl; }
  get providedTo(): FormControl { return this.form.get('providedTo') as FormControl; }
  get delivered(): FormControl { return this.form.get('delivered') as FormControl; }
  get quantity(): FormControl { return this.form.get('quantity') as FormControl; }
  get remark(): FormControl { return this.form.get('remark') as FormControl; }
  get discount(): FormControl { return this.form.get('discount') as FormControl; }
  get discountAmountNet(): FormControl { return this.form.get('discountAmountNet') as FormControl; }
  get discountAmountGross(): FormControl { return this.form.get('discountAmountGross') as FormControl; }
  get addDiscount(): FormControl { return this.form.get('addDiscount') as FormControl; }
  get vat(): FormControl { return this.form.get('vat') as FormControl; }
  get productTypeControl(): FormControl { return this.form.get('productType') as FormControl; }
  get unitType(): FormControl { return this.form.get('unitType') as FormControl; }
  get correctionType(): FormControl { return this.form.get('correctionType') as FormControl; }
  get arbitraryPositionProductTypeControl(): FormControl { return this.form.get('arbitraryPositionProductType') as FormControl; }
  get specifyAmountsSeparately(): FormControl { return this.form.get('specifyAmountsSeparately') as FormControl; }
  get vatTotal(): FormControl { return this.form.get('vatTotal') as FormControl; }

}
