import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { get } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

import { ProductModel, ProductsService } from '../../../products';
import { getSerialNumberInfoHelper } from './exchange-add-product-modal-new.helper';
import { PricesInfoModel } from 'projects/workspace/src/app/trade-prices/models/prices-info.model';
import { ExchangeApiService } from 'projects/workspace/src/app/exchange/services/exchange-api.service';
import { TradePricesApiService } from 'projects/workspace/src/app/trade-prices/services/trade-prices-api.service';
import { ExchangeAuthorizationReasonEnum, ExchangeRequiredSolutionEnum } from 'projects/workspace/src/app/exchange/enums';
import {
  ExchangeNewPositionBodyModel,
  ExchangePositionModel,
  ExchangeReplacementProductPriceInfoModel,
  ExchangeSalesOrderProductModel
} from 'projects/workspace/src/app/exchange/models';
import { ProductTypes } from '../../../products/product-types';
import { ProductPriceModalComponent } from '../../modals-products';
import { CommonModalsActionsEnum } from '../../modals-common';
import { CustomSearchFn, getProductSearchValue } from '../../../rnpl-common/helpers';
import { ActionButtonsService } from '../../../../services/action-buttons.service';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';


@Component({
  selector: 'rnpl-exchange-add-product-modal',
  templateUrl: './exchange-add-product-modal-new.component.html',
  styleUrls: ['./exchange-add-product-modal-new.component.scss'],
})
export class ExchangeAddProductModalNewComponent implements OnInit, OnDestroy {

  public companyProfile: CompanyProfile;
  public enteredSerialNumber: string;
  public serialNumbers: string[] = [];

  public exchangeReasonEnum = ExchangeAuthorizationReasonEnum;
  public exchangePurposesEnum = ExchangeRequiredSolutionEnum;
  public productTypes = ProductTypes;

  public selectedDeliveredProduct: ExchangeSalesOrderProductModel = null;
  public selectedOrderedProduct: ProductModel = null;

  public selectedReason: ExchangeAuthorizationReasonEnum = null;
  public selectedPurpose: ExchangeRequiredSolutionEnum = ExchangeRequiredSolutionEnum.MONEY_RETURN;

  public specifySerialNumbers: boolean = false;
  public specifyRemark: boolean = false;
  public ignorePriceDifference: boolean = true;
  public invalidSerialNumber: boolean = false;
  public serialNumberAlreadyAdded: boolean = false;
  public quantity: number;
  public remark: string;

  public deliveredProductPrices = {
    netUnitPrice: null,
    vat: null,
    grossUnitPrice: null,
    netTotalPrice: null,
    grossTotalPrice: null,
  };

  public replacementProductPriceInfo: ExchangeReplacementProductPriceInfoModel = null;
  public availableProductsList: ExchangeSalesOrderProductModel[] = [];
  public activeGoodsProducts: ProductModel[] = [];

  public getSerialNumberInfo = getSerialNumberInfoHelper;

  public customSearchFn = CustomSearchFn;

  readonly showDropdownSpinAvailable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly showDropdownSpinActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    public dialogRef: MatDialogRef<ExchangeAddProductModalNewComponent>,
    public exchangeApiService: ExchangeApiService,
    public productsService: ProductsService,
    public pricesApiService: TradePricesApiService,
    public translateService: TranslateService,
    public dialog: MatDialog,
    private readonly actionButtonService: ActionButtonsService,
    private readonly store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: DataType
  ) {
    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((profile: CompanyProfile) => this.companyProfile = profile);
  }

  ngOnInit(): void {
    if (this.data.position) {
      this.selectedReason = this.data.position.reason;
      this.selectedPurpose = this.data.position.purpose;
      this.specifyRemark = this.data.position.addRemark;
      this.ignorePriceDifference = this.data.position.ignorePriceDifference;
      this.remark = this.data.position.remark;
      this.serialNumbers = this.data.position.units.map(unit => unit.serialNumber) || [];

      this.quantity = this.data.position.quantity;
      this.specifySerialNumbers = this.data.position.specifySerialNumbers;
    }
    this.getSalesOrderProducts();
    this.getActiveProducts();
  }

  public getSalesOrderProducts(): void {
    this.exchangeApiService.getExchangeSalesOrderProducts(this.data.exchangeId)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.showDropdownSpinAvailable$.next(false))
      )
      .subscribe((products: ExchangeSalesOrderProductModel[]) => {
        if (this.data.position && this.data.position.productId) {
          this.selectedDeliveredProduct = products.find(product => +product.id === +this.data.position.productId);

          if (this.selectedOrderedProduct && this.selectedDeliveredProduct) {
            this.replacementProductChanged();
          }
        }

        this.availableProductsList = this.prepareProductsList(products);
      });
  }

  public getActiveProducts(): void {
    this.productsService.getActiveProducts()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.showDropdownSpinActive$.next(false))
      )
      .subscribe((products: ProductModel[]) => {
        const availableProducts = products.filter((product: ProductModel) => product.type === ProductTypes.GOODS);

        if (this.data.position && this.data.position.replacementProductId) {
          this.selectedOrderedProduct = availableProducts.find(product => +product.id === +this.data.position.replacementProductId);

          if (this.selectedOrderedProduct && this.selectedDeliveredProduct) {
            this.replacementProductChanged();
          }
        }
        this.activeGoodsProducts = this.prepareActiveGoodsProducts(availableProducts);
        this.productChanged();
      });
  }

  public prepareProductsList(products): ExchangeSalesOrderProductModel[] {
    return products.map(item => ({
      ...item,
      searchLabel: `${item.name} ${item.orderedQuantity} ${item.shippedQuantity} ${item.returnedQuantity}`
    }));
  }

  public prepareActiveGoodsProducts(products): ProductModel[] {
    return products.map(item => ({
      ...item,
      searchLabel: getProductSearchValue(item, this.translateService)
    }));
  }

  public getProductPriceData(productId: number): void {
    this.pricesApiService.getProductSalesInfo(productId)
      .subscribe((response: PricesInfoModel) => {
        this.replacementProductPriceInfo = {
          ...this.replacementProductPriceInfo,
          available: response.stock_status.available
        };
      });
  }

  productChanged(clearQuantity = false): void {
    if (clearQuantity) {
      if (this.selectedDeliveredProduct) {
        this.specifySerialNumbers = this.selectedDeliveredProduct.serialNumbersAvailable;
      }

      this.quantity = this.specifySerialNumbers ? 0 : 1;
    }

    this.replacementProductChanged();

    if (
      this.selectedPurpose === ExchangeRequiredSolutionEnum.EXCHANGE &&
      this.selectedReason === ExchangeAuthorizationReasonEnum.DAMAGED_PRODUCT &&
      this.selectedDeliveredProduct &&
      !this.selectedOrderedProduct &&
      this.activeGoodsProducts
    ) {
      this.selectedOrderedProduct = this.activeGoodsProducts.find(product => +product.id === +this.selectedDeliveredProduct.id);
      if (this.selectedOrderedProduct) {
        this.getProductPriceData(+this.selectedOrderedProduct.id);
      }
    }
  }

  public replacementProductChanged(): void {
    if (get(this, 'selectedDeliveredProduct.soPositionId') && get(this, 'selectedOrderedProduct.id')) {
      this.exchangeApiService.getReplacementProductsPriceInfo(
        get(this, 'selectedDeliveredProduct.soPositionId'),
        get(this, 'selectedOrderedProduct.id'),
        this.quantity
      )
        .pipe(takeUntil(this.destroy$))
        .subscribe(res => {
          this.replacementProductPriceInfo = res;

          if (!this.replacementProductPriceInfo.sellingPriceExists) {
            this.openPriceModal();
          }
        });
    }
  }

  public openPriceModal(): void {
    this.productsService.getProduct(get(this, 'selectedOrderedProduct.id'))
      .subscribe((response: ProductModel) => {
        const dialog = this.dialog.open(ProductPriceModalComponent, {
          data: {
            product: response,
            productType: response.productType,
          },
          disableClose: true,
        });

        dialog.afterClosed().subscribe((res: CommonModalsActionsEnum) => {
          if (res === CommonModalsActionsEnum.CONFIRM) {
            this.replacementProductChanged();
          } else {
            this.selectedOrderedProduct = null;
            this.replacementProductPriceInfo = null;
          }
        });
      });
  }

  public addSerialNumber(): void {
    if (
        this.selectedDeliveredProduct &&
        (this.selectedDeliveredProduct.serialNumbersAvailable || get(this, 'data.position.specifySerialNumbers'))
    ) {
      if (
        this.selectedDeliveredProduct.serialNumbers.includes(this.enteredSerialNumber) ||
        (get(this, 'data.position.units') || []).some(unit => unit.serialNumber === this.enteredSerialNumber)
      ) {
        if (!this.serialNumbers.includes(this.enteredSerialNumber)) {
          this.serialNumbers.push(this.enteredSerialNumber);
          this.quantity = this.serialNumbers.length;
          this.enteredSerialNumber = null;
          this.replacementProductChanged();
        } else {
          this.serialNumberAlreadyAdded = true;
        }
      } else {
        this.invalidSerialNumber = true;
      }
    }
  }

  public removeSerialNumber(index: number): void {
    this.serialNumbers.splice(index, 1);
    this.quantity = this.serialNumbers.length;
    // this.replacementProductChanged();
  }

  public increaseQuantity(): void {
    if (this.maxQuantity && this.maxQuantity <= this.quantity) { return; }
    this.quantity = (this.quantity || 0) + 1;
    this.replacementProductChanged();
  }

  public decreaseQuantity(): void {
    if (!this.quantity) { return; }
    this.quantity = this.quantity - 1;
    this.replacementProductChanged();
  }

  private getPositionRequestBody(): ExchangeNewPositionBodyModel {
    return {
      productId: get(this, 'selectedDeliveredProduct.id'),
      soPositionId: get(this, 'selectedDeliveredProduct.soPositionId'),
      replacementProductId: get(this, 'selectedOrderedProduct.id'),
      quantity: this.quantity,
      specifySerialNumbers: this.specifySerialNumbers,
      serialNumbers: this.serialNumbers,
      ignorePriceDifference: this.ignorePriceDifference,
      reason: this.selectedReason,
      purpose: this.selectedPurpose,
      addRemark: this.specifyRemark,
      remark: this.remark,
      netUnitPrice: this.deliveredProductPrices.netTotalPrice,
      vat: this.deliveredProductPrices.vat,
      grossUnitPrice: this.deliveredProductPrices.grossUnitPrice,
      netTotalPrice: this.deliveredProductPrices.netTotalPrice,
      grossTotalPrice: this.deliveredProductPrices.grossTotalPrice,
    };
  }

  public submitProduct(): void {
    const request$ = this.data.position
      ? this.exchangeApiService.editExchangePosition(this.data.exchangeId, this.getPositionRequestBody(), this.data.position.id)
      : this.exchangeApiService.addExchangePosition(this.data.exchangeId, this.getPositionRequestBody());

    request$
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => this.dialogRef.close(res));
  }

  public barcodeDialogToggle(): void {
    this.actionButtonService.barcodeDialogToggle();
  }

  get maxQuantity(): number {
    if (this.selectedDeliveredProduct) {
      return this.selectedDeliveredProduct.shippedQuantity - this.selectedDeliveredProduct.returnedQuantity;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}


interface DataType {
  exchangeId: number;
  position?: ExchangePositionModel;
}
