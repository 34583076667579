import { createReducer, on } from '@ngrx/store';

import { CollectionCompanyProfileActions } from '../actions/action-types';
import { CountryModel } from 'common/src/modules/rnpl-common';
import { CorporatePartnerModel, WarehouseInfoModel } from '../../../crm/models';
import { CompanyProfile, LegalType, Title } from '../../models/company-profile.model';
import { Validation } from '../../../shared/models/response';
import { OnboardingModel } from '../../models/onboarding.model';
import { SubscriptionPlanEnum } from 'common/src/modules/subscription-activation/subscription-activation.emum';

export const CompanyProfileFeatureKey = 'companyProfileStore';

export interface CompanyProfileState {
  companyProfile: CompanyProfile;
  onboarding: OnboardingModel;
  // currentState: UIStatesEnum;
  countries: CountryModel[];
  warehouses: WarehouseInfoModel[];
  partners: CorporatePartnerModel[];
  loadingRequestsCount: number;
  saveAction: boolean;
  actionName: string;
  // saveActionDisabled: boolean;
  // saveActionDisabledAllowed: boolean;
  updatedAt: Date;
  legalTypes: LegalType[];
  titles: Title[];
  validations: Validation[];
  legalCountryTranslate: string;
}

export const initialCompanyProfileState: CompanyProfileState = {
  companyProfile: {} as CompanyProfile,
  onboarding: null,
  // currentState: UIStatesEnum.VIEW,
  countries: [],
  warehouses: [],
  partners: [],
  loadingRequestsCount: 0,
  saveAction: null,
  actionName: null,
  // saveActionDisabled: false,
  // saveActionDisabledAllowed: false,
  updatedAt: null,
  legalTypes: [],
  titles: [],
  validations: [],
  legalCountryTranslate: '',
};

export function CompanyProfileReducer(state, action) {
  return _CompanyProfileReducer(state, action);
}

const _CompanyProfileReducer = createReducer(
  initialCompanyProfileState,
  on(CollectionCompanyProfileActions.LoadCompanyProfile, (state, {companyProfile}) => ({
    ...state,
    companyProfile: setCompanyProfile(companyProfile)
  })),
  on(CollectionCompanyProfileActions.LoadLegalCountryTranslate, (state, {legalCountryTranslate}) => ({
    ...state,
    legalCountryTranslate
  })),
  on(CollectionCompanyProfileActions.LoadOnboarding, (state, {onboarding}) => ({
    ...state,
    onboarding
  })),
  on(CollectionCompanyProfileActions.UpdateCompanyProfile, (state, {companyProfile}) => ({
    ...state,
    companyProfile: setCompanyProfile(companyProfile)
  })),
  on(CollectionCompanyProfileActions.UpdateProfileUpdatedAt, (state, { updatedAt }) => ({
    ...state,
    updatedAt
  })),
  on(CollectionCompanyProfileActions.ClearProfileUpdatedAt, (state) => ({
    ...state,
    updatedAt: null
  })),
  on(CollectionCompanyProfileActions.LoadCompanyProfileCountries, (state, {countries}) => ({
    ...state,
    countries
  })),
  on(CollectionCompanyProfileActions.LoadLegalTypes, (state, {legalTypes}) => ({
    ...state,
    legalTypes
  })),
  on(CollectionCompanyProfileActions.UpdateValidations, (state, {validations}) => ({
    ...state,
    validations
  })),
  on(CollectionCompanyProfileActions.ResetValidations, (state) => ({
    ...state,
    validations: []
  })),
  on(CollectionCompanyProfileActions.LoadTitles, (state, {titles}) => ({
    ...state,
    titles
  })),
  on(CollectionCompanyProfileActions.IncrementLoadingRequestsCount, (state) => ({
    ...state,
    loadingRequestsCount: state.loadingRequestsCount + 1
  })),
  on(CollectionCompanyProfileActions.DecrementLoadingRequestsCount, (state) => ({
    ...state,
    loadingRequestsCount: state.loadingRequestsCount - 1
  })),
  // on(CollectionCompanyProfileActions.UpdateProfileCurrentState, (state, { currentState }) => ({
  //   ...state,
  //   currentState: currentState
  // })),
  on(CollectionCompanyProfileActions.UpdateSaveAction, (state, { saveAction }) => ({
    ...state,
    saveAction
  })),
  on(CollectionCompanyProfileActions.UpdateBtnToClearLoadingStatus, (state, { actionName }) => ({
    ...state,
    actionName
  })),
  // on(CollectionCompanyProfileActions.UpdateSaveActionDisabled, (state, { saveActionDisabled }) => ({
  //   ...state,
  //   saveActionDisabled
  // })),
  // on(CollectionCompanyProfileActions.UpdateSaveActionDisabledAllowed, (state, { saveActionDisabledAllowed }) => ({
  //   ...state,
  //   saveActionDisabledAllowed
  // })),
);

export function setCompanyProfile(profile: CompanyProfile): CompanyProfile {
  return {
    ...profile,
    hasWarehouse: (profile.subscriptionManagement.subscriptionPlanActive !== SubscriptionPlanEnum.FREE)
     && (profile.subscriptionManagement.subscriptionPlanActive !== SubscriptionPlanEnum.ACCOUNTING_MONTHLY)
     && (profile.subscriptionManagement.channelOptions.wholesalesEnabled || profile.subscriptionManagement.channelOptions.ecommerceEnabled),
  };
}
