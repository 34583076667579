import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { debounceTime } from 'rxjs/internal/operators';

import { DASHBOARDS_MONTHS, DASHBOARDS_PERIODS_LIST, DASHBOARDS_QUARTERS, DASHBOARDS_UDF_RANGES } from '../../constants';
import { selectCompanyProfile } from '../../../administration/store/selectors';
import { CompanyProfile } from '../../../administration/models/company-profile.model';
import { AppState } from '../../../store/state/app.state';
import { DashboardPeriodFormModel } from './dashboard-period-form.model';
import { getYearsList } from '../../helpers';

@Component({
  selector: 'rnpl-dashboard-period-form',
  templateUrl: './dashboard-period-form.component.html',
  styleUrls: ['./dashboard-period-form.component.scss']
})
export class DashboardPeriodFormComponent implements OnInit, OnChanges, OnDestroy {

  public form: FormGroup;
  public yearsList: {value: string, label: string}[] = [];
  // public companyProfile: CompanyProfile;
  // public wid: number;

  @Input() public initialSelectedRange: string = 'USER_DEFINED';
  @Input() public dashboardsPeriodsList = DASHBOARDS_PERIODS_LIST;
  @Input() public udfList: {value: string, label: string}[] = DASHBOARDS_UDF_RANGES;
  @Input() public monthsList: {value: number, label: string}[] = DASHBOARDS_MONTHS;
  @Input() public quartersList: {value: number, label: string}[] = DASHBOARDS_QUARTERS;

  @Output() formChanged: EventEmitter<DashboardPeriodFormModel> = new EventEmitter<DashboardPeriodFormModel>();

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private readonly fb: FormBuilder,
    private readonly store: Store<AppState>,
    // private readonly toasterService: ToasterService,
  ) {
  }

  ngOnInit(): void {
    this.initForm();

    this.store.select(selectCompanyProfile)
      .subscribe((companyProfile: CompanyProfile) => {
        if (!this.yearsList.length) {
          const currentYear = new Date(companyProfile.tracking.createdAt).getFullYear();
          const initialYear = currentYear < 2023
            ? 2023
            : currentYear;
          const list = getYearsList(initialYear);
          this.yearsList = this.prepareYearsList(list);
        }
      });

    this.outputValues();

    // this.store
    //   .select(selectCompanyProfile)
    //   .pipe(
    //     distinctUntilChanged(isEqual),
    //     takeUntil(this.destroy$)
    //   )
    //   .subscribe((profile: CompanyProfile) => {
    //     this.companyProfile = profile;
    //     this.wid = profile.workspaceId;
    //     this.outputValues();
    //   });
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  private prepareYearsList(years: string[]): {value: string, label: string}[] {
    const preparedList = years
      .reverse()
      .map((year: string) => ({ value: year, label: year}));
    // replace current year label
    preparedList[0].label = 'CASH_FLOW.CURRENT_YEAR';

    return preparedList;
  }

  public initForm(): void {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentQuarter = Math.floor((currentMonth / 3)) + 1;
    const currentYear = today.getFullYear();

    this.form = this.fb.group({
      selectedRange: [this.initialSelectedRange],
      udfRange: ['month'],
      month: [currentMonth],
      year: [currentYear.toString()],
      quarter: [currentQuarter],
      // groupBy: ['DAY'],
      // dateTo: [new Date()],
      // dateFrom: [],
    });

    // this.periodControl.valueChanges
    //   .pipe(
    //     startWith('month'),
    //     takeUntil(this.destroy$)
    //   )
    //   .subscribe((period: string) => {
    //     this.setDateFrom(period);
    //   });
    //
    // this.dateFromControl.valueChanges
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((value: Date) => {
    //     this.updateGroupBy(value, this.dateToControl.value);
    //   });
    //
    // this.dateToControl.valueChanges
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((value: Date) => {
    //     this.updateGroupBy(this.dateFromControl.value, value);
    //   });

    this.form.valueChanges
      .pipe(
        debounceTime(100),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.outputValues());
  }

  // public datePickerValueManuallyChanged(): void {
  //   // set 'custom-period' value to this.periodControl if one of dates changed manually
  //   this.periodControl.patchValue('custom-period', {emitEvent: false});
  // }

  public outputValues(): void {
    const formValue = this.form.getRawValue();
    // if (!formValue.dateTo || !formValue.dateFrom) {
    //   return;
    // }
    //
    // if (formValue.dateTo < formValue.dateFrom) {
    //   this.toasterService.notify({type: 'error', message: 'DASHBOARDS.DATE_ERROR'});
    //   return;
    // }
    //
    // if ((this.wid || this.wid === 0) && formValue) {
    //   // if (formValue.dateTo) {
    //     formValue.dateTo = this.dateToString(formValue.dateTo);
    //   // }
    //   // if (formValue.dateFrom) {
    //     formValue.dateFrom = this.dateToString(formValue.dateFrom);
    //   // }
      this.formChanged.emit(formValue);
    // }
  }

  // public updateGroupBy(dateFrom: Date, dateTo: Date): void {
  //   if ((this.periodControl.value === 'custom-period' || this.periodControl.value === 'total') && dateFrom && dateTo) {
  //     const daysBetween = daysBetweenDates(dateFrom, dateTo);
  //     switch(true) {
  //       case daysBetween < 30:
  //         this.groupByControl.patchValue('DAY');
  //         break;
  //       case daysBetween < 180:
  //         this.groupByControl.patchValue('WEEK');
  //         break;
  //       default:
  //         this.groupByControl.patchValue('MONTH');
  //     }
  //   }
  // }

  // public setDateFrom(period: string): void {
  //   const today = new Date();
  //   const todayDate = today.getDate();
  //   this.dateToControl.patchValue(today);
  //
  //   switch (period) {
  //     case 'week':
  //       this.dateFromControl.patchValue(new Date(new Date().setDate(todayDate - 7)));
  //       this.groupByControl.patchValue('DAY');
  //       break;
  //     case 'month':
  //       // this.dateFromControl.patchValue(new Date(new Date().setMonth(today.getMonth() - 1)));
  //       this.dateFromControl.patchValue(new Date(new Date().setDate(todayDate - 30)));
  //       this.groupByControl.patchValue('DAY');
  //       break;
  //     case 'quarter':
  //       // this.dateFromControl.patchValue(new Date(new Date().setMonth(today.getMonth() - 4)));
  //       this.dateFromControl.patchValue(new Date(new Date().setDate(todayDate - 90)));
  //       this.groupByControl.patchValue('WEEK');
  //       break;
  //     case 'half-year':
  //       // this.dateFromControl.patchValue(new Date(new Date().setMonth(today.getMonth() - 6)));
  //       this.dateFromControl.patchValue(new Date(new Date().setDate(todayDate - 180)));
  //       this.groupByControl.patchValue('MONTH');
  //       break;
  //     case 'year':
  //       this.dateFromControl.patchValue(new Date(new Date(new Date().setMonth(today.getMonth() - 12)).setDate(todayDate - 1)));
  //       // this.dateFromControl.patchValue(new Date(new Date().setDate(todayDate - 360)));
  //       this.groupByControl.patchValue('MONTH');
  //       break;
  //     case 'custom-period':
  //       this.dateFromControl.setValue(null);
  //       this.dateToControl.setValue(null);
  //       break;
  //     case 'total':
  //       this.dateFromControl.setValue(new Date(this.companyProfile.tracking.createdAt));
  //       this.updateGroupBy(this.dateFromControl.value, this.dateToControl.value);
  //       break;
  //   }
  // }

  // public dateToString(date: Date): string {
  //   return date.toISOString().substring(0,10);
  // }

  // get selectedRangeControl(): FormControl { return this.form.get('selectedRange') as FormControl; }
  get udfRangeControl(): FormControl { return this.form.get('udfRange') as FormControl; }
  get monthControl(): FormControl { return this.form.get('month') as FormControl; }
  get yearControl(): FormControl { return this.form.get('year') as FormControl; }
  get quarterControl(): FormControl { return this.form.get('quarter') as FormControl; }
  // get groupByControl(): FormControl { return this.form.get('groupBy') as FormControl; }
  // get dateToControl(): FormControl { return this.form.get('dateTo') as FormControl; }
  // get dateFromControl(): FormControl { return this.form.get('dateFrom') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
