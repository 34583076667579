import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'rnpl-blank-iin-modal',
  templateUrl: './blank-iin-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlankIinModalComponent {

  public separatePositions = false;

  constructor(
    public dialogRef: MatDialogRef<BlankIinModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      // todo
    }
  ) {
  }

  public rejectAction(): void {
    this.dialogRef.close(
      { queryParams: '?confirmed=true&matchDocument=false&blankMatching=true&separatePositions=false', parse: false }
    );
  }

  public confirmAction(): void {
    this.dialogRef.close(
      { queryParams: `?confirmed=true&matchDocument=true&blankMatching=true&separatePositions=${this.separatePositions}`, parse: true }
    );
  }

}
