import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ContextMenuModule } from 'ngx-contextmenu';

import { SideNavigationComponent } from './side-navigation.component';
import { RnplCommonModule } from 'common/src/modules';
import { SideNavigationProfileMenuModule } from '../side-navigation-profile-menu/side-navigation-profile-menu.module';
import { SideNavigationQuickActionsModule } from '../side-navigation-quick-actions/side-navigation-quick-actions.module';

@NgModule({
  declarations: [
    SideNavigationComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RnplCommonModule,
    RouterModule,
    SideNavigationProfileMenuModule,
    SideNavigationQuickActionsModule,
    PerfectScrollbarModule,
    ContextMenuModule,
  ],
  exports: [SideNavigationComponent],
  entryComponents: [SideNavigationComponent]
})
export class SideNavigationModule { }
