import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { WarehouseMainDropdownItem } from 'projects/workspace/src/app/warehouse/interfaces/warehouse-main-dropdown-item';
import { NavigationItemModel } from 'common/src/models';
import { SystemPreferencesPermissionsService } from '../../../services/system-preferences-permissions.service';

@Component({
  selector: 'rnpl-warehouse-selection',
  templateUrl: 'warehouse-selection.component.html',
  styleUrls: ['warehouse-selection.component.scss']
})
export class WarehouseSelectionComponent implements OnChanges, OnInit, OnDestroy {
  @Input()
  readonly warehouseListData: Array<WarehouseMainDropdownItem>;

  @Input()
  readonly isSingleItem: boolean = false;

  @Input()
  readonly selected: WarehouseMainDropdownItem;

  readonly unsubscriber$: Subject<void> = new Subject();

  displayWarehouseName: string;
  displayWarehouseNumber: string | number;
  warehouseNavItems: NavigationItemModel[];

  private activeLink: string;

  constructor(
    private readonly router: Router,
    private systemPreferencesPermissionsService: SystemPreferencesPermissionsService,
    ) {
  }

  selectWarehouse(item): void {
    if (item.runple_id !== this.displayWarehouseNumber) {
      this.displayWarehouseName = item.label;
      this.displayWarehouseNumber = item.runple_id;
      this.warehouseNavItems = this.getWarehouseNavItems(item.id);

      this.navigateToNewUrlAfterSelection(item.id);
    }
  }

  routNavigate(item): void {
    if (!item.disabled) {
      this.router.navigate([item.routerLink]);
    }
  }

  isActiveClass(item): boolean {
    if (!item.routerLink) { return; }
    const baseLink = item.routerLink.split('/', 3).join('/');

    return this.activeLink.includes(baseLink);
  }

  // private getWarehouseNavItems(id: number): NavigationItemModel[] {
  //   return [
  //     // {
  //     //   title: 'Inbound',
  //     //   disabled: false,
  //     //   icon: 'arrow-circle-down',
  //     //   routerLink: `/warehouse/${id}/inbound-board`
  //     // },
  //     {
  //       title: 'Incoming delivery',
  //       disabled: false,
  //       icon: 'arrow-circle-down',
  //       routerLink: `/warehouse/${id}/incoming-delivery`,
  //     },
  //     {
  //       title: 'Inhouse processes',
  //       icon: 'warehouse-process',
  //       disabled: true,
  //       routerLink: '',
  //     },
  //     {
  //       title: 'Outbound',
  //       icon: 'arrow-circle-up',
  //       disabled: true,
  //       routerLink: '',
  //     },
  //     {
  //       title: 'Stock status',
  //       icon: 'archive',
  //       disabled: true,
  //       routerLink: '',
  //     },
  //     {
  //       title: 'Parameters',
  //       icon: 'options-2',
  //       disabled: false,
  //       routerLink: `/warehouse/warehouse-profile/${id}/general-info`,
  //     },
  //   ];
  // }

  private getWarehouseNavItems(id: number): NavigationItemModel[] {
    return [
      {
        title: 'APP.STOCK_STATUS',
        icon: 'cubes',
        show: this.systemPreferencesPermissionsService.goodsProductTypeEnabled(),
        routerLink: '/warehouse/stock-status/general-stock/1',
      },
      {
        title: 'APP.INCOMING_DELIVERIES',
        icon: 'incoming-delivery',
        routerLink: '/warehouse/incoming-delivery',
      },
      {
        title: 'APP.OUTGOING_DELIVERIES',
        icon: 'outgoing-delivery',
        routerLink: '/warehouse/outgoing-delivery',
      },
      {
        title: 'STOCKTAKING.STOCKTAKING',
        icon: 'stocktaking',
        routerLink: '/warehouse/stocktaking',
      },
      {
        title: 'APP.TRANSACTIONS',
        icon: 'stocktransfer',
        routerLink: '/warehouse/transactions',
      },
      {
        title: 'APP.MANAGEMENT',
        icon: 'warehouse',
        routerLink: `/warehouse/warehouse-profile`,
      },
      {
        title: 'SETTINGS.WAREHOUSE_SETTINGS',
        icon: 'options-2',
        routerLink: '/warehouse/settings',
      },
      // {
      //   title: 'Warehouse dashboard',
      //   icon: 'dashboard',
      //   routerLink: '/warehouse/dashboard'
      // },
    ].filter(itm => {
      if (itm.hasOwnProperty('show')) {
        return itm.show;
      }
      return itm;
    });
  }

  private navigateToNewUrlAfterSelection(id: number): void {
    if (this.activeLink && this.selected.id !== id) {
      this.router.navigate([this.activeLink.replace(String(this.selected.id), String(id))]);
    }
  }

  ngOnInit(): void {
    this.activeLink = this.router.routerState.snapshot.url;
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        takeUntil(this.unsubscriber$)
      )
      .subscribe((data: NavigationEnd) => this.activeLink = data.url);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('selected') && changes.selected.currentValue) {
      this.selectWarehouse(changes.selected.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

}
