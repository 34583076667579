import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ProductsService } from 'common/src/modules/products/products.service';
import { ProductModel } from '../../../products';
import { CustomSearchFn } from '../../../rnpl-common/helpers';
import { ProductTypes } from '../../../products/product-types';
import { CommonModalsActionsEnum, WarningModalComponent } from '../../modals-common';

@Component({
  selector: 'rnpl-product-variation-modal',
  templateUrl: './product-variation-modal.component.html',
  styleUrls: ['./product-variation-modal.component.scss'],
})
export class ProductVariationModalComponent extends BaseModalComponent implements OnInit {

  public productsList: ProductModel[] = [];
  public selectedProducts: ProductModel[] = [];
  public selectedProductInvalid: boolean = false;
  public selectVariationManually: boolean = false;
  public customSearchFn = CustomSearchFn;

  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly submitRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    public toasterService: ToasterService,
    public productsService: ProductsService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ProductVariationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      productId: number,
      familyId: number,
      addedVariations: ProductModel[],
      productType: ProductTypes,
    }
  ) {
    super(toasterService);
  }

  ngOnInit() {
    this.showDropdownSpin$.next(true);
    this.getProducts();
  }

  public selectVariationManuallyChanged(): void {
    this.selectedProducts = [];
    this.productsList = [];
    this.showDropdownSpin$.next(true);
    this.getProducts();
  }

  public removeSelected(event: {position: ProductModel}): void {
    this.selectedProducts = this.selectedProducts.filter(p => p.id !== event.position.id);
  }

  public toggleSelected(checked: boolean, product: ProductModel): void {
    if (checked) {
      this.selectedProducts.push(product);
    } else {
      this.selectedProducts = this.selectedProducts.filter(p => p.id !== product.id);
    }
  }

  private getProducts(): void {
    const variationsOfProductId = this.isGoodsProductType && !this.selectVariationManually
      ? this.data.productId
      : null;
    this.productsService.getActiveProducts(undefined, false, null, variationsOfProductId)
      .pipe(takeUntil(this._destroy))
      .subscribe((products: ProductModel[]) => {
        this.productsList = products
          .filter((product: ProductModel) => product.type === this.data.productType)
          .filter((product: ProductModel) => this.data.productId !== product.id)
          .filter((product: ProductModel) => this.data.addedVariations.every(added => added.productId !== product.id))
          .map((item: ProductModel) => ({
            ...item,
            searchLabel: `${item.runple_id} ${item.name} ${item.description}`
          }));
        if (variationsOfProductId) {
          this.selectedProducts = this.productsList;
        }
        this.showDropdownSpin$.next(false);
      });
  }

  public submit(forceDifferentCategory = false): void {
    if (this.selectedProducts && !this.selectedProducts.length) {
      if (!this.selectVariationManually && this.isGoodsProductType) {
        this.toasterService.notify({
          type: 'error',
          message: 'POSITIONS.YOU_MUST_SELECT_A_PRODUCT'
        });
      } else {
        this.selectedProductInvalid = true;
      }
      return;
    }

    if (!forceDifferentCategory && this.selectedProducts.some(p => +p.family.id !== +this.data.familyId)) {
      const dialog = this.dialog.open(WarningModalComponent, {
        data: {
          title: 'PRODUCTS_VARIATIONS.ADD_VARIATION',
          message: 'PRODUCTS_VARIATIONS.DIFFERENT_CATEGORY_MSG',
          confirmBtnText: 'BUTTON.CONTINUE',
          confirmBtnIcon: 'arrow-right'
        }
      });

      dialog.afterClosed().subscribe(res => {
        if (res === CommonModalsActionsEnum.CONFIRM) {
          this.submit( true);
        }
      });
      return;
    }

    if (this.submitRequest$.getValue()) { return; }
    this.submitRequest$.next(true);

    this.productsService.addProductVariation(this.data.productId, this.selectedProducts.map(p => +p.id))
      .pipe(
        finalize(() => this.submitRequest$.next(false)),
        takeUntil(this._destroy)
      )
      .subscribe(() => {
        this.dialogRef.close(CommonModalsActionsEnum.CONFIRM);
      });
  }

  get isGoodsProductType(): boolean {
    return this.data.productType === ProductTypes.GOODS;
  }

}
