import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatExpansionModule, MatMenuModule } from '@angular/material';
import {
  IgxCalendarModule,
  IgxDatePickerModule,
  IgxFocusModule,
  IgxInputGroupModule,
  IgxMaskModule,
  IgxTimePickerModule
} from 'igniteui-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { StoreModule } from '@ngrx/store';
import { SafePipeModule } from 'safe-pipe';
import { TranslateModule } from '@ngx-translate/core';
import { NgxCurrencyModule } from 'ngx-currency-custom';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ContextMenuModule } from 'ngx-contextmenu';

import { RnplCommonModule } from 'common/src/modules/rnpl-common';
import { NavTabsComponent } from './nav-tabs/nav-tabs.component';
import { CardComponent } from './card/card.component';
import { FormCardComponent } from './form-card/form-card.component';
import { PageCardComponent } from './page-card/page-card.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { ProgressLineComponent } from './progress-line/progress-line.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { CopyrightComponent } from './copyright/copyright.component';
import { HeaderComponent } from './header/header.component';
import { ToasterComponent } from './toaster';
import { BreadcrumbsComponent } from './breadcrumbs';
import { ToggleAccordionComponent } from './toggle-accordion/toggle-accordion.component';
import { AccordionTreeComponent } from './accordion-tree/accordion-tree.component';
import { AccordionTreeLevelComponent } from './accordion-tree/accordion-tree-level.component';
import { CountryImagePipe, LangImagePipe } from './pipes/country-image.pipe';
import { NonAuthFooterComponent } from './non-auth-footer/non-auth-footer.component';
import { NonAuthHeaderComponent } from './non-auth-header/non-auth-header.component';
import { FormTooltipComponent } from './form-tooltip/form-tooltip.component';
import { IconTipComponent } from './icon-tip/icon-tip.component';
import { ViewSummaryComponent } from './view-summary/view-summary.component';
import { ViewSummaryWrapComponent } from './view-summary-wrap/view-summary-wrap.component';
import { ViewNavComponent } from './view-nav/view-nav.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { SettingsOutletComponent } from './settings-outlet/settings-outlet.component';
import { CustomTableComponent } from './table/custom-table.component';
import { ColumnPrimaryComponent } from './table/components/column-primary/column-primary.component';
import { ColumnWithCurrencyComponent } from './table/components/column-with-currency/column-with-currency.component';
import { ColumnWithDropdownComponent } from './table/components/column-with-dropdown/column-with-dropdown.component';
import { ColumnWithTypeStatusComponent } from './table/components/column-with-type-status/column-with-type-status.component';
import { ColumnWithArrowRightComponent } from './table/components/column-with-arrow-right/column-with-arrow-right.component';
import { ColumnWithBtnAddRemoveComponent } from './table/components/column-with-btn-add-remove/column-with-btn-add-remove.component';
import { ColumnWithInputComponent } from './table/components/column-witn-input/column-with-input.component';
import { ColumnWithAvataComponent } from './table/components/column-with-avatar/column-with-avata.component';
import { WarehouseSelectionComponent } from './warehouse-selection/warehouse-selection.component';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { DropdownInputComponent } from './dropdown-input/dropdown-input.component';
import { NavBarActionsComponent } from './nav-bar-actions/nav-bar-actions.component';
import { NavBarActionsNewComponent } from './nav-bar-actions-new/nav-bar-actions-new.component';
import { UsersCommonModule } from '../users/users-common.module';
import { ContextMenuComponent } from './table/components/context-menu/context-menu.component';
import { ProcessBoardComponent } from './process-board/process-board.component';
import { ProcessCardComponent } from './process-card/process-card.component';
import { BoardsPageComponent } from './boards-page/boards-page.component';
import { ProcessCardGroupComponent } from './process-card-group/process-card-group.component';
import { ColumnPlaceholderComponent } from './table/components/column-placeholder/column-placeholder.component';
import { ColumnWithFormInputComponent } from './table/components/column-with-form-input/column-with-form-input.component';
import { ColumnWithFormTextareaComponent } from './table/components/column-with-form-textarea/column-with-form-textarea.component';
import { SettingsTableComponent } from './settings-table/settings-table.component';
import { TableActionBarComponent } from './table-action-bar/table-action-bar.component';
import { TableSkeletonComponent } from './table-skeleton/table-skeleton.component';
import { ColumnWithCountryComponent } from './table/components/column-with-country/column-with-country.component';
import {
  ColumnWithCorporatePartnerComponent
} from './table/components/column-with-corporate-partner/column-with-corporate-partner.component';
import {
  ColumnWithAvatarArrowRightComponent
} from './table/components/column-with-avatar-arrow-right/column-with-avatar-arrow-right.component';
import {
  ColumnWithEnableDisableRowComponent
} from './table/components/column-with-enable-disable-row/column-with-enable-disable-row.component';
import {
  ColumnHeaderAddColumnButtonComponent
} from './table/components/column-header-add-column-button/column-header-add-column-button.component';
import {
  ColumnHeaderTextWithRemoveButtonComponent
} from './table/components/column-header-text-with-remove-button/column-header-text-with-remove-button.component';
import { InputSkeletonComponent } from './input-skeleton/input-skeleton.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { InfoListComponent } from './info-components/info-list/info-list.component';
import { InfoItemComponent } from './info-components/info-item/info-item.component';
import { ViewSummarySalesDataComponent } from './view-summary-sales-data/view-summary-sales-data.component';
import { ViewSummaryPurchaseDataComponent } from './view-summary-purchase-data/view-summary-purchase-data.component';
import { ViewSummaryOfferComponent } from './view-summary-offer/view-summary-offer.component';
import { WarehouseMenuComponent } from './warehouse-menu/warehouse-menu.component';
import { ViewSummaryDocumentComponent } from './view-summary-document/view-summary-document.component';
import { HeadNavigationComponent } from './head-navigation/head-navigation.component';
import { NavMenuItemsComponent } from './nav-menu-items/nav-menu-items.component';
import { ColumnWithDateComponent } from './table/components/column-with-date/column-with-date.component';
import { TableSummaryBarModule } from './table-summary-bar/table-summary-bar.module';
import { DocumentRidComponent } from './document-rid/document-rid.component';
import { DocumentStatusComponent } from './document-status/document-status.component';
import { DocumentPartnerInfoComponent } from './document-partner-info/document-partner-info.component';
import { DocumentSavedInfoComponent } from './document-saved-info/document-saved-info.component';
import { ButtonDropdownComponent } from './button-dropdown/button-dropdown.component';
import { ColumnWithProfitComponent } from './table/components/column-with-profit/column-with-profit.component';
import { StatusLineModule } from './status-line/status.line.module';
import { RnplSelectComponent } from './rnpl-select/rnpl-select.component';
import { InfoBlockComponent } from './info-block/info-block.component';
import * as fromAdministration from 'projects/workspace/src/app/administration/store/reducers';
import { ColumnLinkComponent } from './table/components/column-link/column-link.component';
import { ActionButtonsComponent } from './action-buttons/action-buttons.component';
import { SelectPartnerComponent } from './select-partner/select-partner.component';
import { LoaderWhiteModule } from './loader-white/loader-white.module';
import { ViewDocumentContainerComponent } from './view-document-container/view-document-container.component';
import { TableTotalInfoSkeletonComponent } from './table-total-info-skeleton/table-total-info-skeleton.component';
import { DocumentStepsComponent } from './document-steps/document-steps.component';
import { HeadNavigationProcessBasedComponent } from './head-navigation-process-based/head-navigation-process-based.component';
import { BottomNavigationComponent } from './bottom-navigation/bottom-navigation.component';
import { QuickActionsComponent } from './quick-actions/quick-actions.component';
import { ProfileMenuComponent } from './profile-menu/profile-menu.component';
import { TablePaginationComponent } from './table-pagination/table-pagination.component';
import { PositionCardSkeletonComponent } from './position-card-skeleton/position-card-skeleton.component';
import { LogoModule } from './logo/logo.module';
import { SelectedItemsBadgeComponent } from './selected-items-badge/selected-items-badge.component';
import { ColumnPrimaryWithNumberComponent } from './table/components/column-primary-with-number/column-primary-with-number.component';
import { NumberFormatPipeModule } from 'projects/workspace/src/app/shared/pipes/number-format-pipe/number-format-pipe.module';

const COMPONENT_LIST: Array<any> = [
  BreadcrumbsComponent,
  NavTabsComponent,
  CardComponent,
  FormCardComponent,
  PageCardComponent,
  ProgressBarComponent,
  ProgressLineComponent,
  NavBarComponent,
  CopyrightComponent,
  HeaderComponent,
  ProgressBarComponent,
  ToasterComponent,
  ToggleAccordionComponent,
  AccordionTreeComponent,
  AccordionTreeLevelComponent,
  NonAuthFooterComponent,
  NonAuthHeaderComponent,
  FormTooltipComponent,
  CountryImagePipe,
  LangImagePipe,
  IconTipComponent,
  ViewSummaryComponent,
  ViewSummaryWrapComponent,
  ViewNavComponent,
  ViewNavComponent,
  TimePickerComponent,
  SettingsOutletComponent,
  CustomTableComponent,
  ColumnWithAvatarArrowRightComponent,
  ColumnPrimaryComponent,
  ColumnPrimaryWithNumberComponent,
  ColumnWithDropdownComponent,
  ColumnWithTypeStatusComponent,
  ColumnWithArrowRightComponent,
  ColumnWithBtnAddRemoveComponent,
  ColumnWithInputComponent,
  ColumnWithAvataComponent,
  ColumnLinkComponent,
  ColumnWithEnableDisableRowComponent,
  ColumnHeaderAddColumnButtonComponent,
  ColumnWithFormInputComponent,
  ColumnWithFormTextareaComponent,
  ColumnWithCountryComponent,
  ColumnHeaderTextWithRemoveButtonComponent,
  ColumnWithCorporatePartnerComponent,
  WarehouseSelectionComponent,
  EmptyStateComponent,
  DropdownInputComponent,
  NavBarActionsComponent,
  ContextMenuComponent,
  ProcessBoardComponent,
  ProcessCardComponent,
  BoardsPageComponent,
  ProcessCardGroupComponent,
  ColumnPlaceholderComponent,
  NavBarActionsNewComponent,
  SettingsTableComponent,
  TableActionBarComponent,
  TableSkeletonComponent,
  InputSkeletonComponent,
  ColumnWithCurrencyComponent,
  DatePickerComponent,
  InfoListComponent,
  InfoItemComponent,
  ViewSummarySalesDataComponent,
  ViewSummaryPurchaseDataComponent,
  ViewSummaryOfferComponent,
  WarehouseMenuComponent,
  ViewSummaryDocumentComponent,
  HeadNavigationComponent,
  NavMenuItemsComponent,
  ColumnWithDateComponent,
  DocumentRidComponent,
  DocumentStatusComponent,
  DocumentPartnerInfoComponent,
  DocumentSavedInfoComponent,
  ButtonDropdownComponent,
  ColumnWithProfitComponent,
  RnplSelectComponent,
  InfoBlockComponent,
  ActionButtonsComponent,
  SelectPartnerComponent,
  ViewDocumentContainerComponent,
  TableTotalInfoSkeletonComponent,
  DocumentStepsComponent,
  HeadNavigationProcessBasedComponent,
  BottomNavigationComponent,
  QuickActionsComponent,
  ProfileMenuComponent,
  TablePaginationComponent,
  PositionCardSkeletonComponent,
  SelectedItemsBadgeComponent,
];

@NgModule({
  declarations: COMPONENT_LIST,
  exports: [
    ...COMPONENT_LIST,
    TableSummaryBarModule,
    StatusLineModule,
    LoaderWhiteModule
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    RnplCommonModule,
    LoaderWhiteModule,
    IgxTimePickerModule,
    FormsModule,
    IgxInputGroupModule,
    IgxMaskModule,
    NgxMaskModule,
    NgxDatatableModule,
    UsersCommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    IgxDatePickerModule,
    IgxCalendarModule,
    IgxFocusModule,
    TableSummaryBarModule,
    StatusLineModule,
    StoreModule.forFeature(fromAdministration.CompanyProfileFeatureKey, fromAdministration.CompanyProfileReducer),
    NgxCurrencyModule,
    SafePipeModule,
    MatExpansionModule,
    TranslateModule,
    PerfectScrollbarModule,
    DragDropModule,
    LogoModule,
    ContextMenuModule,
    NumberFormatPipeModule
  ]
})
export class UIComponentsModule {
}
