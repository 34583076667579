import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'rnpl-add-positions-remark',
  templateUrl: './add-positions-remark.component.html',
})
export class AddPositionsRemarkComponent {

  public addRemark: FormControl = new FormControl();
  public remark: FormControl = new FormControl();
  public isReadOnly: boolean = false;

  @Input('value') set remarkSetter({remark, addRemark}: any) {
    this.remark.setValue(remark);
    this.addRemark.setValue(addRemark);
  }

  @Input('isReadOnly') set remarkStateSetter(readOnly: boolean) {
    this.isReadOnly = readOnly;
    if (readOnly) {
      this.addRemark.disable();
      this.remark.disable();
    } else {
      this.addRemark.enable();
      this.remark.enable();
    }
  }

  @Input() public remarkId: string;
  @Input() public addRemarkId: string;

  @Output() fieldChanged: EventEmitter<any> = new EventEmitter<any>();

  public updateField(fieldValue: any, fieldName: string): void {
    this.fieldChanged.emit({fieldName, fieldValue});
  }

  // get remark(): FormControl { return this.form.get('remark') as FormControl; }
  // get addRemark(): FormControl { return this.form.get('addRemark') as FormControl; }
}
