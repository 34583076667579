import { NavBarBtnModel, UIStatesEnum } from 'common/src/models';
import { DeliveryNoteListTabsEnum } from '../../enums';
import { TranslateService } from '@ngx-translate/core';
import {
  ControlTypeEnum, InputDropdownTypeEnum,
  PositionInfoListModel,
  PositionItemTypeEnum
} from 'common/src/modules/position-card/position-card-models.model';

export function getPositionsButtons(state: UIStatesEnum): NavBarBtnModel[] {
  return [
    {
      classes: 'btn-white red',
      text: 'BUTTON.DELETE',
      multipleText: 'BUTTON.REMOVE',
      badgeClass: 'rnpl-badge--red',
      iconName: 'minus-circle',
      actionName: 'onDeletePositionsClick',
      multiple: false,
      showCondition: state === UIStatesEnum.EDIT || state === UIStatesEnum.CREATE
    },
    {
      classes: 'btn-white',
      text: 'BUTTON.UNPACK',
      multipleText: 'BUTTON.UNPACK',
      badgeClass: 'rnpl-badge--red',
      iconName: 'undo',
      actionName: 'onUnpackPositionsClick',
      multiple: false,
      showCondition: state === UIStatesEnum.PACKING
    }
  ].filter(btn => btn.showCondition);
}


export function generatePositionsCardsInfo(
  state: UIStatesEnum,
  deliveryNoteStatus: DeliveryNoteListTabsEnum,
  isLinkedDocument: boolean,
  translateService: TranslateService
): PositionInfoListModel[] {
  const cantEditColumn = (state === UIStatesEnum.VIEW);
  const translatedPcs = translateService.instant('FORM.PCS');

  let positionInfoList: PositionInfoListModel[] = [
    {
      label: 'FORM.DESCRIPTION',
      prop: 'productDescription',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.TEXTAREA,
      itemClass: 'col-12',
      isOptional: true,
      showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE],
    },
    // {
    //   label: 'COLUMN.BARCODE',
    //   prop: 'barcode',
    //   type: PositionItemTypeEnum.PRIMARY,
    // },
    {
      label: isLinkedDocument ? 'COLUMN.QUANTITY' : 'COLUMN.ORDERED',
      prop: 'ordered',
      unitType: true,
      type: PositionItemTypeEnum.NUMBER,
      valueClass: 'font-weight-bold',
      showWithDocumentStatus: [
        DeliveryNoteListTabsEnum.DRAFT,
        DeliveryNoteListTabsEnum.OPEN,
        DeliveryNoteListTabsEnum.PACKING,
        DeliveryNoteListTabsEnum.SHIPPED,
      ],
      showCondition: isLinkedDocument,
    },
    {
      label: 'COLUMN.QUANTITY',
      prop: 'ordered',
      unitType: true,
      type: PositionItemTypeEnum.NUMBER,
      valueClass: 'font-weight-bold',
      showWithDocumentStatus: [
        DeliveryNoteListTabsEnum.PACKING,
      ],
      showCondition: !isLinkedDocument,
    },
    {
      label: 'COLUMN.ORDERED',
      prop: 'ordered',
      unitType: true,
      type: cantEditColumn ? PositionItemTypeEnum.NUMBER : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.NUMBER,
      showCondition: !isLinkedDocument,
      showWithDocumentStatus: [
        DeliveryNoteListTabsEnum.DRAFT,
        DeliveryNoteListTabsEnum.OPEN,
      ],
    },
    {
      label: 'COLUMN.PACKED_UP',
      prop: 'packedUp',
      unitType: true,
      type: PositionItemTypeEnum.NUMBER,
      valueClass: 'font-weight-bold',
      showWithDocumentStatus: [
        DeliveryNoteListTabsEnum.SHIPPED,
      ],
    },
    {
      label: 'COLUMN.QUANTITY',
      prop: 'packedUp',
      unitType: true,
      type: PositionItemTypeEnum.NUMBER,
      valueClass: 'font-weight-bold',
      showWithDocumentStatus: [
        DeliveryNoteListTabsEnum.PACKED_UP,
      ],
    },
    {
      label: 'COLUMN.ON_STOCK',
      suffix: translatedPcs,
      type: PositionItemTypeEnum.STATUS_LINE,
      prop: 'availableTotal',
      showWithDocumentStatus: [
        // DeliveryNoteListTabsEnum.DRAFT,
        DeliveryNoteListTabsEnum.OPEN,
        DeliveryNoteListTabsEnum.PACKING,
      ],
    },
    {
      label: 'COLUMN.PACKED_UP',
      suffix: translatedPcs,
      type: PositionItemTypeEnum.STATUS_LINE,
      prop: 'packedUpTotal',
      showWithDocumentStatus: [
        DeliveryNoteListTabsEnum.PACKING,
      ],
    },
    // {
    //   label: 'COLUMN.OPEN',
    //   prop: 'opened',
    //   unitType: true,
    //   type: PositionItemTypeEnum.PRIMARY,
    //   valueClass: 'font-weight-bold',
    //   showWithDocumentStatus: [DeliveryNoteListTabsEnum.PACKING],
    //   showCondition: isLinkedDocument,
    // },
    {
      label: 'COLUMN.QUANTITY',
      prop: 'quantity',
      unitType: 'unitType',
      type: cantEditColumn ? PositionItemTypeEnum.NUMBER : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.NUMBER,
      showWithDocumentStatus: [
        DeliveryNoteListTabsEnum.CANCELED,
        DeliveryNoteListTabsEnum.DELETED,
      ],
      showWithUIState: [UIStatesEnum.VIEW]
    },
    {
      label: 'COLUMN.AMOUNT',
      prop: 'amount',
      type: PositionItemTypeEnum.CURRENCY,
      suffix: '€',
      valueClass: 'font-weight-bold',
      showWithDocumentStatus: [
        DeliveryNoteListTabsEnum.PACKING,
        DeliveryNoteListTabsEnum.SHIPPED,
        DeliveryNoteListTabsEnum.PACKED_UP,
        DeliveryNoteListTabsEnum.CANCELED,
        DeliveryNoteListTabsEnum.DELETED,
      ],
      showCondition: isLinkedDocument,
      showWithUIState: [UIStatesEnum.VIEW]
    },
    {
      label: 'COMMON.INTERNAL_REMARK_1',
      prop: 'remark',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.REMARK,
      bindValue: 'value',
      remarkBlockClass: 'mt-8'
    },
  ];

  return positionInfoList
    .filter(itm => itm.hasOwnProperty('showCondition') ? itm.showCondition : true)
    .filter((col: PositionInfoListModel) => col.showWithUIState ? col.showWithUIState.includes(state) : true)
    .filter((col: PositionInfoListModel) => col.showWithDocumentStatus ? col.showWithDocumentStatus.includes(deliveryNoteStatus) : true);
}
