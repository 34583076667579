import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { Location } from '@angular/common';
import { finalize, startWith, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { FamilyModel } from 'common/src/modules/products/models';
import { ProductsService } from 'common/src/modules/products/products.service';
import { ProductTypes } from 'common/src/modules/products/product-types';
import { ImageModel } from '../../../../models';
import { FamilyWizardContext } from 'common/src/modules/products/family-wizard';
import { WizardService } from 'common/src/modules/wizard/wizard.service';
import { ProductIdentificationEnum } from 'projects/workspace/src/app/products-two-level/enums/product-identification.enum';

type DataType = {
  family: FamilyModel,
  productType: ProductTypes
};

@Component({
  selector: 'rnpl-position-modal',
  templateUrl: './composite-product-modal.component.html',
  styleUrls: ['./composite-product-modal.component.scss'],
})
export class CompositeProductModalComponent extends BaseModalComponent implements OnInit, OnDestroy {

  public modalTitle: {[key: string]: string} = {
    [ProductTypes.GOODS]: 'BUTTON.CREATE_PRODUCT',
    [ProductTypes.SERVICES]: 'BUTTON.CREATE_SERVICE',
    [ProductTypes.DIGITAL]: 'BUTTON.CREATE_DGT_PRODUCT',
  };

  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly blueprintLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public family: FamilyModel;
  public familiesTree: Array<FamilyModel>;
  public flatFamilies: FamilyModel[] = [];
  public selectedFamily: FamilyModel = null;
  public productTypes = ProductTypes;
  public specifyProductInformationManually: boolean = true;
  // public isProductSpecVisible: boolean = false;
  public productIdentificationEnum: typeof ProductIdentificationEnum = ProductIdentificationEnum;
  public productIdentification: ProductIdentificationEnum = null;
  public manufacturer: string = null;
  public productCode: string = null;

  public controls: any = [];
  // public glForm: FormGroup = new FormGroup({});
  public form: FormGroup = new FormGroup({});
  public displayProductErrors: boolean = false;
  public productImages: Array<ImageModel> = [];

  public counterAnimation: boolean = false;
  public isDisableForm: boolean = true;

  readonly submitProductRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  @ViewChild('nameForm', {static: true}) nameFormRef;
  @ViewChild('hardcodedForm', {static: true}) hardcodedFormRef;
  @ViewChild('barcodeForm', {static: true}) barcodeFormRef;
  @ViewChild('preferencesComponent', {static: true}) preferencesComponentFormRef;

  constructor(
    public location: Location,
    public fb: FormBuilder,
    public wizardService: WizardService,
    public toasterService: ToasterService,
    public productsService: ProductsService,
    public dialogRef: MatDialogRef<CompositeProductModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataType
  ) {
    super(toasterService);

    setInterval(() => {
      this.displayHighlightingAnimation();
    }, 1200);
  }

  ngOnInit() {
    this.showDropdownSpin$.next(true);
    this.initFormData();

    const requestParams = new HttpParams().set('products_type', this.productType.replace('-','_'));

    this.productsService.getFamiliesTree(requestParams)
      .pipe(takeUntil(this._destroy))
      .subscribe(response => {
        this.familiesTree = response.data;
        this.flatFamilies =  this.convertTreeToFlat(this.familiesTree, 'id');
        this.showDropdownSpin$.next(false);
      });
  }

  public displayHighlightingAnimation(): void {
    this.counterAnimation = !this.counterAnimation;
  }

  public initFormData(): void {
    // this.glForm = this.fb.group({
    //   glProductTypeId: [],
    //   usedUpByDefault: [],
    //   glAccountIdPurchaseDebit: [],
    //   glAccountIdPurchaseCredit: [],
    //   glAccountWriteOffDebit: [],
    //   longTermPeriodOfUse: [false],
    //   fundsWriteOffMethod: [null, Validators.required],
    //   periodOfUse: [2, [Validators.required, Validators.min(1)]],
    //   fundsWriteOffRate: [null, [Validators.required, Validators.min(0.01), Validators.max(99.99)]],
    // });
    //
    // this.glForm.valueChanges
    //   .pipe(
    //     startWith(this.glForm.getRawValue()),
    //     takeUntil(this.destroy$)
    //   )
    //   .subscribe(() => {
    //     if (this.glForm.getRawValue().glProductTypeId) {
    //       this.glAccountIdPurchaseDebitControl.enable({emitEvent: false});
    //       this.glAccountWriteOffDebitControl.enable({emitEvent: false});
    //       this.periodOfUseControl.enable({emitEvent: false});
    //     } else {
    //       this.glAccountIdPurchaseDebitControl.disable({emitEvent: false});
    //       this.glAccountWriteOffDebitControl.disable({emitEvent: false});
    //       this.periodOfUseControl.disable({emitEvent: false});
    //     }
    //
    //     if (this.glForm.getRawValue().longTermPeriodOfUse) {
    //       this.usedUpByDefaultControl.disable({emitEvent: false});
    //     } else if (this.glForm.getRawValue().glProductTypeId) {
    //       this.usedUpByDefaultControl.enable({emitEvent: false});
    //     }
    //
    //     if (this.glForm.getRawValue().usedUpByDefault) {
    //       this.longTermPeriodOfUseControl.disable({emitEvent: false});
    //     } else if (this.glForm.getRawValue().glProductTypeId) {
    //       this.longTermPeriodOfUseControl.enable({emitEvent: false});
    //     }
    //   });

    if (this.data.family) { // true when new product creates
      this.family = this.data.family;
      // this.patchGlForm(this.data.family);
      this.selectedFamily = this.data.family;

      this.getProductBlueprint(this.selectedFamily.id);
    }
  }

  // private patchGlForm(family: FamilyModel): void {
  //   this.glProductTypeIdControl.patchValue(family.gl_product_type_id);
  //   this.glAccountIdPurchaseDebitControl.patchValue(family.gl_account_id_purchase_debit);
  //   this.glAccountIdPurchaseCreditControl.patchValue(family.gl_account_id_purchase_credit);
  //   this.glAccountWriteOffDebitControl.patchValue(family.gl_account_write_off_debit);
  //   this.periodOfUseControl.patchValue(family.period_of_use);
  //   this.longTermPeriodOfUseControl.patchValue(family.long_term_period_of_use);
  //   this.fundsWriteOffMethodControl.patchValue(family.funds_write_off_method);
  //   this.fundsWriteOffRateControl.patchValue(family.funds_write_off_rate);
  // }

  private convertTreeToFlat(tree: FamilyModel[], idsPropName: string): FamilyModel[] {
    if (!tree) { return; }

    const flatArray = [];
    const getTreeItems = (branch) => {
      branch.map(node => {
        if (!node.product_locked_at) {
          flatArray.push(node);
        }

        if (node.children.length) {
          getTreeItems(node.children);
        }
      });
    };

    getTreeItems(tree);

    return flatArray;
  }

  public getProductBlueprint(familyId: string = this.family.id): void {
    if (!!familyId) {
      this.enableForms();
    } else {
      this.disableForms();
    }

    this.clearForms();
    // this.patchGlForm(this.selectedFamily);

    this.blueprintLoading$.next(true);
    this.form = new FormGroup({});
    this.controls = [];
    this.productsService.getProductBlueprint(this.productTypeFormKey, familyId)
      .pipe(takeUntil(this._destroy))
      .subscribe((response) => {
        this.controls = response.map(control => {
          control.properties = {label: control.label};
          const optionsRule = control.rules.find(rule => rule.type === 'options');
          if (optionsRule && optionsRule.list) {
            optionsRule.options = optionsRule.list.map(option => {
              return {label: option.eng, value: option.eng};
            });
          }
          return control;
        });
        this.blueprintLoading$.next(false);
      });
  }

  public clearForms(): void {
    this.nameFormRef.initForm();
    this.hardcodedFormRef.initForm();
    this.barcodeFormRef.initForm();
    this.preferencesComponentFormRef.initForm();
    this.productImages = [];
  }

  public disableForms(): void {
    this.isDisableForm = true;
    // this.glForm.disable();
    // this.nameFormRef.disableForm();
    // this.hardcodedFormRef.disableForm();
    // this.barcodeFormRef.disableForm();
    // this.preferencesComponentFormRef.disableForm();
  }

  public enableForms(): void {
    this.isDisableForm = false;
    // this.glForm.enable();
    // this.nameFormRef.enableForm();
    // this.hardcodedFormRef.enableForm();
    // this.barcodeFormRef.enableForm();
    // this.preferencesComponentFormRef.enableForm();
  }

  public submitProduct(): void {
    if (this.submitProductRequest$.getValue()) { return; }
    this.submitProductRequest$.next(true);

    if (!this.selectedFamily) {
      this.displayMessage('error', 'MESSAGES.CATEGORY_MUST_BE_FILLED');
      this.submitProductRequest$.next(false);
      return;
    }

    if (this.form.invalid) {
      this.displayProductErrors = true;
      this.displayMessage('error', 'MESSAGES.REQUIRED_FIELDS_MUST_BE_FILLED');
      this.submitProductRequest$.next(false);
      return;
    }

    const images = this.productImages.filter((image: ImageModel) => !!image)
      .map((image: ImageModel) => {
        return {id: image.apiModel.id};
      });

    const productData = {
      specifyProductInformationManually: false, // todo: hardcoded (remove after product details integration)
      productIdentification: this.productIdentification,
      manufacturer: this.manufacturer,
      productCode: this.productCode,
      general_products_model: {
        family_id: this.selectedFamily.id,
        container: {}
      },
      container: this.form.getRawValue(),
      // ...this.glForm.getRawValue(),
      ...this.nameFormRef.getFormData(),
      ...this.hardcodedFormRef.getFormData(),
      ...this.barcodeFormRef.getFormData(),
      ...this.preferencesComponentFormRef.getFormData(),
      images: images
    };

    this.productsService.createCompositeProduct(+this.selectedFamily.id, this.productType, productData)
      .pipe(
        finalize(() => { this.submitProductRequest$.next(false); }),
        takeUntil(this._destroy))
      .subscribe(response => {
        this.dialogRef.close(response);
        this.displayMessage('success', 'SUCCESS_CREATED.PRODUCT');
      }, (error) => {
        this.displayMessage('error', error.error.message);
      });
  }

  public setProductImages(images): void {
    this.productImages = images;
  }

  public setupFormLayout(): void {
    this.wizardService.edit<FamilyWizardContext>('family',
      { family: this.selectedFamily },
      this.location.path(),
    );

    this.dialogRef.close();
  }

  // public closeRightBlock(): void {
  //   this.isProductSpecVisible = false;
  // }
  //
  // public openRightBlock(): void {
  //   this.isProductSpecVisible = true;
  // }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get productTypeFormKey(): string {
    return `product_${this.productType}_create`;
  }

  get productType(): ProductTypes { return this.data.productType; }

  // get glProductTypeIdControl(): FormControl { return this.glForm.get('glProductTypeId') as FormControl; }
  // get glAccountIdPurchaseDebitControl(): FormControl { return this.glForm.get('glAccountIdPurchaseDebit') as FormControl; }
  // get glAccountIdPurchaseCreditControl(): FormControl { return this.glForm.get('glAccountIdPurchaseCredit') as FormControl; }
  // get glAccountWriteOffDebitControl(): FormControl { return this.glForm.get('glAccountWriteOffDebit') as FormControl; }
  // get usedUpByDefaultControl(): FormControl { return this.glForm.get('usedUpByDefault') as FormControl; }
  // get longTermPeriodOfUseControl(): FormControl { return this.glForm.get('longTermPeriodOfUse') as FormControl; }
  // get fundsWriteOffMethodControl(): FormControl { return this.glForm.get('fundsWriteOffMethod') as FormControl; }
  // get periodOfUseByDefaultControl(): FormControl { return this.glForm.get('periodOfUse') as FormControl; }
  // get fundsWriteOffRateControl(): FormControl { return this.glForm.get('fundsWriteOffRate') as FormControl; }

}
