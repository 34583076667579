import { ProductTypes } from 'common/src/modules/products/product-types';
import { GradientColor } from 'common/src/models';

export function getHomePageMenuItems(
  canViewAccounting: boolean,
  canViewAdministration: boolean,
  canViewAnalytics: boolean,
  canViewEcommerce: boolean,
  canViewPartners: boolean,
  canViewProducts: boolean,
  canViewRetail: boolean,
  canViewServices: boolean,
  canViewTeam: boolean,
  canViewWarehouse: boolean,
  canViewWholesale: boolean,
  wholesaleEnabled: boolean,
  ecommerceEnabled: boolean,
  servicesEnabled: boolean,
  goodsProductTypeEnabled: boolean,
  servicesProductTypeEnabled: boolean,
  digitalProductTypeEnabled: boolean,
  corporatePartnersEnabled: boolean,
  privatePartnersEnabled: boolean,
  canManageWarehouse: boolean,
  canManageProducts: boolean,
  canManagePartners: boolean,
  canManageAccounting: boolean,
  canManageEcommerce: boolean,
  canManageWholesale: boolean,
  canManageServices: boolean,
  isFreeOrAccountingPlan: boolean,
  isMasterWorkspace: boolean,
): any [] {

  const productTypeRedirect = goodsProductTypeEnabled
    ? ProductTypes.GOODS
    : servicesProductTypeEnabled
      ? ProductTypes.SERVICES
      : ProductTypes.DIGITAL;

  const ECOMMERCE_ITEMS = {
    title: 'MODULE.E_COMMERCE',
    icon: 'shopping-cart',
    show: canViewEcommerce && ecommerceEnabled,
    color: GradientColor.RED,
    children: [
      {
        title: 'ECOMMERCE.ORDERS',
        icon: 'sales-order',
        show: canViewEcommerce,
        routerLink: `/e-commerce/orders`,
      },
      {
        title: 'APP.PRODUCTS',
        icon: 'box',
        show: canViewEcommerce,
        routerLink: `/e-commerce/products/${ProductTypes.GOODS}`,
      },
      {
        title: 'APP.PRICES',
        show: canViewEcommerce,
        icon: 'pricetag',
        // routerLink: `/e-commerce/prices/${productTypeRedirect}`,
        routerLink: `/e-commerce/prices/${ProductTypes.GOODS}`,
        disabled: false
      },
      {
        title: 'APP.CORPORATE_CUSTOMERS',
        show: canViewEcommerce && corporatePartnersEnabled,
        icon: 'factory',
        routerLink: `/e-commerce/customers/corporate`,
      },
      {
        title: 'APP.PRIVATE_CUSTOMERS',
        show: canViewEcommerce && privatePartnersEnabled,
        icon: 'person',
        routerLink: `/e-commerce/customers/private`,
      },
      {
        title: 'APP.SETTINGS',
        show: canViewEcommerce && canManageEcommerce,
        icon: 'options-2',
        routerLink: `/e-commerce/settings`,
      },
    ]
  };

  const WAREHOUSE_ITEMS = {
    title: 'MODULE.WAREHOUSE',
    icon: 'warehouse',
    show: canViewWarehouse && (wholesaleEnabled || ecommerceEnabled),
    color: GradientColor.ORANGE,
    children: [
      {
        title: 'APP.STOCK_STATUS',
        show: goodsProductTypeEnabled,
        icon: 'cubes',
        routerLink: '/warehouse/stock-status/general-stock/1',
      },
      {
        title: 'APP.INCOMING_DELIVERIES',
        icon: 'incoming-delivery',
        routerLink: '/warehouse/incoming-delivery',
      },
      {
        title: 'APP.OUTGOING_DELIVERIES',
        icon: 'outgoing-delivery',
        routerLink: '/warehouse/outgoing-delivery',
        action: 'createNewDeliveryNote'
      },
      {
        title: 'APP.TRANSACTIONS',
        icon: 'stocktransfer',
        routerLink: '/warehouse/transactions',
        action: 'createTransaction'
      },
      {
        title: 'Stocktaking',
        icon: 'stocktaking',
        routerLink: '/warehouse/stocktaking',
      },
      {
        title: 'WAREHOUSE.WAREHOUSE_MANAGEMENT',
        icon: 'warehouse',
        routerLink: '/warehouse',
      },
      {
        title: 'APP.SETTINGS',
        show: canManageWarehouse,
        icon: 'options-2',
        routerLink: '/warehouse/settings'
      }
    ]
  };

  const TRADE_ITEMS = {
    title: 'MODULE.TRADE',
    icon: 'cash',
    show: (canViewWholesale || canViewEcommerce || canViewServices) && (wholesaleEnabled || ecommerceEnabled || servicesEnabled || isFreeOrAccountingPlan),
    // show: canViewWholesale || canViewServices,
    color: GradientColor.YELLOW,
    children: [
      {
        title: 'APP.OFFERS',
        show: canViewWholesale && (wholesaleEnabled || isFreeOrAccountingPlan || (servicesEnabled && !wholesaleEnabled && !ecommerceEnabled)),
        icon: 'offer',
        routerLink: '/trade/offers',
        disabled: false,
        action: 'createNewOffer'
      },
      {
        title: 'APP.SALES_ORDERS',
        show: canViewWholesale && wholesaleEnabled,
        icon: 'sales-order',
        routerLink: '/trade/sales-order',
        disabled: false,
        action: 'createNewOrder'
      },
      {
        title: 'APP.PURCHASE_ORDERS',
        show: (canViewWholesale || canViewEcommerce) && (wholesaleEnabled || ecommerceEnabled),
        // show: (canViewWholesale || canViewServices) && (wholesaleEnabled || servicesEnabled),
        icon: 'purchase-order',
        routerLink: '/trade/purchase-order',
        disabled: false,
        action: 'createNewPurchaseOrder'
      },
      {
        title: 'APP.PRICES',
        show: (canViewWholesale || canViewServices) && (wholesaleEnabled  || servicesEnabled || isFreeOrAccountingPlan)
          || (canViewWholesale || canViewEcommerce || canViewServices) && (wholesaleEnabled || ecommerceEnabled  || servicesEnabled),
        // show: (canViewWholesale || canViewServices) && (wholesaleEnabled || servicesEnabled),
        icon: 'pricetag',
        routerLink: (canViewWholesale || canViewServices) && (wholesaleEnabled  || servicesEnabled || isFreeOrAccountingPlan)
          ? `/trade/prices/sales/${productTypeRedirect}`
          : `/trade/prices/purchase/${productTypeRedirect}`,
        disabled: false
      },
      // {
      //   title: 'PRICE.SALES_PRICES',
      //   show: (canViewWholesale || canViewServices) && (wholesaleEnabled  || servicesEnabled || isFreeOrAccountingPlan),
      //   // show: (canViewWholesale || canViewServices) && (wholesaleEnabled || servicesEnabled),
      //   icon: 'pricetag',
      //   routerLink: `/trade/prices/sales/${productTypeRedirect}`,
      //   disabled: false
      // },
      // {
      //   title: 'PRICE.PURCHASE_PRICES',
      //   show: (canViewWholesale || canViewEcommerce || canViewServices) && (wholesaleEnabled || ecommerceEnabled  || servicesEnabled),
      //   icon: 'pricetag',
      //   routerLink: `/trade/prices/purchase/${productTypeRedirect}`,
      //   disabled: false
      // },
      {
        title: 'APP.RETURNS',
        show: canViewWholesale && (wholesaleEnabled || ecommerceEnabled),
        // show: canViewWholesale && wholesaleEnabled,
        icon: 'exchange-return',
        routerLink: '/trade/exchanges-and-returns',
        disabled: false,
        // locked: true,
        // action: 'createNewERA',
      },
      {
        title: 'APP.SETTINGS',
        show: canManageWholesale && (wholesaleEnabled || isFreeOrAccountingPlan || (servicesEnabled && !wholesaleEnabled && !ecommerceEnabled)),
        icon: 'options-2',
        routerLink: '/trade/settings'
      }
    ]
  };

  const SERVICES_ITEMS = {
    title: 'MODULE.SERVICES',
    icon: 'checklist',
    show: canViewServices && servicesEnabled,
    color: 'green',
    children: [
      {
        title: 'APP.TIME_TRACKING',
        icon: 'timer',
        routerLink: '/services/time-tracking',
        disabled: false,
        action: 'onLogTimeClick',
      },
      {
        title: 'APP.SUBSCRIPTIONS',
        icon: 'subscriptions',
        routerLink: '/services/subscription',
        disabled: false,
        action: 'createNewSubscription'
      },
      {
        title: 'APP.SETTINGS',
        show: canManageServices && servicesEnabled,
        icon: 'options-2',
        routerLink: '/services/settings'
      }
    ]
  };

  const ACCOUNTING_ITEMS = {
    title: 'MODULE.ACCOUNTING',
    icon: 'calculator',
    show: canViewAccounting,
    color: GradientColor.VIOLET,
    children: [
      {
        title: 'APP.OUTGOING_INVOICES',
        icon: 'outgoing-invoice',
        routerLink: '/accounting/outgoing-invoice',
        disabled: false,
        action: 'createNewInvoice'
      },
      {
        title: 'APP.INCOMING_INVOICES',
        icon: 'incoming-invoice',
        routerLink: '/accounting/incoming-invoice',
        disabled: false,
        action: 'createNewIncomingInvoice'
      },
      {
        title: 'APP.PAYMENTS',
        icon: 'payment',
        routerLink: '/accounting/payments',
        disabled: false
      },
      {
        title: 'SCHEDULED_PAYMENT.SCHEDULED_PAYMENTS',
        icon: 'recurring-costs',
        routerLink: '/accounting/scheduled-payment',
        disabled: false,
        action: 'createNewScheduledPayment'
      },
      {
        title: 'APP.PAYMENTS_CALENDAR',
        icon: 'payments-calendar',
        routerLink: '/accounting/payments-calendar',
        disabled: false
      },
      {
        title: 'APP.CREDIT_NOTES',
        icon: 'credit-note',
        routerLink: '/accounting/credit-note',
        disabled: false,
        action: 'createNewCreditNote'
      },
      {
        title: 'APP.OPEN_ITEM_LIST',
        icon: 'open-items-list',
        routerLink: '/accounting/open-items',
        disabled: false
      },
      {
        title: 'REPORTING_PERIODS.REPORTING_PERIODS',
        icon: 'accountant-report',
        routerLink: '/accounting/reporting-periods',
        disabled: false
      },
      {
        title: 'APP.BANK_ACCOUNTS',
        icon: 'account',
        routerLink: '/accounting/bank-accounts',
        disabled: false,
        action: 'createBankAccount'
      },
      {
        title: 'GL_ACCOUNTS.LEDGER_ACCOUNTS',
        icon: 'gl-accounts',
        routerLink: '/accounting/gl-accounts',
        disabled: false,
      },
      {
        title: 'FIXED_ASSETS.ASSETS_DIRECTORY',
        icon: 'fixed-assets',
        routerLink: '/accounting/fixed-assets',
        disabled: false,
      },
      {
        title: 'APP.SETTINGS',
        show: canManageAccounting,
        icon: 'options-2',
        routerLink: '/accounting/settings'
      }
    ]
  };

  const PRODUCT_ITEMS = {
    title: 'MODULE.PRODUCTS',
    icon: 'box',
    show: canViewProducts,
    color: GradientColor.PURPLE,
    children: [
      {
        title: 'COMMON.GOODS',
        icon: 'box',
        show: goodsProductTypeEnabled,
        routerLink: `/products/${ProductTypes.GOODS}`,
        disabled: false,
        action: 'createNewProduct'
      },
      {
        title: 'APP.SERVICES',
        icon: 'checklist',
        show: servicesProductTypeEnabled,
        routerLink: `/products/${ProductTypes.SERVICES}`,
        disabled: false,
        action: 'createNewService'
      },
      {
        title: 'APP.DIGITAL_PRODUCTS',
        icon: 'digital-product',
        show: digitalProductTypeEnabled,
        routerLink: `/products/${ProductTypes.DIGITAL}`,
        disabled: false,
        action: 'createNewDigitalProduct'
      },
      {
        title: 'GL_ACCOUNTS.GENERAL_PRODUCTS',
        icon: 'dashboard',
        routerLink: `/products/general-products`,
        disabled: false,
      },
      {
        title: 'APP.CATEGORIES',
        icon: 'folder-open',
        routerLink: `/products/settings/categories/${productTypeRedirect}`,
        disabled: false
      },
      {
        title: 'APP.SETTINGS',
        show: canManageProducts,
        icon: 'options-2',
        routerLink: '/products/products-settings'
      }
    ]
  };

  const PARTNERS_ITEMS = {
    title: 'MODULE.PARTNERS',
    icon: 'heart',
    show: canViewPartners,
    color: 'brown',
    children: [
      {
        title: 'LAUNCH_PAD.COMPANIES',
        icon: 'factory',
        show: corporatePartnersEnabled,
        routerLink: '/partners-new/corporate',
        action: 'createNewPartner',
      },
      {
        title: 'APP.PRIVATE_PARTNERS',
        icon: 'person',
        show: privatePartnersEnabled,
        routerLink: '/partners-new/private',
        action: 'createNewPrivatePartner',
      },
      {
        title: 'APP.SETTINGS',
        icon: 'options-2',
        show: canManagePartners,
        routerLink: '/partners-new/settings'
      }
    ]
  };

  const TEAM_ITEMS = {
    title: 'MODULE.TEAM',
    icon: 'people',
    show: canViewTeam,
    color: 'sky',
    children: [
      {
        title: 'APP.EMPLOYEES',
        icon: 'people',
        routerLink: '/team',
        action: 'createNewEmployee'
      }
    ]
  };

  const ANALYTICS_ITEMS = {
    title: 'MODULE.ANALYTICS',
    icon: 'business-intelligence',
    show: (wholesaleEnabled && canViewWholesale) || canViewWarehouse || canViewAccounting,
    color: 'mint',
    // locked: true,
    children: [
      // {
      //   title: 'DASHBOARDS.TRADE_DATA',
      //   locked: true,
      //   icon: 'cash',
      //   show: canViewWholesale && wholesaleEnabled,
      //   routerLink: '/analytics/trade-dashboard'
      // },
      {
        title: 'DASHBOARDS.ACCOUNTING_DATA',
        icon: 'calculator',
        show: canViewAccounting,
        routerLink: '/analytics/accounting-dashboard'
      },
      // {
      //   title: 'DASHBOARDS.WAREHOUSE_DATA',
      //   locked: true,
      //   icon: 'warehouse',
      //   show: canViewWarehouse && (ecommerceEnabled || wholesaleEnabled),
      //   routerLink: '/analytics/warehouse-dashboard'
      // },
      {
        title: 'HQ: Subscriptions',
        icon: 'runple',
        show: isMasterWorkspace,
        routerLink: '/analytics/hq-subscriptions'
      },
      {
        title: 'CASH_FLOW.CASH_FLOW',
        icon: 'cashflow',
        routerLink: '/analytics/cashflow'
      },
      {
        title: 'REPORTS.REPORTS',
        icon: 'reports',
        routerLink: '/analytics/reports'
      },
    ]
  };

  const SYSTEM_ITEMS = {
    title: 'MODULE.SYSTEM',
    color: 'black',
    show: canViewAdministration || canViewAccounting || canViewWarehouse || canViewWholesale,
    children: [
      {
        title: 'MODULE.CONTROL_CENTER',
        icon: 'compass',
        routerLink: '/control-center',
        show: canViewAccounting || canViewWarehouse || canViewWholesale || canViewAdministration
      },
      {
        title: 'MODULE.ADMINISTRATION',
        icon: 'settings',
        routerLink: '/administration/company-profile',
        show: canViewAdministration,
      },
      {
        title: 'DATA_IMPORT.DATA_IMPORT',
        icon: 'cloud-upload',
        routerLink: '/data-import',
        show: canViewAdministration,
      },
      {
        title: 'PDF_SETTINGS.PDF_DESIGNER',
        icon: 'pdf-designer',
        routerLink: '/pdf-designer',
        show: canViewAdministration,
      },
    ]
  };


  return [
    TRADE_ITEMS,
    ECOMMERCE_ITEMS,
    SERVICES_ITEMS,
    ACCOUNTING_ITEMS,
    WAREHOUSE_ITEMS,
    PRODUCT_ITEMS,
    PARTNERS_ITEMS,
    TEAM_ITEMS,
    ANALYTICS_ITEMS,
    SYSTEM_ITEMS
    // ADMINISTRATION_ITEMS
  ];
}
