import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'projects/workspace/src/environments/environment';
import { AppState } from '../../store/state/app.state';
import { selectCompanyProfile } from '../../administration/store/selectors';
import { CompanyProfile } from '../../administration/models/company-profile.model';
import { GlAccountModel, GlTransactionModel } from '../models';
import { ResponseList, ResponseModel } from '../../shared/models';
import { DisplayToaster } from '../../shared/decorators/toaster';
import { PaginationModel } from 'common/src/models';
import { FilterModelNew } from '../../outgoing-invoice/models/filter-model-new';
import { FileUploadParams } from 'common/src/models/file-upload-params.model';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { GlAccountRuleModel } from '../../accounting/accounting-settings-module/models';

@Injectable({
  providedIn: 'root'
})
export class GlAccountsApiService {

  private readonly apiEndpoint: string = `${environment.javaApiVersion}/workspaces`;
  private wid: number;

  private apiUrl(url: string = ''): string {
    return this.apiEndpoint + `/${this.wid}/gl` + url;
  }

  constructor(
    private readonly http: HttpClient,
    private readonly toasterService: ToasterService,
    private readonly translateService: TranslateService,
    private readonly store: Store<AppState>,
  ) {
    this.store.select(selectCompanyProfile)
      .subscribe((profile: CompanyProfile) => {
        this.wid = profile.workspaceId;
      });
  }

  @DisplayToaster({showErrorMessage: true})
  public getGlAccountById(getAccountId: number): Observable<GlAccountModel> {
    return this.http.get<ResponseModel<GlAccountModel>>(this.apiUrl(`/accounts/${getAccountId}`))
      .pipe(map((res: ResponseModel<GlAccountModel>) => res.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public createGlAccount(getAccount: {parentId: number, code: string, name: string}): Observable<GlAccountModel[]> {
    return this.http.post<ResponseModel<GlAccountModel[]>>(this.apiUrl('/accounts'), getAccount)
      .pipe(map((res: ResponseModel<GlAccountModel[]>) => res.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public createGlTransaction(
    transactions: {glAccountId: number, direction: 'CREDIT'|'DEBIT', amount: number}[]
  ): Observable<GlTransactionModel[]> {
    return this.http.post<ResponseModel<GlTransactionModel[]>>(this.apiUrl('/transactions'), transactions)
      .pipe(map((res: ResponseModel<GlTransactionModel[]>) => res.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public updateGlAccount(getAccount: {parentId: number, code: string, name: string}, getAccountId: number): Observable<GlAccountModel[]> {
    return this.http.patch<ResponseModel<GlAccountModel[]>>(this.apiUrl(`/accounts/${getAccountId}`), getAccount)
      .pipe(map((res: ResponseModel<GlAccountModel[]>) => res.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public deleteGlAccount(getAccountId: number): Observable<any> {
    return this.http.delete<ResponseModel<any>>(this.apiUrl(`/accounts/${getAccountId}`));
  }

  public getGlAccounts(legalOnly = false): Observable<GlAccountModel[]> {
    const params = {
      legalOnly: legalOnly.toString()
    };
    return this.http.get<ResponseModel<GlAccountModel[]>>(this.apiUrl('/accounts'), {params})
      .pipe(map((res: ResponseModel<GlAccountModel[]>) => res.data));
  }

  public getGlAccountsBankRelated(): Observable<GlAccountModel[]> {
    return this.http.get<ResponseModel<GlAccountModel[]>>(this.apiUrl('/accounts/bank-related'))
      .pipe(map((res: ResponseModel<GlAccountModel[]>) => res.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public getGlAccountsBalances(): Observable<GlAccountModel[]> {
    return this.http.get<ResponseModel<GlAccountModel[]>>(this.apiUrl('/balances'))
      .pipe(map((res: ResponseModel<GlAccountModel[]>) => res.data));
  }

  public getGlAccountsCategories(): Observable<any[]> {
    return this.http.get<ResponseModel<any[]>>(this.apiUrl('/categories'))
      .pipe(map((res: ResponseModel<any[]>) => res.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public getGlAccountsTransactions(
    pagination: PaginationModel,
    sort: FilterModelNew,
    filters: any = {}
  ): Observable<ResponseList<GlTransactionModel>> {
    const params = {
      page: pagination.page,
      sortBy: sort.sortBy,
      direction: sort.direction,
      length: pagination.per_page,
    };

    for (const [key, value] of Object.entries(filters)) {
      params[key] = value;
    }

    return this.http.get<ResponseList<GlTransactionModel>>(this.apiUrl('/transactions'), { params });
  }

  getTransactionsLinkedDocuments(glAccountId?: number): Observable<{ key: string, value: string }[]> {
    const params = {};

    if (glAccountId) { params['glAccounts'] = glAccountId; }

    return this.http.get<ResponseModel<{ key: string, value: string }[]>>(this.apiUrl('/transactions/filter/linked-documents'), { params })
      .pipe(
        map((response: ResponseModel<{ key: string, value: string }[]>) => response.data.map(item => {
          if (item.value === 'EMPTY') {
            return {
              ...item,
              value: this.translateService.instant('DOC_SUMMARY.WITHOUT_DOCUMENT')
            }
          }
          return item;
        }))
      );
  }

  public getGlAccountsExportParams(): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: this.apiUrl('/accounts/export'),
      type: 'zip',
    };
    return of(fileParams);
  }

  public getGlTransactionsExportParams(
    filters?: {
      createdFrom?: string;
      createdTo?: string;
    },
    glAccountId?: number,
  ): Observable<FileUploadParams> {
    let url = this.apiUrl('/transactions/export');

    if (filters.createdFrom || filters.createdTo) {
      url = `${url}?created=period`;
    }
    if (filters.createdFrom) {
      url = `${url}&createdFrom=${filters.createdFrom}`;
    }
    if (filters.createdTo) {
      url = `${url}&createdTo=${filters.createdTo}`;
    }
    if (glAccountId) {
      url = `${url}&glAccounts=${glAccountId}`;
    }

    const fileParams: FileUploadParams = {
      url,
      type: 'zip',
    };
    return of(fileParams);
  }

  // RULES
  @DisplayToaster({showErrorMessage: true})
  public getGlAccountRules(): Observable<GlAccountRuleModel[]> {
    return this.http.get<ResponseModel<GlAccountRuleModel[]>>(this.apiUrl('/rules'))
      .pipe(map((res: ResponseModel<GlAccountRuleModel[]>) => res.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public createGlAccountRule(rule: GlAccountRuleModel): Observable<GlAccountRuleModel> {
    return this.http.post<ResponseModel<GlAccountRuleModel>>(this.apiUrl('/rules'), rule)
      .pipe(map((res: ResponseModel<GlAccountRuleModel>) => res.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public getGlAccountRule(ruleId: number): Observable<GlAccountRuleModel> {
    return this.http.get<ResponseModel<GlAccountRuleModel>>(this.apiUrl(`/rules/${ruleId}`))
      .pipe(map((res: ResponseModel<GlAccountRuleModel>) => res.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public deleteGlAccountRule(ruleId: number): Observable<boolean> {
    return this.http.delete<ResponseModel<boolean>>(this.apiUrl(`/rules/${ruleId}`))
      .pipe(map((res: ResponseModel<boolean>) => res.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public updateGlAccountRule(ruleId: number, rule: GlAccountRuleModel): Observable<GlAccountRuleModel> {
    return this.http.patch<ResponseModel<GlAccountRuleModel>>(this.apiUrl(`/rules/${ruleId}`), rule)
      .pipe(map((res: ResponseModel<GlAccountRuleModel>) => res.data));
  }
  // RULES END

}
