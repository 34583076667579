import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DatePipe, Location } from '@angular/common';
import { FileSaverService } from 'ngx-filesaver';
import { FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { get, isEqual } from 'lodash';

import { TableActivateTypes } from 'common/src/modules/ui-components/table/custom-table.enums';
import { TableColumnModel } from 'common/src/models/table-column.model';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { FileService } from 'common/src/services/file.service';
import {
  getStockStatusBinsInnerTableColumns,
  getStockStatusFiltersConfig,
  getTableSummaryBarItems,
  NavBarButtons,
  StockStatusDocumentsInnerTableColumns,
  StockStatusListColumns,
  StockStatusListTabs,
} from './stock-status-list.config';
import { NavBarBtnModel, PaginationModel } from 'common/src/models';
import { FilterModel } from '../../models/filter.model';
import { BinLocationService, StockAddressesService } from '../../services';
import { ResponseList, UserSorting } from '../../../shared/models';
import { StockStatusBinsModel, StockStatusDocumentModel, StockStatusModel } from '../../models/stock-address.model';
import { StockStatusListState } from '../../store/reducers';
import { AppState } from '../../../store/state/app.state';
import { selectStockStatusList } from '../../store/selectors';
import { DEFAULT_PAGINATION, DEFAULT_SORT_DIRECTION } from '../../../shared/constants';
import { TableSummaryBarItemModel } from 'common/src/modules/ui-components/table-summary-bar/table-summary-bar.model';
import {
  DocumentTypesEnum,
  DocumentTypesUppercaseEnum
} from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { TabDefinitionModel } from 'common/src/modules/ui-components/nav-tabs/tab-definition.model';
import { FiltersGroupModel, FiltersService } from 'common/src/modules/filters';
import { getDocumentTypeLabel } from '../../../shared/helpers';
import { ProductsService } from 'common/src/modules/products';
import { UserSortingService } from '../../../shared/services';
import { BinLocationsTabsEnum } from '../../enums';
import { TransactionsApiService } from '../../modules/transactions/services/transactions-api.service';

@Component({
  selector: 'rnpl-stock-status-list',
  templateUrl: './stock-status-list.component.html',
  styleUrls: ['./stock-status-list.component.scss'],
})
export class StockStatusListComponent implements OnInit, OnDestroy {
  public columns: TableColumnModel[] = StockStatusListColumns();
  public customizedColumns: TableColumnModel[] = [];
  public innerTableColumns: TableColumnModel[] = [];
  public pagination: PaginationModel = DEFAULT_PAGINATION;
  public isOnStock: FormControl = new FormControl(false);
  public productDataBy: 'documents'|'bins' = 'documents';
  public shelfList$: BehaviorSubject<any> = new BehaviorSubject([]);

  public sort: FilterModel = { nameColumn: 'categoryPath', value: DEFAULT_SORT_DIRECTION };
  public filtersConfig: FiltersGroupModel[] = [];

  public navBarButtons: NavBarBtnModel[] = NavBarButtons;
  public tableSummaryBarItems: TableSummaryBarItemModel[] = [];

  public tabs:  TabDefinitionModel[] = StockStatusListTabs;
  public documentTypesUppercaseEnum = DocumentTypesUppercaseEnum;

  readonly listData$: BehaviorSubject<StockStatusListState> = new BehaviorSubject(null);
  readonly stockStatusList$: BehaviorSubject<StockStatusModel[]> = new BehaviorSubject([]);
  readonly selectedInnerTableData$: BehaviorSubject<StockStatusDocumentModel[] | StockStatusBinsModel[]> = new BehaviorSubject([]);
  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly isLoadingInnerTable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly updatedAt$: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());
  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  readonly btnToClearLoadingStatus$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  @ViewChild('tableComponent', {static: false}) tableComponentRef;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly stockAddressService: StockAddressesService,
    private readonly productsService: ProductsService,
    private readonly toasterService: ToasterService,
    private readonly location: Location,
    private readonly datePipe: DatePipe,
    private readonly fileService: FileService,
    private readonly fileSaverService: FileSaverService,
    private readonly store: Store<AppState>,
    private readonly cdr: ChangeDetectorRef,
    private readonly filtersService: FiltersService,
    private titleService: Title,
    private translate: TranslateService,
    private userSortingService: UserSortingService,
    private binLocationApiService: BinLocationService,
    private transactionsApiService: TransactionsApiService,

  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.translate.instant('TAB.STOCK_STATUS_GENERAL_STOCK'));
    this.bindTOList();
    this.shelfList$.next(Array.from({length: 10}, (val, index) => index === 0 ? '00' : (index + '0')));

    this.filtersConfig = getStockStatusFiltersConfig(
      this.binLocationApiService.getRackFiltersList(BinLocationsTabsEnum.GENERAL_STOCK),
      this.binLocationApiService.getShelfList(BinLocationsTabsEnum.GENERAL_STOCK),
      this.binLocationApiService.getBinList(BinLocationsTabsEnum.GENERAL_STOCK),
      this.transactionsApiService.getBatches(),

      // this.productsService.getProductsRunpleIds(ProductTypes.GOODS, ProductStatusEnum.ACTIVE, null),
      // this.productsService.getProductsNames(ProductTypes.GOODS, ProductStatusEnum.ACTIVE, null)
    );

    this.activatedRoute.paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe((params: ParamMap) => {
        const pageFromRouting = Number(params.get('page'));
        this.pagination = { ...this.pagination, page: pageFromRouting };
        this.tableSummaryBarItems = [];

        this.getStockStatusList();
        this.cdr.detectChanges();
      });

    this.bindIsOnStockChanges();
  }

  public bindTOList() {
    this.store.select(selectStockStatusList)
      .pipe(takeUntil(this.destroy$))
      .subscribe((stockStatusList) => {
        this.listData$.next(stockStatusList);
      });
  }

  public bindIsOnStockChanges(): void {
    this.isOnStock.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: boolean) => {
        this.getStockStatusList(undefined, undefined, value);
      });
  }

  public getStockStatusList(
    preventLoading = false,
    page = this.pagination.page,
    hideOutOfStock = this.isOnStock.value
  ): void {
    if (!preventLoading) {
      this.isLoading$.next(true);
    }

    if (this.tableComponentRef) {
      this.tableComponentRef.collapseAll();
    }

    this.userSortingService.getUserSorting(DocumentTypesUppercaseEnum.STOCK_GENERAL, DocumentTypesUppercaseEnum.STOCK_GENERAL)
      .pipe(
        tap((sorting: UserSorting) => {
          this.sort = {
            nameColumn: sorting.sortBy || this.sort.nameColumn,
            value: sorting.direction || this.sort.value
          };
          const stockStatusByCurrentPage = this.listData$.getValue()[page];
          if (stockStatusByCurrentPage && isEqual(this.sort, stockStatusByCurrentPage.sort)) {
            this.pagination = stockStatusByCurrentPage.pagination;
            this.tableSummaryBarItems = getTableSummaryBarItems(stockStatusByCurrentPage.totals);
            this.sort = stockStatusByCurrentPage.sort;
            this.stockStatusList$.next(this.prepareStockStatusList(stockStatusByCurrentPage.data));
            this.isLoading$.next(false);
          }
        }),
        switchMap(() => this.stockAddressService.getStockStatusList(
          page,
          this.pagination.per_page,
          this.sort,
          hideOutOfStock,
          this.filtersService.getFilters()
        )),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (data: ResponseList<StockStatusModel>) => {
          this.stockStatusList$.next(this.prepareStockStatusList(data.data));
          this.pagination = get(data, 'pagination', DEFAULT_PAGINATION);
          this.isLoading$.next(false);
          this.updatedAt$.next(new Date());
          this.sort = get(this, 'sort',  { nameColumn: 'categoryPath', value: DEFAULT_SORT_DIRECTION });
          this.pagination = get(this, 'pagination', DEFAULT_PAGINATION);
          this.tableSummaryBarItems = getTableSummaryBarItems(data.totals);
        },
        (error) => {
          this.isLoading$.next(false);
          this.updatedAt$.next(null);
          this.showMsg('error', error.error.message);
        }
      );
  }

  private prepareStockStatusList (data: any[]): any[] {
    return data.map(stockStatus => {
      return {
        ...stockStatus,
        productLink: {
          label: stockStatus.productRunpleId,
          routerLink: `/products/product-view/${stockStatus.productId}`
        },
        productNameLink: {
          label: stockStatus.productName,
          routerLink: `/products/product-view/${stockStatus.productId}`
        }
      };
    });
  }

  private prepareStockStatusInnerList(data: any[]): any[] {
    return data.map(document => {
      return {
        ...document,
        documentLink: {
          label: document.runpleId,
          routerLink: this.linkToDocument(document),
        },
      };
    });
  }

  public changeProductDataBy(source: 'documents'|'bins'): void {
    this.productDataBy = source;

    if (this.tableComponentRef) {
      this.tableComponentRef.collapseAll();
    }
  }

  public rowClickReceiver(event): void {
    if (event.type === TableActivateTypes.RowDetail) {
      this.isLoadingInnerTable$.next(true);
      let request$: Observable<any>;

      if (this.productDataBy === 'documents') {
        this.innerTableColumns = StockStatusDocumentsInnerTableColumns();

        request$ = this.stockAddressService.getStockStatusListDocuments(event.row.id)
          .pipe(map(documents => {
            return documents.map((doc: StockStatusDocumentModel) => ({
              ...doc,
              unitType: event.row.unitType,
              typeLabel: this.translate.instant(getDocumentTypeLabel(doc.type))
            }));
          }));
      }

      if (this.productDataBy === 'bins') {
        this.innerTableColumns = getStockStatusBinsInnerTableColumns();

        request$ = this.stockAddressService.getStockStatusListBins(event.row.id)
          .pipe(map(bins => {
            return bins.map((bin: StockStatusBinsModel) => ({
              ...bin,
              unitType: event.row.unitType,
              locationType: bin.locationType === 'general_stock'
                ? this.translate.instant('TAB.GENERAL_STOCK')
                : this.translate.instant('TAB.RETURNS_STOCK')
            }));
          }));
      }

      if (!request$) { return; }

      request$
        .pipe(
          finalize(() => this.isLoadingInnerTable$.next(false)),
          takeUntil(this.destroy$)
        )
        .subscribe((data: any[]) => {
          this.selectedInnerTableData$.next(this.prepareStockStatusInnerList(data));
        });
    }
  }

  public linkToDocument(document: StockStatusDocumentModel): string {
    let url: string = '';
    if (document.type === DocumentTypesEnum.PO) {
      url = `/trade/purchase-order/${document.id}`;
    }

    if (document.type === DocumentTypesEnum.SO) {
      url = `/trade/sales-order/${document.id}`;
    }

    return url;
  }

  public sortTable(e): void {
    let sortedProp = e.column.prop;

    if (sortedProp === 'productLink') {
      sortedProp = 'productRunpleId';
    }

    if (sortedProp === 'productNameLink') {
      sortedProp = 'productName';
    }

    this.sort = { nameColumn: sortedProp, value: e.newValue.toUpperCase() };
    this.saveUserSorting(sortedProp, e.newValue.toUpperCase());
  }

  public saveUserSorting(sortBy: string, direction: 'ASC'|'DESC'): void {
    const sorting = {
      sortBy,
      direction,
      type: DocumentTypesUppercaseEnum.STOCK_GENERAL,
      status: DocumentTypesUppercaseEnum.STOCK_GENERAL
    }
    this.userSortingService.saveUserSorting(sorting)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.getStockStatusList(true, this.pagination.page, this.isOnStock.value));
  }

  public goToPage(page: number): void {
    const url = this.getNewPaginationUrl(this.router.url);

    this.pagination = { ...this.pagination, page: page + 1 };
    this.router.navigate([url, this.pagination.page], {queryParamsHandling: 'merge'});
  }

  public getNewPaginationUrl(oldUrl: string): string {
    return oldUrl.split('/').slice(0, -1).join('/');
  }

  private showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  public actionsEvent(e: string): void {
    if (this[e]) {
      this[e]();
    }
  }

  public onExportCsvClick(): void {
    this.downloadFile(`/warehouse/stock-status/csv?hideOutOfStock=${+this.isOnStock.value}`);
  }

  public downloadFile(url: string) {
    this.fileService.downloadFile(url)
      .pipe(
        finalize(() => this.btnToClearLoadingStatus$.next('onExportCsvClick')),
        takeUntil(this.destroy$)
      )
      .subscribe((res: any) => {
        const contentDispositionHeader = res.headers.get('Content-Disposition');
        this.fileSaverService.save(res.body, this.getFileName(contentDispositionHeader));
      });
  }

  public getFileName(str: string): string {
    const fileNameRegExp = /\"([^)]+)\"/;
    const currentDate = this.datePipe.transform(new Date(), 'yyMMdd_hhmm');
    if (!!str) {
      const name = fileNameRegExp.exec(str);
      return name[1];
    }
    return `stock_status_${currentDate}.zip`;
  }

  public get showSummaryBar(): boolean {
    return !!this.tableSummaryBarItems.length;
  }

  get translatedProductTypeBy(): string {
    return this.productDataBy === 'documents'
      ? 'TABLE_SUMMARY_BAR.DOCUMENTS'
      : 'BUTTON.BINS';
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
