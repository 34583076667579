import { Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { BehaviorSubject, concat, EMPTY, of, ReplaySubject } from 'rxjs';
import { catchError, finalize, switchMap, takeUntil, tap, toArray } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { FileService } from 'common/src/services/file.service';
import { CommonModalsActionsEnum, DangerModalComponent } from 'common/src/modules/modals/modals-common';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { FileAttachmentTypeEnum } from '../../enums';
import { FileAttachmentModel } from '../../models';
import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { FilesAttachmentApiService } from './files-attachment-api.service';


@Component({
  selector: 'rnpl-files-attachment',
  templateUrl: './files-attachment.component.html',
  styleUrls: ['./files-attachment.component.scss']
})
export class FilesAttachmentComponent implements OnChanges, OnDestroy {

  @Input() disabled: boolean = false;
  @Input() isAccountingAttachments: boolean = false;
  @Input() documentType: DocumentTypesUppercaseEnum = null;
  @Input() documentId: number = null;

  public attachmentItmIcon: {[key in FileAttachmentTypeEnum]?: string} = {
    [FileAttachmentTypeEnum.PDF]: 'icon-format-pdf',
    [FileAttachmentTypeEnum.IMAGE]: 'icon-format-image',
    [FileAttachmentTypeEnum.ARCHIVE]: 'icon-format-zip',
    [FileAttachmentTypeEnum.DOCUMENT]: 'icon-format-word',
    [FileAttachmentTypeEnum.TABLE]: 'icon-format-xlsx',
    [FileAttachmentTypeEnum.TEXT]: 'icon-format-txt',
  };

  public fileAttachmentTypeEnum = FileAttachmentTypeEnum;
  public attachments: FileAttachmentModel[] = [];

  @ViewChild('UploadFileInput', {static: true}) public uploadFileInputEl: ElementRef;

  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private readonly toasterService: ToasterService,
    private readonly translateService: TranslateService,
    private readonly fileService: FileService,
    private readonly dialog: MatDialog,
    private readonly filesAttachmentApiService: FilesAttachmentApiService,
  ) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && (changes.hasOwnProperty('documentType') || changes.hasOwnProperty('documentId'))) {
      this.getAttachments();
    }
  }

  public getAttachments(): void {
    if (!this.documentType || !this.documentId) { return; }

    const request$ = this.isAccountingAttachments
      ? this.filesAttachmentApiService.getAccountingAttachments(this.documentType, this.documentId)
      : this.filesAttachmentApiService.getAttachments(this.documentType, this.documentId);

    request$
      .pipe(
        finalize(() => this.isLoading$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe((attachments: FileAttachmentModel[]) => this.attachments = attachments);
  }

  public uploadFile(filesList: FileList): void {
    if (this.disabled) { return; }
    if (!this.documentType || !this.documentId) { return; }
    if (!filesList || !filesList.length) { return; }

    const requests$ = [];
    Array.from(filesList).map((file, index) => {
      requests$.push(
        of(null)
          .pipe(
            tap(() => {
              this.toasterService.notifyRequestMessage({
                key: 'fileAttach' + index,
                message: this.translateService.instant('ATTACHMENTS.FILE_UPLOADING', {name: file.name})
              });
            }),
            switchMap(() => {
              return this.isAccountingAttachments
                ? this.filesAttachmentApiService.uploadAccountingAttachment(this.documentType, this.documentId, file)
                : this.filesAttachmentApiService.uploadAttachment(this.documentType, this.documentId, file);
            }),
            catchError(() => EMPTY),
            finalize(() => this.toasterService.hideRequestMessage('fileAttach' + index))
          )
      );
    });

    concat(...requests$)
      .pipe(
        toArray(),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.uploadFileInputEl.nativeElement.value = null;
        this.getAttachments();
      });
  }

  public downloadFile(fileId: number, name: string): void {
    if (!this.documentType || !this.documentId) { return; }

    const request$ = this.isAccountingAttachments
      ? this.filesAttachmentApiService.getDownloadAccountingAttachment(this.documentType, this.documentId, fileId)
      : this.filesAttachmentApiService.getDownloadAttachment(fileId);

    request$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.isAccountingAttachments
          ? this.fileService.downloadFileByUrl(res.url, name)
          : this.fileService.downloadFileByUrl(res.tempUrl, name);
      });
  }

  public deleteAttachment(fileId: number): void {
    if (!this.documentType || !this.documentId) { return; }

    const dialog = this.dialog.open(DangerModalComponent, {
      data: {
        title: 'MODAL.DELETE',
        message: 'ATTACHMENTS.DELETE_FILE_MSG',
        confirmBtnText: 'BUTTON.DELETE',
        confirmBtnIcon: 'trash-2',
      }
    });

    dialog.afterClosed().subscribe((response: CommonModalsActionsEnum) => {
      if (response === CommonModalsActionsEnum.CONFIRM) {
        const request$ = this.isAccountingAttachments
          ? this.filesAttachmentApiService.deleteAccountingAttachment(this.documentType, this.documentId, fileId)
          : this.filesAttachmentApiService.deleteAttachment(this.documentType, this.documentId, fileId);

        request$
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => this.getAttachments());
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
