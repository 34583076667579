import { AppDataModel, AppNameEnum } from './launchpad.model';
import { ProductTypes } from '../products/product-types';

export function getTradeSettingsActionItem(showWholesaleSettings) {
  return {
    router: `/trade/settings`,
    title: 'LAUNCH_PAD.OPEN_SETTINGS',
    icon: 'options-2',
    hide: !showWholesaleSettings,
  };
}

export function getServicesSettingsActionItem(showServicesSettings) {
  return {
    router: `/services/settings`,
    title: 'LAUNCH_PAD.OPEN_SETTINGS',
    icon: 'options-2',
    hide: !showServicesSettings,
  };
}

export function getProductsSettingsActionItem(showProductsSettings) {
  return {
    router: `/products/products-settings`,
    title: 'LAUNCH_PAD.OPEN_SETTINGS',
    icon: 'options-2',
    hide: !showProductsSettings,
  };
}

export function getPartnersSettingsActionItem(showPartnersSettings) {
  return {
    router: `/partners-new/settings`,
    title: 'LAUNCH_PAD.OPEN_SETTINGS',
    icon: 'options-2',
    hide: !showPartnersSettings,
  };
}

export function getWarehouseSettingsActionItem(showWarehouseSettings) {
  return {
    router: `/warehouse/settings/delivery-services`,
    title: 'LAUNCH_PAD.OPEN_SETTINGS',
    icon: 'options-2',
    hide: !showWarehouseSettings,
  };
}

export function getAccountingSettingsActionItem(showAccountingSettings) {
  return {
    router: `/accounting/settings`,
    title: 'LAUNCH_PAD.OPEN_SETTINGS',
    icon: 'options-2',
    hide: !showAccountingSettings,
  };
}

export function AppDataByModuleName(
  showProductsSettings: boolean = false,
  showPartnersSettings: boolean = false,
  showWarehouseSettings: boolean = false,
  showAccountingSettings: boolean = false,
  showWholesaleSettings: boolean = false,
  showServicesSettings: boolean = false,
  showGoods: boolean = false,
  showServices: boolean = false,
  showDigitalProducts: boolean = false,
  showCorporatePartners: boolean = false,
  showPrivatePartners: boolean = false,
  showSalesPrices: boolean = false,
  showPurchasePrices: boolean = false,
): {[key: string]: AppDataModel} {
  return {
    [AppNameEnum.ADMINISTRATION]: {
      title: 'Administration',
      iconName: 'settings',
      mainColor: 'black',
      routerLink: '/administration',
      actionList: [
        {
          router: '/administration/company-profile',
          title: 'LAUNCH_PAD.OPEN_ADMINISTRATION',
          icon: 'list'
        },
      ]
    },
    [AppNameEnum.PDF_DESIGNER]: {
      title: 'PDF_SETTINGS.PDF_DESIGNER',
      iconName: 'pdf-designer',
      mainColor: 'black',
      routerLink: '/pdf-designer',
      actionList: [
        {
          router: '/pdf-designer',
          title: 'LAUNCH_PAD.OPEN_PDF_DESIGNER',
          icon: 'list'
        },
      ]
    },
    // [AppNameEnum.TRADE_DASHBOARD]: {
    //   title: 'DASHBOARDS.TRADE_DATA',
    //   iconName: 'cash',
    //   mainColor: 'mint',
    //   routerLink: '/analytics/trade-dashboard',
    //   actionList: [
    //     {
    //       router: '/analytics/trade-dashboard',
    //       title: 'LAUNCH_PAD.OPEN_TRADE_DASHBOARD',
    //       icon: 'list'
    //     },
    //   ]
    // },
    [AppNameEnum.ACCOUNTING_DASHBOARD]: {
      title: 'DASHBOARDS.ACCOUNTING_DATA',
      iconName: 'calculator',
      mainColor: 'mint',
      routerLink: '/analytics/accounting-dashboard',
      actionList: [
        {
          router: '/analytics/accounting-dashboard',
          title: 'LAUNCH_PAD.OPEN_ACCOUNTING_DASHBOARD',
          icon: 'list'
        },
      ]
    },
    [AppNameEnum.CASHFLOW]: {
      title: 'CASH_FLOW.CASH_FLOW',
      iconName: 'cashflow',
      mainColor: 'mint',
      routerLink: '/analytics/cashflow',
      actionList: [
        {
          router: '/analytics/cashflow',
          title: 'CASH_FLOW.OPEN_CAHSFLOW_LIST',
          icon: 'list'
        },
      ]
    },
    // [AppNameEnum.WAREHOUSE_DASHBOARD]: {
    //   title: 'DASHBOARDS.WAREHOUSE_DATA',
    //   iconName: 'warehouse',
    //   mainColor: 'mint',
    //   routerLink: '/analytics/warehouse-dashboard',
    //   actionList: [
    //     {
    //       router: '/analytics/warehouse-dashboard',
    //       title: 'LAUNCH_PAD.OPEN_WAREHOUSE_DASHBOARD',
    //       icon: 'list'
    //     },
    //   ]
    // },
    [AppNameEnum.REPORTS]: {
      title: 'REPORTS.REPORTS',
      iconName: 'reports',
      mainColor: 'mint',
      routerLink: '/analytics/reports',
      actionList: [
        {
          router: '/analytics/reports',
          title: 'REPORTS.OPEN_REPORTS_LIST',
          icon: 'list'
        },
      ]
    },
    [AppNameEnum.STOCK_STATUS]: {
      title: 'APP.STOCK_STATUS',
      iconName: 'cubes',
      mainColor: 'orange',
      routerLink: '/warehouse/stock-status',
      actionList: [
        {
          router: '/warehouse/stock-status',
          title: 'LAUNCH_PAD.OPEN_STOCK_STATUS',
          icon: 'list'
        },
        getWarehouseSettingsActionItem(showWarehouseSettings),
      ]
    },
    [AppNameEnum.INCOMING_DELIVERIES]: {
      title: 'APP.INCOMING_DELIVERIES',
      iconName: 'incoming-delivery',
      mainColor: 'orange',
      routerLink: '/warehouse/incoming-delivery',
      actionList: [
        {
          router: '/warehouse/incoming-delivery',
          title: 'LAUNCH_PAD.OPEN_INCOMING_DELIVERY_LIST',
          icon: 'list'
        },
        getWarehouseSettingsActionItem(showWarehouseSettings),
      ]
    },
    [AppNameEnum.OUTGOING_DELIVERIES]: {
      title: 'APP.OUTGOING_DELIVERIES',
      iconName: 'outgoing-delivery',
      mainColor: 'orange',
      routerLink: '/warehouse/outgoing-delivery',
      actionList: [
        {
          action: 'createNewDeliveryNote',
          title: 'BUTTON.CREATE_OUTGOING_DELIVERY',
          icon: 'plus-square'
        },
        {
          router: '/warehouse/outgoing-delivery',
          title: 'LAUNCH_PAD.OPEN_OUTGOING_DELIVERY_LIST',
          icon: 'list'
        },
        getWarehouseSettingsActionItem(showWarehouseSettings),
      ]
    },
    [AppNameEnum.STOCKTAKING]: {
      title: 'STOCKTAKING.STOCKTAKING',
      iconName: 'stocktaking',
      mainColor: 'orange',
      routerLink: '/warehouse/stocktaking',
      actionList: [
        {
          router: '/warehouse/stocktaking',
          title: 'LAUNCH_PAD.OPEN_STOCKTAKING_LIST',
          icon: 'list'
        },
        getWarehouseSettingsActionItem(showWarehouseSettings),
      ]
    },
    [AppNameEnum.TRANSACTIONS]: {
      title: 'APP.TRANSACTIONS',
      iconName: 'stocktransfer',
      mainColor: 'orange',
      routerLink: '/warehouse/transactions',
      actionList: [
        {
          action: 'createTransaction',
          title: 'BUTTON.CREATE_TRANSACTION',
          icon: 'plus-square'
        },
        {
          router: '/warehouse/transactions',
          title: 'LAUNCH_PAD.OPEN_TRANSACTION_LIST',
          icon: 'list'
        },
        getWarehouseSettingsActionItem(showWarehouseSettings),
      ]
    },
    [AppNameEnum.MANAGEMENT]: {
      title: 'WAREHOUSE.WAREHOUSE_MANAGEMENT',
      iconName: 'warehouse',
      mainColor: 'orange',
      routerLink: '/warehouse/warehouse-profile',
      actionList: [
        {
          router: '/warehouse/warehouse-profile',
          title: 'LAUNCH_PAD.OPEN_WAREHOUSE_MANAGEMENT',
          icon: 'list'
        },
        getWarehouseSettingsActionItem(showWarehouseSettings),
      ]
    },
    [AppNameEnum.OUTGOING_INVOICES]: {
      title: 'APP.OUTGOING_INVOICES',
      iconName: 'outgoing-invoice',
      mainColor: 'violet',
      routerLink: '/accounting/outgoing-invoice',
      actionList: [
        {
          action: 'createNewInvoice',
          title: 'BUTTON.CREATE_OIN',
          icon: 'plus-square'
        },
        {
          router: '/accounting/outgoing-invoice',
          title: 'LAUNCH_PAD.OPEN_OIN_LIST',
          icon: 'list'
        },
        getAccountingSettingsActionItem(showAccountingSettings),
      ]
    },
    [AppNameEnum.INCOMING_INVOICES]: {
      title: 'APP.INCOMING_INVOICES',
      iconName: 'incoming-invoice',
      mainColor: 'violet',
      routerLink: '/accounting/incoming-invoice',
      actionList: [
        {
          action: 'createNewIncomingInvoice',
          title: 'BUTTON.CREATE_INCOMING_INVOICE',
          icon: 'plus-square'
        },
        {
          router: '/accounting/incoming-invoice',
          title: 'LAUNCH_PAD.OPEN_IIN_LIST',
          icon: 'list'
        },
        getAccountingSettingsActionItem(showAccountingSettings),
      ]
    },
    [AppNameEnum.PAYMENTS]: {
      title: 'APP.PAYMENTS',
      iconName: 'payment',
      mainColor: 'violet',
      routerLink: '/accounting/payments',
      actionList: [
        {
          router: '/accounting/payments',
          title: 'LAUNCH_PAD.OPEN_PAYMENTS_LIST',
          icon: 'list'
        },
        getAccountingSettingsActionItem(showAccountingSettings),
      ]
    },
    [AppNameEnum.CREDIT_NOTES]: {
      title: 'APP.CREDIT_NOTES',
      iconName: 'credit-note',
      mainColor: 'violet',
      routerLink: '/accounting/credit-note',
      actionList: [
        {
          action: 'createNewCreditNote',
          title: 'BUTTON.CREATE_CREDIT_NOTE',
          icon: 'plus-square'
        },
        {
          router: '/accounting/credit-note',
          title: 'LAUNCH_PAD.OPEN_CRN_LIST',
          icon: 'list'
        },
        getAccountingSettingsActionItem(showAccountingSettings),
      ]
    },
    [AppNameEnum.OPEN_ITEM_LIST]: {
      title: 'APP.OPEN_ITEM_LIST',
      iconName: 'open-items-list',
      mainColor: 'violet',
      routerLink: '/accounting/open-items',
      actionList: [
        {
          router: '/accounting/open-items',
          title: 'LAUNCH_PAD.OPEN_OPEN_ITEMS_LIST',
          icon: 'list'
        },
        getAccountingSettingsActionItem(showAccountingSettings),
      ]
    },
    [AppNameEnum.REPORTING_PERIODS]: {
      title: 'REPORTING_PERIODS.REPORTING_PERIODS',
      iconName: 'accountant-report',
      mainColor: 'violet',
      routerLink: '/accounting/reporting-periods',
      actionList: [
        {
          router: '/accounting/reporting-periods/list',
          title: 'LAUNCH_PAD.OPEN_REPORTING_PERIODS',
          icon: 'list'
        },
        getAccountingSettingsActionItem(showAccountingSettings),
      ]
    },
    [AppNameEnum.BANK_ACCOUNT]: {
      title: 'APP.BANK_ACCOUNTS',
      iconName: 'account',
      mainColor: 'violet',
      routerLink: '/accounting/bank-accounts',
      actionList: [
        {
          action: 'createBankAccount',
          title: 'BANK_ACCOUNTS.CREATE_ACCOUNT',
          icon: 'plus-square'
        },
        {
          action: 'createBankAccountManually',
          title: 'CARDLESS_BA.CREATE_ACCOUNT_MANUALLY',
          icon: 'plus-square'
        },
        {
          router: '/accounting/bank-accounts',
          title: 'LAUNCH_PAD.OPEN_BANK_ACCOUNTS',
          icon: 'list'
        },
        getAccountingSettingsActionItem(showAccountingSettings),
      ]
    },
    [AppNameEnum.SCHEDULED_PAYMENTS]: {
      title: 'SCHEDULED_PAYMENT.SCHEDULED_PAYMENTS',
      iconName: 'recurring-costs',
      mainColor: 'violet',
      routerLink: '/accounting/scheduled-payment',
      actionList: [
        {
          action: 'createNewScheduledPayment',
          title: 'SCHEDULED_PAYMENT.CREATE_SCHEDULED_PAYMENT',
          icon: 'plus-square'
        },
        {
          router: '/accounting/scheduled-payment',
          title: 'LAUNCH_PAD.OPEN_SCHEDULE_PAYMENTS',
          icon: 'list'
        },
        getAccountingSettingsActionItem(showAccountingSettings),
      ]
    },
    [AppNameEnum.PAYMENT_CALENDAR]: {
      title: 'APP.PAYMENTS_CALENDAR',
      iconName: 'payments-calendar',
      mainColor: 'violet',
      routerLink: '/accounting/payments-calendar',
      actionList: [
        {
          router: '/accounting/payments-calendar',
          title: 'LAUNCH_PAD.OPEN_PAYMENTS_CALENDAR',
          icon: 'list'
        },
        getAccountingSettingsActionItem(showAccountingSettings),
      ]
    },
    [AppNameEnum.GL_ACCOUNT]: {
      title: 'GL_ACCOUNTS.LEDGER_ACCOUNTS',
      iconName: 'gl-accounts',
      mainColor: 'violet',
      routerLink: '/accounting/gl-accounts',
      actionList: [
        {
          router: '/accounting/gl-accounts',
          title: 'LAUNCH_PAD.OPEN_GL_ACCOUNTS',
          icon: 'list'
        },
        getAccountingSettingsActionItem(showAccountingSettings),
      ]
    },
    [AppNameEnum.FIXED_ASSETS]: {
      title: 'FIXED_ASSETS.ASSETS_DIRECTORY',
      iconName: 'fixed-assets',
      mainColor: 'violet',
      routerLink: '/accounting/fixed-assets',
      actionList: [
        {
          router: '/accounting/fixed-assets',
          title: 'LAUNCH_PAD.OPEN_FIXED_ASSETS',
          icon: 'list'
        },
        getAccountingSettingsActionItem(showAccountingSettings),
      ]
    },
    [AppNameEnum.GOODS_REGISTER]: {
      title: 'GOODS_REGISTER.GOODS_REGISTER',
      iconName: 'goods-register',
      mainColor: 'violet',
      routerLink: '/accounting/goods-register',
      actionList: [
        {
          router: '/accounting/fixed-assets',
          title: 'GOODS_REGISTER.OPEN_GOODS_REGISTER',
          icon: 'list'
        },
        getAccountingSettingsActionItem(showAccountingSettings),
      ]
    },
    [AppNameEnum.COST_CENTER]: {
      title: 'COST_CENTER.COST_CENTERS',
      iconName: 'costs_center',
      mainColor: 'violet',
      routerLink: '/accounting/cost-center',
      actionList: [
        {
          router: '/accounting/cost-center',
          title: 'COST_CENTER.OPEN_COST_CENTER',
          icon: 'list'
        },
        getAccountingSettingsActionItem(showAccountingSettings),
      ]
    },
    // [AppNameEnum.SETTINGS]: {
    //   title: 'SETTINGS.ACCOUNTING_SETTINGS',
    //   iconName: 'options-2',
    //   mainColor: 'violet',
    //   routerLink: '/accounting/settings',
    //   actionList: [
    //     {
    //       router: '/accounting/settings',
    //       title: 'LAUNCH_PAD.OPEN_SETTINGS',
    //       icon: 'options-2'
    //     },
    //   ]
    // },
    [AppNameEnum.PURCHASE_ORDERS]: {
      title: 'APP.PURCHASE_ORDERS',
      iconName: 'purchase-order',
      mainColor: 'yellow',
      routerLink: '/trade/purchase-order',
      actionList: [
        {
          action: 'createNewPurchaseOrder',
          title: 'BUTTON.CREATE_PO',
          icon: 'plus-square'
        },
        {
          router: '/trade/purchase-order',
          title: 'LAUNCH_PAD.OPEN_PO_LIST',
          icon: 'list'
        },
        getTradeSettingsActionItem(showWholesaleSettings)
      ]
    },
    [AppNameEnum.PURCHASE_PRICES]: {
      title: 'PRICE.PURCHASE_PRICES',
      iconName: 'pricetag',
      mainColor: 'yellow',
      routerLink: '/trade/prices/purchase/',
      actionList: [
        {
          router: '/trade/prices/purchase/',
          title: 'LAUNCH_PAD.OPEN_PURCHASE_PRICE',
          icon: 'list'
        },
        getTradeSettingsActionItem(showWholesaleSettings)
      ]
    },
    [AppNameEnum.SALES_PRICES]: {
      title: 'PRICE.SALES_PRICES',
      iconName: 'pricetag',
      mainColor: 'yellow',
      routerLink: '/trade/prices/sales',
      actionList: [
        {
          router: '/trade/prices/sales',
          title: 'LAUNCH_PAD.OPEN_SALES_PRICE',
          icon: 'list'
        },
        getTradeSettingsActionItem(showWholesaleSettings)
      ]
    },
    [AppNameEnum.OFFERS]: {
      title: 'APP.OFFERS',
      iconName: 'offer',
      mainColor: 'yellow',
      routerLink: '/trade/offers',
      actionList: [
        {
          action: 'createNewOffer',
          title: 'BUTTON.CREATE_OFFER',
          icon: 'plus-square'
        },
        {
          action: '/trade/offers',
          title: 'LAUNCH_PAD.OPEN_OFFERS_LIST',
          icon: 'list'
        },
        getTradeSettingsActionItem(showWholesaleSettings)
      ]
    },
    [AppNameEnum.SALES_ORDERS]: {
      title: 'APP.SALES_ORDERS',
      iconName: 'sales-order',
      mainColor: 'yellow',
      routerLink: '/trade/sales-order',
      actionList: [
        {
          action: 'createNewOrder',
          title: 'BUTTON.CREATE_SALES_ORDER',
          icon: 'plus-square'
        },
        {
          router: '/trade/sales-order',
          title: 'LAUNCH_PAD.OPEN_SO_LIST',
          icon: 'list'
        },
        getTradeSettingsActionItem(showWholesaleSettings)
      ]
    },
    [AppNameEnum.RETURNS]: {
      title: 'APP.RETURNS',
      iconName: 'exchange-return',
      mainColor: 'yellow',
      routerLink: '/trade/exchanges-and-returns',
      actionList: [
        {
          action: 'createNewERA',
          title: 'BUTTON.CREATE_AUTHORIZATION',
          icon: 'plus-square'
        },
        {
          router: '/trade/exchanges-and-returns',
          title: 'LAUNCH_PAD.OPEN_RETURNS_LIST',
          icon: 'list'
        },
        getTradeSettingsActionItem(showWholesaleSettings)
      ]
    },
    [AppNameEnum.TIME_TRACKING]: {
      title: 'APP.TIME_TRACKING',
      iconName: 'timer',
      mainColor: 'green',
      routerLink: '/services/time-tracking',
      actionList: [
        {
          action: 'onLogTimeClick',
          title: 'BUTTON.LOG_TIME',
          icon: 'timer'
        },
        {
          router: '/services/time-tracking',
          title: 'LAUNCH_PAD.OPEN_LOG',
          icon: 'list'
        },
        getServicesSettingsActionItem(showServicesSettings)
      ]
    },
    [AppNameEnum.SUBSCRIPTIONS]: {
      title: 'APP.SUBSCRIPTIONS',
      iconName: 'subscriptions',
      mainColor: 'green',
      routerLink: '/services/subscription',
      actionList: [
        {
          action: 'createNewSubscription',
          title: 'BUTTON.CREATE_SUBSCRIPTION',
          icon: 'plus-square'
        },
        {
          router: '/services/subscription',
          title: 'LAUNCH_PAD.OPEN_SBC_LIST',
          icon: 'list'
        },
        getServicesSettingsActionItem(showServicesSettings)
      ]
    },
    [AppNameEnum.E_COMMERCE]: {
      title: 'MODULE.E_COMMERCE',
      iconName: 'shopping-cart',
      mainColor: 'red',
      routerLink: '/e-commerce/orders',
      actionList: [
        {
          router: '/e-commerce/orders',
          title: 'LAUNCH_PAD.OPEN_ORDERS_LIST',
          icon: 'list'
        },
      ]
    },
    [AppNameEnum.CONTROL_CENTER]: {
      title: 'MODULE.CONTROL_CENTER',
      iconName: 'compass',
      mainColor: 'black',
      routerLink: '/control-center',
      actionList: [
        {
          router: '/control-center/sales',
          title: 'LAUNCH_PAD.OPEN_CONTROL_CENTER',
          icon: 'list'
        },
      ]
    },
    [AppNameEnum.DATA_IMPORT]: {
      title: 'DATA_IMPORT.DATA_IMPORT',
      iconName: 'cloud-upload',
      routerLink: '/data-import',
      mainColor: 'black',
      actionList: [
        {
          router: '/data-import',
          title: 'LAUNCH_PAD.OPEN_DATA_IMPORT',
          icon: 'list'
        },
        // {
        //   action: 'openProductsImportModal',
        //   title: 'DATA_IMPORT.IMPORT_PRODUCTS',
        //   icon: 'box'
        // },
        // {
        //   action: 'openPartnersImportModal',
        //   title: 'DATA_IMPORT.IMPORT_PARTNERS',
        //   icon: 'heart'
        // },
        // {
        //   action: 'openPricesImportModal',
        //   title: 'DATA_IMPORT.IMPORT_PRICES',
        //   icon: 'pricetag'
        // },
        // {
        //   action: 'openStockImportModal',
        //   title: 'DATA_IMPORT.IMPORT_STOCK_DATA',
        //   icon: 'cubes'
        // },
      ]
    },
    [AppNameEnum.GOODS]: {
      title: 'COMMON.GOODS',
      iconName: 'box',
      mainColor: 'purple',
      routerLink: `/products/${ProductTypes.GOODS}`,
      actionList: [
        {
          action: 'createNewProduct',
          title: 'BUTTON.CREATE_PRODUCT',
          icon: 'plus-square'
        },
        {
          router: `/products/${ProductTypes.GOODS}`,
          title: 'DATA_IMPORT.OPEN_GOODS',
          icon: 'list'
        },
        getProductsSettingsActionItem(showProductsSettings),
      ]
    },
    [AppNameEnum.SERVICES]: {
      title: 'APP.SERVICES',
      iconName: 'checklist',
      mainColor: 'purple',
      routerLink: `/products/${ProductTypes.SERVICES}`,
      actionList: [
        {
          action: 'createNewService',
          title: 'BUTTON.CREATE_SERVICE',
          icon: 'plus-square'
        },
        {
          router: `/products/${ProductTypes.SERVICES}`,
          title: 'DATA_IMPORT.OPEN_SERVICES',
          icon: 'list'
        },
        getProductsSettingsActionItem(showProductsSettings),
      ]
    },
    [AppNameEnum.DIGITAL]: {
      title: 'APP.DIGITAL_PRODUCTS',
      iconName: 'digital-product',
      mainColor: 'purple',
      routerLink: `/products/${ProductTypes.DIGITAL}`,
      actionList: [
        {
          action: 'createNewDigitalProduct',
          title: 'BUTTON.CREATE_DGT_PRODUCT',
          icon: 'plus-square'
        },
        {
          router: `/products/${ProductTypes.DIGITAL}`,
          title: 'DATA_IMPORT.OPEN_DIGITAL',
          icon: 'list'
        },
        getProductsSettingsActionItem(showProductsSettings),
      ]
    },
    [AppNameEnum.GENERAL_PRODUCTS]: {
      title: 'GL_ACCOUNTS.GENERAL_PRODUCTS',
      iconName: 'dashboard',
      mainColor: 'purple',
      routerLink: `/products/general-products`,
      actionList: [
        {
          router: `/products/general-products`,
          title: 'LAUNCH_PAD.OPEN_GENERAL_PRODUCT_LIST',
          icon: 'list'
        },
        getProductsSettingsActionItem(showProductsSettings),
      ]
    },
    [AppNameEnum.CATEGORIES]: {
      title: 'APP.CATEGORIES',
      iconName: 'folder-open',
      mainColor: 'purple',
      routerLink: `/products/settings/categories/${ProductTypes.GOODS}`,
      actionList: [
        {
          router: `/products/settings/categories/${ProductTypes.GOODS}`,
          title: 'LAUNCH_PAD.OPEN_CATEGORIES',
          icon: 'list'
        },
        getProductsSettingsActionItem(showProductsSettings),
      ]
    },
    [AppNameEnum.CORPORATE_PARTNERS]: {
      title: 'LAUNCH_PAD.COMPANIES',
      iconName: 'factory',
      mainColor: 'brown',
      routerLink: `/partners-new/corporate`,
      actionList: [
        {
          action: 'createNewPartner',
          title: 'LAUNCH_PAD.CREATE_COMPANY',
          icon: 'plus-square'
        },
        {
          router: `/partners-new/corporate`,
          title: 'LAUNCH_PAD.OPEN_COMPANIES',
          icon: 'list'
        },
        getPartnersSettingsActionItem(showPartnersSettings),
      ]
    },
    [AppNameEnum.PRIVATE_PARTNERS]: {
      title: 'APP.PRIVATE_PARTNERS',
      iconName: 'person',
      mainColor: 'brown',
      routerLink: `/partners-new/private`,
      actionList: [
        {
          action: 'createNewPrivatePartner',
          title: 'LAUNCH_PAD.CREATE_PP',
          icon: 'plus-square'
        },
        {
          router: `/partners-new/private`,
          title: 'DATA_IMPORT.OPEN_PP',
          icon: 'list'
        },
        getPartnersSettingsActionItem(showPartnersSettings),
      ]
    },
    [AppNameEnum.EMPLOYEES]: {
      title: 'APP.EMPLOYEES',
      iconName: 'people',
      mainColor: 'sky',
      routerLink: `/team`,
      actionList: [
        {
          action: 'createNewEmployee',
          title: 'TEAM.CREATE_EMPLOYEE',
          icon: 'plus-square'
        },
        {
          router: `/team`,
          title: 'LAUNCH_PAD.OPEN_EMPLOYEE',
          icon: 'list'
        },
      ]
    },
    [AppNameEnum.PARTNERS_SETTINGS]: {
      title: 'SETTINGS.PARTNER_SETTINGS',
      iconName: 'options-2',
      mainColor: 'brown',
      routerLink: `/partners-new/settings`,
      actionList: [
        {
          router: `/partners-new/settings`,
          title: 'LAUNCH_PAD.OPEN_PARTNERS_SETTINGS',
          icon: 'options-2'
        },
      ]
    },
    [AppNameEnum.PRODUCTS_SETTINGS]: {
      title: 'SETTINGS.PRODUCTS_SETTINGS',
      iconName: 'options-2',
      mainColor: 'purple',
      routerLink: `/products/products-settings`,
      actionList: [
        {
          router: `/products/products-settings`,
          title: 'LAUNCH_PAD.OPEN_PRODUCTS_SETTINGS',
          icon: 'options-2'
        },
      ]
    },
    [AppNameEnum.PRICES]: {
      title: 'APP.PRICES',
      iconName: 'pricetag',
      mainColor: 'yellow',
      routerLink: showSalesPrices ? '/trade/prices/sales' : '/trade/prices/purchase',
      actionList: [
        {
          router: '/trade/prices/sales',
          title: 'LAUNCH_PAD.OPEN_SALES_PRICE',
          icon: 'list',
          hide: !showSalesPrices
        },
        {
          router: '/trade/prices/purchase',
          title: 'LAUNCH_PAD.OPEN_PURCHASE_PRICE',
          icon: 'list',
          hide: !showPurchasePrices
        },
      ]
    },
    [AppNameEnum.PRODUCTS]: {
      title: 'APP.PRODUCTS',
      iconName: 'box',
      mainColor: 'purple',
      routerLink: `/products/${showGoods ? ProductTypes.GOODS : showServices ? ProductTypes.SERVICES : ProductTypes.DIGITAL}`,
      actionList: [
        {
          action: 'createNewProduct',
          title: 'BUTTON.CREATE_PRODUCT',
          icon: 'plus-square',
          hide: !showGoods
        },
        {
          router: `/products/${ProductTypes.GOODS}`,
          title: 'DATA_IMPORT.OPEN_GOODS',
          icon: 'list',
          hide: !showGoods
        },
        {
          action: 'createNewService',
          title: 'BUTTON.CREATE_SERVICE',
          icon: 'plus-square',
          hide: !showServices
        },
        {
          router: `/products/${ProductTypes.SERVICES}`,
          title: 'DATA_IMPORT.OPEN_SERVICES',
          icon: 'list',
          hide: !showServices
        },
        {
          action: 'createNewDigitalProduct',
          title: 'BUTTON.CREATE_DGT_PRODUCT',
          icon: 'plus-square',
          hide: !showDigitalProducts
        },
        {
          router: `/products/${ProductTypes.DIGITAL}`,
          title: 'DATA_IMPORT.OPEN_DIGITAL',
          icon: 'list',
          hide: !showDigitalProducts
        },
        {
          router: `/products/products-settings`,
          title: 'LAUNCH_PAD.OPEN_PRODUCTS_SETTINGS',
          icon: 'options-2',
          hide: !showProductsSettings,
        },
      ]
    },
    [AppNameEnum.PARTNERS]: {
      title: 'APP.BUSINESS_PARTNER',
      iconName: 'heart',
      mainColor: 'brown',
      routerLink: showCorporatePartners ? `/partners-new/corporate` : `/partners-new/private`,
      actionList: [
        {
          action: 'createNewPartner',
          title: 'LAUNCH_PAD.CREATE_COMPANY',
          icon: 'plus-square',
          hide: !showCorporatePartners,
        },
        {
          router: `/partners-new/corporate`,
          title: 'LAUNCH_PAD.OPEN_COMPANIES',
          icon: 'list',
          hide: !showCorporatePartners,
        },
        {
          action: 'createNewPrivatePartner',
          title: 'LAUNCH_PAD.CREATE_PP',
          icon: 'plus-square',
          hide: !showPrivatePartners,
        },
        {
          router: `/partners-new/private`,
          title: 'DATA_IMPORT.OPEN_PP',
          icon: 'list',
          hide: !showPrivatePartners,
        },
        {
          router: `/partners-new/settings`,
          title: 'LAUNCH_PAD.OPEN_PARTNERS_SETTINGS',
          icon: 'options-2',
          hide: !showPartnersSettings,
        },
      ]
    },
  };
}
