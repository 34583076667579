import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SafePipeModule } from 'safe-pipe';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import {
  InitialSetupComponent,
  LegalInfoComponent,
  InitialSetupPageComponent,
  FinishingComponent,
  ErrorPageComponent,
  CompanyTypeComponent,
  CompanyDetailsComponent,
  ContactInfoComponent,
  AccountingMethodComponent,
  AppFeaturesComponent
} from './components';
import {
  InputValidationErrorsModule
} from 'projects/workspace/src/app/shared/components/input-validation-errors/input-validation-errors.module';
import { AddressFormModule } from 'projects/workspace/src/app/crm/partner-forms/components/address-form/address-form.module';
import { UIComponentsModule } from '../ui-components/ui-components.module';
import { RnplCommonModule } from '../rnpl-common';
import { HelloInitialSetupComponent } from './components/hello-initial-setup/hello-initial-setup.component';
import { HotjarService } from 'projects/workspace/src/app/shared/services';



@NgModule({
  declarations: [
    InitialSetupComponent,
    LegalInfoComponent,
    InitialSetupPageComponent,
    HelloInitialSetupComponent,
    FinishingComponent,
    ErrorPageComponent,
    CompanyTypeComponent,
    CompanyDetailsComponent,
    ContactInfoComponent,
    AccountingMethodComponent,
    AppFeaturesComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgSelectModule,
    InputValidationErrorsModule,
    UIComponentsModule,
    ReactiveFormsModule,
    RnplCommonModule,
    AddressFormModule,
    FormsModule,
    SafePipeModule,
    TranslateModule,
    PerfectScrollbarModule,
  ],
  providers: [
    HotjarService
  ],
  entryComponents: [
    InitialSetupComponent
  ]
})
export class InitialSetupModule { }
