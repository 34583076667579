import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material';

import { ColumnsSettingsComponent } from './columns-settings.component';
import { UserColumnsSettingsService } from '../../services/user-columns-settings.service';


@NgModule({
  declarations: [
    ColumnsSettingsComponent
  ],
  exports: [
    ColumnsSettingsComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    TranslateModule
  ],
  providers: [
    UserColumnsSettingsService
  ]
})
export class ColumnsSettingsModule { }
