import { createReducer, on } from '@ngrx/store';

import { PaginationModel, UIStatesEnum } from 'common/src/models';
import { CollectionEcoOrderActions } from '../actions/action-types';
import { SalesPositionsByProductType } from '../../models/sales-order-position.model';
import { EcoOrderListTabsEnum } from '../../enums';
import { FilterModel } from '../../../../../warehouse/models/filter.model';
import { CorporatePartnerModel } from '../../../../../crm/models';
import { ResponseModel } from '../../../../../shared/models/response';
import { EcoOrderModel } from '../../models';

export const EcoOrderFeatureKey = 'ecoOrderStore';

export interface EcoOrdersListState {
  [page: string]: {
    pagination: PaginationModel;
    sort: FilterModel;
    data: EcoOrderModel[];
  }
}
export interface EcoOrdersState {
  [EcoOrderListTabsEnum.OPEN]?: EcoOrdersListState;
  [EcoOrderListTabsEnum.COMPLETED]?: EcoOrdersListState;
  [EcoOrderListTabsEnum.CANCELED]?: EcoOrdersListState;
  [EcoOrderListTabsEnum.DELETED]?: EcoOrdersListState;
}

export interface EcoOrderStore {
  entity: EcoOrderState;
  list: EcoOrdersListState;
  blocksValidations: EcoOrderBlocksValidationsState;
}

export interface EcoOrderState {
  ecoOrder: EcoOrderModel;
  currentState: UIStatesEnum;
  partners: CorporatePartnerModel[];
  positions: ResponseModel<SalesPositionsByProductType>;
  positionsCount: number;
  loadingRequestsCount: number;
  updatedAt: Date;
  isShouldRefresh: boolean;
}

export const initialEcoOrderState: EcoOrderState = {
  ecoOrder: null,
  partners: [],
  currentState: UIStatesEnum.VIEW,
  positions: null,
  positionsCount: 0,
  loadingRequestsCount: 0,
  updatedAt: null,
  isShouldRefresh: false
};

export const initialEcoOrdersState: EcoOrdersState = {
  [EcoOrderListTabsEnum.OPEN]: {},
  [EcoOrderListTabsEnum.COMPLETED]: {},
  [EcoOrderListTabsEnum.CANCELED]: {},
  [EcoOrderListTabsEnum.DELETED]: {},
}

export interface EcoOrderBlocksValidationsState {
  propertiesBlockValid: boolean;
  deliveryInfoBlockValid: boolean;
  billingInfoBlockValid: boolean;
  positionsBlockValid: boolean;
}

export const initialEcoOrderBlocksValidationsState: EcoOrderBlocksValidationsState = {
  propertiesBlockValid: false,
  deliveryInfoBlockValid: false,
  billingInfoBlockValid: false,
  positionsBlockValid: false,
};

export function EcoOrderReducer(state, action) {
  return _EcoOrderReducer(state, action);
}

export function EcoOrderListReducer(state, action) {
  return _EcoOrderListReducer(state, action);
}

export function EcoOrderBlocksValidationsReducer(state, action) {
  return _EcoOrderBlocksValidationsReducer(state, action);
}

const _EcoOrderBlocksValidationsReducer = createReducer(
  initialEcoOrderBlocksValidationsState,
  on(CollectionEcoOrderActions.UpdatePropertiesBlockValid, (state, { propertiesBlockValid }) => ({
    ...state,
    propertiesBlockValid
  })),
  on(CollectionEcoOrderActions.UpdateDeliveryInfoBlockValid, (state, { deliveryInfoBlockValid }) => ({
    ...state,
    deliveryInfoBlockValid
  })),
  on(CollectionEcoOrderActions.UpdateBillingInfoBlockValid, (state, { billingInfoBlockValid }) => ({
    ...state,
    billingInfoBlockValid
  })),
  on(CollectionEcoOrderActions.UpdatePositionsBlockValid, (state, { positionsBlockValid }) => ({
    ...state,
    positionsBlockValid
  })),
);

const _EcoOrderListReducer = createReducer(
  initialEcoOrdersState,
  on(CollectionEcoOrderActions.LoadEcoOrdersList, (state, { ecoOrderListData, status, page }) => ({
    ...state,
    [status]: {
      ...state[status],
      ...ecoOrderListData
    },
  })),
);

const _EcoOrderReducer = createReducer(
  initialEcoOrderState,
  // EcoOrder
  on(CollectionEcoOrderActions.LoadEcoOrder, (state, { ecoOrder }) => ({
    ...state,
    ecoOrder
  })),
  on(CollectionEcoOrderActions.ClearSalesOrder, (state) => ({
    ...state,
    salesOrder: null
  })),
  on(CollectionEcoOrderActions.ClearEcoOrderState, (state) => ({
    ...state,
    ecoOrder: null,
    currentState: UIStatesEnum.VIEW,
    positions: null,
    positionsCount: 0,
    loadingRequestsCount: 0,
    updatedAt: null
  })),
  on(CollectionEcoOrderActions.UpdateEcoOrder, (state, { ecoOrder }) => ({
    ...state,
    ecoOrder
  })),
  // on(CollectionEcoOrderActions.UpdateSalesOrderSummary, (state, { summary }) => ({
  //   ...state,
  //   salesOrder: {
  //     ...state.salesOrder,
  //     summary
  //   }
  // })),
  on(CollectionEcoOrderActions.UpdateShouldRefreshEntity, (state, { isShouldRefresh }) => ({
    ...state,
    isShouldRefresh,
  })),
  on(CollectionEcoOrderActions.LoadEcoOrderPositions, (state, { positions }) => ({
    ...state,
    positions
  })),
  on(CollectionEcoOrderActions.UpdateEcoOrderPositionsCount, (state, { positionsCount }) => ({
    ...state,
    positionsCount
  })),
  // on(CollectionEcoOrderActions.UpdateSalesOrderStatus, (state, { status}) => ({
  //   ...state,
  //   salesOrder: {
  //     ...state.salesOrder,
  //     status: status
  //   }
  // })),
  on(CollectionEcoOrderActions.UpdateEcoOrderUpdatedAt, (state, { updatedAt }) => ({
    ...state,
    updatedAt
  })),
  // on(CollectionEcoOrderActions.LoadSalesOrderCountries, (state, {countries}) => ({
  //   ...state,
  //   countries
  // })),
  // on(CollectionEcoOrderActions.LoadSalesOrderPartners, (state, {partners}) => ({
  //   ...state,
  //   partners
  // })),
  on(CollectionEcoOrderActions.UpdateEcoOrderCurrentState, (state, { currentState }) => ({
    ...state,
    currentState: currentState
  })),
  on(CollectionEcoOrderActions.IncrementLoadingRequestsCount, (state) => ({
    ...state,
    loadingRequestsCount: state.loadingRequestsCount + 1
  })),
  on(CollectionEcoOrderActions.DecrementLoadingRequestsCount, (state) => ({
    ...state,
    loadingRequestsCount: state.loadingRequestsCount - 1
  })),
);
